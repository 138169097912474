import React from 'react';
import WizardStepItem from './WizardStepItem';
import icons from '../../../../constants/icons';
import { useTranslation } from 'react-i18next';

export default function KeyWorkflowTagsStep({
  requesterTags,
  handleRequesterTagSelect,
}) {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="mb-4">{t('Choose a request tag')}</h2>
      {requesterTags
        ? [...requesterTags]
            ?.sort((a, b) => a.tag.localeCompare(b.tag))
            .map((tag) => (
              <WizardStepItem
                key={tag.id}
                id={tag.id}
                title={tag.tag}
                description=""
                icon={icons.WIZARD_NEXT}
                onClick={handleRequesterTagSelect}
                typeIcon={`${icons.TAG} text-orange`}
              ></WizardStepItem>
            ))
        : ''}
    </>
  );
}
