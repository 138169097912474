import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';

import {
  savePlatform,
  getPlatformById,
} from '../../../../actions/platformActions';
import { toast } from 'react-toastify';
import PlatformForm from '../components/PlatformForm';
import { useForm } from 'react-hook-form';
import KwPanel from '../../../common/KwPanel';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';

function Edit({ done, id, cancel, savePlatform, getPlatformById }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [file, setFile] = useState();
  const onSubmit = (data) => {
    savePlatform({ ...platform, ...data, file }).then(() => {
      toast.success(t('Platform saved'));
      done(platform);
    });
  };

  const [platform, setPlatform] = useState({});

  useEffect(() => {
    if (id) {
      getPlatformById(id).then((d) => {
        setPlatform(d);
      });
    }
  }, [id, getPlatformById]);

  return (
    <SlideoutLayout
      title={id ? t('Edit platform') : t('Add platform')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="submit"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <KwPanel className="mb-4 pb-1">
        <PlatformForm
          platform={platform}
          register={register}
          errors={errors}
          control={control}
          setFile={setFile}
        ></PlatformForm>
      </KwPanel>
    </SlideoutLayout>
  );
}
Edit.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  savePlatform: PropTypes.func.isRequired,
  getPlatformById: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  savePlatform,
  getPlatformById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
