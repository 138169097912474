import React, { useState } from 'react';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import UserProfileGroupForm from '../components/UserProfileGroupForm';
import { saveUserProfileGroup } from '../../../../actions/userProfileGroupActions';
import { useTranslation } from 'react-i18next';

function Add({ done, id, cancel }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = (data) => {
    saveUserProfileGroup({ ...userProfileGroup, ...data })
      .then(() => {
        toast.success(t('Group saved'));
        done(userProfileGroup);
      })
      .catch(() => {
        toast.error(t('Failed to save group'));
      });
  };

  const [userProfileGroup] = useState({});

  return (
    <SlideoutLayout
      title={id ? t('Edit group') : t('Add group')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <UserProfileGroupForm
        userProfileGroup={userProfileGroup}
        register={register}
        errors={errors}
        control={control}
      ></UserProfileGroupForm>
    </SlideoutLayout>
  );
}
Add.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};
export default Add;
