import React from 'react';
import FormGroup from './FormGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const FormDate = ({
  name,
  label,
  placeholder,
  defaultValue,
  register,
  required,
  joinedButton,
  joinedButtonIcon,
  joinedButtonClick,
  joinedButtonClassName,
  joinedButtonTitle,
  error,
  errorMessage,
  helpContent,
}) => (
  <FormGroup
    label={label}
    error={error}
    errorMessage={errorMessage}
    required={required}
    helpContent={helpContent}
  >
    {joinedButton ? (
      <div className="input-group" role="search">
        <input
          {...register(name, { required })}
          type="date"
          aria-label={label}
          name={name}
          placeholder={placeholder}
          className="form-control float-left"
          style={{ width: 'calc(100% - 45px)' }}
          defaultValue={defaultValue}
          data-lpignore="true"
          autoComplete="off"
        />
        <button
          type="submit"
          onClick={joinedButtonClick}
          title={joinedButtonTitle}
          className={`btn ${
            joinedButtonClassName || 'btn-primary'
          } float-left r-corners-right`}
        >
          <FontAwesomeIcon
            icon={joinedButtonIcon}
            aria-hidden="true"
          ></FontAwesomeIcon>
        </button>
      </div>
    ) : (
      <input
        {...register(name, { required })}
        type="date"
        aria-label={label}
        name={name}
        placeholder={placeholder}
        className={`form-control`}
        defaultValue={defaultValue}
        data-lpignore="true"
        autoComplete="off"
      />
    )}
  </FormGroup>
);
export default FormDate;
