import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import FormSelect from '../../../common/FormSelect';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { getPlatforms } from '../../../../actions/platformActions';
import { saveKeyProviderPlatform } from '../../../../actions/keyProviderActions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function AddKeyProviderPlatform({
  keyProviderId,
  done,
  cancel,
  platforms,
  getPlatforms,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  useEffect(() => {
    if (!platforms) {
      getPlatforms();
    }
  }, [platforms, getPlatforms]);

  const onSubmit = (data) => {
    saveKeyProviderPlatform(keyProviderId, data.platformId)
      .then(() => {
        toast.success(t('Platform successfully added to key provider'));
        const platform = platforms.find((x) => x.id === data.platformId);
        done(platform);
      })
      .catch(() => {
        toast.error(t('Failed to add platform to key provider'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Add platform')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <FormSelect
        autoFocus
        register={register}
        required={true}
        error={errors.platformId}
        errorMessage={t('Platform is required')}
        name="platformId"
        label={t('Platform')}
      >
        <option value="">{t('--- choose ---')}</option>
        {platforms
          ? [...platforms]
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))
          : ''}
      </FormSelect>
    </SlideoutLayout>
  );
}

AddKeyProviderPlatform.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  keyProviderId: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    platforms: state.platforms,
  };
}

const mapDispatchToProps = {
  getPlatforms,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddKeyProviderPlatform);
