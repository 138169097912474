import React, { useState } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../../layout/ActionBar';
import Add from './slideout/Add';
import UserProfileGroupList from './components/UserProfileGroupList';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function UserProfileGroups({ location }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();

  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const handleAddUserProfileGroup = (e) => {
    e.cancelBubble = true;
    setAddSlideoutOpen(true);
    return false;
  };

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={handleAddUserProfileGroup}
          text={t('Add group')}
        />
      </ActionBar>
      <UserProfileGroupList location={location} reload={reload} />

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <Add
          cancel={() => setAddSlideoutOpen(false)}
          done={(d) => {
            setAddSlideoutOpen(false);
            setReload(new Date());
          }}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileGroups);
