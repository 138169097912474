import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';

import {
  savePackageType,
  getPackageTypeById,
} from '../../../../actions/packageTypeActions';
import { toast } from 'react-toastify';
import PackageTypeForm from '../components/PackageTypeForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';

function Edit({ done, id, cancel, savePackageType, getPackageTypeById }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = (data) => {
    savePackageType({ ...packageType, ...data }).then(() => {
      toast.success(t('PackageType saved'));
      done(packageType);
    });
  };

  const [packageType, setPackageType] = useState({});

  useEffect(() => {
    if (id) {
      getPackageTypeById(id).then((d) => {
        setPackageType(d);
      });
    }
  }, [id, getPackageTypeById]);

  return (
    <SlideoutLayout
      title={id ? t('Edit packageType') : t('Add packageType')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="save"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <PackageTypeForm
        packageType={packageType}
        register={register}
        errors={errors}
        control={control}
      ></PackageTypeForm>
    </SlideoutLayout>
  );
}
Edit.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  savePackageType: PropTypes.func.isRequired,
  getPackageTypeById: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    company: state.company,
  };
}

const mapDispatchToProps = {
  savePackageType,
  getPackageTypeById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
