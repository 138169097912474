import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function KeyProviderHeader({
  keyProviders,
  filterKeyProvider,
  handleFilterKeyProvider,
}) {
  const { t } = useTranslation();
  return (
    <div className="bulk-grid-game-name-th">
      <span className="mb-2">{t('Key provider')}</span>
      <select
        className="form-control "
        value={filterKeyProvider || ''}
        onChange={handleFilterKeyProvider}
      >
        <option value="">{t('All')}</option>
        {keyProviders
          ? [...keyProviders]
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))
          : ''}
      </select>
    </div>
  );
}

KeyProviderHeader.propTypes = {
  keyProviders: PropTypes.array.isRequired,
  filterKeyProvider: PropTypes.string,
  handleFilterKeyProvider: PropTypes.func.isRequired,
};

export default KeyProviderHeader;
