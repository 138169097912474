import React, { useState, useEffect } from 'react';
import { getAssignableUsers } from '../../../actions/userActions';
import { reassignRequest } from '../../../actions/requestActions';
import FormSelect from '../../common/FormSelect';
import KwPanel from '../../common/KwPanel';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { toast } from 'react-toastify';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

export default function ReassignBulk({ done, cancel, requestGroups }) {
  const [userId, setUserId] = useState();
  const [users, setUsers] = useState();
  const [processing, setProcessing] = useState();
  const { t } = useTranslation();

  const onUserChange = (e) => {
    const id = e.target.value;
    setUserId(id);
  };

  useEffect(() => {
    if (!users) {
      getAssignableUsers()
        .then((d) => {
          setUsers(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('No users found'));
        });
    }
  }, [users]);

  const onSubmit = (data) => {
    setProcessing(true);
    const actions = [];
    for (const i in requestGroups) {
      const requestGroup = requestGroups[i];
      actions.push(reassignRequest(requestGroup, userId || null));
    }
    Promise.all(actions).then(() => {
      setProcessing(false);
      done();
      toast.success(t('Key requests updated'));
    });
  };

  return (
    <SlideoutLayout
      title={t('Reassign')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          className="btn btn-primary"
          onClick={onSubmit}
          isDisabled={processing}
          title={processing ? t('Processing') : t('Assign')}
          text={t('Assign')}
        />
      }
    >
      <KwPanel className="mb-4 form-horizontal">
        <FormSelect
          label={t('Assign to')}
          placeholder=""
          onChange={onUserChange}
          name={'assignTo'}
        >
          <option value={null}>{t('--- unassign ---')}</option>
          {users?.map((x) => (
            <option key={x.id} value={x.id}>
              {x.fullName}
            </option>
          ))}
        </FormSelect>
      </KwPanel>
    </SlideoutLayout>
  );
}
