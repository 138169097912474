import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';

import { cloneWorkflow } from '../../../../actions/workflowActions';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import CloneWorkflowForm from '../components/CloneWorkflowForm';
import { useTranslation } from 'react-i18next';

function Clone({ done, id, cancel, cloneWorkflow }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = (data) => {
    cloneWorkflow({ ...data, id })
      .then((newId) => {
        toast.success(t('Workflow successfully cloned'));
        done(newId);
      })
      .catch(() => {
        toast.error(t('Failed to clone workflow'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Clone workflow')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Clone')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <CloneWorkflowForm
        register={register}
        errors={errors}
        control={control}
      />
    </SlideoutLayout>
  );
}
Clone.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  cloneWorkflow,
};

export default connect(mapStateToProps, mapDispatchToProps)(Clone);
