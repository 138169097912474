import React from 'react';
import './App.scss';
import "./util/i18n.js";

import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './registerServiceWorker';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
