import React from 'react';
import WizardStepItem from './WizardStepItem';
import icons from '../../../../constants/icons';
import { useTranslation } from 'react-i18next';
export default function SelectKeyWorkflowStep({
  keyWorkflows,
  handleKeyWorkflowSelect,
}) {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="mb-4">{t('What type of request is it?')}</h2>
      {keyWorkflows
        ? [...keyWorkflows]
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((keyWorkflow) => (
              <WizardStepItem
                key={keyWorkflow.id}
                id={keyWorkflow.id}
                title={keyWorkflow.name}
                description={keyWorkflow.description}
                icon={icons.WIZARD_NEXT}
                onClick={handleKeyWorkflowSelect}
                typeIcon={`${icons.WORKFLOW} text-aqua`}
              ></WizardStepItem>
            ))
        : ''}
    </>
  );
}
