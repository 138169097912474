import React, { useState } from 'react';
import ApproverNotificationUsersList from './components/ApproverNotificationUsersList';
import icons from '../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function ApproverNotificationUsers({ location }) {
  const { t } = useTranslation();
  const [reload] = useState();

  return (
    <>
      <ApproverNotificationUsersList
        helpPanel={
          <>
            <p className="alert alert-info">
              <span className="icon-wrapper me-3">
                <FontAwesomeIcon icon={icons.NOTIFICATION} />
              </span>
              {''}
              {t(
                "You can manage who receives notifications about approvals here. Click a user's name to toggle their preference."
              )}
            </p>
          </>
        }
        location={location}
        reload={reload}
      />
    </>
  );
}
export default ApproverNotificationUsers;
