import React from 'react';
import PropTypes from 'prop-types';

function PriceCell({ promotion, game, editable }) {
  return (
    <div className={editable ? 'grid-price' : 'grid-price-readonly'}>£123</div>
  );
}

PriceCell.propTypes = {
  promotion: PropTypes.object,
  game: PropTypes.object,
  editable: PropTypes.bool,
};

export default PriceCell;
