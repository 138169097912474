import actionTypes from '../constants/actionTypes';
import { ForbiddenError, TrialEndedError } from '../api/apiUtils';

export function handleErrorWithDispatch(e, dispatch) {
  if (e instanceof TrialEndedError) {
    dispatch({
      type: actionTypes.TRIAL_ENDED,
    });
  } else if (e instanceof ForbiddenError) {
    dispatch({
      type: actionTypes.ACCESS_DENIED,
    });
  } else if (e instanceof Error) {
    dispatch({
      type: actionTypes.API_FAILURE,
    });
  }
}

export function handleError(e, history, callback) {
  if (e instanceof TrialEndedError) {
    history.push('/trial-ended');
  } else if (e instanceof ForbiddenError) {
    history.push('/access-denied');
  } else if (e instanceof Error) {
    if (callback) {
      callback(e);
    } else {
      throw e;
    }
  }
}
