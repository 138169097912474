import React from 'react';
import KwPanel from '../../../common/KwPanel';
import FormInput from '../../../common/FormInput';
import PropTypes from 'prop-types';
import FormStatic from '../../../common/FormStatic';
import FormSelect from '../../../common/FormSelect';
import FormToggleBool from '../../../common/FormToggleBool';
import LanguagePicker from './LanguagePicker';
import { useTranslation } from 'react-i18next';

const UserForm = ({ user, register, errors, control, companies, readonly }) => {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4">
      <FormInput
        register={register}
        required={true}
        error={errors.fullName}
        errorMessage={t('Name is required')}
        name="fullName"
        label={t('Name')}
        placeholder={t('Name of the user')}
        disabled={readonly === true}
        defaultValue={user.fullName || ''}
        autoFocus={true}
      />
      <FormStatic label={t('Username')}>{user.username}</FormStatic>
      <FormSelect
        register={register}
        required={true}
        error={errors.companyId}
        errorMessage={t('Company is required')}
        name="companyId"
        label={t('Company')}
        placeholder=""
        disabled={readonly === true}
        defaultValue={user.companyId || ''}
      >
        <option value="">{t('--- choose ---')}</option>
        {companies &&
          [...companies]
            ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
            ?.map((x) => (
              <option key={x.id} value={x.id}>
                {x.companyName}
              </option>
            ))}
      </FormSelect>
      <FormInput
        register={register}
        required={true}
        error={errors.emailAddress}
        errorMessage={t('Email address is required')}
        name="emailAddress"
        label={t('Email address')}
        placeholder={t('Email address of the user')}
        disabled={readonly === true}
        defaultValue={user.emailAddress || ''}
      />
      <LanguagePicker user={user} register={register} />
      <FormInput
        register={register}
        error={errors.creditLimit}
        name="creditLimit"
        label={t('Credit limit')}
        placeholder={t('Optional')}
        disabled={readonly === true}
        defaultValue={user.creditLimit || ''}
      />
      <FormToggleBool
        control={control}
        name="isAuthorised"
        label={t('Is authorised')}
        disabled={readonly === true}
        defaultValue={user.isAuthorised || false}
      />
    </KwPanel>
  );
};

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
};

export default UserForm;
