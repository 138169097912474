import React from 'react';
import PropTypes from 'prop-types';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import Button from '../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';
const KeyCount = ({
  request,
  processing,
  onEditRequestKeyCount,
  compressed,
}) => {
  const { t } = useTranslation();
  return compressed ? (
    <>
      {request.currentUserCanSign && (
        <span
          tabIndex={0}
          disabled={processing}
          onClick={(e) => onEditRequestKeyCount(e, request)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onEditRequestKeyCount(e, request);
            }
          }}
          className="a"
          style={{ fontSize: '12px' }}
        >
          <FontAwesomeIcon icon={icons.EDIT} className="me-4" />
        </span>
      )}
      <span className={request.currentUserCanSign ? 'edit-hover-item' : ''}>
        {CommaNumber_NoDecimal(request.numberOfKeys)}
      </span>
    </>
  ) : (
    <div className="col-6">
      <label className="col-form-label">{t('Keys')}</label>
      <div className="flex ps-0">
        <div className="form-control-static px-3 width-100 flex align-items-center h-100 num no-wrap">
          {CommaNumber_NoDecimal(request.numberOfKeys)}
        </div>
        {request.currentUserCanSign && (
          <Button
            isDisabled={processing}
            title={processing ? t('Processing') : t('Edit')}
            onClick={(e) => onEditRequestKeyCount(e, request)}
            className="btn btn-default float-left r-corners-right"
            text={t('Edit')}
          />
        )}
      </div>
    </div>
  );
};

KeyCount.propTypes = {
  request: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  onEditRequestKeyCount: PropTypes.func.isRequired,
  compressed: PropTypes.bool,
};

export default KeyCount;
