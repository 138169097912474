import React, { useState } from 'react';
import {
  loadGamesModifiedByWeekByUser,
  exportGamesModifiedByWeekByUser,
} from '../../../../actions/reportByWeekActions';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { CommaNumber_NoDecimal } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import useTableSettings from '../../../../hooks/useTableSettings';
import YearFormSelect from '../YearFormSelect';
import UserLink from '../../../common/links/UserLink';
import { useTranslation } from 'react-i18next';

function GamesModifiedByWeekByUserList({ location, tableViewSwitcher }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const allColumns = ['fullName', 'year'];
  for (let i = 1; i <= 52; i++) {
    allColumns.push(`week${i}`);
  }
  const defaultVisibleColumns = allColumns;
  const [items, setItems] = useState();
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useLocalStorage('report-year', currentYear);
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const years = range(currentYear, currentYear - 5, -1);
  const params = new URLSearchParams(location.search);
  const {
    tableSettings,
    setTableSettings,
    filters,
    filterChange,
    isLoading,
    parameters,
  } = useTableSettings(
    'gamesmodifiedbyweekbyuserlist-settings',
    getTableSettingsDefaults({
      defaultSort: 'fullName',
      defaultVisibleColumns,
    }),
    { year },
    loadGamesModifiedByWeekByUser,
    setItems,
    reload,
    t('Failed to load report'),
    location
  );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'fullName',
      Label: t('Uploading user'),
      Render: (item) => (
        <UserLink id={item.userProfileId} name={item.fullName} imageSize={32} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'year',
      Label: t('Year'),
      Render: (item) => item.year,
    },
  ];
  // add the remaining 52 weeks to the columnsConfig array
  for (let i = 1; i <= 52; i++) {
    columnsConfig.push({
      Key: `week${i}`,
      Label: i.toString(),
      Render: (row) => CommaNumber_NoDecimal(row[`week${i}`]),
    });
  }

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  const handleYearChange = (e) => {
    setYear(e.target.value);
    parameters.current = { ...parameters.current, year: e.target.value };
    setReload(new Date());
  };

  // return an alaska table
  return items ? (
    <>
      <div className="form-horizontal row px-4 px-md-0">
        <div className="col-md-6">
          <YearFormSelect
            year={year}
            years={years}
            handleYearChange={handleYearChange}
          ></YearFormSelect>
        </div>
      </div>
      <KwPanel className="mb-4">
        <Table
          className="table-v-stripes"
          columns={columns}
          allColumns={allColumns}
          rows={items.data.map((x) => {
            return { Key: x.uploadinguserId, ...x };
          })}
          totalRowCount={items.totalRowCount}
          filteredRowCount={items.filteredRowCount}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          isLoading={isLoading}
          filters={filters}
          filterChange={filterChange}
          exportData={(filters, scopes) =>
            exportGamesModifiedByWeekByUser(year, filters, scopes)
          }
          tableActionBarButtons={tableViewSwitcher}
        />
      </KwPanel>
    </>
  ) : (
    <Loading></Loading>
  );
}
export default GamesModifiedByWeekByUserList;
