import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import { NavLink } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import { loadPromotions } from '../../actions/promotionActions';
import { CommaNumber_NoDecimal, hexToRgbA } from '../../util/formatter';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function PromotionCalendar({ location, history }) {
  const [reload, setReload] = useState();
  const { t } = useTranslation();
  const [events, setEvents] = useState();
  const [dateRange, setDateRange] = useState();

  useEffect(() => {
    if (dateRange) {
      loadPromotions({
        filters: {
          promotionDateFrom: dateRange.start,
          promotionDateTo: dateRange.end,
        },
        _sort: '',
        _page: 0,
        _limit: 10000,
      })
        .then((promotions) => {
          if (promotions.data) {
            let data = promotions.data.map((item, index) => ({
              title: item.name,
              start: item.start,
              end: item.end,
              color: item.keyProviderColourHex
                ? hexToRgbA(item.keyProviderColourHex, 0.5)
                : 'rgba(22,190,239,0.5)',
              extendedProps: item,
            }));

            setEvents(data);
          }
        })
        .catch((e) => {
          toast.error(e.message ? e.message : t('Failed to get promotions'));
        });
    }
  }, [dateRange]);

  function renderEventContent(eventInfo) {
    return (
      <>
        <div>
          <b>{eventInfo.event.title}</b>
        </div>
        <div>{eventInfo.event.extendedProps.keyProviderName}</div>
        <div>
          {CommaNumber_NoDecimal(eventInfo.event.extendedProps.numberOfSKUs)}{' '}
          skus
        </div>
      </>
    );
  }

  const handleDates = (rangeInfo) => {
    setDateRange({ start: rangeInfo.startStr, end: rangeInfo.endStr });
  };

  const handleEventClick = (eventClickInfo) => {
    if (eventClickInfo && eventClickInfo.event.extendedProps) {
      history.push(`/promotion/${eventClickInfo.event.extendedProps.id}`);
    }
  };

  return (
    <>
      <ActionBar
        breadcrumb={[
          {
            link: '/promotions',
            label: t('Promotion calendar'),
          },
        ]}
      >
        <NavLink className="btn btn-primary" to={`/promotion/add`}>
          {t('Add promotion')}
        </NavLink>
      </ActionBar>
      <FullCalendar
        plugins={[dayGridPlugin]}
        // headerToolbar={{
        //   left: 'prev,next today',
        //   center: 'title',
        //   right: 'dayGridMonth,timeGridWeek',
        // }}
        initialView="dayGridMonth"
        eventContent={renderEventContent} // custom render function
        events={events}
        datesSet={handleDates}
        eventClick={handleEventClick}
      />
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionCalendar);
