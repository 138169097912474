import {
  apiUrl,
  getList,
  getSingleRecord,
  saveRecord,
  deleteRecord,
  apiOptions,
  handleResponse,
  handleError,
  getStatsData,
  getAll,
  postAction,
  get,
  putAction,
  getAuditData,
  exportData,
  deleteAction,
} from './apiUtils';
const baseUrl = apiUrl + '/workflows/';

export function getWorkflows(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function getAllWorkflows() {
  return getAll(baseUrl);
}
export function getRequestableKeyWorkflows(companyId, gameId, skuId) {
  return get(
    `${baseUrl}requestable/${companyId || ''}?gameId=${gameId || ''}&skuId=${
      skuId || ''
    }`
  );
}
export function getWorkflowById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveWorkflow(workflow) {
  if (workflow.maxKeys === '') {
    workflow.maxKeys = 0;
  }
  if (workflow.maxValue === '') {
    workflow.maxValue = 0;
  }
  if (workflow.maxKeysPerUser === '') {
    workflow.maxKeysPerUser = 0;
  }
  return saveRecord(baseUrl, workflow);
}

export function deleteWorkflow(id) {
  return deleteRecord(baseUrl, id);
}

export function getGamesForWorkflows(id, companyId) {
  return fetch(`${baseUrl}${id}/games/${companyId || ''}`, apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function getStats(id) {
  return getStatsData(baseUrl, id);
}

export function getStatsByDay(id) {
  return get(`${baseUrl}${id}/statsByDay/`);
}

export function getAggregateStats(id, from, to) {
  // Format the from as YYYY-MM-DD
  from = from.toISOString().split('T')[0];
  // Format the to as YYYY-MM-DD
  to = to.toISOString().split('T')[0];

  return get(`${baseUrl}${id}/aggregateStats?fromDate=${from}&toDate=${to}`);
}

export function getAudit(id) {
  return getAuditData(baseUrl, id);
}

export function addAudit(id, comment) {
  return postAction(`${baseUrl}${id}/audit`, { comment });
}

export function cloneWorkflow(id, name, withRestrictions) {
  return postAction(`${baseUrl}${id}/clone`, { name, withRestrictions });
}

export function saveWorkflowSteps(id, steps) {
  return putAction(`${baseUrl}${id}/steps`, { steps });
}

export function getWorkflowStepsById(id) {
  return get(`${baseUrl}${id}/steps`);
}

export function getWorkflowTagsById(id) {
  return get(`${baseUrl}${id}/tags`);
}

export function addWorkflowTag(id, tag) {
  return postAction(`${baseUrl}${id}/tag/`, { tag });
}

export function deleteWorkflowTag(id, tag) {
  return deleteRecord(`${baseUrl}${id}/tag/`, null, { tag });
}

// ==========================================
// REQUESTERS
export function getWorkflowRequestersById(id) {
  return get(`${baseUrl}${id}/requesters`);
}
// by group
export function addWorkflowRequesterGroup(
  id,
  userProfileGroupId,
  companyId,
  userId
) {
  return postAction(
    `${baseUrl}${id}/requester/group/${userProfileGroupId}`,
    null
  );
}
export function deleteWorkflowRequesterGroup(id, userProfileGroupId) {
  return deleteRecord(`${baseUrl}${id}/requester/group/`, userProfileGroupId);
}
// by company
export function addWorkflowRequesterCompany(id, companyId) {
  return postAction(`${baseUrl}${id}/requester/company/${companyId}`, null);
}
export function deleteWorkflowRequesterCompany(id, companyId) {
  return deleteRecord(`${baseUrl}${id}/requester/company/`, companyId);
}
// by user
export function addWorkflowRequesterUser(id, userId) {
  return postAction(`${baseUrl}${id}/requester/user/${userId}`, null);
}
export function deleteWorkflowRequesterUser(id, userId) {
  return deleteRecord(`${baseUrl}${id}/requester/user/`, userId);
}

export function exportWorkflowData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/workflows/search`,
    'keyWorkflowName',
    filters,
    scopes
  );
}

// ==========================================
// KEY POOLS
export function getKeyPoolBalance(keyWorkflowId, companyId, gameId, skuId) {
  return get(
    `${baseUrl}keypoolbalance/${gameId}/${skuId}/${keyWorkflowId}/${
      companyId || ''
    }`
  );
}

export function getApproverNotificationUsers(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}notification/approver/users`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportApproverNotificationUsers(filters, scopes) {
  return exportData(
    `${apiUrl}/export/workflows/notification/approver/users`,
    'keyWorkflowName',
    filters,
    scopes
  );
}

export function sendApproverNotification(keyWorkflowStepId) {
  return postAction(
    `${baseUrl}notification/approver/notify/${keyWorkflowStepId}`,
    {}
  );
}

export function addKeyWorkflowStepNotificationFilter(
  keyWorkflowStepId,
  userProfileId
) {
  return postAction(`${baseUrl}notification/approver/userfilter`, {
    keyWorkflowStepId,
    userProfileId,
  });
}

export function deleteKeyWorkflowStepNotificationFilter(
  keyWorkflowStepId,
  userProfileId
) {
  return deleteAction(
    `${baseUrl}notification/approver/userfilter/${keyWorkflowStepId}/${userProfileId}`
  );
}
