import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { useTranslation } from 'react-i18next';

import {
  saveTerritory,
  getTerritoryById,
} from '../../../../actions/territoryActions';
import { toast } from 'react-toastify';
import TerritoryForm from '../components/TerritoryForm';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '../../../common/Button';

function Edit({ done, id, cancel, saveTerritory, getTerritoryById }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = (data) => {
    saveTerritory({ ...territory, ...data })
      .then(() => {
        toast.success(t('Territory saved'));
        done(territory);
      })
      .catch(() => {
        toast.error(t('Failed to save territory'));
      });
  };

  const [territory, setTerritory] = useState({});

  useEffect(() => {
    if (id) {
      getTerritoryById(id).then((d) => {
        setTerritory(d);
      });
    }
  }, [id, getTerritoryById]);

  return (
    <SlideoutLayout
      title={id ? t('Edit territory') : t('Add territory')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="submit"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <TerritoryForm
        territory={territory}
        register={register}
        errors={errors}
        control={control}
      ></TerritoryForm>
    </SlideoutLayout>
  );
}

Edit.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  saveTerritory: PropTypes.func.isRequired,
  getTerritoryById: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    territory: state.territory,
  };
}

const mapDispatchToProps = {
  saveTerritory,
  getTerritoryById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
