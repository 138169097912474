import React from 'react';
import KwPanel from '../../../common/KwPanel';
import FormStatic from '../../../common/FormStatic';
import PropTypes from 'prop-types';
import icons from '../../../../constants/icons';
import Button from '../../../common/Button';
import { useTranslation } from 'react-i18next';

const KeyProviderRequestInformation = ({
  keyProvider,
  requestInformation,
  handleEditInternalRequestInformation,
}) => {
  const { t } = useTranslation();
  return keyProvider && requestInformation ? (
    <KwPanel className="mb-4 pb-1">
      <div className="row">
        <div className="col">
          <header className="mb-4">
            <h2>{t('Request information')}</h2>
            <Button
              onClick={handleEditInternalRequestInformation}
              className="btn btn-default"
              icon={icons.EDIT}
              text={t('Edit internal request information')}
              hideTitle={true}
            />
          </header>
          {requestInformation.map((item) => (
            <FormStatic label={item.type} key={`i_${item.id}`}>
              {item.internalSite ? (
                <label className="badge bg-success">{t('Visible')}</label>
              ) : (
                <label className="badge bg-danger">{t('Hidden')}</label>
              )}
            </FormStatic>
          ))}
        </div>
      </div>
    </KwPanel>
  ) : (
    ''
  );
};

KeyProviderRequestInformation.propTypes = {
  keyProvider: PropTypes.object.isRequired,
  requestInformation: PropTypes.array.isRequired,
  handleEditInternalRequestInformation: PropTypes.func.isRequired,
};

export default KeyProviderRequestInformation;
