import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { loadPromotions } from '../../../actions/promotionActions';
import { NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';

import { hexToRgbA, Date_AsString } from '../../../util/formatter';

import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import { useTranslation } from 'react-i18next';

function PromotionList({ location, reload, keyProviders, scope }) {
  const allColumns = [
    'name',
    'keyProviderName',
    'start',
    'end',
    'numberOfSKUs',
  ];
  const defaultVisibleColumns = allColumns.filter((x) => x !== 'todo');
  const { t } = useTranslation();
  const [promotions, setPromotions] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'promotionlist-settings',
      getTableSettingsDefaults({
        defaultSort: 'start',
        defaultVisibleColumns,
      }),
      null,
      loadPromotions,
      setPromotions,
      reload,
      t('Failed to load promotions'),
      location,
      scope
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Promotion'),
      className: 'flush',
      Render: (item) => (
        <div
          className="p-2 d-flex align-items-center promotion-td"
          style={
            item.keyProviderColourHex
              ? {
                  borderLeft: `3px solid ${item.keyProviderColourHex}`,
                }
              : null
          }
        >
          <NavLink to={`/promotion/${item.id}`}>{item.name}</NavLink>
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyProviderName',
      Label: t('Key provider'),
      Filter: {
        Render: (col) => (
          <select
            className="form-control"
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('--- All ---')}</option>
            {keyProviders &&
              [...keyProviders]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((x) => (
                  <option key={x.id} value={x.name}>
                    {x.name}
                  </option>
                ))}
          </select>
        ),
      },
    },
    {
      Key: 'start',
      Label: t('Start'),
      Render: (item) => Date_AsString(item.start),
    },
    {
      Key: 'end',
      Label: t('End'),
      Render: (item) => Date_AsString(item.end),
    },
    {
      Key: 'numberOfSKUs',
      Label: t('Number of SKUs'),
      Render: (item) => item.numberOfSKUs,
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const suffixColumnsWithConfig = [];
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return promotions ? (
    <KwPanel>
      <Table
        columns={columns}
        suffixColumns={suffixColumnsWithConfig}
        allColumns={allColumns}
        rows={promotions.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={promotions.totalRowCount}
        filteredRowCount={promotions.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filterChange={filterChange}
        trStyle={(row) => {
          return {
            backgroundColor: `${hexToRgbA(row.keyProviderColourHex, 0.0375)}`,
          };
        }}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

PromotionList.propTypes = {
  location: PropTypes.object.isRequired,
  reload: PropTypes.bool,
  keyProviders: PropTypes.array,
  scope: PropTypes.string,
};

export default PromotionList;
