import React, { useState } from 'react';
import { loadDisallowedSkus } from '../../../../actions/territoryActions';
import { NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import AutoSelectText from '../../../common/AutoSelectText';
import useTableSettings from '../../../../hooks/useTableSettings';
import { useTranslation } from 'react-i18next';

function DisallowedSkuList({ location, reload, territoryId }) {
  const { t } = useTranslation();
  const allColumns = [
    'name',
    'platform',
    'steamRef',
    'packageId',
    'packageType',
  ];
  const defaultVisibleColumns = allColumns;
  const [games, setGames] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'dissalowedskulist-settings',
      getTableSettingsDefaults({ defaultSort: 'name', defaultVisibleColumns }),
      { territoryId },
      loadDisallowedSkus,
      setGames,
      reload,
      t('Failed to load skus'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Name'),
      Render: (item) => (
        <>
          <NavLink to={`/sku/${item.id}`} className="game-title">
            {item.gameName}
          </NavLink>
          <br />
          <NavLink to={`/sku/${item.id}`}>{item.name}</NavLink>
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'platform',
      Label: t('Platform'),
      Render: (item) => item.platform,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'steamRef',
      Label: t('Product code'),
      className: 'autoselect',
      Render: (item) => <AutoSelectText>{item.steamRef || ''}</AutoSelectText>,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'packageId',
      Label: t('Package Id'),
      className: 'autoselect',
      Render: (item) => <AutoSelectText>{item.packageId}</AutoSelectText>,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'packageType',
      Label: t('Package type'),
      Render: (item) => item.packageId,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return games ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={games.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={games.totalRowCount}
        filteredRowCount={games.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filterChange={filterChange}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default DisallowedSkuList;
