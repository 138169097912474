import React, { useEffect, useState } from 'react';

import {
  loadApproverNotificationUsers,
  exportApproverNotificationUsers,
  sendApproverNotification,
} from '../../../../actions/workflowActions';
import { getMembers } from '../../../../actions/userProfileGroupActions';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import ApproverNotificationUsers from './ApproverNotificationUsers';
import icons from '../../../../constants/icons';
import Button from '../../../common/Button';
import KebabMenu from '../../../common/KebabMenu';
import { toast } from 'react-toastify';
import SlidingPane from 'react-sliding-pane';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import Gravatar from 'react-gravatar';
import WorkflowLink from '../../../common/links/WorkflowLink';
import { useTranslation } from 'react-i18next';

function ApproverNotificationUsersList({
  title,
  helpPanel,
  location,
  reload,
  ownUserOnly,
  scope,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'keyWorkflowName',
    'keyWorkflowStepGroupName',
    'keyWorkflowStepSequence',
    'approvers',
  ];
  let suffixColumns = ownUserOnly ? [] : ['id'];
  const defaultVisibleColumns = allColumns;
  const [approverNotificationUsers, setApproverNotificationUsers] = useState();
  const [open, setOpen] = useState(false);
  const [userProfileGroupId, setUserProfileGroupId] = useState();
  const [members, setMembers] = useState();
  useEffect(() => {
    if (userProfileGroupId) {
      getMembers(userProfileGroupId)
        .then((data) => {
          setMembers(data.data);
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to load members'));
        });
    }
  }, [userProfileGroupId]);

  const handleSendEmails = (e, row) => {
    sendApproverNotification(row.keyWorkflowStepId)
      .then(() => {
        toast.success(t('Notifications sent'));
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to send notifications'));
      });
    return false;
  };

  const handleShowMembers = (item) => {
    setOpen(true);
    if (item.userProfileGroupId === userProfileGroupId) return;
    setMembers();
    setUserProfileGroupId(item.userProfileGroupId);
  };

  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'v2-approverNotificationUsers-settings',
      getTableSettingsDefaults({
        defaultSort: 'keyWorkflowName',
        defaultVisibleColumns,
      }),
      {},
      loadApproverNotificationUsers,
      setApproverNotificationUsers,
      reload,
      t('Failed to load approvers'),
      location,
      scope
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'keyWorkflowName',
      Label: t('Workflow'),
      width: '10%',
      Render: (item) => (
        <WorkflowLink
          id={item.keyWorkflowId}
          key={item.keyWorkflowId}
          name={item.keyWorkflowName}
          colour={item.keyWorkflowColourHex}
        ></WorkflowLink>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyWorkflowStepGroupName',
      Label: t('Step'),
      width: '10%',
      Render: (item) => (
        <span className="a" onClick={() => handleShowMembers(item)}>
          {item.keyWorkflowStepGroupName}
        </span>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyWorkflowStepSequence',
      Label: t('Step'),
      width: '5%',
      Render: (item) => item.keyWorkflowStepSequence,
    },
    {
      Key: 'approvers',
      Label: t('Users who receive notification'),
      className: 'no-navigate',
      Render: (item) => (
        <ApproverNotificationUsers
          keyWorkflowStepId={item.keyWorkflowStepId}
          approvers={item.approvers}
          notificationFilters={item.notificationFilters}
          ownUserOnly={ownUserOnly}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'id',
      width: '5%',
      Label: '',
      className: 'text-right',
      Sort: false,
      Render: (row) => (
        <>
          {!ownUserOnly && (
            <KebabMenu
              take={1}
              className="inline-icons"
              buttons={[
                <Button
                  key="send-emails-button"
                  className="btn btn-default me-2"
                  onClick={(e) => handleSendEmails(e, row)}
                  icon={icons.SEND_EMAILS}
                  text={t('Send email to approvers')}
                  title={t(
                    'Send an email to all approvers who are enabled to receive notifications'
                  )}
                ></Button>,
              ]}
            ></KebabMenu>
          )}
        </>
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return approverNotificationUsers ? (
    <>
      <KwPanel className="mb-4">
        {title && (
          <h2>
            <header className="mb-2">
              <h2 className="no-margin">{title}</h2>
            </header>
          </h2>
        )}
        {helpPanel || <></>}
        <Table
          columns={columns}
          suffixColumns={suffixColumnsWithConfig}
          allColumns={allColumns}
          defaultVisibleColumns={defaultVisibleColumns}
          rows={approverNotificationUsers.data.map((x) => {
            return { Key: x.keyWorkflowStepId, ...x };
          })}
          totalRowCount={approverNotificationUsers.totalRowCount}
          filteredRowCount={approverNotificationUsers.filteredRowCount}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          isLoading={isLoading}
          filters={filters}
          filterChange={filterChange}
          exportData={exportApproverNotificationUsers}
          scopes={approverNotificationUsers.scopes}
          location={location}
          hideScopeTags={scope}
        />
      </KwPanel>
      <SlidingPane
        isOpen={open}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setOpen(false)}
      >
        <SlideoutLayout
          title={t('Approval group')}
          cancel={() => setOpen(false)}
          done={() => setOpen(false)}
        >
          <KwPanel className="mb-4">
            {members?.map((x) => (
              <div key={x.username} className="mb-3 d-flex align-items-center">
                <Gravatar
                  email={x.username}
                  size={24}
                  rating="pg"
                  default="retro"
                  className="img-circle"
                  style={{
                    border: '2px solid #ffffff',
                    overflow: 'hidden',
                  }}
                />
                <span className="ms-3">
                  {x.fullName} ({x.company})
                </span>
              </div>
            ))}
          </KwPanel>
        </SlideoutLayout>
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}
export default ApproverNotificationUsersList;
