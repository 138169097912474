import React from 'react';
import KwPanel from '../../common/KwPanel';
import FormStatic from '../../common/FormStatic';
import {
  CommaNumber_NoDecimal,
  Date_AsString_NoHtml,
} from '../../../util/formatter';
import TransactionStatusBadge from './TransactionStatusBadge';
import KeyProviderLink from '../../common/links/KeyProviderLink';
import UserLink from '../../common/links/UserLink';
import YesNoLabel from '../../common/YesNoLabel';
import WorkflowLink from '../../common/links/WorkflowLink';
import CompanyLink from '../../common/links/CompanyLink';
import GameLink from '../../common/links/GameLink';
import RequestGroupLink from '../../common/links/RequestGroupLink';
import { useTranslation } from 'react-i18next';

const ReportedDisabledTransactionDetails = ({ transaction, currentUser }) => {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4">
      <header className="mb-2">
        <h2 className="no-margin ">{t('Disabled details')}</h2>
      </header>
      <div className="row">
        <div className="col">
          <FormStatic label={t('Target key provider')}>
            <KeyProviderLink
              id={transaction.keyProviderId}
              name={transaction.keyProviderName}
            />
          </FormStatic>
          {transaction.targetAccountDetailId && (
            <FormStatic label={t('Target account')}>
              {transaction.targetAccountDetailName}
            </FormStatic>
          )}
          {transaction.targetCompanyId && (
            <FormStatic label={t('Target company')}>
              <CompanyLink
                id={transaction.targetCompanyId}
                name={transaction.targetCompanyName}
              />
            </FormStatic>
          )}
          {transaction.targetGameId && !transaction.targetGameSKUId && (
            <FormStatic label={t('Target game')}>
              <GameLink
                gameId={transaction.targetGameId}
                gameTitle={transaction.targetGameName}
              />
            </FormStatic>
          )}
          {transaction.targetGameSKUId && (
            <FormStatic label={t('Target SKU')}>
              <GameLink
                gameId={transaction.targetGameId}
                gameTitle={transaction.targetGameName}
                skuId={transaction.targetGameSKUId}
                skuName={transaction.targetGameSKUName}
                currentUser={currentUser}
              />
            </FormStatic>
          )}
          {transaction.targetKeyRequestId &&
            transaction.targetKeyRequestGroupId && (
              <FormStatic label={t('Target request')}>
                <RequestGroupLink
                  requestId={transaction.targetKeyRequestId}
                  requestReference={transaction.targetKeyRequestReference}
                  requestGroupId={transaction.targetKeyRequestGroupId}
                  requestGroupReference={
                    transaction.targetKeyRequestGroupReference
                  }
                />
              </FormStatic>
            )}
          {transaction.targetKeyWorkflowId && (
            <FormStatic label={t('Target key workflow')}>
              <WorkflowLink
                id={transaction.targetKeyWorkflowId}
                name={transaction.targetKeyWorkflowName}
                colour={transaction.targetKeyWorkflowColourHex}
              />
            </FormStatic>
          )}
          {transaction.targetRequestingUserProfileId && (
            <FormStatic label={t('Target user')}>
              <UserLink
                id={transaction.targetRequestingUserProfileId}
                name={transaction.targetRequestingUserProfileFullName}
              />
            </FormStatic>
          )}

          <FormStatic label={t('Target statuses')}>
            <div>
              <div>
                {t('Activated')} :{' '}
                <YesNoLabel value={transaction.targetIsActivated} />
              </div>
              <div>
                {t('Allocated')} :{' '}
                <YesNoLabel value={transaction.targetIsAllocated} />
              </div>
              <div>
                {t('Available')} :{' '}
                <YesNoLabel value={transaction.targetIsAvailable} />
              </div>
              <div>
                {t('Expired')} :{' '}
                <YesNoLabel value={transaction.targetIsExpired} />
              </div>
              <div>
                {t('Sold')} : <YesNoLabel value={transaction.targetIsSold} />
              </div>
            </div>
          </FormStatic>
          {transaction.description && (
            <FormStatic label={t('Uploaded file description')}>
              {transaction.description}
            </FormStatic>
          )}
          {transaction.reasons && transaction.reasons.length > 0 && (
            <FormStatic label={t('Reason')}>
              {transaction.reasons?.map((x) => (
                <span key={x} className="badge bg-info me-2">
                  {x}
                </span>
              ))}
            </FormStatic>
          )}
          <FormStatic label={t('More information')}>
            {transaction.reason}
          </FormStatic>
        </div>
        <div className="col">
          <FormStatic label={t('Date requested')}>
            {Date_AsString_NoHtml(transaction.creationDate)}
          </FormStatic>
          <FormStatic label={t('Transaction status')}>
            <TransactionStatusBadge
              success={transaction.success}
              partialSuccess={transaction.partialSuccess}
              message={transaction.message}
              status={transaction.status}
            ></TransactionStatusBadge>
          </FormStatic>
          <FormStatic label={t('Total keys')} className="num no-wrap">
            {transaction.batchCount >= 0
              ? CommaNumber_NoDecimal(transaction.batchCount)
              : '-'}
          </FormStatic>
          <FormStatic label={t('Requested by')}>
            <>
              <UserLink
                id={transaction.manualImportUserId}
                name={transaction.manualImportUser}
              />
              , {transaction.manualImportUserCompany}
            </>
          </FormStatic>
        </div>
      </div>
    </KwPanel>
  );
};

export default ReportedDisabledTransactionDetails;
