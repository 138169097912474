import React from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import KwPanel from './KwPanel';
import icons from '../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CollapsePanel = ({ children, header, storageKey }) => {
  const [expanded, setExpanded] = useLocalStorage(storageKey, false);

  return (
    <KwPanel className="mb-4 p-0">
      <header
        className={
          expanded ? 'space-bottom clickable p-4' : 'clickable collapsed p-4'
        }
        onClick={() => setExpanded(!expanded)}
      >
        <div className="d-flex align-items-center w-100">
          <div className="d-flex align-items-center w-100">{header}</div>
          <div className="flex-1 text-right">
            {expanded ? (
              <FontAwesomeIcon
                icon={icons.ACCORDIAN_OPENED}
                className={`ps-4`}
              ></FontAwesomeIcon>
            ) : (
              <FontAwesomeIcon
                icon={icons.ACCORDIAN_CLOSED}
                className={`ps-4`}
              ></FontAwesomeIcon>
            )}
          </div>
        </div>
      </header>
      <div className={expanded ? 'row px-4 pb-4' : 'hidden'}>{children}</div>
    </KwPanel>
  );
};

export default CollapsePanel;
