import React from 'react';
import FormGroup from './FormGroup';
const FormButtonGroup = ({
  buttons,
  name,
  label,
  error,
  errorMessage,
  required,
  extraRowClasses = 'align-items-start',
  onChange,
  value,
  register,
  toggleGroupClassName,
  helpContent,
}) => {
  return (
    <FormGroup
      label={label}
      error={error}
      errorMessage={errorMessage}
      required={required}
      extraRowClasses={extraRowClasses}
      helpContent={helpContent}
    >
      <div className={`toggle-group ${toggleGroupClassName}`}>
        {buttons.map((btn) => {
          return typeof btn === 'object' ? (
            <label
              key={btn.value}
              className={value === btn.value ? 'checked' : ''}
              onClick={() => onChange(btn)}
            >
              {btn.text}
            </label>
          ) : (
            <label
              key={btn}
              className={value === btn ? 'checked' : ''}
              onClick={() => onChange(btn)}
            >
              {btn}
            </label>
          );
        })}
      </div>
    </FormGroup>
  );
};
export default FormButtonGroup;
