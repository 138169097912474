import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActionBar from '../layout/ActionBar';
import RequestKeys from './slideout/RequestKeys';
import { userIs } from '../auth/authUtils';
import userTypes from '../../constants/userTypes';
import SlidingPane from 'react-sliding-pane';
import { addToGroup, removeFromGroup } from '../../actions/signalRActions';
import { Trans, useTranslation } from 'react-i18next';

import RequestsTable from './components/RequestsTable';
import Button from '../common/Button';

function Requests({
  location,
  history,
  currentUser,
  setCompleteRequestSlideoutOpen,
  basket,
}) {
  const [reload, setReload] = useState();
  const { t } = useTranslation();

  const [requestKeysSlideoutOpen, setRequestKeysSlideoutOpen] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState([
    { label: t('Requests'), link: '/requests/all' },
  ]);
  return (
    <>
      {basket && basket.basketCount > 0 && (
        <div
          tabIndex={0}
          className="alert alert-basket twinkle flex-shrink-0"
          onClick={() => setCompleteRequestSlideoutOpen(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setCompleteRequestSlideoutOpen(true);
            }
          }}
        >
          {basket.basketCount > 1 ? (
            <Trans
              i18nKey="basketCount"
              components={{ bold: <b /> }}
              values={{ basketCount: basket.basketCount }}
            >
              You have <bold>{basket.basketCount}</bold> requests to complete in
              your basket.
            </Trans>
          ) : (
            <Trans i18nKey="oneRequestToComplete" components={{ bold: <b /> }}>
              You have <bold>1</bold> request to complete in your basket.
            </Trans>
          )}
        </div>
      )}
      {basket && basket.submittingCount > 0 && (
        <div className="alert alert-basket twinkle flex-shrink-0">
          {basket.submittingCount > 1 ? (
            <Trans
              i18nKey="submittingCount"
              components={{ bold: <b /> }}
              values={{ submittingCount: basket.submittingCount }}
            >
              You have <bold>{basket.submittingCount}</bold> requests being
              processed.
            </Trans>
          ) : (
            <Trans
              i18nKey="oneRequestBeingProcessed"
              components={{ bold: <b /> }}
            >
              You have <bold>1</bold> request being processed.
            </Trans>
          )}
        </div>
      )}
      <ActionBar breadcrumb={breadcrumb}>
        {userIs(currentUser, userTypes.REQUESTER) && (
          <Button
            className="btn btn-primary ms-2"
            onClick={() => setRequestKeysSlideoutOpen(true)}
            text={t('Request keys')}
          />
        )}
      </ActionBar>

      <RequestsTable
        location={location}
        history={history}
        setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
        setBreadcrumb={setBreadcrumb}
        reload={reload}
        setReload={setReload}
      />

      <SlidingPane
        isOpen={requestKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRequestKeysSlideoutOpen(false)}
      >
        <RequestKeys
          done={() => {
            setRequestKeysSlideoutOpen(false);
            setCompleteRequestSlideoutOpen(true);
          }}
          cancel={() => setRequestKeysSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    currentUser: state.auth,
    importTransactions: state.importTransactions,
    basket: state.basket,
  };
}

const mapDispatchToProps = {
  addToGroup,
  removeFromGroup,
};

Requests.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCompleteRequestSlideoutOpen: PropTypes.func.isRequired,
  basket: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
