export function now() {
  return new Date();
}

export function nowMinusDays(days) {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date;
}

export function nowPlusDays(days) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}
