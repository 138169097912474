import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import KwPanel from '../../common/KwPanel';

import { withdrawRequest } from '../../../actions/requestActions';
import { toast } from 'react-toastify';
import Button from '../../common/Button';
import ConfirmationHeader from '../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function Withdraw({ done, id, cancel }) {
  const [processing, setProcessing] = useState();
  const { t } = useTranslation();

  const handleWithdraw = () => {
    setProcessing(true);
    withdrawRequest(id)
      .then(() => {
        toast.success(t('Request successfully withdrawn'));
        done();
        setProcessing(false);
      })
      .catch((e) => {
        toast.error(
          t('Failed to withdraw request {message}', { message: e.message })
        );
        setProcessing(false);
      });
  };

  return (
    <SlideoutLayout title={t('Withdraw request')} cancel={cancel} done={done}>
      <KwPanel>
        <ConfirmationHeader text={t("Are you sure? This can't be undone")} />
        <Button
          className={`btn btn-danger me-2`}
          isDisabled={processing}
          onClick={handleWithdraw}
          text={t('Yes, withdraw the request')}
        />
        <Button
          className="btn btn-default"
          isDisabled={processing}
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
Withdraw.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default Withdraw;
