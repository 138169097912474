import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LeftNavWrapper from '../menu-components/LeftNavWrapper';
import LeftMenuItem from '../menu-components/LeftMenuItem';
import EditGame from '../../game/slideout/EditGame';
import permissionTypes from '../../../constants/permissionTypes';
import SlidingPane from 'react-sliding-pane';
import icons from '../../../constants/icons';
import NavColours from '../../../constants/NavColours';
import { useTranslation } from 'react-i18next';

function LeftMenu_Game({ currentUser, wrapperClassName }) {
  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const addGame = (e) => {
    e.cancelBubble = true;
    e.stopPropagation();
    setAddSlideoutOpen(true);
    return false;
  };
  const { t } = useTranslation();
  return (
    <>
      <LeftNavWrapper className={wrapperClassName}>
        <LeftMenuItem
          url="/games"
          activeRegex="^\/(game|sku)"
          icon={icons.GAME}
          colour={NavColours.NAV_COLOUR_1}
          title={t('All games')}
          currentUser={currentUser}
        />
        <LeftMenuItem
          url="/game"
          activeRegex="^\/game$"
          icon={icons.ADD}
          colour={NavColours.NAV_COLOUR_1}
          title={t('Add game')}
          onClick={addGame}
          currentUser={currentUser}
          allowedPermissions={[permissionTypes.MANAGE_GAMES]}
        />
      </LeftNavWrapper>

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <EditGame
          cancel={() => setAddSlideoutOpen(false)}
          done={(d) => {
            setAddSlideoutOpen(false);
          }}
        />
      </SlidingPane>
    </>
  );
}

LeftMenu_Game.propTypes = {
  currentUser: PropTypes.object,
};

export default LeftMenu_Game;
