import { apiUrl, apiOptions, handleError } from './apiUtils';
export const baseUrl = apiUrl + '/images/';

export function getImageById(id) {
  return fetch(`${baseUrl}${id}`, apiOptions())
    .then(async (res) => {
      if (res.ok) {
        return await res.blob();
      } else {
        throw new Error('Network response was not ok.');
      }
    })
    .catch(handleError);
}
