import React, { useState, useEffect } from 'react';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { getEmailById, resendEmail } from '../../../../actions/emailActions';
import EmailForm from '../components/EmailDetails';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Button from '../../../common/Button';
import { useTranslation } from 'react-i18next';

function View({ id, done, cancel }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState();

  const handleResendEmail = () => {
    resendEmail(id)
      .then(() => {
        toast.success(t('Email successfully scheduled to be resent'));
        done();
      })
      .catch(() => {
        toast.error(t('Failed to resend email'));
      });
  };

  useEffect(() => {
    if (id) {
      getEmailById(id).then((d) => {
        setEmail(d);
      });
    }
  }, [id]);

  return (
    <SlideoutLayout
      title={t('Email details')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          className="btn btn-small btn-primary"
          onClick={handleResendEmail}
          text={t('Resend')}
        />
      }
    >
      {email && <EmailForm email={email}></EmailForm>}
    </SlideoutLayout>
  );
}

View.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default View;
