import React, { useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import store from './store';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './theme1.css';
import permissionTypes from './constants/permissionTypes';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fass } from '@fortawesome/sharp-solid-svg-icons';
import { fasr } from '@fortawesome/sharp-regular-svg-icons';
import { fasl } from '@fortawesome/sharp-light-svg-icons';
import { fast } from '@fortawesome/sharp-thin-svg-icons';

import SigninOidc from './components/auth/signin-oidc';
import SignoutOidc from './components/auth/signout-oidc';
import PrivateRoute from './components/auth/PrivateRoute';
import hasPermission from './components/auth/hasPermission';

import MustAuth from './components/auth/MustAuth';
import BigLoader from './components/layout/BigLoader';
import Layout from './components/Layout';

import Home from './components/home/Home';
import Oops from './components/Oops';
import AccessDenied from './components/AccessDenied';
import ApiFailure from './components/ApiFailure';
import NotFound from './components/NotFound';
import TrialEnded from './components/TrialEnded';

import Companies from './components/company/Companies';
import Company from './components/company/Company';

import Announcements from './components/settings/announcement/Announcements';
import Announcement from './components/settings/announcement/Announcement';

import CompanyTypes from './components/settings/company-type/CompanyTypes';
import CompanyType from './components/settings/company-type/CompanyType';

import Games from './components/game/Games';
import Game from './components/game/Game';

import Promotions from './components/promotion/Promotions';
import PromotionCalendar from './components/promotion/PromotionCalendar';
import Promotion from './components/promotion/Promotion';

import Sku from './components/sku/Sku';

import Settings from './components/settings/Settings';

import Keys from './components/keys/Keys';
import Accounts from './components/keys/Accounts';
import Account from './components/keys/Account';
import ReportedDisabled from './components/keys/ReportedDisabled';
import ReportedActivated from './components/keys/ReportedActivated';
import ReportedSold from './components/keys/ReportedSold';
import IngestedBatches from './components/keys/IngestedBatches';
import BulkIngested from './components/keys/BulkIngested';

import Emails from './components/settings/email/Emails';

import GameTemplates from './components/settings/game-template/GameTemplates';
import GameTemplate from './components/settings/game-template/GameTemplate';

import KeyPoolNotifications from './components/settings/key-pool-notification/KeyPoolNotifications';
import KeyPoolNotification from './components/settings/key-pool-notification/KeyPoolNotification';

import PackageTypes from './components/settings/package-type/PackageTypes';
import PackageType from './components/settings/package-type/PackageType';

import Platforms from './components/settings/platform/Platforms';
import Platform from './components/settings/platform/Platform';

import Territories from './components/settings/territory/Territories';
import Territory from './components/settings/territory/Territory';

import KeyProviders from './components/settings/key-provider/KeyProviders';
import KeyProvider from './components/settings/key-provider/KeyProvider';

import Users from './components/settings/users/Users';
import User from './components/settings/users/User';
import UsersInGroup from './components/settings/users/UsersInGroup';

import Workflows from './components/settings/workflow/Workflows';
import Workflow from './components/settings/workflow/Workflow';

import ApproverNotificationUsers from './components/settings/workflow/ApproverNotificationUsers';

import RequestsBySKU from './components/reports/RequestsBySKU';
import RequestsByGame from './components/reports/RequestsByGame';
import RequestsByCompany from './components/reports/RequestsByCompany';
import KeysBySku from './components/reports/KeysBySku';
import KeysByCompany from './components/reports/KeysByCompany';
import SLAsByCompanies from './components/reports/SLAsByCompanies';
import SLAsByGame from './components/reports/SLAsBySku';
import SLAsByWorkflows from './components/reports/SLAsByWorkflows';

import AllowedSkus from './components/settings/territory/AllowedSkus';
import DisallowedSkus from './components/settings/territory/DisallowedSkus';

import Request from './components/requests/Request';
import Requests from './components/requests/Requests';
import RequestsAssignedToMe from './components/requests/RequestsAssignedToMe';
import RequestsMadeByMe from './components/requests/RequestsMadeByMe';
import RequestsToBeApproved from './components/requests/RequestsToBeApproved';
import RequestsBulk from './components/requests/Bulk';

import UserProfileGroup from './components/settings/user-profile-group/UserProfileGroup';
import UserProfileGroups from './components/settings/user-profile-group/UserProfileGroups';

import Uploaders from './components/settings/uploader/Uploaders';

import DisableReasons from './components/settings/disabled-reasons/DisableReasons';
import DisableReason from './components/settings/disabled-reasons/DisableReason';

import UploadGames from './components/settings/upload-games/UploadGames';
import GameKeys from './components/keys/GameKeys';
import KeyPools from './components/keys/KeyPools';
import ReportedActivatedTransaction from './components/keys/ReportedActivatedTransaction';
import ReportedDisabledTransaction from './components/keys/ReportedDisabledTransaction';
import ReportedSoldTransaction from './components/keys/ReportedSoldTransaction';
import IngestedTransaction from './components/keys/IngestedTransaction';
import Permissions from './components/settings/permissions/Permissions';
import SilentRenewOidc from './components/auth/silent-renew-oidc';
import KeySearch from './components/keys/KeySearch';
import userTypes from './constants/userTypes';
import { ErrorBoundary } from 'react-error-boundary';
import DownloadKeyCode from './components/public/DownloadKeyCode';
import MovedTransactions from './components/keys/MovedTransactions';
import MovedTransaction from './components/keys/MovedTransaction';
import MoveKeysBetweenRequests from './components/keys/MoveKeysBetweenRequests';
import RequestsByAuthorisingUser from './components/reports/RequestsByAuthorisingUser';
import RequestsByUploadingUser from './components/reports/RequestsByUploadingUser';
import WorkflowsByRequesters from './components/reports/WorkflowsByRequesters';
import WorkflowsByApprovers from './components/reports/WorkflowsByApprovers';
import KeysByGame from './components/reports/KeysByGame';
import PromotionsGrid from './components/promotion/PromotionsGrid';
import KeysByUploadingUser from './components/reports/KeysByUploadingUser';
import KeysByAuthorisingUser from './components/reports/KeysByAuthorisingUser';
import UsersModifiedByUser from './components/reports/UsersModifiedByUser';
import KeyWorkflowsModifiedByUser from './components/reports/KeyWorkflowsModifiedByUser';
import GamesModifiedByUser from './components/reports/GamesModifiedByUser';
import SKUsModifiedByUser from './components/reports/SKUsModifiedByUser';
import RequestsByWorkflow from './components/reports/RequestsByWorkflow';
import KeysByWorkflow from './components/reports/KeysByWorkflow';
import HealthChecks from './components/settings/health-check/HealthChecks';

const App = () => {
  const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
  const [classes, setClasses] = useState();
  const [completeRequestSlideoutOpen, setCompleteRequestSlideoutOpen] =
    useState(false);

  library.add(fas, fal, far, fat, fad, fass, fasr, fasl, fast, fab);

  return (
    <ReduxProvider store={store}>
      <BrowserRouter basename={baseUrl}>
        <ErrorBoundary FallbackComponent={Oops}>
          <Switch>
            <Route exact path="/not-found">
              <NotFound />
            </Route>
            <Route exact path="/access-denied" component={AccessDenied} />
            <Route exact path="/error" component={Oops} />
            <Route exact path="/api-failure" component={ApiFailure} />
            <Route exact path="/trial-ended" component={TrialEnded} />
            <Route exact path="/signout-oidc" component={SignoutOidc} />
            <Route exact path="/signin-oidc" component={SigninOidc} />
            <Route
              exact
              path="/silent-renew-oidc"
              component={SilentRenewOidc}
            />
            <Route
              exact
              path="/download-key-code/:keyRequestId/:accountId"
              component={DownloadKeyCode}
            />

            <Route>
              <MustAuth>
                <Layout
                  classes={classes}
                  completeRequestSlideoutOpen={completeRequestSlideoutOpen}
                  setCompleteRequestSlideoutOpen={
                    setCompleteRequestSlideoutOpen
                  }
                >
                  <ToastContainer
                    autoClose="3000"
                    hideProgressBar
                    position={toast.POSITION.TOP_CENTER}
                  />
                  <Switch>
                    <PrivateRoute exact path="/" component={Home} />
                    <PrivateRoute
                      exact
                      path="/games"
                      component={Games}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute
                      exact
                      path="/game/add"
                      component={Game}
                      allowedPermissions={[permissionTypes.MANAGE_GAMES]}
                    />
                    <PrivateRoute
                      exact
                      path="/game/:id"
                      component={Game}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute
                      exact
                      path="/sku/:id"
                      component={Sku}
                      isPowerUser={true}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute
                      exact
                      path="/request/:id"
                      component={Request}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute exact path="/requests" component={Requests} />
                    <PrivateRoute
                      exact
                      path="/requests/all"
                      component={Requests}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute
                      exact
                      path="/requests/assigned-to-me"
                      component={RequestsAssignedToMe}
                      allowedUserTypes={[
                        userTypes.APPROVER,
                        userTypes.UPLOADER,
                      ]}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute
                      exact
                      path="/requests/made-by-me"
                      component={RequestsMadeByMe}
                      allowedUserTypes={[userTypes.REQUESTER]}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute
                      exact
                      path="/requests/approve"
                      component={RequestsToBeApproved}
                      allowedUserTypes={[userTypes.APPROVER]}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute
                      exact
                      path="/requests/bulk"
                      component={RequestsBulk}
                      allowedUserTypes={[userTypes.REQUESTER]}
                      setClasses={setClasses}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute
                      path="/companies"
                      component={Companies}
                      allowedPermissions={[permissionTypes.MANAGE_COMPANIES]}
                    />
                    <PrivateRoute
                      path="/companies-archived"
                      component={Companies}
                      allowedPermissions={[permissionTypes.MANAGE_COMPANIES]}
                    />
                    <PrivateRoute
                      exact
                      path="/promotions/all"
                      component={Promotions}
                    />
                    <PrivateRoute
                      exact
                      path="/promotions/grid"
                      component={PromotionsGrid}
                      setClasses={setClasses}
                    />
                    <PrivateRoute
                      exact
                      path="/promotions"
                      component={PromotionCalendar}
                    />
                    <PrivateRoute
                      exact
                      path="/promotion/add"
                      component={Promotion}
                      allowedPermissions={[permissionTypes.MANAGE_PROMOTIONS]}
                    />
                    <PrivateRoute
                      exact
                      path="/promotion/:id"
                      component={Promotion}
                    />
                    <PrivateRoute
                      exact
                      path="/company/:id"
                      component={Company}
                      allowedPermissions={[permissionTypes.MANAGE_COMPANIES]}
                      allowedUserTypes={[
                        userTypes.UPLOADER,
                        userTypes.APPROVER,
                        userTypes.ADMIN,
                      ]}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute
                      path="/keys/search"
                      component={KeySearch}
                      allowedPermissions={[
                        permissionTypes.MANAGE_KEYS,
                        permissionTypes.KEYS_SEARCH,
                      ]}
                    />
                    <PrivateRoute
                      path="/keys/account/:id"
                      component={Account}
                      allowedPermissions={[
                        permissionTypes.MANAGE_KEYS,
                        permissionTypes.KEYS_SEARCH,
                      ]}
                    />
                    <PrivateRoute
                      path="/keys/accounts"
                      component={Accounts}
                      allowedPermissions={[
                        permissionTypes.MANAGE_KEYS,
                        permissionTypes.KEYS_SEARCH,
                      ]}
                    />
                    <PrivateRoute
                      path="/keys/game"
                      component={GameKeys}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/pools"
                      component={KeyPools}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/reported-sold/:id"
                      component={ReportedSoldTransaction}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/reported-sold"
                      component={ReportedSold}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/moved/:id"
                      component={MovedTransaction}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/moved"
                      component={MovedTransactions}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/move-between-requests"
                      component={MoveKeysBetweenRequests}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/reported-activated/:id"
                      component={ReportedActivatedTransaction}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/reported-activated"
                      component={ReportedActivated}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/reported-disabled/:id"
                      component={ReportedDisabledTransaction}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/reported-disabled"
                      component={ReportedDisabled}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/ingested-batches/:id"
                      component={IngestedTransaction}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/ingested-batches"
                      component={IngestedBatches}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys/bulk-ingest"
                      component={BulkIngested}
                      allowedPermissions={[permissionTypes.MANAGE_KEYS]}
                    />
                    <PrivateRoute
                      path="/keys"
                      component={Keys}
                      allowedUserTypes={[
                        userTypes.REQUESTER,
                        userTypes.UPLOADER,
                        userTypes.APPROVER,
                        userTypes.ADMIN,
                      ]}
                    />
                    <PrivateRoute
                      path="/reports/monthly"
                      component={Home}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/settings/emails/"
                      component={Emails}
                      allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
                    />
                    <PrivateRoute
                      path="/settings/approver-notification-users/"
                      component={ApproverNotificationUsers}
                      allowedPermissions={[permissionTypes.MANAGE_WORKFLOWS]}
                    />
                    <PrivateRoute
                      path="/settings/workflows/"
                      component={Workflows}
                      allowedPermissions={[permissionTypes.MANAGE_WORKFLOWS]}
                    />
                    <PrivateRoute
                      path="/settings/workflow/:id"
                      component={Workflow}
                      allowedPermissions={[permissionTypes.MANAGE_WORKFLOWS]}
                    />
                    <PrivateRoute
                      path="/settings/disabled-reasons/"
                      component={DisableReasons}
                      allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
                    />
                    <PrivateRoute
                      path="/settings/disabled-reason/:id"
                      component={DisableReason}
                      allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
                    />
                    <PrivateRoute
                      path="/settings/game-templates/"
                      component={GameTemplates}
                      allowedPermissions={[permissionTypes.MANAGE_GAMES]}
                    />
                    <PrivateRoute
                      path="/settings/game-template/:id"
                      component={GameTemplate}
                      allowedPermissions={[permissionTypes.MANAGE_GAMES]}
                    />
                    <PrivateRoute
                      path="/settings/key-pool-notifications/"
                      component={KeyPoolNotifications}
                      allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
                    />
                    <PrivateRoute
                      path="/settings/key-pool-notification/:id"
                      component={KeyPoolNotification}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/user-profile-group/:id/members"
                      component={UsersInGroup}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/user-profile-group/:id"
                      component={UserProfileGroup}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/user-profile-groups/"
                      component={UserProfileGroups}
                      allowedPermissions={[permissionTypes.MANAGE_USERS]}
                    />
                    <PrivateRoute
                      path="/settings/uploaders/"
                      component={Uploaders}
                      allowedPermissions={[permissionTypes.MANAGE_USERS]}
                    />
                    <PrivateRoute
                      path="/settings/permissions/"
                      component={Permissions}
                      allowedPermissions={[permissionTypes.MANAGE_PERMISSIONS]}
                    />
                    <PrivateRoute
                      path="/settings/territories/"
                      component={Territories}
                      allowedPermissions={[permissionTypes.MANAGE_TERRITORIES]}
                    />
                    <PrivateRoute
                      path="/settings/territory/allowed-skus/:id"
                      component={AllowedSkus}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/territory/disallowed-skus/:id"
                      component={DisallowedSkus}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/territory/:id"
                      component={Territory}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/key-providers/"
                      component={KeyProviders}
                      allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
                    />
                    <PrivateRoute
                      path="/settings/key-provider/:id"
                      component={KeyProvider}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/package-types/"
                      component={PackageTypes}
                      allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
                    />
                    <PrivateRoute
                      path="/settings/announcement/:id"
                      component={Announcement}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/announcements/"
                      component={Announcements}
                      allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
                    />
                    <PrivateRoute
                      path="/settings/company-type/:id"
                      component={CompanyType}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/company-types/"
                      component={CompanyTypes}
                      allowedPermissions={[permissionTypes.MANAGE_COMPANIES]}
                    />
                    <PrivateRoute
                      path="/settings/package-type/:id"
                      component={PackageType}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/platforms/"
                      component={Platforms}
                      allowedPermissions={[permissionTypes.MANAGE_PLATFORMS]}
                    />
                    <PrivateRoute
                      path="/settings/platform/:id"
                      component={Platform}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/upload-games/"
                      component={UploadGames}
                      allowedPermissions={[permissionTypes.MANAGE_GAMES]}
                      setClasses={setClasses}
                    />
                    <PrivateRoute
                      path="/settings/users/"
                      component={Users}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings/user/:id"
                      component={User}
                      isPowerUser={true}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute
                      path="/settings/health-checks/"
                      component={HealthChecks}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/settings"
                      component={() => Settings({ setClasses })}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/users/"
                      component={Users}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/users-archived/"
                      component={Users}
                      isPowerUser={true}
                    />
                    <PrivateRoute
                      path="/user/:id"
                      component={User}
                      isPowerUser={true}
                      setCompleteRequestSlideoutOpen={
                        setCompleteRequestSlideoutOpen
                      }
                    />
                    <PrivateRoute
                      path="/reports/requests-by-sku"
                      component={RequestsBySKU}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/requests-by-game"
                      component={RequestsByGame}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/requests-by-workflow"
                      component={RequestsByWorkflow}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/keys-by-game"
                      component={KeysByGame}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/keys-by-workflow"
                      component={KeysByWorkflow}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/keys-by-sku"
                      component={KeysBySku}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/keys-by-company"
                      component={KeysByCompany}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/requests-by-company"
                      component={RequestsByCompany}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/requests-authorising-user"
                      component={RequestsByAuthorisingUser}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/keys-authorising-user"
                      component={KeysByAuthorisingUser}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/requests-uploading-user"
                      component={RequestsByUploadingUser}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/keys-uploading-user"
                      component={KeysByUploadingUser}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/workflows-requesters"
                      component={WorkflowsByRequesters}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/workflows-approvers"
                      component={WorkflowsByApprovers}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/sla-company"
                      component={SLAsByCompanies}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/sla-sku"
                      component={SLAsByGame}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/sla-workflow"
                      component={SLAsByWorkflows}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/games-added"
                      component={GamesModifiedByUser}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/skus-added"
                      component={SKUsModifiedByUser}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/users-added"
                      component={UsersModifiedByUser}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/reports/keyworkflows-added"
                      component={KeyWorkflowsModifiedByUser}
                      allowedPermissions={[permissionTypes.VIEW_REPORTS]}
                    />
                    <PrivateRoute
                      path="/auth/hasPermission"
                      component={hasPermission}
                    />
                    <Redirect to="/not-found" />
                  </Switch>
                </Layout>
              </MustAuth>
              <BigLoader />
            </Route>
          </Switch>
        </ErrorBoundary>
      </BrowserRouter>
    </ReduxProvider>
  );
};

export default App;
