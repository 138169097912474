import React from 'react';
import EmailsList from './components/EmailsList';
import ActionBar from '../../layout/ActionBar';

export default function Emails({ location }) {
  return (
    <>
      <ActionBar></ActionBar>
      <EmailsList location={location} />
    </>
  );
}
