import React, { useEffect } from 'react';

import ReactDOM from 'react-dom';
import Breadcrumb from '../common/Breadcrumb';

function ActionBar({ children, breadcrumb }) {
  const actionBar = document.getElementById('layout-action-bar');
  useEffect(() => {
    const page = document.getElementById('page');
    if (page) {
      page.classList.add('action-bar-open');
    }
    return () => page.classList.remove('action-bar-open');
  }, []);

  return (
    actionBar &&
    ReactDOM.createPortal(
      !breadcrumb ? (
        <div className="action-bar-inner">{children}</div>
      ) : (
        <div className="action-bar-inner">
          <div className="d-flex w-100 justify-content-between">
            <Breadcrumb items={breadcrumb}></Breadcrumb>
            <div>{children}</div>
          </div>
        </div>
      ),
      actionBar
    )
  );
}

export default ActionBar;
