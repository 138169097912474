export const apiUrl = '/api';
export const isMock = (baseUrl) => baseUrl.indexOf('localhost:3001') > -1;
let token;
export function setToken(value) {
  token = value;
}
export class TrialEndedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'TrialEndedError';
  }
}
export class ForbiddenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ForbiddenError';
  }
}
export async function handleResponse(response) {
  if (response.ok) return response.json();
  const error = await response.text();
  if (response.status === 400) {
    throw new Error(error);
  } else if (response.status === 403) {
    throw new ForbiddenError(error);
  } else if (response.status === 402) {
    throw new TrialEndedError(error);
  } else if (response.status === 401) {
    console.error('Token expired');
    window.location.reload();
  }
  throw new Error();
}

export async function handleFileResponse(res) {
  if (res.ok) {
    const filenameExp = /filename="?([^;^"]+)"?;/im;
    const contentDisposition =
      res.headers.get('Content-Disposition') ??
      res.headers.get('content-disposition');
    const matchResult = contentDisposition?.match(filenameExp);
    const filename = matchResult ? matchResult[1] : 'unknown';
    if (filename === 'unknown') {
      throw new Error('Could not retrieve filename');
    }
    return {
      filename: filename,
      blob: await res.blob(),
    };
  } else if (res.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await res.text();
    throw new Error(error);
  }
  throw new Error();
}

export function downloadFile({ filename, blob }) {
  // 2. Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); //due to CORS can't get filename from content-disposition
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error('API call failed. ' + error);
  throw error;
}

export function apiOptions() {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

//Couldn't get JSON server to route with /:id without picking up
//all GET queries, so had to implement this.
//Hopefully will find a fix, and can remove this everywhere in time.
function getSingleRecordUrl(baseUrl, id) {
  return !isMock(baseUrl) ? `${baseUrl}${id}` : `${baseUrl}?id=${id}`;
}

export function getSingleRecord(baseUrl, id) {
  return fetch(getSingleRecordUrl(baseUrl, id), apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

function getStatsUrl(baseUrl, id) {
  return !isMock(baseUrl)
    ? `${baseUrl}${id}/stats`
    : `${baseUrl}stats?id=${id}`;
}

export function getStatsData(baseUrl, id) {
  return fetch(getStatsUrl(baseUrl, id), apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

function getAuditUrl(baseUrl, id) {
  return !isMock(baseUrl)
    ? `${baseUrl}${id}/audit`
    : `${baseUrl}audit?id=${id}`;
}

export function getAuditData(baseUrl, id) {
  return fetch(getAuditUrl(baseUrl, id), apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function saveRecord(baseUrl, record) {
  return fetch(baseUrl + (record.id || ''), {
    method: record.id ? 'PUT' : 'POST', // POST for create, PUT to update when id already exists.
    headers: { ...apiOptions().headers, 'content-type': 'application/json' },
    body: JSON.stringify({
      ...record,
    }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getListUrl(
  baseUrl,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}${
    baseUrl.indexOf('?') > -1 ? '&' : '?'
  }_sort=${_sort}&_order=${_order}&_page=${_page + 1}&_limit=${_limit}`;
  if (filters) {
    url +=
      '&' +
      Object.keys(filters)
        .filter((key) => filters[key])
        .map((key) => `${key}_like=${filters[key]}`)
        .join('&');
  }
  if (_scopes) {
    url +=
      '&' +
      Object.keys(_scopes)
        .filter((key) => _scopes[key])
        .map((key) => `${key}_scope=${_scopes[key]}`)
        .join('&');
  }
  return url;
}

export function getList(
  baseUrl,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return fetch(
    getListUrl(`${baseUrl}`, filters, _scopes, _sort, _order, _page, _limit),
    apiOptions()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function deleteRecord(baseUrl, id, data) {
  if (data) {
    return fetch(baseUrl + (id ?? ''), {
      method: 'DELETE',
      headers: { ...apiOptions().headers, 'content-type': 'application/json' },
      body: JSON.stringify({
        ...data,
      }),
    })
      .then(handleResponse)
      .catch(handleError);
  } else {
    return deleteAction(baseUrl + id);
  }
}

export function deleteAction(url) {
  return fetch(url, {
    method: 'DELETE',
    headers: { ...apiOptions().headers, 'content-type': 'application/json' },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function postAction(baseUrl, record) {
  return fetch(baseUrl, {
    method: 'POST',
    headers: { ...apiOptions().headers, 'content-type': 'application/json' },
    body: JSON.stringify({ ...record }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function putAction(baseUrl, record) {
  return fetch(baseUrl, {
    method: 'PUT',
    headers: { ...apiOptions().headers, 'content-type': 'application/json' },
    body: JSON.stringify({ ...record }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function upload(baseUrl, record) {
  return uploadWithMethod(
    baseUrl + (record.id || ''),
    record,
    record.id ? 'PUT' : 'POST'
  );
}
export function uploadWithMethod(baseUrl, record, method) {
  const data = new FormData();
  for (const key in record) {
    if (Array.isArray(record[key])) {
      for (const item in record[key]) {
        data.append(key, record[key][item]);
      }
    } else {
      if (record[key] === 'null' || record[key] === null) {
        record[key] = '';
      }
      data.append(key, record[key]);
    }
  }
  return fetch(baseUrl, {
    method,
    headers: { ...apiOptions().headers },
    body: data,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAll(baseUrl) {
  return fetch(baseUrl, apiOptions()).then(handleResponse).catch(handleError);
}

export function get(baseUrl) {
  let url = `${baseUrl}`;
  return fetch(url, apiOptions()).then(handleResponse).catch(handleError);
}
export function download(url) {
  return fetch(url, apiOptions())
    .then(handleFileResponse)
    .then(downloadFile)
    .catch(handleError);
}

export function postArray(url, array) {
  return fetchArray(url, array, 'POST');
}
export function deleteArray(url, array) {
  return fetchArray(url, array, 'DELETE');
}

function fetchArray(url, array, method) {
  return fetch(url, {
    method: method,
    headers: { ...apiOptions().headers, 'content-type': 'application/json' },
    body: JSON.stringify([...array]),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function exportData(url, sort, filters, scopes) {
  return download(
    getListUrl(url, filters, scopes, sort, 'asc', -1, 2_147_483_647)
  );
}

export function nullBlankProperties(obj, excludedProps) {
  for (let prop in obj) {
    if (
      obj.hasOwnProperty(prop) &&
      obj[prop] === '' &&
      !excludedProps?.some(prop)
    ) {
      obj[prop] = null;
    }
  }
  return obj;
}

export function removeBlankProperties(obj, excludedProps) {
  for (let prop in obj) {
    if (
      obj.hasOwnProperty(prop) &&
      obj[prop] === '' &&
      !excludedProps?.some(prop)
    ) {
      delete obj[prop];
    }
  }
  return obj;
}
