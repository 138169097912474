import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { loadKeyPools } from '../../../actions/skuActions';
import { NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import Checkbox from '../../common/Checkbox';
import useUploadNotifications from '../../../hooks/useUploadNotifications';
import TransactionStatusBadge from '../../keys/components/TransactionStatusBadge';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import KebabMenu from '../../common/KebabMenu';
import Button from '../../common/Button';
import icons from '../../../constants/icons';
import skuHelp from '../../../constants/help/skuHelp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function KeyPoolsList({
  id,
  gameid,
  location,
  reload,
  upload,
  onSteamRequest,
  handleBulkActions,
  onDisableUnused,
  onMoveKeys,
  addToGroup,
  removeFromGroup,
  importTransactions,
  currentUser,
  itemsChecked,
  setItemsChecked,
  onBulkUploadKeys,
}) {
  const { t } = useTranslation();
  const language = i18next.language;
  const allColumns = ['keyWorkflowName', 'available', 'used', 'total'];
  const suffixColumns = ['checkbox'];
  const defaultVisibleColumns = allColumns;

  const [keyPools, setKeyPools] = useState();
  useUploadNotifications({
    keypools: keyPools,
    setKeyPools,
    addToGroup,
    removeFromGroup,
    importTransactions,
  });
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'sku-keypoolslist-settings',
      getTableSettingsDefaults({
        defaultSort: 'gameName',
        defaultVisibleColumns,
      }),
      { id, gameid },
      loadKeyPools,
      setKeyPools,
      reload,
      t('Failed to load key pools'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'keyWorkflowName',
      Label: t('Pool'),
      width: '35%',
      Render: (item) =>
        hasPermission(currentUser, permissionTypes.MANAGE_WORKFLOWS) ? (
          <NavLink to={`/settings/workflow/${item.keyWorkflowId}`}>
            {item.keyWorkflowName}
          </NavLink>
        ) : (
          item.keyWorkflowName
        ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'available',
      Label: t('Pooled'),
      helpContent: skuHelp.SKU_POOL_TABLE_POOLED[language],
      className: 'text-right num no-wrap',
      width: '15%',
      Render: (row) =>
        row.lowStock ? (
          <span className="badge bg-danger">
            {CommaNumber_NoDecimal(row.available)}
          </span>
        ) : (
          <span className="text-pos1">
            {CommaNumber_NoDecimal(row.available)}
          </span>
        ),
    },
    {
      Key: 'used',
      Label: t('Delivered'),
      helpContent: skuHelp.SKU_POOL_TABLE_DELIVERED[language],
      className: 'text-right num no-wrap',
      width: '15%',
      Render: (row) => CommaNumber_NoDecimal(row.used),
    },
    {
      Key: 'total',
      Label: t('Total'),
      helpContent: skuHelp.SKU_POOL_TABLE_TOTAL[language],
      className: 'text-right num no-wrap',
      width: '15%',
      Render: (row) => CommaNumber_NoDecimal(row.total),
    },
    {
      Key: 'checkbox',
      Label: '',
      className: 'text-right no-navigate no-wrap',
      Sort: false,
      width: '7%',
      Render: (row, rows, totalRowCount) => (
        <div className="d-flex align-items-center justify-content-end">
          {row.uploadInProgress ? (
            <TransactionStatusBadge
              success={row.success}
              partialSuccess={row.partialSuccess}
              message={row.message}
              status={row.status}
            ></TransactionStatusBadge>
          ) : (
            row.canUploadKeys && (
              <KebabMenu
                take={1}
                className="inline-icons"
                buttons={[
                  <Button
                    className="btn btn-default me-2"
                    onClick={(e) => upload(e, row)}
                    icon={icons.UPLOAD_KEYS}
                    text={t('Upload')}
                  />,
                  <Button
                    className="btn btn-default me-2"
                    onClick={(e) => onSteamRequest(e, row)}
                    icon={icons.REQUEST_ON_STEAM}
                    text={t('Request on Steam')}
                  />,
                  hasPermission(currentUser, permissionTypes.MANAGE_KEYS) && (
                    <Button
                      className="btn btn-default me-2"
                      isDisabled={totalRowCount === 1 || row.available === 0}
                      title={
                        totalRowCount === 1
                          ? t('There are no other pools available')
                          : row.available === 0
                            ? t('There are keys in this pool available')
                            : ''
                      }
                      onClick={(e) => onMoveKeys(e, row)}
                      icon={icons.MOVE_KEYS}
                      text={t('Move keys to another pool')}
                    />
                  ),
                  hasPermission(currentUser, permissionTypes.MANAGE_KEYS) && (
                    <Button
                      className="btn btn-default me-2"
                      onClick={(e) => onDisableUnused(e, row)}
                      icon={icons.DISABLE_KEYS}
                      text={t('Disable unused')}
                    />
                  ),
                  hasPermission(currentUser, permissionTypes.MANAGE_KEYS) && (
                    <NavLink
                      to={`/keys/ingested-batches?s_sku=${row['skuId']}&f_keyWorkflowName=${row['keyWorkflowName']}`}
                      className="no-pad"
                      title={t('View key pool history')}
                    >
                      <FontAwesomeIcon
                        icon={icons.LINK}
                        fixedWidth
                        className="me-3 no-navigate"
                      />
                      {t('View key pool history')}
                    </NavLink>
                  ),
                ]}
              ></KebabMenu>
            )
          )}
          <Checkbox
            className="ms-3"
            checked={itemsChecked?.some(
              (x) => x.keyWorkflowId === row.keyWorkflowId
            )}
            onChange={(checked) =>
              checked
                ? setItemsChecked([...itemsChecked, row])
                : setItemsChecked(
                    itemsChecked.filter(
                      (x) => x.keyWorkflowId !== row.keyWorkflowId
                    )
                  )
            }
          ></Checkbox>
        </div>
      ),
      Filter: {
        Render: () => (
          <Checkbox
            disabled={!keyPools}
            checked={false}
            onChange={(checked) =>
              checked
                ? setItemsChecked(
                    keyPools?.data?.filter((x) => x.keyProviderReference)
                  )
                : setItemsChecked([])
            }
          ></Checkbox>
        ),
      },
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return keyPools ? (
    <KwPanel className="mb-4">
      <header>
        <h2>{t('Key pools')}</h2>
        {keyPools.data.filter((x) => x.canUploadKeys).length > 0 && (
          <Button
            className="btn btn-default btn-xs-full space-bottom-xs-s me-2"
            onClick={onBulkUploadKeys}
            icon={icons.UPLOAD_KEYS}
            text={t('Upload keys')}
          />
        )}
      </header>
      <Table
        columns={columns}
        suffixColumns={suffixColumnsWithConfig}
        allColumns={allColumns}
        rows={keyPools.data.map((x) => {
          return { Key: `${x.skuId}-${x.keyWorkflowId}`, ...x };
        })}
        totalRowCount={keyPools.totalRowCount}
        filteredRowCount={keyPools.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filterChange={filterChange}
        setBulkActionsSlideoutOpen={handleBulkActions}
        bulkActionTitle={
          itemsChecked.length === 0
            ? t('Bulk actions - first tick some key pools')
            : t('Bulk actions')
        }
        itemsChecked={itemsChecked}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

KeyPoolsList.propTypes = {
  id: PropTypes.string.isRequired,
  gameid: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  reload: PropTypes.func,
  upload: PropTypes.func.isRequired,
  onSteamRequest: PropTypes.func.isRequired,
  handleBulkActions: PropTypes.func.isRequired,
  onDisableUnused: PropTypes.func.isRequired,
  onMoveKeys: PropTypes.func.isRequired,
  addToGroup: PropTypes.func.isRequired,
  removeFromGroup: PropTypes.func.isRequired,
  importTransactions: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  itemsChecked: PropTypes.array,
  setItemsChecked: PropTypes.func.isRequired,
  onBulkUploadKeys: PropTypes.func.isRequired,
};

export default KeyPoolsList;
