import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SortableItem from './SortableItem';

const SortableList = ({
  items,
  allColumns,
  onSortEnd,
  onColumnChange,
  onColumnDelete,
}) => {
  return (
    <ol className="sortable-container">
      <DragDropContext onDragEnd={onSortEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ width: '100%' }}
            >
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`item-${item.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        left: 'auto !important',
                        top: snapshot.isDragging
                          ? provided.draggableProps.style?.top
                          : 'auto !important',
                      }}
                    >
                      <SortableItem
                        key={item.id}
                        items={items}
                        allColumns={allColumns}
                        index={index}
                        column={item}
                        onColumnChange={onColumnChange}
                        onColumnDelete={onColumnDelete}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ol>
  );
};

SortableList.propTypes = {
  items: PropTypes.array,
  allColumns: PropTypes.array,
  onSortEnd: PropTypes.func.isRequired,
  onColumnChange: PropTypes.func.isRequired,
  onColumnDelete: PropTypes.func.isRequired,
};

export default SortableList;
