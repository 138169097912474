import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import KwPanel from '../../../common/KwPanel';

import {
  deleteWorkflowRequesterGroup,
  deleteWorkflowRequesterUser,
  deleteWorkflowRequesterCompany,
} from '../../../../actions/workflowActions';
import { toast } from 'react-toastify';
import Button from '../../../common/Button';
import ConfirmationHeader from '../../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function DeleteRequester({
  done,
  workflowId,
  userProfileGroupId,
  userProfileId,
  companyId,
  cancel,
}) {
  const { t } = useTranslation();
  const handleDelete = () => {
    if (userProfileGroupId) {
      deleteWorkflowRequesterGroup(workflowId, userProfileGroupId)
        .then(() => {
          toast.success(t('Requester successfully deleted'));
          done();
        })
        .catch((ex) => {
          console.error(ex);
          toast.error(t('Failed to delete requester'));
        });
    } else if (userProfileId) {
      deleteWorkflowRequesterUser(workflowId, userProfileId)
        .then(() => {
          toast.success(t('Requester successfully deleted'));
          done();
        })
        .catch((ex) => {
          console.error(ex);
          toast.error(t('Failed to delete requester'));
        });
    } else if (companyId) {
      deleteWorkflowRequesterCompany(workflowId, companyId)
        .then(() => {
          toast.success(t('Requester successfully deleted'));
          done();
        })
        .catch((ex) => {
          console.error(ex);
          toast.error(t('Failed to delete requester'));
        });
    }
  };

  return (
    <SlideoutLayout
      title={t('Delete confirmation')}
      cancel={cancel}
      done={done}
    >
      <KwPanel>
        <ConfirmationHeader text={t('Are you sure?')} />
        <Button
          className="btn btn-danger me-2"
          onClick={handleDelete}
          text={t('Yes, delete the requester')}
        />
        <Button
          className="btn btn-default"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
DeleteRequester.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRequester);
