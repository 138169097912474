import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import ExpandPanel from '../../common/ExpandPanel';
import icons from '../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { scopes as tableScopes } from '../../../constants/scopes';
import { useTranslation } from 'react-i18next';

export default function WarningMaxValue({
  transactionLimit,
  currentTransactionAmount,
  companyId,
  companyName,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <ExpandPanel className={`alert alert-danger ${isOpen ? '' : 'collapsed'}`}>
      <header
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <h2 className="heading-with-icon">
          <span className="icon-wrapper warning-icon me-3">
            <FontAwesomeIcon icon={icons.ARE_YOU_SURE} />
          </span>
          {companyName
            ? t('Warning - maximum open request value exceeded for {company}', {
                company: companyName,
              })
            : t(
                'Warning - maximum open request value exceeded for this company'
              )}
          <span className="ui-icon">
            <FontAwesomeIcon icon={icons.ACCORDIAN_CLOSED} />
          </span>
        </h2>
      </header>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <label className="col-sm-3 col-form-label">
              {t('Maximum open request value')}
            </label>
            <div className="col-sm-3 ps-0 pe-0 pe-md-4 mb-2 mb-md-0">
              <NavLink
                to={`/company/${companyId}`}
                className="form-control-static px-3 py-2 flex h-100 align-items-center"
                title={t('Company settings')}
              >
                <div className="width-100 num">{transactionLimit}</div>
                <div className="me-2">
                  <FontAwesomeIcon icon={icons.SETTINGS} />
                </div>
              </NavLink>
            </div>
            <label className="col-sm-3 col-form-label">
              {t('Total open value including this request')}
            </label>
            <div className="col-sm-3 pe-0 ps-0">
              <NavLink
                to={`/requests/all?${tableScopes.COMPANY}=${companyId}&f_requestStatus=pending%20approval`}
                className="form-control-static px-3 py-2 flex h-100 align-items-center"
                title={t('View requests for this company')}
              >
                <div className="width-100 num">{currentTransactionAmount}</div>
                <div className="me-2">
                  <FontAwesomeIcon icon={icons.LINK_INTERNAL} />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </ExpandPanel>
  );
}
