import React from 'react';
import permissionTypes from '../../../constants/permissionTypes';
import LinkBadge from './LinkBadge';
import { useTranslation } from 'react-i18next';

function WorkflowLink({ id, name, colour }) {
  const { t } = useTranslation();
  return (
    <LinkBadge
      url={`/settings/workflow/${id}`}
      name={name}
      title={t('View workflow')}
      colour={colour}
      permissionType={permissionTypes.MANAGE_WORKFLOWS}
    />
  );
}

export default WorkflowLink;
