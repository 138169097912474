import React, { useState } from 'react';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { connect } from 'react-redux';
import { saveWorkflow } from '../../../../actions/workflowActions';
import { toast } from 'react-toastify';
import WorkflowForm from '../components/WorkflowForm';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import KwPanel from '../../../common/KwPanel';
import { useTranslation } from 'react-i18next';

function Add({ done, id, cancel, saveWorkflow }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [isAuroraWorkflow, setIsAuroraWorkflow] = useState(false);

  const onSubmit = (data) => {
    saveWorkflow({ ...workflow, ...data, isAuroraWorkflow: isAuroraWorkflow })
      .then(() => {
        toast.success(t('Workflow saved'));
        done(workflow);
      })
      .catch(() => {
        toast.error(t('Failed to save workflow'));
      });
  };

  const [workflow] = useState({});

  return (
    <SlideoutLayout
      title={id ? t('Add workflow') : t('Edit workflow')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <KwPanel className="mb-4">
        <WorkflowForm
          workflow={workflow}
          register={register}
          errors={errors}
          control={control}
          isAuroraWorkflow={isAuroraWorkflow}
          setIsAuroraWorkflow={setIsAuroraWorkflow}
        ></WorkflowForm>
      </KwPanel>
    </SlideoutLayout>
  );
}
Add.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  saveWorkflow: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  saveWorkflow,
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
