import React from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import KwPanel from '../../../common/KwPanel';
import { toast } from 'react-toastify';
import { archiveUser } from '../../../../actions/userActions';
import Button from '../../../common/Button';
import ConfirmationHeader from '../../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function Archive({ done, id, cancel }) {
  const { t } = useTranslation();
  const handleArchive = () => {
    archiveUser(id)
      .then(() => {
        toast.success(t('User successfully archived'));
        done();
      })
      .catch((e) => {
        console.error(e);
        toast.error(t('Failed to archive user'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Archive confirmation')}
      cancel={cancel}
      done={done}
    >
      <KwPanel>
        <ConfirmationHeader text={t('Are you sure?')} />
        <p>{t('Once the user is archived, they will not be able to login.')}</p>
        <hr />
        <Button
          className="btn btn-danger me-2"
          onClick={handleArchive}
          text={t('Yes, archive the user')}
        />
        <Button
          className="btn btn-default"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
Archive.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default Archive;
