const aggregateBarColours = Object.freeze({
  COLOUR_1: 'bar-colour-1',
  COLOUR_2: 'bar-colour-2',
  COLOUR_3: 'bar-colour-3',
  COLOUR_4: 'bar-colour-4',
  COLOUR_5: 'bar-colour-5',
  COLOUR_6: 'bar-colour-6',
  COLOUR_7: 'bar-colour-5',
  COLOUR_8: 'bar-colour-4',
  COLOUR_9: 'bar-colour-3',
  COLOUR_10: 'bar-colour-2',
  COLOUR_11: 'bar-colour-1',
  COLOUR_12: 'bar-colour-2',
});

export default aggregateBarColours;
