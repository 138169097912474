import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import KwPanel from '../../common/KwPanel';

import { deleteCompany } from '../../../actions/companyActions';
import { toast } from 'react-toastify';
import Button from '../../common/Button';
import ConfirmationHeader from '../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function Delete({ done, id, cancel, deleteCompany }) {
  const { t } = useTranslation();
  const handleDelete = () => {
    deleteCompany(id)
      .then(() => {
        toast.success(t('Company successfully deleted'));
        done();
      })
      .catch(() => {
        toast.error(t('Failed to delete company'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Delete confirmation')}
      cancel={cancel}
      done={done}
    >
      <KwPanel>
        <ConfirmationHeader text={t('Are you sure?')} />
        <p>
          {t('This will also archive any users associated with this company')}
        </p>
        <Button
          className="btn btn-danger me-2"
          onClick={handleDelete}
          text={t('Yes, delete the company')}
        />
        <Button
          className="btn btn-default"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
Delete.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  deleteCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(Delete);
