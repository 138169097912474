const colours = Object.freeze({
  STATS_OPEN_REQUESTS: 'blue',
  STATS_OVER_SLA: 'red',
  STATS_OPEN_REQUESTS_VALUE: 'blue',
  STATS_COMPLETED_REQUESTS: 'aqua',
  STATS_KEYS_IN_POOL: 'dark-yellow',
  STATS_KEYS_DELIVERED: 'yellow',
  STATS_KEYS_DELIVERED_VALUE: 'yellow',
  STATS_KEYS_SOLD: 'orange',
  STATS_KEYS_DISABLED: 'green',
  STATS_ACCOUNTS: 'aqua',
  STATS_ACCOUNT_DOWNLOADED: 'aqua',
  STATS_KEYS_EXPIRED: 'red',
});

export default colours;
