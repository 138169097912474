import React, { useState } from 'react';
import i18next from 'i18next';
import { gameKeys, exportGameKeysData } from '../../../actions/metricActions';
import { NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import KeyProviderLink from '../../common/links/KeyProviderLink';
import PlatformLink from '../../common/links/PlatformLink';
import skuHelp from '../../../constants/help/skuHelp';
import { scopes as tableScopes } from '../../../constants/scopes';
import { useTranslation } from 'react-i18next';

function GamesKeysList({ location, reload, setBreadcrumb }) {
  const { t } = useTranslation();
  const language = i18next.language;
  const allColumns = [
    'gameName',
    'platformName',
    'keyProviderName',
    'isAvailableCodeCount',
    'isAllocatedCodeCount',
    'isSoldCodeCount',
    'isActivatedCodeCount',
    'isDisabledCodeCount',
    'isExpiredCodeCount',
  ];
  const defaultVisibleColumns = allColumns;

  const [gamekeys, setGameKeys] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'gameskeyslist-settings',
      getTableSettingsDefaults({
        defaultSort: 'gameName',
        defaultVisibleColumns,
      }),
      null,
      gameKeys,
      setGameKeys,
      reload,
      t('Failed to load game keys'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'gameName',
      Label: t('Game'),
      Render: (item) => (
        <>
          <NavLink to={`/sku/${item.skuId}`} className="game-title">
            {item.gameName}
          </NavLink>
          <br />
          <NavLink to={`/sku/${item.skuId}`}>{item.skuName}</NavLink>
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'platformName',
      Label: t('Platform'),
      Render: (item) => (
        <PlatformLink id={item.platformId} name={item.platformName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyProviderName',
      Label: t('Key provider'),
      Render: (item) => (
        <KeyProviderLink id={item.keyProviderId} name={item.keyProviderName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'isAvailableCodeCount',
      Label: t('Pooled'),
      helpContent: skuHelp.SKU_POOL_TABLE_POOLED[language],
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.isAvailableCodeCount),
    },
    {
      Key: 'isAllocatedCodeCount',
      Label: t('Delivered'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.isAllocatedCodeCount),
    },
    {
      Key: 'isSoldCodeCount',
      Label: t('Reported sold'),
      className: 'text-right num no-wrap',
      Render: (item) =>
        item.isSoldCodeCount > 0 ? (
          <NavLink to={`/keys/reported-sold/?${tableScopes.SKU}=${item.skuId}`}>
            {CommaNumber_NoDecimal(item.isSoldCodeCount)}
          </NavLink>
        ) : (
          0
        ),
    },
    {
      Key: 'isActivatedCodeCount',
      Label: t('Activated'),
      className: 'text-right num no-wrap',
      Render: (item) =>
        item.isActivatedCodeCount > 0 ? (
          <NavLink
            to={`/keys/reported-activated/?${tableScopes.SKU}=${item.skuId}`}
          >
            {CommaNumber_NoDecimal(item.isActivatedCodeCount)}
          </NavLink>
        ) : (
          0
        ),
    },
    {
      Key: 'isDisabledCodeCount',
      Label: t('Disabled'),
      className: 'text-right num no-wrap',
      Render: (item) =>
        item.isDisabledCodeCount > 0 ? (
          <NavLink
            to={`/keys/reported-disabled/?${tableScopes.SKU}=${item.skuId}`}
          >
            {CommaNumber_NoDecimal(item.isDisabledCodeCount)}
          </NavLink>
        ) : (
          0
        ),
    },
    {
      Key: 'isExpiredCodeCount',
      Label: t('Expired'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.isExpiredCodeCount),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return gamekeys ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={gamekeys.data.map((x) => {
          return { Key: x.skuId, ...x };
        })}
        totalRowCount={gamekeys.totalRowCount}
        filteredRowCount={gamekeys.filteredRowCount}
        scopes={gamekeys.scopes}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportGameKeysData}
        location={location}
        setBreadcrumb={setBreadcrumb}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default GamesKeysList;
