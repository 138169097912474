import { nowMinusDays } from './dateUtils';

export const dynamicDateRanges = {
  today: {
    label: 'Today',
    calculateFrom: () => {
      return new Date();
    },
    calculateTo: () => {
      return new Date();
    },
  },
  yesterday: {
    label: 'Yesterday',
    calculateFrom: () => {
      return nowMinusDays(1);
    },
    calculateTo: () => {
      return new Date();
    },
  },
  thisWeek: {
    label: 'This week',
    calculateFrom: () => {
      const today = new Date();
      const startOfWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay()
      );
      return startOfWeek;
    },
    calculateTo: () => {
      return new Date();
    },
  },
  last7: {
    label: 'Last 7 days',
    calculateFrom: () => {
      return nowMinusDays(7);
    },
    calculateTo: () => {
      return new Date();
    },
  },
  last30: {
    label: 'Last 30 days',
    calculateFrom: () => {
      return nowMinusDays(30);
    },
    calculateTo: () => {
      return new Date();
    },
  },
  last90: {
    label: 'Last 90 days',
    calculateFrom: () => {
      return nowMinusDays(90);
    },
    calculateTo: () => {
      return new Date();
    },
  },
  last12Months: {
    label: 'Last 12 months',
    calculateFrom: () => {
      const today = new Date();
      const startOfLast12Months = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      );
      return startOfLast12Months;
    },
    calculateTo: () => {
      return new Date();
    },
  },
  allTime: {
    label: 'All time',
    calculateFrom: () => {
      return new Date(2010, 0, 1);
    },
    calculateTo: () => {
      return new Date();
    },
  },
};
