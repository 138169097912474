import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ACTIVATION:
      return { ...state, ...action.transaction };
    case actionTypes.CLEAR_ACTIVATION:
      return null;
    default:
      return state;
  }
}
