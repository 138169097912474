import React from 'react';

const skuHelp = Object.freeze({
  FULFILMENT_DATE: {
    en: (
      <p>
        If a fulfillment date is specified, downloading of keys will be
        restricted until the specified date is reached.
      </p>
    ),
    ja: <p></p>,
    fr: <p></p>,
    de: <p></p>,
    es: <p></p>,
    it: <p></p>,
  },
  CAN_REQUEST_FROM: {
    en: (
      <>
        <p>
          If a date is provided, requesting the SKU will be restricted{' '}
          <u>until</u> the specified date is reached.
        </p>
        <p>If the date field is left empty, there will be no restriction.</p>
      </>
    ),
    ja: (
      <>
        <p></p>
        <p></p>
      </>
    ),
    fr: (
      <>
        <p></p>
        <p></p>
      </>
    ),
    de: (
      <>
        <p></p>
        <p></p>
      </>
    ),
    es: (
      <>
        <p></p>
        <p></p>
      </>
    ),
    it: (
      <>
        <p></p>
        <p></p>
      </>
    ),
  },
  CAN_REQUEST_UNTIL: {
    en: (
      <>
        <p>
          If a date is provided, requesting the SKU will be restricted{' '}
          <u>after</u> the specified date is reached.
        </p>
        <p>If the date field is left empty, there will be no restriction.</p>
      </>
    ),
    ja: (
      <>
        <p></p>
        <p></p>
      </>
    ),
    fr: (
      <>
        <p></p>
        <p></p>
      </>
    ),
    de: (
      <>
        <p></p>
        <p></p>
      </>
    ),
    it: (
      <>
        <p></p>
        <p></p>
      </>
    ),
    es: (
      <>
        <p></p>
        <p></p>
      </>
    ),
  },
  SKU_POOL_TABLE_POOLED: {
    en: (
      <>
        <p>
          "Pooled" is a sum of codes
          <br />
          in the following statuses:
        </p>
        <ul className="mt-2">
          <li>Available</li>
          <li>Reserved (Aurora)</li>
        </ul>
      </>
    ),
    ja: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    fr: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    de: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    it: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    es: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
        </ul>
      </>
    ),
  },
  SKU_POOL_TABLE_DELIVERED: {
    en: (
      <>
        <p>
          "Delivered" is a sum of codes
          <br />
          in the following statuses:
        </p>
        <ul className="mt-2">
          <li>Allocated</li>
          <li>Activated</li>
          <li>Sold</li>
        </ul>
      </>
    ),
    ja: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    fr: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    de: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    it: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    es: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    ),
  },
  SKU_POOL_TABLE_TOTAL: {
    en: (
      <>
        <p>
          "Total" is a sum of codes
          <br />
          in the following statuses:
        </p>
        <ul className="mt-2">
          <li>Available</li>
          <li>Allocated</li>
          <li>Activated</li>
          <li>Reserved (Aurora)</li>
          <li>Sold</li>
        </ul>
      </>
    ),
    ja: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    fr: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    de: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    it: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    ),
    es: (
      <>
        <p></p>
        <ul className="mt-2">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    ),
  },
  SKU_APP_ID: {
    en: <>You can override the game App ID by entering a new one here.</>,
    ja: <></>,
    fr: <></>,
    de: <></>,
    es: <></>,
    it: <></>,
  },
});

export default skuHelp;
