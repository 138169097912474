import React from 'react';
import CombinedStatsDonut from './CombinedStatsDonut';
import StatsOverTime from './StatsOverTime';

const CombinedStats = ({
  getData,
  getAuthorisedData,
  getUploadedData,
  stats,
  heatmapOnClick,
  heatmapOnAuthorisedClick,
  heatmapOnUploadedClick,
  setModeStorageKey,
  id,
  mode,
}) => {
  return (
    <div className="d-flex overflow-hidden flex-column flex-xl-row">
      <CombinedStatsDonut stats={stats} />
      <StatsOverTime
        getData={getData}
        getAuthorisedData={getAuthorisedData}
        getUploadedData={getUploadedData}
        onClick={heatmapOnClick}
        onAuthorisedClick={heatmapOnAuthorisedClick}
        onUploadedClick={heatmapOnUploadedClick}
        setModeStorageKey={setModeStorageKey}
        id={id}
        mode={mode}
      />
    </div>
  );
};

export default CombinedStats;
