import React, { useState } from 'react';
import { loadDisabledTransactionSkus } from '../../../actions/keyCodeActions';
import { NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import CompanyLink from '../../common/links/CompanyLink';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function ReportedDisabledTransactionSkusList({
  id,
  location,
  reload,
  handleDownload,
}) {
  const { t } = useTranslation();
  const allColumns = ['game', 'keyCount', 'companyName'];
  const suffixColumns = ['id'];
  const defaultVisibleColumns = allColumns;
  const [transactions, setTransactions] = useState();

  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'reporteddisabledtransactionskuslist-settings',
      getTableSettingsDefaults({
        defaultSort: 'game',
        defaultVisibleColumns,
      }),
      { id },
      loadDisabledTransactionSkus,
      setTransactions,
      reload,
      t('Failed to load disabled transaction Skus'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'game',
      Label: t('Game'),
      width: '30%',
      Render: (item) => (
        <>
          <NavLink to={`/sku/${item.skuId}`} className="game-title">
            {item.game}
          </NavLink>
          <br />
          <NavLink to={`/sku/${item.skuId}`}>{item.sku}</NavLink>
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyCount',
      Label: t('Key count'),
      width: '20%',
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.keyCount),
    },
    {
      Key: 'companyName',
      Label: t('Company'),
      width: '30%',
      Render: (item) => (
        <CompanyLink
          id={item.companyId}
          name={item.companyName}
          fallbackText={t('--- unknown ---')}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'id',
      Label: '',
      className: 'text-right',
      Sort: false,
      width: '20%',
      Render: (item) => (
        <Button
          className="btn btn-default"
          onClick={(e) => handleDownload(e, item)}
          text={t('Download')}
        />
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return transactions ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        suffixColumns={suffixColumnsWithConfig}
        allColumns={allColumns}
        rows={transactions.data.map((x) => {
          return { Key: x.skuId, ...x };
        })}
        totalRowCount={transactions.totalRowCount}
        filteredRowCount={transactions.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filterChange={filterChange}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default ReportedDisabledTransactionSkusList;
