import React from 'react';
import PropTypes from 'prop-types';
import KwPanel from '../../common/KwPanel';
import { useTranslation } from 'react-i18next';

const CompanyKeyProviders = ({
  keyProviders,
  companyKeyProviders,
  newCompanyKeyProviders,
  onChange,
  onNewChange,
  onAdd,
  onRemove,
}) => {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4 pb-2">
      <header className="mb-2">
        <h2 className="no-margin">{t('Key provider references')}</h2>
      </header>
      {keyProviders && keyProviders.length > 0 ? (
        <table className="table table-has-buttons width-100">
          <thead>
            <tr>
              <th>{t('Name')}</th>
              <th>{t('Reference')}</th>
            </tr>
          </thead>
          <tbody>
            {keyProviders?.map((row) => {
              const companyKeyProvider = companyKeyProviders?.find(
                (x) => x.id === row.id
              );
              return (
                <tr key={row.id}>
                  <td>{row.name}</td>
                  <td>
                    {onChange ? (
                      <input
                        placeholder=""
                        className="form-control"
                        defaultValue={companyKeyProvider?.reference || ''}
                        data-lpignore="true"
                        autoComplete="off"
                        onChange={(e) =>
                          onChange({ ...row, reference: e.target.value })
                        }
                      />
                    ) : (
                      companyKeyProvider?.reference
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        t('None')
      )}
    </KwPanel>
  );
};

CompanyKeyProviders.propTypes = {
  // the full list of available key providers
  keyProviders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  // this companies key providers
  companyKeyProviders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      reference: PropTypes.string,
    })
  ),
  // single item change
  onChange: PropTypes.func,
  // adding a new key provider reference
  onAdd: PropTypes.func,
  // removing a key provider reference
  onRemove: PropTypes.func,
};
export default CompanyKeyProviders;
