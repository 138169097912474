import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import ImageFromApi from '../ImageFromApi';
import icons from '../../../constants/icons';
import { bestContrast } from '../../../util/formatter';
import { useTranslation } from 'react-i18next';

function PlatformLink({
  id,
  name,
  currentUser,
  imageId,
  imageSize,
  platforms,
  hideImage,
  colourHex,
  className,
}) {
  const { t } = useTranslation();
  const [image, setImage] = useState(imageId);
  useEffect(() => {
    if (!imageId && platforms) {
      setImage(platforms.find((x) => x.id === id)?.imageId);
    }
  }, [imageId, platforms, id]);

  const [colour, setColour] = useState(colourHex);
  const [textClass, setTextClass] = useState('text-light');
  useEffect(() => {
    if (!colourHex && platforms) {
      let _colour = platforms.find((x) => x.id === id)?.colourHex;
      setColour(_colour);

      setTextClass(
        colour ? bestContrast(_colour, 'text-light', 'text-dark') : 'text-light'
      );
    }
  }, [colourHex, textClass, colour, platforms, id]);

  return (
    <div className={`d-flex align-items-center ${className}`}>
      {!hideImage && (id || name) && (
        <ImageFromApi
          imageId={image}
          imageSize={imageSize}
          fallbackIcon={icons.PLATFORM}
          className={textClass}
          colourHex={colour}
        ></ImageFromApi>
      )}
      {id && name ? (
        hasPermission(currentUser, permissionTypes.MANAGE_PLATFORMS) ? (
          <NavLink to={`/settings/platform/${id}`} title={t('View platform')}>
            {name}
          </NavLink>
        ) : (
          name
        )
      ) : name ? (
        name
      ) : (
        '-'
      )}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    platforms: state.platforms,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformLink);
