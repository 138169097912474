import React, { useState } from 'react';
import {
  loadIngestedTransactions,
  exportIngestedTransactionsData,
} from '../../../actions/keyCodeActions';
import { Link, NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal, Date_AsString } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import TransactionStatusBadge from './TransactionStatusBadge';

import KebabMenu from '../../common/KebabMenu';
import Button from '../../common/Button';
import icons from '../../../constants/icons';
import UserLink from '../../common/links/UserLink';
import { useTranslation } from 'react-i18next';

function IngestedBatchesList({
  location,
  reload,
  onRemove,
  onRetry,
  setBreadcrumb,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'uploadedDate',
    'gameName',
    'keyWorkflowName',
    'requestReference',
    'tag1',
    'keyCount',
    'manualImportUser',
    'status',
  ];
  const suffixColumns = ['id'];
  const defaultVisibleColumns = allColumns;
  const [transactions, setTransactions] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'ingestedbatcheslist-settings',
      getTableSettingsDefaults({
        defaultSort: 'uploadedDate',
        direction: 'desc',
        defaultVisibleColumns,
      }),
      null,
      loadIngestedTransactions,
      setTransactions,
      reload,
      t('Failed to load ingested transactions'),
      location
    );

  let getToolTip = (transaction) => {
    if (!transaction.keysDirty) return '';
    let tooltip = '';
    if (transaction.requestId != null) {
      tooltip = t('Already assigned to a request');
    } else if (transaction.keysDirty) {
      tooltip = t('Keys have already been allocated');
    } else if (transaction.codesImported !== true) {
      tooltip = t('Keys are being processed and cannot be stopped');
    }
    return tooltip;
  };

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'uploadedDate',
      Label: t('Uploaded date'),
      width: '10%',
      Render: (item) => (
        <Link to={'/keys/ingested-batches/' + item.id}>
          {Date_AsString(item.uploadedDate)}
        </Link>
      ),
      // TODO: We have no date filtering yet
      // Filter: {
      //   Render: (col) => (
      //     <SimpleTextFilter
      //       Key={col.Key}
      //       Value={params.get(`f_${[col.Key]}`) || ''}
      //       onChange={(value) => filterChange(col.Key, value)}
      //     />
      //   ),
      // },
    },
    {
      Key: 'gameName',
      Label: t('Game'),
      width: '15%',
      Render: (item) => (
        <>
          <NavLink to={`/sku/${item.gameSkuId}`} className="game-title">
            {item.gameName}
          </NavLink>
          <br />
          <NavLink to={`/sku/${item.gameSkuId}`}>{item.gameSkuName}</NavLink>
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyWorkflowName',
      Label: t('Key pool'),
      width: '10%',
      Render: (item) =>
        item.keyWorkflowId ? (
          <Link to={'/settings/workflow/' + item.keyWorkflowId}>
            {item.keyWorkflowName}
          </Link>
        ) : (
          '--'
        ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestReference',
      Label: t('Request'),
      width: '10%',
      Render: (item) =>
        item.requestGroupId ? (
          <Link to={'/request/' + item.requestGroupId}>
            {item.requestReference}
          </Link>
        ) : (
          ''
        ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'tag1',
      Label: t('Tag'),
      width: '20%',
      Render: (item) => item.tag1,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyCount',
      Label: t('Key count'),
      width: '5%',
      className: 'text-right num no-wrap',
      Render: (item) =>
        item.keyCount >= 0 ? CommaNumber_NoDecimal(item.keyCount) : '--',
    },
    {
      Key: 'manualImportUser',
      Label: t('Uploaded by'),
      width: '10%',
      className: '',
      Render: (item) => (
        <>
          <UserLink id={item.manualImportUserId} name={item.manualImportUser} />
          <br />
          {item.manualImportUserCompany}
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'status',
      Label: t('Status'),
      width: '10%',
      className: '',
      Render: (item) => (
        <TransactionStatusBadge
          success={item.success}
          partialSuccess={item.partialSuccess}
          message={item.message}
          status={item.status}
        ></TransactionStatusBadge>
      ),
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('All')}</option>
            <option value="failed">{t('Failed')}</option>
            <option value="pending">{t('Pending')}</option>
            <option value="success">{t('Success')}</option>
          </select>
        ),
      },
    },
    {
      Key: 'id',
      Label: '',
      className: 'text-right no-navigate no-wrap',
      width: '7%',
      Sort: false,
      Render: (item) => (
        <KebabMenu
          take={1}
          className="inline-icons"
          buttons={[
            <Button
              className="btn btn-default me-2"
              isDisabled={item.keysDirty}
              title={getToolTip(item)}
              onClick={(e) => onRemove(e, item)}
              icon={icons.DELETE}
              text={t('Remove')}
            />,
            <Button
              className="btn btn-default me-2"
              isDisabled={item.keysDirty}
              title={getToolTip(item)}
              onClick={(e) => onRetry(e, item)}
              icon={icons.RETRY}
              text={t('Retry')}
            />,
          ]}
        ></KebabMenu>
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return transactions ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        suffixColumns={suffixColumnsWithConfig}
        allColumns={allColumns}
        rows={transactions.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={transactions.totalRowCount}
        filteredRowCount={transactions.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportIngestedTransactionsData}
        scopes={transactions.scopes}
        location={location}
        setBreadcrumb={setBreadcrumb}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default IngestedBatchesList;
