import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../../constants/icons';
import FormStatic from '../../common/FormStatic';
import {
  CommaNumber_NoDecimal,
  CommaNumber_WithDecimal,
} from '../../../util/formatter';
import YesNoLabel from '../../common/YesNoLabel';
import CollapsePanel from '../../common/CollapsePanel';
import ImageFromApi from '../../common/ImageFromApi';
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import { useTranslation } from 'react-i18next';

const CompanyDetails = ({ company }) => {
  const muhahaha = (e) => {
    const audio = new Audio('/content/sound/muhahaha.mp3');
    audio.play();
  };
  const { t } = useTranslation();

  return (
    <CollapsePanel
      storageKey={'companyDetails'}
      header={
        <>
          <div className="game-img-wrapper me-4">
            <ImageFromApi
              imageId={company.imageId}
              imageSize={32}
              fallbackIcon={icons.COMPANY}
              colourHex={company.colourHex}
            ></ImageFromApi>
          </div>
          <h2 className="no-margin">
            {company.companyName || t('Company')}
            {company.companyTypeName && (
              <span className="ms-3">({company.companyTypeName})</span>
            )}
          </h2>
          <div
            className="ms-4"
            style={{
              height: 35,
              width: 100,
            }}
            title={t('Request activity last 90 days')}
          >
            <Sparklines
              data={[
                company.requestActivityScore30to60Days,
                company.requestActivityScore7to30Days,
                company.requestActivityScoreLast7Days,
              ]}
              min={0}
              max={100}
              height={35}
              width={100}
              style={{
                opacity:
                  company.requestActivityScore30to60Days +
                    company.requestActivityScore7to30Days +
                    company.requestActivityScoreLast7Days >
                  0
                    ? 1
                    : 0.5,
              }}
            >
              <SparklinesCurve color="#46b29d" />
            </Sparklines>
          </div>
          <div
            className="ms-4"
            style={{
              height: 35,
              width: 100,
            }}
            title={t('Codes requested last 90 days')}
          >
            <Sparklines
              data={[
                company.keyActivityScore30to60Days,
                company.keyActivityScore7to30Days,
                company.keyActivityScoreLast7Days,
              ]}
              min={0}
              max={100}
              height={35}
              width={100}
              style={{
                opacity:
                  company.keyActivityScore30to60Days +
                    company.keyActivityScore7to30Days +
                    company.keyActivityScoreLast7Days >
                  0
                    ? 1
                    : 0.5,
              }}
            >
              <SparklinesCurve color="#bb5ebf" />
            </Sparklines>
          </div>

          <div className="flex-1 text-right">
            {company.isDeleted && (
              <h1 className="archived no-margin">
                <i
                  className={icons.ARCHIVED}
                  style={{ cursor: 'pointer' }}
                  onClick={muhahaha}
                  tabIndex={0}
                ></i>{' '}
                {t('Deleted')}
              </h1>
            )}
          </div>
        </>
      }
    >
      <div className="col-count-2">
        <FormStatic label={t('Name')}>{company.companyName}</FormStatic>
        <FormStatic label={t('Company type')}>
          {company.companyTypeName || '-'}
        </FormStatic>
        <FormStatic label={t('Maximum value of all open requests')}>
          {company.transactionLimit
            ? CommaNumber_WithDecimal(company.transactionLimit)
            : t('No limit')}
        </FormStatic>
        <FormStatic label={t('Maximum balance of all allocated keys')}>
          {company.globalTransactionLimit
            ? CommaNumber_NoDecimal(company.globalTransactionLimit)
            : t('No limit')}
        </FormStatic>
        <FormStatic label={t('Request visible to all users')}>
          <YesNoLabel value={company.requestVisibleToAllUsers} />
        </FormStatic>
        <FormStatic label={t('Pro-forma invoicing')}>
          <YesNoLabel value={company.isProformaInvoicing} />
        </FormStatic>
        <FormStatic label={t('Key provider references')} className="flex-wrap">
          {!company.companyKeyProviders ||
          company.companyKeyProviders.length === 0 ? (
            <span>{t('None')}</span>
          ) : (
            company.companyKeyProviders
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((x, i) => {
                return (
                  <span className="me-2" key={x.id}>
                    {x.name} <span>({x.reference})</span>
                    {i + 1 < company.companyKeyProviders.length ? ', ' : ''}
                  </span>
                );
              })
          )}
        </FormStatic>
        <FormStatic label={t('Is publisher')}>
          <YesNoLabel value={company.isPublisher} />
        </FormStatic>
        <FormStatic label={t('Is developer')}>
          <YesNoLabel value={company.isDeveloper} />
        </FormStatic>
        <FormStatic label={t('Domains')}>
          {company.domains?.join(', ')}
        </FormStatic>
      </div>
    </CollapsePanel>
  );
};

CompanyDetails.propTypes = {
  company: PropTypes.object.isRequired,
};

export default CompanyDetails;
