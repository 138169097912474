import React from 'react';
import LeftNavWrapper from '../menu-components/LeftNavWrapper';
import LeftMenuItem from '../menu-components/LeftMenuItem';
import permissionTypes from '../../../constants/permissionTypes';
import icons from '../../../constants/icons';
import NavColours from '../../../constants/NavColours';
import { scopes as tableScopes } from '../../../constants/scopes';
import { useTranslation } from 'react-i18next';

function LeftMenu_User({ currentUser, wrapperClassName, companyTypes }) {
  const { t } = useTranslation();

  return (
    <LeftNavWrapper className={wrapperClassName}>
      <LeftMenuItem
        url="/users/"
        activeRegex="\/user(s)?\/(?!\?)"
        icon={icons.USER}
        colour={NavColours.NAV_COLOUR_1}
        title={t('All users')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_USERS]}
      />
      {companyTypes && <hr />}
      {companyTypes &&
        [...companyTypes]
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((x) => (
            <LeftMenuItem
              key={x.id}
              url={`/users/?f_companyTypeId=${x.id}`}
              icon={icons.USER}
              colour={NavColours.NAV_COLOUR_2}
              title={x.name}
              currentUser={currentUser}
              allowedPermissions={[permissionTypes.MANAGE_USERS]}
            />
          ))}
      {companyTypes && <hr />}
      <LeftMenuItem
        url={`/users?${tableScopes.ISARCHIVED}=true`}
        icon={icons.DELETE}
        colour={NavColours.NAV_COLOUR_3}
        title={t('Archived users')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_USERS]}
      />
    </LeftNavWrapper>
  );
}

export default LeftMenu_User;
