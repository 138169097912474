import React from 'react';

const announcementHelp = Object.freeze({
  ANNOUNCEMENT_DATE: {
    en: <p>The date that the announcement will be displayed to the user.</p>,
    ja: <p></p>,
  },
  EXPIRY_DATE: {
    en: (
      <p>
        The date that the announcement will be removed from the user's view. If
        this is the same date as the announcement date, then the announcement
        will be displayed for one day.
      </p>
    ),
    ja: <p></p>,
    fr: <p></p>,
    de: <p></p>,
    es: <p></p>,
    it: <p></p>,
  },
});

export default announcementHelp;
