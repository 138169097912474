import React, { useState } from 'react';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { connect } from 'react-redux';
import { saveKeyProvider } from '../../../../actions/keyProviderActions';
import { toast } from 'react-toastify';
import KeyProviderForm from '../components/KeyProviderForm';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import KwPanel from '../../../common/KwPanel';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';

function Add({ done, id, cancel, saveKeyProvider }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const [file, setFile] = useState();

  const onSubmit = (data) => {
    saveKeyProvider({ ...keyProvider, ...data, file })
      .then(() => {
        toast.success(t('Key provider saved'));
        done(keyProvider);
      })
      .catch(() => {
        toast.error(t('Failed to save key provider'));
      });
  };

  const [keyProvider] = useState({});

  return (
    <SlideoutLayout
      title={id ? t('Edit key provider') : t('Add key provider')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="submit"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <KwPanel className="mb-4 pb-1">
        <KeyProviderForm
          keyProvider={keyProvider}
          register={register}
          errors={errors}
          control={control}
          setFile={setFile}
        ></KeyProviderForm>
      </KwPanel>
    </SlideoutLayout>
  );
}
Add.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  saveKeyProvider: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  saveKeyProvider,
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
