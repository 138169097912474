import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import KwPanel from '../../../common/KwPanel';

import {
  deletePermissionGroup,
  deletePermissionUser,
} from '../../../../actions/permissionActions';
import { toast } from 'react-toastify';
import Button from '../../../common/Button';
import ConfirmationHeader from '../../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function RemoveMember({
  done,
  permissionKeys,
  userProfileGroupId,
  userProfileId,
  companyId,
  cancel,
}) {
  const { t } = useTranslation();
  const handleDelete = () => {
    if (userProfileGroupId) {
      deletePermissionGroup(permissionKeys, userProfileGroupId)
        .then(() => {
          toast.success(t('Member successfully removed'));
          done();
        })
        .catch((ex) => {
          console.error(ex);
          toast.error(t('Failed to remove member'));
        });
    } else if (userProfileId) {
      deletePermissionUser(permissionKeys, userProfileId)
        .then(() => {
          toast.success(t('Member successfully removed'));
          done();
        })
        .catch((ex) => {
          console.error(ex);
          toast.error(t('Failed to remove member'));
        });
    }
  };

  return (
    <SlideoutLayout
      title={t('Remove confirmation')}
      cancel={cancel}
      done={done}
    >
      <KwPanel>
        <ConfirmationHeader text="Are you sure?" />
        <Button
          className="btn btn-default me-2"
          onClick={handleDelete}
          text={t('Yes, remove this member')}
        />
        <Button
          className="btn btn-primary"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
RemoveMember.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveMember);
