import React from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import FormTextArea from '../../common/FormTextArea';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function Add({ done, cancel }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    done(data);
  };

  return (
    <SlideoutLayout
      title={t('Add comment')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <FormTextArea
        autoFocus={true}
        register={register}
        required={true}
        error={errors.register}
        errorMessage={t('Required')}
        name="comment"
        placeholder={t('Your comment here..')}
      />
    </SlideoutLayout>
  );
}
Add.propTypes = {
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default Add;
