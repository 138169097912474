import React from 'react';
import FormSelect from '../../common/FormSelect';
import { useTranslation } from 'react-i18next';

export default function CompanyFormSelect({
  company,
  companies,
  handleCompanyChange,
}) {
  const { t } = useTranslation();
  return (
    <FormSelect
      name="company"
      label={t('Company')}
      onChange={handleCompanyChange}
      defaultValue={company}
    >
      <option value="">{t('---all---')}</option>
      {companies &&
        [...companies]
          ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
          .map((x) => (
            <option key={x.id} value={x.id}>
              {x.companyName}
            </option>
          ))}
    </FormSelect>
  );
}
