import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.CREATE_PLATFORM:
      return state ? [...state, action.platform] : state;
    case actionTypes.UPDATE_PLATFORM:
      return state
        ? state.map((platform) =>
            platform.id === action.platform.id ? action.platform : platform
          )
        : state;
    case actionTypes.LOAD_PLATFORMS:
      return action.platforms;
    case actionTypes.DELETE_PLATFORM:
      return state
        ? state.filter((platform) => platform.id !== action.id)
        : state;
    default:
      return state;
  }
}
