import React, { useEffect, useState } from 'react';
import FormButtonGroup from '../../../common/FormButtonGroup';
import WizardStepItem from './WizardStepItem';
import icons from '../../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export default function SelectSKUStep({ skus, handleSKUSelect }) {
  const [filteredSKUs, setFilteredSKUs] = useState(skus);
  const [filter, setFilter] = useState('All');
  const { t } = useTranslation();
  const platforms = [
    'All',
    ...Array.from(new Set(skus?.map((x) => x.platform))),
  ];
  const onChange = (platform) => {
    setFilter(platform);
  };
  useEffect(() => {
    setFilteredSKUs(
      skus?.filter((x) => filter === 'All' || x.platform === filter)
    );
  }, [filter, skus]);

  return (
    <>
      <h2 className="mb-4">{t('Choose the SKU')}</h2>
      {platforms?.length > 2 && (
        <FormButtonGroup
          toggleGroupClassName="full-width"
          buttons={platforms}
          onChange={onChange}
          value={filter}
        ></FormButtonGroup>
      )}
      {filteredSKUs
        ? [...filteredSKUs]
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((sku) => (
              <WizardStepItem
                key={sku.skuId}
                id={sku.skuId}
                title={sku.name}
                description={
                  <>
                    {sku.platform && (
                      <div className="text-blue" title={t('Platform')}>
                        {sku.platform}
                      </div>
                    )}
                    {sku.packageId && (
                      <div className="text-yellow" title={t('package Id')}>
                        <FontAwesomeIcon
                          icon={icons.APP_ID}
                          className={`me-2`}
                        />
                        {sku.packageId}
                      </div>
                    )}
                  </>
                }
                icon={icons.WIZARD_NEXT}
                typeIcon={`${icons.GAME} c-orange`}
                onClick={handleSKUSelect}
              ></WizardStepItem>
            ))
        : ''}
    </>
  );
}
