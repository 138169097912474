import { apiUrl, getList, exportData } from './apiUtils';
const baseUrl = apiUrl + '/metrics/';

export function gameKeys(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}keys/games`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportGameKeysData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/metrics/keys/games`,
    'gameName',
    filters,
    scopes
  );
}

export function keyPools(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}keys/pools`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportKeyPoolsData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/metrics/keys/pools`,
    'gameName',
    filters,
    scopes
  );
}
