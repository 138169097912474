import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { navPermissionCheck } from './authUtils';
import Loading from '../common/Loading';
import { useTranslation } from 'react-i18next';

function PrivateRoute({
  component: Component,
  currentUser,
  allowedPermissions,
  allowedUserTypes,
  isPowerUser,
  location,
  ...rest
}) {
  const { t } = useTranslation();
  if (currentUser == null) {
    return <Loading message={t('logging in...')} />;
  }

  if (currentUser && currentUser.isLoading) {
    return <Loading message={t('Please wait..')} />;
  }

  // if this route has permissions, check agains the user
  let hasPermission = navPermissionCheck(
    currentUser,
    allowedPermissions,
    allowedUserTypes,
    isPowerUser
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        hasPermission ? (
          <Component {...props} {...rest} location={location} />
        ) : (
          <Redirect to="/access-denied" />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  allowedPermissions: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
