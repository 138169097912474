import React from 'react';
import { connect } from 'react-redux';
import { isPowerUser } from '../auth/authUtils';
import HomeAdvanced from './HomeAdvanced';
import HomeBasic from './HomeBasic';

function Home({ currentUser, location, history }) {
  return (
    <>
      {isPowerUser(currentUser) ? (
        <HomeAdvanced location={location} history={history} />
      ) : (
        <HomeBasic location={location} />
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
