import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GameAdvanced from './GameAdvanced';
import GameBasic from './GameBasic';
import { hasAnyPermissionForCompany } from '../auth/authUtils';
import permissionTypes from '../../constants/permissionTypes';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { getGameById } from '../../actions/gameActions';

const ManageGame = ({
  id,
  currentUser,
  location,
  setCompleteRequestSlideoutOpen,
  history,
}) => {
  const { t } = useTranslation();
  const [game, setGame] = useState();
  const [reloadGame, setReloadGame] = useState();

  useEffect(() => {
    if (id) {
      // get the game
      getGameById(id)
        .then((d) => {
          setGame(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/games');
          toast.error(e.message || t('Game not found'));
        });
    }
  }, [id, history, reloadGame, t]);

  return game ? (
    <>
      {hasAnyPermissionForCompany(
        currentUser,
        [permissionTypes.MANAGE_GAMES, permissionTypes.MANAGE_KEYS],
        game.publisherCompanyId
      ) ? (
        <GameAdvanced
          key={id}
          location={location}
          id={id}
          setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
          game={game}
          setGame={setGame}
          reloadGame={reloadGame}
          setReloadGame={setReloadGame}
        />
      ) : (
        <GameBasic
          key={id}
          location={location}
          id={id}
          setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
          game={game}
        />
      )}
    </>
  ) : (
    <></>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    location: ownProps.location,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageGame)
);
