import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CompaniesList from './components/CompaniesList';
import ActionBar from '../layout/ActionBar';
import AddCompany from './slideout/AddCompany';
import { getAllCompanyTypes } from '../../actions/companyTypeActions';
import SlidingPane from 'react-sliding-pane';
import { useTranslation } from 'react-i18next';

function Companies({ location, companyTypes, getAllCompanyTypes }) {
  const [reload, setReload] = useState();
  const [addCompanySlideoutOpen, setAddCompanySlideoutOpen] = useState(false);
  const { t } = useTranslation();
  //companies
  useEffect(() => {
    if (!companyTypes) {
      getAllCompanyTypes().catch((e) => {});
    }
  }, [companyTypes, getAllCompanyTypes]);
  return (
    <>
      <ActionBar>
        <button
          className="btn btn-primary"
          onClick={() => setAddCompanySlideoutOpen(true)}
        >
          {t('Add company')}
        </button>
      </ActionBar>
      <CompaniesList
        location={location}
        reload={reload}
        companyTypes={companyTypes}
      />

      <SlidingPane
        isOpen={addCompanySlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddCompanySlideoutOpen(false)}
      >
        <AddCompany
          done={(savedCompany) => {
            if (savedCompany) {
              // redirect away
              window.location.assign(`/company/${savedCompany.id}`);
            }
            setAddCompanySlideoutOpen(false);
          }}
          cancel={() => setAddCompanySlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    companyTypes: state.companyTypes,
  };
}

const mapDispatchToProps = { getAllCompanyTypes };

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
