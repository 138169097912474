import React from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import { navPermissionCheck } from '../../auth/authUtils';

function TopMenuItem(props) {
  let hasPermission = navPermissionCheck(
    props.currentUser,
    props.allowedPermissions,
    props.allowedUserTypes,
    props.isPowerUser
  );

  return hasPermission ? (
    <li
      key={props}
      className={`float-left ${
        props.activeRegex !== undefined
          ? props.location.pathname.match(RegExp(props.activeRegex))
            ? 'is-current'
            : ''
          : props.location.pathname.startsWith(
                props.activeif !== undefined ? props.activeif : props.url
              )
            ? 'is-current'
            : ''
      }`}
    >
      <NavLink
        tag={Link}
        className="no-pad-tb bold"
        activeClassName="is-current"
        to={props.url}
      >
        {props.title}
      </NavLink>
      {props.children}
    </li>
  ) : (
    ''
  );
}

export default withRouter(TopMenuItem);
