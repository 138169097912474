import * as workflowApi from '../api/workflowApi';
import actionTypes from '../constants/actionTypes';

export function saveWorkflow(workflow) {
  return function (dispatch) {
    return workflowApi.saveWorkflow(workflow).then((savedWorkflow) => {
      dispatch({
        type: workflow.id
          ? actionTypes.UPDATE_WORKFLOW
          : actionTypes.CREATE_WORKFLOW,
        workflow: savedWorkflow,
      });
    });
  };
}

export function getWorkflowById(id) {
  return workflowApi.getWorkflowById(id);
}

export function getRequestableKeyWorkflows(companyId, gameId, skuId) {
  return workflowApi.getRequestableKeyWorkflows(companyId, gameId, skuId);
}

export function loadWorkflows({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return workflowApi.getWorkflows(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAllWorkflows() {
  return function (dispatch) {
    return workflowApi.getAllWorkflows().then((workflows) => {
      dispatch({
        type: actionTypes.LOAD_WORKFLOWS,
        workflows,
      });
    });
  };
}

export function getGamesForWorkflows(id, companyId) {
  return workflowApi.getGamesForWorkflows(id, companyId);
}

export function getStats(id) {
  return workflowApi.getStats(id);
}

export function getStatsByDay(id) {
  return workflowApi.getStatsByDay(id);
}

export function getAggregateStats(id, from, to) {
  return workflowApi.getAggregateStats(id, from, to);
}

export function getAudit(id) {
  return workflowApi.getAudit(id);
}

export function addAudit(id, comment) {
  return workflowApi.addAudit(id, comment);
}

export function deleteWorkflow(id) {
  return function (dispatch) {
    return workflowApi.deleteWorkflow(id).then(() => {
      dispatch({
        type: actionTypes.DELETE_WORKFLOW,
        id,
      });
    });
  };
}

export function cloneWorkflow({ id, name, withRestrictions }) {
  return function (dispatch) {
    return workflowApi
      .cloneWorkflow(id, name, withRestrictions)
      .then((newId) => {
        return newId;
      });
  };
}

// ==========================================
// STEPS
export function getWorkflowStepsById(id) {
  return workflowApi.getWorkflowStepsById(id);
}

export function saveWorkflowSteps(id, steps) {
  return workflowApi.saveWorkflowSteps(id, steps);
}

// ==========================================
// REQUESTERS
export function getWorkflowRequestersById(id) {
  return workflowApi.getWorkflowRequestersById(id);
}
// by group
export function addWorkflowRequesterGroup(id, userProfileGroupId) {
  return workflowApi.addWorkflowRequesterGroup(id, userProfileGroupId);
}
export function deleteWorkflowRequesterGroup(id, userProfileGroupId) {
  return workflowApi.deleteWorkflowRequesterGroup(id, userProfileGroupId);
}
// by company
export function addWorkflowRequesterCompany(id, companyId) {
  return workflowApi.addWorkflowRequesterCompany(id, companyId);
}
export function deleteWorkflowRequesterCompany(id, companyId) {
  return workflowApi.deleteWorkflowRequesterCompany(id, companyId);
}
// by user
export function addWorkflowRequesterUser(id, userId) {
  return workflowApi.addWorkflowRequesterUser(id, userId);
}
export function deleteWorkflowRequesterUser(id, userId) {
  return workflowApi.deleteWorkflowRequesterUser(id, userId);
}
// ==========================================
// TAGS
export function getWorkflowTagsById(id) {
  return workflowApi.getWorkflowTagsById(id);
}

export function addWorkflowTag(id, tag) {
  return workflowApi.addWorkflowTag(id, tag);
}

export function deleteWorkflowTag(id, tag) {
  return workflowApi.deleteWorkflowTag(id, tag);
}

export function exportWorkflowData(filters, scopes) {
  return workflowApi.exportWorkflowData(filters, scopes);
}

// ==========================================
// KEY POOLS
export function getKeyPoolBalance(keyWorkflowId, companyId, gameId, skuId) {
  return workflowApi.getKeyPoolBalance(keyWorkflowId, companyId, gameId, skuId);
}

export function loadApproverNotificationUsers({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return workflowApi.getApproverNotificationUsers(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportApproverNotificationUsers(filters, scopes) {
  return workflowApi.exportApproverNotificationUsers(filters, scopes);
}

export function sendApproverNotification(keyWorkflowStepId) {
  return workflowApi.sendApproverNotification(keyWorkflowStepId);
}

export function addKeyWorkflowStepNotificationFilter(
  keyWorkflowStepId,
  userProfileId
) {
  return workflowApi.addKeyWorkflowStepNotificationFilter(
    keyWorkflowStepId,
    userProfileId
  );
}

export function deleteKeyWorkflowStepNotificationFilter(
  keyWorkflowStepId,
  userProfileId
) {
  return workflowApi.deleteKeyWorkflowStepNotificationFilter(
    keyWorkflowStepId,
    userProfileId
  );
}
