import * as uploaderApi from '../api/uploaderApi';

export function saveUploader(uploader) {
  return uploaderApi.saveUploader(uploader);
}

export function getUploaderById(id) {
  return uploaderApi.getUploaderById(id);
}

export function loadUploaders({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return uploaderApi.getUploaders(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function deleteUploader(id) {
  return uploaderApi.deleteUploader(id);
}

export function getUploadersForKeyProvider(keyProviderId) {
  return uploaderApi.getUploadersForKeyProvider(keyProviderId);
}

export function getUploadersForKeyWorkflow(keyWorkflowId) {
  return uploaderApi.getUploadersForKeyWorkflow(keyWorkflowId);
}
export function getUploadersForPlatform(platformId) {
  return uploaderApi.getUploadersForPlatform(platformId);
}

export function exportUploadersData(filters, scopes) {
  return uploaderApi.exportUploadersData(filters, scopes);
}
