import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CommaNumber_WithDecimal } from '../../../../util/formatter';
import Gravatar from 'react-gravatar';
import FormStatic from '../../../common/FormStatic';
import CompanyLink from '../../../common/links/CompanyLink';
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import YesNoLabel from '../../../common/YesNoLabel';
import CollapsePanel from '../../../common/CollapsePanel';
import TerritoryLink from '../../../common/links/TerritoryLink';
import { getAllTerritories } from '../../../../actions/territoryActions';
import { useTranslation } from 'react-i18next';
import icons from '../../../../constants/icons';

const UserDetails = ({
  user,
  userProfileGroups,
  permissions,
  userProfileTerritories,
  currentUser,
  getAllTerritories,
  territories,
}) => {
  const { t } = useTranslation();
  const muhahaha = (e) => {
    const audio = new Audio('/content/sound/muhahaha.mp3');
    audio.play();
  };

  useEffect(() => {
    if (!territories) {
      getAllTerritories().catch(() => {});
    }
  }, [territories, getAllTerritories]);

  return (
    <CollapsePanel
      storageKey={'userDetails'}
      header={
        <>
          <div className="user-img-wrapper me-4">
            <Gravatar
              email={user?.username}
              size={45}
              rating="pg"
              default="retro"
              className="img-circle"
            />
          </div>
          <h2 className="no-margin">
            {user.fullName || t('user')}
            <span className="ms-3">({user.companyName})</span>
          </h2>
          <div
            className="ms-4"
            style={{
              height: 35,
              width: 100,
            }}
            title={t('Request activity last 90 days')}
          >
            <Sparklines
              data={[
                user.requestActivityScore30to60Days,
                user.requestActivityScore7to30Days,
                user.requestActivityScoreLast7Days,
              ]}
              min={0}
              max={100}
              height={35}
              width={100}
              style={{
                opacity:
                  user.requestActivityScore30to60Days +
                    user.requestActivityScore7to30Days +
                    user.requestActivityScoreLast7Days >
                  0
                    ? 1
                    : 0.5,
              }}
            >
              <SparklinesCurve color="#46b29d" />
            </Sparklines>
          </div>
          <div
            className="ms-4"
            style={{
              height: 35,
              width: 100,
            }}
            title={t('Codes requested last 90 days')}
          >
            <Sparklines
              data={[
                user.keyActivityScore30to60Days,
                user.keyActivityScore7to30Days,
                user.keyActivityScoreLast7Days,
              ]}
              min={0}
              max={100}
              height={35}
              width={100}
              style={{
                opacity:
                  user.keyActivityScore30to60Days +
                    user.keyActivityScore7to30Days +
                    user.keyActivityScoreLast7Days >
                  0
                    ? 1
                    : 0.5,
              }}
            >
              <SparklinesCurve color="#bb5ebf" />
            </Sparklines>
          </div>

          <div className="flex-1 text-right">
            {user.isArchived && (
              <h1 className="archived no-margin">
                <i
                  className={icons.ARCHIVED}
                  style={{ cursor: 'pointer' }}
                  onClick={muhahaha}
                ></i>{' '}
                {t('Archived')}
              </h1>
            )}
          </div>
        </>
      }
    >
      <div className="col-count-2">
        <FormStatic label={t('Name')}>{user.fullName}</FormStatic>
        <FormStatic label={t('Company')}>
          <CompanyLink id={user.companyId} name={user.companyName} />{' '}
        </FormStatic>
        <FormStatic label={t('Email')}>{user.emailAddress || '-'}</FormStatic>
        <FormStatic label={t('Credit limit')}>
          {user.creditLimit ? CommaNumber_WithDecimal(user.creditLimit) : '-'}
        </FormStatic>
        <FormStatic label={t('Is authorised')}>
          <YesNoLabel value={user.isAuthorised} />
        </FormStatic>
        <FormStatic label={t('Group membership')} className="flex-wrap">
          {!userProfileGroups || userProfileGroups.length === 0 ? (
            <span>{t('None')}</span>
          ) : (
            userProfileGroups
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((x, i) => {
                return (
                  <span className="me-2" key={x.id}>
                    <NavLink
                      key={x.name}
                      to={`/settings/user-profile-group/${x.id}`}
                      title={t('View group')}
                    >
                      {x.name}
                    </NavLink>
                    {i + 1 < userProfileGroups.length ? ', ' : ''}
                  </span>
                );
              })
          )}
        </FormStatic>
        <FormStatic label={t('Direct permissions')} className="flex-wrap">
          {!permissions || permissions.length === 0 ? (
            <span>{t('None')}</span>
          ) : (
            permissions
              ?.sort((a, b) => a.permissionName.localeCompare(b.permissionName))
              .map((x, i) => {
                return (
                  <span key={x.permissionName}>
                    <span className="me-2">
                      {x.permissionName}
                      {i + 1 < permissions.length ? ', ' : ''}
                    </span>
                  </span>
                );
              })
          )}
        </FormStatic>
        <FormStatic label={t('Allowed territories')} className="flex-wrap">
          {!userProfileTerritories || userProfileTerritories.length === 0 ? (
            <span>{t('All allowed')}</span>
          ) : (
            userProfileTerritories
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((x, i) => {
                return (
                  <span className="me-2" key={x.id}>
                    <TerritoryLink
                      name={x.name}
                      id={x.id}
                      colour={x.colourHex}
                      currentUser={currentUser}
                      territories={territories}
                    />
                    {i + 1 < userProfileTerritories.length ? ', ' : ''}
                  </span>
                );
              })
          )}
        </FormStatic>
      </div>
    </CollapsePanel>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    territories: state.territories,
  };
}

const mapDispatchToProps = {
  getAllTerritories: () => getAllTerritories(),
};

UserDetails.propTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
