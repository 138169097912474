import { handleResponse, handleError, apiUrl, apiOptions } from './apiUtils';

const baseUrl = apiUrl + '/search';
export function search(query) {
  return fetch(`${baseUrl}?query=${query}`, apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function searchRequests(query) {
  return fetch(`${baseUrl}/requests?query=${query}`, apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function searchRequestGroups(query) {
  return fetch(`${baseUrl}/requestGroups?query=${query}`, apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function searchGames(query) {
  return fetch(`${baseUrl}/games?query=${query}`, apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function searchUsers(query) {
  return fetch(`${baseUrl}/users?query=${query}`, apiOptions())
    .then(handleResponse)
    .catch(handleError);
}
