import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import FormFileUpload from '../../common/FormFileUpload';
import KwPanel from '../../common/KwPanel';
import { useForm } from 'react-hook-form';
import FormInput from '../../common/FormInput';
import FormDate from '../../common/FormDate';
import FormStatic from '../../common/FormStatic';
import { uploadToKeyPool } from '../../../actions/skuActions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function KeyPoolUpload({
  done,
  cancel,
  gameId,
  gameName,
  skuId,
  skuName,
  keyWorkflowId,
  keyWorkflowName,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [keys, setKeys] = useState();
  const addFiles = (files) => {
    setKeys({ ...keys, file: files[0] });
  };
  const onSubmit = (data) => {
    uploadToKeyPool(skuId, gameId, keyWorkflowId, { ...keys, ...data })
      .then(() => {
        toast.success(t('Keys successfully uploaded'));
        done();
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to upload keys'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Upload to key pool')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Upload')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!keys || keys.length === 0}
        />
      }
    >
      <KwPanel className="mb-4">
        <FormStatic label={t('Game')}>{gameName}</FormStatic>
        <FormStatic label={t('SKU')}>{skuName}</FormStatic>
        <FormStatic label={t('Pool')}>{keyWorkflowName}</FormStatic>
        <FormInput
          register={register}
          name="tag"
          label={t('Tag')}
          placeholder={t('Optional')}
        ></FormInput>
        <FormDate
          register={register}
          name="expiryDate"
          label={t('Expiry date')}
          placeholder={t('Optional')}
        />
        <FormFileUpload
          name="files"
          required
          errorMessage={t('Required')}
          label={t('Upload key file')}
          error={errors.files}
          addFiles={addFiles}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
KeyPoolUpload.propTypes = {
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  gameId: PropTypes.string.isRequired,
  gameName: PropTypes.string.isRequired,
  skuId: PropTypes.string.isRequired,
  skuName: PropTypes.string.isRequired,
  keyWorkflowId: PropTypes.string.isRequired,
  keyWorkflowName: PropTypes.string.isRequired,
};

export default KeyPoolUpload;
