import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getPlatformsForKeyProvider } from '../../../../actions/keyProviderActions';
import AddKeyProviderPlatform from './AddKeyProviderPlatform';
import DeleteKeyProviderPlatform from './DeleteKeyProviderPlatform';
import KwPanel from '../../../common/KwPanel';
import SlidingPane from 'react-sliding-pane';

import image_controller from '../../../../content/images/controller.svg';
import PlatformLink from '../../../common/links/PlatformLink';
import icons from '../../../../constants/icons';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function PlatformBadges({ label, keyProviderId }) {
  const [platforms, setPlatforms] = useState();
  const { t } = useTranslation();

  const [addPlatformSlideoutOpen, setAddPlatformSlideoutOpen] = useState(false);
  const [removePlatformSlideoutOpen, setRemovePlatformSlideoutOpen] =
    useState(false);
  const [removePlatformId, setRemovePlatformId] = useState();

  useEffect(() => {
    if (keyProviderId && !platforms) {
      // get platforms
      getPlatformsForKeyProvider(keyProviderId)
        .then((d) => {
          setPlatforms(d);
        })
        .catch((e) => {
          toast.error(t('Failed to find key provider platforms'));
        });
    }
  }, [keyProviderId, platforms]);

  return (
    <>
      <KwPanel className="mb-4">
        <header className="mb-2">
          <h2 className="no-margin">
            <span className="workflow-icon">
              <img
                src={image_controller}
                alt={t('Upload keys icon')}
                width="32"
                height="32"
              />
            </span>
            {label}
          </h2>
          <div className="float-right">
            <Button
              className="btn btn-default"
              onClick={(e) => {
                setAddPlatformSlideoutOpen(true);
              }}
              text={t('Add')}
            />
          </div>
        </header>
        <div>
          {platforms && platforms.length > 0 ? (
            <div className="tags">
              {platforms.map((platform) => (
                <div className="tag" key={platform.id}>
                  <div className="d-flex">
                    <PlatformLink id={platform.id} name={platform.name} />
                    <span
                      className="ms-3 a"
                      onClick={(e) => {
                        setRemovePlatformId(platform.id);
                        setRemovePlatformSlideoutOpen(true);
                      }}
                      title={t('Remove')}
                    >
                      <FontAwesomeIcon icon={icons.DELETE_TAG} />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <span>
              {t(
                'There have been no supported platforms defined for this key provider'
              )}
            </span>
          )}
        </div>
      </KwPanel>

      <SlidingPane
        isOpen={addPlatformSlideoutOpen}
        hideHeader={true}
        from="right"
        className="-side-panel"
        onRequestClose={() => setAddPlatformSlideoutOpen(false)}
      >
        <AddKeyProviderPlatform
          done={(d) => {
            setPlatforms([...platforms, d]);
            setAddPlatformSlideoutOpen(false);
          }}
          cancel={() => setAddPlatformSlideoutOpen(false)}
          keyProviderId={keyProviderId}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={removePlatformSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRemovePlatformSlideoutOpen(false)}
      >
        {removePlatformId && platforms && platforms.length ? (
          <DeleteKeyProviderPlatform
            keyProviderId={keyProviderId}
            platformId={removePlatformId}
            done={(d) => {
              setPlatforms(platforms.filter((r) => r.id !== removePlatformId));
              setRemovePlatformSlideoutOpen(false);
            }}
            cancel={() => setRemovePlatformSlideoutOpen(false)}
          />
        ) : (
          <></>
        )}
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformBadges);
