import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function NumberOfKeysHeader({
  bulkChangeNumberOfKeys,
  bulkNumberOfKeysOnBlur,
  bulkNumberOfKeys,
}) {
  const { t } = useTranslation();
  return (
    <div className="bulk-grid-game-name-th hidden-input">
      <span className="mb-2 text-right">{t('Number of keys')}</span>
      <input
        type="number"
        className="form-control num"
        onChange={bulkChangeNumberOfKeys}
        onBlur={bulkNumberOfKeysOnBlur}
        value={bulkNumberOfKeys || ''}
        placeholder={t('Apply to all')}
      ></input>
    </div>
  );
}

NumberOfKeysHeader.propTypes = {
  bulkChangeNumberOfKeys: PropTypes.func.isRequired,
  bulkNumberOfKeysOnBlur: PropTypes.func.isRequired,
  bulkNumberOfKeys: PropTypes.string,
};

export default NumberOfKeysHeader;
