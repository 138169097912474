import { Link } from 'react-router-dom';
import { SimpleTextFilter } from '../../../common/Table';
import GameLink from '../../../common/links/GameLink';
import CompanyLink from '../../../common/links/CompanyLink';
import {
  CommaNumber_NoDecimal,
  CommaNumber_WithDecimal,
  Date_AsString_OneLine,
  DateOnly_AsString_NoHtml,
  WorkflowWithJustification,
} from '../../../../util/formatter';
import UserLink from '../../../common/links/UserLink';
import TimeLabel from '../../../common/TimeLabel';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { RequestStatusLabel } from '../RequestStatusLabel';
import Checkbox from '../../../common/Checkbox';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RequestListButtons from '../RequestListButtons';
import requestStatusNames from '../../../../constants/requestStatusNames';
import PlatformLink from '../../../common/links/PlatformLink';
import { useTranslation } from 'react-i18next';

function RequestsColumnsConfig({
  params,
  requests,
  currentUser,
  filterChange,
  filtersChange,
  itemsChecked,
  setItemsChecked,
  handleShowDetails,
  handleDownloadKeys,
  handleUploadKeys,
  handleBulkStatusChange,
  handleBulkReassign,
  handleBulkSteamRequest,
  handleCopyRequest,
  handleReturnKeys,
  isProcessing,
}) {
  const { t } = useTranslation();
  const [
    requestCompletionDateDisplayValue,
    setRequestCompletionDateDisplayValue,
  ] = useState();

  const [requestDateDisplayValue, setRequestDateDisplayValue] = useState();
  useEffect(() => {
    if (!requestDateDisplayValue) {
      let _from = params.get(`f_requestedDateFrom`) || null;
      let _to = params.get(`f_requestedDateTo`) || null;
      if (_from && _to) {
        let fromDate = Date.parse(_from);
        let toDate = Date.parse(_to);
        if (isNaN(fromDate) || isNaN(toDate)) {
          setRequestDateDisplayValue('');
        } else {
          setRequestDateDisplayValue(`${_from} - ${_to}`);
        }
      }
    }
  }, [params, requestDateDisplayValue]);

  const [requiredByDateDisplayValue, setRequiredByDateDisplayValue] =
    useState();
  useEffect(() => {
    if (!requiredByDateDisplayValue) {
      let _from = params.get(`f_requiredByDateFrom`) || null;
      let _to = params.get(`f_requiredByDateTo`) || null;
      if (_from && _to) {
        let fromDate = Date.parse(_from);
        let toDate = Date.parse(_to);
        if (isNaN(fromDate) || isNaN(toDate)) {
          setRequiredByDateDisplayValue('');
        } else {
          setRequiredByDateDisplayValue(`${_from} - ${_to}`);
        }
      }
    }
  }, [params, requiredByDateDisplayValue]);

  useEffect(() => {
    if (!requestCompletionDateDisplayValue) {
      let _from = params.get(`f_requestCompletionDateFrom`) || null;
      let _to = params.get(`f_requestCompletionDateTo`) || null;
      if (_from && _to) {
        let fromDate = Date.parse(_from);
        let toDate = Date.parse(_to);
        if (isNaN(fromDate) || isNaN(toDate)) {
          setRequestCompletionDateDisplayValue('');
        } else {
          setRequestCompletionDateDisplayValue(`${_from} - ${_to}`);
        }
      }
    }
  }, [params, requestCompletionDateDisplayValue]);

  return [
    {
      Key: 'reference',
      Label: '#',
      width: '5%',
      className: 'no-wrap',
      Render: (row) => (
        <>
          <Link to={'/request/' + row.requestGroupId}>
            {row.groupReference}
          </Link>
          <br />
          <small>{row.reference}</small>
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'games',
      Label: t('Games'),
      width: '10%',
      Render: (row) => (
        <GameLink
          gameId={row.gameId}
          skuId={row.skuId}
          gameTitle={row.gameTitle}
          skuName={row.skuName}
          currentUser={currentUser}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'platform',
      Label: t('Platform'),
      width: '10%',
      Render: (row) => (
        <PlatformLink
          id={row.platformId}
          name={row.platform}
          key={`${row.id},${row.platformId}`}
          currentUser={currentUser}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'companyName',
      Label: t('Company'),
      width: '5%',
      Render: (row) => (
        <CompanyLink id={row.companyId} name={row.companyName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedByCompanyName',
      Label: t('Requested by Company'),
      width: '5%',
      Render: (row) => (
        <CompanyLink
          id={row.requestedByCompanyId}
          name={row.requestedByCompanyName}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'currentRequestValue',
      width: '5%',
      Label: t('Value'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_WithDecimal(row.currentRequestValue),
    },
    {
      Key: 'numberOfKeys',
      width: '5%',
      Label: t('Keys'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.numberOfKeys),
    },
    {
      Key: 'keyWorkflowName',
      width: '10%',
      Label: t('Type'),
      Render: (row) => WorkflowWithJustification(row),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedByFullName',
      width: '10%',
      Label: t('Requested by'),
      Render: (row) => (
        <>
          <UserLink id={row.requestedById} name={row.requestedByFullName} />
          <br />
          {row.requestedByCompanyName}
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedDate',
      width: '10%',
      Label: t('Request date'),
      Render: (row) => (
        <>
          <div>{Date_AsString_OneLine(row.requestedDate)}</div>
          <TimeLabel
            elapsedMinutesFormatted={row.elapsedMinutesFormatted}
            breached={row.isSLABreached}
            title="Time between request and completion"
            ongoing={row.requestCompletionDate ? false : true}
          />
        </>
      ),
      Filter: {
        Render: (col) => (
          <DateRangePicker
            initialSettings={{
              showDropdowns: true,
              minYear: 2010,
              buttonClasses: 'btn btn-default',
              autoUpdateInput: false,
              locale: {
                format: 'DD-MMM-YYYY',
                applyLabel: t('Apply'),
                cancelLabel: t('Clear'),
              },
            }}
            onApply={(e, picker) => {
              let from = picker.startDate.format('DD-MMM-YYYY');
              let to = picker.endDate.format('DD-MMM-YYYY');
              filtersChange([
                { key: 'requestedDateFrom', value: from },
                { key: 'requestedDateTo', value: to },
              ]);
              setRequestDateDisplayValue(`${from} - ${to}`);
            }}
            onCancel={(e, picker) => {
              picker.setStartDate(new Date());
              picker.setEndDate(new Date());
              filtersChange([
                { key: 'requestedDateFrom', value: '' },
                { key: 'requestedDateTo', value: '' },
              ]);
              setRequestDateDisplayValue('');
            }}
          >
            <input
              type="text"
              className={`form-control ${
                requestDateDisplayValue ? 'filtered' : ''
              }`}
              value={requestDateDisplayValue}
              onChange={(e) => {
                setRequestDateDisplayValue(e.target.value);
              }}
            />
          </DateRangePicker>
        ),
      },
    },
    {
      Key: 'requiredByDate',
      Label: t('Required by date'),
      Render: (row) => (
        <div>{DateOnly_AsString_NoHtml(row.requiredByDate)}</div>
      ),
      Filter: {
        Render: (col) => (
          <DateRangePicker
            initialSettings={{
              showDropdowns: true,
              minYear: 2010,
              buttonClasses: 'btn btn-default',
              autoUpdateInput: false,
              locale: {
                format: 'DD-MMM-YYYY',
                applyLabel: t('Apply'),
                cancelLabel: t('Clear'),
              },
            }}
            onApply={(e, picker) => {
              let from = picker.startDate.format('DD-MMM-YYYY');
              let to = picker.endDate.format('DD-MMM-YYYY');
              filtersChange([
                { key: 'requiredByDateFrom', value: from },
                { key: 'requiredByDateTo', value: to },
              ]);
              setRequiredByDateDisplayValue(`${from} - ${to}`);
            }}
            onCancel={(e, picker) => {
              picker.setStartDate(new Date());
              picker.setEndDate(new Date());
              filtersChange([
                { key: 'requiredByDateFrom', value: '' },
                { key: 'requiredByDateTo', value: '' },
              ]);
              setRequiredByDateDisplayValue('');
            }}
          >
            <input
              type="text"
              className={`form-control ${
                requiredByDateDisplayValue ? 'filtered' : ''
              }`}
              value={requiredByDateDisplayValue}
              onChange={(e) => {
                setRequiredByDateDisplayValue(e.target.value);
              }}
            />
          </DateRangePicker>
        ),
      },
    },
    {
      Key: 'requestCompletionDate',
      Label: t('Completed'),
      Render: (row) =>
        row.requestCompletionDate && (
          <>
            <div>{Date_AsString_OneLine(row.requestCompletionDate)}</div>
            <TimeLabel
              elapsedMinutesFormatted={row.elapsedMinutesFormatted}
              breached={row.isSLABreached}
              title={t('Time between request and completion')}
            />
          </>
        ),
      Filter: {
        Render: (col) => (
          <DateRangePicker
            initialSettings={{
              showDropdowns: true,
              minYear: 2010,
              buttonClasses: 'btn btn-default',
              autoUpdateInput: false,
              locale: {
                format: 'DD-MMM-YYYY',
                applyLabel: t('Apply'),
                cancelLabel: t('Clear'),
              },
            }}
            onApply={(e, picker) => {
              let from = picker.startDate.format('DD-MMM-YYYY');
              let to = picker.endDate.format('DD-MMM-YYYY');
              filtersChange([
                { key: 'requestCompletionDateFrom', value: from },
                { key: 'requestCompletionDateTo', value: to },
              ]);
              setRequestCompletionDateDisplayValue(`${from} - ${to}`);
            }}
            onCancel={(e, picker) => {
              picker.setStartDate(new Date());
              picker.setEndDate(new Date());
              filtersChange([
                { key: 'requestCompletionDateFrom', value: '' },
                { key: 'requestCompletionDateTo', value: '' },
              ]);
              setRequestCompletionDateDisplayValue('');
            }}
          >
            <input
              type="text"
              className={`form-control ${
                requestCompletionDateDisplayValue ? 'filtered' : ''
              }`}
              value={requestCompletionDateDisplayValue}
              onChange={(e) => {
                setRequestCompletionDateDisplayValue(e.target.value);
              }}
            />
          </DateRangePicker>
        ),
      },
    },
    {
      Key: 'currentUserProfileGroupName',
      Label: t('Waiting for'),
      width: '10%',
      Render: (row) => (
        <div
          tabIndex={0}
          className="a"
          onClick={(e) => handleShowDetails(e, row)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleShowDetails(e, row);
            }
          }}
        >
          {row.currentUserProfileGroupName}
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestStatus',
      Label: t('Status'),
      width: '10%',
      Render: (row) => (
        <RequestStatusLabel
          requestStatus={row.requestStatus}
          isWaitingForKeys={row.isWaitingForKeys}
          isUpdateInProgress={row.isUpdateInProgress}
          isDownloaded={row.downloaded}
        />
      ),
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">All</option>
            {requestStatusNames.map((x) => (
              <option key={x} value={x}>
                {t(x)}
              </option>
            ))}
          </select>
        ),
      },
    },
    {
      Key: 'assignedToUserProfileFullName',
      Label: t('Assigned to'),
      width: '10%',
      Render: (row) =>
        row.assignedToUserProfileId ? (
          <UserLink
            id={row.assignedToUserProfileId}
            name={row.assignedToUserProfileFullName}
          />
        ) : (
          <span className="unassigned">{t('Unassigned')}</span>
        ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'checkbox',
      Label: '',
      className: 'text-right no-navigate',
      Sort: false,
      width: '12%',
      Render: (item) => (
        <div className="d-flex align-items-center justify-content-end">
          <RequestListButtons
            row={item}
            handleDownloadKeys={handleDownloadKeys}
            handleUploadKeys={handleUploadKeys}
            handleBulkStatusChange={handleBulkStatusChange}
            handleBulkReassign={handleBulkReassign}
            handleBulkSteamRequest={handleBulkSteamRequest}
            handleCopyRequest={handleCopyRequest}
            handleReturnKeys={handleReturnKeys}
            isProcessing={isProcessing}
            currentUser={currentUser}
          />
          <Checkbox
            className="ms-3"
            checked={itemsChecked && itemsChecked.some((x) => x.id === item.id)}
            onChange={(checked) =>
              checked
                ? setItemsChecked([...itemsChecked, item])
                : setItemsChecked(itemsChecked.filter((x) => x.id !== item.id))
            }
          ></Checkbox>
        </div>
      ),
      Filter: {
        Render: () => (
          <Checkbox
            disabled={!requests}
            checked={false}
            onChange={(checked) =>
              checked ? setItemsChecked(requests?.data) : setItemsChecked([])
            }
          ></Checkbox>
        ),
      },
    },
  ];
}

RequestsColumnsConfig.propTypes = {
  params: PropTypes.object.isRequired,
  requests: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  filterChange: PropTypes.func.isRequired,
  filtersChange: PropTypes.func.isRequired,
  itemsChecked: PropTypes.array.isRequired,
  setItemsChecked: PropTypes.func.isRequired,
  handleShowDetails: PropTypes.func.isRequired,
  handleDownloadKeys: PropTypes.func.isRequired,
  handleUploadKeys: PropTypes.func.isRequired,
  handleBulkStatusChange: PropTypes.func.isRequired,
  handleBulkReassign: PropTypes.func.isRequired,
  handleBulkSteamRequest: PropTypes.func.isRequired,
  handleCopyRequest: PropTypes.func.isRequired,
  handleReturnKeys: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};

export default RequestsColumnsConfig;
