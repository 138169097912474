import React, { useState } from 'react';
import IngestedBatchesList from './components/IngestedBatchesList';
import ActionBar from '../layout/ActionBar';
import RemoveIngestedKeys from './slideout/RemoveIngestedKeys';
import SlidingPane from 'react-sliding-pane';
import { retryIngestedKeys } from '../../actions/keyCodeActions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function IngestedBatches({ location }) {
  const [reload, setReload] = useState();
  const { t } = useTranslation();

  const [removeIngestedSlideoutOpen, setRemoveIngestedSlideoutOpen] =
    useState(false);
  const [removeIngestedSlideoutItem, setRemoveIngestedSlideoutItem] =
    useState();
  const [breadcrumb, setBreadcrumb] = useState([
    { label: t('Ingested batches'), link: '/keys/ingested-batches' },
  ]);
  const onRetry = (e, item) => {
    retryIngestedKeys(item.id)
      .then(() => {
        toast.success(t('Retrying batch'));
        setReload(new Date());
      })
      .catch((e) => toast.error(e.message || t('Failed to retry')));
  };

  return (
    <>
      <ActionBar breadcrumb={breadcrumb}></ActionBar>

      <IngestedBatchesList
        location={location}
        reload={reload}
        onRemove={(e, transaction) => {
          setRemoveIngestedSlideoutItem(transaction);
          setRemoveIngestedSlideoutOpen(true);
        }}
        onRetry={onRetry}
        setBreadcrumb={setBreadcrumb}
      />

      <SlidingPane
        isOpen={removeIngestedSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setRemoveIngestedSlideoutOpen(false)}
      >
        {removeIngestedSlideoutItem ? (
          <RemoveIngestedKeys
            id={removeIngestedSlideoutItem.id}
            done={(d) => {
              setReload(new Date());
              setRemoveIngestedSlideoutOpen(false);
            }}
            cancel={() => setRemoveIngestedSlideoutOpen(false)}
          />
        ) : (
          <></>
        )}
      </SlidingPane>
    </>
  );
}

export default IngestedBatches;
