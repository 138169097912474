import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Gravatar from 'react-gravatar';
import { search } from '../../../../api/userApi';
import { useTranslation } from 'react-i18next';

function Search({ autoFocus, onFocus, onBlur, onChange, defaultValue }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const handleSearch = (query) => {
    setIsLoading(true);

    search(query)
      .then((items) => {
        setOptions(items);
        setIsLoading(false);
      })
      .catch(() => {
        setOptions([]);
        setIsLoading(false);
      });
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const renderResult = (data, props) => {
    return (
      <>
        <Gravatar
          email={data?.username}
          size={26}
          rating="pg"
          default="retro"
          className="img-circle me-3 ms-2"
        />
        <div className="item-content">
          <span>{data.fullName}</span>
          <br />
          {data.company}
        </div>
      </>
    );
  };

  return autoFocus ? (
    <AsyncTypeahead
      autoFocus
      filterBy={filterBy}
      id="search-typeahead"
      isLoading={isLoading}
      labelKey="fullName"
      minLength={2}
      onSearch={handleSearch}
      options={options}
      placeholder={t('Search')}
      renderMenuItemChildren={renderResult}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      defaultInputValue={defaultValue}
    />
  ) : (
    <AsyncTypeahead
      filterBy={filterBy}
      id="search-typeahead"
      isLoading={isLoading}
      labelKey="fullName"
      minLength={2}
      onSearch={handleSearch}
      options={options}
      placeholder={t('Search')}
      renderMenuItemChildren={renderResult}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      defaultInputValue={defaultValue}
    />
  );
}
export default Search;
