import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ActionBar from '../../layout/ActionBar';
import Delete from './slideout/Delete';
import {
  saveUserProfileGroup,
  getUserProfileGroupById,
  deleteCompanies,
  addCompanies,
  getCompanies,
} from '../../../actions/userProfileGroupActions';
import {
  addPermissionGroup,
  deletePermissionGroup,
  getPermissionGroup,
} from '../../../actions/permissionActions';
import Loading from '../../common/Loading';
import FormInput from '../../common/FormInput';
import KwPanel from '../../common/KwPanel';
import { cellClick } from '../../../util/table';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import PermissionBadges from '../permissions/components/PermissionBadges';
import SlidingPane from 'react-sliding-pane';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import CompanyBadges from './components/CompanyBadges';
import { useTranslation } from 'react-i18next';
import ResponsiveActionBarButtons from '../../common/ResponsiveActionBarButtons';
import Button from '../../common/Button';

import { scopes } from '../../../constants/scopes';

function UserProfileGroup({ id, history, currentUser }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [reload, setReload] = useState();
  const [permissions, setPermissions] = useState();
  const [companies, setCompanies] = useState();

  const onSubmit = (data) => {
    saveUserProfileGroup({ ...userProfileGroup, ...data })
      .then(() => {
        setUserProfileGroup({ ...userProfileGroup, ...data });
        toast.success(t('Group saved'));
      })
      .catch(() => {
        toast.error(t('Failed to save group'));
      });
  };

  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);
  const handleDelete = (e) => {
    e.cancelBubble = true;
    setDeleteSlideoutOpen(true);
    return false;
  };

  const [userProfileGroup, setUserProfileGroup] = useState();

  useEffect(() => {
    if (id) {
      getUserProfileGroupById(id)
        .then((d) => {
          setUserProfileGroup(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/settings/user-profile-groups/');
          toast.error(t('Group not found'));
        });
    }
  }, [id, history, t]);

  useEffect(() => {
    if (id) {
      getPermissionGroup(id)
        .then((d) => {
          setPermissions(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get permissions'));
        });
    }
  }, [reload, id, t]);

  useEffect(() => {
    if (id) {
      getCompanies(id)
        .then((d) => {
          setCompanies(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get companies'));
        });
    }
  }, [reload, id, t]);

  return userProfileGroup && permissions ? (
    <>
      <ActionBar
        breadcrumb={[
          {
            link: '/settings/user-profile-groups',
            label: t('User profile groups'),
          },
          { label: userProfileGroup.name },
        ]}
      >
        <div className="d-flex">
          <ResponsiveActionBarButtons
            buttons={[
              id && (
                <Button
                  className="btn btn-danger ms-2 d-none d-md-inline-flex"
                  onClick={handleDelete}
                  text={t('Delete')}
                />
              ),
              <Button
                key="save"
                className="btn btn-primary ms-2"
                onClick={handleSubmit(onSubmit)}
                text={t('Save')}
              />,
            ]}
          />
        </div>
      </ActionBar>
      <KwPanel className="mb-4">
        <div className="row">
          <div className="col">
            <header className="mb-4">
              <h2 className="no-margin">{t('Group details')}</h2>
            </header>
            <FormInput
              register={register}
              required
              error={errors.name}
              errorMessage={t('Name is required')}
              name="name"
              label={t('Name')}
              placeholder={t('Name of the group')}
              defaultValue={userProfileGroup.name || ''}
            />
          </div>
          <div className="col">
            <header className="mb-4">
              <h2 className="no-margin">{t('Stats')}</h2>
            </header>
            <table
              className="table table-has-buttons width-100"
              style={{ tableLayout: 'fixed' }}
            >
              <tbody>
                <tr>
                  <td onClick={cellClick}>
                    <Link to={`/settings/user-profile-group/${id}/members`}>
                      {t('Number of Users')}
                    </Link>
                  </td>
                  <td />
                  <td onClick={cellClick} className="num text-right">
                    {CommaNumber_NoDecimal(userProfileGroup.memberCount)}
                  </td>
                </tr>
                <tr>
                  <td onClick={cellClick}>
                    {hasPermission(
                      currentUser,
                      permissionTypes.MANAGE_WORKFLOWS
                    ) ? (
                      <Link
                        to={`/settings/workflows/?${scopes.APPROVINGGROUP}=${id}`}
                      >
                        {t('Number of workflows (configured as approver)')}
                      </Link>
                    ) : (
                      t('Number of workflows (configured as approver)')
                    )}
                  </td>
                  <td>
                    <Link
                      to={`/reports/workflows-approvers/?${scopes.APPROVINGGROUP}=${userProfileGroup.id}`}
                      className="btn btn-default"
                    >
                      {t('View users')}
                    </Link>
                  </td>
                  <td onClick={cellClick} className="num text-right">
                    {CommaNumber_NoDecimal(
                      userProfileGroup.workflowApproverCount
                    )}
                  </td>
                </tr>
                <tr>
                  <td onClick={cellClick}>
                    {hasPermission(
                      currentUser,
                      permissionTypes.MANAGE_WORKFLOWS
                    ) ? (
                      <Link
                        to={`/settings/workflows/?${scopes.REQUESTERSGROUP}=${id}`}
                      >
                        {t('Number of workflows (configured as requester)')}
                      </Link>
                    ) : (
                      t('Number of workflows (configured as requester)')
                    )}
                  </td>
                  <td>
                    <Link
                      to={`/reports/workflows-requesters?${scopes.REQUESTERSGROUP}=${userProfileGroup.id}`}
                      className="btn btn-default"
                    >
                      {t('View users')}
                    </Link>
                  </td>
                  <td onClick={cellClick} className="num text-right">
                    {CommaNumber_NoDecimal(
                      userProfileGroup.workflowRequesterCount
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </KwPanel>

      <PermissionBadges
        onAdd={(d) => {
          addPermissionGroup(d, id).then(() => setReload(new Date()));
        }}
        onRemove={(d) => {
          deletePermissionGroup(d, id).then(() => setReload(new Date()));
        }}
        permissions={permissions}
        label={t('Permissions')}
        description={t(
          'These permissions will be granted to all members of this group.'
        )}
      />

      <CompanyBadges
        onAdd={(d) => {
          addCompanies(d, id).then(() => setReload(new Date()));
        }}
        onRemove={(d) => {
          deleteCompanies(d, id).then(() => setReload(new Date()));
        }}
        userProfileGroupId={id}
        companies={companies}
        label={t('Companies')}
        description={t(
          'Games from these companies will be visible to users in this group and any permissions defined above will be scoped to these companies'
        )}
      />

      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          cancel={() => setDeleteSlideoutOpen(false)}
          done={(d) => {
            setDeleteSlideoutOpen(false);
            history.push('/settings/user-profile-groups/');
          }}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileGroup);
