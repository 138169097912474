import * as territoryApi from '../api/territoryApi';
import actionTypes from '../constants/actionTypes';

export function saveTerritory(territory) {
  return function (dispatch) {
    return territoryApi.saveTerritory(territory).then((savedTerritory) => {
      dispatch({
        type: territory.id
          ? actionTypes.UPDATE_TERRITORY
          : actionTypes.CREATE_TERRITORY,
        territory: { ...territory, id: savedTerritory.id },
      });
    });
  };
}

export function getTerritoryById(id) {
  return territoryApi.getTerritoryById(id);
}

export function loadTerritories({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return territoryApi.getTerritories(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAllTerritories() {
  return function (dispatch) {
    return territoryApi.getAllTerritories().then((territories) => {
      dispatch({
        type: actionTypes.LOAD_TERRITORIES,
        territories,
      });
    });
  };
}

export function loadAllowedSkus({
  gameId,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return territoryApi.getAllowedSkus(
    gameId,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadDisallowedSkus({
  gameId,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return territoryApi.getDisallowedSkus(
    gameId,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function deleteTerritory(id) {
  return function (dispatch) {
    return territoryApi.deleteTerritory(id).then(() => {
      dispatch({
        type: actionTypes.DELETE_TERRITORY,
        id,
      });
    });
  };
}

export function exportTerritoryData(filters, scopes) {
  return territoryApi.exportTerritoryData(filters, scopes);
}
