import React from 'react';
import { useTranslation } from 'react-i18next';
import AutoSelectText from '../../common/AutoSelectText';

const RequesterTag = ({ request }) => {
  const { t } = useTranslation();
  return (
    <div className="col-6">
      <label className="col-form-label">{t('Requester tag')}</label>
      <div className="flex ps-0">
        <div className="form-control-static px-3 flex h-100 w-100 align-items-center">
          <AutoSelectText>{request.requesterTag}</AutoSelectText>
        </div>
      </div>
    </div>
  );
};
export default RequesterTag;
