import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.LOAD_WORKFLOWS:
      return action.workflows;
    case actionTypes.UPDATE_WORKFLOW:
      return state
        ? state.map((workflow) =>
            workflow.id === action.workflow.id ? action.workflow : workflow
          )
        : state;
    case actionTypes.CREATE_WORKFLOW:
      return state ? [...state, action.workflow] : state;
    case actionTypes.DELETE_WORKFLOW:
      return state
        ? state.filter((workflow) => workflow.id !== action.id)
        : state;
    default:
      return state;
  }
}
