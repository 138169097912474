import React from 'react';
import PropTypes from 'prop-types';
import RequestsMadeByMeList from '../RequestsMadeByMeList';
import TableViewSwitcher from '../TableViewSwitcher';
import RequestsMadeByMeGroupedList from '../RequestsMadeByMeGroupedList';
import RequestsList from './RequestsList';
import RequestsGroupedList from './RequestsGroupedList';
import { isPowerUser } from '../../../auth/authUtils';
import { useTranslation } from 'react-i18next';

function RequestsTableForUserTypeAndDisplayMode({
  title,
  location,
  history,
  reload,
  itemsChecked,
  setItemsChecked,
  requestsGrouped,
  setRequestsGrouped,
  currentUser,
  setBulkActionsSlideoutOpen,
  setCompleteRequestSlideoutOpen,
  setBreadcrumb,
  scope,
  settingsKey,
  handleDownloadKeys,
  handleUploadKeys,
  handleBulkStatusChange,
  handleBulkReassign,
  handleBulkSteamRequest,
  handleReturnKeys,
  isProcessing,
}) {
  const { t } = useTranslation();
  // Are we a power user?
  if (isPowerUser(currentUser)) {
    // Yes, so show the requests grouped by request group
    if (requestsGrouped) {
      return (
        <RequestsGroupedList
          title={title}
          location={location}
          history={history}
          reload={reload}
          itemsChecked={itemsChecked}
          setItemsChecked={setItemsChecked}
          handleBulkActions={
            isPowerUser(currentUser) && setBulkActionsSlideoutOpen
          }
          bulkActionTitle={
            itemsChecked.length === 0
              ? t('Bulk actions - first tick some requests')
              : t('Bulk actions')
          }
          tableViewSwitcher={
            <TableViewSwitcher
              requestsGrouped={requestsGrouped}
              setRequestsGrouped={setRequestsGrouped}
              setItemsChecked={setItemsChecked}
            />
          }
          setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
          setBreadcrumb={setBreadcrumb}
          scope={scope}
          settingsKey={settingsKey}
        />
      );
    }

    // No, so show the requests in a list
    return (
      <RequestsList
        title={title}
        location={location}
        history={history}
        reload={reload}
        handleDownloadKeys={handleDownloadKeys}
        handleUploadKeys={handleUploadKeys}
        handleBulkStatusChange={handleBulkStatusChange}
        handleBulkReassign={handleBulkReassign}
        handleBulkSteamRequest={handleBulkSteamRequest}
        handleReturnKeys={handleReturnKeys}
        itemsChecked={itemsChecked}
        setItemsChecked={setItemsChecked}
        isProcessing={isProcessing}
        handleBulkActions={
          isPowerUser(currentUser) && setBulkActionsSlideoutOpen
        }
        bulkActionTitle={
          itemsChecked.length === 0
            ? t('Bulk actions - first tick some requests')
            : t('Bulk actions')
        }
        tableViewSwitcher={
          <TableViewSwitcher
            requestsGrouped={requestsGrouped}
            setRequestsGrouped={setRequestsGrouped}
            setItemsChecked={setItemsChecked}
          />
        }
        setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
        setBreadcrumb={setBreadcrumb}
        scope={scope}
        settingsKey={settingsKey}
      />
    );
  }

  // No not a power user, so show the "made by me" requests grouped by request group
  if (requestsGrouped) {
    return (
      <RequestsMadeByMeGroupedList
        location={location}
        reload={reload}
        history={history}
        setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
        settingsKey={settingsKey}
        allRequests={true}
        tableViewSwitcher={
          <TableViewSwitcher
            requestsGrouped={requestsGrouped}
            setRequestsGrouped={setRequestsGrouped}
            setItemsChecked={setItemsChecked}
          />
        }
      />
    );
  }

  // No, so show the "made by me" requests in a list
  return (
    <RequestsMadeByMeList
      location={location}
      reload={reload}
      history={history}
      handleDownloadKeys={handleDownloadKeys}
      isProcessing={isProcessing}
      setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
      settingsKey={settingsKey}
      allRequests={true}
      tableViewSwitcher={
        <TableViewSwitcher
          requestsGrouped={requestsGrouped}
          setRequestsGrouped={setRequestsGrouped}
          setItemsChecked={setItemsChecked}
        />
      }
    />
  );
}

RequestsTableForUserTypeAndDisplayMode.propTypes = {
  title: PropTypes.string,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  reload: PropTypes.any,
  itemsChecked: PropTypes.array.isRequired,
  setItemsChecked: PropTypes.func,
  requestsGrouped: PropTypes.bool.isRequired,
  setRequestsGrouped: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  setBulkActionsSlideoutOpen: PropTypes.func.isRequired,
  setCompleteRequestSlideoutOpen: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func,
  scope: PropTypes.object,
  settingsKey: PropTypes.string,
  handleDownloadKeys: PropTypes.func.isRequired,
  handleUploadKeys: PropTypes.func.isRequired,
  handleBulkStatusChange: PropTypes.func.isRequired,
  handleBulkReassign: PropTypes.func.isRequired,
  handleBulkSteamRequest: PropTypes.func.isRequired,
  handleReturnKeys: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};

export default RequestsTableForUserTypeAndDisplayMode;
