import React, { useState } from 'react';
import { connect } from 'react-redux';
import { loadCompanies, exportData } from '../../../actions/companyActions';
import { Table, getTableSettingsDefaults } from '../../common/Table';
import { hexToRgbA } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import { userIsAny } from '../../auth/authUtils';
import userTypes from '../../../constants/userTypes';
import CompaniesListColumnsConfig from './CompaniesListColumnsConfig';
import { useTranslation } from 'react-i18next';

function getAllCompanyColumns(currentUser) {
  let columns = [
    'companyName',
    'companyTypeName',
    'isProformaInvoicing',
    'transactionLimit',
    'globalTransactionLimit',
    'requestsOpen',
    'requestsComplete',
    'keysRequested',
  ];
  if (
    userIsAny(currentUser, [
      userTypes.ADMIN,
      userTypes.APPROVER,
      userTypes.UPLOADER,
    ])
  ) {
    columns = [...columns, 'requestActivity', 'keyActivity'];
  }
  return columns;
}

function CompanyList({ location, reload, companyTypes, currentUser }) {
  const allColumns = getAllCompanyColumns(currentUser);
  const defaultVisibleColumns = allColumns.filter(
    (x) => x !== 'keyActivity' && x !== 'requestActivity'
  );
  const [companies, setCompanies] = useState();
  const params = new URLSearchParams(location.search);
  const { t } = useTranslation();

  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'companylist-settings',
      getTableSettingsDefaults({
        defaultSort: 'companyName',
        defaultVisibleColumns,
      }),
      null,
      loadCompanies,
      setCompanies,
      reload,
      t('Failed to load companies'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  const columnsConfig = CompaniesListColumnsConfig({
    params,
    filterChange,
    companyTypes,
  });

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return companies ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        rows={companies.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={companies.totalRowCount}
        filteredRowCount={companies.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        trStyle={(row) => {
          return {
            backgroundColor: `${hexToRgbA(row.colourHex, 0.0375)}`,
          };
        }}
        exportData={exportData}
        scopes={companies.scopes}
        location={location}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);
