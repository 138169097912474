import React, { Fragment } from 'react';

import { NavLink } from 'react-router-dom';
function Breadcrumb({ items }) {
  return (
    <div className="breadcrumb">
      {items.map((item, index) => (
        <Fragment key={item.label}>
          <span className="bc-item">
            {item.link ? (
              <NavLink to={item.link}>{item.label}</NavLink>
            ) : (
              item.label
            )}
          </span>
          {index !== items.length - 1 && '/'}
        </Fragment>
      ))}
    </div>
  );
}

export default Breadcrumb;
