import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { downloadAccountDetailKeyCode } from '../../actions/requestActions';
import ErrorOccured from './components/ErrorOccured';
import KeyCodeDetails from './components/KeyCodeDetails';
import Loading from './components/Loading';

function DownloadKeyCode() {
  const params = useParams();
  const keyRequestId = params.keyRequestId;
  const accountId = params.accountId;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [details, setDetails] = useState();
  const [packshotError, setPackshotError] = useState(false);

  useEffect(() => {
    setLoading(true);
    downloadAccountDetailKeyCode(keyRequestId, accountId)
      .then((data) => setDetails(data))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [keyRequestId, accountId]);
  return (
    <div className="download-key-code">
      <div
        className={`bg-blur ${!details || packshotError ? 'no-media' : ''}`}
        style={{
          backgroundImage: `url(${details?.packshotUrl})`,
        }}
      >
        <span className="particle"></span>
        <span className="particle"></span>
        <span className="particle"></span>
      </div>
      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorOccured></ErrorOccured>
      ) : (
        details && (
          <KeyCodeDetails
            details={details}
            packshotError={packshotError}
            setPackshotError={setPackshotError}
          ></KeyCodeDetails>
        )
      )}
    </div>
  );
}

export default DownloadKeyCode;
