import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import {
  addWorkflowRequesterGroup,
  addWorkflowRequesterUser,
  addWorkflowRequesterCompany,
} from '../../../../actions/workflowActions';
import { getAllCompanies } from '../../../../actions/companyActions';
import { toast } from 'react-toastify';
import FormGroup from '../../../common/FormGroup';
import FormSelect from '../../../common/FormSelect';
import FormButtonGroup from '../../../common/FormButtonGroup';
import Search from '../../users/components/Search';
import KwPanel from '../../../common/KwPanel';
import { useTranslation } from 'react-i18next';

function AddRequester({
  workflowId,
  groups,
  done,
  cancel,
  getAllCompanies,
  companies,
}) {
  const { t } = useTranslation();
  const [mode, setMode] = useState('Group');
  const [user, setUser] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    if (mode === 'Group') {
      addWorkflowRequesterGroup(workflowId, data.id)
        .then((requester) => {
          toast.success(t('Requester successfully added to workflow'));
          done(requester);
        })
        .catch(() => {
          toast.error(t('Failed to add requester to workflow'));
        });
      return;
    } else if (mode === 'User') {
      if (!user) {
        toast.error(t('Failed to add requester to workflow'));
        return;
      }
      addWorkflowRequesterUser(workflowId, user.id)
        .then((requester) => {
          toast.success(t('Requester successfully added to workflow'));
          done(requester);
        })
        .catch(() => {
          toast.error(t('Failed to add requester to workflow'));
        });
      return;
    } else if (mode === 'Company') {
      addWorkflowRequesterCompany(workflowId, data.companyId)
        .then((requester) => {
          toast.success(t('Requester successfully added to workflow'));
          done(requester);
        })
        .catch(() => {
          toast.error(t('Failed to add requester to workflow'));
        });
      return;
    }
    toast.error(t('Please required fields'));
  };

  const handleUserSearchChange = (d) => {
    if (d && d.length > 0) {
      setUser(d[0]);
    } else {
      setUser(undefined);
    }
  };

  useEffect(() => {
    if (!companies) {
      getAllCompanies();
    }
  }, [companies, getAllCompanies]);

  return (
    <SlideoutLayout
      title={t('Add requester')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      {groups && (
        <KwPanel>
          <FormButtonGroup
            register={register}
            error={errors.type}
            errorMessage={t('Type is required')}
            name="mode"
            label={t('Type of requester')}
            buttons={[
              { value: 'Group', text: t('Group') },
              { value: 'User', text: t('User') },
              { value: 'Company', text: t('Company') },
            ]}
            onChange={(btn) => setMode(btn.value)}
            value={mode}
          ></FormButtonGroup>
          {mode === 'Group' ? (
            <FormSelect
              register={register}
              required
              errorMessage={t('Required')}
              error={errors.id}
              name="id"
              label={t('Group')}
              placeholder=""
            >
              <option value="00000000-0000-0000-0000-000000000000">
                {t('--- choose ---')}
              </option>
              {groups
                ? [...groups]
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((x) => (
                      <option key={x.id} value={x.id}>
                        {x.name}
                      </option>
                    ))
                : ''}
            </FormSelect>
          ) : mode === 'User' ? (
            <FormGroup label={t('User')}>
              <Search onChange={handleUserSearchChange}></Search>
            </FormGroup>
          ) : mode === 'Company' ? (
            <FormSelect
              register={register}
              required
              error={errors.companyId}
              errorMessage={t('Company is required')}
              name="companyId"
              label={t('Company')}
            >
              <option value="">{t('--- choose ---')}</option>
              {companies &&
                [...companies]
                  ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
                  ?.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.companyName}
                    </option>
                  ))}
            </FormSelect>
          ) : (
            ''
          )}
        </KwPanel>
      )}
    </SlideoutLayout>
  );
}

AddRequester.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  workflowId: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    companies: state.companies,
  };
}

const mapDispatchToProps = { getAllCompanies };

export default connect(mapStateToProps, mapDispatchToProps)(AddRequester);
