import React from 'react';
import { NavLink } from 'react-router-dom';
import { isPowerUser } from '../../auth/authUtils';

function GameLink({
  gameId,
  skuId,
  publisherCompanyId,
  gameTitle,
  skuName,
  className,
  currentUser,
  onClick,
}) {
  const handleOnClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <div className={className}>
      {currentUser && isPowerUser(currentUser) ? (
        <>
          {skuId && gameId ? (
            <>
              <NavLink
                to={`/game/${gameId}`}
                className="game-title"
                onClick={handleOnClick}
              >
                {gameTitle}
              </NavLink>
              <br />
              <NavLink to={`/sku/${skuId}`} onClick={handleOnClick}>
                {skuName}
              </NavLink>
            </>
          ) : (
            <>
              <NavLink to={`/game/${gameId}`} onClick={handleOnClick}>
                {gameTitle}
              </NavLink>
            </>
          )}
        </>
      ) : (
        <>
          <NavLink to={`/game/${gameId}`} onClick={handleOnClick}>
            {gameTitle}
          </NavLink>
          <br />
          {skuName}
        </>
      )}
    </div>
  );
}
export default GameLink;
