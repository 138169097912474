import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CompanyForm from '../components/CompanyForm';
import CompanyKeyProviders from '../components/CompanyKeyProviders';

import SlidingPane from 'react-sliding-pane';

import {
  saveCompany,
  saveCompanyKeyProviders,
  getCompanyById,
} from '../../../actions/companyActions';
import { getKeyProviders } from '../../../actions/keyProviderActions';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Loading from '../../common/Loading';
import Delete from './Delete';
import ResponsiveActionBarButtons from '../../common/ResponsiveActionBarButtons';
import { getAllCompanyTypes } from '../../../actions/companyTypeActions';
import DisableKeys from '../../keys/slideout/DisableKeys';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';

import image_no_content from '../../../content/images/no-content.svg';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

const EditCompany = ({
  done,
  cancel,
  id,
  saveCompany,
  keyProviders,
  getKeyProviders,
  history,
  companyTypes,
  getAllCompanyTypes,
}) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
  } = useForm();
  const [disableKeysSlideoutOpen, setDisableKeysSlideoutOpen] = useState(false);
  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);
  const [file, setFile] = useState();

  const onSubmit = (data) => {
    if (!company) {
      toast.error(t('Failed to save company'));
      return;
    }
    // clean the new flag off the object, this only affects the mockdb
    saveCompany({
      ...company,
      ...data,
      file,
    })
      .then(() => {
        // save key providers
        saveCompanyKeyProviders(company.id, company.companyKeyProviders).then(
          () => {
            setCompany({ ...company, ...data });
            toast.success(t('Company saved'));
            done(company);
          }
        );
      })
      .catch((e) => {
        console.error(e);
        toast.error(t('Failed to save company'));
      });
  };

  //companies
  useEffect(() => {
    if (!companyTypes) {
      getAllCompanyTypes().catch((e) => {
        toast.error(t('Failed to get company types'));
      });
    }
  }, [companyTypes, getAllCompanyTypes]);

  const [company, setCompany] = useState();

  useEffect(() => {
    if (id) {
      // get the company
      getCompanyById(id)
        .then((d) => {
          setCompany(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/companies');
          toast.error(t('Company not found'));
        });
    }
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [id, keyProviders, getKeyProviders, history]);

  const handleKeyProviderChange = (kp) => {
    if (kp && company) {
      let companyKeyProviders = [...company.companyKeyProviders];
      const index = companyKeyProviders.findIndex((x) => x.id === kp.id);
      if (index > -1) {
        companyKeyProviders[index].reference = kp.reference;
      } else {
        companyKeyProviders.push({
          id: kp.id,
          name: kp.name,
          reference: kp.reference,
        });
      }
      setCompany({ ...company, companyKeyProviders });
    }
  };

  const setDomains = (domains) => {
    setCompany({ ...company, domains: domains });
  };

  return company ? (
    <SlideoutLayout
      title={t('Edit {company}', { company: company.companyName })}
      cancel={cancel}
      done={done}
      bar={
        <ResponsiveActionBarButtons
          buttons={[
            <Button
              key="disableKeys"
              className="btn btn-danger ms-2 d-none d-md-inline-flex"
              onClick={() => setDisableKeysSlideoutOpen(true)}
              text={t('Disable keys')}
            />,
            <Button
              key="delete"
              className="btn btn-danger ms-2 d-none d-md-inline-flex"
              onClick={() => setDeleteSlideoutOpen(true)}
              isDisabled={company.isDeleted}
              title={company.isDeleted ? t('Company is deleted') : t('Delete')}
              text={t('Delete')}
            />,
            <Button
              key="save"
              className="btn btn-primary ms-2"
              onClick={handleSubmit(onSubmit)}
              isDisabled={company.isDeleted}
              title={company.isDeleted ? t('Company is deleted') : t('Save')}
              text={t('Save')}
            />,
          ]}
        />
      }
    >
      {!company.isDeleted ? (
        <div className="row">
          <div className="col-sm-12">
            <CompanyForm
              company={company}
              companyTypes={companyTypes}
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              getValues={getValues}
              columnCssClass="col-12"
              setFile={setFile}
              setDomains={setDomains}
            ></CompanyForm>
            <CompanyKeyProviders
              keyProviders={keyProviders}
              companyKeyProviders={company?.companyKeyProviders}
              onChange={handleKeyProviderChange}
            />
          </div>
        </div>
      ) : (
        <div
          className="alert alert-info text-center"
          style={{ padding: '30px 15px' }}
        >
          <img
            src={image_no_content}
            alt={t('This company has been deleted')}
            className="space-bottom-small"
          />
          <p>{t('This company has been deleted')}</p>
        </div>
      )}
      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          done={(d) => {
            history.push('/companies/');
          }}
          cancel={() => setDeleteSlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={disableKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDisableKeysSlideoutOpen(false)}
      >
        <DisableKeys
          title={t('Disable keys for {company}', {
            company: company.companyName,
          })}
          targetCompanyId={id}
          done={(d) => {
            setDisableKeysSlideoutOpen(false);
            done();
          }}
          cancel={() => setDisableKeysSlideoutOpen(false)}
          defaultReason={
            (t('Disable keys for "{company}" company'),
            { company: company.companyName })
          }
        />
      </SlidingPane>
    </SlideoutLayout>
  ) : (
    <Loading></Loading>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    keyProviders: state.keyProviders,
    currentUser: state.auth,
    companyTypes: state.companyTypes,
    history: ownProps.history,
  };
}

const mapDispatchToProps = {
  saveCompany,
  getKeyProviders,
  getAllCompanyTypes,
};

EditCompany.propTypes = {
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  saveCompany: PropTypes.func.isRequired,
  getKeyProviders: PropTypes.func.isRequired,
  getAllCompanyTypes: PropTypes.func.isRequired,
  keyProviders: PropTypes.array,
  companyTypes: PropTypes.array,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
