import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import { copyRequestGroup } from '../../../actions/requestActions';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import Loading from '../../common/Loading';
import GameLink from '../../common/links/GameLink';
import { CommaNumber_NoDecimal } from '../../../util/formatter';

import KwPanel from '../../common/KwPanel';
import Button from '../../common/Button';
import icons from '../../../constants/icons';
import { useTranslation } from 'react-i18next';

function CopyRequest({
  done,
  cancel,
  requestGroup,
  requests,
  currentUser,
  copyRequestGroup,
  setCompleteRequestSlideoutOpen,
}) {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState();

  const [copyDetails, setCopyDetails] = useState();
  useEffect(() => {
    setCopyDetails([...requests]);
  }, [requests, setCopyDetails]);

  const setRowData = (id, data) => {
    let newArr = [...copyDetails];
    let index = newArr.findIndex((x) => x.id === id);
    newArr[index] = { ...newArr[index], ...data };
    setCopyDetails(newArr);
  };

  const handleSubmit = () => {
    if (copyDetails) {
      setProcessing(true);
      let dataToSubmit = copyDetails.map((request) => {
        return {
          requestId: request.id,
          requesterTagId: request.requesterTagId ?? null,
          requesterTag: request.requesterTag ?? null,
          numberOfKeys: parseInt(request.numberOfKeys),
        };
      });
      copyRequestGroup(requestGroup.requestGroupId, dataToSubmit)
        .then((d) => {
          // pop the basket
          setCompleteRequestSlideoutOpen(true);

          done(d);
          setProcessing(false);
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to copy request'));
          setProcessing(false);
        });
    } else {
      toast.error(t('Failed to copy request'));
    }
  };

  const handleRemove = (requestGroupId, requestId) => {
    setCopyDetails([...copyDetails].filter((x) => x.id !== requestId));
  };

  return (
    <SlideoutLayout
      title={t('Copy request')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          className="btn btn-primary"
          onClick={handleSubmit}
          isDisabled={processing}
          text={t('Copy')}
        />
      }
    >
      {copyDetails ? (
        <KwPanel key={requestGroup.id} className="space-bottom">
          <header className="mb-2">
            <h2 className="no-margin bold">
              {`${requestGroup.keyWorkflowName} - ${requestGroup.companyName}`}
            </h2>
          </header>
          <div className="table-wrapper">
            <table className="table table-has-buttons jsDataTable width-100 space-bottom td-align-top">
              <thead>
                <tr>
                  <th scope="col">
                    <span>{t('Game')}</span>
                  </th>
                  <th scope="col">
                    <span>{t('Provider')}</span>
                  </th>
                  <th scope="col">
                    <span>{copyDetails[0].packageIdLabel}</span>
                  </th>
                  {requestGroup.keyWorkflowRequiresTag && (
                    <th scope="col">
                      <span>{t('Tag')}</span>
                    </th>
                  )}
                  <th scope="col" className="text-right">
                    <span>{t('Keys')}</span>
                  </th>
                  <th scope="col" style={{ minWidth: '68px', width: '68px' }}>
                    <span className="sr-only">{t('Row actions')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {copyDetails.map((request) => (
                  <tr key={request.id} className="br_row">
                    <td width="40%">
                      <GameLink
                        gameId={request.gameId}
                        skuId={request.skuId}
                        gameTitle={request.gameTitle}
                        skuName={request.skuName}
                        currentUser={currentUser}
                      ></GameLink>
                    </td>
                    <td>
                      {request.keyProviderName}
                      <br />
                      {request.platformName}
                    </td>
                    <td>{request.packageId}</td>

                    {requestGroup.keyWorkflowRequiresTag &&
                      !requestGroup.keyWorkflowTagsAsFreeText && (
                        <td>
                          <div className="flex justify-content-start">
                            <select
                              className={`form-control`}
                              defaultValue={request.requesterTag}
                              onChange={(e) => {
                                e.cancelBubble = true;
                                setRowData(request.id, {
                                  requesterTagId: e.target.value,
                                });
                              }}
                            >
                              <option>{t('--- choose ---')}</option>
                              {requestGroup.keyWorkflowRequesterTags.map(
                                (tag) => (
                                  <option key={tag.id} value={tag.id}>
                                    {tag.tag}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </td>
                      )}

                    {requestGroup.keyWorkflowRequiresTag &&
                      requestGroup.keyWorkflowTagsAsFreeText && (
                        <td>
                          <div className="flex justify-content-start">
                            <input
                              type="text"
                              value={CommaNumber_NoDecimal(
                                request.requesterTag
                              )}
                              className={`form-control input-sm no-navigate`}
                              style={{ width: '120px' }}
                              onChange={(e) => {
                                e.cancelBubble = true;
                                setRowData(request.id, {
                                  requesterTag: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </td>
                      )}

                    <td>
                      <div className="flex justify-content-end">
                        <input
                          type="text"
                          value={CommaNumber_NoDecimal(request.numberOfKeys)}
                          className={`form-control input-sm num text-right no-navigate`}
                          style={{ width: '120px' }}
                          onChange={(e) => {
                            e.cancelBubble = true;
                            setRowData(request.id, {
                              numberOfKeys: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </td>
                    <td
                      className="text-right"
                      style={{ minWidth: '68px', width: '68px' }}
                    >
                      <Button
                        className="btn btn-default btn-sm btn-xs-full show-loading"
                        isDisabled={copyDetails.length === 1}
                        onClick={() => {
                          handleRemove(requestGroup.id, request.id);
                        }}
                        text={t('Remove')}
                        hideTitle={true}
                        icon={icons.DELETE}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </KwPanel>
      ) : (
        <Loading />
      )}
    </SlideoutLayout>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    done: ownProps.done,
    cancel: ownProps.cancel,
    requestGroup: ownProps.requestGroup,
    requests: ownProps.requests,
    setCompleteRequestSlideoutOpen: ownProps.setCompleteRequestSlideoutOpen,
  };
}

const mapDispatchToProps = {
  copyRequestGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyRequest);
