import React from 'react';
import { useTranslation } from 'react-i18next';

function GameNameHeaderCell() {
  const { t } = useTranslation();
  return (
    <div className="promo-game-name">
      {t('Game')}
      <br />
      <input type="text" className="form-control" placeholder={t('Filter')} />
    </div>
  );
}

export default GameNameHeaderCell;
