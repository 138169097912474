import React from 'react';
export default function Logo() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 107.9 15.9"
      xmlSpace="preserve"
      className="center-block"
      style={{ width: '140px' }}
    >
      <g>
        <path
          className="st0"
          fill="#16beef"
          d="M3.4,10.6l-2.2,5.1H0L6.6,0.1h1l6.6,15.6H13l-2.2-5.1 M10.5,9.7L7.1,1.5L3.7,9.7"
        ></path>
        <path
          className="st1"
          fill="#FFFFFF"
          d="M21.4,15.7V0.1h1.1v14.6h9.3v1H21.4z"
        ></path>
        <path
          className="st1"
          fill="#FFFFFF"
          d="M44,0.1h1l6.6,15.6h-1.2l-2.2-5.1h-7.5l-2.2,5.1h-1.2L44,0.1z M47.8,9.7l-3.4-8.2L41,9.7H47.8z"
        ></path>
        <path
          className="st1"
          fill="#FFFFFF"
          d="M67.6,2.7c-1-1.1-2.4-1.7-4.3-1.7c-1.5,0-2.6,0.3-3.3,0.9c-0.7,0.6-1,1.4-1,2.3c0,0.5,0.1,0.9,0.3,1.2
  c0.2,0.3,0.5,0.6,0.8,0.8c0.4,0.2,0.8,0.4,1.4,0.6c0.6,0.2,1.3,0.3,2.1,0.5c0.8,0.2,1.6,0.4,2.3,0.6c0.7,0.2,1.3,0.5,1.7,0.8
  c0.5,0.3,0.8,0.7,1.1,1.2s0.4,1,0.4,1.7s-0.1,1.3-0.4,1.8s-0.7,0.9-1.1,1.3s-1.1,0.6-1.8,0.8c-0.7,0.2-1.4,0.3-2.3,0.3
  c-2.4,0-4.5-0.8-6.2-2.3l0.6-0.9c0.7,0.7,1.5,1.2,2.4,1.6s2,0.6,3.2,0.6c1.4,0,2.4-0.2,3.2-0.7c0.8-0.5,1.2-1.3,1.2-2.3
  c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.3-0.9-0.5-1.5-0.7c-0.6-0.2-1.3-0.4-2.2-0.6c-0.8-0.2-1.6-0.4-2.2-0.6
  c-0.6-0.2-1.2-0.4-1.6-0.7c-0.4-0.3-0.8-0.7-1-1.1s-0.3-1-0.3-1.6c0-0.7,0.1-1.3,0.4-1.9c0.3-0.5,0.6-1,1.1-1.4s1.1-0.7,1.7-0.8
  C61.7,0.1,62.5,0,63.3,0c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.2,1.4L67.6,2.7z"
        ></path>
        <path
          className="st1"
          fill="#FFFFFF"
          d="M76.6,15.7V0.1h1.1v9.5l9.1-9.5h1.3L81.7,7l6.8,8.8h-1.3L81,7.6l-3.2,3.3v4.8H76.6z"
        ></path>
        <path
          className="st1"
          fill="#FFFFFF"
          d="M100.4,0.1h1l6.6,15.6h-1.2l-2.2-5.1h-7.5l-2.2,5.1h-1.2L100.4,0.1z M104.3,9.7l-3.4-8.2l-3.5,8.2H104.3z"
        ></path>
      </g>
    </svg>
  );
}
