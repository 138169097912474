import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import DisableKeysForm from '../components/DisableKeysForm';

import { getKeyProviders } from '../../../actions/keyProviderActions';
import {
  getDisableReasons,
  disableKeys,
} from '../../../actions/keyCodeActions';

import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Loading from '../../common/Loading';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function DisableKeys({
  title,
  done,
  cancel,
  keyProviders,
  getKeyProviders,
  disableReasons,
  getDisableReasons,
  targetGameId,
  targetGameSKUId,
  targetCompanyId,
  targetKeyWorkflowId,
  targetRequestingUserProfileId,
  targetAccountDetailId,
  targetKeyRequestId,
  targetKeyRequestAccountDetail,
  defaultReason,
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm();
  const { t } = useTranslation();
  const onSubmit = (data) => {
    let _disable = (keyProviderId) =>
      disableKeys({
        keyProviderId: keyProviderId,
        reason: data.reason,
        disabledReasons: data.disabledReasons,
        description: data.description,

        targetGameId: targetGameId,
        targetGameSKUId: targetGameSKUId,
        targetCompanyId: targetCompanyId,
        targetKeyWorkflowId: targetKeyWorkflowId,
        targetRequestingUserProfileId: targetRequestingUserProfileId,
        targetAccountDetailId: targetAccountDetailId,
        targetKeyRequestId: targetKeyRequestId,
        targetKeyRequestAccountDetail: targetKeyRequestAccountDetail,

        targetIsAvailable: data.codeStatuses?.some((x) => x === 1),
        targetIsAllocated: data.codeStatuses?.some((x) => x === 2),
        targetIsActivated: data.codeStatuses?.some((x) => x === 4),
        targetIsSold: data.codeStatuses?.some((x) => x === 8),
        targetIsExpired: data.codeStatuses?.some((x) => x === 16),
      });

    // foreach the keyproviders
    if (data.keyProviders) {
      Promise.all(
        data.keyProviders.map((keyProviderId) => _disable(keyProviderId))
      )
        .then((xx) => {
          let allErrors = xx.every((x) => x.isError);
          if (allErrors) {
            toast.error(
              t('Disabled keys request failed') +
                '\n' +
                xx.map((x) => x.message + '\n')
            );
          } else {
            let withErrors = xx.some((x) => x.isError);
            if (withErrors) {
              toast.warning(
                t('Disable keys request queues, with errors') +
                  '\n' +
                  xx.filter((x) => x.isError).map((x) => x.message + '\n')
              );
            } else {
              toast.success(t('Disabled keys request successfully queued'));
              done();
            }
          }
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to queue disabled keys request'));
        });
    } else {
      _disable(null)
        .then((response) => {
          if (response.isSuccess) {
            toast.success(t('Disabled keys request successfully queued'));
          } else {
            toast.error(response.message);
          }
          done();
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to queue disabled keys request'));
        });
    }
  };

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders]);

  useEffect(() => {
    if (!disableReasons) {
      getDisableReasons();
    }
  }, [disableReasons, getDisableReasons]);

  return keyProviders ? (
    <SlideoutLayout
      title={title || t('Disabled keys')}
      cancel={cancel}
      done={done}
    >
      <DisableKeysForm
        showKeyProviders={
          targetKeyRequestId ||
          targetGameId ||
          targetGameSKUId ||
          targetKeyRequestAccountDetail ||
          targetAccountDetailId
            ? false
            : true
        }
        keyProviders={keyProviders}
        disableReasons={disableReasons}
        register={register}
        errors={errors}
        control={control}
        cancel={cancel}
        submit={handleSubmit(onSubmit)}
        setValue={setValue}
        getValues={getValues}
        defaultReason={defaultReason}
      />
    </SlideoutLayout>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    keyProviders: state.keyProviders,
    disableReasons: state.disableReasons,
  };
}

const mapDispatchToProps = {
  getKeyProviders,
  getDisableReasons,
};

DisableKeys.propTypes = {
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DisableKeys);
