import React, { useEffect, useRef } from 'react';
import * as wordanimation from '../util/alaska.ui.oopsanimation.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import icons from '../constants/icons.ts';

function ApiFailure() {
  const ref = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (ref.current) {
      const initializingComponent = ref.current;
      const initCanvas =
        initializingComponent.getElementsByTagName('canvas')[0];
      initializingComponent.style.transition = `opacity 20ms, transform 20ms`;
      initializingComponent.style.opacity = 1;
      initializingComponent.style.display = null;
      wordanimation.init(
        initCanvas,
        t('FAILURE'),
        t(
          'There has been an error with the API, please retry again in a minute'
        ),
        ['#16beef', '#46B29D', '#BB5EBF', '#fe5557', '#b2178a']
      );
    }
  }, [ref, t]);
  return (
    <div className="page page--403">
      <main ref={ref}>
        <canvas></canvas>
        <a href="/" className="btn btn-primary">
          <FontAwesomeIcon icon={icons.CHEVRON_LEFT}></FontAwesomeIcon>
          {' ' + t('Take me back to Alaska')}
        </a>
      </main>
    </div>
  );
}

export default ApiFailure;
