import {
  apiUrl,
  getList,
  deleteRecord,
  getSingleRecord,
  saveRecord,
  getStatsData,
  getAll,
  getAuditData,
  postAction,
  exportData,
} from './apiUtils';
const baseUrl = apiUrl + '/packageTypes/';

export function getPackageTypes(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getPackageTypeById(id) {
  return getSingleRecord(baseUrl, id);
}

export function savePackageType(packageType) {
  return saveRecord(baseUrl, packageType);
}

export function deletePackageType(id) {
  return deleteRecord(baseUrl, id);
}

export function getStats(id) {
  return getStatsData(baseUrl, id);
}

export function getAudit(id) {
  return getAuditData(baseUrl, id);
}

export function addAudit(id, comment) {
  return postAction(`${baseUrl}${id}/audit`, { comment });
}

export function getAllPackageTypes() {
  return getAll(baseUrl);
}

export function exportPackageTypeData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/packageTypes/search`,
    'name',
    filters,
    scopes
  );
}
