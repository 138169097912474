import userTypes from '../../constants/userTypes';

export function isPowerUser(currentUser) {
  return (
    (currentUser.userType &
      (userTypes.APPROVER | userTypes.ADMIN | userTypes.UPLOADER)) >
    0
  );
}

export function userIs(currentUser, userType) {
  return (currentUser.userType & userType) > 0;
}

export function userIsAny(currentUser, userTypes) {
  return userTypes?.some((x) => currentUser.userType & x);
}

export function userIsAll(currentUser, userTypes) {
  return userTypes?.every((x) => currentUser.userType & x);
}

export function getCompanyIdsForPermission(currentUser, permission) {
  let companyIds = currentUser?.permissions
    ?.filter((x) => x.permissionKey === permission)
    .map((x) => x.companyId);
  if (companyIds) {
    // return the unique list
    return companyIds.filter(
      (value, index, array) => array.indexOf(value) === index
    );
  }
}

export function getCompanyIdsForAnyPermission(currentUser) {
  let companyIds = currentUser?.permissions.map((x) => x.companyId);
  if (companyIds) {
    // return the unique list
    return companyIds.filter(
      (value, index, array) => array.indexOf(value) === index
    );
  }
}

// ===============================================================
export function hasPermission(currentUser, permission) {
  return hasPermissionForCompany(
    currentUser,
    permission,
    currentUser.companyId
  );
}
export function hasPermissionForCompany(currentUser, permission, companyId) {
  if (companyId) {
    return currentUser?.permissions?.some(
      (x) => x.permissionKey === permission && x.companyId === companyId
    );
  } else {
    return false;
  }
}
// ===============================================================
export function hasAllPermissions(currentUser, permissions) {
  return hasAllPermissionsForCompany(
    currentUser,
    permissions,
    currentUser.companyId
  );
}
export function hasAllPermissionsForCompany(
  currentUser,
  permissions,
  companyId
) {
  if (companyId) {
    return currentUser?.permissions?.every(
      (x) =>
        (x =
          permissions?.indexOf(x.permissionKey) > -1 &&
          x.companyId === companyId)
    );
  } else {
    return false;
  }
}
// ===============================================================
export function hasAnyPermission(currentUser, permissions) {
  return hasAnyPermissionForCompany(
    currentUser,
    permissions,
    currentUser.companyId
  );
}
export function hasAnyPermissionForCompany(
  currentUser,
  permissions,
  companyId
) {
  if (companyId) {
    return currentUser?.permissions?.some(
      (x) =>
        (x =
          permissions?.indexOf(x.permissionKey) > -1 &&
          x.companyId === companyId)
    );
  } else {
    return false;
  }
}
// ===============================================================

export function navPermissionCheck(
  _currentUser,
  _allowedPermissions,
  _allowedUserTypes,
  _isPowerUser
) {
  let result = false;
  if (_currentUser && !_currentUser.isLoading) {
    let permission = true;
    let userType = true;
    let powerUser = true;
    if (_allowedPermissions !== undefined) {
      permission = hasAnyPermission(_currentUser, _allowedPermissions);
    }
    if (_allowedUserTypes !== undefined) {
      userType = userIsAny(_currentUser, _allowedUserTypes);
    }
    if (_isPowerUser !== undefined) {
      powerUser = isPowerUser(_currentUser) === _isPowerUser;
    }
    result = permission && userType && powerUser;
  }
  return result;
}

export function hasFeature(currentUser, feature) {
  return currentUser?.features?.some((x) => x === feature);
}
