import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';

function SlideoutLayout(props) {
  const hideBar = props?.hideBar || false;
  const { t } = useTranslation();

  return (
    <>
      <header className="clearfix width-100">
        <div className="width-100">
          {props.title ? <h1 className="no-margin">{props.title}</h1> : ''}
        </div>
        <div
          className="icon-wrapper"
          tabIndex={0}
          onClick={props.cancel}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              props.cancel();
            }
          }}
          title={t('Close')}
        >
          <FontAwesomeIcon
            icon={icons.CLOSE}
            className="cursor-pointer me-2"
            size="2x"
          />
        </div>
      </header>
      {!hideBar && <div className="bar bar-end text-right">{props.bar}</div>}
      <div className="content">{props.children}</div>
    </>
  );
}

SlideoutLayout.propTypes = {
  title: PropTypes.string,
  bar: PropTypes.node,
  cancel: PropTypes.func,
  hideBar: PropTypes.bool,
  children: PropTypes.node,
};

export default SlideoutLayout;
