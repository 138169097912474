import * as announcementApi from '../api/announcementApi';
import actionTypes from '../constants/actionTypes';

export function saveAnnouncement(announcement) {
  return announcementApi.saveAnnouncement(announcement);
}

export function getAnnouncementById(id) {
  return announcementApi.getAnnouncementById(id);
}

export function loadAnnouncements({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return announcementApi.getAnnouncements(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAllAnnouncements() {
  return function (dispatch) {
    return announcementApi.getAllAnnouncements().then((announcements) => {
      dispatch({
        type: actionTypes.LOAD_ANNOUNCEMENTS,
        announcements,
      });
    });
  };
}

export function deleteAnnouncement(id) {
  return announcementApi.deleteAnnouncement(id);
}

export function exportAnnouncementsData(filters, scopes) {
  return announcementApi.exportAnnouncementsData(filters, scopes);
}
