import React, { useState } from 'react';
import { connect } from 'react-redux';

import { searchTag } from '../../../actions/keyCodeActions';
import { NavLink } from 'react-router-dom';
import { Table, getTableSettingsDefaults } from '../../common/Table';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import CodeStatusHistory from '../slideout/CodeStatusHistory';
import AutoSelectText from '../../common/AutoSelectText';
import useTableSettings from '../../../hooks/useTableSettings';
import SlidingPane from 'react-sliding-pane';
import CompanyLink from '../../common/links/CompanyLink';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function SearchCodeList({ location, reload, tag, keyProviderId }) {
  const allColumns = [
    'code',
    'tag1',
    'codeStatus',
    'gameName',
    'skuName',
    'requestReference',
    'companyName',
    'keyPool',
  ];
  const suffixColumns = ['id'];
  const { t } = useTranslation();
  const defaultVisibleColumns = allColumns;

  const [historySlideoutOpen, setHistorySlideoutOpen] = useState(false);
  const [historySlideoutItem, setHistorySlideoutItem] = useState();

  const [codes, setCodes] = useState();
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'reporteddicodesearchlistsabledlist-settings',
      getTableSettingsDefaults({
        defaultSort: 'codeStatus',
        direction: 'desc',
        defaultVisibleColumns,
      }),
      {
        tag,
        keyProviderId,
      },
      searchTag,
      setCodes,
      reload,
      t('Failed to search codes'),
      location
    );

  // slideout panel
  const handleShowCodeHistory = (e, code) => {
    e.cancelBubble = true;
    setHistorySlideoutItem(code);
    setHistorySlideoutOpen(true);
  };

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'code',
      Label: t('Key'),
      width: '20%',
      className: 'num no-wrap autoselect',
      Render: (item) => <AutoSelectText>{item.code}</AutoSelectText>,
    },
    {
      Key: 'tag1',
      Label: t('Tag'),
      width: '10%',
      className: 'num autoselect',
      Render: (item) =>
        item.tag1 ? <AutoSelectText>{item.tag1}</AutoSelectText> : '-',
    },
    {
      Key: 'codeStatus',
      Label: t('Key status'),
      width: '10%',
      Render: (item) => item.codeStatus,
    },
    {
      Key: 'gameName',
      Label: t('Game'),
      width: '10%',
      Render: (item) => (
        <NavLink to={`/game/${item.gameId}`}>{item.gameName}</NavLink>
      ),
    },
    {
      Key: 'skuName',
      Label: t('SKU'),
      width: '10%',
      Render: (item) => (
        <NavLink to={`/sku/${item.skuId}`}>{item.skuName}</NavLink>
      ),
    },
    {
      Key: 'requestReference',
      Label: t('Request Ref.'),
      width: '10%',
      Render: (item) => item.requestReference ?? '-',
    },
    {
      Key: 'companyName',
      Label: t('Company'),
      width: '10%',
      Render: (item) => (
        <CompanyLink id={item.companyId} name={item.companyName} />
      ),
    },
    {
      Key: 'keyPool',
      Label: t('Key pool'),
      width: '10%',
      Render: (item) => item.keyPool ?? '-',
    },
    {
      Key: 'id',
      Label: '',
      width: '10%',
      Sort: false,
      className: 'text-right',
      Render: (item) => (
        <Button
          className="btn btn-default"
          onClick={(e) => handleShowCodeHistory(e, item)}
          text={t('Code history')}
        />
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return codes ? (
    <>
      <KwPanel className="mb-4">
        <Table
          columns={columns}
          suffixColumns={suffixColumnsWithConfig}
          allColumns={allColumns}
          rows={codes.data.map((x) => {
            return { Key: x.id, ...x };
          })}
          totalRowCount={codes.totalRowCount}
          filteredRowCount={codes.filteredRowCount}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          isLoading={isLoading}
          filterChange={filterChange}
        />
      </KwPanel>

      <SlidingPane
        isOpen={historySlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setHistorySlideoutOpen(false)}
      >
        <>
          {historySlideoutItem && (
            <CodeStatusHistory
              id={historySlideoutItem.id}
              done={(d) => {
                setHistorySlideoutOpen(false);
              }}
              cancel={() => setHistorySlideoutOpen(false)}
            />
          )}
        </>
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchCodeList);
