import React from 'react';
import KwPanel from '../../common/KwPanel';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { Date_AsString_NoHtml } from '../../../util/formatter';
import Gravatar from 'react-gravatar';
import UserPopup from '../../popups/user';
import { useTranslation } from 'react-i18next';

import image_no_content from '../../../content/images/no-content.svg';

export default function RequestDetailsSignatures({
  done,
  cancel,
  requestStep,
  history,
}) {
  const { t } = useTranslation();
  return (
    <SlideoutLayout title={t('Signatures')} cancel={cancel} done={done}>
      <KwPanel className="mb-4 form-horizontal">
        <header className="mb-2">
          <h4 className="no-margin bold ">{t('Signed By')}</h4>
        </header>
        {requestStep &&
        requestStep.authorisations &&
        requestStep.authorisations.length > 0 ? (
          requestStep.authorisations.map((x) => (
            <div className="signature clearfix space-bottom" key={x}>
              <div className="float-left">
                <h4 className="bold space-bottom" data-bind="text: Name">
                  {x.authorisedBy}
                </h4>
                <div style={{ fontSize: '1.1rem' }}>
                  {Date_AsString_NoHtml(x.authorisedDate)}
                </div>
                <div style={{ color: '#666666' }}>
                  {requestStep.keyRequestApproverUserProfileGroupName}
                </div>
              </div>
              <Gravatar
                email={x.authorisedUsername}
                size={52}
                rating="pg"
                default="retro"
                className="img-circle float-right img-border hide-phablet"
                style={{ border: '2px solid #ffffff', overflow: 'hidden' }}
              />
            </div>
          ))
        ) : (
          <div className="text-center py-3">
            <img
              src={image_no_content}
              className="space-bottom-small"
              alt={t('Empty desk')}
            />
            <div>{t('No users have signed')}</div>
          </div>
        )}
      </KwPanel>
      {requestStep &&
        requestStep.authorisations.length < requestStep.requiredSignatures && (
          <KwPanel className="mb-4 form-horizontal">
            <header className="mb-2">
              <h4 className="no-margin bold ">{t('Who can sign')}</h4>
            </header>
            {requestStep && requestStep.whoCanSign && requestStep.authorisations
              ? requestStep.whoCanSign.map((x) => (
                  <div
                    key={`whocan${x.userProfileId}`}
                    className="mb-3 d-flex align-items-center"
                  >
                    <Gravatar
                      email={x.username}
                      size={24}
                      rating="pg"
                      default="retro"
                      className="img-circle"
                      style={{
                        border: '2px solid #ffffff',
                        overflow: 'hidden',
                      }}
                    />
                    <UserPopup id={x.userProfileId}>
                      <span className="ms-3">{x.fullName}</span>
                    </UserPopup>
                  </div>
                ))
              : t('Nobody')}
          </KwPanel>
        )}
    </SlideoutLayout>
  );
}
