import React, { useEffect, useState } from 'react';
import CombinedStats from '../../../common/CombinedStats';
import colours from '../../../../constants/colours';
import { withRouter } from 'react-router-dom';
import { DateForFilter } from '../../../../util/formatter';
import { scopes as tableScopes } from '../../../../constants/scopes';
import { statsModes } from '../../../../hooks/useAggregateStatsData';

const KeyProviderStats = ({ id, stats, getData, history }) => {
  const [_stats, _setStats] = useState([]);

  useEffect(() => {
    if (stats) {
      _setStats([
        {
          colour: colours.STATS_OPEN_REQUESTS,
          linkto: `/requests/all?f_requestStatus=pending%20approval&${tableScopes.KEYPROVIDER}=${id}`,
          title: 'Open requests',
          metric: stats.openRequests,
          primaryStat: true,
        },
        {
          colour: colours.STATS_OVER_SLA,
          linkto: `/requests/all?${tableScopes.ISOVERSLA}=true&${tableScopes.KEYPROVIDER}=${id}`,
          title: 'Over SLA',
          metric: stats.overSLA,
          primaryStat: true,
        },
        {
          colour: colours.STATS_COMPLETED_REQUESTS,
          linkto: `/requests/all?f_requestStatus=authorised&${tableScopes.KEYPROVIDER}=${id}`,
          title: 'Complete requests',
          metric: stats.completeRequests,
        },
        {
          colour: colours.STATS_KEYS_IN_POOL,
          linkto: `/keys/game?${tableScopes.KEYPROVIDER}=${id}`,
          title: 'Keys in pool',
          metric: stats.keysAvailable,
        },
        {
          colour: colours.STATS_KEYS_DELIVERED,
          linkto: `/keys/game?${tableScopes.KEYPROVIDER}=${id}`,
          title: 'Keys delivered',
          metric: stats.keysAllocated,
        },
        {
          colour: colours.STATS_KEYS_DELIVERED_VALUE,
          linkto: `/keys/game?${tableScopes.KEYPROVIDER}=${id}`,
          title: 'Keys delivered value',
          metric: stats.keyStockValue,
        },
        {
          colour: colours.STATS_KEYS_SOLD,
          linkto: `/keys/game?${tableScopes.KEYPROVIDER}=${id}`,
          title: 'Keys reported sold',
          metric: stats.keysSold,
        },
        {
          colour: colours.STATS_KEYS_DISABLED,
          linkto: `/keys/game?${tableScopes.KEYPROVIDER}=${id}`,
          title: 'Keys disabled',
          metric: stats.keysBlacklisted,
        },
        {
          colour: colours.STATS_KEYS_EXPIRED,
          linkto: `/keys/game?${tableScopes.KEYPROVIDER}=${id}`,
          title: 'Keys expired',
          metric: stats.keysExpired,
        },
        {
          colour: colours.STATS_ACCOUNTS,
          linkto: `/keys/accounts?${tableScopes.KEYPROVIDER}=${id}`,
          title: 'Accounts',
          metric: stats.accountsTotal,
        },
        {
          colour: colours.STATS_ACCOUNT_DOWNLOADED,
          linkto: `/keys/accounts?${tableScopes.KEYPROVIDER}=${id}&${tableScopes.ISDOWNLOADED}=true`,
          title: 'Accounts (downloaded)',
          metric: stats.accountsDownloadedKeys,
        },
      ]);
    }
  }, [id, stats]);

  return (
    <CombinedStats
      stats={_stats}
      getData={getData}
      setModeStorageKey={`key-provider-stats-mode`}
      heatmapOnClick={(value) => {
        if (value) {
          let date = DateForFilter(value.date);
          let destination = `/requests/all?${tableScopes.KEYPROVIDER}=${id}&f_requestedDateFrom=${date}&f_requestedDateTo=${date}`;
          history.push(destination);
        }
      }}
      id={id}
      mode={statsModes.KEYPROVIDER}
    />
  );
};

export default withRouter(KeyProviderStats);
