import * as keyCodeApi from '../api/keyCodeApi';
import actionTypes from '../constants/actionTypes';

//----------------------------------------------------------------------
// Search
export function searchCode({
  code,
  keyProviderId,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.searchCode({
    code,
    keyProviderId,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit,
  });
}

export function searchTag({
  tag,
  keyProviderId,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.searchTag({
    tag,
    keyProviderId,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit,
  });
}

export function getCodeStatusHistory(id) {
  return keyCodeApi.getCodeStatusHistory(id);
}

//----------------------------------------------------------------------
// ACTIVATED
export function getActivatedTransactionById(id) {
  return keyCodeApi.getActivatedTransactionById(id);
}

export function loadActivatedTransactions({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.getActivatedTransactions(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function uploadActivations(record) {
  return keyCodeApi.uploadActivations(record);
}

export function loadActivatedTransactionSkus({
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.loadActivatedTransactionSkus(
    id,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function clearActivatedTransaction() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CLEAR_ACTIVATION,
    });
  };
}

export function exportActivatedTransactionData(filters, scopes) {
  return keyCodeApi.exportActivatedTransactionData(filters, scopes);
}
//----------------------------------------------------------------------
// DISABLED
export function getDisabledTransactionById(id) {
  return keyCodeApi.getDisabledTransactionById(id);
}

export function loadDisabledTransactions({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.getDisabledTransactions(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function uploadDisabled(record) {
  return keyCodeApi.uploadDisabled(record);
}

export function loadDisabledTransactionSkus({
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.loadDisabledTransactionSkus(
    id,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function downloadDisabledKeys(id) {
  return keyCodeApi.downloadDisabledKeys(id);
}
export function downloadDisabledKeysForSkuAndCompany(id, skuId, companyId) {
  return keyCodeApi.downloadDisabledKeysForSkuAndCompany(id, skuId, companyId);
}

export function clearDisabledTransaction(id) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CLEAR_DISABLE_TRANSACTION,
      id,
    });
  };
}

export function authoriseDisabledTransaction(id) {
  return keyCodeApi.authoriseDisabledTransaction(id);
}

export function exportDisabledTransactionData(filters, scopes) {
  return keyCodeApi.exportDisabledTransactionData(filters, scopes);
}

//----------------------------------------------------------------------
// DISABLED REASONS
export function saveDisableReason(disableReason) {
  return function (dispatch) {
    return keyCodeApi
      .saveDisableReason(disableReason)
      .then((savedDisableReason) => {
        dispatch({
          type: disableReason.id
            ? actionTypes.UPDATE_DISABLEREASON
            : actionTypes.CREATE_DISABLEREASON,
          disableReason: { ...disableReason, id: savedDisableReason.id },
        });
      });
  };
}

export function getDisableReasonById(id) {
  return keyCodeApi.getDisableReasonById(id);
}

export function loadDisableReasons({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.getDisableReasons(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function deleteDisableReason(id) {
  return function (dispatch) {
    return keyCodeApi.deleteDisableReason(id).then(() => {
      dispatch({
        type: actionTypes.DELETE_DISABLEREASON,
        id,
      });
    });
  };
}

export function getDisableReasons() {
  return function (dispatch) {
    return keyCodeApi.getAllDisableReasons().then((response) => {
      let disableReasons = response.data.sort(
        (a, b) => (a.reason > b.reason && 1) || -1
      );
      dispatch({
        type: actionTypes.LOAD_DISABLEREASONS,
        disableReasons,
      });
    });
  };
}

export function exportDisableReasonsData(filters, scopes) {
  return keyCodeApi.exportDisableReasonsData(filters, scopes);
}

//----------------------------------------------------------------------
// SOLD
export function getSoldTransactionById(id) {
  return keyCodeApi.getSoldTransactionById(id);
}

export function loadSoldTransactions({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.getSoldTransactions(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function uploadSold(record) {
  return keyCodeApi.uploadSold(record);
}
export function loadSoldTransactionSkus({
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.loadSoldTransactionSkus(
    id,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function clearSoldTransaction() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CLEAR_SOLD_TRANSACTION,
    });
  };
}

export function authoriseSoldTransaction(id) {
  return keyCodeApi.authoriseSoldTransaction(id);
}

export function exportSoldTransactionData(filters, scopes) {
  return keyCodeApi.exportSoldTransactionData(filters, scopes);
}

//----------------------------------------------------------------------
// MOVE
export function getMoveTransactionById(id) {
  return keyCodeApi.getMoveTransactionById(id);
}

export function loadMoveTransactions({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.getMoveTransactions(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function clearMoveTransaction() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CLEAR_MOVE_TRANSACTION,
    });
  };
}

export function exportMoveTransactionData(filters, scopes) {
  return keyCodeApi.exportMoveTransactionData(filters, scopes);
}

//----------------------------------------------------------------------
// INGESTED
export function getIngestedTransactionById(id) {
  return keyCodeApi.getIngestedTransactionById(id);
}

export function removeIngestedKeys(id) {
  return keyCodeApi.removeIngestedKeys(id);
}

export function retryIngestedKeys(id) {
  return keyCodeApi.retryIngestedKeys(id);
}

export function loadIngestedTransactions({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.getIngestedTransactions(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function clearImportTransaction(id) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.DELETE_IMPORT_TRANSACTION,
      id,
    });
  };
}

export function exportIngestedTransactionsData(filters, scopes) {
  return keyCodeApi.exportIngestedTransactionsData(filters, scopes);
}

//----------------------------------------------------------------------
// BULK INGESTED

export function getBulkIngestedTransactionById(id) {
  return keyCodeApi.getBulkIngestedTransactionById(id);
}

export function loadBulkIngestedTransactions({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyCodeApi.getBulkIngestedTransactions(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function bulkUpload(record) {
  return keyCodeApi.bulkUpload(record);
}

export function exportBulkIngestedTransactionsData(filters, scopes) {
  return keyCodeApi.exportBulkIngestedTransactionsData(filters, scopes);
}

//----------------------------------------------------------------------
// DISABLE KEYS

export function disableKeys({
  keyProviderId,
  reason,
  disabledReasons,
  description,

  targetGameId,
  targetGameSKUId,
  targetCompanyId,
  targetKeyWorkflowId,
  targetRequestingUserProfileId,
  targetAccountDetailId,
  targetKeyRequestId,
  targetKeyRequestAccountDetail,

  targetIsAvailable,
  targetIsAllocated,
  targetIsActivated,
  targetIsSold,
  targetIsExpired,
}) {
  return keyCodeApi.disableKeys({
    keyProviderId,
    reason,
    disabledReasons,
    description,

    targetGameId,
    targetGameSKUId,
    targetCompanyId,
    targetKeyWorkflowId,
    targetRequestingUserProfileId,
    targetAccountDetailId,
    targetKeyRequestId,
    targetKeyRequestAccountDetail,

    targetIsAvailable,
    targetIsAllocated,
    targetIsActivated,
    targetIsSold,
    targetIsExpired,
  });
}

//----------------------------------------------------------------------
// MOVE

export function moveKeys({
  skuId,
  sourceKeyWorkflowId,
  destinationKeyPools,
  description,
}) {
  return keyCodeApi.moveKeys({
    skuId,
    sourceKeyWorkflowId,
    destinationKeyPools,
    description,
  });
}

export function splitFileIntoKeyPools({
  skuId,
  fileId,
  destinationKeyPools,
  description,
}) {
  return keyCodeApi.splitFileIntoKeyPools({
    skuId,
    fileId,
    destinationKeyPools,
    description,
  });
}

// ===================================
// Move keys between requests
export function moveKeysBetweenRequests({
  sourceKeyRequestId,
  destinationKeyRequestId,
  reason,
}) {
  return keyCodeApi.moveKeysBetweenRequests({
    sourceKeyRequestId,
    destinationKeyRequestId,
    reason,
  });
}
