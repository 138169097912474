import React from 'react';
import LeftNavWrapper from '../menu-components/LeftNavWrapper';
import LeftMenuItem from '../menu-components/LeftMenuItem';
import permissionTypes from '../../../constants/permissionTypes';
import { hasAnyPermission } from '../../auth/authUtils';
import icons from '../../../constants/icons';
import NavColours from '../../../constants/NavColours';
import { useTranslation } from 'react-i18next';

export default function LeftMenu_Settings({ currentUser, wrapperClassName }) {
  const { t } = useTranslation();
  return (
    <LeftNavWrapper className={wrapperClassName}>
      <LeftMenuItem
        url="/settings/key-providers/"
        activeif="/settings/key-provider"
        icon={icons.KEY_PROVIDER}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Key providers')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
      />
      <LeftMenuItem
        url="/settings/package-types/"
        activeif="/settings/package-type"
        icon={icons.PACKAGE_TYPE}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Package types')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
      />
      <LeftMenuItem
        url="/settings/platforms/"
        activeif="/settings/platform"
        icon={icons.PLATFORM}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Platforms')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_PLATFORMS]}
      />
      <LeftMenuItem
        url="/settings/territories/"
        activeif="/settings/territor"
        icon={icons.TERRITORIES}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Territories')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_TERRITORIES]}
      />
      {hasAnyPermission(currentUser, [
        permissionTypes.MANAGE_SETTINGS,
        permissionTypes.MANAGE_PLATFORMS,
      ]) && <hr />}
      <LeftMenuItem
        url="/settings/workflows/"
        activeif="/settings/workflow"
        icon={icons.WORKFLOW}
        colour={NavColours.NAV_COLOUR_2}
        title={t('Workflows')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_WORKFLOWS]}
      />
      <LeftMenuItem
        url="/settings/approver-notification-users/"
        activeif="/settings/approver-notification-users"
        icon={icons.WORKFLOW}
        colour={NavColours.NAV_COLOUR_2}
        title={t('Workflow approver notifications')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_WORKFLOWS]}
      />
      {hasAnyPermission(currentUser, [permissionTypes.MANAGE_WORKFLOWS]) && (
        <hr />
      )}
      <LeftMenuItem
        url="/users/"
        activeRegex="^\/settings\/user(s)?\/"
        icon={icons.USER}
        colour={NavColours.NAV_COLOUR_3}
        title={t('All users')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_USERS]}
      />
      <LeftMenuItem
        url="/settings/user-profile-groups/"
        activeif="/settings/user-profile-group"
        icon={icons.USERS}
        colour={NavColours.NAV_COLOUR_3}
        title={t('Groups')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_USERGROUPS]}
      />
      <LeftMenuItem
        url="/settings/permissions/"
        activeif="/settings/permission"
        icon={icons.USER_PERMISSIONS}
        colour={NavColours.NAV_COLOUR_3}
        title={t('Permissions')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_PERMISSIONS]}
      />
      <LeftMenuItem
        url="/settings/uploaders/"
        activeif="/settings/uploader"
        icon={icons.UPLOADERS}
        colour={NavColours.NAV_COLOUR_3}
        title={t('Uploaders')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_PERMISSIONS]}
      />
      {hasAnyPermission(currentUser, [
        permissionTypes.MANAGE_USERS,
        permissionTypes.MANAGE_USERGROUPS,
        permissionTypes.MANAGE_PERMISSIONS,
      ]) && <hr />}
      <LeftMenuItem
        url="/settings/company-types/"
        activeif="/settings/company-type"
        icon={icons.COMPANY}
        colour={NavColours.NAV_COLOUR_4}
        title={t('Company types')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_COMPANIES]}
      />
      {hasAnyPermission(currentUser, [permissionTypes.MANAGE_COMPANIES]) && (
        <hr />
      )}
      <LeftMenuItem
        url="/settings/disabled-reasons/"
        activeif="/settings/disabled-reason"
        icon={icons.DISABLED_REASON}
        colour={NavColours.NAV_COLOUR_5}
        title={t('Disabled reasons')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
      />
      <LeftMenuItem
        url="/settings/key-pool-notifications/"
        activeif="/settings/key-pool-notification"
        icon={icons.SLIDERS}
        colour={NavColours.NAV_COLOUR_5}
        title={t('Key pool notifications')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_KEYS]}
      />
      <LeftMenuItem
        url="/settings/upload-games/"
        activeif="/settings/upload-game"
        icon={icons.UPLOAD}
        colour={NavColours.NAV_COLOUR_5}
        title={t('Upload games')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_GAMES]}
      />
      {hasAnyPermission(currentUser, [
        permissionTypes.MANAGE_SETTINGS,
        permissionTypes.MANAGE_KEYS,
        permissionTypes.MANAGE_GAMES,
      ]) && <hr />}
      <LeftMenuItem
        url="/settings/emails/"
        activeif="/settings/email"
        icon={icons.EMAIL_LOGS}
        colour={NavColours.NAV_COLOUR_6}
        title={t('Email logs')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
      />
      <hr />
      <LeftMenuItem
        url="/settings/announcements/"
        activeif="/settings/announcements"
        icon={icons.ANNOUNCEMENT}
        colour={NavColours.NAV_COLOUR_7}
        title={t('Announcements')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_SETTINGS]}
      />
    </LeftNavWrapper>
  );
}
