import React, { useEffect, useState } from 'react';
import CombinedStats from '../../common/CombinedStats';
import { DateForFilter } from '../../../util/formatter';
import { withRouter } from 'react-router-dom';
import colours from '../../../constants/colours';
import { scopes as tableScopes } from '../../../constants/scopes';
import { statsModes } from '../../../hooks/useAggregateStatsData';
const CompanyStats = ({ id, stats, getData, history }) => {
  const [_stats, _setStats] = useState([]);

  useEffect(() => {
    if (stats) {
      _setStats([
        {
          colour: colours.STATS_OPEN_REQUESTS,
          linkto: `/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISPENDING}=true&${tableScopes.COMPANY}=${id}`,
          title: 'Open requests',
          metric: stats.openRequests,
          primaryStat: true,
        },
        {
          colour: colours.STATS_OVER_SLA,
          linkto: `/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISOVERSLA}=true&${tableScopes.COMPANY}=${id}`,
          title: 'Over SLA',
          metric: stats.overSLA,
          primaryStat: true,
        },
        {
          colour: colours.STATS_OPEN_REQUESTS_VALUE,
          linkto: `/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISPENDING}=true&${tableScopes.COMPANY}=${id}`,
          title: 'Open requests value',
          metric: stats.openRequestsValue,
        },
        {
          colour: colours.STATS_COMPLETED_REQUESTS,
          linkto: `/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISAUTHORISED}=true&${tableScopes.COMPANY}=${id}`,
          title: 'Complete requests',
          metric: stats.completeRequests,
        },
        {
          colour: colours.STATS_KEYS_DELIVERED,
          linkto: `/keys/game?${tableScopes.COMPANY}=${id}`,
          title: 'Keys delivered',
          metric: stats.keysAllocated,
        },
        {
          colour: colours.STATS_KEYS_DELIVERED_VALUE,
          linkto: `/keys/game?${tableScopes.COMPANY}=${id}`,
          title: 'Key delivered value',
          metric: stats.keyStockValue,
        },
        {
          colour: colours.STATS_KEYS_SOLD,
          linkto: `/keys/game?${tableScopes.COMPANY}=${id}`,
          title: 'Keys reported sold',
          metric: stats.keysSold,
        },
        {
          colour: colours.STATS_KEYS_DISABLED,
          linkto: `/keys/game?${tableScopes.COMPANY}=${id}`,
          title: 'Keys disabled',
          metric: stats.keysBlacklisted,
        },
      ]);
    }
  }, [id, stats]);

  return (
    <CombinedStats
      stats={_stats}
      getData={getData}
      setModeStorageKey={`company-stats-mode`}
      heatmapOnClick={(value) => {
        if (value) {
          let date = DateForFilter(value.date);
          let destination = `/requests/all?${tableScopes.COMPANY}=${id}&f_requestedDateFrom=${date}&f_requestedDateTo=${date}`;
          history.push(destination);
        }
      }}
      id={id}
      mode={statsModes.COMPANY}
    />
  );
};

export default withRouter(CompanyStats);
