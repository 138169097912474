import { useState, useEffect } from 'react';
import { getAggregateStats } from '../actions/requestActions';
import { getAggregateStats as getCompanyAggregateStats } from '../actions/companyActions';
import { getAggregateStats as getGameAggregateStats } from '../actions/gameActions';
import { getAggregateStats as getPlatformAggregateStats } from '../actions/platformActions';
import { getAggregateStats as getKeyProviderAggregateStats } from '../actions/keyProviderActions';
import { getAggregateStats as getKeyWorkflowAggregateStats } from '../actions/workflowActions';
import { getAggregateStats as getUserAggregateStats } from '../actions/userActions';
import { getAggregateStats as getSKUAggregateStats } from '../actions/skuActions';
import { DateForFilter } from '../util/formatter';
import { scopes as tableScopes } from '../constants/scopes';

// export an enum of modes for company, game, platform, keyworkflow
export const statsModes = {
  ALL: 'all',
  COMPANY: 'company',
  GAME: 'game',
  KEYPROVIDER: 'key provider',
  PLATFORM: 'platform',
  SKU: 'sku',
  USER: 'user',
  KEYWORKFLOW: 'keyWorkflow',
};

export default function useAggregateStatsData(statsMode, id, from, to) {
  const [data, setData] = useState();
  const [showByCompany, setShowByCompany] = useState(true);
  const [showByGame, setShowByGame] = useState(true);
  const [showByPlatform, setShowByPlatform] = useState(true);
  const [showByKeyWorkflow, setShowByKeyWorkflow] = useState(true);
  const [baseUrl, setBaseUrl] = useState(
    '/requests/all?f_requestedDateFrom=30-Jan-2023&f_requestedDateTo=30-Jan-2023'
  );
  const [dateRangeChanged, setDateRangeChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // listen for changes to the date range in order to trigger a refetch
  useEffect(() => {
    setDateRangeChanged(true);
  }, [from, to]);

  useEffect(() => {
    if (statsMode) {
      let getData;
      let fromDateForFilter = DateForFilter(from);
      let toDateForFilter = DateForFilter(to);

      switch (statsMode) {
        case statsModes.COMPANY:
          setShowByCompany(false);
          getData = () => getCompanyAggregateStats(id, from, to);
          setBaseUrl(
            `/requests/all?f_requestedDateFrom=${fromDateForFilter}&f_requestedDateTo=${toDateForFilter}&${tableScopes.COMPANY}=${id}`
          );
          break;
        case statsModes.GAME:
          setShowByGame(false);
          getData = () => getGameAggregateStats(id, from, to);
          setBaseUrl(
            `/requests/all?f_requestedDateFrom=${fromDateForFilter}&f_requestedDateTo=${toDateForFilter}&${tableScopes.GAME}=${id}`
          );
          break;
        case statsModes.KEYPROVIDER:
          getData = () => getKeyProviderAggregateStats(id, from, to);
          setBaseUrl(
            `/requests/all?f_requestedDateFrom=${fromDateForFilter}&f_requestedDateTo=${toDateForFilter}&${tableScopes.KEYPROVIDER}=${id}`
          );
          break;
        case statsModes.PLATFORM:
          setShowByPlatform(false);
          getData = () => getPlatformAggregateStats(id, from, to);
          setBaseUrl(
            `/requests/all?f_requestedDateFrom=${fromDateForFilter}&f_requestedDateTo=${toDateForFilter}&${tableScopes.PLATFORM}=${id}`
          );
          break;
        case statsModes.SKU:
          setShowByGame(false);
          getData = () => getSKUAggregateStats(id, from, to);
          setBaseUrl(
            `/requests/all?f_requestedDateFrom=${fromDateForFilter}&f_requestedDateTo=${toDateForFilter}&${tableScopes.SKU}=${id}`
          );
          break;
        case statsModes.USER:
          getData = () => getUserAggregateStats(id, from, to);
          setBaseUrl(
            `/requests/all?f_requestedDateFrom=${fromDateForFilter}&f_requestedDateTo=${toDateForFilter}&${tableScopes.USER}=${id}`
          );
          break;
        case statsModes.KEYWORKFLOW:
          getData = () => getKeyWorkflowAggregateStats(id, from, to);
          setShowByKeyWorkflow(false);
          setBaseUrl(
            `/requests/all?f_requestedDateFrom=${fromDateForFilter}&f_requestedDateTo=${toDateForFilter}&${tableScopes.KEYWORKFLOW}=${id}`
          );
          break;
        case statsModes.ALL:
        default:
          getData = () => getAggregateStats(from, to);
          setBaseUrl(
            `/requests/all?f_requestedDateFrom=${fromDateForFilter}&f_requestedDateTo=${toDateForFilter}`
          );
          break;
      }

      const shouldFetch = (!data || dateRangeChanged) && !isLoading;

      if (shouldFetch && getData) {
        if (!data) {
          setIsLoading(true);
        }
        getData()
          .then((d) => {
            setData(d);
            setDateRangeChanged(false);
            setIsLoading(false);
          })
          .catch((ex) => {
            //silent failure, or we can throw a message here
            console.error(ex);
            setIsLoading(false);
          });
      }
    }
  }, [data, statsMode, id, from, to, dateRangeChanged, isLoading]);

  return [
    data,
    showByCompany,
    showByGame,
    showByPlatform,
    showByKeyWorkflow,
    baseUrl,
    isLoading,
  ];
}
