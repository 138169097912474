import React from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import SKUsModifiedByMonthByUserList from './components/month/SKUsModifiedByMonthByUserList';
import SKUsModifiedByWeekByUserList from './components/week/SKUsModifiedByWeekByUserList';
import ReportTableViewSwitcher from './components/ReportTableViewSwitcher';
import { useLocalStorage } from '../../hooks/useLocalStorage';

function SKUsModifiedByUser({ location }) {
  const [groupBy, setGroupBy] = useLocalStorage('report-groupBy', 'month');

  return (
    <>
      <ActionBar></ActionBar>
      {groupBy === 'month' ? (
        <SKUsModifiedByMonthByUserList
          location={location}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      ) : (
        <SKUsModifiedByWeekByUserList
          location={location}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SKUsModifiedByUser);
