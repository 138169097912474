import { apiUrl, deleteArray, get, postArray } from './apiUtils';
const baseUrl = apiUrl + '/permissions/';

export function getAllPermissions() {
  return get(baseUrl);
}

// by group
export function getPermissionGroup(userProfileGroupId) {
  return get(`${baseUrl}group/${userProfileGroupId}`);
}
export function addPermissionGroup(permissionKeys, userProfileGroupId) {
  return postArray(`${baseUrl}group/${userProfileGroupId}`, permissionKeys);
}
export function deletePermissionGroup(permissionKeys, userProfileGroupId) {
  return deleteArray(`${baseUrl}group/${userProfileGroupId}`, permissionKeys);
}
// by user
export function getPermissionUser(userProfileId) {
  return get(`${baseUrl}user/${userProfileId}`);
}
export function addPermissionUser(permissionKeys, userProfileId) {
  return postArray(`${baseUrl}user/${userProfileId}`, permissionKeys);
}
export function deletePermissionUser(permissionKeys, userProfileId) {
  return deleteArray(`${baseUrl}user/${userProfileId}`, permissionKeys);
}
