import React from 'react';
import icons from '../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export default function SuccessIcon({ result }) {
  const { t } = useTranslation();
  return result.success ? (
    <FontAwesomeIcon
      icon={icons.SUCCESS}
      className="success me-2"
      title={t('Success')}
      fixedWidth
    />
  ) : (
    <FontAwesomeIcon
      icon={icons.FAILED}
      className="failure me-2"
      title={t('Failed')}
      fixedWidth
    />
  );
}
