import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getSoldTransactionById,
  clearSoldTransaction,
  authoriseSoldTransaction,
} from '../../actions/keyCodeActions';
import ReportedSoldTransactionDetails from './components/ReportedSoldTransactionDetails';
import ReportedSoldTransactionSkusList from './components/ReportedSoldTransactionSkusList';
import KeyProcessProgressBar from './components/KeyProcessProgressBar';
import ActionBar from '../layout/ActionBar';
import UploadSold from './slideout/UploadSold';

import { addToGroup, removeFromGroup } from '../../actions/signalRActions';
import KwPanel from '../common/KwPanel';
import { toast } from 'react-toastify';
import SlidingPane from 'react-sliding-pane';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

function ReportedSoldTransaction({
  id,
  history,
  location,
  addToGroup,
  removeFromGroup,
  soldTransaction,
  clearSoldTransaction,
}) {
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState();
  const [reload, setReload] = useState();

  const [uploadSlideoutOpen, setUploadSlideoutOpen] = useState(false);

  useEffect(() => {
    if (id) {
      // get transaction
      getSoldTransactionById(id)
        .then((d) => {
          setTransaction(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/keys/reported-sold/');
          toast.error(t('Transaction not found'));
        });
    }
  }, [id, history, reload, t]);

  useEffect(() => {
    if (
      transaction &&
      (transaction.status === 'Pending' ||
        transaction.status === 'Pending Authorisation')
    ) {
      const key = `sold:${transaction.id}`;
      addToGroup(key);
      return () => removeFromGroup(key);
    }
  }, [transaction, addToGroup, removeFromGroup]);

  useEffect(() => {
    if (soldTransaction && soldTransaction.id === id) {
      setTransaction((prevState) => ({
        ...prevState,
        ...soldTransaction,
      }));
      return () => clearSoldTransaction();
    }
  }, [soldTransaction, id, clearSoldTransaction]);

  const handleAuthorise = (e) => {
    e.cancelBubble = true;
    authoriseSoldTransaction(id)
      .then((authorised) => setTransaction({ ...transaction, ...authorised }))
      .catch((e) => {
        toast.error(e.message || t('Failed to authorise transaction'));
      });
  };

  return (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/keys/reported-sold', label: t('Reported sold') },
          { label: t('Sold transaction') },
        ]}
      >
        <Button
          className="btn btn-primary"
          onClick={() => {
            setUploadSlideoutOpen(true);
          }}
          text={t('Upload sold keys')}
        />
      </ActionBar>
      {transaction && (
        <>
          <ReportedSoldTransactionDetails transaction={transaction} />
          {transaction.success && (
            <ReportedSoldTransactionSkusList
              id={id}
              location={location}
              reload={reload}
            />
          )}
          {!transaction.isAuthorised ? (
            <KwPanel className="mb-4">
              <header className="mb-2">
                <h2>{t('Authorise transaction')}</h2>
              </header>
              <Button
                className="btn btn-primary"
                onClick={handleAuthorise}
                text={t('Authorise')}
              />
            </KwPanel>
          ) : (
            <KeyProcessProgressBar transaction={transaction} />
          )}
        </>
      )}

      <SlidingPane
        isOpen={uploadSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setUploadSlideoutOpen(false)}
      >
        <UploadSold
          done={(d) => {
            setUploadSlideoutOpen(false);
            setReload(new Date());
          }}
          cancel={() => setUploadSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    soldTransaction: state.soldTransaction,
  };
}

const mapDispatchToProps = {
  addToGroup,
  removeFromGroup,
  clearSoldTransaction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportedSoldTransaction);
