import React from 'react';
import { Controller } from 'react-hook-form';
import FormGroup from './FormGroup';
import Checkbox from './Checkbox';
const FormCheckBoxList = ({
  name,
  label,
  error,
  errorMessage,
  items,
  required,
  getValues,
  setValue,
  control,
}) => {
  const handleOnChange = (item) => {
    const value = getValues(name) || [];
    const arr = value.includes(item.value)
      ? value.filter((i) => i !== item.value) // remove item
      : [...value, item.value];
    setValue(name, arr); // add item
  };
  return (
    <FormGroup
      label={label}
      required={required}
      error={error}
      errorMessage={errorMessage}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        render={() =>
          items?.map((x) => (
            <div
              className="mb-2"
              key={x.value}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Checkbox
                onChange={() => handleOnChange(x)}
                checked={getValues(name)?.includes(x.value) ?? false}
                label={x.text}
              />
            </div>
          )) ?? <></>
        }
      />
    </FormGroup>
  );
};
export default FormCheckBoxList;
