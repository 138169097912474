import {
  apiUrl,
  getList,
  deleteRecord,
  getSingleRecord,
  postAction,
  putAction,
  getAuditData,
  get,
  exportData,
  getAll,
  getStatsData,
  upload,
} from './apiUtils';
const baseUrl = apiUrl + '/companies/';

export function getCompanies(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function getAllCompanies() {
  return getAll(baseUrl);
}
export function getRequestableCompanies(gameId, skuId) {
  return skuId
    ? get(`${baseUrl}requestable?skuId=${skuId}`)
    : gameId
      ? get(`${baseUrl}requestable?gameId=${gameId}`)
      : get(`${baseUrl}requestable`);
}
export function getCompanyById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveCompany(company) {
  // handle commas, decimals etc
  if (company.transactionLimit) {
    company.transactionLimit =
      parseFloat(company.transactionLimit.replace(/,/g, '')) || 0;
  }
  // handle commas, decimals etc
  if (company.globalTransactionLimit) {
    company.globalTransactionLimit =
      parseFloat(company.globalTransactionLimit.replace(/,/g, '')) || 0;
  }
  // handle commas, decimals etc
  if (company.outsideStockKeys) {
    company.outsideStockKeys =
      parseInt(company.outsideStockKeys.replace(/,/g, '')) || 0;
  }
  // handle null ("") companyType
  if (company.companyTypeId === '') {
    company.companyTypeId = null;
  }

  company.users = null;

  return upload(baseUrl, company);
}

export function saveCompanyKeyProviders(id, keyProviders) {
  return putAction(`${baseUrl}${id}/keyproviderreferences`, { keyProviders });
}

export function deleteCompany(id) {
  return deleteRecord(baseUrl, id);
}

export function getStats(id) {
  return getStatsData(baseUrl, id);
}

export function getStatsByDay(id) {
  return get(`${baseUrl}${id}/statsByDay/`);
}

export function getAggregateStats(id, from, to) {
  // Format the from as YYYY-MM-DD
  from = from.toISOString().split('T')[0];
  // Format the to as YYYY-MM-DD
  to = to.toISOString().split('T')[0];

  return get(`${baseUrl}${id}/aggregateStats?fromDate=${from}&toDate=${to}`);
}

export function getAudit(id) {
  return getAuditData(baseUrl, id);
}

export function addAudit(id, comment) {
  return postAction(`${baseUrl}${id}/audit`, { comment });
}

export function disableKeys(id) {
  return postAction(`${baseUrl}${id}/disablekeys`, null);
}

export function getUsers(id, filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}${id}/users`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportCompanyListData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/companies/search`,
    'companyName',
    filters,
    scopes
  );
}
