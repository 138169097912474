import React, { useEffect } from 'react';
import { signinRedirectCallback } from '../../services/userService';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SigninOidc() {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    const logError = (e) => {
      try {
        if (e.message.indexOf('exp is in the past') > -1) {
          const expiryTime = e.message.replace('exp is in the past:', '');
          var tokenExpiresAt = new Date(1970, 0, 1); // Epoch
          tokenExpiresAt.setSeconds(expiryTime);
          const now = new Date();
          console.error(
            `Your browser does not match the server time. Token expires at: ${tokenExpiresAt.toUTCString()}, your time ${now.toUTCString()}`
          );
        }
        console.error(e);
      } catch (ex) {
        console.error(ex);
      }
    };

    async function signinAsync() {
      await signinRedirectCallback()
        .then((user) => {
          history.push(user.state || '/');
        })
        .catch((e) => {
          logError(e);
          history.push('/error');
        });
    }
    signinAsync();
  }, [history]);

  return <div>{t('Redirecting...')}</div>;
}

export default SigninOidc;
