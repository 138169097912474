import React from 'react';
import FormInput from '../../../common/FormInput';
import PropTypes from 'prop-types';
import FormColourPicker from '../../../common/FormColourPicker';
import FormImage from '../../../common/FormImage';
import { useTranslation } from 'react-i18next';

const PlatformForm = ({ platform, register, errors, control, setFile }) => {
  const { t } = useTranslation();
  return (
    platform && (
      <>
        <FormInput
          register={register}
          required={true}
          error={errors.name}
          errorMessage={t('Required')}
          name="name"
          label={t('Name')}
          defaultValue={platform.name || ''}
          autoFocus={true}
        />
        <FormColourPicker
          control={control}
          name="colourHex"
          label={t('Colour')}
          extraRowClasses="align-items-start"
          defaultValue={platform.colourHex || 'transparent'}
        />
        <FormImage
          control={control}
          name="image"
          label={t('Image')}
          setFile={setFile}
          value={platform.imageId}
        ></FormImage>
      </>
    )
  );
};

PlatformForm.propTypes = {
  platform: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default PlatformForm;
