import React from 'react';
import icons from '../../../../constants/icons';
import ImageFromApi from '../../../common/ImageFromApi';
import PropTypes from 'prop-types';

function KeyProviderRow({ game, style, keyProviders }) {
  let keyProvider = keyProviders.find(
    (keyProvider) => keyProvider.id === game.keyProviderId
  );
  return (
    <div className="grid-empty" style={style}>
      {game.isSKU && (
        <>
          <ImageFromApi
            imageId={keyProvider.imageId}
            fallbackIcon={icons.KEY_PROVIDER}
            colourHex={keyProvider.colourHex}
          ></ImageFromApi>
          {keyProvider.name}
        </>
      )}
    </div>
  );
}

KeyProviderRow.propTypes = {
  game: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  keyProviders: PropTypes.array.isRequired,
};

export default KeyProviderRow;
