import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../../layout/ActionBar';
import Add from './slideout/Add';
import { getAllWorkflows } from '../../../actions/workflowActions';
import { getKeyProviders } from '../../../actions/keyProviderActions';
import { getPlatforms } from '../../../actions/platformActions';
import { getAllGames } from '../../../actions/gameActions';
import KeyPoolNotificationsList from './components/KeyPoolNotificationsList';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function KeyPoolNotifications({
  location,
  workflows,
  getAllWorkflows,
  keyProviders,
  getKeyProviders,
  platforms,
  getPlatforms,
  games,
  getAllGames,
  currentUser,
}) {
  const [reload, setReload] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (!workflows) {
      getAllWorkflows();
    }
  }, [workflows, getAllWorkflows]);

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders]);

  useEffect(() => {
    if (!platforms) {
      getPlatforms();
    }
  }, [platforms, getPlatforms]);

  useEffect(() => {
    if (!games) {
      getAllGames();
    }
  }, [games, getAllGames]);

  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const addKeyPoolNotification = (e) => {
    e.cancelBubble = true;
    setAddSlideoutOpen(true);
    return false;
  };

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={addKeyPoolNotification}
          text={t('Add key pool notification')}
        />
      </ActionBar>

      <KeyPoolNotificationsList
        location={location}
        reload={reload}
        currentUser={currentUser}
      />

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <>
          {games && workflows && keyProviders && platforms && (
            <Add
              games={games}
              workflows={workflows}
              keyProviders={keyProviders}
              platforms={platforms}
              cancel={() => setAddSlideoutOpen(false)}
              done={(d) => {
                setReload(new Date());
                setAddSlideoutOpen(false);
              }}
            />
          )}
        </>
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    workflows: state.workflows,
    keyProviders: state.keyProviders,
    platforms: state.platforms,
    games: state.games,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  getAllWorkflows,
  getKeyProviders,
  getPlatforms,
  getAllGames,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyPoolNotifications);
