import React, { useState } from 'react';
import {
  loadCompanyTypes,
  exportCompanyTypesData,
} from '../../../../actions/companyTypeActions';
import { Link, NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { CommaNumber_NoDecimal } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import YesNoLabel from '../../../common/YesNoLabel';
import { useTranslation } from 'react-i18next';

function CompanyTypeList({ location, reload }) {
  const { t } = useTranslation();
  const allColumns = ['name', 'isDefault', 'companies', 'userProfiles'];
  const defaultVisibleColumns = allColumns;
  const [companyTypes, setCompanyTypes] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'companytypelist-settings',
      getTableSettingsDefaults({ defaultSort: 'name', defaultVisibleColumns }),
      null,
      loadCompanyTypes,
      setCompanyTypes,
      reload,
      t('Failed to load company types'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Name'),
      width: '40%',
      Render: (companyType) => (
        <Link to={'/settings/company-type/' + companyType.id}>
          {companyType.name}
        </Link>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'isDefault',
      Label: t('Is default'),
      width: '10%',
      className: 'no-wrap',
      Render: (companyType) => (
        <YesNoLabel value={companyType.isDefault} emptyOnNo={true} />
      ),
    },
    {
      Key: 'companies',
      Label: t('Number of companies'),
      width: '25%',
      className: 'text-right num no-wrap',
      Render: (companyType) => (
        <NavLink to={`/companies?f_companyTypeId=${companyType.id}`}>
          {CommaNumber_NoDecimal(companyType.companies)}
        </NavLink>
      ),
    },
    {
      Key: 'userProfiles',
      Label: t('Number of users'),
      width: '25%',
      className: 'text-right num no-wrap',
      Render: (companyType) => (
        <NavLink to={`/users/?f_companyTypeId=${companyType.id}`}>
          {CommaNumber_NoDecimal(companyType.userProfiles)}
        </NavLink>
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return companyTypes ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={companyTypes.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={companyTypes.totalRowCount}
        filteredRowCount={companyTypes.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportCompanyTypesData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default CompanyTypeList;
