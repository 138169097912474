import * as accountDetailApi from '../api/accountDetailApi';
export function loadAccountDetails({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return accountDetailApi.getAccountDetails(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadAccountDetailKeyRequests({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return accountDetailApi.loadAccountDetailKeyRequests(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAccountDetailById(id) {
  return accountDetailApi.getAccountDetailById(id);
}

export function loadAccountDetailKeyRequestsForAccount({
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return accountDetailApi.loadAccountDetailKeyRequestsForAccount(
    id,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getStats(id) {
  return accountDetailApi.getStats(id);
}
export function exportAccountData(filters, scopes) {
  return accountDetailApi.exportAccountData(filters, scopes);
}

export function exportAccountDetailKeyRequestsForAccount(id, filters, scopes) {
  return accountDetailApi.exportAccountDetailKeyRequestsForAccount(
    id,
    filters,
    scopes
  );
}

export function exportAccountDetailKeyRequests(filters, scopes) {
  return accountDetailApi.exportAccountDetailKeyRequests(filters, scopes);
}
