import React, { useEffect, useState } from 'react';
import CombinedStats from '../../../common/CombinedStats';
import colours from '../../../../constants/colours';
import { withRouter } from 'react-router-dom';
import { DateForFilter } from '../../../../util/formatter';
import { scopes as tableScopes } from '../../../../constants/scopes';
import { statsModes } from '../../../../hooks/useAggregateStatsData';

const UserStats = ({
  id,
  stats,
  getData,
  getAuthorisedData,
  getUploadedData,
  history,
}) => {
  const [_stats, _setStats] = useState([]);

  useEffect(() => {
    if (stats) {
      _setStats([
        {
          colour: colours.STATS_OPEN_REQUESTS,
          linkto: `/requests/all?f_requestStatus=pending%20approval&${tableScopes.USER}=${id}`,
          title: 'Open requests',
          metric: stats.openRequests,
          primaryStat: true,
        },
        {
          colour: colours.STATS_OVER_SLA,
          linkto: `/requests/all?${tableScopes.ISOVERSLA}=true&${tableScopes.USER}=${id}`,
          title: 'Over SLA',
          metric: stats.overSLA,
          primaryStat: true,
        },
        {
          colour: colours.STATS_COMPLETED_REQUESTS,
          linkto: `/requests/all?f_requestStatus=authorised&${tableScopes.USER}=${id}`,
          title: 'Complete requests',
          metric: stats.completeRequests,
        },
        {
          colour: colours.STATS_KEYS_DELIVERED,
          linkto: `/keys/game?${tableScopes.USER}=${id}`,
          title: 'Keys delivered',
          metric: stats.keysAllocated,
        },
        {
          colour: colours.STATS_KEYS_DELIVERED_VALUE,
          linkto: `/keys/game?${tableScopes.USER}=${id}`,
          title: 'Keys delivered value',
          metric: stats.keyStockValue,
        },
        {
          colour: colours.STATS_KEYS_SOLD,
          linkto: `/keys/game?${tableScopes.USER}=${id}`,
          title: 'Keys reported sold',
          metric: stats.keysSold,
        },
        {
          colour: colours.STATS_KEYS_DISABLED,
          linkto: `/keys/game?${tableScopes.USER}=${id}`,
          title: 'Keys disabled',
          metric: stats.keysBlacklisted,
        },
      ]);
    }
  }, [id, stats]);

  return (
    <CombinedStats
      stats={_stats}
      getData={getData}
      getAuthorisedData={getAuthorisedData}
      getUploadedData={getUploadedData}
      setModeStorageKey={`user-stats-mode`}
      heatmapOnClick={(value) => {
        if (value) {
          let date = DateForFilter(value.date);
          let destination = `/requests/all?${tableScopes.USER}=${id}&f_requestedDateFrom=${date}&f_requestedDateTo=${date}`;
          history.push(destination);
        }
      }}
      heatmapOnAuthorisedClick={(value) => {
        if (value) {
          let date = DateForFilter(value.date);
          let destination = `/requests/all?${tableScopes.AUTHORISEDBY}=${id}&f_authorisedDateFrom=${date}&f_authorisedDateTo=${date}`;
          history.push(destination);
        }
      }}
      heatmapOnUploadedClick={(value) => {
        if (value) {
          let date = DateForFilter(value.date);
          let destination = `/requests/all?${tableScopes.UPLOADEDBY}=${id}&f_keysUploadedDateFrom=${date}&f_keysUploadedDateTo=${date}`;
          history.push(destination);
        }
      }}
      id={id}
      mode={statsModes.USER}
    />
  );
};

export default withRouter(UserStats);
