import React from 'react';
import PropTypes from 'prop-types';
import ImageWithFallback from '../../common/ImageWithFallback';
import icons from '../../../constants/icons';
import FormStatic from '../../common/FormStatic';
import * as DOMPurify from 'dompurify';
import { NavLink } from 'react-router-dom';
import PlatformLink from '../../common/links/PlatformLink';
import KeyProviderLink from '../../common/links/KeyProviderLink';
import PackageTypeLink from '../../common/links/PackageTypeLink';
import {
  CommaNumber_WithDecimal,
  DateOnly_AsString_Raw,
} from '../../../util/formatter';
import WorkflowLink from '../../common/links/WorkflowLink';
import TerritoryLink from '../../common/links/TerritoryLink';
import CollapsePanel from '../../common/CollapsePanel';
import SkuRestrictions from './SkuRestrictions';
import skuHelp from '../../../constants/help/skuHelp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoSelectText from '../../common/AutoSelectText';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const SkuDetails = ({ sku }) => {
  const { t } = useTranslation();
  const language = i18next.language;
  const muhahaha = (e) => {
    const audio = new Audio('/content/sound/muhahaha.mp3');
    audio.play();
  };

  return (
    <CollapsePanel
      storageKey={'skuDetails'}
      header={
        <>
          <div className="game-img-wrapper me-4">
            <ImageWithFallback
              url={sku.packshotUrl}
              fallBackImage={
                <div className="game-img">
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={icons.SKU} className="SKU" />
                  </div>
                </div>
              }
              alt={sku.name}
            ></ImageWithFallback>
          </div>
          <div className="restrictions">
            <h2>{sku.name || t('SKU')}</h2>
            <div className="d-flex flex-column flex-sm-row">
              <SkuRestrictions
                isDisallow={sku.disallowListedTerritories}
                restrictions={sku.territories}
                render={({ id, name, colour }) => (
                  <TerritoryLink
                    id={id}
                    key={id}
                    name={name}
                    colour={colour}
                  ></TerritoryLink>
                )}
              ></SkuRestrictions>
              <SkuRestrictions
                isDisallow={sku.disallowListedKeyWorkflows}
                restrictions={sku.keyWorkflows}
                render={({ id, name, colour }) => (
                  <WorkflowLink
                    id={id}
                    key={id}
                    name={name}
                    colour={colour}
                  ></WorkflowLink>
                )}
              ></SkuRestrictions>
            </div>
          </div>
          <div className="flex-1 text-right">
            {sku.isArchived && (
              <h1 className="archived no-margin">
                <FontAwesomeIcon
                  icon={icons.ARCHIVED}
                  className="me-2"
                  style={{ cursor: 'pointer' }}
                  onClick={muhahaha}
                />
                {t('Archived')}
              </h1>
            )}
            {!sku.isArchived && sku.isLicenseExpired && (
              <h1 className="c-red no-margin">
                <FontAwesomeIcon
                  icon={icons.LICENSE_EXPIRED}
                  className="me-2"
                />
                {t('License expired')}
              </h1>
            )}
          </div>
        </>
      }
    >
      <>
        <div className="col-count-2">
          <FormStatic label={t('SKU')}>{sku.name}</FormStatic>
          <FormStatic label={t('Game')}>
            <NavLink to={`/game/${sku.gameId}`}>{sku.gameName}</NavLink>
          </FormStatic>
          <FormStatic label={t('Platform')}>
            <PlatformLink id={sku.platformId} name={sku.platform} />
          </FormStatic>
          <FormStatic label={t('Key provider')}>
            <KeyProviderLink id={sku.keyProviderId} name={sku.keyProvider} />
          </FormStatic>
          <FormStatic label={t('Package type')}>
            <PackageTypeLink id={sku.packageTypeId} name={sku.packageType} />
          </FormStatic>
          {sku.skuAppId || !sku.appId ? (
            <FormStatic label={t('App Id')}>{sku.skuAppId || '-'}</FormStatic>
          ) : (
            <FormStatic label={t('App Id')}>
              <AutoSelectText
                title={t('This app Id is taken from the game, click to select')}
              >
                {sku.appId || '-'}
              </AutoSelectText>
            </FormStatic>
          )}
          <FormStatic label={t('Package Id')}>
            <AutoSelectText>{sku.packageId || '-'}</AutoSelectText>
          </FormStatic>
          <FormStatic label={t('Product code')}>
            <AutoSelectText>{sku.productCode || '-'}</AutoSelectText>
          </FormStatic>
          <FormStatic label={t('Single key value')}>
            {CommaNumber_WithDecimal(sku.singleKeyValue || '-')}
          </FormStatic>
          <FormStatic
            label={t('Fulfillment date')}
            helpContent={skuHelp.FULFILMENT_DATE[language]}
          >
            {DateOnly_AsString_Raw(sku.fulfillmentDate || '', '-')}
          </FormStatic>
          <FormStatic
            label={t('Can request from')}
            helpContent={skuHelp.CAN_REQUEST_FROM[language]}
          >
            {DateOnly_AsString_Raw(sku.canRequestFromDate || '', '-')}
          </FormStatic>
          <FormStatic
            label={t('Can request until')}
            helpContent={skuHelp.CAN_REQUEST_UNTIL[language]}
          >
            {DateOnly_AsString_Raw(sku.canRequestUntilDate || '', '-')}
          </FormStatic>
          <FormStatic
            label={t('Territory restrictions')}
            className="flex-wrap restrictions"
          >
            <span className="me-2">
              {sku.disallowListedTerritories && sku.territories?.length > 0 ? (
                <>
                  <FontAwesomeIcon
                    icon={icons.DISALLOW}
                    fixedWidth
                    className="disallow me-2"
                    title={t('Disallowed')}
                  />{' '}
                  {t('Disallow :')}
                </>
              ) : !sku.disallowListedTerritories &&
                sku.territories?.length > 0 ? (
                <>
                  <FontAwesomeIcon
                    icon={icons.ALLOW}
                    fixedWidth
                    className="allow me-2"
                    title={t('allow only')}
                  />{' '}
                  {t('allow only :')}
                </>
              ) : (
                t('no restrictions')
              )}
            </span>
            {sku.territories &&
              sku.territories.length > 0 &&
              sku.territories
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x, i) => {
                  return (
                    <TerritoryLink
                      id={x.id}
                      key={x.id}
                      name={x.name}
                      colour={x.colourHex}
                    ></TerritoryLink>
                  );
                })}
          </FormStatic>
          <FormStatic
            label={t('Workflow restrictions')}
            className="flex-wrap restrictions"
          >
            <span className="me-2">
              {sku.disallowListedKeyWorkflows &&
              sku.keyWorkflows?.length > 0 ? (
                <>
                  <FontAwesomeIcon
                    icon={icons.DISALLOW}
                    className="disallow me-2"
                    fixedWidth
                    title={t('Disallowed')}
                  />{' '}
                  {t('Disallow :')}
                </>
              ) : !sku.disallowListedKeyWorkflows &&
                sku.keyWorkflows?.length > 0 ? (
                <>
                  <FontAwesomeIcon
                    icon={icons.ALLOW}
                    className={`allow me-2`}
                    fixedWidth
                    title={t('allow only')}
                  />{' '}
                  {t('allow only :')}
                </>
              ) : (
                t('no restrictions')
              )}
            </span>
            {sku.keyWorkflows &&
              sku.keyWorkflows.length > 0 &&
              sku.keyWorkflows
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x, i) => {
                  return (
                    <WorkflowLink
                      id={x.id}
                      key={x.id}
                      name={x.name}
                      colour={x.colourHex}
                    ></WorkflowLink>
                  );
                })}
          </FormStatic>
        </div>
        <div className="pb-2 col-12">
          <label className="col-form-label">{t('Notes')}</label>
          <div className="ps-0">
            <div
              className="form-control-static px-3 flex h-100 w-100 align-items-center"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(sku.notesHTML || '-'),
              }}
            ></div>
          </div>
        </div>
      </>
    </CollapsePanel>
  );
};

SkuDetails.propTypes = {
  sku: PropTypes.object.isRequired,
};

export default SkuDetails;
