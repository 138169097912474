import React from 'react';
import FormInput from '../../../common/FormInput';
import FormTextArea from '../../../common/FormTextArea';
import PropTypes from 'prop-types';
import FormColourPicker from '../../../common/FormColourPicker';
import FormRichText from '../../../common/FormRichText';
import FormImage from '../../../common/FormImage';
import keyProviderHelp from '../../../../constants/help/keyProviderHelp';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const KeyProviderForm = ({
  keyProvider,
  register,
  errors,
  control,
  setFile,
}) => {
  const language = i18next.language;
  const { t } = useTranslation();
  return keyProvider ? (
    <>
      <FormInput
        register={register}
        required={true}
        error={errors.name}
        errorMessage={t('Required')}
        name="name"
        label={t('Name')}
        defaultValue={keyProvider.name || ''}
        autoFocus={true}
      />
      <FormInput
        register={register}
        error={errors.packageIdLabel}
        name="packageIdLabel"
        label={t('Package Id label')}
        defaultValue={keyProvider.packageIdLabel || ''}
      />
      <FormTextArea
        register={register}
        error={errors.validCodeFormatMasks}
        name="validCodeFormatMasks"
        label={t('Valid code formats')}
        className="codemask"
        placeholder="xxxxx-xxxxx-xxxxx"
        defaultValue={keyProvider.validCodeFormatMasks || ''}
        helpContent={keyProviderHelp.CODE_FORMATS[language]}
      />
      <FormInput
        register={register}
        error={errors.packshotUrlFormat}
        name="packshotUrlFormat"
        label={t('Packshot Url Format')}
        defaultValue={keyProvider.packshotUrlFormat || ''}
        helpContent={keyProviderHelp.PACKSHOT_URL_FORMAT[language]}
      />
      <FormInput
        register={register}
        error={errors.bulkFilenameRegexPattern}
        name="bulkFilenameRegexPattern"
        label={t('Bulk Filename Regex Pattern')}
        defaultValue={keyProvider.bulkFilenameRegexPattern || ''}
        helpContent={keyProviderHelp.BULK_FILENAME_REGEX_PATTERN[language]}
      />

      <FormRichText
        control={control}
        name="instructions"
        label={t('Instructions')}
        extraRowClasses="align-items-start"
        placeholder={t(
          'Details on how to redeem key code on key provider platform'
        )}
        defaultValue={keyProvider.instructions || ''}
      />
      <FormInput
        register={register}
        error={errors.disabledKeyBatchLimit}
        name="disabledKeyBatchLimit"
        label={t('Disabled Key Batch Limit')}
        defaultValue={keyProvider.disabledKeyBatchLimit || 0}
        helpContent={keyProviderHelp.DISABLED_KEY_BATCH_LIMIT[language]}
      />
      <FormColourPicker
        control={control}
        name="colourHex"
        label={t('Colour')}
        extraRowClasses="align-items-start"
        defaultValue={keyProvider.colourHex || 'transparent'}
      />
      <FormImage
        control={control}
        name="image"
        label={t('Image')}
        setFile={setFile}
        value={keyProvider.imageId}
      ></FormImage>
    </>
  ) : (
    ''
  );
};

KeyProviderForm.propTypes = {
  keyProvider: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object,
  setFile: PropTypes.func,
};

export default KeyProviderForm;
