import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BasicStats from './components/BasicStats';
import { getGeneralStats } from '../../actions/userActions';
import Loading from '../common/Loading';
import RequestsMadeByMeList from '../requests/components/RequestsMadeByMeList';
import { downloadKeys } from '../../actions/requestActions';
import { userIs } from '../auth/authUtils';
import userTypes from '../../constants/userTypes';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import image_no_requests from '../../content/images/no_requests-01.svg';
import { useTranslation, Trans } from 'react-i18next';

function Home({ currentUser, location }) {
  const [stats, setStats] = useState();
  const [reload] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    // get the stats
    if (!stats) {
      getGeneralStats()
        .then((d) => {
          setStats(d);
        })
        .catch((e) => {});
    }
  }, [currentUser, stats]);

  const handleDownloadKeys = (e, request) => {
    e.cancelBubble = true;
    downloadKeys(request.id).catch((e) =>
      toast.error(e.message || t('Failed to download keys'))
    );
  };

  return stats ? (
    <>
      {userIs(currentUser, userTypes.REQUESTER) ? (
        <>
          <BasicStats id={currentUser.id} stats={stats} />
          <RequestsMadeByMeList
            location={location}
            reload={reload}
            defaultPageSize={10}
            settingsKey="homebasic-requestsmadebymelist-settings"
            handleDownloadKeys={handleDownloadKeys}
            title={t('Your requests')}
            noDataComponent={
              <div className="text-center no-records py-4">
                <img
                  src={image_no_requests}
                  className="space-bottom"
                  alt={t('No requests')}
                />
                <h2 className="bold space-bottom">{t('No requests')}</h2>
                <div>
                  <Trans
                    i18nKey="noActiveRequestsLink"
                    components={{ NavLink: <NavLink to="/games" /> }}
                  >
                    You currently have no active requests.{' '}
                    <NavLink>Browse some games to get started</NavLink>
                  </Trans>
                </div>
              </div>
            }
          />
        </>
      ) : (
        <div className="alert alert-warning twinkle flex-shrink-0 ">
          <div>
            {t(
              "You don't have access to anything in Alaska right now, please contact your administrator for access"
            )}
          </div>
        </div>
      )}
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
