import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { addWorkflowTag } from '../../../../actions/workflowActions';
import { toast } from 'react-toastify';
import FormInput from '../../../common/FormInput';
import { useTranslation } from 'react-i18next';

function AddTag({ workflowId, done, cancel }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    addWorkflowTag(workflowId, data.tag)
      .then((tag) => {
        toast.success(t('Tag successfully added to workflow'));
        done(tag);
      })
      .catch(() => {
        toast.error(t('Failed to add tag to workflow'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Add tag')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <FormInput
        autoFocus
        register={register}
        required={true}
        error={errors.tag}
        errorMessage={t('Tag is required')}
        name="tag"
        label={t('Tag')}
      />
    </SlideoutLayout>
  );
}

AddTag.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  workflowId: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddTag);
