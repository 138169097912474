import React from 'react';
import FormStatic from '../../common/FormStatic';
import KwPanel from '../../common/KwPanel';
import { useTranslation } from 'react-i18next';

import KeyProviderLink from '../../common/links/KeyProviderLink';
function AccountForm({ account, currentUser }) {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4 pb-3">
      <div className="row">
        <div className="col-12">
          <header className="mb-2">
            <h2 className="no-margin">{t('Account details')}</h2>
          </header>
        </div>
        <FormStatic label={t('Login')}>{account.login}</FormStatic>
        <FormStatic label={t('Name')}>{account.name}</FormStatic>
        <FormStatic label={t('Email Address')}>
          {account.emailAddress}
        </FormStatic>
        <FormStatic label={t('Key provider')}>
          <KeyProviderLink
            id={account.keyProviderId}
            name={account.keyProvider}
          />
        </FormStatic>
      </div>
    </KwPanel>
  );
}
export default AccountForm;
