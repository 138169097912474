import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.CREATE_KEYREQUEST:
      return null; //force a reload, maybe should map it to the correct shape?
    case actionTypes.UPDATE_KEYREQUEST:
      return state
        ? {
            ...state,
            data: state.data.map((keyRequestGroup) =>
              keyRequestGroup.id === action.record.id
                ? action.record
                : keyRequestGroup
            ),
            basketCount: action.record.submitting
              ? state.basketCount - 1
              : state.basketCount,
            submittingCount: action.record.submitting
              ? state.submittingCount + 1
              : state.submittingCount,
          }
        : state;
    case actionTypes.KEYREQUEST_SUBMITTED:
      return state
        ? {
            ...state,
            data: state.data.filter(
              (keyRequestGroup) =>
                keyRequestGroup.id !== action.keyRequestGroupId
            ),
            totalRowCount:
              state.totalRowCount -
              state.data.find((x) => x.id === action.keyRequestGroupId)?.games
                .length,
            submittingCount: state.submittingCount - 1,
          }
        : state;
    case actionTypes.DELETE_KEYREQUEST:
      return state
        ? {
            ...state,
            data: state.data
              .filter(
                (keyRequestGroup) =>
                  keyRequestGroup.id !== action.keyRequestGroupId ||
                  keyRequestGroup.games.length !== 1 ||
                  !keyRequestGroup.games.find(
                    (x) => x.requestId === action.keyRequestId
                  )
              )
              .map((group) => ({
                ...group,
                games: group.games.filter(
                  (game) => game.requestId !== action.keyRequestId
                ),
              })),
            totalRowCount: state.totalRowCount - 1,
          }
        : state;
    case actionTypes.DELETE_KEYREQUESTGROUP:
      return state
        ? {
            ...state,
            data: state.data.filter(
              (keyRequestGroup) =>
                keyRequestGroup.id !== action.keyRequestGroupId
            ),
            totalRowCount: state.totalRowCount - 1,
            basketCount: state.basketCount - 1,
          }
        : state;
    case actionTypes.LOAD_KEYREQUESTS:
      return {
        ...action.requests,
        basketCount: action.requests.data.filter((x) => !x.submitting).length,
        submittingCount: action.requests.data.filter((x) => x.submitting)
          .length,
      };
    case actionTypes.UPDATE_ACCOUNT_DETAILS:
      return {
        ...state,
        data: state.data.map((group) => ({
          ...group,
          games: group.games.map((game) =>
            game.requestId === action.requestId
              ? {
                  ...game,
                  accountDetails:
                    game.accountDetails + action.accountDetails.length,
                }
              : game
          ),
        })),
      };
    case actionTypes.DELETE_ACCOUNT_DETAILS:
      return {
        ...state,
        data: state.data.map((group) => ({
          ...group,
          games: group.games.map((game) =>
            game.requestId === action.requestId
              ? {
                  ...game,
                  accountDetails: game.accountDetails - 1,
                }
              : game
          ),
        })),
      };
    default:
      return state;
  }
}
