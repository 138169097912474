import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddCompany from '../slideout/AddCompany';
import RemoveCompany from '../slideout/RemoveCompany';
import KwPanel from '../../../common/KwPanel';
import SlidingPane from 'react-sliding-pane';
import icons from '../../../../constants/icons';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function CompanyBadges({
  label,
  description,
  onAdd,
  onRemove,
  companies,
  userProfileGroupId,
}) {
  const { t } = useTranslation();
  const [_companies, setCompanies] = useState();

  useEffect(() => {
    setCompanies(companies);
  }, [companies]);

  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const addCompany = (e) => {
    e.cancelBubble = true;
    setAddSlideoutOpen(true);
    return false;
  };

  const [removeSlideoutOpen, setRemoveSlideoutOpen] = useState(false);
  const [removeSlideoutItem, setRemoveSlideoutItem] = useState();
  const removeCompany = (e, id) => {
    e.cancelBubble = true;
    setRemoveSlideoutItem(id);
    setRemoveSlideoutOpen(true);
    return false;
  };

  const CompanyTag = ({ company }) => (
    <div className="tag" key={company.id}>
      <span>{company.companyName}</span>
      <span
        className="ms-3 a"
        onClick={(e) => removeCompany(e, company.id)}
        title={t('Remove')}
      >
        <FontAwesomeIcon icon={icons.DELETE_TAG} />
      </span>
    </div>
  );

  return (
    <>
      <KwPanel className="mb-4">
        <header className="mb-2">
          <h2 className="no-margin">{label || t('Companies')}</h2>
          <div className="float-right">
            <Button
              className="btn btn-default"
              onClick={addCompany}
              text={t('Edit')}
            />
          </div>
        </header>
        {description && <div>{description}</div>}
        <div className="mt-3 tags">
          {_companies && _companies.length > 0 ? (
            _companies.map((x, i) => <CompanyTag key={i} company={x} />)
          ) : (
            <></>
          )}
        </div>
      </KwPanel>

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <AddCompany
          userProfileGroupId={userProfileGroupId}
          companies={_companies}
          cancel={() => setAddSlideoutOpen(false)}
          done={(d) => {
            if (d.added) {
              onAdd(d.added);
            }
            if (d.removed) {
              onRemove(d.removed);
            }
            setAddSlideoutOpen(false);
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={removeSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRemoveSlideoutOpen(false)}
      >
        <>
          {removeSlideoutItem && (
            <RemoveCompany
              ids={[removeSlideoutItem]}
              done={(d) => {
                setRemoveSlideoutOpen(false);
                onRemove(d);
              }}
              cancel={() => setRemoveSlideoutOpen(false)}
            />
          )}
        </>
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBadges);
