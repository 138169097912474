import * as companyTypeApi from '../api/companyTypeApi';
import actionTypes from '../constants/actionTypes';

export function saveCompanyType(companyType) {
  return function (dispatch) {
    return companyTypeApi
      .saveCompanyType(companyType)
      .then((savedCompanyType) => {
        dispatch({
          type: companyType.id
            ? actionTypes.UPDATE_COMPANYTYPE
            : actionTypes.CREATE_COMPANYTYPE,
          companyType: { ...companyType, id: savedCompanyType.id },
        });
      });
  };
}

export function getCompanyTypeById(id) {
  return companyTypeApi.getCompanyTypeById(id);
}

export function loadCompanyTypes({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return companyTypeApi.getCompanyTypes(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAllCompanyTypes() {
  return function (dispatch) {
    return companyTypeApi.getAllCompanyTypes().then((companyTypes) => {
      dispatch({
        type: actionTypes.LOAD_COMPANYTYPES,
        companyTypes,
      });
    });
  };
}

export function getAudit(id) {
  return companyTypeApi.getAudit(id);
}

export function addAudit(id, comment) {
  return companyTypeApi.addAudit(id, comment);
}

export function deleteCompanyType(id) {
  return function (dispatch) {
    return companyTypeApi.deleteCompanyType(id).then(() => {
      dispatch({
        type: actionTypes.DELETE_COMPANYTYPE,
        id,
      });
    });
  };
}

export function exportCompanyTypesData(filters, scopes) {
  return companyTypeApi.exportCompanyTypesData(filters, scopes);
}
