import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getActivatedTransactionById,
  clearActivatedTransaction,
} from '../../actions/keyCodeActions';
import { addToGroup, removeFromGroup } from '../../actions/signalRActions';
import ReportedActivatedTransactionDetails from './components/ReportedActivatedTransactionDetails';
import ReportedActivatedTransactionSkusList from './components/ReportedActivatedTransactionSkusList';
import KeyProcessProgressBar from './components/KeyProcessProgressBar';
import ActionBar from '../layout/ActionBar';
import UploadActivated from './slideout/UploadActivated';
import { toast } from 'react-toastify';
import SlidingPane from 'react-sliding-pane';
import { useTranslation } from 'react-i18next';

function ReportedActivatedTransaction({
  id,
  history,
  location,
  addToGroup,
  removeFromGroup,
  activation,
  clearActivatedTransaction,
}) {
  const [transaction, setTransaction] = useState();
  const [reload, setReload] = useState();
  const { t } = useTranslation();
  const [uploadSlideoutOpen, setUploadSlideoutOpen] = useState(false);

  useEffect(() => {
    if (id) {
      // get transaction
      getActivatedTransactionById(id)
        .then((d) => {
          setTransaction(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/keys/reported-activated/');
          toast.error(t('Transaction not found'));
        });
    }
  }, [id, history, t]);

  useEffect(() => {
    if (transaction && transaction.status === 'Pending') {
      addToGroup(`activation:${transaction.id}`);
      return () => removeFromGroup(`activation:${transaction.id}`);
    }
  }, [transaction, addToGroup, removeFromGroup]);

  useEffect(() => {
    if (activation && activation.id === id) {
      setTransaction((prevState) => ({
        ...prevState,
        ...activation,
      }));
      return () => clearActivatedTransaction();
    }
  }, [activation, id, clearActivatedTransaction]);

  return (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/keys/reported-activated', label: t('Reported activated') },
          { label: t('Activated transaction') },
        ]}
      >
        <button
          className="btn btn-primary"
          onClick={() => {
            setUploadSlideoutOpen(true);
          }}
        >
          {t('Upload activated keys')}
        </button>
      </ActionBar>
      {transaction && (
        <>
          <ReportedActivatedTransactionDetails transaction={transaction} />
          {transaction.success && (
            <ReportedActivatedTransactionSkusList
              id={id}
              location={location}
              reload={reload}
            />
          )}
          <KeyProcessProgressBar transaction={transaction} />
        </>
      )}

      <SlidingPane
        isOpen={uploadSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setUploadSlideoutOpen(false)}
      >
        <UploadActivated
          done={(d) => {
            setUploadSlideoutOpen(false);
            setReload(new Date());
          }}
          cancel={() => setUploadSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    activation: state.activation,
  };
}

const mapDispatchToProps = {
  addToGroup,
  removeFromGroup,
  clearActivatedTransaction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportedActivatedTransaction);
