import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import {
  getAccountDetailById,
  getStats,
} from '../../actions/accountDetailActions';
import Loading from '../common/Loading';
import ResponsiveActionBarButtons from '../common/ResponsiveActionBarButtons';
import ActionBar from '../layout/ActionBar';
import AccountForm from './components/AccountForm';
import AccountStats from './components/AccountStats';
import AccountDetailKeyRequestsList from './components/AccountDetailKeyRequestsList';
import SlidingPane from 'react-sliding-pane';
import DisableKeys from './slideout/DisableKeys';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

function Account({ id, currentUser, location }) {
  const { t } = useTranslation();
  const [account, setAccount] = useState();
  const [reload] = useState();
  const [disableKeysSlideoutOpen, setDisableKeysSlideoutOpen] = useState(false);
  const [stats, setStats] = useState();
  const [reloadStats, setReloadStats] = useState();
  const [itemsChecked, setItemsChecked] = useState([]);
  const [targetRequestAccountDetailIds, setTargetRequestAccountDetailIds] =
    useState([]);

  const onDisableKeys = (accounts) => {
    setTargetRequestAccountDetailIds(accounts.map((x) => x.id));
    setDisableKeysSlideoutOpen(true);
  };

  useEffect(() => {
    if (!account) {
      getAccountDetailById(id)
        .then((record) => setAccount(record))
        .catch((e) => {
          toast.error(e.message ?? t('Failed to get account detail'));
        });
    }
  });
  useEffect(() => {
    if (id) {
      // get the stats
      getStats(id)
        .then((d) => {
          setStats(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get account detail stats'));
        });
    }
  }, [id, reloadStats, t]);

  return account ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/keys/accounts', label: t('Accounts') },
          { label: account.login },
        ]}
      >
        <ResponsiveActionBarButtons
          buttons={[
            <Button
              key="disableKeys"
              className="btn btn-danger d-none d-md-inline-flex ms-2"
              onClick={() => {
                setTargetRequestAccountDetailIds([]);
                setItemsChecked([]);
                setDisableKeysSlideoutOpen(true);
              }}
              text={t('Disable all keys')}
            />,
          ]}
        />
      </ActionBar>
      <AccountStats id={id} stats={stats}></AccountStats>
      <AccountForm account={account} currentUser={currentUser}></AccountForm>
      <AccountDetailKeyRequestsList
        id={id}
        currentUser={currentUser}
        location={location}
        reload={reload}
        itemsChecked={itemsChecked}
        setItemsChecked={setItemsChecked}
        onDisableKeys={onDisableKeys}
      ></AccountDetailKeyRequestsList>
      <SlidingPane
        isOpen={disableKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDisableKeysSlideoutOpen(false)}
      >
        <DisableKeys
          title={t('Disable keys for {account}', { account: account.login })}
          targetAccountDetailId={id}
          targetKeyRequestAccountDetail={targetRequestAccountDetailIds}
          done={(d) => {
            setDisableKeysSlideoutOpen(false);
            // reload stats
            setReloadStats(new Date());
          }}
          cancel={() => setDisableKeysSlideoutOpen(false)}
          defaultReason={t('Disable keys for {account}', {
            account: account.login,
          })}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
