import React from 'react';
import TerritoryLink from '../../../common/links/TerritoryLink';
import PropTypes from 'prop-types';
import SkuRestrictions from '../../../sku/components/SkuRestrictions';
import { useTranslation } from 'react-i18next';

function TerritoryRow({ game, style }) {
  const { t } = useTranslation();
  return (
    <div className="grid-empty" style={style}>
      {game.isSKU && (
        <div className="restrictions">
          <SkuRestrictions
            isDisallow={!game?.territoriesRestricted}
            restrictions={game?.territories}
            noDataText={t('No restrictions')}
            render={({ id, name, colour }) => (
              <TerritoryLink
                id={id}
                key={id}
                name={name}
                colour={colour}
              ></TerritoryLink>
            )}
          ></SkuRestrictions>
        </div>
      )}
    </div>
  );
}

TerritoryRow.propTypes = {
  game: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
};

export default TerritoryRow;
