import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import AccountsList from './components/AccountsList';
import ActionBar from '../layout/ActionBar';
import AccountDetailTableViewSwitcher from './components/AccountDetailTableViewSwitcher';
import AccountKeysList from './components/AccountKeysList';
import SlidingPane from 'react-sliding-pane';
import DisableKeys from './slideout/DisableKeys';
import { useTranslation } from 'react-i18next';

function Account({ location, currentUser }) {
  const [reload, setReload] = useState();
  const { t } = useTranslation();
  const [breadcrumb, setBreadcrumb] = useState([
    { label: t('Account details'), link: '/keys/account-details' },
  ]);
  const [grouped, setGrouped] = useLocalStorage('accountDetailsGrouped', false);
  const [itemsChecked, setItemsChecked] = useState([]);
  const [disableKeysSlideoutOpen, setDisableKeysSlideoutOpen] = useState(false);
  const [targetRequestAccountDetailIds, setTargetRequestAccountDetailIds] =
    useState([]);
  const onDisableKeys = (accounts) => {
    setTargetRequestAccountDetailIds(accounts.map((x) => x.id));
    setDisableKeysSlideoutOpen(true);
  };
  return (
    <>
      <ActionBar breadcrumb={breadcrumb}></ActionBar>
      {grouped ? (
        <AccountsList
          location={location}
          reload={reload}
          currentUser={currentUser}
          setBreadcrumb={setBreadcrumb}
          itemsChecked={itemsChecked}
          tableViewSwitcher={
            <AccountDetailTableViewSwitcher
              grouped={grouped}
              setGrouped={setGrouped}
              setItemsChecked={setItemsChecked}
            />
          }
        />
      ) : (
        <AccountKeysList
          location={location}
          reload={reload}
          currentUser={currentUser}
          setBreadcrumb={setBreadcrumb}
          itemsChecked={itemsChecked}
          setItemsChecked={setItemsChecked}
          onDisableKeys={onDisableKeys}
          tableViewSwitcher={
            <AccountDetailTableViewSwitcher
              grouped={grouped}
              setGrouped={setGrouped}
              setItemsChecked={setItemsChecked}
            />
          }
        />
      )}
      <SlidingPane
        isOpen={disableKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDisableKeysSlideoutOpen(false)}
      >
        <DisableKeys
          title={t('Disable keys for multiple accounts')}
          targetKeyRequestAccountDetail={targetRequestAccountDetailIds}
          done={(d) => {
            setDisableKeysSlideoutOpen(false);
            // reload stats
            setReload(new Date());
          }}
          cancel={() => setDisableKeysSlideoutOpen(false)}
          defaultReason={t('Disable keys for multiple accounts')}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
