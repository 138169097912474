import React from 'react';
import { withRouter } from 'react-router-dom';
import ActionBar from '../layout/ActionBar';
import KeysByMonthByWorkflowList from './components/month/KeysByMonthByWorkflowList';
import KeysByWeekByWorkflowList from './components/week/KeysByWeekByWorkflowList';
import ReportTableViewSwitcher from './components/ReportTableViewSwitcher';
import { useLocalStorage } from '../../hooks/useLocalStorage';

function KeysByWorkflow({ location, history }) {
  const [groupBy, setGroupBy] = useLocalStorage('report-groupBy', 'month');

  return (
    <>
      <ActionBar></ActionBar>
      {groupBy === 'month' ? (
        <KeysByMonthByWorkflowList
          location={location}
          history={history}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      ) : (
        <KeysByWeekByWorkflowList
          location={location}
          history={history}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      )}
    </>
  );
}

export default withRouter(KeysByWorkflow);
