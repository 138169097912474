import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ActionBar from '../../layout/ActionBar';
import PlatformForm from './components/PlatformForm';
import Stats from './components/Stats';
import {
  savePlatform,
  getPlatformById,
  getStats,
  getAudit,
  addAudit,
  getStatsByDay,
} from '../../../actions/platformActions';
import Delete from './slideout/Delete';
import UploaderBadges from '../uploader/components/UploaderBadges';
import KwPanel from '../../common/KwPanel';
import KeyProviderBadges from './components/KeyProviderBadges';
import AuditButton from '../../audit/components/AuditButton';
import SlidingPane from 'react-sliding-pane';
import Loading from '../../common/Loading';
import { useTranslation } from 'react-i18next';
import ResponsiveActionBarButtons from '../../common/ResponsiveActionBarButtons';
import Button from '../../common/Button';

function Platform({ id, savePlatform, history }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [file, setFile] = useState();

  const onSubmit = (data) => {
    savePlatform({ ...platform, ...data, file })
      .then(() => {
        setPlatform({ ...platform, ...data });
        toast.success(t('Platform saved'));
      })
      .catch(() => {
        toast.error(t('Failed to save platform'));
      });
  };

  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);
  const handleDelete = (e) => {
    e.cancelBubble = true;
    setDeleteSlideoutOpen(true);
    return false;
  };

  const [platform, setPlatform] = useState();
  const [stats, setStats] = useState();

  useEffect(() => {
    if (id) {
      getPlatformById(id)
        .then((d) => {
          setPlatform(d);
          // get the stats
          getStats(id)
            .then((d) => {
              setStats(d);
            })
            .catch((e) => {
              console.error(e);
              toast.error(t('Failed to get platform stats'));
            });
        })
        .catch((e) => {
          console.error(e);
          history.push('/settings/platforms/');
          toast.error(t('Platform not found'));
        });
    }
  }, [id, history, t]);

  return platform ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/settings/platforms', label: t('Platforms') },
          { label: platform.name },
        ]}
      >
        <div className="d-flex">
          <AuditButton id={id} getAudit={getAudit} addAudit={addAudit} />
          <ResponsiveActionBarButtons
            buttons={[
              id && (
                <Button
                  className="btn btn-danger ms-2 d-none d-md-inline-flex"
                  onClick={handleDelete}
                  text={t('Delete')}
                />
              ),
              <Button
                key="save"
                className="btn btn-primary ms-2"
                onClick={handleSubmit(onSubmit)}
                text={t('Save')}
              />,
            ]}
          />
        </div>
      </ActionBar>

      <Stats
        id={platform.id}
        stats={stats}
        getData={() => getStatsByDay(platform.id)}
      />

      <KwPanel className="mb-4 pb-3 pt-2">
        <PlatformForm
          platform={platform}
          register={register}
          errors={errors}
          control={control}
          setFile={setFile}
        ></PlatformForm>
      </KwPanel>
      <KeyProviderBadges
        label={t('Supported key providers')}
        platformId={platform.id}
      ></KeyProviderBadges>
      <UploaderBadges
        label={t('Who can upload keys')}
        platformId={platform.id}
      ></UploaderBadges>

      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          done={(d) => {
            setDeleteSlideoutOpen(false);
            history.push('/settings/platforms');
          }}
          cancel={() => setDeleteSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
  };
}

const mapDispatchToProps = {
  savePlatform,
};

export default connect(mapStateToProps, mapDispatchToProps)(Platform);
