import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../../../constants/icons';
function Workflow({ option }) {
  return (
    <>
      <div className="icon-wrapper workflow">
        <FontAwesomeIcon icon={icons.WORKFLOW} />
      </div>
      <div className="item-content">
        <span>{option.title}</span>
      </div>
    </>
  );
}

Workflow.propTypes = {
  option: PropTypes.object.isRequired,
};

export default Workflow;
