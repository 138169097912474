import React from 'react';
import permissionTypes from '../../constants/permissionTypes';
import { navPermissionCheck } from './authUtils';
import { connect } from 'react-redux';

function hasPermission(
  { currentUser }: { currentUser: any },
  { t }: { t: any }
) {
  return currentUser ? (
    <>
      <span>{currentUser.username}</span>
      <h2 className="mt-3">{t("User's permissions")}</h2>
      <ul>
        {currentUser.permissions.map((permission: any) => (
          <li key={permission}>
            {permission.permissionKey} {permission.companyId}
          </li>
        ))}
      </ul>
      <h2 className="mt-3">{t('Does user have permission')}</h2>
      <ul>
        <li>
          {permissionTypes.IMPERSONATE_USERS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.IMPERSONATE_USERS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.VIEW_REPORTS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.VIEW_REPORTS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_KEYS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_KEYS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_GAMES}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_GAMES)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_WORKFLOWS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_WORKFLOWS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_USERGROUPS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_USERGROUPS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_PERMISSIONS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_PERMISSIONS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_USERS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_USERS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_COMPANIES}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_COMPANIES)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_REPORTS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_REPORTS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.UPLOAD_ACTIVATIONS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.UPLOAD_ACTIVATIONS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.KEYS_SEARCH}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.KEYS_SEARCH)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_TERRITORIES}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_TERRITORIES)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_PLATFORMS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_PLATFORMS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_SETTINGS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_SETTINGS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_TRANSLATIONS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_TRANSLATIONS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.REQUEST_ON_BEHALF_OF}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.REQUEST_ON_BEHALF_OF)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.IMPERSONATE_USERS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.IMPERSONATE_USERS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.VIEW_PROMOTIONS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.VIEW_PROMOTIONS)
            ? 'true'
            : 'false'}
        </li>
        <li>
          {permissionTypes.MANAGE_PROMOTIONS}
          {' = '}
          {navPermissionCheck(currentUser, permissionTypes.MANAGE_PROMOTIONS)
            ? 'true'
            : 'false'}
        </li>
      </ul>
    </>
  ) : (
    <>{t('Loading...')}</>
  );
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(hasPermission);
