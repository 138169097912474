import React from 'react';
import FormGroup from './FormGroup';
import PropTypes from 'prop-types';
const FormTextArea = ({
  label,
  name,
  defaultValue,
  placeholder,
  register,
  error,
  errorMessage,
  required,
  extraRowClasses = 'align-items-start',
  className,
  children,
  autoFocus,
  disabled,
  maxlength,
  rows,
  helpContent,
}) => {
  const [count, setCount] = React.useState(
    defaultValue ? defaultValue.length : 0
  );

  return (
    <FormGroup
      label={label}
      error={error}
      errorMessage={errorMessage}
      required={required}
      extraRowClasses={extraRowClasses}
      autoFocus={autoFocus ?? false}
      helpContent={helpContent}
    >
      <div className="flex">
        <textarea
          {...register(name, { required })}
          aria-label={label}
          name={name}
          placeholder={placeholder}
          className={`form-control ${className || ''}`}
          rows={rows ?? 6}
          defaultValue={defaultValue}
          disabled={disabled ?? false}
          maxLength={maxlength}
          onChange={(e) => setCount(e.target.value.length)}
        ></textarea>
        {children}
      </div>
      <div
        style={{
          textAlign: 'right',
          color: maxlength - count < 0 ? 'red' : 'unset',
        }}
      >
        {maxlength && <span>{maxlength - count}</span>}
      </div>
    </FormGroup>
  );
};

FormTextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.func,
  error: PropTypes.object,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  extraRowClasses: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxlength: PropTypes.number,
  rows: PropTypes.number,
  helpContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]),
};

export default FormTextArea;
