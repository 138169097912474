import React, { useState } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Audits from '../slideout/Audits';
import Add from '../slideout/Add';
import { toast } from 'react-toastify';
import { isPowerUser } from '../../auth/authUtils';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import icons from '../../../constants/icons';
import { useTranslation } from 'react-i18next';

function AuditButton({ id, getAudit, addAudit, currentUser }) {
  const { t } = useTranslation();
  const [auditReload, setAuditReload] = useState();
  const [auditSlideoutOpen, setAuditSlideoutOpen] = useState(false);
  const [addAuditSlideoutOpen, setAddAuditSlideoutOpen] = useState(false);

  return (
    isPowerUser(currentUser) && (
      <>
        <Button
          onClick={() => setAuditSlideoutOpen(true)}
          className="btn btn-default btn--square ms-2"
          text={t('Open audit')}
          icon={icons.AUDIT}
          hideTitle={true}
        />
        <SlidingPane
          isOpen={auditSlideoutOpen}
          hideHeader={true}
          from="right"
          className="large-side-panel"
          onRequestClose={() => setAuditSlideoutOpen(false)}
        >
          <Audits
            id={id}
            getAudit={getAudit}
            addAudit={() => setAddAuditSlideoutOpen(true)}
            reload={auditReload}
            done={() => setAuditSlideoutOpen(false)}
            cancel={() => setAuditSlideoutOpen(false)}
          />
        </SlidingPane>

        <SlidingPane
          isOpen={addAuditSlideoutOpen}
          hideHeader={true}
          from="right"
          className="side-panel"
          onRequestClose={() => setAuditSlideoutOpen(false)}
        >
          <Add
            id={id}
            done={(d) => {
              addAudit(id, d.comment)
                .then(() => {
                  setAuditReload(new Date());
                  setAddAuditSlideoutOpen(false);
                })
                .catch((e) => {
                  toast.error(t('Failed to add comment'));
                });
            }}
            cancel={() => setAddAuditSlideoutOpen(false)}
          />
        </SlidingPane>
      </>
    )
  );
}

AuditButton.propTypes = {
  id: PropTypes.string.isRequired,
  getAudit: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuditButton);
