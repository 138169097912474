import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../../../constants/icons';
import { FormatCamelCaseAsString } from '../../../../util/formatter';
import Button from '../../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const SortableItem = ({
  column,
  items,
  allColumns,
  onColumnChange,
  onColumnDelete,
}) => {
  const { t } = useTranslation();
  return (
    <li className="sortable-item">
      <FontAwesomeIcon icon={icons.SORTABLE_HANDLE} className="ms-2 me-5" />
      <select
        className="form-control"
        style={{ display: 'inline-block' }}
        defaultValue={column.key}
        onChange={(e) => onColumnChange(e, column)}
      >
        {allColumns
          ?.sort((a, b) => a.localeCompare(b))
          .map((x) => (
            <option value={x} key={x}>
              {FormatCamelCaseAsString(x)}
            </option>
          ))}
      </select>

      <Button
        className="btn btn-default btn-outline ms-3 btn--square"
        isDisabled={items?.length < 2}
        title={items?.length < 2 ? t('Can not remove last item') : t('Remove')}
        onClick={() => onColumnDelete(column)}
        text={t('Remove')}
        icon={icons.DELETE_TAG}
        hideTitle={true}
      />
    </li>
  );
};

SortableItem.propTypes = {
  column: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  allColumns: PropTypes.array.isRequired,
  onColumnChange: PropTypes.func.isRequired,
  onColumnDelete: PropTypes.func.isRequired,
};

export default SortableItem;
