import React, { useEffect } from 'react';
import { signinSilentCallback } from '../../services/userService';
import { useTranslation } from 'react-i18next';

function SilentRenewOidc() {
  const { t } = useTranslation();
  useEffect(() => {
    signinSilentCallback();
  }, []);
  return <div>{t('Silently refreshed token...')}</div>;
}

export default SilentRenewOidc;
