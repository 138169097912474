import React, { useState } from 'react';
import {
  loadHealthChecks,
  downloadHealthCheck,
} from '../../../../actions/healthCheckActions';
import { Table, getTableSettingsDefaults } from '../../../common/Table';
import { hexToRgbA } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import YesNoLabel from '../../../common/YesNoLabel';
import { useTranslation } from 'react-i18next';

function HealthChecksList({ location, reload }) {
  const { t } = useTranslation();
  const allColumns = ['creationDate', 'success', 'message', 'fileId'];
  const defaultVisibleColumns = allColumns;
  const [healthChecks, setHealthChecks] = useState();
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'healthcheck-settings',
      getTableSettingsDefaults({
        defaultSort: 'creationDate',
        defaultVisibleColumns,
      }),
      null,
      loadHealthChecks,
      setHealthChecks,
      reload,
      t('Failed to load health checks'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'creationDate',
      Label: t('Date'),
      width: '40%',
    },
    {
      Key: 'success',
      Label: t('Success'),
      width: '30%',
      Render: (row) => <YesNoLabel value={row.success} />,
    },
    {
      Key: 'message',
      Label: t('Message'),
      width: '30%',
    },
    {
      Key: 'fileId',
      Label: t('File'),
      width: '30%',
      Render: (row) => {
        if (!row.success) {
          return (
            <button
              className="btn btn-primary"
              onClick={() => downloadHealthCheck(row.id)}
            >
              {t('Download')}
            </button>
          );
        }
        return '';
      },
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return healthChecks ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={healthChecks.data.map((x) => {
          return { Key: x.creationDate, ...x };
        })}
        totalRowCount={healthChecks.totalRowCount}
        filteredRowCount={healthChecks.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filterChange={filterChange}
        trStyle={(row) => {
          return {
            backgroundColor: `${hexToRgbA(row.colourHex, 0.0375)}`,
          };
        }}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default HealthChecksList;
