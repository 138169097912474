import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Game from './leftmenus/Game';
import Company from './leftmenus/Company';
import Keys from './leftmenus/Keys';
import Requests from './leftmenus/Requests';
import Settings from './leftmenus/Settings';
import Basket from './menu-components/Basket';
//import Notifications from './menu-components/Notifications';
import permissionTypes from '../../constants/permissionTypes';
import OffCanvasTopMenuItem from './menu-components/OffCanvasTopMenuItem';
import Search from './menu-components/Search';
import userTypes from '../../constants/userTypes';
import { userIs } from '../auth/authUtils';
import Reports from './leftmenus/Reports';
import User from './leftmenus/User';
import icons from '../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavColours from '../../constants/NavColours';
import LeftMenu_Workflow from './leftmenus/Workflow';
import { useTranslation } from 'react-i18next';

function OffCanvasMenu({
  history,
  currentUser,
  basket,
  closeNavMenu,
  navMenuOpen,
  companyTypes,
  completeRequestSlideoutOpen,
  setCompleteRequestSlideoutOpen,
  location,
}) {
  const { t } = useTranslation();
  const [openItem, setOpenItem] = useState('Home');
  useEffect(() => {
    if (!navMenuOpen) {
      setOpenItem('Home');
    }
  }, [navMenuOpen]);

  return (
    <>
      <div id="off-canvas">
        <div className="icon-nav-bar">
          <div className="search-wrapper">
            <Search history={history} closeNavMenu={closeNavMenu}></Search>
            <div className="search-icon">
              <FontAwesomeIcon icon={icons.SEARCH} />
              {/* No constants for this icon */}
            </div>
          </div>
          {/* <div className="notifications">
          <Notifications />
        </div> */}
          {userIs(currentUser, userTypes.REQUESTER) && (
            <div className="basket">
              <Basket
                basket={basket}
                onCompleteRequest={() => setCompleteRequestSlideoutOpen(false)}
                completeRequestSlideoutOpen={completeRequestSlideoutOpen}
                setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
              />
            </div>
          )}
        </div>

        <h2 className="">
          <span
            className="a back"
            onClick={() => {
              setOpenItem('Home');
            }}
          >
            <FontAwesomeIcon icon={icons.CHEVRON_LEFT} className="me-3" />
            <span>{t(openItem)}</span>
          </span>
        </h2>
        <ul className={`nav ${openItem !== 'Home' ? 'fade-out' : ''}`}>
          <OffCanvasTopMenuItem
            activeif="/request"
            url="/requests/all"
            title={t('Requests')}
            icon={icons.REQUESTS}
            currentUser={currentUser}
            isPowerUser={true}
            colour={NavColours.NAV_COLOUR_1}
            onClick={() => {
              if (openItem === 'Home') {
                setOpenItem('Requests');
              }
            }}
          >
            <Requests
              currentUser={currentUser}
              wrapperClassName={openItem === 'Requests' ? 'open' : ''}
            ></Requests>
          </OffCanvasTopMenuItem>
          <OffCanvasTopMenuItem
            activeif="/request"
            url="/requests/made-by-me"
            title={t('Requests')}
            icon={icons.REQUESTS}
            currentUser={currentUser}
            isPowerUser={false}
            colour={NavColours.NAV_COLOUR_1}
            onClick={() => {
              if (openItem === 'Home') {
                setOpenItem('Requests');
              }
            }}
          >
            <Requests
              currentUser={currentUser}
              wrapperClassName={openItem === 'Requests' ? 'open' : ''}
            ></Requests>
          </OffCanvasTopMenuItem>
          <OffCanvasTopMenuItem
            activeif="/game"
            url="/games"
            title={t('Games')}
            icon={icons.GAME}
            currentUser={currentUser}
            allowedUserTypes={[
              userTypes.REQUESTER,
              userTypes.UPLOADER,
              userTypes.APPROVER,
              userTypes.ADMIN,
            ]}
            colour={NavColours.NAV_COLOUR_1}
            onClick={() => {
              if (openItem === 'Home') {
                setOpenItem('Games');
              }
            }}
          >
            <Game
              currentUser={currentUser}
              wrapperClassName={openItem === 'Games' ? 'open' : ''}
            ></Game>
          </OffCanvasTopMenuItem>
          <OffCanvasTopMenuItem
            activeif="/compan"
            url="/companies"
            title={t('Companies')}
            icon={icons.COMPANY}
            allowedPermissions={[permissionTypes.MANAGE_COMPANIES]}
            currentUser={currentUser}
            colour={NavColours.NAV_COLOUR_1}
            onClick={() => {
              if (openItem === 'Home') {
                setOpenItem('Companies');
              }
            }}
          >
            <Company
              currentUser={currentUser}
              companyTypes={companyTypes}
              wrapperClassName={openItem === 'Companies' ? 'open' : ''}
            ></Company>
          </OffCanvasTopMenuItem>
          <OffCanvasTopMenuItem
            activeif="/key"
            url="/keys"
            title={t('Keys')}
            icon={icons.REQUEST_KEYS}
            allowedPermissions={[
              permissionTypes.MANAGE_KEYS,
              permissionTypes.KEYS_SEARCH,
            ]}
            currentUser={currentUser}
            colour={NavColours.NAV_COLOUR_1}
            onClick={() => {
              if (openItem === 'Home') {
                setOpenItem('Keys');
              }
            }}
          >
            <Keys
              currentUser={currentUser}
              wrapperClassName={openItem === 'Keys' ? 'open' : ''}
            ></Keys>
          </OffCanvasTopMenuItem>
          <OffCanvasTopMenuItem
            activeif="/report"
            url="/reports"
            title={t('Reports')}
            icon={icons.REPORT}
            allowedPermissions={[permissionTypes.VIEW_REPORTS]}
            currentUser={currentUser}
            colour={NavColours.NAV_COLOUR_1}
            onClick={() => {
              if (openItem === 'Home') {
                setOpenItem('Reports');
              }
            }}
          >
            <Reports
              currentUser={currentUser}
              wrapperClassName={openItem === 'Reports' ? 'open' : ''}
            ></Reports>
          </OffCanvasTopMenuItem>
          <OffCanvasTopMenuItem
            url="/users/"
            activeRegex="^\/user(s)?\/"
            icon={icons.USER}
            title={t('Users')}
            allowedPermissions={[permissionTypes.MANAGE_USERS]}
            currentUser={currentUser}
            colour={NavColours.NAV_COLOUR_1}
            onClick={() => {
              if (openItem === 'Home') {
                setOpenItem('Users');
              }
            }}
          >
            <User
              currentUser={currentUser}
              companyTypes={companyTypes}
              wrapperClassName={openItem === 'Users' ? 'open' : ''}
            ></User>
          </OffCanvasTopMenuItem>
          <OffCanvasTopMenuItem
            url="/settings/workflows/"
            activeRegex="^\/settings/workflow(s)?\/"
            icon={icons.WORKFLOW}
            title={t('Workflows')}
            allowedPermissions={[permissionTypes.MANAGE_WORKFLOWS]}
            currentUser={currentUser}
            colour={NavColours.NAV_COLOUR_1}
            onClick={() => {
              if (openItem === 'Home') {
                setOpenItem('Workflows');
              }
            }}
          >
            <LeftMenu_Workflow
              currentUser={currentUser}
              wrapperClassName={openItem === 'Workflows' ? 'open' : ''}
            ></LeftMenu_Workflow>
          </OffCanvasTopMenuItem>
          <OffCanvasTopMenuItem
            activeif="/settings"
            url="/settings"
            title={t('Settings')}
            icon={icons.SETTINGS}
            allowedUserTypes={[userTypes.ADMIN]}
            currentUser={currentUser}
            colour={NavColours.NAV_COLOUR_1}
            onClick={() => {
              if (openItem === 'Home') {
                setOpenItem('Settings');
              }
            }}
          >
            <Settings
              currentUser={currentUser}
              wrapperClassName={openItem === 'Settings' ? 'open' : ''}
              location={location}
            ></Settings>
          </OffCanvasTopMenuItem>
        </ul>
      </div>
    </>
  );
}

OffCanvasMenu.propTypes = {
  currentUser: PropTypes.object,
  basket: PropTypes.object,
};

export default withRouter(OffCanvasMenu);
