import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../../../constants/icons';
import { skuNameSubsition } from '../../../../util/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SKURow({ game, style, onRemove }) {
  return (
    <div className="grid-game-name bulk-grid-sku-name" style={style}>
      {game.showRemove && (
        <button
          className="btn btn-default btn--square btn-outline"
          onClick={() => onRemove(game)}
        >
          <FontAwesomeIcon icon={icons.DELETE_TAG} />
        </button>
      )}
      {skuNameSubsition(game.gameName, game.name)}
    </div>
  );
}

SKURow.propTypes = {
  game: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default SKURow;
