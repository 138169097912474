import React from 'react';
import { Link } from 'react-router-dom';

function RequestGroupLink({
  requestId,
  requestReference,
  requestGroupId,
  requestGroupReference,
}) {
  return (
    <div>
      <Link to={'/request/' + requestGroupId}>{requestGroupReference}</Link>
      <br />
      <small>{requestReference}</small>
    </div>
  );
}

export default RequestGroupLink;
