import React, { useEffect, useState } from 'react';
import icons from '../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export function RequestStatusLabel({
  requestStatus,
  isWaitingForKeys,
  isUpdateInProgress,
  isDownloaded,
  noBadge,
}) {
  const { t } = useTranslation();
  let getLabelClasses = (
    requestStatus,
    isWaitingForKeys,
    isUpdateInProgress
  ) => {
    if (requestStatus.isDeclined || requestStatus.isWithdrawn)
      return 'bg-danger';

    if (isUpdateInProgress) return 'bg-info';

    if (requestStatus.isAuthorised && isWaitingForKeys) return 'bg-info';

    if (requestStatus.isAuthorised) return 'bg-success';

    if (requestStatus.isPending) return 'bg-info';

    if (requestStatus.name === 'Keys Ordered') return 'bg-info';

    return 'bg-info';
  };

  const [name, setName] = useState();
  useEffect(() => {
    if (isUpdateInProgress) {
      setName('Processing...');
    } else {
      requestStatus &&
        setName(
          requestStatus.isAuthorised
            ? isWaitingForKeys
              ? 'Pending keys'
              : requestStatus.name
            : requestStatus.name
        );
    }
  }, [requestStatus, isUpdateInProgress, isWaitingForKeys]);

  let nameTranslation =
    t(t(name)) + (isDownloaded ? ' ' + t('(downloaded)') : '');

  return requestStatus ? (
    noBadge ? (
      <span title={nameTranslation}>
        {nameTranslation}
        {isDownloaded ? (
          <FontAwesomeIcon icon={icons.DOWNLOADED} className="ms-2" />
        ) : (
          ''
        )}
      </span>
    ) : (
      <span
        className={`badge ${getLabelClasses(
          requestStatus,
          isWaitingForKeys,
          isUpdateInProgress
        )}`}
        title={nameTranslation}
      >
        {nameTranslation}
        {isDownloaded ? (
          <FontAwesomeIcon icon={icons.DOWNLOADED} className="ms-2" />
        ) : (
          ''
        )}
      </span>
    )
  ) : (
    <span className={`badge bg-multiple`}>{t('Multiple')}</span>
  );
}
