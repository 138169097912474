import React from 'react';
import FormGroup from './FormGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const FormInputNumber = ({
  name,
  label,
  placeholder,
  defaultValue,
  register,
  required,
  joinedButton,
  joinedButtonIcon,
  joinedButtonClick,
  joinedButtonDisabled,
  error,
  errorMessage,
  onChange,
  className,
  autoFocus,
  disabled,
}) => (
  <FormGroup
    label={label}
    error={error}
    errorMessage={errorMessage}
    required={required}
  >
    {joinedButton ? (
      <div className="input-group">
        <input
          {...register(name)}
          type="number"
          aria-label={label}
          name={name}
          placeholder={placeholder}
          className={`form-control float-left ${className || ''}`}
          style={{ width: 'calc(100% - 45px)' }}
          defaultValue={defaultValue}
          data-lpignore="true"
          autoComplete="off"
          onChange={onChange}
          autoFocus={autoFocus ?? false}
          disabled={disabled ?? false}
        />
        <button
          type="submit"
          onClick={joinedButtonClick}
          className="btn btn-primary float-left r-corners-right"
          disabled={joinedButtonDisabled || disabled}
        >
          <FontAwesomeIcon
            icon={joinedButtonIcon}
            aria-hidden="true"
          ></FontAwesomeIcon>
        </button>
      </div>
    ) : (
      <input
        {...register(name, { required })}
        type="number"
        aria-label={label}
        name={name}
        placeholder={placeholder}
        className={`form-control ${className || ''}`}
        defaultValue={defaultValue}
        data-lpignore="true"
        autoComplete="off"
        onChange={onChange}
        autoFocus={autoFocus ?? false}
        disabled={disabled ?? false}
      />
    )}
  </FormGroup>
);
export default FormInputNumber;
