import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddTerritoryMembership from '../slideout/AddTerritory';
import RemoveTerritoryMembership from '../slideout/RemoveTerritory';
import KwPanel from '../../../common/KwPanel';
import { NavLink } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import icons from '../../../../constants/icons';
import Button from '../../../common/Button';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function TerritoryBadges({
  label,
  nodata,
  description,
  onAdd,
  onRemove,
  userProfileTerritories,
  allTerritories,
}) {
  const { t } = useTranslation();
  const [localUserProfileTerritories, setLocalUserProfileTerritories] =
    useState();

  useEffect(() => {
    // update userProfileTerritories with the data from allTerritories matching on the id
    // this is because userProfileTerritories only has the id and name, not the description
    // and we need the description to display the tag
    const result = allTerritories?.filter((x) =>
      // return true if x.id is in userProfileTerritories
      userProfileTerritories?.some((y) => y.id === x.id)
    );
    setLocalUserProfileTerritories(result);
  }, [userProfileTerritories, allTerritories]);

  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const handleAdd = (e) => {
    e.cancelBubble = true;
    setAddSlideoutOpen(true);
  };

  const [removeSlideoutOpen, setRemoveSlideoutOpen] = useState(false);
  const [removeSlideoutItem, setRemoveSlideoutItem] = useState();
  const handleRemove = (e, userProfileTerritoryId) => {
    e.cancelBubble = true;
    setRemoveSlideoutOpen(true);
    setRemoveSlideoutItem(userProfileTerritoryId);
    return false;
  };

  const Tag = ({ userProfileTerritory }) =>
    userProfileTerritory ? (
      <div className="tag" key={userProfileTerritory.id}>
        <NavLink
          to={`/settings/territory/${userProfileTerritory.id}`}
          title={`${userProfileTerritory.description ?? ''}\n${t(
            '(click to view)'
          )}`}
        >
          {userProfileTerritory.name}
        </NavLink>
        <span
          className="ms-3 a"
          onClick={(e) => handleRemove(e, userProfileTerritory.id)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleRemove(e, userProfileTerritory.id);
            }
          }}
          title={t('Remove')}
        >
          <FontAwesomeIcon icon={icons.DELETE_TAG} />
        </span>
      </div>
    ) : (
      <></>
    );

  Tag.propTypes = {
    userProfileTerritory: PropTypes.object.isRequired,
  };

  return (
    <>
      <KwPanel className="mb-4">
        <header className="mb-2">
          <h2 className="no-margin">{label}</h2>
          <div className="float-right">
            <Button
              className="btn btn-default"
              onClick={handleAdd}
              text={t('Add')}
            />
          </div>
        </header>
        {description && <div>{description}</div>}
        <div className="mt-3 tags">
          {localUserProfileTerritories && localUserProfileTerritories.length > 0
            ? localUserProfileTerritories.map((x, i) => (
                <Tag key={x.id} userProfileTerritory={x} />
              ))
            : nodata || ''}
        </div>
      </KwPanel>

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <AddTerritoryMembership
          cancel={() => setAddSlideoutOpen(false)}
          done={(d) => {
            setAddSlideoutOpen(false);
            onAdd(d);
          }}
          allTerritories={allTerritories}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={removeSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setRemoveSlideoutOpen(false)}
      >
        <RemoveTerritoryMembership
          userProfileTerritoryId={removeSlideoutItem}
          cancel={() => setRemoveSlideoutOpen(false)}
          done={(d) => {
            setRemoveSlideoutOpen(false);
            onRemove(d);
          }}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

TerritoryBadges.propTypes = {
  label: PropTypes.string.isRequired,
  nodata: PropTypes.any,
  description: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  userProfileTerritories: PropTypes.array,
  allTerritories: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryBadges);
