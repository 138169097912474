import React, { useState, useEffect } from 'react';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { connect } from 'react-redux';
import { saveCompany, getCompanyById } from '../../../actions/companyActions';
import { toast } from 'react-toastify';
import CompanyForm from '../components/CompanyForm';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Loading from '../../common/Loading';
import { useTranslation } from 'react-i18next';
function AddCompany({
  done,
  id,
  cancel,
  saveCompany,
  getCompanyById,
  companyTypes,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
  } = useForm();

  const [file, setFile] = useState();
  const onSubmit = (data) => {
    saveCompany({ ...company, ...data, file })
      .then((savedCompany) => {
        toast.success(t('Company saved'));
        done(savedCompany);
      })
      .catch(() => {
        toast.error(t('Failed to save company'));
      });
  };

  const [company, setCompany] = useState();

  // default company type
  const [defaultCompanyType, setDefaultCompanyType] = useState();
  useEffect(() => {
    if (companyTypes) {
      setDefaultCompanyType(companyTypes.find((x) => x.isDefault));
    }
  }, [companyTypes, setDefaultCompanyType]);

  useEffect(() => {
    if (id) {
      getCompanyById(id).then((d) => {
        setCompany(d);
      });
    } else {
      setCompany({ id: '' });
    }
  }, [id, getCompanyById]);
  const setDomains = (domains) => {
    setCompany({ ...company, domains: domains });
  };

  return company ? (
    <SlideoutLayout
      title={`${id ? t('Edit company') : t('Add company')}`}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <CompanyForm
        company={company}
        companyTypes={companyTypes}
        defaultCompanyType={defaultCompanyType}
        register={register}
        errors={errors}
        control={control}
        getValues={getValues}
        setValue={setValue}
        columnCssClass="col-12"
        setFile={setFile}
        setDomains={setDomains}
      ></CompanyForm>
    </SlideoutLayout>
  ) : (
    <Loading></Loading>
  );
}
AddCompany.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  saveCompany: PropTypes.func.isRequired,
  getCompanyById: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    company: state.company,
    companyTypes: state.companyTypes,
  };
}

const mapDispatchToProps = {
  saveCompany,
  getCompanyById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCompany);
