import React from 'react';
import { DateOnly_AsString_NoHtml } from '../../../../../util/formatter';
import { useTranslation } from 'react-i18next';

function PromotionHeaderCell({ promotion }) {
  const { t } = useTranslation();
  const data = '3';
  return (
    <div className="promo-header">
      <div className="no-border">
        <div className="promo-name">{promotion.name}</div>
        <div className="promo-date-wrapper">
          {DateOnly_AsString_NoHtml(promotion.start)} - {t(data)} {t('Days')}
        </div>
      </div>
      <div className="no-border grid-packages">
        <span>{t('Packages on promotion')}</span>
        <br />
        <span className="packages">10/100</span>
      </div>
    </div>
  );
}

export default PromotionHeaderCell;
