import {
  apiUrl,
  getList,
  getSingleRecord,
  saveRecord,
  deleteRecord,
  exportData,
} from './apiUtils';
const baseUrl = apiUrl + '/keys/pools/notifications/';

export function getKeyPoolNotifications(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getKeyPoolNotificationById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveKeyPoolNotification(userProfileGroup) {
  return saveRecord(baseUrl, userProfileGroup);
}

export function deleteKeyPoolNotification(id) {
  return deleteRecord(baseUrl, id);
}

export function exportKeyPoolNotificationData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/keys/pools/notifications/search`,
    'sku',
    filters,
    scopes
  );
}
