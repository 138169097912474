import React from 'react';
import LeftNavWrapper from '../menu-components/LeftNavWrapper';
import LeftMenuItem from '../menu-components/LeftMenuItem';
import permissionTypes from '../../../constants/permissionTypes';
import icons from '../../../constants/icons';
import NavColours from '../../../constants/NavColours';
import { useTranslation } from 'react-i18next';

export default function LeftMenu_Workflow({ currentUser, wrapperClassName }) {
  const { t } = useTranslation();
  return (
    <LeftNavWrapper className={wrapperClassName}>
      <LeftMenuItem
        url="/settings/workflows/"
        activeif="/settings/workflow"
        icon={icons.WORKFLOW}
        colour={NavColours.NAV_COLOUR_2}
        title={t('Workflows')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_WORKFLOWS]}
      />
      <LeftMenuItem
        url="/settings/approver-notification-users/"
        activeif="/settings/approver-notification-users"
        icon={icons.WORKFLOW}
        colour={NavColours.NAV_COLOUR_2}
        title={t('Workflow approver notifications')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_WORKFLOWS]}
      />
    </LeftNavWrapper>
  );
}
