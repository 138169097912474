import React from 'react';
import PropTypes from 'prop-types';
import FormSelect from '../../common/FormSelect';
import FormStatic from '../../common/FormStatic';
import FormTextArea from '../../common/FormTextArea';
import { useTranslation } from 'react-i18next';

const ChangeStatusForm = ({ request, statuses, register, errors }) => {
  const { t } = useTranslation();
  return (
    <>
      <FormStatic label="Game">{request.gameTitle}</FormStatic>
      <FormStatic label="SKU">{request.skuName}</FormStatic>
      <FormSelect
        register={register}
        required={true}
        error={errors.statusId}
        errorMessage={t('Status is required')}
        name="statusId"
        label={t('New status')}
        placeholder=""
        autoFocus={true}
      >
        <option value="">{t('--- choose ---')}</option>
        {statuses?.map((x) => (
          <option key={x.value} value={x.value}>
            {x.text}
          </option>
        ))}
      </FormSelect>
      <FormTextArea
        register={register}
        required={true}
        error={errors.reason}
        errorMessage={t('Required')}
        name="reason"
        label={t('Please provide a reason...')}
        placeholder=""
      />
    </>
  );
};

ChangeStatusForm.propTypes = {
  request: PropTypes.object.isRequired,
  statuses: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ChangeStatusForm;
