import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as deTranslation from '../content/locales/de.json';
import * as enTranslation from '../content/locales/en.json';
import * as esTranslation from '../content/locales/es.json';
import * as frTranslation from '../content/locales/fr.json';
import * as itTranslation from '../content/locales/it.json';
import * as jaTranslation from '../content/locales/ja.json';

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  lng: '',
  resources: {
    de: { translation: deTranslation },
    en: { translation: enTranslation },
    es: { translation: esTranslation },
    fr: { translation: frTranslation },
    it: { translation: itTranslation },
    ja: { translation: jaTranslation },
  },
});
