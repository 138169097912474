import React, { useEffect, useState } from 'react';
import {
  CommaNumber_NoDecimal,
  Date_AsString_NoHtml,
} from '../../../util/formatter';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { getCodeStatusHistory } from '../../../actions/keyCodeActions';
import Loading from '../../common/Loading';
import { NavLink } from 'react-router-dom';
import UserLink from '../../common/links/UserLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavColours from '../../../constants/NavColours';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';

export default function CodeStatusHistory({ done, cancel, id }) {
  const [history, setHistory] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    if (id) {
      getCodeStatusHistory(id)
        .then((d) => {
          setHistory(d);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [id]);

  const getIcon = (item) => {
    if (item.downloadedById) {
      return (
        <div className={NavColours.NAV_COLOUR_7}>
          <FontAwesomeIcon icon={icons.DOWNLOAD} />
        </div>
      );
    } else {
      switch (item.codeStatusName) {
        case 'Available':
          return (
            <div className={NavColours.NAV_COLOUR_1}>
              <FontAwesomeIcon icon={icons.KEY_AVAILABLE} />
            </div>
          );
        case 'Allocated':
          return (
            <div className={NavColours.NAV_COLOUR_2}>
              <FontAwesomeIcon icon={icons.KEY_ALLOCATED} />
            </div>
          );
        case 'Activated':
          return (
            <div className={NavColours.NAV_COLOUR_3}>
              <FontAwesomeIcon icon={icons.KEY_ACTIVATED} />
            </div>
          );
        case 'Disabled':
          return (
            <div className={NavColours.NAV_COLOUR_4}>
              <FontAwesomeIcon icon={icons.DISABLED} />
            </div>
          );
        case 'Sold':
          return (
            <div className={NavColours.NAV_COLOUR_5}>
              <FontAwesomeIcon icon={icons.KEY_SOLD} />
            </div>
          );
        case 'Moved':
        default:
          return (
            <div className={NavColours.NAV_COLOUR_6}>
              <FontAwesomeIcon icon={icons.KEY_MOVED} />
            </div>
          );
      }
    }
  };

  return history ? (
    <SlideoutLayout title={t('Code history')} cancel={cancel} done={done}>
      <div className="timeline">
        <div className="d-flex justify-content-between mb-3">
          <p>{t('Newest at top')}</p>
          <p>
            {CommaNumber_NoDecimal(history.length) === 1
              ? t('1 item')
              : t('{number} items', {
                  number: CommaNumber_NoDecimal(history.length),
                })}
          </p>
        </div>
        {history.map((item) => {
          return (
            <div className={`timeline-item`} key={item.statusDate}>
              <div className="timeline-item-icon">{getIcon(item)}</div>
              <div className="timeline-item-details">
                <div className="timeline-item-details-inner">
                  <span>{Date_AsString_NoHtml(item.statusDate)}</span>
                  <h3>
                    {item.downloadedById
                      ? t('Downloaded')
                      : item.codeStatusName}
                  </h3>
                  {(item.downloadedById || item.downloadedByIpAddress) && (
                    <div className="d-flex flex-wrap">
                      {item.downloadedById && (
                        <div className="flex-grow-1 flex-basis-50 no-wrap">
                          <div className="d-inline-block me-2">
                            <label>{t('Downloaded by')}</label>
                            <>
                              <UserLink
                                id={item.downloadedById}
                                name={item.downloadedByFullName}
                              />
                              , {item.downloadedByCompanyName}
                            </>
                          </div>
                        </div>
                      )}
                      {item.downloadedByIpAddress && (
                        <div className="flex-grow-1 flex-basis-50 no-wrap">
                          <label>{t('Downloaded from')}</label>
                          {item.downloadedByIpAddress}
                        </div>
                      )}
                    </div>
                  )}
                  {(item.keyRequestGroupId ||
                    item.importTransactionId ||
                    item.disabledTransactionId ||
                    item.soldTransactionId ||
                    item.moveTransactionId) && (
                    <div className="d-flex flex-wrap">
                      {item.keyRequestGroupId && (
                        <div className="flex-grow-1 flex-basis-50 no-wrap">
                          <label>{t('Request')}</label>
                          <NavLink
                            to={`/request/${item.keyRequestGroupId}`}
                            onClick={cancel}
                          >
                            {item.keyRequestGroupReference}
                          </NavLink>
                        </div>
                      )}
                      {item.importTransactionId && (
                        <div className="flex-grow-1 flex-basis-50 no-wrap">
                          <label>{t('Uploaded')}</label>
                          <NavLink
                            to={`/keys/ingested-batches/${item.importTransactionId}`}
                            onClick={cancel}
                          >
                            <FontAwesomeIcon
                              icon={icons.VIEW}
                              className="me-2"
                            />
                            {t('View transaction')}
                          </NavLink>
                        </div>
                      )}

                      {item.activationTransactionId && (
                        <div className="flex-grow-1 flex-basis-50 no-wrap">
                          <label>{t('Activated')}</label>
                          <NavLink
                            to={`/keys/reported-activated/${item.activationTransactionId}`}
                            onClick={cancel}
                          >
                            <FontAwesomeIcon
                              icon={icons.VIEW}
                              className="me-2"
                            />
                            {t('View activation transaction')}
                          </NavLink>
                        </div>
                      )}
                      {item.disabledTransactionId && (
                        <div className="flex-grow-1 flex-basis-50 no-wrap">
                          <label>{t('Disabled')}</label>
                          <NavLink
                            to={`/keys/reported-disabled/${item.disabledTransactionId}`}
                            onClick={cancel}
                          >
                            <FontAwesomeIcon
                              icon={icons.VIEW}
                              className="me-2"
                            />
                            {t('View disabled transaction')}
                          </NavLink>
                        </div>
                      )}
                      {item.soldTransactionId && (
                        <div className="flex-grow-1 flex-basis-50 no-wrap">
                          <label>{t('Reported sold')}</label>
                          <NavLink
                            to={`/keys/reported-sold/${item.soldTransactionId}`}
                            onClick={cancel}
                          >
                            <FontAwesomeIcon
                              icon={icons.VIEW}
                              className="me-2"
                            />
                            {t('View sold transaction')}
                          </NavLink>
                        </div>
                      )}
                      {item.moveTransactionId && (
                        <div className="flex-grow-1 flex-basis-50 no-wrap">
                          <label>{t('Moved pools')}</label>
                          <NavLink
                            to={`/keys/moved/${item.moveTransactionId}`}
                            onClick={cancel}
                          >
                            <FontAwesomeIcon
                              icon={icons.VIEW}
                              className="me-2"
                            />
                            {t('View move transaction')}
                          </NavLink>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </SlideoutLayout>
  ) : (
    <Loading></Loading>
  );
}
