import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { hasPermission } from '../../auth/authUtils';
import UserPopup from '../../popups/user';
import permissionTypes from '../../../constants/permissionTypes';

function UserLink({ id, name, currentUser, className }) {
  return (
    <>
      {id && name ? (
        hasPermission(currentUser, permissionTypes.MANAGE_USERS) ? (
          <UserPopup id={id}>
            <span className={className}>
              <NavLink to={`/user/${id}`}>{name}</NavLink>
            </span>
          </UserPopup>
        ) : (
          name
        )
      ) : name ? (
        name
      ) : (
        '-'
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserLink);
