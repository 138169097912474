import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { moveKeysBetweenRequests } from '../../actions/keyCodeActions';
import { searchRequests } from '../../api/searchApi';
import { useForm } from 'react-hook-form';
import KwPanel from '../common/KwPanel';
import ActionBar from '../layout/ActionBar';
import Request from '../layout/menu-components/SearchResults/Request';
import FormGroup from '../common/FormGroup';
import FormTextArea from '../common/FormTextArea';
import ResponsiveActionBarButtons from '../common/ResponsiveActionBarButtons';
import Button from '../common/Button';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function MoveKeysBetweenRequests() {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const sourceTypeaheadRef = useRef(null);
  const destinationTypeaheadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const onSubmit = (data) => {
    if (!keyform.sourceKeyRequestId) {
      toast.error(t('Source request is required'));
      return;
    }
    if (!keyform.destinationKeyRequestId) {
      toast.error(t('Destination request is required'));
      return;
    }
    // clean the new flag off the object, this only affects the mockdb
    moveKeysBetweenRequests(keyform)
      .then(() => {
        toast.success(t('Keys moved'));
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to move keys'));
      });
  };

  const [keyform, setKeyForm] = useState({
    sourceKeyRequestId: null,
    destinationKeyRequestId: null,
    reason: '',
  });

  const handleSearch = (query) => {
    setIsLoading(true);

    searchRequests(query)
      .then((items) => {
        setOptions(items);
        setIsLoading(false);
      })
      .catch(() => {
        setOptions([]);
        setIsLoading(false);
      });
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const renderResult = (option, props) => {
    switch (option.resultType) {
      case 2: //requestgroup
        return <Request option={option} />;
      default:
        return <span>{option.title}</span>;
    }
  };

  const handleOnSourceChange = (options) => {
    if (options && options.length > 0) {
      let option = options[0];
      setKeyForm({ ...keyform, sourceKeyRequestId: option.id });
    }
  };

  const handleOnDestinationChange = (options) => {
    if (options && options.length > 0) {
      let option = options[0];
      setKeyForm({ ...keyform, destinationKeyRequestId: option.id });
    }
  };

  return (
    <>
      <ActionBar>
        <ResponsiveActionBarButtons
          buttons={[
            <Button
              key="move"
              text={t('Move')}
              className="btn btn-primary ms-2"
              onClick={handleSubmit(onSubmit)}
            />,
          ]}
        />
      </ActionBar>
      <KwPanel className="pb-3 pt-1">
        <FormGroup label={t('Source request')}>
          <AsyncTypeahead
            required
            className="required"
            ref={sourceTypeaheadRef}
            filterBy={filterBy}
            id="search-source-typeahead"
            isLoading={isLoading}
            labelKey="title"
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder={t('Search')}
            renderMenuItemChildren={renderResult}
            onChange={handleOnSourceChange}
          />
        </FormGroup>
        <FormGroup label={t('Destination request')}>
          <AsyncTypeahead
            required
            className="required"
            ref={destinationTypeaheadRef}
            filterBy={filterBy}
            id="search-destination-typeahead"
            isLoading={isLoading}
            labelKey="title"
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder={t('Search')}
            renderMenuItemChildren={renderResult}
            onChange={handleOnDestinationChange}
          />
        </FormGroup>

        <FormTextArea
          register={register}
          required
          error={errors.reason}
          errorMessage={t('Required')}
          name="reason"
          label={t('Please provide a reason')}
          placeholder=""
          defaultValue={keyform.reason}
        />
      </KwPanel>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveKeysBetweenRequests);
