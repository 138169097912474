import {
  apiUrl,
  handleResponse,
  handleError,
  getList,
  apiOptions,
  getSingleRecord,
  saveRecord,
  deleteRecord,
  postArray,
  deleteArray,
  exportData,
} from './apiUtils';
const baseUrl = apiUrl + '/gameTemplates/';

export function getAllGameTemplates() {
  let url = `${baseUrl}`;
  return fetch(url, apiOptions()).then(handleResponse).catch(handleError);
}

export function getGameTemplates(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getGameTemplateById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveGameTemplate(gameTemplate) {
  return saveRecord(baseUrl, gameTemplate);
}

export function deleteGameTemplate(id) {
  return deleteRecord(baseUrl, id);
}

export function addTerritories(id, territories) {
  return postArray(`${baseUrl}${id}/territories`, territories);
}

export function removeTerritories(id, territories) {
  return deleteArray(`${baseUrl}${id}/territories`, territories);
}
export function addKeyWorkflows(id, keyWorkflows) {
  return postArray(`${baseUrl}${id}/keyWorkflows`, keyWorkflows);
}

export function removeKeyWorkflows(id, keyWorkflows) {
  return deleteArray(`${baseUrl}${id}/keyWorkflows`, keyWorkflows);
}

export function exportGameTemplateData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/gameTemplates/search`,
    'name',
    filters,
    scopes
  );
}
