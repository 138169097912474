import * as companyApi from '../api/companyApi';
import actionTypes from '../constants/actionTypes';

export function saveCompany(company) {
  return function (dispatch) {
    return companyApi.saveCompany(company).then((savedCompany) => {
      dispatch({
        type: company.id
          ? actionTypes.UPDATE_COMPANY
          : actionTypes.CREATE_COMPANY,
        company: {
          ...company,
          id: savedCompany.id,
          file: null,
          imageId: savedCompany.imageId,
        },
      });
      return savedCompany;
    });
  };
}

export function saveCompanyKeyProviders(id, keyProviders) {
  return companyApi.saveCompanyKeyProviders(id, keyProviders);
}

export function getCompanyById(id) {
  return companyApi.getCompanyById(id);
}

export function loadCompanies({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return companyApi.getCompanies(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadCompaniesWithOpenRequests({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return companyApi.getCompanies(
    { ...filters, withOpenRequests: true },
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAllCompanies() {
  return function (dispatch) {
    return companyApi.getAllCompanies().then((companies) => {
      dispatch({
        type: actionTypes.LOAD_COMPANIES,
        companies,
      });
    });
  };
}
export function getRequestableCompanies(gameId, skuId) {
  return companyApi.getRequestableCompanies(gameId, skuId);
}

export function getStats(id) {
  return companyApi.getStats(id);
}

export function getStatsByDay(id) {
  return companyApi.getStatsByDay(id);
}

export function getAggregateStats(id, from, to) {
  return companyApi.getAggregateStats(id, from, to);
}

export function getAudit(id) {
  return companyApi.getAudit(id);
}

export function addAudit(id, comment) {
  return companyApi.addAudit(id, comment);
}

export function deleteCompany(id) {
  return function (dispatch) {
    return companyApi.deleteCompany(id).then(() => {
      dispatch({
        type: actionTypes.DELETE_COMPANY,
        id,
      });
    });
  };
}

export function disableKeys(id) {
  return companyApi.disableKeys(id);
}

export function loadUsers({
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return companyApi.getUsers(
    id,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportData(filters, scopes) {
  return companyApi.exportCompanyListData(filters, scopes);
}
