import React from 'react';
import FormSelect from '../../common/FormSelect';
import { useTranslation } from 'react-i18next';

export default function YearFormSelect({ year, years, handleYearChange }) {
  const { t } = useTranslation();
  return (
    <FormSelect
      name="year"
      label={t('Year')}
      onChange={handleYearChange}
      defaultValue={year}
    >
      {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </FormSelect>
  );
}
