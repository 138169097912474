import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import {
  saveDisableReason,
  getDisableReasonById,
} from '../../../../actions/keyCodeActions';
import { toast } from 'react-toastify';
import DisableReasonForm from '../components/DisableReasonForm';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';

function Edit({ done, id, cancel, saveDisableReason }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = (data) => {
    saveDisableReason({ ...disableReason, ...data }).then(() => {
      toast.success(t('Disabled reason saved'));
      done(disableReason);
    });
  };

  const [disableReason, setDisableReason] = useState({});

  useEffect(() => {
    if (id) {
      getDisableReasonById(id)
        .then((d) => {
          setDisableReason(d);
        })
        .catch(() => {
          toast.error(t('Failed to load disabled reason'));
        });
    }
  }, [id]);

  return (
    <SlideoutLayout
      title={id ? t('Edit disabled reason') : t('Add disabled reason')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="submit"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <DisableReasonForm
        disableReason={disableReason}
        register={register}
        errors={errors}
        control={control}
      ></DisableReasonForm>
    </SlideoutLayout>
  );
}

Edit.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  saveDisableReason: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  saveDisableReason,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
