import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import Button from '../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateOnly_AsString_NoHtml } from '../../util/formatter';
import { useTranslation } from 'react-i18next';
import icons from '../../constants/icons';

function AnnouncementModal({ announcements }) {
  const { t } = useTranslation();

  const [dismissedAnnouncements, setDismissedAnnouncements] = useLocalStorage(
    'dismissedAnnouncements',
    []
  );
  const [filteredAnnouncements, setFilteredAnnouncements] = useState([]);

  useEffect(() => {
    if (announcements) {
      // we keep track of announcements we've dismissed in localStorage, so we don't show them again
      // get a set of filtered announcements - dismissedAnnouncements is an array of {id: 1, expires: <date>} objects
      const filtered = announcements.filter(
        (announcement) =>
          !dismissedAnnouncements.some((x) => x.id === announcement.id)
      );

      setFilteredAnnouncements(filtered);
    }
  }, [dismissedAnnouncements, announcements]);

  const getHousekeptAnnouncements = () => {
    // remove any announcements from dismissedAnnouncements that dont exist in announcements, based on id
    var finalDismissedAnnouncements = dismissedAnnouncements.filter((x) =>
      announcements.map((announcement) => announcement.id).includes(x.id)
    );

    return finalDismissedAnnouncements;
  };

  const handleDismiss = () => {
    let finalDismissedAnnouncements = getHousekeptAnnouncements();

    // add the filtered announcement's ids to the finalDismissedAnnouncements array in localStorage
    finalDismissedAnnouncements = [
      ...finalDismissedAnnouncements,
      ...filteredAnnouncements,
    ];

    // store
    setDismissedAnnouncements(finalDismissedAnnouncements);
  };

  const handleDismissSingle = (announcement) => {
    let finalDismissedAnnouncements = getHousekeptAnnouncements();

    // add the filtered announcement's ids to the finalDismissedAnnouncements array in localStorage
    finalDismissedAnnouncements = [
      ...finalDismissedAnnouncements,
      ...[announcement],
    ];

    // store
    setDismissedAnnouncements(finalDismissedAnnouncements);
  };

  return filteredAnnouncements && filteredAnnouncements.length > 0 ? (
    <>
      <div className="announcement-modal">
        <header className="d-flex">
          <h1 className="no-margin">{t('Announcement')}</h1>
          <div
            className="icon-wrapper"
            tabIndex={0}
            onClick={handleDismiss}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleDismiss();
              }
            }}
            title={t('Close')}
          >
            <FontAwesomeIcon
              icon={icons.CLOSE}
              className="cursor-pointer d-flex"
              size="2x"
            />
          </div>
        </header>
        <div className="content">
          <ul>
            {filteredAnnouncements.map((announcement) => (
              <li key={announcement.id}>
                <div className="announcemment-detail">
                  <span className="message-date">
                    {DateOnly_AsString_NoHtml(announcement.announcementDate)}
                  </span>
                  <span className="message-text">{announcement.message}</span>
                </div>
                {filteredAnnouncements.length > 1 && (
                  <div
                    className="single-announcement-dismiss"
                    tabIndex={0}
                    onClick={() => handleDismissSingle(announcement)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleDismissSingle(announcement);
                      }
                    }}
                    title={t('Close')}
                  >
                    <FontAwesomeIcon
                      icon={icons.CLOSE}
                      className="cursor-pointer d-flex message-dismiss-icon"
                      size="1x"
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <footer>
          <Button
            className="btn btn-primary"
            onClick={handleDismiss}
            text={t('Dismiss')}
          >
            {t('Dismiss')}
          </Button>
        </footer>
      </div>
    </>
  ) : (
    <></>
  );
}
export default AnnouncementModal;
