import React, { useEffect, useState } from 'react';
import KwPanel from '../../common/KwPanel';
import FormInput from '../../common/FormInput';
import FormToggleBool from '../../common/FormToggleBool';
import FormStatic from '../../common/FormStatic';
import PropTypes from 'prop-types';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import FormSelect from '../../common/FormSelect';
import FormColourPicker from '../../common/FormColourPicker';
import FormImage from '../../common/FormImage';
import icons from '../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Help from '../../common/Help';
const CompanyForm = ({
  company,
  register,
  errors,
  control,
  setValue,
  companyTypes,
  defaultCompanyType,
  getValues,
  columnCssClass,
  setFile,
  setDomains,
}) => {
  const { t } = useTranslation();
  const [totalKeys, setTotalKeys] = useState(0);
  useEffect(() => {
    setTotalKeys(
      parseInt(company?.internalKeyCount || 0) +
        parseInt(company?.outsideStockKeys || 0)
    );
  }, [company, company.outsideStockKeys, company.internalKeyCount]);

  const recalc = () => {
    setTotalKeys(
      parseInt(company?.internalKeyCount || 0) +
        parseInt(getValues('outsideStockKeys').replace(/,/g, '') || 0)
    );
  };

  const [domain, setDomain] = useState('');
  const addDomain = () => {
    setDomains(company.domains ? [...company.domains, domain] : [domain]);
    setDomain('');
    setValue('domain', '');
  };
  const removeDomain = (domainName) => {
    setDomains(company.domains.filter((x) => x !== domainName));
  };

  return (
    <>
      <KwPanel className="mb-4 pb-3">
        <div className="row">
          <div className="col-12">
            <header className="mb-2">
              <h2 className="no-margin">{t('Company details')}</h2>
            </header>
          </div>
          <div className={columnCssClass || 'col-sm-6'}>
            <FormInput
              register={register}
              required={true}
              error={errors.companyName}
              errorMessage="Required"
              name="companyName"
              label={t('Name')}
              placeholder={t('Name of the company')}
              defaultValue={company.companyName || ''}
              autoFocus={true}
            />
            <FormSelect
              register={register}
              error={errors.companyId}
              name="companyTypeId"
              label={t('Company type')}
              placeholder=""
              defaultValue={
                company.companyTypeId ||
                (defaultCompanyType && defaultCompanyType.id) ||
                ''
              }
            >
              <option value="">{t('--- choose ---')}</option>
              {companyTypes &&
                [...companyTypes]
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  ?.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  ))}
            </FormSelect>
            <FormInput
              register={register}
              required={true}
              pattern={/^[0-9,.]+$/i}
              error={errors.transactionLimit}
              errorMessage={t('Number required')}
              name="transactionLimit"
              label={t('Maximum value of all open requests')}
              className="num"
              defaultValue={company.transactionLimit || 0}
            />
            <FormInput
              register={register}
              required={true}
              pattern={/^[0-9,.]+$/i}
              error={errors.globalTransactionLimit}
              errorMessage={t('Number required')}
              name="globalTransactionLimit"
              label={t('Maximum balance of all allocated keys')}
              className="num"
              defaultValue={company.globalTransactionLimit || 0}
            />
          </div>
          <div className={columnCssClass || 'col-sm-6'}>
            <FormToggleBool
              control={control}
              name="requestVisibleToAllUsers"
              label={t('Request visible to all users')}
              defaultValue={company.requestVisibleToAllUsers || false}
            />
            <FormToggleBool
              control={control}
              name="isProformaInvoicing"
              label={t('Pro-forma Invoicing')}
              defaultValue={company.isProformaInvoicing || false}
            />
            <FormColourPicker
              control={control}
              name="colourHex"
              label={t('Colour')}
              extraRowClasses="align-items-start"
              defaultValue={company.colourHex || 'transparent'}
            />
            <FormImage
              control={control}
              name="image"
              label={t('Image')}
              setFile={setFile}
              value={company.imageId}
            ></FormImage>
            <FormToggleBool
              control={control}
              name="isPublisher"
              label={t('Is publisher')}
              defaultValue={company.isPublisher || false}
            />
            <FormToggleBool
              control={control}
              name="isDeveloper"
              label={t('Is developer')}
              defaultValue={company.isDeveloper || false}
            />
          </div>
        </div>
      </KwPanel>
      <KwPanel className="mb-4 pb-3">
        <header className="mb-2">
          <h2 className="no-margin">{t('Domains')}</h2>
          <Help
            helpContent={t(
              'When user auto-provisioning is enabled, this list of domains is used to map the user to the correct company within Alaska. e.g. a.user@acme.com -> Acme'
            )}
            size="2x"
            className=""
          />
        </header>
        <FormInput
          register={register}
          name="domain"
          label={t('Domain name')}
          className=""
          placeholder={t('e.g. acme.com')}
          value={domain}
          defaultValue=""
          onChange={(e) => setDomain(e.target.value)}
          error={errors.code}
          joinedButton={true}
          joinedButtonText={t('Add')}
          joinedButtonClick={addDomain}
          joinedButtonDisabled={!domain}
        />
        <div className="tags">
          {company.domains && company.domains.length > 0 ? (
            company.domains.map((x, i) => (
              <div className="tag" key={i}>
                <span className="type">{x}</span>
                <span
                  className="ms-3 a"
                  onClick={(e) => {
                    removeDomain(x);
                  }}
                  title={t('Remove')}
                >
                  <FontAwesomeIcon icon={icons.DELETE_TAG}></FontAwesomeIcon>
                </span>
              </div>
            ))
          ) : (
            <span>
              {t('There are currently 0 domains setup for this company.')}
            </span>
          )}
        </div>
      </KwPanel>
      <KwPanel className="mb-4 pb-3">
        <header className="mb-2">
          <h2 className="no-margin">{t('Keys')}</h2>
        </header>
        <FormStatic label={t('Keys in stock from Alaska')} className="num">
          {CommaNumber_NoDecimal(company.internalKeyCount)}
        </FormStatic>
        <FormInput
          register={register}
          required
          error={errors.outsideStockKeys}
          errorMessage={t('Number required')}
          name="outsideStockKeys"
          label={t('Keys in stock from outside Alaska')}
          className="num"
          defaultValue={company.outsideStockKeys || 0}
          onChange={recalc}
        />
        <FormStatic label={t('Total keys in stock')} className="num">
          {CommaNumber_NoDecimal(totalKeys)}
        </FormStatic>
      </KwPanel>
    </>
  );
};

CompanyForm.propTypes = {
  company: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  companyTypes: PropTypes.array.isRequired,
  setDomains: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default CompanyForm;
