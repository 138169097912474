import React, { useState } from 'react';
import { connect } from 'react-redux';
import KwPanel from '../../../common/KwPanel';
import AddTag from '../slideout/AddTag';
import DeleteTag from '../slideout/DeleteTag';
import image_aurora from '../../../../content/images/AURORA_LOGO_32_01_LANDSCAPE.svg';

import SlidingPane from 'react-sliding-pane';

import image_tag from '../../../../content/images/tag.svg';
import icons from '../../../../constants/icons';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';

const WorkflowTags = ({
  workflow,
  tags,
  onWorkflowChange,
  isAuroraWorkflow,
}) => {
  const { t } = useTranslation();
  const [wf, setWf] = useState(workflow);
  const [_tags, setTags] = useState(tags ?? []);

  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const onAdd = (e) => {
    e.cancelBubble = true;
    setAddSlideoutOpen(true);
    return false;
  };

  const [removeSlideoutOpen, setRemoveSlideoutOpen] = useState(false);
  const [removeSlideoutItem, setRemoveSlideoutItem] = useState();
  const removeTag = (e, tag) => {
    e.cancelBubble = true;
    setRemoveSlideoutItem(tag);
    setRemoveSlideoutOpen(true);
    return false;
  };

  return wf ? (
    <>
      <KwPanel className="mb-4">
        <header className="mb-2">
          <h2 className="no-margin">
            <span className="workflow-icon">
              <img src={image_tag} alt="tags icon" width="32" height="32" />
            </span>
            {t('Requester tags')}
          </h2>
          {!isAuroraWorkflow &&
            wf.workflowRequiresTag &&
            !wf.tagsAsFreeText && (
              <div className="float-right">
                <Button
                  onClick={onAdd}
                  className="btn btn-primary"
                  text={t('Add')}
                />
              </div>
            )}
        </header>
        {!isAuroraWorkflow ? (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className="toggle-group me-2 mb-4">
                <label
                  key="A"
                  className={
                    !wf.workflowRequiresTag && !wf.tagsAsFreeText
                      ? 'checked'
                      : ''
                  }
                  onClick={() => {
                    setWf({
                      ...wf,
                      workflowRequiresTag: false,
                      tagsAsFreeText: false,
                    });
                    onWorkflowChange({
                      workflowRequiresTag: false,
                      tagsAsFreeText: false,
                    });
                  }}
                >
                  {t('No tag required')}
                </label>
                <label
                  key="M"
                  className={
                    wf.workflowRequiresTag && wf.tagsAsFreeText ? 'checked' : ''
                  }
                  onClick={() => {
                    setWf({
                      ...wf,
                      workflowRequiresTag: true,
                      tagsAsFreeText: true,
                    });
                    onWorkflowChange({
                      workflowRequiresTag: true,
                      tagsAsFreeText: true,
                    });
                  }}
                >
                  {t('Allow free text')}
                </label>
                <label
                  key="L"
                  className={
                    wf.workflowRequiresTag && !wf.tagsAsFreeText
                      ? 'checked'
                      : ''
                  }
                  onClick={() => {
                    setWf({
                      ...wf,
                      workflowRequiresTag: true,
                      tagsAsFreeText: false,
                    });
                    onWorkflowChange({
                      workflowRequiresTag: true,
                      tagsAsFreeText: false,
                    });
                  }}
                >
                  {t('Choose from list')}
                </label>
              </div>
            </div>
            {wf.workflowRequiresTag && !wf.tagsAsFreeText && (
              <div className="tags">
                {_tags && _tags.length > 0 ? (
                  _tags.map((tag) => (
                    <div className="tag" key={tag.id}>
                      <span>{tag.tag}</span>
                      <span
                        className="ms-3 a"
                        onClick={(e) => removeTag(e, tag)}
                        title={t('Remove')}
                      >
                        <FontAwesomeIcon icon={icons.DELETE_TAG} />
                      </span>
                    </div>
                  ))
                ) : (
                  <div>
                    <Trans
                      i18nKey="no tags provided message"
                      components={{
                        span: <span className="a" onClick={onAdd} />,
                      }}
                    >
                      No tags have been provided, please <span>Add a tag</span>{' '}
                      for this to work
                    </Trans>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div
            className="alert alert-info text-center"
            style={{ padding: '30px 15px' }}
          >
            <img
              src={image_aurora}
              alt="Aurora logo"
              height={20}
              className="mb-4"
            />
            <p>
              {t(
                'Aurora™ workflows only allow free text tags, which are optional'
              )}
            </p>
          </div>
        )}
      </KwPanel>

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <AddTag
          done={(d) => {
            // dont add if already there
            if (_tags.filter((r) => r.id === d.id).length === 0) {
              setTags([..._tags, d]);
            }
            setAddSlideoutOpen(false);
          }}
          cancel={() => setAddSlideoutOpen(false)}
          workflowId={workflow.id}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={removeSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRemoveSlideoutOpen(false)}
      >
        <>
          {removeSlideoutItem && (
            <DeleteTag
              workflowId={workflow.id}
              tag={removeSlideoutItem.tag}
              done={(d) => {
                setTags([
                  ..._tags.filter((r) => r.tag !== removeSlideoutItem.tag),
                ]);
                setRemoveSlideoutOpen(false);
              }}
              cancel={() => setRemoveSlideoutOpen(false)}
            />
          )}
        </>
      </SlidingPane>
    </>
  ) : (
    ''
  );
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTags);
