import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import CompanyStats from './components/CompanyStats';
import CompanyUsersList from './components/CompanyUsersList';
import { useTranslation } from 'react-i18next';
import SlidingPane from 'react-sliding-pane';

import {
  saveCompany,
  getCompanyById,
  getStats,
  getAudit,
  addAudit,
  getStatsByDay,
} from '../../actions/companyActions';
import { getKeyProviders } from '../../actions/keyProviderActions';
import { toast } from 'react-toastify';
import Loading from '../common/Loading';
import AuditButton from '../audit/components/AuditButton';
import ResponsiveActionBarButtons from '../common/ResponsiveActionBarButtons';
import { getAllCompanyTypes } from '../../actions/companyTypeActions';
import DisableKeys from '../keys/slideout/DisableKeys';
import EditCompany from './slideout/EditCompany';
import CompanyDetails from './components/CompanyDetails';
import RequestsTable from '../requests/components/RequestsTable';
import Button from '../common/Button';

const ManageCompany = ({
  location,
  id,
  keyProviders,
  getKeyProviders,
  history,
  currentUser,
  setCompleteRequestSlideoutOpen,
}) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState();

  const [disableKeysSlideoutOpen, setDisableKeysSlideoutOpen] = useState(false);
  const [editCompanySlideoutOpen, setEditCompanySlideoutOpen] = useState(false);
  const [company, setCompany] = useState();
  const [stats, setStats] = useState();
  const [reloadStats, setReloadStats] = useState();
  const [reloadRequests, setReloadRequests] = useState();

  useEffect(() => {
    if (id) {
      // get the company
      getCompanyById(id)
        .then((d) => {
          setCompany(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/companies');
          toast.error(t('Company not found'));
        });
    }
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [id, keyProviders, getKeyProviders, history, reload, t]);

  useEffect(() => {
    if (id) {
      // get the stats
      getStats(id)
        .then((d) => {
          setStats(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get company stats'));
        });
    }
  }, [id, reloadStats, getKeyProviders, history, reload]);

  return company && stats ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/companies', label: t('Companies') },
          { label: company.companyName },
        ]}
      >
        <div className="d-flex">
          <AuditButton id={id} getAudit={getAudit} addAudit={addAudit} />
          <ResponsiveActionBarButtons
            buttons={[
              // <Button
              //   key="disableKeys"
              //   className="btn btn-danger me-2 d-none d-md-inline-flex"
              //   onClick={() => setDisableKeysSlideoutOpen(true)}
              //   text="Disable keys"
              // />,

              <Button
                className="btn btn-primary d-none d-md-inline-flex ms-2"
                onClick={() => setEditCompanySlideoutOpen(true)}
                text={t('Edit')}
              />,
            ]}
          />
        </div>
      </ActionBar>

      <CompanyDetails company={company} />

      <CompanyStats
        id={company.id}
        stats={stats}
        getData={() => getStatsByDay(company.id)}
      />

      <div className="row">
        <div className="col-sm-12">
          <CompanyUsersList
            id={company.id}
            location={location}
            reload={reload}
            currentUser={currentUser}
          />
        </div>
      </div>

      <RequestsTable
        title={t('Requests made by {company}', {
          company: company.companyName,
        })}
        location={location}
        history={history}
        setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
        scope={{ company: id }}
        reload={reloadRequests}
        setReload={setReloadRequests}
        settingsKey={'company-requests-list'}
      />

      <SlidingPane
        isOpen={editCompanySlideoutOpen}
        title={t('Edit {company}', { company: company.companyName })}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setEditCompanySlideoutOpen(false)}
      >
        <EditCompany
          id={id}
          history={history}
          done={(d) => {
            setReload(new Date());
            setEditCompanySlideoutOpen(false);
          }}
          cancel={() => setEditCompanySlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={disableKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDisableKeysSlideoutOpen(false)}
      >
        <DisableKeys
          title={t('Disable keys for {company}', { company: company.name })}
          targetCompanyId={id}
          done={(d) => {
            setDisableKeysSlideoutOpen(false);
            // reload stats
            setReloadStats(new Date());
          }}
          cancel={() => setDisableKeysSlideoutOpen(false)}
          defaultReason={t('Disable keys for {company}', {
            company: company.name,
          })}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    keyProviders: state.keyProviders,
    currentUser: state.auth,
    companyTypes: state.companyTypes,
  };
}

const mapDispatchToProps = {
  saveCompany,
  getKeyProviders,
  getAllCompanyTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCompany);
