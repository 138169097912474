import {
  apiUrl,
  getList,
  deleteRecord,
  getSingleRecord,
  saveRecord,
  getStatsData,
  getAll,
  deleteAction,
  getAuditData,
  postAction,
  exportData,
  get,
  upload,
} from './apiUtils';
const baseUrl = apiUrl + '/platforms/';

export function getAllPlatforms() {
  return getAll(baseUrl);
}

export function getPlatforms(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getPlatformById(id) {
  return getSingleRecord(baseUrl, id);
}

export function savePlatform(platform) {
  return upload(baseUrl, platform);
}

export function deletePlatform(id) {
  return deleteRecord(baseUrl, id);
}

export function getStats(id) {
  return getStatsData(baseUrl, id);
}

export function getStatsByDay(id) {
  return get(`${baseUrl}${id}/statsByDay/`);
}

export function getAggregateStats(id, from, to) {
  // Format the from as YYYY-MM-DD
  from = from.toISOString().split('T')[0];
  // Format the to as YYYY-MM-DD
  to = to.toISOString().split('T')[0];

  return get(`${baseUrl}${id}/aggregateStats?fromDate=${from}&toDate=${to}`);
}

export function getAudit(id) {
  return getAuditData(baseUrl, id);
}

export function addAudit(id, comment) {
  return postAction(`${baseUrl}${id}/audit`, { comment });
}

export function getKeyProvidersForPlatform(id) {
  return getAll(`${baseUrl}${id}/keyProviders`);
}

export function savePlatformKeyProvider(platformId, keyProviderId) {
  return saveRecord(`${baseUrl}${platformId}/keyProvider/`, { keyProviderId });
}

export function deletePlatformKeyProvider(platformId, keyProviderId) {
  return deleteAction(`${baseUrl}${platformId}/keyProvider/${keyProviderId}`);
}

export function exportPlatformData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/platforms/search`,
    'name',
    filters,
    scopes
  );
}
