import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActionBar from '../layout/ActionBar';
import { getKeyProviders } from '../../actions/keyProviderActions';
import { getAllTerritories } from '../../actions/territoryActions';
import { loadPromotions } from '../../actions/promotionActions';

import 'react-virtualized/styles.css';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid';
import { hexToRgbA } from '../../util/formatter';
import { getSKUs } from '../../actions/skuActions';
import Loading from '../common/Loading';
import PercentageCell from './components/grid/cells/PercentageCell';
import EmptyCell from './components/grid/cells/EmptyCell';
import PriceCell from './components/grid/cells/PriceCell';
import GameNameCell from './components/grid/headers/GameNameCell';
import WarningCell from './components/grid/cells/WarningCell';
import GameNameHeaderCell from './components/grid/headers/GameNameHeaderCell';
import PriceHeaderCell from './components/grid/headers/PriceHeaderCell';
import PromotionHeaderCell from './components/grid/headers/PromotionHeaderCell';
import ExtraInfoHeaderCell from './components/grid/headers/ExtraInfoHeaderCell';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

//https://github.com/bvaughn/react-virtualized/blob/master/docs/Grid.md

function PromotionsGrid({
  keyProviders,
  getKeyProviders,
  territories,
  getAllTerritories,
  setClasses,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    setClasses('flex-grow-1');
  }, [setClasses]);

  const [gridState, setGridState] = useState({
    scrollToColumn: 0,
    scrollToRow: 0,
  });

  const STYLE = {
    //outline: '1px solid #3b3a39',
  };
  const STYLE_BOTTOM_LEFT_GRID = {
    //borderRight: '2px solid #3b3a39',
    // backgroundColor: '#f7f7f7',
  };
  const STYLE_TOP_LEFT_GRID = {
    //borderBottom: '2px solid #3b3a39',
    //borderRight: '2px solid #3b3a39',
    fontWeight: 'bold',
  };
  const STYLE_TOP_RIGHT_GRID = {
    //borderBottom: '2px solid #3b3a39',
    fontWeight: 'bold',
  };

  // const _cache = new CellMeasurerCache({
  //   defaultWidth: 150,
  //   minWidth: 75,
  //   minHeight: 60,
  // });

  const [promotions, setPromotions] = useState();
  const [games, setGames] = useState();
  const [dateRange, setDateRange] = useState({
    start: new Date('2022-01-01'),
    end: new Date('2022-12-31'),
  });
  useEffect(() => {
    if (dateRange) {
      getSKUs().then((skus) => {
        let gameData = skus.map((s) => ({
          name: s.gameName,
          sku: s.name,
          id: s.id,
          promotions: [],
        }));
        loadPromotions({
          filters: {
            promotionDateFrom: dateRange.start,
            promotionDateTo: dateRange.end,
          },
          _sort: 'start',
          _page: 0,
          _limit: 10000,
        })
          .then((promotions) => {
            if (promotions.data) {
              let promoData = promotions.data.map((item, index) => ({
                name: item.name,
                start: item.start,
                end: item.end,
                color: item.keyProviderColourHex
                  ? hexToRgbA(item.keyProviderColourHex, 0.5)
                  : 'rgba(22,190,239,0.5)',
                games: item.games,
              }));

              promoData.map((promo) => () => {
                promo.game.map((promoGame) => () => {
                  let game = gameData.find((g) => g.id === promoGame.skuId);
                  game.promotions.push({
                    id: promo.id,
                    keyValue: promoGame.keyValue,
                    discountPercentage: promoGame.discountPercentage,
                    price: promoGame.price,
                  });
                });
              });

              setGames(gameData);
              setPromotions(promoData);
            }
          })
          .catch((e) => {
            toast.error(e.message ? e.message : t('Failed to get promotions'));
          });
      });
    }
  }, [dateRange]);

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders]);

  useEffect(() => {
    if (!territories) {
      getAllTerritories();
    }
  }, [territories, getAllTerritories]);

  let _cellRenderer = ({ columnIndex, t, key, parent, rowIndex, style }) => {
    let content = <div></div>;

    if (rowIndex === 0) {
      // header
      if (columnIndex === 0) {
        content = <GameNameHeaderCell />;
      } else if (columnIndex === 1) {
        content = <PriceHeaderCell />;
      } else if (columnIndex === 2) {
        content = <ExtraInfoHeaderCell />;
      } else if (promotions) {
        content = (
          <PromotionHeaderCell promotion={promotions[columnIndex - 3]} />
        );
      }
    } else if (games && promotions) {
      // game
      let game = games[rowIndex - 1];
      let promo = promotions[columnIndex - 3];
      let gamePromo = game.promotions.find((x) => x.id === promo.id);
      if (columnIndex === 0) {
        content = <GameNameCell promotion={gamePromo} game={game} />;
      } else if (columnIndex === 1) {
        content = <PriceCell promotion={gamePromo} game={game} />;
      } else if (columnIndex === 2) {
        content = <EmptyCell />;
        // test components START
      } else if (columnIndex === 3) {
        content = <PercentageCell promotion={{ discount: 0.5 }} game={game} />;
      } else if (columnIndex === 4) {
        content = <EmptyCell />;
      } else if (columnIndex === 5) {
        content = <WarningCell />;
      } else if (columnIndex === 6) {
        content = (
          <PercentageCell
            promotion={{ discount: 0.5 }}
            game={game}
            editable={true}
          />
        );
      }
      // test components END
      else if (gamePromo) {
        content = <PercentageCell promotion={gamePromo} game={game} />;
      } else {
        content = <EmptyCell />;
      }
    }

    return (
      // <CellMeasurer
      //   cache={_cache}
      //   columnIndex={columnIndex}
      //   key={key}
      //   parent={parent}
      //   rowIndex={rowIndex}
      // >
      <div key={key} style={{ ...style, overflow: 'hidden' }}>
        {content}
      </div>
      //  </CellMeasurer>
    );
  };

  let _getColumnWidth = ({ index }) => {
    switch (index) {
      case 0:
        return 300;
      case 1:
        return 100;
      case 2:
        return 100;
      default:
        return 150;
    }
  };

  let _getRowHeight = ({ index }) => {
    if (index === 0) {
      return 120;
    }
    return 69;
  };

  return promotions && games ? (
    <>
      <ActionBar
        breadcrumb={[
          {
            link: '/promotions/grid',
            label: t('Promotion explorer'),
          },
        ]}
      ></ActionBar>
      {/* need to make this fill the screen */}
      <div className="grid-wrapper">
        <AutoSizer>
          {({ width, height }) => (
            <MultiGrid
              {...gridState}
              fixedColumnCount={3}
              fixedRowCount={1}
              cellRenderer={_cellRenderer}
              columnWidth={_getColumnWidth}
              columnCount={promotions.length + 3}
              enableFixedColumnScroll
              enableFixedRowScroll
              height={height}
              rowHeight={_getRowHeight}
              //deferredMeasurementCache={_cache}
              rowCount={games.length + 1}
              style={STYLE}
              styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
              styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
              styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
              width={width}
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
            />
          )}
        </AutoSizer>
      </div>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    setClasses: ownProps.setClasses,
    keyProviders: state.keyProviders,
    territories: state.territories,
  };
}

const mapDispatchToProps = {
  getKeyProviders,
  getAllTerritories,
};

PromotionsGrid.propTypes = {
  location: PropTypes.object.isRequired,
  setClasses: PropTypes.func.isRequired,
  getKeyProviders: PropTypes.func.isRequired,
  getAllTerritories: PropTypes.func.isRequired,
  keyProviders: PropTypes.array,
  territories: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionsGrid);
