import { apiUrl, getList, download } from './apiUtils';
const baseUrl = apiUrl + '/healthcheck/';
export function getHealthChecks(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function downloadHealthCheck(id) {
  return download(`${baseUrl}${id}/download`);
}
