import React from 'react';
import KwPanel from '../../../common/KwPanel';
import PropTypes from 'prop-types';
import FormDate from '../../../common/FormDate';
import FormTextArea from '../../../common/FormTextArea';
import announcementHelp from '../../../../constants/help/announcementHelp';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const AnnouncementForm = ({ announcement, register, errors }) => {
  const { t } = useTranslation();
  const language = i18next.language;
  return announcement ? (
    <KwPanel className="mb-4 pb-1">
      <FormTextArea
        register={register}
        required={true}
        error={errors.message}
        errorMessage={t('Required')}
        name="message"
        label={t('Message')}
        defaultValue={announcement.message || ''}
        autoFocus={true}
      />
      <FormDate
        register={register}
        required={true}
        error={errors.message}
        errorMessage={t('Required')}
        name="announcementDate"
        defaultValue={announcement.announcementDate || ''}
        label={t('Announcement date')}
        helpContent={announcementHelp.ANNOUNCEMENT_DATE[language]}
      />
      <FormDate
        register={register}
        required={true}
        error={errors.message}
        errorMessage={t('Required')}
        name="expiryDate"
        defaultValue={announcement.expiryDate || ''}
        label={t('Expiry date')}
        helpContent={announcementHelp.EXPIRY_DATE[language]}
      />
    </KwPanel>
  ) : (
    ''
  );
};

AnnouncementForm.propTypes = {
  announcement: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default AnnouncementForm;
