import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.UPDATE_SOLD_TRANSACTION:
      return { ...state, ...action.transaction };
    case actionTypes.CLEAR_SOLD_TRANSACTION:
      return null;
    default:
      return state;
  }
}
