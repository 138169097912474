import React from 'react';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import KwPanel from '../../common/KwPanel';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';
import FormTextArea from '../../common/FormTextArea';
import { useForm } from 'react-hook-form';
function DeclineReason({ done, cancel, onSubmit }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  return (
    <SlideoutLayout
      title={t('Please provide a reason for declining the request')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
          title={t('Save changes')}
          text={t('Save changes')}
        />
      }
    >
      <KwPanel className="mb-4">
        <FormTextArea
          register={register}
          required={true}
          error={errors.reason}
          errorMessage={t('Required')}
          name="reason"
          label={t('Please provide a reason...')}
          placeholder=""
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
export default DeclineReason;
