import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddGroupMembership from '../slideout/AddGroupMembership';
import RemoveGroupMembership from '../slideout/RemoveGroupMembership';
import KwPanel from '../../../common/KwPanel';
import { NavLink } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import icons from '../../../../constants/icons';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function UserProfileGroupBadges({
  label,
  description,
  onAdd,
  onRemove,
  userProfileGroups,
  allGroups,
}) {
  const [_userProfileGroups, setUserProfileGroups] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    setUserProfileGroups(userProfileGroups);
  }, [userProfileGroups]);

  const [addUserProfileGroupSlideoutOpen, setAddUserProfileGroupSlideoutOpen] =
    useState(false);
  const addUserProfileGroup = (e) => {
    e.cancelBubble = true;
    setAddUserProfileGroupSlideoutOpen(true);
    return false;
  };

  const [
    removeUserProfileGroupSlideoutOpen,
    setRemoveUserProfileGroupSlideoutOpen,
  ] = useState(false);
  const [
    removeUserProfileGroupSlideoutItem,
    setRemoveUserProfileGroupSlideoutItem,
  ] = useState();
  const removeUserProfileGroup = (e, userProfileGroupId) => {
    e.cancelBubble = true;
    setRemoveUserProfileGroupSlideoutOpen(true);
    setRemoveUserProfileGroupSlideoutItem(userProfileGroupId);
    return false;
  };

  const UserProfileGroupTag = ({ userProfileGroup }) => (
    <div className="tag" key={userProfileGroup.id}>
      <NavLink
        to={`/settings/user-profile-group/${userProfileGroup.id}`}
        title={t('Click to view group details')}
      >
        {userProfileGroup.name}
      </NavLink>
      <span
        className="ms-3 a"
        onClick={(e) => removeUserProfileGroup(e, userProfileGroup.id)}
        title={t('Remove')}
      >
        <FontAwesomeIcon icon={icons.DELETE_TAG} />
      </span>
    </div>
  );

  return (
    <>
      <KwPanel className="mb-4">
        <header className="mb-2">
          <h2 className="no-margin">{label || t('UserProfileGroups')}</h2>
          <div className="float-right">
            <Button
              className="btn btn-default"
              onClick={addUserProfileGroup}
              text={t('Add')}
            />
          </div>
        </header>
        {description && <div>{description}</div>}
        <div className="mt-3 tags">
          {_userProfileGroups && _userProfileGroups.length > 0 ? (
            _userProfileGroups.map((x, i) => (
              <UserProfileGroupTag key={i} userProfileGroup={x} />
            ))
          ) : (
            <></>
          )}
        </div>
      </KwPanel>

      <SlidingPane
        isOpen={addUserProfileGroupSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddUserProfileGroupSlideoutOpen(false)}
      >
        <AddGroupMembership
          cancel={() => setAddUserProfileGroupSlideoutOpen(false)}
          done={(d) => {
            setAddUserProfileGroupSlideoutOpen(false);
            onAdd(d);
          }}
          allGroups={allGroups}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={removeUserProfileGroupSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setRemoveUserProfileGroupSlideoutOpen(false)}
      >
        <RemoveGroupMembership
          userProfileGroupId={removeUserProfileGroupSlideoutItem}
          cancel={() => setRemoveUserProfileGroupSlideoutOpen(false)}
          done={(d) => {
            setRemoveUserProfileGroupSlideoutOpen(false);
            onRemove(d);
          }}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileGroupBadges);
