import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import KwPanel from './KwPanel';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const CombinedStatsDonut = (props) => {
  const { t } = useTranslation();
  const [cleanStats, setCleanStats] = useState();
  const [primaryStat1, setPrimaryStat1] = useState();
  const [primaryStat2, setPrimaryStat2] = useState();

  useEffect(() => {
    if (props.stats && props.stats.length > 0) {
      let s = props.stats.map((stat, i) => {
        let result = 0;
        let suffix = '';
        let digits = 0;

        if (stat.metric <= 999) {
          result = stat.metric;
        } else if (stat.metric > 999 && stat.metric <= 999999) {
          result = stat.metric / 1000;
          digits = 1;
          suffix = 'k';
        } else if (stat.metric > 999999) {
          result = stat.metric / 1000000;
          digits = 1;
          suffix = 'm';
        }
        return { result, suffix, digits, ...stat };
      });

      setCleanStats(s);

      let p = s.filter((stat) => stat.primaryStat);
      if (p && p.length > 0) {
        setPrimaryStat1(p[0]);
      }
      if (p && p.length > 1) {
        setPrimaryStat2(p[1]);
      }
    }
  }, [props.stats]);

  const animateDelay = (e, stat) => {
    e.preventDefault();
    //e.currentTarget.firstChild.classList.add('active');
    //setTimeout(() => {
    props.history.push(stat.linkto);
    //}, 500);
    return false;
  };

  return (
    <>
      {cleanStats && props.stats && (
        <KwPanel className={`stats-panel mb-4 d-flex`}>
          {primaryStat1 && (
            <div className="number-wrapper h-100">
              <button
                className={`d-flex no-wrap align-items-center justify-content-center number-btn bg-${primaryStat1.colour}`}
                onClick={(e) => animateDelay(e, primaryStat1)}
              >
                <span className="number">
                  <CountUp
                    end={primaryStat1.result}
                    digits={primaryStat1.digits}
                    suffix={primaryStat1.suffix}
                    duration={0.6}
                    delay={0}
                  />
                </span>
                {primaryStat1.title}
              </button>
              {primaryStat2 && (
                <div className="stat">
                  <button
                    className="d-flex no-wrap align-items-center justify-content-center"
                    onClick={(e) => animateDelay(e, primaryStat2)}
                  >
                    {primaryStat2.title}
                    <span className={`monospace text-${primaryStat2.colour}`}>
                      <CountUp
                        end={primaryStat2.result}
                        digits={primaryStat2.digits}
                        suffix={primaryStat2.suffix}
                        duration={0.6}
                        delay={0}
                      />
                    </span>
                  </button>
                </div>
              )}
            </div>
          )}
          <ul className="h-100">
            {cleanStats
              .filter((stat, i) => !stat.primaryStat && i < 8)
              .map((stat, i) => {
                return (
                  <li key={i}>
                    <button
                      className="d-flex no-wrap align-items-center justify-content-between"
                      onClick={(e) => animateDelay(e, stat)}
                    >
                      {t(stat.title)}
                      <span
                        className={`number text-${stat.colour}`}
                        title={stat.metric}
                      >
                        <CountUp
                          end={stat.result}
                          digits={stat.digits}
                          suffix={stat.suffix}
                          duration={0.6}
                          delay={0}
                        />
                      </span>
                    </button>
                  </li>
                );
              })}
          </ul>
        </KwPanel>
      )}
    </>
  );
};
export default withRouter(CombinedStatsDonut);
