import React from 'react';
import PropTypes from 'prop-types';
import { CommaNumber_WithDecimal } from '../../../util/formatter';
import Button from '../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';

const KeyValue = ({ request, processing, onEditRequestValue, compressed }) => {
  const { t } = useTranslation();

  return compressed ? (
    <>
      {request.currentUserCanSign && (
        <span
          tabIndex={0}
          disabled={processing}
          onClick={(e) => onEditRequestValue(e, request)}
          className="a"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onEditRequestValue(e, request);
            }
          }}
          style={{ fontSize: '12px' }}
        >
          <FontAwesomeIcon icon={icons.EDIT} className="me-4" />
        </span>
      )}
      <span className={request.currentUserCanSign ? 'edit-hover-item' : ''}>
        {CommaNumber_WithDecimal(request.currentRequestValue)}
      </span>
    </>
  ) : (
    <div className="col-6">
      <label className="col-form-label">{t('Value')}</label>
      <div className="flex ps-0">
        <div className="form-control-static px-3 width-100 h-100 flex align-items-center num">
          {CommaNumber_WithDecimal(request.currentRequestValue)}
        </div>
        {request.currentUserCanSign && (
          <Button
            isDisabled={processing}
            title={processing ? t('Processing') : t('Edit')}
            onClick={(e) => onEditRequestValue(e, request)}
            className="btn btn-default float-left r-corners-right"
            text={t('Edit')}
          />
        )}
      </div>
    </div>
  );
};

KeyValue.propTypes = {
  request: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  onEditRequestValue: PropTypes.func.isRequired,
  compressed: PropTypes.bool,
};

export default KeyValue;
