import React from 'react';
import TagHeader from './TagHeader';
import NumberOfKeysHeader from './NumberOfKeysHeader';
import PlatformRow from './PlatformRow';
import KeyProviderRow from './KeyProviderRow';
import AppIdPackageIdRow from './AppIdPackageIdRow';
import GameRow from './GameRow';
import SKURow from './SKURow';
import TagRow from './TagRow';
import NumberOfKeysRow from './NumberOfKeysRow';
import KeyProviderHeader from './KeyProviderHeader';
import PlatformHeader from './PlatformHeader';
import TerritoryHeader from './TerritoryHeader';
import TerritoryRow from './TerritoryRow';
import PackageTypeRow from './PackageTypeRow';

export function cellRenderer({
  t,
  columnIndex,
  rowIndex,
  games,
  style,
  key,
  handleRemove,
  bulkChangeNumberOfKeys,
  changeNumberOfKeys,
  bulkNumberOfKeys,
  bulkNumberOfKeysOnBlur,
  gameNumberOfKeysOnBlur,
  bulkChangeTag,
  changeTag,
  gameTagOnBlur,
  bulkTag,
  bulkTagOnBlur,
  keyWorkflow,
  filterGame,
  handleFilterGame,
  filterPackageType,
  handleFilterPackageType,
  filterAppPackageId,
  handleFilterAppPackageId,
  filterKeyProvider,
  handleFilterKeyProvider,
  filterPlatform,
  handleFilterPlatform,
  platforms,
  filterTerritory,
  handleFilterTerritory,
  territories,
  keyProviders,
}) {
  if (rowIndex === 0) {
    return (
      <div key={key} style={{ ...style, overflow: 'hidden' }}>
        {headerRow({
          t,
          columnIndex,
          bulkChangeNumberOfKeys,
          bulkNumberOfKeys,
          bulkNumberOfKeysOnBlur,
          bulkChangeTag,
          bulkTag,
          bulkTagOnBlur,
          keyWorkflow,
          filterGame,
          handleFilterGame,
          filterPackageType,
          handleFilterPackageType,
          filterAppPackageId,
          handleFilterAppPackageId,
          filterKeyProvider,
          handleFilterKeyProvider,
          filterPlatform,
          handleFilterPlatform,
          platforms,
          filterTerritory,
          handleFilterTerritory,
          territories,
          keyProviders,
        })}
      </div>
    );
  } else {
    return (
      <div key={key} style={{ ...style, overflow: 'hidden' }}>
        {games ? (
          bodyRow({
            columnIndex: columnIndex,
            game: games[rowIndex - 1],
            handleRemove: handleRemove,
            changeTag: changeTag,
            gameTagOnBlur: gameTagOnBlur,
            changeNumberOfKeys: changeNumberOfKeys,
            gameNumberOfKeysOnBlur: gameNumberOfKeysOnBlur,
            keyWorkflow: keyWorkflow,
            keyProviders: keyProviders,
          })
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

function headerRow({
  t,
  columnIndex,
  bulkChangeNumberOfKeys,
  bulkNumberOfKeys,
  bulkNumberOfKeysOnBlur,
  bulkChangeTag,
  bulkTag,
  bulkTagOnBlur,
  keyWorkflow,
  filterGame,
  handleFilterGame,
  filterPackageType,
  handleFilterPackageType,
  filterAppPackageId,
  handleFilterAppPackageId,
  filterKeyProvider,
  handleFilterKeyProvider,
  filterPlatform,
  handleFilterPlatform,
  platforms,
  filterTerritory,
  handleFilterTerritory,
  territories,
  keyProviders,
}) {
  if (columnIndex === 0) {
    return (
      <div className="bulk-grid-game-name-th">
        <span className="mb-2">{t('Game')}</span>
        <input
          type="input"
          className="form-control"
          placeholder=""
          value={filterGame || ''}
          onChange={handleFilterGame}
        ></input>
      </div>
    );
  }
  if (columnIndex === 1) {
    return (
      <TerritoryHeader
        territories={territories}
        filterTerritory={filterTerritory}
        handleFilterTerritory={handleFilterTerritory}
      ></TerritoryHeader>
    );
  }

  if (columnIndex === 2) {
    return (
      <div className="bulk-grid-game-name-th">
        <span className="mb-2">{t('App Id / Package Id')} </span>
        <input
          type="input"
          className="form-control"
          placeholder=""
          value={filterAppPackageId || ''}
          onChange={handleFilterAppPackageId}
        ></input>
      </div>
    );
  }

  if (columnIndex === 3) {
    return (
      <div className="bulk-grid-game-name-th">
        <span className="mb-2">{t('Package type')}</span>
        <input
          type="input"
          className="form-control"
          placeholder=""
          value={filterPackageType || ''}
          onChange={handleFilterPackageType}
        ></input>
      </div>
    );
  }

  if (columnIndex === 4) {
    return (
      <PlatformHeader
        platforms={platforms}
        filterPlatform={filterPlatform}
        handleFilterPlatform={handleFilterPlatform}
      ></PlatformHeader>
    );
  }

  if (columnIndex === 5) {
    return (
      <KeyProviderHeader
        keyProviders={keyProviders}
        filterKeyProvider={filterKeyProvider}
        handleFilterKeyProvider={handleFilterKeyProvider}
      ></KeyProviderHeader>
    );
  }

  if (columnIndex === 6) {
    return keyWorkflow?.workflowRequiresTag ? (
      <TagHeader
        bulkChangeTag={bulkChangeTag}
        tagsAsFreeText={keyWorkflow?.tagsAsFreeText}
        requesterTags={keyWorkflow?.requesterTags}
        bulkTag={bulkTag}
        bulkTagOnBlur={bulkTagOnBlur}
      ></TagHeader>
    ) : (
      <div className="bulk-grid-game-name-th">
        <div className="d-flex flex-column h-100 justify-content-between border-0"></div>
      </div>
    );
  }

  if (columnIndex === 7) {
    return (
      <NumberOfKeysHeader
        bulkChangeNumberOfKeys={bulkChangeNumberOfKeys}
        bulkNumberOfKeysOnBlur={bulkNumberOfKeysOnBlur}
        bulkNumberOfKeys={bulkNumberOfKeys}
      ></NumberOfKeysHeader>
    );
  }

  return <div className="bulk-grid-game-name-th"></div>;
}

function getRowStyle({ game, columnIndex }) {
  if (columnIndex === 0 && game.isSKU) {
    return {
      paddingLeft: '16px',
      display: 'flex',
      alignItems: 'center',
    };
  }

  if (game.isSKU) {
    return { backgroundColor: 'transparent' };
  }

  return {
    backgroundColor: '#16beef0f',
    borderTop: '3px solid #ffffff0f',
  };
}

function bodyRow({
  columnIndex,
  game,
  handleRemove,
  changeTag,
  gameTagOnBlur,
  changeNumberOfKeys,
  gameNumberOfKeysOnBlur,
  keyWorkflow,
  keyProviders,
}) {
  switch (columnIndex) {
    case 0:
      return !game.isSKU ? (
        <GameRow
          game={game}
          style={getRowStyle({ game, columnIndex })}
          onRemove={handleRemove}
        ></GameRow>
      ) : (
        <SKURow
          game={game}
          style={getRowStyle({ game, columnIndex })}
          onRemove={handleRemove}
        ></SKURow>
      );
    case 1:
      return (
        <TerritoryRow
          game={game}
          style={getRowStyle({ game, columnIndex })}
        ></TerritoryRow>
      );
    case 2:
      return (
        <AppIdPackageIdRow
          game={game}
          style={getRowStyle({ game, columnIndex })}
        ></AppIdPackageIdRow>
      );
    case 3:
      return (
        <PackageTypeRow
          game={game}
          style={getRowStyle({ game, columnIndex })}
        ></PackageTypeRow>
      );
    case 4:
      return (
        <PlatformRow
          game={game}
          style={getRowStyle({ game, columnIndex })}
        ></PlatformRow>
      );
    case 5:
      return (
        <KeyProviderRow
          game={game}
          keyProviders={keyProviders}
          style={getRowStyle({ game, columnIndex })}
        ></KeyProviderRow>
      );
    case 6:
      return (
        <TagRow
          game={game}
          style={getRowStyle({ game, columnIndex })}
          tagsAsFreeText={keyWorkflow?.tagsAsFreeText}
          requesterTags={keyWorkflow?.requesterTags}
          changeTag={changeTag}
          gameTagOnBlur={gameTagOnBlur}
        ></TagRow>
      );
    case 7:
      return (
        <NumberOfKeysRow
          game={game}
          style={getRowStyle({ game, columnIndex })}
          gameNumberOfKeysOnBlur={gameNumberOfKeysOnBlur}
          changeNumberOfKeys={changeNumberOfKeys}
        ></NumberOfKeysRow>
      );
    default:
      return (
        <div
          className="grid-empty"
          style={getRowStyle({ game, columnIndex })}
        ></div>
      );
  }
}

export function getColumnWidth({ index, keyWorkflow }) {
  switch (index) {
    case 0:
      return 400;
    case 1:
      return 100;
    case 6:
      return keyWorkflow?.workflowRequiresTag ? 150 : 0;
    default:
      return 150;
  }
}

export function getRowHeight({ index }) {
  return index === 0 ? 80 : 49;
}
