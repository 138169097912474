import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import icons from '../../constants/icons';
const KebabMenu = ({
  buttons,
  className,
  horizontal,
  skip,
  take,
  reverse,
  reverseFilterButtons,
  buttonIcon,
  buttonClassName,
  doNotFilterButtons,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  let _buttons = reverse ? [...buttons].reverse() : buttons;
  let start = skip ?? 0;
  let end = start + (take ?? 0);
  const filteredButtons =
    _buttons && end > 0
      ? _buttons
          .filter((button) => button?.props)
          .filter(
            (button, index) =>
              index >= start && index < end && !button.props.disabled
          )
          .reverse()
      : [];

  const hasData =
    (filteredButtons && filteredButtons.length > 0) || doNotFilterButtons;

  return (
    <>
      {hasData && (
        <div className="flex align-items-center justify-content-end">
          {(filteredButtons ?? []).map((button, index) => ({
            ...button,
            key: index,
            props: {
              ...button.props,
              onClick: (e) => {
                setIsOpen(false);
                if (button.props?.onClick) {
                  button.props.onClick(e);
                }
              },
              className: `${button.props?.className} btn--square`,
              hideTitle: true,
            },
          }))}
          <Dropdown
            className={`dropdown pe-2 d-inline-flex ${className || ''}`}
            show={isOpen}
            onToggle={() => setIsOpen(!isOpen)}
          >
            <Dropdown.Toggle
              variant="default"
              className={`btn btn-default dropdown-toggle no-icon no-navigate btn-context-menu ${
                buttonClassName || ''
              }`}
              onClick={() => setIsOpen(!isOpen)}
            >
              <FontAwesomeIcon
                icon={
                  buttonIcon ||
                  (horizontal ? icons.KEBAB_MENU : icons.KEBAB_MENU_VERTICAL)
                }
                fixedWidth
                aria-hidden="true"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="dropdown-menu dropdown-menu-dark"
              popperConfig={{
                strategy: 'fixed',
              }}
            >
              {_buttons
                .filter((button) => button?.props)
                .map((button, index) =>
                  button.props.disabled ? (
                    <span
                      key={index}
                      className="tooltip-left"
                      style={{ cursor: 'not-allowed' }}
                      data-tooltip={button.props.title}
                    >
                      {{
                        ...button,
                        props: {
                          ...button.props,
                          className: 'dropdown-item',
                        },
                      }}
                    </span>
                  ) : (
                    {
                      ...button,
                      key: index,
                      props: {
                        ...button.props,
                        onClick: (e) => {
                          setIsOpen(false);
                          if (button.props.onClick) {
                            button.props.onClick(e);
                          }
                        },
                        className: `dropdown-item ${
                          button.props.dropdownclasses || ''
                        }`,
                      },
                    }
                  )
                )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </>
  );
};

KebabMenu.propTypes = {
  buttons: PropTypes.array,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  skip: PropTypes.number,
  take: PropTypes.number,
  reverse: PropTypes.bool,
  reverseFilterButtons: PropTypes.bool,
  buttonIcon: PropTypes.string,
  buttonClassName: PropTypes.string,
  doNotFilterButtons: PropTypes.bool,
};

export default KebabMenu;
