import React from 'react';
import ActionBar from '../layout/ActionBar';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import ReportTableViewSwitcher from './components/ReportTableViewSwitcher';
import KeysByMonthByGameList from './components/month/KeysByMonthByGameList';
import KeysByWeekByGameList from './components/week/KeysByWeekByGameList';

function KeysByGame({ location }) {
  const [groupBy, setGroupBy] = useLocalStorage('report-group-by', 'month');
  return (
    <>
      <ActionBar></ActionBar>
      {groupBy === 'month' ? (
        <KeysByMonthByGameList
          location={location}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      ) : (
        <KeysByWeekByGameList
          location={location}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      )}
    </>
  );
}

export default KeysByGame;
