import actionTypes from '../constants/actionTypes';

export default function (state = [], action) {
  switch (action.type) {
    case actionTypes.UPDATE_DISABLE_TRANSACTION:
      return state
        ? state.find((transaction) => transaction.id === action.transaction.id)
          ? state.map((transaction) =>
              transaction.id === action.transaction.id
                ? action.transaction
                : transaction
            )
          : [...state, action.transaction]
        : state;
    case actionTypes.CREATE_DISABLE_TRANSACTION:
      return state ? [...state, action.transaction] : state;
    case actionTypes.CLEAR_DISABLE_TRANSACTION:
      return state
        ? state.filter((transaction) => transaction.id !== action.id)
        : state;
    default:
      return state;
  }
}
