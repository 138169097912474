import React from 'react';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import KwPanel from '../../common/KwPanel';
import { returnKeys } from '../../../actions/requestActions';
import { toast } from 'react-toastify';
import Button from '../../common/Button';
import ConfirmationHeader from '../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function ReturnKeys({ request, done, cancel }) {
  const onSubmit = () => {
    returnKeys(request.id)
      .then(done)
      .catch((e) => {
        toast.error(e.message ?? t('Failed to return keys to key pool'));
      });
  };
  const { t } = useTranslation();
  return (
    <SlideoutLayout
      title={t('Return keys to key pool for request {reference}', {
        reference: request.reference,
      })}
      cancel={cancel}
      done={done}
    >
      <KwPanel className="mb-4">
        <>
          <ConfirmationHeader />
          <p>
            {t(
              'This will withdraw the request and return the keys to the key pool. Are you sure you want to proceed?'
            )}
          </p>
          <Button
            onClick={onSubmit}
            className="btn btn-primary me-2"
            text={t('Yes, continue')}
          />
          <Button
            onClick={cancel}
            className="btn btn-default"
            text={t('No, cancel')}
          />
        </>
      </KwPanel>
    </SlideoutLayout>
  );
}
export default ReturnKeys;
