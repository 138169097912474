import React from 'react';
import { NavLink } from 'react-router-dom';

function GameNameCell({ promotion, game, editable }) {
  return (
    <div className="grid-game-name">
      <NavLink to={`/sku/${game.skuId}`} className="game-title">
        {game.name}
      </NavLink>
      <br />
      <NavLink to={`/sku/${game.skuId}`}>{game.sku}</NavLink>
    </div>
  );
}

export default GameNameCell;
