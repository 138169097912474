import React from 'react';
import { Date_AsString } from '../../../util/formatter';
import KwPanel from '../../common/KwPanel';
import { useTranslation } from 'react-i18next';

function DisableKeyProviderResults({ results }) {
  const { t } = useTranslation();
  return (
    <KwPanel>
      <header className="mb-2">
        <h2 className="no-margin ">{t('Key provider disable results')}</h2>
      </header>
      <table className="table dataTable width-100">
        <thead>
          <tr>
            <th>{t('Date ran')}</th>
            <th>{t('Ran by')}</th>
            <th>{t('Keys banned')}</th>
            <th>{t('Keys remaining')}</th>
            <th>{t('Keys failed to ban')}</th>
            <th>{t('Keys already banned')}</th>
            <th>{t('Purchases revoked')}</th>
            <th>{t('Purchased skipped')}</th>
            <th>{t('Purchases failed to revoke')}</th>
          </tr>
        </thead>
        <tbody>
          {results.map((item) => {
            return (
              <tr key={item.id}>
                <td>{Date_AsString(item.creationDate)}</td>
                <td>{item.createdBy}</td>
                <td>{item.result.keysBanned}</td>
                <td>{item.result.keysRemaining}</td>
                <td>{item.result.keysFailedToBan}</td>
                <td>{item.result.keysAlreadyBanned}</td>
                <td>{item.result.purchasesRevoked}</td>
                <td>{item.result.purchasesSkipped}</td>
                <td>{item.result.purchasesFailedToRevoke}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </KwPanel>
  );
}
export default DisableKeyProviderResults;
