import React from 'react';
import PropTypes from 'prop-types';
import LeftNavWrapper from '../menu-components/LeftNavWrapper';
import LeftMenuItem from '../menu-components/LeftMenuItem';
import permissionTypes from '../../../constants/permissionTypes';
import NavColours from '../../../constants/NavColours';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';

function LeftMenu_Promotion({ currentUser, wrapperClassName }) {
  const { t } = useTranslation();

  return (
    <LeftNavWrapper className={wrapperClassName}>
      <LeftMenuItem
        url="/promotions"
        activeRegex="^\/promotions$"
        icon={icons.CALENDAR}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Calendar')}
        currentUser={currentUser}
      />
      <LeftMenuItem
        url="/promotions/grid"
        activeRegex="^\/promotions/grid$"
        icon={icons.GRID_DIVIDER}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Explorer')}
        currentUser={currentUser}
      />
      <LeftMenuItem
        url="/promotions/all"
        activeRegex="^\/promotions\/all$"
        icon={icons.LIST}
        colour={NavColours.NAV_COLOUR_1}
        title={t('List')}
        currentUser={currentUser}
      />
      <LeftMenuItem
        url="/promotion/add"
        activeRegex="^\/promotion\/add$"
        icon={icons.ADD}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Add promotion')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_PROMOTIONS]}
      />
    </LeftNavWrapper>
  );
}

LeftMenu_Promotion.propTypes = {
  currentUser: PropTypes.object,
};

export default LeftMenu_Promotion;
