import React, { useState } from 'react';
import ImageFromApi from '../../../common/ImageFromApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function WizardStepItem({
  id,
  title,
  typeIcon,
  typeImageId,
  typeImageColourHex,
  typeImageFallback,
  description,
  icon,
  onClick,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleOnClick = (id) => {
    setTimeout(() => setIsLoading(true), 300);
    onClick(id);
  };

  return (
    <div
      className={`col-sm-12 template p-0 wizard step`}
      style={{ cursor: 'pointer' }}
      aria-label={'Choose item'}
      onClick={() => handleOnClick(id)}
    >
      <div className="template-detail">
        <div className="d-flex">
          {typeImageId || typeImageColourHex ? (
            <ImageFromApi
              imageId={typeImageId}
              imageSize={44}
              colourHex={typeImageColourHex}
              fallbackIcon={typeImageFallback}
            />
          ) : typeIcon ? (
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={typeIcon} />
            </div>
          ) : (
            ''
          )}
          <div className="p-3 d-flex justify-content-center flex-column">
            <h2 className="mb-0 p-0 pb-1">{title}</h2>
            {description}
          </div>
        </div>
      </div>
      <div className="icon-wrapper">
        {isLoading ? (
          <FontAwesomeIcon icon="fa-sharp fa-solid fa-spinner-third" spin />
        ) : (
          <FontAwesomeIcon icon={icon} />
        )}
      </div>
    </div>
  );
}
