import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  loadRequestsMadeByMeGrouped,
  exportMyRequestData,
  loadRequestsGrouped,
} from '../../../actions/requestActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';

import {
  CommaNumber_NoDecimal,
  Date_AsString,
  WorkflowWithJustification,
} from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import { RequestStatusLabel } from './RequestStatusLabel';
import useTableSettings from '../../../hooks/useTableSettings';
import GameLink from '../../common/links/GameLink';
import UserLink from '../../common/links/UserLink';
import requestStatusNames from '../../../constants/requestStatusNames';
import PlatformLink from '../../common/links/PlatformLink';
import { useTranslation } from 'react-i18next';
function RequestsMadeByMeGroupedList({
  location,
  reload,
  currentUser,
  settingsKey,
  defaultPageSize,
  tableViewSwitcher,
  allRequests,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'reference',
    'games',
    'platform',
    'numberOfKeys',
    'keyWorkflowName',
    'requestedByFullName',
    'requestedDate',
    'currentUserProfileGroupName',
    'requestStatus',
  ];
  const defaultVisibleColumns = allColumns;

  const [requests, setRequests] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      settingsKey || 'requestsmadebymelist-2.13-settings',
      getTableSettingsDefaults({
        defaultSort: 'reference',
        direction: 'desc',
        defaultVisibleColumns,
      }),
      null,
      allRequests ? loadRequestsGrouped : loadRequestsMadeByMeGrouped,
      setRequests,
      reload,
      t('Failed to load requests'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'reference',
      Label: '#',
      width: '5%',
      className: 'no-wrap',
      Render: (row) => <Link to={'/request/' + row.id}>{row.reference}</Link>,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'games',
      Label: t('Games'),
      width: '20%',
      Render: (row) =>
        row.games && row.games.length > 0
          ? row.games.map((x, i) => (
              <GameLink
                gameId={x.id}
                skuId={x.skuId}
                gameTitle={x.gameTitle}
                skuName={x.skuName}
                currentUser={currentUser}
                key={`${row.id},${x.skuId}`}
                className={i < row.games.length - 1 ? 'mb-4' : ''}
              />
            ))
          : '',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'platform',
      Label: t('Platform'),
      width: '20%',
      Render: (row) =>
        row.games && row.games.length > 0
          ? row.games.map((x, i) => (
              <PlatformLink
                id={x.platformId}
                name={x.platform}
                key={`${row.id},${x.platformId}`}
                className={i < row.games.length - 1 ? 'mb-4' : ''}
                currentUser={currentUser}
              />
            ))
          : '',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'numberOfKeys',
      Label: t('Keys'),
      width: '10%',
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.numberOfKeys),
    },
    {
      Key: 'keyWorkflowName',
      Label: t('Type'),
      width: '20%',
      Render: (row) => WorkflowWithJustification(row),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedByFullName',
      Label: t('Requested by'),
      width: '10%',
      Render: (row) => (
        <>
          <UserLink id={row.requestedById} name={row.requestedByFullName} />
          <br />
          {row.requestedByCompanyName}
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'currentUserProfileGroupName',
      Label: t('Waiting for'),
      width: '10%',
      Render: (row) =>
        row.currentUserProfileGroupNames &&
        row.currentUserProfileGroupNames.length > 0
          ? row.currentUserProfileGroupNames.map((x) => (
              <span key={x}>{x}</span>
            ))
          : '',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestStatus',
      Label: t('Status'),
      width: '15%',
      Render: (row) => (
        <RequestStatusLabel
          requestStatus={row.requestStatus}
          isWaitingForKeys={row.isWaitingForKeys}
          isDownloaded={row.downloaded}
        />
      ),
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">All</option>
            {requestStatusNames.map((x) => (
              <option key={x} value={x}>
                {t(x)}
              </option>
            ))}
          </select>
        ),
      },
    },
    {
      Key: 'requestedDate',
      Label: t('Request date'),
      width: '5%',
      Render: (row) => Date_AsString(row.requestedDate),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return requests?.data ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={requests.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={requests.totalRowCount}
        filteredRowCount={requests.filteredRowCount}
        scopes={requests.scopes}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        className="td-align-top"
        tableActionBarButtons={tableViewSwitcher}
        exportData={exportMyRequestData}
        location={location}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsMadeByMeGroupedList);
