import React from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import WorkflowsByApproversList from './components/WorkflowsByApproversList';

function WorkflowsByApprovers({ location, currentUser }) {
  return (
    <>
      <ActionBar></ActionBar>
      <WorkflowsByApproversList location={location} currentUser={currentUser} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowsByApprovers);
