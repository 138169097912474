import React from 'react';
import ImageWithFallback from '../../../common/ImageWithFallback';
import icons from '../../../../constants/icons';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function GameResult({ name, packshotUrl, appId }) {
  return (
    <>
      <ImageWithFallback
        url={packshotUrl}
        fallBackImage={
          <div className="game-img">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={icons.GAME} className="game" />
            </div>
          </div>
        }
        alt={name}
      ></ImageWithFallback>
      <div className="item-content">
        <span>{name}</span>
        <br />
        {appId}
      </div>
    </>
  );
}

GameResult.propTypes = {
  name: PropTypes.string.isRequired,
  packshotUrl: PropTypes.string,
  appId: PropTypes.string,
};

export default GameResult;
