import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../../../constants/icons';

function Company({ option }) {
  return (
    <>
      <div className="icon-wrapper companny">
        <FontAwesomeIcon icon={icons.COMPANY} />
      </div>
      <div className="item-content">
        <span>{option.title}</span>
      </div>
    </>
  );
}

Company.propTypes = {
  option: PropTypes.object,
};

export default Company;
