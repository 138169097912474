import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  CommaNumber_NoDecimal,
  CommaNumber_WithDecimal,
} from '../../../util/formatter';

import image_request_start from '../../../content/images/request_icons/request_start.svg';
import image_request_keys from '../../../content/images/request_icons/request_keys.svg';
import image_company from '../../../content/images/company.svg';
import image_sold_keys from '../../../content/images/sold-keys.svg';
import CompanyLink from '../../common/links/CompanyLink';
import ImageFromApi from '../../common/ImageFromApi';
import icons from '../../../constants/icons';
import WorkflowLink from '../../common/links/WorkflowLink';
import { useTranslation } from 'react-i18next';

function Stats({
  numberOfKeys,
  currentRequestValue,
  keyWorkflowId,
  keyWorkflowName,
  keyWorkflowColourHex,
  companyId,
  companyName,
  currentUser,
  companies,
}) {
  const [image, setImage] = useState();
  const [colour, setColour] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (companies) {
      setImage(companies.find((x) => x.id === companyId)?.imageId);
      setColour(companies.find((x) => x.id === companyId)?.colourHex);
    }
  }, [companies, companyId]);

  return (
    <div className="row">
      <div className="col-sm-3 space-bottom">
        <div className="panel kw-panel r-corners-sm-up  panel--highlights h-100">
          <div className="d-flex">
            <span className="workflow-icon">
              {image || colour ? (
                <ImageFromApi
                  imageId={image}
                  imageSize={32}
                  colourHex={colour}
                  fallbackIcon={icons.COMPANY}
                ></ImageFromApi>
              ) : (
                <img
                  src={image_company}
                  alt={t('Company')}
                  width="24"
                  height="24"
                />
              )}
            </span>
            <h2 className="bold no-margin">{t('Company')}</h2>
          </div>
          <div className="ml-42">
            <CompanyLink id={companyId} name={companyName} hideImage={true} />
          </div>
        </div>
      </div>
      <div className="col-sm-3 space-bottom">
        <div className="panel kw-panel r-corners-sm-up  panel--highlights h-100">
          <div className="d-flex">
            <span className="workflow-icon">
              <img
                src={image_request_keys}
                alt={t('Trophy')}
                width="24"
                height="24"
              />
            </span>
            <h2 className="bold no-margin">{t('Total keys requested')}</h2>
          </div>
          <div className="ml-42 num no-wrap">
            {CommaNumber_NoDecimal(numberOfKeys)}
          </div>
        </div>
      </div>
      <div className="col-sm-3 space-bottom">
        <div className="panel kw-panel r-corners-sm-up  panel--highlights h-100">
          <div className="d-flex">
            <span className="workflow-icon">
              <img
                src={image_sold_keys}
                alt={t('Trophy')}
                width="24"
                height="24"
              />
            </span>
            <h2 className=" bold no-margin">{t('Value of request')}</h2>
          </div>
          <div className="ml-42 num">
            {CommaNumber_WithDecimal(currentRequestValue)}
          </div>
        </div>
      </div>
      <div className="col-sm-3 space-bottom">
        <div className="panel kw-panel r-corners-sm-up  panel--highlights h-100">
          <div className="d-flex">
            <span className="workflow-icon">
              <img
                src={image_request_start}
                alt={t('Trophy')}
                width="24"
                height="24"
              />
            </span>
            <h2 className=" bold no-margin">{t('Request type')}</h2>
          </div>
          <div className="ml-42 text-overflow-ellipsis">
            <WorkflowLink
              id={keyWorkflowId}
              key={keyWorkflowId}
              name={keyWorkflowName}
              colour={keyWorkflowColourHex}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    companies: state.companies,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
