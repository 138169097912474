import React, { useEffect, useState, useRef } from 'react';
import { CommaNumber_NoDecimal } from '../../util/formatter';
import { withRouter, NavLink } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { StartPlasm, StopPlasm } from './plasm';
import { getCompanyLens } from '../../actions/lensActions';
import { useTranslation } from 'react-i18next';

function CompanyContent(props) {
  const ref = useRef();
  const [company, setCompany] = useState();
  const { t } = useTranslation();

  // Animation effect
  useEffect(() => {
    let plas = null;
    if (props.state.animate && company) {
      plas = StartPlasm(ref.current, props.id);
    } else if (plas != null) {
      StopPlasm(plas);
    }
    return function cleanup() {
      if (plas != null) {
        StopPlasm(plas);
      }
    };
  }, [props.state.animate, props.id, company]);

  // get the data and start the animation
  useEffect(() => {
    if (props.state.animate && props.id) {
      getCompanyLens(props.id)
        .then((d) => {
          setCompany(d);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [props.state.animate, props.id]);

  return company ? (
    <div className="tippy-content">
      <div
        className="ani-wrapper"
        ref={ref}
        style={{ height: '70px', width: '300px' }}
      ></div>
      <div className="ani-content">
        <div className="ani-content-inner">
          <div className="ani-details">
            <h1 className="solo">{company.companyName}</h1>
            <div>
              {t('Open requests:')}{' '}
              <span className="num">
                {CommaNumber_NoDecimal(company.openRequests)}
              </span>
            </div>
            <div>
              {t('Complete requests:')}{' '}
              <span className="num">
                {CommaNumber_NoDecimal(company.completeRequests)}
              </span>
            </div>
            <div>
              {t('Stock keys:')}{' '}
              <span className="num">
                {CommaNumber_NoDecimal(company.keysAllocated)}
              </span>
            </div>
          </div>
        </div>
        <div className="ani-btns">
          <NavLink to={`/company/${props.id}`} className="btn btn-default">
            {t('View company')}
          </NavLink>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
}

function CompanyPopup(props) {
  const [state, setState] = useState({ animate: false });
  const onMount = () => setState({ ...state, animate: true });
  const onHide = () => setState({ ...state, animate: false });

  //https:github.com/atomiks/tippyjs-react
  return (
    <Tippy
      interactive={true}
      interactiveBorder={20}
      delay={300}
      arrow={true}
      content={<CompanyContent id={props.id} state={state} />}
      onMount={onMount}
      onHide={onHide}
      appendTo={document.body}
    >
      {props.children}
    </Tippy>
  );
}

export default withRouter(CompanyPopup);
