import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import KwPanel from '../../../common/KwPanel';
import { cellClick } from '../../../../util/table';
import { FriendlyTimeFromMinutes } from '../../../../util/formatter';

import image_no_content_steps from '../../../../content/images/no-content_steps.svg';
import image_aurora from '../../../../content/images/AURORA_LOGO_32_01_LANDSCAPE.svg';

import image_request_inprogress from '../../../../content/images/request_icons/request_inprogress.svg';
import YesNoLabel from '../../../common/YesNoLabel';
import Button from '../../../common/Button';
import { useTranslation } from 'react-i18next';

const WorkflowTargets = ({
  steps,
  workflowSLAMinutes,
  workflowEnableSLAReminder,
  onEdit,
  isAuroraWorkflow,
}) => {
  const { t } = useTranslation();
  let totalMinutes = (s) =>
    (s
      ? s
          .map((step) => step.slaMinutes)
          .reduce((prev, next) => +prev + +next, 0) ?? 0
      : 0) + (workflowSLAMinutes ?? 0);

  return (
    <KwPanel className="mb-4">
      <header className="mb-2">
        <h2 className="no-margin">
          <span className="workflow-icon">
            <img
              src={image_request_inprogress}
              alt={t('Hourglass icon')}
              width="32"
              height="32"
            />
          </span>
          {t('Workflow targets')}
        </h2>
        {!isAuroraWorkflow && (
          <div className="float-right">
            <Button
              onClick={onEdit}
              className="btn btn-default"
              text={t('Edit')}
            />
          </div>
        )}
      </header>
      {!isAuroraWorkflow && steps ? (
        <table className="table dataTable width-100">
          <thead>
            <tr>
              <th>{t('Stage')}</th>
              <th>{t('Enable reminders')}</th>
              <th>{t('SLA')}</th>
            </tr>
          </thead>
          <tbody>
            {steps.map((step) => {
              return (
                <tr key={step.id}>
                  <td onClick={cellClick}>
                    <NavLink
                      to={`/settings/user-profile-group/${step.userProfileGroupId}`}
                    >
                      {step.groupName}
                    </NavLink>
                  </td>
                  <td onClick={cellClick}>
                    <YesNoLabel value={step.enableSLAReminder} />
                  </td>
                  <td onClick={cellClick} className="num">
                    {step.slaMinutes && step.slaMinutes > 0
                      ? FriendlyTimeFromMinutes(step.slaMinutes)
                      : '---'}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td onClick={cellClick}>{t('Key upload')}</td>
              <td onClick={cellClick}>
                <YesNoLabel value={workflowEnableSLAReminder} />
              </td>
              <td onClick={cellClick} className="num">
                {workflowSLAMinutes && workflowSLAMinutes > 0
                  ? FriendlyTimeFromMinutes(workflowSLAMinutes)
                  : '---'}
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right">{t('Total:')}</td>
              <td className="num">
                {FriendlyTimeFromMinutes(totalMinutes(steps))}
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div
          className="alert alert-info text-center"
          style={{ padding: '30px 15px' }}
        >
          {isAuroraWorkflow ? (
            <>
              <img
                src={image_aurora}
                alt={t('Aurora logo')}
                height={20}
                className="mb-4"
              />
              <p>
                {t(
                  'Aurora™ workflows dont need any SLAs as they are immediately fulfilled from pools'
                )}
              </p>
            </>
          ) : (
            <>
              <img
                src={image_no_content_steps}
                alt={t('No steps')}
                className="mb-2"
              />
              <p>
                {t(
                  'There are no steps defined for this workflow. If no steps are defined, you can not set any SLAs.'
                )}
              </p>
            </>
          )}
        </div>
      )}
    </KwPanel>
  );
};

WorkflowTargets.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      userProfileGroupId: PropTypes.string.isRequired,
      groupName: PropTypes.string.isRequired,
      enableSLAReminder: PropTypes.bool.isRequired,
      slaMinutes: PropTypes.number,
    }).isRequired
  ),
};

export default WorkflowTargets;
