import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  loadRequestsToBeApproved,
  exportApprovalRequestData,
} from '../../../actions/requestActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import {
  CommaNumber_NoDecimal,
  CommaNumber_WithDecimal,
  Date_AsString_OneLine,
  WorkflowWithJustification,
} from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import GameLink from '../../common/links/GameLink';
import SlideoutRequestDetail from '../slideout/RequestDetails';
import RequestDetailsSignatures from '../slideout/RequestDetailsSignatures';
import SlidingPane from 'react-sliding-pane';
import TimeLabel from '../../common/TimeLabel';
import icons from '../../../constants/icons';
import UserLink from '../../common/links/UserLink';
import CompanyLink from '../../common/links/CompanyLink';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';
import DeclineReason from '../slideout/DeclineReason';
function RequestsToBeApprovedList({
  location,
  history,
  reload,
  onApproveRequest,
  onDeclineRequest,
  currentUser,
  tableViewSwitcher,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'reference',
    'games',
    'requestedByCompanyName',
    'keyWorkflowName',
    'requestedByFullName',
    'requestedDate',
    'numberOfKeys',
    'currentRequestValue',
    'keyValue',
    'currentUserProfileGroupName',
    'assignedToUserProfileFullName',
  ];
  const suffixColumns = ['actions'];
  const defaultVisibleColumns = allColumns;

  const setRowData = (id, data) => {
    if (!requests) {
      return;
    }
    let newArr = [...requests.data];
    let index = newArr.findIndex((x) => x.id === id);
    newArr[index] = { ...newArr[index], ...data };
    setRequests({ ...requests, ...{ data: newArr } });
  };

  const [requests, setRequests] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'requeststobeapprovedlist-settings',
      getTableSettingsDefaults({
        defaultSort: 'reference',
        defaultVisibleColumns,
      }),
      null,
      loadRequestsToBeApproved,
      setRequests,
      reload,
      t('Failed to load requests'),
      location
    );

  // slideout panel
  const [requestDetailsSlideoutItem, setRequestDetailsSlideoutItem] =
    useState();
  const [requestDetailsSlideoutOpen, setRequestDetailsSlideoutOpen] =
    useState(false);
  const [declineReasonSlideoutOpen, setDeclineReasonSlideoutOpen] =
    useState(false);
  const [declineRequestId, setDeclineRequestId] = useState();

  const showWaitingFor = (e, row) => {
    e.cancelBubble = true;
    setRequestDetailsSlideoutItem(row);
    setRequestDetailsSlideoutOpen(true);
    return false;
  };

  const [signaturesSlideoutOpen, setSignaturesSlideoutOpen] = useState(false);
  const [signaturesSlideoutItem, setSignaturesSlideoutItem] = useState();
  let handleShowSignatures = (e, requestStep) => {
    setSignaturesSlideoutItem(requestStep);
    setSignaturesSlideoutOpen(true);
  };

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'reference',
      Label: '#',
      Render: (row) => (
        <Link to={'/request/' + row.requestGroupId}>{row.reference}</Link>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'games',
      Label: t('Games'),
      Render: (row) => (
        <GameLink
          gameId={row.gameId}
          skuId={row.skuId}
          gameTitle={row.gameTitle}
          skuName={row.skuName}
          currentUser={currentUser}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedByCompanyName',
      Label: t('Company'),
      Render: (row) => (
        <CompanyLink
          id={row.requestedByCompanyId}
          name={row.requestedByCompanyName}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyWorkflowName',
      Label: t('Type'),
      Render: (row) => WorkflowWithJustification(row),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedByFullName',
      Label: t('Requested by'),
      Render: (row) => (
        <>
          <UserLink id={row.requestedById} name={row.requestedByFullName} />
          <br />
          {row.requestedByCompanyName}
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'numberOfKeys',
      Label: t('Keys'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.numberOfKeys),
    },
    {
      Key: 'currentRequestValue',
      Label: t('Value'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_WithDecimal(row.currentRequestValue),
    },
    {
      Key: 'keyValue',
      Label: t('Request value'),
      className: 'text-right num no-wrap',
      Render: (row) =>
        !row.processed && row.currentUserCanSign ? (
          <input
            disabled={row.processing}
            type="text"
            value={CommaNumber_WithDecimal(
              row.valueToSubmit || row.currentRequestValue
            )}
            className={`form-control input-sm num text-right no-navigate ${
              row.valueToSubmit ? 'edited' : ''
            }`}
            style={{ width: '120px' }}
            onChange={(e) => {
              e.cancelBubble = true;
              setRowData(row.id, { valueToSubmit: e.target.value });
            }}
          />
        ) : (
          CommaNumber_WithDecimal(row.currentRequestValue)
        ),
    },
    {
      Key: 'currentUserProfileGroupName',
      Label: t('Waiting for'),
      Render: (row) =>
        row.currentUserProfileGroupName && (
          <>
            <div className="a" onClick={(e) => showWaitingFor(e, row)}>
              {row.currentUserProfileGroupName}
            </div>
            {/* {row.currentStepElapsedMinutesFormatted && (
              <div className="mt-2 mb-3">
                <TimeLabel
                  elapsedMinutesFormatted={
                    row.currentStepElapsedMinutesFormatted
                  }
                  breached={row.isSLABreached}
                  title="Time at current step"
                />
              </div>
            )} */}
          </>
        ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'assignedToUserProfileFullName',
      Label: t('Assigned to'),
      Render: (row) =>
        row.assignedToUserProfileId ? (
          <UserLink
            id={row.assignedToUserProfileId}
            name={row.assignedToUserProfileFullName}
          />
        ) : (
          <span className="unassigned">{t('Unassigned')}</span>
        ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'actions',
      className: 'text-right no-wrap no-navigate',
      Label: '',
      Render: (row) =>
        !row.processed && (
          <>
            <Button
              isDisabled={row.processing}
              title={row.processing ? t('Processing') : t('Approve')}
              className={`btn btn-sm btn-default btn--approval me-3 no-navigate ${
                row.approveProcessing ? 'approve processing' : ''
              }`}
              onClick={() => {
                setRowData(row.id, {
                  processing: true,
                  approveProcessing: true,
                  declineProcessing: false,
                });
                onApproveRequest(row.id, null, row.valueToSubmit, (success) => {
                  setRowData(row.id, {
                    processed: success,
                    processing: false,
                    approveProcessing: false,
                    declineProcessing: false,
                  });
                });
              }}
              icon={icons.APPROVE}
              hideTitle={true}
              text={t('Approve')}
            />
            <Button
              isDisabled={row.processing}
              title={row.processing ? t('Processing') : t('Decline')}
              className={`btn btn-sm btn-default btn--decline no-navigate ${
                row.declineProcessing ? 'decline processing' : ''
              }`}
              onClick={() => {
                setRowData(row.id, {
                  processing: true,
                  approveProcessing: false,
                  declineProcessing: true,
                });
                setDeclineReasonSlideoutOpen(true);
                setDeclineRequestId(row.id);
              }}
              icon={icons.DECLINE}
              hideTitle={true}
              text={t('Decline')}
            />
          </>
        ),
    },
    {
      Key: 'requestedDate',
      width: '10%',
      Label: t('Request date'),
      Render: (row) => (
        <>
          <div>{Date_AsString_OneLine(row.requestedDate)}</div>

          <TimeLabel
            elapsedMinutesFormatted={row.elapsedMinutesFormatted}
            breached={row.isSLABreached}
            title={t('Time between request and completion')}
            ongoing={row.requestCompletionDate ? false : true}
          />
        </>
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return requests && requests.data ? (
    <>
      <KwPanel className="mb-4">
        <Table
          columns={columns}
          suffixColumns={suffixColumnsWithConfig}
          allColumns={allColumns}
          rows={requests.data.map((x) => {
            return { Key: x.id, ...x };
          })}
          totalRowCount={requests.totalRowCount}
          filteredRowCount={requests.filteredRowCount}
          scopes={requests.scopes}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          isLoading={isLoading}
          filters={filters}
          filterChange={filterChange}
          className=""
          tableActionBarButtons={tableViewSwitcher}
          exportData={exportApprovalRequestData}
        />
      </KwPanel>

      <SlidingPane
        isOpen={requestDetailsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setRequestDetailsSlideoutOpen(false)}
      >
        <>
          {requestDetailsSlideoutItem && (
            <SlideoutRequestDetail
              done={(d) => {
                setRequestDetailsSlideoutOpen(false);
              }}
              cancel={() => setRequestDetailsSlideoutOpen(false)}
              history={history}
              requestGroupId={requestDetailsSlideoutItem.requestGroupId}
              requestId={requestDetailsSlideoutItem.id}
              onShowSignatures={handleShowSignatures}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={signaturesSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setSignaturesSlideoutOpen(false)}
      >
        <>
          {signaturesSlideoutItem && (
            <RequestDetailsSignatures
              done={(d) => {
                setSignaturesSlideoutOpen(false);
              }}
              cancel={() => setSignaturesSlideoutOpen(false)}
              requestStep={signaturesSlideoutItem}
            />
          )}
        </>
      </SlidingPane>
      <SlidingPane
        isOpen={declineReasonSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDeclineReasonSlideoutOpen(false)}
      >
        <DeclineReason
          done={() => {
            setDeclineReasonSlideoutOpen(false);
            setRowData(declineRequestId, {
              processed: false,
              processing: false,
              approveProcessing: false,
              declineProcessing: false,
            });
            setDeclineRequestId(null);
          }}
          cancel={() => {
            setDeclineReasonSlideoutOpen(false);
            setRowData(declineRequestId, {
              processed: false,
              processing: false,
              approveProcessing: false,
              declineProcessing: false,
            });
            setDeclineRequestId(null);
          }}
          onSubmit={(data) => {
            onDeclineRequest(declineRequestId, data.reason, (success) => {
              setRowData(declineRequestId, {
                processed: success,
                processing: false,
                approveProcessing: false,
                declineProcessing: false,
              });
              setDeclineRequestId(null);
              setDeclineReasonSlideoutOpen(false);
            });
          }}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

RequestsToBeApprovedList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  reload: PropTypes.any,
  onApproveRequest: PropTypes.func.isRequired,
  onDeclineRequest: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsToBeApprovedList);

/**/
