import actionTypes from '../constants/actionTypes';
export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.LOAD_GAMES:
      return action.games;
    case actionTypes.UPDATE_GAME:
      return state
        ? state.map((game) => (game.id === action.game.id ? action.game : game))
        : state;
    case actionTypes.CREATE_GAME:
      return state ? [...state, action.game] : state;
    default:
      return state;
  }
}
