import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';

import ActionBar from '../layout/ActionBar';

import {
  savePromotion,
  getPromotionById,
  getAudit,
  addAudit,
} from '../../actions/promotionActions';
import { getKeyProviders } from '../../actions/keyProviderActions';
import { getAllTerritories } from '../../actions/territoryActions';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Loading from '../common/Loading';
import Delete from './slideout/Delete';
import AuditButton from '../audit/components/AuditButton';
import ResponsiveActionBarButtons from '../common/ResponsiveActionBarButtons';
import PromotionForm from './components/PromotionForm';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

const ManagePromotion = ({
  location,
  id,
  keyProviders,
  getKeyProviders,
  territories,
  getAllTerritories,
  history,
  currentUser,
}) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = useForm();
  const [reload] = useState();

  const onSubmit = (data) => {
    // clean the new flag off the object, this only affects the mockdb
    savePromotion({
      ...promotion,
      ...data,
    }).then(() => {
      toast.success(t('Promotion saved'));
    });
  };

  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);
  const handleDelete = (e) => {
    setDeleteSlideoutOpen(true);
    return false;
  };

  const [promotion, setPromotion] = useState();

  useEffect(() => {
    if (id) {
      // get the promotion
      getPromotionById(id)
        .then((d) => {
          setPromotion(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/promotions');
          toast.error(t('Promotion not found'));
        });
    } else {
      setPromotion({ id: '' });
    }
    if (!keyProviders) {
      getKeyProviders();
    }
    if (!territories) {
      getAllTerritories();
    }
  }, [
    id,
    keyProviders,
    getKeyProviders,
    territories,
    getAllTerritories,
    history,
  ]);

  return promotion && keyProviders && territories ? (
    <>
      <ActionBar
        breadcrumb={[
          {
            link: '/promotions',
            label: t('Promotion calendar'),
          },
          id
            ? {
                link: `/promotion/${id}`,
                label: promotion.name,
              }
            : {
                label: t('Promition add'),
              },
        ]}
      >
        <div className="d-flex w-100 justify-content-between">
          {id && (
            <AuditButton id={id} getAudit={getAudit} addAudit={addAudit} />
          )}
          <div className="d-flex">
            <ResponsiveActionBarButtons
              buttons={[
                id && (
                  <Button
                    className="btn btn-danger ms-2 d-none d-md-inline-flex"
                    onClick={handleDelete}
                    text={t('Delete')}
                  />
                ),
                <Button
                  key="save"
                  text={t('Save')}
                  className="btn btn-primary ms-2"
                  onClick={handleSubmit(onSubmit)}
                />,
              ]}
            />
          </div>
        </div>
      </ActionBar>

      <div className="row">
        <div className="col-sm-12 col-md-12">
          <PromotionForm
            promotion={promotion}
            keyProviders={keyProviders}
            territories={territories}
            register={register}
            errors={errors}
            control={control}
            getValues={getValues}
          ></PromotionForm>
        </div>
      </div>

      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          done={(d) => {
            setDeleteSlideoutOpen(false);
            history.push('/promotions/');
          }}
          cancel={() => setDeleteSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    keyProviders: state.keyProviders,
    territories: state.territories,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  getKeyProviders,
  getAllTerritories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePromotion);
