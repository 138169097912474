import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  loadAccountDetails,
  exportAccountData,
} from '../../../actions/accountDetailActions';
import {
  Table,
  getTableSettingsDefaults,
  SimpleTextFilter,
} from '../../common/Table';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import KeyProviderLink from '../../common/links/KeyProviderLink';
import { useTranslation } from 'react-i18next';

function AccountsList({
  currentUser,
  location,
  reload,
  setBreadcrumb,
  tableViewSwitcher,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'login',
    'name',
    'emailAddress',
    'keyProvider',
    'keysAllocated',
    'keysDownloaded',
    'keysDisabled',
  ];
  const defaultVisibleColumns = allColumns;
  const [accountDetails, setAccountDetails] = useState();
  const params = new URLSearchParams(location.search);

  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'accountDetails-settings',
      getTableSettingsDefaults({
        defaultSort: 'login',
        direction: 'desc',
        defaultVisibleColumns,
      }),
      null,
      loadAccountDetails,
      setAccountDetails,
      reload,
      t('Failed to load account details'),
      location
    );
  // The base config of the columns, this will be filtered based on the visible (above)
  const columnsConfig = [
    {
      Key: 'login',
      Label: t('Login'),
      width: '20%',
      Render: (item) => (
        <NavLink to={`/keys/account/${item.id}`}>{item.login}</NavLink>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'name',
      Label: t('Name'),
      width: '20%',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'emailAddress',
      Label: t('Email address'),
      width: '20%',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyProvider',
      Label: t('Key provider'),
      width: '19%',
      Render: (item) => (
        <KeyProviderLink id={item.keyProviderId} name={item.keyProvider} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keysAllocated',
      Label: t('Keys allocated'),
      className: 'text-right num no-wrap',
      width: '7%',
      Render: (item) =>
        item.keysAllocated >= 0
          ? CommaNumber_NoDecimal(item.keysAllocated)
          : '--',
    },
    {
      Key: 'keysDownloaded',
      Label: t('Keys downloaded'),
      className: 'text-right num no-wrap',
      width: '7%',
      Render: (item) =>
        item.keysDownloaded >= 0
          ? CommaNumber_NoDecimal(item.keysDownloaded)
          : '--',
    },
    {
      Key: 'keysDisabled',
      Label: t('Keys disabled'),
      className: 'text-right num no-wrap',
      width: '7%',
      Render: (item) =>
        item.keysDisabled >= 0
          ? CommaNumber_NoDecimal(item.keysDisabled)
          : '--',
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  // return an alaska table
  return accountDetails ? (
    <KwPanel className="mb-4">
      <header>
        <h2 className="mt-3 mb-2">{t('Accounts - Grouped')}</h2>
      </header>
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={accountDetails.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={accountDetails.totalRowCount}
        filteredRowCount={accountDetails.filteredRowCount}
        scopes={accountDetails.scopes}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportAccountData}
        location={location}
        setBreadcrumb={setBreadcrumb}
        tableActionBarButtons={tableViewSwitcher}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default AccountsList;
