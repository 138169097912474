import React, { useState } from 'react';
import {
  loadAccountDetailKeyRequestsForAccount,
  exportAccountDetailKeyRequestsForAccount,
} from '../../../actions/accountDetailActions';
import {
  Table,
  getTableSettingsDefaults,
  SimpleTextFilter,
} from '../../common/Table';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import { Link } from 'react-router-dom';
import ImageWithFallback from '../../common/ImageWithFallback';
import { Date_AsString } from '../../../util/formatter';
import icons from '../../../constants/icons';
import Checkbox from '../../common/Checkbox';
import KebabMenu from '../../common/KebabMenu';
import Button from '../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function AccountDetailKeyRequestsList({
  id,
  currentUser,
  location,
  reload,
  itemsChecked,
  setItemsChecked,
  onDisableKeys,
}) {
  const allColumns = [
    'game',
    'sku',
    'keyRequestReference',
    'emailSentDate',
    'keyCodeDownloadedDate',
  ];
  const suffixColumns = ['buttons-checkbox'];
  const defaultVisibleColumns = allColumns;
  const [accountDetails, setAccountDetails] = useState();
  const params = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'accountDetails-keyrequests-settings',
      getTableSettingsDefaults({
        defaultSort: 'game',
        direction: 'desc',
        defaultVisibleColumns,
      }),
      { id },
      loadAccountDetailKeyRequestsForAccount,
      setAccountDetails,
      reload,
      t('Failed to load account details'),
      location
    );
  // The base config of the columns, this will be filtered based on the visible (above)
  const columnsConfig = [
    {
      Key: 'game',
      Label: t('Game'),
      width: '40%',
      className: 'flush',
      Render: (item) => (
        <div className="game-img-wrapper">
          <ImageWithFallback
            url={item.packshotUrl}
            fallBackImage={
              <div className="game-img">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={icons.GAME} className={`game`} />
                </div>
              </div>
            }
            alt={item.name}
          ></ImageWithFallback>
          <Link to={'/game/' + item.gameId}>{item.game}</Link>
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'sku',
      Label: t('SKU'),
      width: '20%',
      Render: (item) => <Link to={'/sku/' + item.skuId}>{item.sku}</Link>,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyRequestReference',
      Label: t('Key Request'),
      width: '20%',
      Render: (item) => (
        <Link to={'/request/' + item.keyRequestGroupId}>
          {item.keyRequestReference}
        </Link>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'emailSentDate',
      Label: t('Sent Date'),
      width: '20%',
      Render: (item) => Date_AsString(item.emailSentDate),
    },
    {
      Key: 'keyCodeDownloadedDate',
      Label: t('Downloaded Date'),
      width: '20%',
      Render: (item) => Date_AsString(item.keyCodeDownloadedDate),
    },
    {
      Key: 'buttons-checkbox',
      Label: '',
      className: 'text-right no-navigate',
      Sort: false,
      width: '2%',
      Filter: {
        Render: (col) => (
          <Checkbox
            disabled={!accountDetails}
            checked={false}
            onChange={(checked) =>
              checked
                ? setItemsChecked(accountDetails?.data)
                : setItemsChecked([])
            }
          ></Checkbox>
        ),
      },
      Render: (item) => (
        <div className="d-flex align-items-center justify-content-end">
          <KebabMenu
            take={1}
            className="inline-icons"
            buttons={[
              <Button
                key={item.id + 'disable'}
                className="btn btn-danger btn-xs-full me-2"
                dropdownclasses="danger"
                onClick={(e) => onDisableKeys([item])}
                icon={icons.DISABLE_KEYS}
                text={t('Disable keys')}
              />,
            ]}
          ></KebabMenu>
          <Checkbox
            className="ms-3"
            checked={itemsChecked && itemsChecked.some((x) => x.id === item.id)}
            onChange={(checked) =>
              checked
                ? setItemsChecked([...itemsChecked, item])
                : setItemsChecked(itemsChecked.filter((x) => x.id !== item.id))
            }
          ></Checkbox>
        </div>
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return accountDetails ? (
    <KwPanel className="mb-4">
      <header>
        <h2>{t('Key pools')}</h2>
        {accountDetails.data.length > 0 && (
          <Button
            className="btn btn-danger btn-xs-full space-bottom-xs-s me-2"
            onClick={() => onDisableKeys(itemsChecked)}
            icon={icons.DISABLE_KEYS}
            text={t('Disable selected keys')}
            isDisabled={itemsChecked.length === 0}
          />
        )}
      </header>
      <Table
        columns={columns}
        suffixColumns={suffixColumnsWithConfig}
        allColumns={allColumns}
        rows={accountDetails.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={accountDetails.totalRowCount}
        filteredRowCount={accountDetails.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={(filters, scopes) =>
          exportAccountDetailKeyRequestsForAccount(id, filters, scopes)
        }
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default AccountDetailKeyRequestsList;
