import React, { useState } from 'react';
import {
  loadDisableReasons,
  exportDisableReasonsData,
} from '../../../../actions/keyCodeActions';
import { Table, getTableSettingsDefaults } from '../../../common/Table';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import disableReasonsColumnsConfig from './DisableReasonsColumnsConfig';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function DisableReasonList({ location, reload }) {
  const { t } = useTranslation();
  const allColumns = ['reason'];
  const defaultVisibleColumns = allColumns;
  const [disableReasons, setDisableReasons] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'disablereasonlist-settings',
      getTableSettingsDefaults({
        defaultSort: 'reason',
        defaultVisibleColumns,
      }),
      null,
      loadDisableReasons,
      setDisableReasons,
      reload,
      t('Failed to load disabled reasons'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  const columnsConfig = disableReasonsColumnsConfig({ params, filterChange });

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return disableReasons ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={disableReasons.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={disableReasons.totalRowCount}
        filteredRowCount={disableReasons.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportDisableReasonsData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

DisableReasonList.propTypes = {
  location: PropTypes.object.isRequired,
  reload: PropTypes.any,
};
export default DisableReasonList;
