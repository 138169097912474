import {
  apiUrl,
  getList,
  getSingleRecord,
  saveRecord,
  deleteRecord,
  getAll,
  getAuditData,
  postAction,
} from './apiUtils';
const baseUrl = apiUrl + '/promotions/';

export function getAllPromotions() {
  return getAll(baseUrl);
}

export function getPromotionsByDate(
  from,
  to,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    baseUrl + `${from}/${to}/`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getPromotions(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getPromotionById(id) {
  return getSingleRecord(baseUrl, id);
}

export function savePromotion(promotion) {
  return saveRecord(baseUrl, promotion);
}

export function deletePromotion(id) {
  return deleteRecord(baseUrl, id);
}

export function getAudit(id) {
  return getAuditData(baseUrl, id);
}

export function addAudit(id, comment) {
  return postAction(`${baseUrl}${id}/audit`, { comment });
}
