import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import icons from '../../constants/icons';

const YesNoLabel = ({
  value,
  textIfTrue,
  textIfFalse,
  hideIcon,
  emptyOnNo,
}) => {
  const { t } = useTranslation();
  return value ? (
    <>
      <FontAwesomeIcon
        icon={icons.YES}
        fixedWidth
        className="me-2 c-positive"
      />
      {textIfTrue || t('Yes')}
    </>
  ) : emptyOnNo ? (
    ''
  ) : (
    <>
      <FontAwesomeIcon icon={icons.NO} fixedWidth className="me-2 c-negative" />
      {textIfFalse || t('No')}
    </>
  );
};
export default YesNoLabel;
