import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ReportTableViewSwitcher({ groupBy, setGroupBy }) {
  const { t } = useTranslation();
  return (
    <>
      <button
        className={`btn btn-${
          groupBy === 'month' ? 'primary' : 'default'
        } btn-outline r-corners-left`}
        onClick={(e) => {
          setGroupBy('month');
        }}
        title={t('Group by month')}
      >
        {t('Month')}
      </button>
      <button
        className={`btn btn-${
          groupBy === 'week' ? 'primary' : 'default'
        } btn-outline r-corners-right`}
        onClick={(e) => {
          setGroupBy('week');
        }}
        title={t('Group by week')}
      >
        {t('Week')}
      </button>
    </>
  );
}

ReportTableViewSwitcher.propTypes = {
  groupBy: PropTypes.string.isRequired,
  setGroupBy: PropTypes.func.isRequired,
};

export default ReportTableViewSwitcher;
