import * as platformApi from '../api/platformApi';
import actionTypes from '../constants/actionTypes';

export function savePlatform(platform) {
  return function (dispatch) {
    return platformApi.savePlatform(platform).then((savedPlatform) => {
      dispatch({
        type: platform.id
          ? actionTypes.UPDATE_PLATFORM
          : actionTypes.CREATE_PLATFORM,
        platform: { ...platform, id: savedPlatform.id, file: null },
      });
    });
  };
}

export function getPlatformById(id) {
  return platformApi.getPlatformById(id);
}

export function loadPlatforms({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return platformApi.getPlatforms(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getStats(id) {
  return platformApi.getStats(id);
}

export function getStatsByDay(id) {
  return platformApi.getStatsByDay(id);
}

export function getAggregateStats(id, from, to) {
  return platformApi.getAggregateStats(id, from, to);
}

export function getAudit(id) {
  return platformApi.getAudit(id);
}

export function addAudit(id, comment) {
  return platformApi.addAudit(id, comment);
}

export function getPlatforms() {
  return function (dispatch) {
    return platformApi.getAllPlatforms().then((platforms) => {
      dispatch({
        type: actionTypes.LOAD_PLATFORMS,
        platforms,
      });
    });
  };
}

export function deletePlatform(id) {
  return function (dispatch) {
    return platformApi.deletePlatform(id).then(() => {
      dispatch({
        type: actionTypes.DELETE_PLATFORM,
        id,
      });
    });
  };
}

export function getKeyProvidersForPlatform(id) {
  return platformApi.getKeyProvidersForPlatform(id);
}
export function savePlatformKeyProvider(platformId, keyProviderId) {
  return platformApi.savePlatformKeyProvider(platformId, keyProviderId);
}

export function deletePlatformKeyProvider(platformId, keyProviderId) {
  return platformApi.deletePlatformKeyProvider(platformId, keyProviderId);
}

export function exportPlatformData(filters, scopes) {
  return platformApi.exportPlatformData(filters, scopes);
}
