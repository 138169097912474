import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import icons from '../../constants/icons';

const TimeLabel = ({ elapsedMinutesFormatted, breached, title, ongoing }) => (
  <span
    className={`elapsed-time-label ${breached ? 'breached' : ''}`}
    title={title}
  >
    <span className="icon-wrapper">
      {breached ? (
        <FontAwesomeIcon icon={icons.SLA_BREACHED} />
      ) : (
        <FontAwesomeIcon icon={icons.SLA} />
      )}
    </span>
    {elapsedMinutesFormatted}
    {ongoing && (
      <FontAwesomeIcon
        icon={icons.CHEVRON_RIGHT}
        fade={true}
        size="2xs"
        className="ms-2"
      />
    )}
  </span>
);

TimeLabel.propTypes = {
  elapsedMinutesFormatted: PropTypes.string.isRequired,
  breached: PropTypes.bool,
  title: PropTypes.string,
  ongoing: PropTypes.bool,
};

export default TimeLabel;
