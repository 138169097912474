import React from 'react';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import icons from '../../constants/icons';
import PropTypes from 'prop-types';

const Help = ({ helpContent, size, className }) => {
  return (
    <>
      {helpContent && (
        <Tippy content={helpContent} interactive={true}>
          <FontAwesomeIcon
            icon={icons.INFO}
            className={className}
            size={size}
          />
        </Tippy>
      )}
    </>
  );
};

Help.propTypes = {
  helpContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]),
  className: PropTypes.string,
  size: PropTypes.string,
};

export default Help;
