import React from 'react';
import { cellClick } from '../../../util/table';

export default function TableCell({
  sortedField,
  col,
  row,
  rows,
  totalRowCount,
}) {
  let getTRSortClassNames = (col) => {
    if ((col.Sort ?? true) && sortedField) {
      if (col.Key === sortedField.key) {
        return `sortable ${sortedField.direction ? 'sorted' : ''} ${
          col.className || ''
        }`;
      }
      return `sortable ${col.className || ''}`;
    }
    return col.className || '';
  };

  let renderColInner = (col, row, rows, totalRowCount) =>
    col.Render !== undefined
      ? col.Render(row, rows, totalRowCount)
      : row[col.Key];

  let renderStyle = (col, row, rows, totalRowCount) =>
    col.tdStyle !== undefined ? col.tdStyle(row, rows, totalRowCount) : null;

  return (
    <td
      className={getTRSortClassNames(col)}
      onClick={cellClick}
      style={renderStyle(col, row, rows, totalRowCount)}
    >
      {renderColInner(col, row, rows, totalRowCount)}
    </td>
  );
}
