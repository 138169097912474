import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { addUserToGroup } from '../../../../actions/userProfileGroupActions';
import { toast } from 'react-toastify';
import FormGroup from '../../../common/FormGroup';
import Search from '../../users/components/Search';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';

function AddMember({ userProfileGroupId, done, cancel }) {
  const { t } = useTranslation();
  const [user, setUser] = useState();

  const { handleSubmit } = useForm();

  const onSubmit = (data) => {
    if (user) {
      addUserToGroup(userProfileGroupId, user.id)
        .then(() => {
          toast.success(t('User added to group'));
          done();
        })
        .catch(() => {
          toast.error(t('Failed to add user to group'));
        });
      return;
    }
    toast.error(t('Please required fields'));
  };

  const handleUserSearchChange = (d) => {
    if (d && d.length > 0) {
      setUser(d[0]);
    } else {
      setUser(undefined);
    }
  };

  return (
    <SlideoutLayout
      title={t('Add member')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="submit"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <FormGroup label={t('User')}>
        <Search autoFocus onChange={handleUserSearchChange}></Search>
      </FormGroup>
    </SlideoutLayout>
  );
}

AddMember.propTypes = {
  userProfileGroupId: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddMember);
