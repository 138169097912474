import React, { useEffect } from 'react';
import KwPanel from '../common/KwPanel';
import image_settings from '../../content/images/settings.svg';
import { useTranslation } from 'react-i18next';

export default function Settings({ setClasses }) {
  const { t } = useTranslation();
  useEffect(() => {
    setClasses('pb-4 flex-1');
  }, [setClasses]);
  return (
    <>
      <KwPanel className="panel-settings">
        <img src={image_settings} alt={t('Setting')} width="200" height="200" />
      </KwPanel>
    </>
  );
}
