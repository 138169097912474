export default [
  'Authorised',
  'Complete',
  'Declined',
  'Pending approval',
  'Pending keys',
  'Withdrawn',
  'Keys ordered',
  'On hold',
];
