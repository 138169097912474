import React, { useState, useEffect } from 'react';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import {
  saveGameTemplate,
  getGameTemplateById,
} from '../../../../actions/gameTemplateActions';
import { toast } from 'react-toastify';
import GameTemplateForm from '../components/GameTemplateForm';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';

function Edit({ done, id, cancel }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = (data) => {
    saveGameTemplate({ ...gameTemplate, ...data }).then(() => {
      toast.success(t('Game template saved'));
      done(gameTemplate);
    });
  };

  const [gameTemplate, setGameTemplate] = useState({});

  useEffect(() => {
    if (id) {
      getGameTemplateById(id)
        .then((d) => {
          setGameTemplate(d);
        })
        .catch(() => {
          toast.error(t('Failed to load game template'));
        });
    }
  }, [id]);

  return (
    <SlideoutLayout
      title={id ? t('Edit game template') : t('Add game template')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="save"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <GameTemplateForm
        gameTemplate={gameTemplate}
        register={register}
        errors={errors}
        control={control}
      ></GameTemplateForm>
    </SlideoutLayout>
  );
}

Edit.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default Edit;
