import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ViewLink({ result }) {
  const { t } = useTranslation();
  return result.skuId ? (
    <NavLink to={`/sku/${result.skuId}`} className="">
      {t('View')}
    </NavLink>
  ) : result.gameId ? (
    <NavLink to={`/game/${result.gameId}`} className="">
      {t('View')}
    </NavLink>
  ) : (
    ''
  );
}
