import React from 'react';
import ImageWithFallback from '../../../common/ImageWithFallback';
import icons from '../../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SKU({ option }) {
  const data = JSON.parse(option.data);
  return (
    <>
      <ImageWithFallback
        url={data.PackshotUrl}
        fallBackImage={
          <div className="game-img">
            <div className="icon-wrapper sku">
              <FontAwesomeIcon icon={icons.GAME} className="game" />
            </div>
          </div>
        }
        alt={option.title}
      ></ImageWithFallback>
      <div className="item-content">
        <span>{option.title}</span>
        <br />
        {data.PackageId}
      </div>
    </>
  );
}
export default SKU;
