import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FormatCamelCaseAsString } from '../../../util/formatter';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import icons from '../../../constants/icons';
import KwPanel from '../KwPanel';
import TableColumnSortableComponent from './TableColumnSortableComponent';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function TableColumnSlideout({
  done,
  id,
  cancel,
  visibleColumns,
  allColumns,
  defaultVisibleColumns,
}) {
  const { t } = useTranslation();
  const [columns, setColumns] = useState(
    visibleColumns.map((x, i) => {
      return { id: i, key: x };
    })
  );

  const [newItem, setNewItem] = useState();
  const handleAdd = () => {
    let _max = columns.reduce((sum, col) => sum + col.id, 0);
    let newVal = [...columns, { id: _max + 1, key: newItem }];
    setColumns(newVal);
  };

  const handleAddAll = () => {
    let _max = columns.reduce((sum, col) => sum + col.id, 0);
    let newVal = [...columns];
    allColumns.forEach((x) => {
      if (!isAlreadySelected(x)) {
        newVal.push({ id: _max + 1, key: x });
        _max++;
      }
    });
    setColumns(newVal);
  };

  const handleReset = () => {
    let _max = columns.reduce((sum, col) => sum + col.id, 0);
    setColumns(
      defaultVisibleColumns.map((x, i) => {
        return { id: _max + i, key: x };
      })
    );
  };

  const handleSubmit = () => {
    // validation etc
    if (
      columns.filter(
        (item, index) => columns.findIndex((x) => x.key === item.key) !== index
      ).length > 0
    ) {
      toast.error(t("You can't have the same column more than once"));
    } else {
      // then pass back to caller..
      done(columns.map((x) => x.key));
    }
  };

  const isAlreadySelected = (x) => {
    return columns.filter((c) => c.key === x).length > 0;
  };
  const alreadySelected = (x) => {
    return isAlreadySelected(x) ? t('Disabled') : '';
  };
  const alreadySelectedTitle = (x) => {
    return isAlreadySelected(x) ? t('Column already added (above)') : '';
  };
  const noneToSelect =
    allColumns.filter((x) => !isAlreadySelected(x)).length === 0;

  return (
    <SlideoutLayout
      title={t('Column options')}
      cancel={cancel}
      done={done}
      bar={
        <>
          <input
            type="submit"
            value={t('Reset')}
            title={t('Reset columns to default')}
            className="btn btn-default me-2"
            onClick={handleReset}
          />
          <input
            type="submit"
            value={t('Save')}
            title={t('Save your changes')}
            className="btn btn-primary"
            onClick={handleSubmit}
          />
        </>
      }
    >
      <KwPanel>
        <p className="alert alert-info">
          <span className="icon-wrapper me-3">
            <FontAwesomeIcon icon={icons.PERSONALISATION} />
          </span>
          {''}
          {t('Changes here are only visible to you')}
        </p>
        <div className="container-fluid g-0">
          <TableColumnSortableComponent
            items={columns}
            setItems={setColumns}
            allColumns={[...allColumns]}
          />
        </div>
        <div className="d-flex align-items-center select-filter mt-4">
          <FontAwesomeIcon
            className="ms-2 me-4"
            icon={icons.PLUS}
            style={{ opacity: 0.5 }}
          />
          <select
            className="form-control"
            style={{ display: 'inline-block', width: '50%' }}
            onChange={(e) => setNewItem(e.target.value)}
          >
            <option value="">{t('--- choose ---')}</option>
            {[...allColumns]
              .sort((a, b) => a.localeCompare(b))
              .map((x) => (
                <option
                  value={x}
                  key={x}
                  disabled={alreadySelected(x)}
                  title={alreadySelectedTitle(x)}
                >
                  {FormatCamelCaseAsString(x)}
                </option>
              ))}
          </select>
          <Button
            className="btn btn-default no-r-corners"
            onClick={handleAdd}
            isDisabled={!newItem}
            text={t('Add')}
          />
          <Button
            className="btn btn-default r-corners-right divider-left"
            onClick={handleAddAll}
            isDisabled={noneToSelect}
            text={t('Add all')}
          />
        </div>
      </KwPanel>
    </SlideoutLayout>
  );
}

TableColumnSlideout.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  visibleColumns: PropTypes.array.isRequired,
  allColumns: PropTypes.array.isRequired,
  defaultVisibleColumns: PropTypes.array.isRequired,
};

export default TableColumnSlideout;
