import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getKeyProviders } from '../../actions/keyProviderActions';
import KwPanel from '../common/KwPanel';
import ActionBar from '../layout/ActionBar';
import FormInput from '../common/FormInput';
import FormSelect from '../common/FormSelect';
import SearchCodeList from './components/SearchCodeList';
import SearchTagList from './components/SearchTagList';
import Loading from '../common/Loading';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import icons from '../../constants/icons';
function KeySearch({ location, getKeyProviders, keyProviders }) {
  const { t } = useTranslation();
  const [reload] = useState();
  const [searchTerms, setSearchTerms] = useState();
  const {
    register,
    formState: { errors },
  } = useForm();
  const [keyform, setKeyForm] = useLocalStorage('keys-settings', {
    keyProviderId: null,
    mode: '',
    code: '',
    tag: '',
  });

  // without this, the form doesnt behave first time - if there is no local storage yet.
  // see work item 1255
  useEffect(() => {
    if (keyProviders && keyform.keyProviderId == null) {
      setKeyForm({
        ...keyform,
        keyProviderId: keyProviders ? keyProviders[0].id : null,
      });
    }
  }, [keyform, setKeyForm, keyProviders]);

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [getKeyProviders, keyProviders]);

  const handleChange = ({ target }) => {
    setSearchTerms({
      ...keyform,
      mode: '',
    });
    setKeyForm({
      ...keyform,
      [target.name]: target.value,
    });
  };

  const searchKey = () => {
    setSearchTerms({
      ...keyform,
      mode: 'code',
    });
  };
  const searchTag = () => {
    setSearchTerms({
      ...keyform,
      mode: 'tag',
    });
  };

  return keyProviders ? (
    <>
      <ActionBar></ActionBar>
      <KwPanel className="pb-3 pt-1">
        <FormSelect
          register={register}
          name="keyProviderId"
          label={t('Key provider')}
          placeholder=""
          value={keyform.keyProviderId || ''}
          defaultValue={keyform.keyProviderId || ''}
          onChange={handleChange}
          error={errors.keyProviderId}
          autoFocus={true}
        >
          {keyProviders.map((x) => (
            <option key={x.id} value={x.id}>
              {x.name}
            </option>
          ))}
        </FormSelect>
        <FormInput
          register={register}
          name="code"
          label={t('Key')}
          className="monospace"
          placeholder="XXXX-XXXX-XXXX-XXXX"
          value={keyform.code || ''}
          defaultValue={keyform.code || ''}
          onChange={handleChange}
          error={errors.code}
          joinedButton={true}
          joinedButtonIcon={icons.SEARCH}
          joinedButtonClick={searchKey}
          joinedButtonDisabled={!keyform.code || searchTerms?.mode === 'code'}
        />
        <FormInput
          register={register}
          name="tag"
          label={t('Tag')}
          placeholder="XXX"
          value={keyform.tag || ''}
          defaultValue={keyform.tag || ''}
          onChange={handleChange}
          error={errors.tag}
          joinedButton={true}
          joinedButtonIcon={icons.SEARCH}
          joinedButtonClick={searchTag}
          joinedButtonDisabled={!keyform.tag || searchTerms?.mode === 'tag'}
        />
      </KwPanel>

      {searchTerms && searchTerms.mode === 'code' && (
        <SearchCodeList
          location={location}
          reload={reload}
          code={searchTerms.code}
          keyProviderId={searchTerms.keyProviderId}
        />
      )}

      {searchTerms && searchTerms.mode === 'tag' && (
        <SearchTagList
          location={location}
          reload={reload}
          tag={searchTerms.tag}
          keyProviderId={searchTerms.keyProviderId}
        />
      )}
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    keyProviders: state.keyProviders,
  };
}

const mapDispatchToProps = {
  getKeyProviders,
};

KeySearch.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(KeySearch);
