import * as keyPoolNotificationsApi from '../api/keyPoolNotificationsApi';

export function saveKeyPoolNotification(keyPoolNotifications) {
  return keyPoolNotificationsApi.saveKeyPoolNotification(keyPoolNotifications);
}

export function getKeyPoolNotificationById(id) {
  return keyPoolNotificationsApi.getKeyPoolNotificationById(id);
}

export function loadKeyPoolNotifications({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyPoolNotificationsApi.getKeyPoolNotifications(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function deleteKeyPoolNotification(id) {
  return keyPoolNotificationsApi.deleteKeyPoolNotification(id);
}

export function exportKeyPoolNotificationData(filters, scopes) {
  return keyPoolNotificationsApi.exportKeyPoolNotificationData(filters, scopes);
}
