import React, { useState } from 'react';
import {
  exportWorkflowsByApprovers,
  loadWorkflowsByApprovers,
} from '../../../actions/reportActions';
import Gravatar from 'react-gravatar';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import WorkflowLink from '../../common/links/WorkflowLink';
import UserLink from '../../common/links/UserLink';
import { useTranslation } from 'react-i18next';

function WorkflowByApproversList({ location, currentUser }) {
  const { t } = useTranslation();
  const allColumns = ['keyWorkflowName', 'fullName', 'companyName'];
  const defaultVisibleColumns = allColumns;
  const [reload] = useState();
  const [items, setItems] = useState();
  const params = new URLSearchParams(location.search);

  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'workflowsbyapproversreport-settings',
      getTableSettingsDefaults({
        defaultSort: 'keyWorkflowName',
        defaultVisibleColumns,
      }),
      null,
      loadWorkflowsByApprovers,
      setItems,
      reload,
      t('Failed to load report'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'keyWorkflowName',
      Label: t('Workflow name'),
      Render: (item) => (
        <WorkflowLink
          id={item.keyWorkflowId}
          name={item.keyWorkflowName}
          colour={item.keyWorkflowColourHex}
        ></WorkflowLink>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'fullName',
      Label: t('Name'),
      width: '30%',
      Render: (item) => (
        <div style={{ display: 'flex' }}>
          <Gravatar
            email={item?.emailAddress}
            size={20}
            rating="pg"
            default="retro"
            className="img-circle me-3"
          />
          <UserLink
            id={item.userProfileId}
            name={item.fullName}
            currentUser={currentUser.id}
            imageSize={32}
          />
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return items ? (
    <KwPanel className="mb-4">
      <Table
        className="table-v-stripes"
        columns={columns}
        allColumns={allColumns}
        scopes={items.scopes}
        rows={items.data.map((x, index) => {
          return {
            Key: `${x.keyWorkflowId}-${x.userProfileGroupId}-${x.userProfileId}`,
            ...x,
          };
        })}
        totalRowCount={items.totalRowCount}
        filteredRowCount={items.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportWorkflowsByApprovers}
        location={location}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default WorkflowByApproversList;
