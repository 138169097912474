import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';

import { saveWorkflowSteps } from '../../../../actions/workflowActions';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TargetsForm from '../components/TargetsForm';
import { saveWorkflow } from '../../../../api/workflowApi';
import { useTranslation } from 'react-i18next';

function EditTargets({ done, id, cancel, workflow, workflowSteps }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [updatedSteps, setUpdatedSteps] = useState(workflowSteps);
  const [keyUploadSLAMinutes, setKeyUploadSLAMinutes] = useState(
    workflow.keyUploadSLAMinutes
  );
  const [enableSLAReminder, setEnableSLAReminder] = useState(
    workflow.enableSLAReminder
  );

  const handleStepChange = (steps) => {
    setUpdatedSteps(steps);
  };
  const handleWorkflowSLAMinutesChange = (d) => {
    setKeyUploadSLAMinutes(d);
  };
  const handleWorkflowEnableSLAReminderChange = (d) => {
    setEnableSLAReminder(d);
  };

  const onSubmit = (data) => {
    if (updatedSteps && updatedSteps.length > 0) {
      saveWorkflowSteps(id, updatedSteps)
        .then((savedSteps) => {
          workflow = { ...workflow, keyUploadSLAMinutes, enableSLAReminder };
          saveWorkflow(workflow)
            .then(() => {
              toast.success(t('Workflow steps saved'));
              done({ savedSteps, workflow });
            })
            .catch(() => {
              toast.error(t('Failed to save workflow'));
            });
        })
        .catch(() => {
          toast.error(t('Failed to save workflow steps'));
        });
    } else {
      workflow = { ...workflow, keyUploadSLAMinutes, enableSLAReminder };
      saveWorkflow(workflow)
        .then(() => {
          toast.success(t('Workflow steps saved'));
          done({ savedSteps: [], workflow });
        })
        .catch(() => {
          toast.error(t('Failed to save workflow'));
        });
    }
  };

  return workflowSteps ? (
    <SlideoutLayout
      title={t('Workflow steps')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <TargetsForm
        register={register}
        errors={errors}
        control={control}
        workflowSteps={workflowSteps}
        keyWorkflowName={workflow.keyWorkflowName}
        workflowSLAMinutes={workflow.keyUploadSLAMinutes}
        workflowEnableSLAReminder={workflow.enableSLAReminder}
        onStepChange={handleStepChange}
        onWorkflowSLAMinutesChange={handleWorkflowSLAMinutesChange}
        onWorkflowEnableSLAReminderChange={
          handleWorkflowEnableSLAReminderChange
        }
      />
    </SlideoutLayout>
  ) : (
    ''
  );
}

EditTargets.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditTargets);
