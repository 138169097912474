import React from 'react';
import { useTranslation } from 'react-i18next';
import image_upload_error from '../../../content/images/upload-error.svg';

export default function ErrorOccured() {
  const { t } = useTranslation();
  return (
    <div className="error">
      <img
        className="publisher-img"
        src={image_upload_error}
        alt={t('An error occured')}
      />
      <p>{t('Failed to get key code, please try again later')}</p>
    </div>
  );
}
