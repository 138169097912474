import React from 'react';

import image_auroraLogo from '../../content/images/AURORA_LOGO_32_01_LANDSCAPE.svg';

const workflowHelp = Object.freeze({
  WHAT_IS_AURORA: {
    en: (
      <div>
        <img
          src={image_auroraLogo}
          alt="Aurora logo"
          width="173"
          height="16"
          className="mb-3"
        />
        <p>
          The Aurora&trade; API revolutionises game key distribution, offering
          both speed and top-tier security. It enables game publishers to
          swiftly and securely transfer game keys to retailers, simplifying the
          distribution process. Aurora&trade; is the ultimate choice for secure,
          lightning-fast key distribution in the game industry.
        </p>
        <p>
          Aurora&trade; workflows use key pools and have no sign off process,
          keys are delivered directly to the requester via an API.
        </p>
        <p>
          This is a premium add-on service and may be included in your license.
        </p>
      </div>
    ),
    ja: (
      <div>
        <img
          src={image_auroraLogo}
          alt="Aurora logo"
          width="173"
          height="16"
          className="mb-3"
        />
        <p></p>
        <p></p>
        <p></p>
      </div>
    ),
    de: (
      <div>
        <img
          src={image_auroraLogo}
          alt="Aurora logo"
          width="173"
          height="16"
          className="mb-3"
        />
        <p></p>
        <p></p>
        <p></p>
      </div>
    ),
    fr: (
      <div>
        <img
          src={image_auroraLogo}
          alt="Aurora logo"
          width="173"
          height="16"
          className="mb-3"
        />
        <p></p>
        <p></p>
        <p></p>
      </div>
    ),
    it: (
      <div>
        <img
          src={image_auroraLogo}
          alt="Aurora logo"
          width="173"
          height="16"
          className="mb-3"
        />
        <p></p>
        <p></p>
        <p></p>
      </div>
    ),
    es: (
      <div>
        <img
          src={image_auroraLogo}
          alt="Aurora logo"
          width="173"
          height="16"
          className="mb-3"
        />
        <p></p>
        <p></p>
        <p></p>
      </div>
    ),
  },
  REQUIRES_JUSTIFICATION: {
    en: (
      <p>
        If enabled, requesters will be required to provide some justification
        for the request. This is free text.
      </p>
    ),
    ja: <p></p>,
    fr: <p></p>,
    de: <p></p>,
    it: <p></p>,
    es: <p></p>,
  },
  DEFAULT_RESTRICTED: {
    en: (
      <p>
        If enabled, newly created Game SKUs will have this workflow added to
        their list of restricted workflows, meaning it will not be available as
        a requestable workflow on that game sku by default.
      </p>
    ),
    ja: <p></p>,
    fr: <p></p>,
    de: <p></p>,
    it: <p></p>,
    es: <p></p>,
  },
  WORKFLOW_RESTRICTIONS: {
    en: (
      <ul className="ms-4 mt-4">
        <li>
          <b>Unrestricted</b> = No restrictions
        </li>
        <li>
          <b>Allow Only</b> = Available to only those listed
        </li>
        <li>
          <b>Disallow</b> = Available to any workflow other than those listed
        </li>
      </ul>
    ),
    ja: (
      <ul className="ms-4 mt-4">
        <li>
          <b></b>
        </li>
        <li>
          <b></b>
        </li>
        <li>
          <b></b>
        </li>
      </ul>
    ),
    fr: (
      <ul className="ms-4 mt-4">
        <li>
          <b></b>
        </li>
        <li>
          <b></b>
        </li>
        <li>
          <b></b>
        </li>
      </ul>
    ),
    de: (
      <ul className="ms-4 mt-4">
        <li>
          <b></b>
        </li>
        <li>
          <b></b>
        </li>
        <li>
          <b></b>
        </li>
      </ul>
    ),
    it: (
      <ul className="ms-4 mt-4">
        <li>
          <b></b>
        </li>
        <li>
          <b></b>
        </li>
        <li>
          <b></b>
        </li>
      </ul>
    ),
    es: (
      <ul className="ms-4 mt-4">
        <li>
          <b></b>
        </li>
        <li>
          <b></b>
        </li>
        <li>
          <b></b>
        </li>
      </ul>
    ),
  },
  ALLOW_SAME_COMPANY_DOWNLOAD: {
    en: (
      <p>
        If enabled, and also if the user's company has "Request visible to all
        users" turned on, then users from the same company can download keys
        from this workflow.
      </p>
    ),
    ja: <p></p>,
    fr: <p></p>,
    de: <p></p>,
    it: <p></p>,
    es: <p></p>,
  },
  EXCLUDE_REQUEST_VISIBLE_TO_ALL_USERS: {
    en: (
      <p>
        If enabled, requests from this workflow will not be visible to all users
        even if the user's company has "Request visible to all users" turned on.
      </p>
    ),
    ja: <p></p>,
    fr: <p></p>,
    de: <p></p>,
    it: <p></p>,
    es: <p></p>,
  },
});

export default workflowHelp;
