import * as gameTemplateApi from '../api/gameTemplateApi';

export function saveGameTemplate(gameTemplate) {
  return gameTemplateApi.saveGameTemplate(gameTemplate);
}

export function getGameTemplateById(id) {
  return gameTemplateApi.getGameTemplateById(id);
}

export function getGameTemplates() {
  return gameTemplateApi.getAllGameTemplates();
}

export function loadGameTemplates({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return gameTemplateApi.getGameTemplates(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function deleteGameTemplate(id) {
  return gameTemplateApi.deleteGameTemplate(id);
}

export function addTerritories(id, territories) {
  return gameTemplateApi.addTerritories(id, territories);
}
export function removeTerritories(id, territories) {
  return gameTemplateApi.removeTerritories(id, territories);
}
export function addKeyWorkflows(id, keyWorkflows) {
  return gameTemplateApi.addKeyWorkflows(id, keyWorkflows);
}
export function removeKeyWorkflows(id, keyWorkflows) {
  return gameTemplateApi.removeKeyWorkflows(id, keyWorkflows);
}

export function exportGameTemplateData(filters, scopes) {
  return gameTemplateApi.exportGameTemplateData(filters, scopes);
}
