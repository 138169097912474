import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReportedActivatedList from './components/ReportedActivatedList';
import ActionBar from '../layout/ActionBar';
import UploadActivated from './slideout/UploadActivated';
import SlidingPane from 'react-sliding-pane';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

function ReportedActivated({ location }) {
  const [reload, setReload] = useState();
  const [uploadKeysSlideoutOpen, setUploadKeysSlideoutOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={() => setUploadKeysSlideoutOpen(true)}
          text={t('Upload activated keys')}
        />
      </ActionBar>
      <ReportedActivatedList location={location} reload={reload} />
      <SlidingPane
        isOpen={uploadKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => {
          setUploadKeysSlideoutOpen(false);
          setReload(new Date());
        }}
      >
        <UploadActivated
          done={() => {
            setUploadKeysSlideoutOpen(false);
            setReload(new Date());
          }}
          cancel={() => setUploadKeysSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReportedActivated);
