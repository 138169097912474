import React, { useState } from 'react';
import {
  loadTerritories,
  exportTerritoryData,
} from '../../../../actions/territoryActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { CommaNumber_NoDecimal, hexToRgbA } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TerritoryList({ location, reload }) {
  const { t } = useTranslation();
  const allColumns = [
    'name',
    'description',
    'numberAllowedSKUs',
    'numberDisallowedSKUs',
  ];
  const defaultVisibleColumns = allColumns;
  const [territories, setTerritories] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'territorylist-settings-2.23',
      getTableSettingsDefaults({ defaultSort: 'name', defaultVisibleColumns }),
      null,
      loadTerritories,
      setTerritories,
      reload,
      t('Failed to load territories'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Name'),
      width: '20%',
      Render: (territory) => (
        <div
          className="p-2 d-flex align-items-center territory-td"
          style={
            territory.colourHex
              ? {
                  borderLeft: `3px solid ${territory.colourHex}`,
                }
              : null
          }
        >
          <Link to={'/settings/territory/' + territory.id}>
            {territory.name}
          </Link>
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'description',
      Label: t('Description'),
      width: '40%',
      Render: (territory) => territory.description,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'numberAllowedSKUs',
      Label: t('Number of Allowed SKUs'),
      width: '20%',
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.numberAllowedSKUs),
      // (
      //   <NavLink to={`/settings/territory/allowed-skus/${item.id}`}>
      //     {CommaNumber_NoDecimal(item.numberAllowedSKUs)}
      //   </NavLink>
      // ),
    },
    {
      Key: 'numberDisallowedSKUs',
      Label: t('Number of Disallowed SKUs'),
      width: '20%',
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.numberDisallowedSKUs),
      // (
      //   <NavLink to={`/settings/territory/disallowed-skus/${item.id}`}>
      //     {CommaNumber_NoDecimal(item.numberDisallowedSKUs)}
      //   </NavLink>
      // ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return territories?.data ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={territories.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={territories.totalRowCount}
        filteredRowCount={territories.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportTerritoryData}
        trStyle={(row) => {
          return {
            backgroundColor: `${hexToRgbA(row.colourHex, 0.0375)}`,
          };
        }}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

TerritoryList.propTypes = {
  location: PropTypes.object.isRequired,
  reload: PropTypes.object,
};

export default TerritoryList;
