import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import KwPanel from '../../common/KwPanel';
import { loadKeyPools, validateCodeFile } from '../../../actions/skuActions';
import Loading from '../../common/Loading';
import { CommaNumber_NoDecimal, ParseInt } from '../../../util/formatter';
import { toast } from 'react-toastify';
import FormFileUpload from '../../common/FormFileUpload';
import { splitFileIntoKeyPools } from '../../../actions/keyCodeActions';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function BulkUploadKeys({ done, skuId, gameId, skuName, cancel }) {
  const { t } = useTranslation();
  const [keypools, setKeyPools] = useState();
  const [totalKeys, setTotalKeys] = useState(0);
  const [remainingKeys, setRemainingKeys] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [file, setFile] = useState();
  const [fileId, setFileId] = useState();
  const [keysInFile, setKeysInFile] = useState();
  const [bulkKeys, setBulkKeys] = useState('');

  const addFiles = (files) => {
    let file = files[0];
    setFile(file);

    validateCodeFile(skuId, gameId, { file })
      .then((result) => {
        setFileId(result.fileId);
        setKeysInFile(result.codesInFile);
      })
      .catch((ex) => {
        setFile(null);
        setFileId(null);
        setKeysInFile(null);
        toast.error(ex.message);
      });
  };

  useEffect(() => {
    loadKeyPools({
      id: skuId,
      gameid: gameId,
      filters: [],
      _sort: '',
      _order: '',
      _page: 1,
      _limit: 1_000,
    }).then((data) => {
      setKeyPools(
        data.data.map((x) => ({
          ...x,
          numberOfKeys: 0,
          tag: '',
          expiryDate: '',
        }))
      );
    });
  }, [skuId, gameId]);

  useEffect(() => {
    if (keypools && keysInFile) {
      let _total = keypools.reduce(
        (sum, pool) => sum + ParseInt(pool.numberOfKeys),
        0
      );
      setTotalKeys(_total);
      setRemainingKeys(keysInFile - _total);
    } else {
      setTotalKeys(0);
      setRemainingKeys(keysInFile ? keysInFile : 0);
    }
  }, [keypools, keysInFile]);

  const setRowData = (id, data) => {
    setBulkKeys('');
    let newArr = [...keypools];
    let index = newArr.findIndex((x) => x.keyWorkflowId === id);
    newArr[index] = { ...newArr[index], ...data };
    setKeyPools(newArr);
  };

  const handleSetBulkNumberOfKeys = (e) => {
    if (!keypools) {
      return;
    }
    const numberOfKeys = ParseInt(e.target.value);
    setBulkKeys(numberOfKeys);
    setKeyPools(
      keypools.map((keyPool) => {
        return { ...keyPool, numberOfKeys: numberOfKeys };
      })
    );
  };

  const handleSubmit = () => {
    if (!keypools) {
      return;
    }

    setProcessing(true);

    splitFileIntoKeyPools({
      skuId: skuId,
      fileId: fileId,
      description: '',
      destinationKeyPools: keypools
        .filter((x) => x.numberOfKeys > 0)
        .map((x) => ({
          keyWorkflowId: x.keyWorkflowId,
          tag: x.tag,
          numberOfKeys: ParseInt(x.numberOfKeys),
        })),
    })
      .then((d) => {
        done(d);
        toast.success(t('Import transactions queued'));
      })
      .catch((ex) => {
        setProcessing(false);
        toast.error(ex.message || t('Failed to upload keys'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Bulk upload keys to {skuName}', { skuName: skuName })}
      cancel={cancel}
      done={done}
      bar={
        keypools && (
          <Button
            className="btn btn-primary"
            isDisabled={processing || totalKeys !== keysInFile}
            title={
              processing
                ? t('Processing...')
                : totalKeys !== keysInFile
                  ? t('Number of keys does not match those in the file')
                  : ''
            }
            onClick={handleSubmit}
            text={t('Bulk upload')}
          />
        )
      }
    >
      {keypools ? (
        <KwPanel key={skuId} className="space-bottom">
          <header className="mb-2">
            <h2 className="no-margin bold">{t('Bulk upload keys')}</h2>
          </header>
          <div>
            {!fileId && (
              <FormFileUpload
                name="files"
                label={t('Upload key file')}
                required={true}
                addFiles={addFiles}
              />
            )}
          </div>
          {file && fileId && (
            <div className="table-wrapper">
              <table className="table table-has-buttons jsDataTable width-100 space-bottom td-align-top">
                <thead>
                  <tr>
                    <th scope="col" colSpan={2}>
                      <span>{t('File')}</span>
                    </th>
                    <th scope="col" className="text-right">
                      <span>{t('Remaining')}</span>
                    </th>
                    <th scope="col" className="text-right">
                      <span>{t('Total')}</span>
                    </th>
                  </tr>

                  <tr>
                    <td colSpan={2}>{file.name}</td>
                    <td className="text-right num">
                      {remainingKeys < 0 ? (
                        <span className="text-red">
                          {CommaNumber_NoDecimal(remainingKeys)}
                        </span>
                      ) : (
                        CommaNumber_NoDecimal(remainingKeys)
                      )}
                    </td>
                    <td>
                      <div
                        style={{ marginRight: 12 }}
                        className="num text-right bold"
                      >
                        {CommaNumber_NoDecimal(totalKeys)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">
                      <span>{t('Destination pool')}</span>
                    </th>
                    <th scope="col" className="">
                      <span>{t('Expires')}</span>
                    </th>
                    <th scope="col" className="">
                      <span>{t('Tag')}</span>
                    </th>
                    <th scope="col" className="text-right">
                      <span>{t('Amount to upload')}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={3}></td>
                    <td className="bulk">
                      <div className="flex justify-content-end">
                        <input
                          type="text"
                          className={`form-control input-sm num text-right no-navigate`}
                          style={{ width: '120px' }}
                          value={bulkKeys}
                          onChange={handleSetBulkNumberOfKeys}
                          placeholder={t('Bulk edit')}
                        ></input>
                      </div>
                    </td>
                  </tr>
                  {keypools.map((pool) => (
                    <tr key={pool.keyWorkflowId} className="br_row">
                      <td>{pool.keyWorkflowName}</td>
                      <td>
                        <input
                          type="date"
                          value={pool.expiryDate}
                          placeholder={t('Optional')}
                          className={`form-control input-sm no-navigate`}
                          style={{ width: '120px' }}
                          onChange={(e) => {
                            e.cancelBubble = true;
                            setRowData(pool.keyWorkflowId, {
                              expiryDate: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={pool.tag}
                          placeholder={t('Optional')}
                          className={`form-control input-sm no-navigate`}
                          style={{ width: '120px' }}
                          onChange={(e) => {
                            e.cancelBubble = true;
                            setRowData(pool.keyWorkflowId, {
                              tag: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <td>
                        <div className="flex justify-content-end">
                          <input
                            type="text"
                            value={pool.numberOfKeys}
                            className={`form-control input-sm num text-right no-navigate`}
                            style={{ width: '120px' }}
                            onKeyDown={(keyEvent) => {
                              try {
                                keyEvent.cancelBubble = true;
                                let amt = 0;
                                let handled = false;
                                if (keyEvent.keyCode === 38) {
                                  // up
                                  amt = keyEvent.shiftKey ? 10 : 1;
                                  handled = true;
                                } else if (keyEvent.keyCode === 40) {
                                  // down
                                  amt = keyEvent.shiftKey ? -10 : -1;
                                  handled = true;
                                } else if (keyEvent.keyCode === 33) {
                                  // pgup
                                  amt = keyEvent.shiftKey ? 1000 : 100;
                                  handled = true;
                                } else if (keyEvent.keyCode === 34) {
                                  // pgdown
                                  amt = keyEvent.shiftKey ? -1000 : -100;
                                  handled = true;
                                }
                                let newAmount =
                                  ParseInt(pool.numberOfKeys) + amt;
                                if (handled) {
                                  setRowData(pool.keyWorkflowId, {
                                    numberOfKeys: newAmount < 0 ? 0 : newAmount,
                                  });
                                }
                              } catch (err) {}
                            }}
                            onChange={(e) => {
                              e.cancelBubble = true;
                              setRowData(pool.keyWorkflowId, {
                                numberOfKeys: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </KwPanel>
      ) : (
        <Loading />
      )}
    </SlideoutLayout>
  );
}

BulkUploadKeys.propTypes = {
  skuId: PropTypes.string,
  skuName: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default BulkUploadKeys;
