import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import { CommaNumber_NoDecimal } from '../../util/formatter';
import KwPanel from './KwPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import icons from '../../constants/icons.ts';

const RolledUpStats = ({
  viewAllRequestsLinkTo,
  openRequests,
  openRequestsLinkTo,
  authorisedRequests,
  authorisedRequestsLinkTo,
  declinedRequests,
  declinedRequestsLinkTo,
  totalKeysRequested,
  totalKeysRequestedLinkTo,
}) => {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4">
      <header className="mb-2">
        <h2 className="no-margin">{t('Rolled up stats')}</h2>
        <div className="float-right">
          <NavLink to={viewAllRequestsLinkTo} className="btn btn-default">
            {t('View all requests')}
          </NavLink>
        </div>
      </header>
      <div className="row mb-4">
        <div className="col-sm-6 ps-4">
          <div className="row">
            <div className="col-sm-6 col-form-label">{t('Open requests')}</div>
            <div className="col-sm-6 ps-0">
              <NavLink
                to={openRequestsLinkTo}
                className="form-control-static px-3 flex h-100 w-100 align-items-center"
                title={t('View open requests')}
              >
                <div className="width-100 num">
                  {CommaNumber_NoDecimal(openRequests)}
                </div>
                <div className="me-2">
                  <FontAwesomeIcon icon={icons.LINK_EXTERNAL}></FontAwesomeIcon>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-sm-6 ps-4">
          <div className="row">
            <div className="col-sm-6 col-form-label">
              {t('Authorised requests')}
            </div>
            <div className="col-sm-6 ps-0">
              <NavLink
                to={authorisedRequestsLinkTo}
                className="form-control-static px-3 flex h-100 w-100 align-items-center"
                title={t('View authorised requests')}
              >
                <div className="width-100 num">
                  {CommaNumber_NoDecimal(authorisedRequests)}
                </div>
                <div className="me-2">
                  <FontAwesomeIcon icon={icons.LINK_EXTERNAL}></FontAwesomeIcon>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 ps-4">
          <div className="row">
            <div className="col-sm-6 col-form-label">
              {t('Declined requests')}
            </div>
            <div className="col-sm-6 ps-0">
              <NavLink
                to={declinedRequestsLinkTo}
                className="form-control-static px-3 flex h-100 w-100 align-items-center"
                title={t('View declined requests')}
              >
                <div className="width-100 num">
                  {CommaNumber_NoDecimal(declinedRequests)}
                </div>
                <div className="me-2">
                  <FontAwesomeIcon icon={icons.LINK_EXTERNAL}></FontAwesomeIcon>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-sm-6 ps-4">
          <div className="row">
            <div className="col-sm-6 col-form-label">
              {t('Total keys requested')}
            </div>
            <div className="col-sm-6 ps-0">
              <NavLink
                to={viewAllRequestsLinkTo}
                className="form-control-static px-3 flex h-100 w-100 align-items-center"
                title={t('View total keys requested')}
              >
                <div className="width-100 num">
                  {CommaNumber_NoDecimal(totalKeysRequested)}
                </div>
                <div className="me-2">
                  <FontAwesomeIcon icon={icons.LINK_EXTERNAL}></FontAwesomeIcon>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </KwPanel>
  );
};

RolledUpStats.propTypes = {
  viewAllRequestsLinkTo: PropTypes.string.isRequired,
  openRequests: PropTypes.number.isRequired,
  openRequestsLinkTo: PropTypes.string.isRequired,
  authorisedRequests: PropTypes.number.isRequired,
  authorisedRequestsLinkTo: PropTypes.string.isRequired,
  declinedRequests: PropTypes.number.isRequired,
  declinedRequestsLinkTo: PropTypes.string.isRequired,
  totalKeysRequested: PropTypes.number.isRequired,
  totalKeysRequestedLinkTo: PropTypes.string.isRequired,
};

export default withRouter(RolledUpStats);
