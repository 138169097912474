import React from 'react';
import { useForm } from 'react-hook-form';
import KwPanel from '../../../common/KwPanel';
import FormInput from '../../../common/FormInput';
import Button from '../../../common/Button';
import { useTranslation } from 'react-i18next';

export default function KeyWorkflowFreeTextTagsStep({
  handleKeyWorkflowFreeTextTagInput,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  return (
    <>
      <h2 className="mb-4">{t('Enter a request tag')}</h2>
      <KwPanel>
        <FormInput
          register={register}
          required
          name="keyWorkflowTag"
          label={t('Tag')}
          error={errors.keyWorkflowTag}
          errorMessage={t('Required')}
        ></FormInput>
        <Button
          className="btn btn-primary w-100"
          onClick={handleSubmit(handleKeyWorkflowFreeTextTagInput)}
          text={t('Next')}
        />
      </KwPanel>
    </>
  );
}
