import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ActionBar from '../../layout/ActionBar';
import CompanyTypeForm from './components/CompanyTypeForm';
import Delete from './slideout/Delete';
import {
  getCompanyTypeById,
  saveCompanyType,
} from '../../../actions/companyTypeActions';
import Loading from '../../common/Loading';
import SlidingPane from 'react-sliding-pane';
import { useTranslation } from 'react-i18next';
import ResponsiveActionBarButtons from '../../common/ResponsiveActionBarButtons';
import Button from '../../common/Button';

function CompanyType({ id, saveCompanyType, history }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = (data) => {
    saveCompanyType({ ...companyType, ...data })
      .then(() => {
        setCompanyType({ ...companyType, ...data });
        toast.success(t('Company type saved'));
      })
      .catch((ex) => {
        console.error(ex);
        toast.error(t('Failed to save company type'));
      });
  };

  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);
  const handleDelete = (e) => {
    e.cancelBubble = true;
    setDeleteSlideoutOpen(true);
    return false;
  };

  const [companyType, setCompanyType] = useState({});

  useEffect(() => {
    if (id) {
      getCompanyTypeById(id)
        .then((d) => {
          setCompanyType(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/settings/company-types/');
          toast.error(t('Company type not found'));
        });
    }
  }, [id, history, t]);

  return companyType ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/settings/company-types', label: t('Company types') },
          { label: companyType.name },
        ]}
      >
        <div className="d-flex">
          <ResponsiveActionBarButtons
            buttons={[
              id && (
                <Button
                  className="btn btn-danger ms-2 d-none d-md-inline-flex"
                  onClick={handleDelete}
                  text={t('Delete')}
                />
              ),
              <Button
                key="save"
                className="btn btn-primary ms-2"
                onClick={handleSubmit(onSubmit)}
                text={t('Save')}
              />,
            ]}
          />
        </div>
      </ActionBar>
      <CompanyTypeForm
        companyType={companyType}
        register={register}
        errors={errors}
        control={control}
      ></CompanyTypeForm>

      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          done={(d) => {
            setDeleteSlideoutOpen(false);
            history.push('/settings/company-types/');
          }}
          cancel={() => setDeleteSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
  };
}

const mapDispatchToProps = {
  saveCompanyType,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyType);
