import React from 'react';
import FormGroup from './FormGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const FormSelect = (
  {
    label,
    name,
    children,
    defaultValue,
    placeholder,
    register,
    error,
    errorMessage,
    required,
    className,
    joinedButton,
    joinedButtonIcon,
    joinedButtonClick,
    joinedButtonDisabled,
    onChange,
    autoFocus,
    disabled,
  },
  ...rest
) => {
  // Common properties
  let selectProps = {
    'aria-label': label,
    name,
    placeholder,
    defaultValue: defaultValue,
    onChange: onChange,
    autoFocus: autoFocus ?? false,
    disabled: disabled ?? false,
  };

  // If using react-hook-form, add register and required properties
  if (register) {
    selectProps = {
      ...selectProps,
      ...register(name, { required, onChange }),
    };
  }

  return (
    <FormGroup
      label={label}
      error={error}
      errorMessage={errorMessage}
      required={required}
      {...rest}
    >
      {joinedButton ? (
        <div className="input-group">
          <select
            {...selectProps}
            className={`form-control float-left ${className || ''}`}
            style={{ width: 'calc(100% - 45px)' }}
          >
            {children}
          </select>
          <button
            type="submit"
            onClick={joinedButtonClick}
            className="btn btn-primary float-left r-corners-right"
            disabled={joinedButtonDisabled}
          >
            <FontAwesomeIcon
              icon={joinedButtonIcon}
              aria-hidden="true"
            ></FontAwesomeIcon>
          </button>
        </div>
      ) : (
        <select {...selectProps} className={`form-control ${className || ''}`}>
          {children}
        </select>
      )}
    </FormGroup>
  );
};

// Define default props
FormSelect.defaultProps = {
  className: '',
  autoFocus: false,
  disabled: false,
};

// Define prop types
FormSelect.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  joinedButton: PropTypes.bool,
  joinedButtonIcon: PropTypes.any,
  joinedButtonClick: PropTypes.func,
  joinedButtonDisabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default FormSelect;
