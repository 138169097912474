import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function NumberOfKeysRow({
  game,
  style,
  gameNumberOfKeysOnBlur,
  changeNumberOfKeys,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={
        !game.isSKU
          ? 'grid-empty full-cell'
          : 'grid-empty full-cell sku sku-input'
      }
      style={style}
    >
      <input
        type="number"
        value={game.numberOfKeys || ''}
        onBlur={() => {
          if (!game.isSKU) {
            gameNumberOfKeysOnBlur(game);
          }
        }}
        placeholder={!game.isSKU ? t('Apply to skus') : '0'}
        className={!game.isSKU ? 'form-control num' : 'form-control sku num'}
        onChange={(e) => changeNumberOfKeys(e.target.value, game)}
        disabled={game.showRemove}
      ></input>
    </div>
  );
}

NumberOfKeysRow.propTypes = {
  game: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  gameNumberOfKeysOnBlur: PropTypes.func.isRequired,
  changeNumberOfKeys: PropTypes.func.isRequired,
};

export default NumberOfKeysRow;
