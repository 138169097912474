import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import icons from '../../constants/icons.ts';

const ResponsiveActionBarButtons = ({ buttons }) => {
  return (
    <>
      <Dropdown className={`dropdown d-inline-flex d-md-none`}>
        <Dropdown.Toggle
          variant="default"
          className="btn btn-default dropdown-toggle no-icon no-navigate btn-context-menu ms-2"
        >
          <FontAwesomeIcon icon={icons.DROPDOWN_TOGGLE}></FontAwesomeIcon>
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-dark"
          popperConfig={{
            strategy: 'fixed',
          }}
        >
          {[...buttons]
            .reverse()
            .filter(
              (button) =>
                button &&
                button.props &&
                button.props?.className.indexOf('d-none') !== -1
            )
            .map((button, index) => (
              <li key={index}>
                {{
                  ...button,
                  props: { ...button.props, className: 'dropdown-item' },
                }}
              </li>
            ))}
        </Dropdown.Menu>
      </Dropdown>

      {buttons.map((button) => button)}
    </>
  );
};
export default ResponsiveActionBarButtons;
