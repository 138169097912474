import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.LOAD_COMPANIES:
      return action.companies;
    case actionTypes.UPDATE_COMPANY:
      return state
        ? state.map((company) =>
            company.id === action.company.id ? action.company : company
          )
        : state;
    case actionTypes.CREATE_COMPANY:
      return state ? [...state, action.company] : state;
    case actionTypes.DELETE_COMPANY:
      return state
        ? state.filter((company) => company.id !== action.id)
        : state;
    default:
      return state;
  }
}
