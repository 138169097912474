import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.LOAD_DISABLEREASONS:
      return action.disableReasons;
    case actionTypes.UPDATE_DISABLEREASON:
      return state
        ? state.map((disableReason) =>
            disableReason.id === action.disableReason.id
              ? action.disableReason
              : disableReason
          )
        : state;
    case actionTypes.CREATE_DISABLEREASON:
      return state ? [...state, action.disableReason] : state;
    case actionTypes.DELETE_DISABLEREASON:
      return state
        ? state.filter((disableReason) => disableReason.id !== action.id)
        : state;
    default:
      return state;
  }
}
