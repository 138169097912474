import React from 'react';
import { useForm } from 'react-hook-form';
import FormStatic from '../../../common/FormStatic';
import FormInputNumber from '../../../common/FormInputNumber';
import {
  Date_AsString_NoHtml,
  CommaNumber_NoDecimal,
} from '../../../../util/formatter';
import KeyProviderLink from '../../../common/links/KeyProviderLink';
import Button from '../../../common/Button';
import { useTranslation } from 'react-i18next';

export default function ConfirmStep({
  request,
  saveRequest,
  keyProviderRequestInformation,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const showAppId = keyProviderRequestInformation?.find(
    (x) => x.type === 'App Id'
  )?.internalSite;
  const showFulfillmentDate = keyProviderRequestInformation?.find(
    (x) => x.type === 'Fullfilment Date'
  )?.internalSite;
  const showKeyProvider = keyProviderRequestInformation?.find(
    (x) => x.type === 'Key Provider'
  )?.internalSite;
  const showLicenseExpires = keyProviderRequestInformation?.find(
    (x) => x.type === 'License Expires'
  )?.internalSite;
  const showPackageId = keyProviderRequestInformation?.find(
    (x) => x.type === 'Package Id'
  )?.internalSite;
  const showProductCode = keyProviderRequestInformation?.find(
    (x) => x.type === 'Product Code'
  )?.internalSite;

  const onSubmit = (data) => {
    saveRequest({
      companyId: request?.company?.id,
      keyWorkflowId: request?.keyWorkflow?.id,
      gameSKUId: request?.sku?.skuId,
      requesterTagId: request?.requesterTag?.id,
      requesterTag: request?.requesterTag?.freeText,
      numberOfKeys: data.numberOfKeys ?? 1,
    });
  };
  return (
    <>
      {request?.keyWorkflow?.maxKeys !== 1 && (
        <h2 className="mb-4">{t('How many keys?')}</h2>
      )}
      <div className="space-bottom form-horizontal">
        {request?.company?.companyName && (
          <FormStatic label={t('Company')}>
            {request?.company?.companyName}
          </FormStatic>
        )}
        <FormStatic label={t('Request type')}>
          {request?.keyWorkflow?.name}
        </FormStatic>
        <FormStatic label={t('Game')}>{request?.game?.name}</FormStatic>
        <FormStatic label={t('SKU')}>{request?.sku?.name}</FormStatic>
        {showKeyProvider && (
          <FormStatic label={t('Key provider')}>
            <KeyProviderLink
              id={request?.sku?.keyProviderId}
              name={request?.sku?.keyProviderName}
              imageId={request?.sku?.keyProviderImageId}
              colourHex={request?.sku?.keyProviderColourHex}
            />
          </FormStatic>
        )}
        {showAppId && (
          <FormStatic label={t('App Id')}>{request?.game?.appId}</FormStatic>
        )}
        {showLicenseExpires && (
          <FormStatic label={t('License expires')}>
            {request?.game?.licenseExpires &&
              Date_AsString_NoHtml(request?.game?.licenseExpires)}
          </FormStatic>
        )}
        {showPackageId && (
          <FormStatic label={t('Package Id')}>
            {request?.sku?.packageId}
          </FormStatic>
        )}
        {showProductCode && (
          <FormStatic label={t('Product code')}>
            {request?.sku?.steamRef}
          </FormStatic>
        )}
        {showFulfillmentDate && (
          <FormStatic label={t('Fulfillment date')}>
            {request?.sku?.fulfillmentDate &&
              Date_AsString_NoHtml(request?.sku?.fulfillmentDate)}
          </FormStatic>
        )}
        {request?.requesterTag && (
          <FormStatic label={t('Tag')}>
            {request.requesterTag.tag ?? request.requesterTag.freeText}
          </FormStatic>
        )}
        {request?.keyWorkflow?.showKeyPoolLevelDuringRequest && (
          <FormStatic label={t('Available keys')}>
            {CommaNumber_NoDecimal(request.keyPoolLevel)}
          </FormStatic>
        )}
        {request?.keyWorkflow?.maxKeys === 1 ? (
          <FormStatic label={t('Number of keys')}>1</FormStatic>
        ) : (
          <FormInputNumber
            register={register}
            required
            name="numberOfKeys"
            label={t('Number of keys')}
            error={errors.numberOfKeys}
            errorMessage={t('Required')}
            autoFocus={true}
          ></FormInputNumber>
        )}
        <Button
          className="btn btn-primary w-100"
          onClick={handleSubmit(onSubmit)}
          text={t('Add to basket')}
        />
      </div>
    </>
  );
}
