import {
  apiUrl,
  apiOptions,
  handleError,
  handleResponse,
  getStatsData,
  getList,
  get,
  getSingleRecord,
  saveRecord,
  isMock,
  getAll,
  postAction,
  deleteAction,
  getAuditData,
  putAction,
  exportData,
} from './apiUtils';
const baseUrl = apiUrl + '/users/';

export function getUserById(id) {
  return getSingleRecord(baseUrl, id);
}

export function getByUsername(username) {
  const url = isMock(baseUrl)
    ? `${baseUrl}authenticated?username=${username}`
    : `${baseUrl}authenticated`;
  return fetch(url, apiOptions()).then(handleResponse).catch(handleError);
}

export function getStats(id) {
  return getStatsData(baseUrl, id);
}

export function getStatsByDay(id) {
  return get(`${baseUrl}${id}/statsByDay/`);
}

export function getAggregateStats(id, from, to) {
  // Format the from as YYYY-MM-DD
  from = from.toISOString().split('T')[0];
  // Format the to as YYYY-MM-DD
  to = to.toISOString().split('T')[0];

  return get(`${baseUrl}${id}/aggregateStats?fromDate=${from}&toDate=${to}`);
}

export function getAuthorisedStatsByDay(id) {
  return get(`${baseUrl}${id}/statsAuthorisedByDay/`);
}

export function getUploadedStatsByDay(id) {
  return get(`${baseUrl}${id}/statsUploadedByDay/`);
}
export function getAudit(id) {
  return getAuditData(baseUrl, id);
}

export function addAudit(id, comment) {
  return postAction(`${baseUrl}${id}/audit`, { comment });
}

export function getGeneralStats() {
  return get(`${baseUrl}stats`);
}

export function getUsers(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function getUsersForGroup(
  userProfileGroupById,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}group/${userProfileGroupById}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function saveUser(user) {
  if (user.creditLimit) {
    user.creditLimit = parseFloat(user.creditLimit.replace(/,/g, ''));
  } else {
    user.creditLimit = undefined;
  }
  return saveRecord(baseUrl, user);
}

export function getAssignableUsers() {
  return fetch(baseUrl + 'assignableusers', apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function search(query) {
  return fetch(`${baseUrl}search/${query}`, apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function getUserProfileGroupsForUser(userProfileId) {
  return getAll(`${baseUrl}${userProfileId}/groups`);
}

export function getTerritoriesForUser(userProfileId) {
  return getAll(`${baseUrl}${userProfileId}/territories`);
}

export function addTerritory(userProfileId, territoryId) {
  return postAction(`${baseUrl}${userProfileId}/territory/${territoryId}`);
}

export function removeTerritory(userProfileId, territoryId) {
  return deleteAction(`${baseUrl}${userProfileId}/territory/${territoryId}`);
}

export function archiveUser(id) {
  return deleteAction(`${baseUrl}${id}`);
}

export function resendInvite(id) {
  return putAction(`${baseUrl}${id}/resendInvite`);
}

export function resetAuthenticator(id) {
  return putAction(`${baseUrl}${id}/resetAuthenticator`);
}

export function exportUserData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/users/search`,
    'fullName',
    filters,
    scopes
  );
}

export function setLanguage(language) {
  return putAction(`${baseUrl}setLanguage/${language}`);
}
