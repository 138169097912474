import actionTypes from '../constants/actionTypes';

export function toggleNavMenu() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.LAYOUT_TOGGLE_NAV_MENU,
    });
  };
}
export function closeNavMenu() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.LAYOUT_CLOSE_NAV_MENU,
    });
  };
}
