import React, { useState } from 'react';
import KwPanel from '../../../common/KwPanel';
import FormInput from '../../../common/FormInput';
import PropTypes from 'prop-types';
import FormSelect from '../../../common/FormSelect';
import Loading from '../../../common/Loading';
import { useTranslation } from 'react-i18next';

const InviteUserForm = ({ register, errors, companies }) => {
  const { t } = useTranslation();
  const [newCompanyName, setNewCompanyName] = useState();

  let handleCompanyChange = (e) => {
    setNewCompanyName(e.target.value);
  };

  return companies ? (
    <KwPanel className="mb-4">
      <FormInput
        register={register}
        required={true}
        error={errors.emailAddress}
        errorMessage={t('Email address is required')}
        name="emailAddress"
        label={t('Email address')}
        placeholder={t('Email address of the user')}
        autoFocus={true}
      />
      <FormSelect
        register={register}
        required={true}
        error={errors.companyId}
        errorMessage={t('Company is required')}
        name="companyId"
        label={t('Company')}
        onChange={handleCompanyChange}
      >
        <option value="">{t('--- choose ---')}</option>
        <option value="new">{t('--- new ---')}</option>
        {companies &&
          [...companies]
            ?.sort((a, b) =>
              (a.companyName || '').localeCompare(b.companyName || '')
            )
            ?.map((x) => (
              <option key={x.id} value={x.id}>
                {x.companyName}
              </option>
            ))}
      </FormSelect>

      {newCompanyName === 'new' && (
        <FormInput
          register={register}
          required
          error={errors.newCompanyName}
          errorMessage={t('Name of the new company is required')}
          name="newCompanyName"
          label={t('Company name')}
          placeholder={t('Name of the new company, to be created')}
        />
      )}
    </KwPanel>
  ) : (
    <Loading />
  );
};

InviteUserForm.propTypes = {
  companies: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default InviteUserForm;
