import React, { useEffect } from 'react';
import Gravatar from 'react-gravatar';
import { connect } from 'react-redux';

import { getConnectedUsers } from '../../../actions/signalRActions';
import KwPanel from '../../common/KwPanel';
import Loading from '../../common/Loading';
import UserLink from '../../common/links/UserLink';
import { useTranslation } from 'react-i18next';

function ConnectedUsers({ connectedUsers, getConnectedUsers }) {
  useEffect(() => {
    if (!connectedUsers || !connectedUsers.initialLoad) {
      getConnectedUsers();
    }
  }, [connectedUsers, getConnectedUsers]);
  const { t } = useTranslation();

  return connectedUsers && connectedUsers.users ? (
    <KwPanel className="mb-4">
      <header className="mb-3">
        <h2 className="no-margin">{t("Who's using Alaska right now?")}</h2>
      </header>
      <div className="col-count-2">
        {[...connectedUsers.users]
          ?.filter((x) => x != null) // Filter out null or undefined values
          ?.sort((a, b) => a.fullname.localeCompare(b?.fullname))
          .map((x) => (
            <div
              key={x.id}
              className={`connected-users mb-4 d-flex ${
                !x.isActive && 'inactive'
              }`}
            >
              <Gravatar
                email={x.username}
                size={20}
                rating="pg"
                default="retro"
                className="img-circle me-3"
              />
              <div className="d-flex flex-wrap">
                <UserLink
                  id={x.id}
                  name={x.fullname}
                  className={`no-wrap me-2`}
                />
                <div className="no-wrap">
                  ({x.companyName})
                  {x.isActive && (
                    <span className="badge bg-info ms-2">{t('Active')}</span>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return { connectedUsers: state.connectedUsers };
}

const mapDispatchToProps = { getConnectedUsers };

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedUsers);
