import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, NavLink } from 'react-router-dom';
import { closeNavMenu } from '../../../actions/layoutActions';
import { navPermissionCheck } from '../../auth/authUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

function LeftMenuItem({
  colour,
  url,
  closeNavMenu,
  onClick,
  currentUser,
  allowedPermissions,
  allowedUserTypes,
  isPowerUser,
  location,
  icon,
  spinOnOver,
  title,
  activeif,
  activeRegex,
}) {
  // UI state
  const [state, setState] = useState({ active: false });
  const [over, setOver] = useState(false);

  // Add a loading effect then remove it after a timeout
  useEffect(() => {
    if (state.active) {
      const timerId = setTimeout(() => {
        setState({ active: false });
      }, 800);
      return () => clearTimeout(timerId);
    }
  }, [state.active]);

  // Check if the user has permission to see this menu item
  let hasPermission = navPermissionCheck(
    currentUser,
    allowedPermissions,
    allowedUserTypes,
    isPowerUser
  );

  // Helper functions for the icon
  const getIcon = () => {
    return (
      <span className="d-flex align-items-center">
        <span className={`menu-icon-wrapper ${colour ?? ''}`}>
          <FontAwesomeIcon
            icon={`${icon}`}
            fixedWidth
            spin={spinOnOver && over}
          />
        </span>
        <span>{title}</span>
      </span>
    );
  };

  // Event handlers for clicking the menu item
  const handleClick = useCallback(
    (e) => {
      closeNavMenu(e);
      if (onClick) {
        onClick(e);
      } else {
        setState({ active: true });
      }
    },
    [closeNavMenu, onClick]
  );

  // Get the class name for the LI element based on the current location
  const getLIClassName = () => {
    let isMatch = false;
    if (activeRegex) {
      isMatch = `${location.pathname}${location.search}`.match(
        RegExp(activeRegex)
      );
    } else {
      isMatch = `${location.pathname}${location.search}`.startsWith(
        activeif !== undefined ? activeif : url
      );
    }
    return `nav__menu-item ${isMatch ? 'is-current' : ''}`;
  };

  // Common props for the Link and NavLink components
  const commonProps = {
    tag: Link,
    className: `a ${state.active ? 'active' : ''}`,
  };

  return hasPermission && location ? (
    <li
      key={title}
      onMouseOver={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      className={getLIClassName()}
    >
      {onClick ? (
        <span
          {...commonProps}
          onClick={handleClick}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClick(e);
            }
          }}
        >
          {getIcon()}
        </span>
      ) : (
        <NavLink
          {...commonProps}
          onClick={handleClick}
          activeClassName=""
          to={url}
        >
          {getIcon()}
        </NavLink>
      )}
    </li>
  ) : (
    ''
  );
}

function mapStateToProps(state, ownProps) {
  return {
    navMenuOpen: state.navMenuOpen,
  };
}

const mapDispatchToProps = {
  closeNavMenu,
};

LeftMenuItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  activeRegex: PropTypes.string,
  activeif: PropTypes.string,
  allowedPermissions: PropTypes.array,
  allowedUserTypes: PropTypes.array,
  isPowerUser: PropTypes.bool,
  spinOnOver: PropTypes.bool,
  colour: PropTypes.string,
  currentUser: PropTypes.object,
  closeNavMenu: PropTypes.func,
  location: PropTypes.object,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftMenuItem)
);
