import { loadKeyPools } from '../actions/skuActions';
const steamToolUriScheme =
  window.globalConfig?.steamToolUriScheme ??
  process.env.REACT_APP_STEAM_TOOL_URI_SCHEME ??
  'alaska';

export function steamRequestKeysUri(id) {
  return `${steamToolUriScheme}:requestKeys:${id}`;
}
export function steamDisableKeysUri(id, banType, reason) {
  return `${steamToolUriScheme}:disableKeys:${id}:${banType}:${reason}`;
}

export async function populateSkuKeyPools(games) {
  const skuKeyPools = [];
  for (const game of games) {
    for (const skuId of game.skus) {
      skuKeyPools.push(
        loadKeyPools({
          id: skuId,
          gameid: game.id,
          filters: [],
          _sort: 'KeyWorkflowName',
          _order: '',
          _page: 1,
          _limit: 100,
        })
      );
    }
  }
  //Wait for all of the promises to resolve
  let skus = [];
  const results = await Promise.all(skuKeyPools);

  for (const i in results) {
    for (const j in results[i].data) {
      const row = results[i].data[j];
      let sku = skus.find((x) => x.id === row.skuId);
      const keyPoolRow = {
        keyWorkflowId: row.keyWorkflowId,
        keyWorkflowName: row.keyWorkflowName,
        keyProviderReference: row.keyProviderReference,
      };
      if (sku) {
        sku.keyPools.push(keyPoolRow);
      } else {
        skus.push({
          id: row.skuId,
          name: row.skuName,
          gameName: row.gameName,
          packageTypeId: row.packageTypeId,
          keyPools: [keyPoolRow],
        });
      }
    }
  }
  return skus;
}
