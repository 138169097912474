import actionTypes from '../constants/actionTypes';
export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.LOAD_CONNECTED_USERS:
      return { initialLoad: true, users: action.users };
    case actionTypes.USER_CONNECTED:
      return state
        ? {
            ...state,
            users: [...state.users, { ...action.user, isActive: true }],
          }
        : { initialLoad: false, users: [action.user] };
    case actionTypes.USER_DISCONNECTED:
      return state
        ? {
            ...state,
            users: state.users.filter(
              (x) => x.username !== action.user.username
            ),
          }
        : state;
    case actionTypes.CLEAR_CONNECTED_USERS:
      return null;
    case actionTypes.USER_ACTIVE:
      return state
        ? {
            ...state,
            users: state.users.map((x) =>
              x.username === action.username ? { ...x, isActive: true } : x
            ),
          }
        : state;
    case actionTypes.USER_INACTIVE:
      return state
        ? {
            ...state,
            users: state.users.map((x) =>
              x.username === action.username ? { ...x, isActive: false } : x
            ),
          }
        : state;
    default:
      return state;
  }
}
