import React from 'react';
import FormGroup from './FormGroup';
const FormStatic = ({
  label,
  children,
  onClick,
  title,
  className,
  extraRowClasses,
  helpContent,
}) => (
  <FormGroup
    label={label}
    extraRowClasses={extraRowClasses}
    helpContent={helpContent}
  >
    <div
      className={`${
        className || ''
      } form-control-static px-3 flex h-100 w-100 align-items-center`}
      onClick={onClick}
      title={title}
      aria-label={label}
    >
      {children}
    </div>
  </FormGroup>
);
export default FormStatic;
