import React, { useState } from 'react';
import FormButtonGroup from '../../common/FormButtonGroup';
import KwPanel from '../../common/KwPanel';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { steamDisableKeysUri } from '../../../util/steamUtils';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';
function DisableOnSteam({ id, done, cancel }) {
  const [type, setType] = useState();
  const [reason, setReason] = useState();
  const handleDisableOnSteam = () => {
    window.location = steamDisableKeysUri(id, type, reason);
    done();
  };
  const { t } = useTranslation();
  return (
    <SlideoutLayout
      title={t('Disable on Steam')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          className="btn btn-primary"
          isDisabled={!type || (type === 'All' && !reason)}
          onClick={handleDisableOnSteam}
          text={t('Disable on Steam')}
        />
      }
    >
      <KwPanel className="mb-4">
        <FormButtonGroup
          name="type"
          label={t('Ban type')}
          buttons={[
            { value: 'All', text: t('Ban all keys') },
            { value: 'Unredeemed', text: t('Ban only unredeemed keys') },
          ]}
          onChange={(btn) => setType(btn.value)}
          value={type}
        ></FormButtonGroup>
        {type === 'All' && (
          <FormButtonGroup
            name="reason"
            label={t('Reason')}
            buttons={[
              { value: 'Fraud', text: t('Revoke keys for Fraud') },
              { value: 'Beta', text: t('Revoke Beta keys') },
              { value: 'Refund', text: t('Revoke keys for Refunds') },
            ]}
            onChange={(btn) => setReason(btn.value)}
            value={reason}
          ></FormButtonGroup>
        )}
      </KwPanel>
    </SlideoutLayout>
  );
}
export default DisableOnSteam;
