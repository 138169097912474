import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CommaNumber_NoDecimal } from '../../../util/formatter';
const Stock = ({ request }) => {
  const { t } = useTranslation();
  return (
    <div className="col-6">
      <label className="col-form-label">{t('Stock')}</label>
      <div className="flex ps-0">
        <div className="form-control-static px-3 flex h-100 w-100 align-items-center num no-wrap">
          {CommaNumber_NoDecimal(request.keysAllocated)}
        </div>
      </div>
    </div>
  );
};

Stock.propTypes = {
  request: PropTypes.shape({
    keysAllocated: PropTypes.number,
  }),
};

export default Stock;
