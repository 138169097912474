import React from 'react';
import FormGroup from './FormGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const FormInput = ({
  name,
  label,
  placeholder,
  defaultValue,
  register,
  required,
  joinedButton,
  joinedButtonText,
  joinedButtonIcon,
  joinedButtonClick,
  joinedButtonDisabled,
  error,
  errorMessage,
  onChange,
  className,
  autoFocus,
  disabled,
  pattern,
  helpContent,
  style,
  type,
}) => (
  <FormGroup
    label={label}
    error={error}
    errorMessage={errorMessage}
    required={required}
    helpContent={helpContent}
  >
    {joinedButton ? (
      <div className="input-group">
        <input
          {...register(name, { required, pattern })}
          type={type ?? 'text'}
          aria-label={label}
          name={name}
          placeholder={placeholder}
          className={`form-control float-left ${className || ''}`}
          defaultValue={defaultValue}
          data-lpignore="true"
          autoComplete="off"
          onChange={onChange}
          autoFocus={autoFocus ?? false}
          disabled={disabled ?? false}
          style={style}
        />
        <button
          type="submit"
          onClick={joinedButtonClick}
          className="btn btn-primary float-left r-corners-right"
          disabled={joinedButtonDisabled || disabled}
        >
          {joinedButtonIcon && (
            <FontAwesomeIcon
              icon={joinedButtonIcon}
              aria-hidden="true"
            ></FontAwesomeIcon>
          )}
          {joinedButtonText}
        </button>
      </div>
    ) : (
      <input
        {...register(name, { required, pattern })}
        type={type ?? 'text'}
        aria-label={label}
        name={name}
        placeholder={placeholder}
        className={`form-control ${className || ''}`}
        defaultValue={defaultValue}
        data-lpignore="true"
        autoComplete="off"
        onChange={onChange}
        autoFocus={autoFocus ?? false}
        disabled={disabled ?? false}
        style={style}
      />
    )}
  </FormGroup>
);
export default FormInput;
