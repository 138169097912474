import React from 'react';
import Button from '../../common/Button';
import icons from '../../../constants/icons';
import KebabMenu from '../../common/KebabMenu';
import { CanCopyRequest } from '../../../util/permissions';
import { useTranslation } from 'react-i18next';

function RequestGroupListButtons({
  row,
  handleDownloadKeys,
  handleUploadKeys,
  handleBulkStatusChange,
  handleBulkReassign,
  handleBulkSteamRequest,
  handleCopyRequest,
  isProcessing,
  currentUser,
}) {
  const { t } = useTranslation();
  return (
    <KebabMenu
      take={1}
      className="inline-icons"
      buttons={[
        typeof handleCopyRequest === 'function' && (
          <Button
            isDisabled={
              !CanCopyRequest({
                companyId: row.companyId,
                requestedById: row.requestedById,
                currentUser,
              })
            }
            className="btn btn-default me-2"
            onClick={(e) => handleCopyRequest(e, row)}
            icon={icons.REQUEST_AGAIN}
            text={t('Request again')}
            title={t('Make a new request based on this request')}
          ></Button>
        ),
      ]}
    ></KebabMenu>
  );
}

export default RequestGroupListButtons;
