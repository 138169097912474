import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Stats from './components/Stats';
import { getGeneralStats } from '../../actions/userActions';
import RequestStats from './components/RequestStats';
import CompanyStats from './components/CompanyStats';
import Loading from '../common/Loading';
import { hasAnyPermission, hasPermission, userIs } from '../auth/authUtils';
import permissionTypes from '../../constants/permissionTypes';
import StatsOverTime from '../common/StatsOverTime';
import { DateForFilter } from '../../util/formatter';
import { getStatsByDay } from '../../actions/requestActions';
import userTypes from '../../constants/userTypes';
import ConnectedUsers from './components/ConnectedUsers';
import { statsModes } from '../../hooks/useAggregateStatsData';

function Home({ currentUser, location, history }) {
  const [stats, setStats] = useState();
  const [reload] = useState();
  useEffect(() => {
    // get the stats
    if (!stats) {
      getGeneralStats()
        .then((d) => {
          setStats(d);
        })
        .catch((e) => {});
    }
  }, [currentUser, stats]);

  let userHasPermissionToHeatmap = () => {
    return (
      hasAnyPermission(currentUser, [
        permissionTypes.MANAGE_KEYS,
        permissionTypes.VIEW_REPORTS,
      ]) || userIs(currentUser, userTypes.APPROVER)
    );
  };
  let heatmapControl = () => (
    <div className="col-12">
      <StatsOverTime
        getData={() => getStatsByDay()}
        onClick={(value) => {
          if (value) {
            let date = DateForFilter(value.date);
            let destination = `/requests/all?f_requestedDateFrom=${date}&f_requestedDateTo=${date}`;
            history.push(destination);
          }
        }}
        mode={statsModes.ALL}
      />
    </div>
  );

  return stats ? (
    <>
      <Stats id={currentUser.id} stats={stats} currentUser={currentUser} />

      {userHasPermissionToHeatmap() && heatmapControl()}

      <div className="row equal-height">
        <div className="col-12 col-lg-6">
          <RequestStats
            openRequests={stats.allOpenRequests}
            openRequestsValue={stats.allOpenRequestsValue}
            openRequestsSLABreached={stats.overSLA}
            openRequestsSLABreachedValue={stats.overSLAValue}
            requestsNeedingKeys={stats.requestsNeedingKeys}
            requestsNeedingKeysValue={stats.requestsNeedingKeysValue}
            keysOrdered={stats.keysOrdered}
            keysOrderedValue={stats.keysOrderedValue}
            requestsYouCanAuthorise={stats.requestsYouCanAuthorise}
            requestsYouCanAuthoriseValue={stats.requestsYouCanAuthoriseValue}
          />
        </div>

        {hasPermission(currentUser, permissionTypes.MANAGE_COMPANIES) && (
          <div className="col-12 col-lg-6">
            <CompanyStats location={location} reload={reload} />
          </div>
        )}
      </div>
      <div className="row equal-height">
        <div className="col-12">
          <ConnectedUsers></ConnectedUsers>
        </div>
      </div>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
