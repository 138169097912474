import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TagHeader({
  bulkChangeTag,
  tagsAsFreeText,
  requesterTags,
  bulkTag,
  bulkTagOnBlur,
}) {
  const { t } = useTranslation();
  return (
    <div className="bulk-grid-game-name-th hidden-input">
      <span className="mb-2">{t('Tag')}</span>
      {tagsAsFreeText ? (
        <input
          type="text"
          className="form-control apply"
          onChange={bulkChangeTag}
          value={bulkTag || ''}
          onBlur={bulkTagOnBlur}
          placeholder={t('Apply to all')}
        ></input>
      ) : (
        <select
          className="form-control apply"
          value={bulkTag || ''}
          onChange={(e) => {
            bulkChangeTag(e);
          }}
        >
          <option value="">{t('Apply to all')}</option>
          {requesterTags &&
            [...requesterTags]
              ?.sort((a, b) => a.tag.localeCompare(b.tag))
              ?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.tag}
                </option>
              ))}
        </select>
      )}
    </div>
  );
}

TagHeader.propTypes = {
  bulkChangeTag: PropTypes.func.isRequired,
  tagsAsFreeText: PropTypes.bool.isRequired,
  requesterTags: PropTypes.array.isRequired,
  bulkTag: PropTypes.string,
  bulkTagOnBlur: PropTypes.func.isRequired,
};

export default TagHeader;
