import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../../layout/ActionBar';
import {
  saveTerritory,
  getTerritoryById,
} from '../../../actions/territoryActions';
import Loading from '../../common/Loading';
import DisallowedSkuList from './components/DisallowedSkuList';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function DisallowedSkus({ id, saveTerritory, history, location }) {
  const { t } = useTranslation();
  const [reload] = useState();
  const [territory, setTerritory] = useState({});

  useEffect(() => {
    if (id) {
      getTerritoryById(id)
        .then((d) => {
          setTerritory(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/settings/territories/');
          toast.error(t('Territory not found'));
        });
    }
  }, [id, history]);

  return territory ? (
    <>
      <ActionBar></ActionBar>

      <DisallowedSkuList location={location} reload={reload} territoryId={id} />
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
  };
}

const mapDispatchToProps = {
  saveTerritory,
};

export default connect(mapStateToProps, mapDispatchToProps)(DisallowedSkus);
