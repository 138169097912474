import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import SkuStats from './components/SkuStats';
import RolledUpStats from '../common/RolledUpStats';
import SlidingPane from 'react-sliding-pane';
import { hasAnyPermissionForCompany, hasPermission } from '../auth/authUtils';
import permissionTypes from '../../constants/permissionTypes';

import {
  getSkuById,
  getStats,
  getSkuCompanyStocks,
  getAudit,
  addAudit,
  getStatsByDay,
} from '../../actions/skuActions';
import { getAllTerritories } from '../../actions/territoryActions';
import { getAllWorkflows } from '../../actions/workflowActions';
import { getPlatforms } from '../../actions/platformActions';
import { getKeyProviders } from '../../actions/keyProviderActions';
import { getAllPackageTypes } from '../../actions/packageTypeActions';
import { addToGroup, removeFromGroup } from '../../actions/signalRActions';

import ActionBar from '../layout/ActionBar';
import { toast } from 'react-toastify';
import CompanyKeyStock from './components/CompanyKeyStock';
import Loading from '../common/Loading';
import Archive from './slideout/Archive';
import Clone from './slideout/Clone';
import KeyPoolsList from './components/KeyPoolsList';
import KeyPoolUpload from '../keys/slideout/KeyPoolUpload';
import RequestKeys from '../requests/slideout/RequestKeys';
import SteamRequest from '../requests/slideout/SteamRequest';
import AuditButton from '../audit/components/AuditButton';
import ResponsiveActionBarButtons from '../common/ResponsiveActionBarButtons';
import DisableUnusedKeys from '../keys/slideout/DisableUnusedKeys';
import DisableKeys from '../keys/slideout/DisableKeys';
import KeyPoolBulkActions from './slideout/KeyPoolBulkActions';
import MoveKeys from './slideout/MoveKeys';
import useUploadNotificationsSingle from '../../hooks/useUploadNotificationsSingle';
import BulkUploadKeys from './slideout/BulkUploadKeys';
import SkuDetails from './components/SkuDetails';
import EditSku from './slideout/EditSku';
import { gameNameSubsition } from '../../util/formatter';
import Button from '../common/Button';
import { scopes as tableScopes } from '../../constants/scopes';
import { useTranslation } from 'react-i18next';

const ManageSku = ({
  id,
  history,
  territories,
  getAllTerritories,
  workflows,
  getAllWorkflows,
  platforms,
  getPlatforms,
  keyProviders,
  getKeyProviders,
  packageTypes,
  getAllPackageTypes,
  location,
  addToGroup,
  removeFromGroup,
  importTransactions,
  moveTransactions,
  currentUser,
  setCompleteRequestSlideoutOpen,
}) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [reloadSku, setReloadSku] = useState();

  const [requestKeysSlideoutOpen, setRequestKeysSlideoutOpen] = useState(false);
  const [archiveSlideoutOpen, setArchiveSlideoutOpen] = useState(false);
  const [cloneSlideoutOpen, setCloneSlideoutOpen] = useState(false);
  const [steamRequestSlideoutOpen, setSteamRequestSlideoutOpen] =
    useState(false);
  const [steamRequestSlideoutItem, setSteamRequestSlideoutItem] = useState();
  const [uploadSlideoutOpen, setUploadSlideoutOpen] = useState(false);
  const [uploadSlideoutItem, setUploadSlideoutItem] = useState();

  const [disableUnusedSlideoutOpen, setDisableUnusedSlideoutOpen] =
    useState(false);
  const [disableUnusedSlideoutItem, setDisableUnusedSlideoutItem] = useState();

  const [moveKeysSlideoutOpen, setMoveKeysSlideoutOpen] = useState(false);
  const [moveKeysSlideoutItem, setMoveKeysSlideoutItem] = useState();

  const [bulkUploadKeysSlideoutOpen, setBulkUploadKeysSlideoutOpen] =
    useState(false);

  const [editSlideoutOpen, setEditSlideoutOpen] = useState(false);

  const found = useCallback(() => {
    setReload(new Date());
  }, [setReload]);
  const [moveTransactionIds, setMoveTransactionIds] =
    useUploadNotificationsSingle(
      'move',
      removeFromGroup,
      moveTransactions,
      found
    );

  const [showDisableKeys, setShowDisableKeys] = useState(false);
  const [bulkActionsSlideoutOpen, setBulkActionsSlideoutOpen] = useState(false);
  const [itemsChecked, setItemsChecked] = useState([]);

  const [sku, setSku] = useState();
  const [companyStocks, setCompanyStocks] = useState([]);
  const [stats, setStats] = useState();

  useEffect(() => {
    if (id) {
      // get the sku
      getSkuById(id)
        .then((d) => {
          setSku(d);
          if (
            !hasAnyPermissionForCompany(
              currentUser,
              [permissionTypes.MANAGE_GAMES, permissionTypes.MANAGE_KEYS],
              d.publisherCompanyId
            )
          ) {
            // redirect away
            window.location.assign(`/game/${d.gameId}`);
          }

          // get the stats
          getStats(id)
            .then((d) => {
              setStats(d);
            })
            .catch((e) => {
              console.error(e);
              toast.error(t('Failed to load stats for sku'));
            });
          if (hasPermission(currentUser, permissionTypes.MANAGE_GAMES)) {
            // get the companyStocks
            getSkuCompanyStocks(id)
              .then((d) => {
                setCompanyStocks(d.data);
              })
              .catch((e) => {
                console.error(e);
                toast.error(t('Failed to get company stocks'));
              });
          }
        })
        .catch((e) => {
          console.error(e);
          history.push('/games');
          toast.error(e.message || t('SKU not found'));
        });
    }
  }, [id, history, currentUser, reloadSku, t]);

  useEffect(() => {
    if (!territories) {
      getAllTerritories();
    }
  }, [territories, getAllTerritories]);

  useEffect(() => {
    if (!workflows) {
      getAllWorkflows();
    }
  }, [workflows, getAllWorkflows]);

  useEffect(() => {
    if (!platforms) {
      getPlatforms();
    }
  }, [platforms, getPlatforms]);

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders]);

  useEffect(() => {
    if (!packageTypes) {
      getAllPackageTypes();
    }
  }, [packageTypes, getAllPackageTypes]);

  return sku &&
    stats &&
    workflows &&
    territories &&
    platforms &&
    keyProviders &&
    packageTypes ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/games', label: t('Games') },
          {
            link: `/game/${sku.gameId}`,
            label: gameNameSubsition(sku.gameName, sku.name),
          },
          { label: sku.name },
        ]}
      >
        <AuditButton id={id} getAudit={getAudit} addAudit={addAudit} />
        {!sku.isArchived && (
          <ResponsiveActionBarButtons
            buttons={[
              <Button
                key="archive"
                className="btn btn-danger ms-2 d-none d-md-inline-flex"
                onClick={() => setArchiveSlideoutOpen(true)}
                text={t('Archive SKU')}
              />,
              <Button
                key="disableKeys"
                className="btn btn-danger ms-2 d-none d-md-inline-flex"
                onClick={() => setShowDisableKeys(true)}
                text={t('Disable keys')}
              />,
              <Button
                key="clone"
                className="btn btn-default ms-2 d-none d-md-inline-flex"
                onClick={() => setCloneSlideoutOpen(true)}
                text={t('Clone sku')}
              />,
              <Button
                key="request"
                className={`btn btn-default ms-2 d-none d-md-inline-flex ${
                  sku && sku.licenseExpired && 'disabled'
                }`}
                isDisabled={sku && sku.licenseExpired}
                title={
                  sku && sku.licenseExpired
                    ? t('License has expired')
                    : t('Request keys')
                }
                onClick={() => setRequestKeysSlideoutOpen(true)}
                text={t('Request keys')}
              />,
              <Button
                key="edit"
                className="btn btn-primary ms-2"
                onClick={() => setEditSlideoutOpen(true)}
                text={t('Edit')}
              />,
            ]}
          />
        )}
      </ActionBar>

      <SkuDetails sku={sku} />

      {stats && (
        <SkuStats stats={stats} id={id} getData={() => getStatsByDay(sku.id)} />
      )}

      {sku &&
        (hasPermission(currentUser, permissionTypes.MANAGE_GAMES) ||
          hasPermission(currentUser, permissionTypes.MANAGE_KEYS)) && (
          <KeyPoolsList
            id={id}
            gameid={sku.gameId}
            location={location}
            reload={reload}
            upload={(e, item) => {
              setUploadSlideoutItem(item);
              setUploadSlideoutOpen(true);
            }}
            onSteamRequest={(e, item) => {
              setSteamRequestSlideoutItem(item);
              setSteamRequestSlideoutOpen(true);
            }}
            onDisableUnused={(e, item) => {
              setDisableUnusedSlideoutItem(item);
              setDisableUnusedSlideoutOpen(true);
            }}
            onMoveKeys={(e, item) => {
              setMoveKeysSlideoutItem(item);
              setMoveKeysSlideoutOpen(true);
            }}
            onBulkUploadKeys={(e, item) => {
              setBulkUploadKeysSlideoutOpen(true);
            }}
            handleBulkActions={setBulkActionsSlideoutOpen}
            addToGroup={addToGroup}
            removeFromGroup={removeFromGroup}
            importTransactions={importTransactions}
            itemsChecked={itemsChecked}
            setItemsChecked={setItemsChecked}
            currentUser={currentUser}
          ></KeyPoolsList>
        )}
      <RolledUpStats
        viewAllRequestsLinkTo={`/requests/all?${tableScopes.SKU}=${sku.id}`}
        openRequests={sku.openRequests}
        openRequestsLinkTo={`/requests/all?f_requestStatus=pending%20approval&${tableScopes.SKU}=${sku.id}`}
        authorisedRequests={sku.authorisedRequests}
        authorisedRequestsLinkTo={`/requests/all?f_requestStatus=authorised&${tableScopes.SKU}=${sku.id}`}
        declinedRequests={sku.declinedRequests}
        declinedRequestsLinkTo={`/requests/all?f_requestStatus=declined&${tableScopes.SKU}=${sku.id}`}
        totalKeysRequested={sku.keysRequested}
        totalKeysRequestedLinkTo={`/keys/skus?${tableScopes.SKU}=${sku.id}`}
      ></RolledUpStats>
      {hasPermission(currentUser, permissionTypes.MANAGE_GAMES) && (
        <CompanyKeyStock companyKeyStocks={companyStocks}></CompanyKeyStock>
      )}

      <SlidingPane
        isOpen={requestKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRequestKeysSlideoutOpen(false)}
      >
        <RequestKeys
          gameId={sku.gameId}
          skuId={id}
          done={() => {
            setRequestKeysSlideoutOpen(false);
            setCompleteRequestSlideoutOpen(true);
          }}
          cancel={() => setRequestKeysSlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={archiveSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setArchiveSlideoutOpen(false)}
      >
        <Archive
          id={id}
          gameId={sku.gameId}
          done={(d) => {
            history.push(`/game/${sku.gameId}`);
          }}
          cancel={() => setArchiveSlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={cloneSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setCloneSlideoutOpen(false)}
      >
        <Clone
          id={id}
          gameId={sku.gameId}
          done={(newid) => {
            history.push(`/sku/${newid}`);
            setCloneSlideoutOpen(false);
          }}
          cancel={() => setCloneSlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={steamRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setSteamRequestSlideoutOpen(false)}
      >
        {steamRequestSlideoutItem ? (
          <SteamRequest
            done={() => setSteamRequestSlideoutOpen(false)}
            cancel={() => setSteamRequestSlideoutOpen(false)}
            keyWorkflowId={steamRequestSlideoutItem.keyWorkflowId}
            keyPoolName={steamRequestSlideoutItem.keyWorkflowName}
            gameName={steamRequestSlideoutItem.gameName}
            gameSkuId={steamRequestSlideoutItem.skuId}
            skuName={steamRequestSlideoutItem.skuName}
            packageTypeId={steamRequestSlideoutItem.packageTypeId}
            customerName={steamRequestSlideoutItem.keyProviderReference}
          ></SteamRequest>
        ) : (
          <></>
        )}
      </SlidingPane>

      <SlidingPane
        isOpen={uploadSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setUploadSlideoutOpen(false)}
      >
        {uploadSlideoutItem ? (
          <KeyPoolUpload
            done={(d) => {
              setUploadSlideoutOpen(false);
              setReload(new Date());
              setUploadSlideoutOpen(false);
            }}
            cancel={() => setUploadSlideoutOpen(false)}
            gameId={uploadSlideoutItem.gameId}
            gameName={uploadSlideoutItem.gameName}
            skuId={uploadSlideoutItem.skuId}
            skuName={uploadSlideoutItem.skuName}
            keyWorkflowId={uploadSlideoutItem.keyWorkflowId}
            keyWorkflowName={uploadSlideoutItem.keyWorkflowName}
          />
        ) : (
          <></>
        )}
      </SlidingPane>

      <SlidingPane
        isOpen={disableUnusedSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDisableUnusedSlideoutOpen(false)}
      >
        {disableUnusedSlideoutItem ? (
          <DisableUnusedKeys
            done={(d) => {
              setReload(new Date());
              setDisableUnusedSlideoutOpen(false);
            }}
            cancel={() => setDisableUnusedSlideoutOpen(false)}
            skuId={disableUnusedSlideoutItem.skuId}
            skuName={disableUnusedSlideoutItem.skuName}
            keyWorkflowId={disableUnusedSlideoutItem.keyWorkflowId}
            keyWorkflowName={disableUnusedSlideoutItem.keyWorkflowName}
            gameName={disableUnusedSlideoutItem.gameName}
          />
        ) : (
          <></>
        )}
      </SlidingPane>

      <SlidingPane
        isOpen={moveKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setMoveKeysSlideoutOpen(false)}
      >
        {moveKeysSlideoutItem ? (
          <MoveKeys
            done={(d) => {
              setMoveTransactionIds([...moveTransactionIds, ...d]);
              for (const moveTransactionId of d) {
                addToGroup(`move:${moveTransactionId}`);
              }
              setReload(new Date());
              setMoveKeysSlideoutOpen(false);
            }}
            cancel={() => setMoveKeysSlideoutOpen(false)}
            skuId={moveKeysSlideoutItem.skuId}
            skuName={moveKeysSlideoutItem.skuName}
            gameId={moveKeysSlideoutItem.gameId}
            gameName={moveKeysSlideoutItem.gameName}
            keyWorkflowId={moveKeysSlideoutItem.keyWorkflowId}
            keyWorkflowName={moveKeysSlideoutItem.keyWorkflowName}
          />
        ) : (
          <></>
        )}
      </SlidingPane>

      <SlidingPane
        isOpen={bulkActionsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setBulkActionsSlideoutOpen(false)}
      >
        <KeyPoolBulkActions
          done={() => {
            setReload(new Date());
            setBulkActionsSlideoutOpen(false);
          }}
          cancel={() => setBulkActionsSlideoutOpen(false)}
          items={itemsChecked}
          sku={sku}
        ></KeyPoolBulkActions>
      </SlidingPane>

      <SlidingPane
        isOpen={bulkUploadKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setBulkUploadKeysSlideoutOpen(false)}
      >
        <BulkUploadKeys
          done={(d) => {
            // setMoveTransactionIds([...moveTransactionIds, ...d]);
            // for (const moveTransactionId of d) {
            //   addToGroup(`move:${moveTransactionId}`);
            // }
            setReload(new Date());
            setBulkUploadKeysSlideoutOpen(false);
          }}
          cancel={() => setBulkUploadKeysSlideoutOpen(false)}
          skuId={sku.id}
          skuName={sku.name}
          gameId={sku.gameId}
        ></BulkUploadKeys>
      </SlidingPane>

      <SlidingPane
        isOpen={showDisableKeys}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setShowDisableKeys(false)}
      >
        <DisableKeys
          title={t('Disable keys for {gameNameSubtition}', {
            gameNameSubtition: gameNameSubsition(sku.gameName, sku.name),
          })}
          targetGameSKUId={id}
          done={(d) => {
            setShowDisableKeys(false);
          }}
          cancel={() => setShowDisableKeys(false)}
          defaultReason={t('Disable keys for {gameNameSubtition} SKU', {
            gameNameSubtition: gameNameSubsition(sku.gameName, sku.name),
          })}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={editSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setEditSlideoutOpen(false)}
      >
        <>
          {sku && workflows && territories && (
            <EditSku
              id={sku.id}
              gameId={sku.gameId}
              history={history}
              columnCssClass="col-12"
              keyProviders={keyProviders}
              platforms={platforms}
              packageTypes={packageTypes}
              currentUser={currentUser}
              workflows={workflows}
              territories={territories}
              done={() => {
                setReloadSku(new Date());
                setEditSlideoutOpen(false);
              }}
              cancel={() => setEditSlideoutOpen(false)}
            />
          )}
        </>
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    territories: state.territories,
    workflows: state.workflows,
    platforms: state.platforms,
    keyProviders: state.keyProviders,
    packageTypes: state.packageTypes,
    importTransactions: state.importTransactions,
    moveTransactions: state.moveTransactions,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  getAllTerritories,
  getAllWorkflows,
  getPlatforms,
  getKeyProviders,
  getAllPackageTypes,
  addToGroup,
  removeFromGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSku);
