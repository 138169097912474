import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';

function TableViewSwitcher({
  requestsGrouped,
  setRequestsGrouped,
  setItemsChecked,
}) {
  const { t } = useTranslation();
  return (
    <>
      <button
        className={`btn btn-${
          requestsGrouped ? 'primary' : 'default'
        } btn-outline btn--square r-corners-left`}
        onClick={(e) => {
          setRequestsGrouped(true);
          if (setItemsChecked) {
            setItemsChecked([]);
          }
        }}
        title={t('View grouped')}
      >
        <FontAwesomeIcon
          icon={icons.TABLE_GROUPED}
          fixedWidth
          aria-hidden="true"
        />
      </button>
      <button
        className={`btn btn-${
          requestsGrouped ? 'default' : 'primary'
        } btn-outline btn--square r-corners-right`}
        onClick={(e) => {
          setRequestsGrouped(false);
          if (setItemsChecked) {
            setItemsChecked([]);
          }
        }}
        title={t('View ungrouped')}
      >
        <FontAwesomeIcon
          icon={icons.TABLE_UNGROUPED}
          fixedWidth
          aria-hidden="true"
        />
      </button>
    </>
  );
}

TableViewSwitcher.propTypes = {
  requestsGrouped: PropTypes.bool.isRequired,
  setRequestsGrouped: PropTypes.func.isRequired,
  setItemsChecked: PropTypes.func,
};

export default TableViewSwitcher;
