import React from 'react';
import KwPanel from '../../../common/KwPanel';
import FormInput from '../../../common/FormInput';
import PropTypes from 'prop-types';
import FormToggleBool from '../../../common/FormToggleBool';
import { useTranslation } from 'react-i18next';

const CompanyTypeForm = ({ companyType, register, errors, control }) => {
  const { t } = useTranslation();
  return companyType ? (
    <KwPanel className="mb-4 pb-1">
      <FormInput
        register={register}
        required={true}
        error={errors.name}
        errorMessage={t('Required')}
        name="name"
        label={t('Name')}
        defaultValue={companyType.name || ''}
        autoFocus={true}
      />
      <FormToggleBool
        control={control}
        name="isDefault"
        label={t('Is default - (user lists and new companies)')}
        defaultValue={companyType.isDefault}
      />
    </KwPanel>
  ) : (
    ''
  );
};

CompanyTypeForm.propTypes = {
  companyType: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default CompanyTypeForm;
