import React from 'react';
import PropTypes from 'prop-types';
import PlatformLink from '../../common/links/PlatformLink';
import { useTranslation } from 'react-i18next';

const KeyProvider = ({ request, compressed }) => {
  const { t } = useTranslation();

  return request && compressed ? (
    <PlatformLink name={request.platform} id={request.platformId} />
  ) : (
    <div className="col-6">
      <label className="col-form-label">{t('Platform')}</label>
      <div className="flex ps-0">
        <div className="form-control-static px-3 flex h-100 w-100 align-items-center">
          <PlatformLink name={request.platform} id={request.platformId} />
        </div>
      </div>
    </div>
  );
};

KeyProvider.propTypes = {
  request: PropTypes.object.isRequired,
  compressed: PropTypes.bool,
};

export default KeyProvider;
