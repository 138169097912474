import React from 'react';
import { connect } from 'react-redux';
import { useState } from 'react';
import ApproverNotificationUserItem from './ApproverNotificationUserItem';
import {
  addKeyWorkflowStepNotificationFilter,
  deleteKeyWorkflowStepNotificationFilter,
} from '../../../../actions/workflowActions';
const ApproverNotificationUsers = ({
  keyWorkflowStepId,
  approvers,
  notificationFilters,
  ownUserOnly,
  currentUser,
}) => {
  const [_approvers, _setApprovers] = useState(approvers);
  const [_notificationFilters, _setNotificationFilters] =
    useState(notificationFilters);

  const handleClick = ({ fullName, userProfileId, emailAddress }) => {
    const existingIndex = _notificationFilters.findIndex(
      (x) => x.userProfileId === userProfileId
    );
    if (existingIndex === -1) {
      // call api to add
      addKeyWorkflowStepNotificationFilter(
        keyWorkflowStepId,
        userProfileId
      ).then(() => {
        // add
        _setNotificationFilters([
          ..._notificationFilters,
          { fullName, userProfileId, emailAddress },
        ]);
      });
    } else {
      // call api to remove
      deleteKeyWorkflowStepNotificationFilter(
        keyWorkflowStepId,
        userProfileId
      ).then(() => {
        //remove
        let result = [..._notificationFilters];
        result.splice(existingIndex, 1);
        _setNotificationFilters(result);
      });
    }
  };

  return currentUser && _approvers ? (
    <div className="tags">
      {_approvers
        .filter(
          (approver) =>
            !ownUserOnly || approver.userProfileId === currentUser.id
        )
        .map((approver, index) => (
          <span key={approver.userProfileId}>
            <ApproverNotificationUserItem
              fullName={approver.fullName}
              key={`${approver.keyWorkflowStepId} ${approver.userProfileId} ${index}`}
              userProfileId={approver.userProfileId}
              emailAddress={approver.emailAddress}
              notificationFilters={_notificationFilters}
              onClick={handleClick}
            />
          </span>
        ))}
    </div>
  ) : (
    <></>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproverNotificationUsers);
