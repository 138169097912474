import React, { useState } from 'react';
import KwPanel from '../../../common/KwPanel';
import FormButtonGroup from '../../../common/FormButtonGroup';
import FormSelect from '../../../common/FormSelect';
import PropTypes from 'prop-types';
import FormInputNumber from '../../../common/FormInputNumber';
import { useTranslation } from 'react-i18next';

const KeyPoolNotificationForm = ({
  keyPoolNotification,
  register,
  errors,
  setValue,
  watch,
  games,
  workflows,
  keyProviders,
  platforms,
}) => {
  const { t } = useTranslation();
  const [game, setGame] = useState(
    keyPoolNotification?.skuId && games
      ? games.find((g) =>
          g.skUs.find((s) => s.skuId === keyPoolNotification?.skuId)
        )
      : null
  );

  const handleOnGameChange = (el) => {
    const gameid = el.target.value;
    setGame(games.find((x) => x.id === gameid));
  };
  const watchType = watch(
    'type',
    keyPoolNotification?.keyWorkflowId
      ? 'Workflow'
      : keyPoolNotification?.keyProviderId
        ? 'Key provider'
        : keyPoolNotification.platformId
          ? 'Platform'
          : 'SKU'
  );
  const handleOnTypeChange = (btn) => {
    setValue('type', btn.value);
    switch (btn.value) {
      case 'SKU':
      default:
        clearFields(['keyWorkflowId', 'keyProviderId', 'platformId']);
        break;
      case 'Workflow':
        clearFields(['gameId', 'skuId', 'keyProviderId', 'platformId']);
        break;
      case 'Key provider':
        clearFields(['gameId', 'skuId', 'keyWorkflowId', 'platformId']);
        break;
      case 'Platform':
        clearFields(['gameId', 'skuId', 'keyWorkflowId', 'keyProviderId']);
        break;
    }
  };
  const clearFields = (fields) => {
    setGame(null);
    for (const field of fields) {
      setValue(field, null, {
        shouldDirty: true,
      });
    }
  };
  return (
    <KwPanel className="mb-4">
      <FormInputNumber
        register={register}
        required={true}
        error={errors.lowerLimit}
        errorMessage={t('Lower limit is required')}
        name="lowerLimit"
        label={t('Lower limit')}
        placeholder={t(
          'How many keys left in the pool before you get notified'
        )}
        defaultValue={keyPoolNotification.lowerLimit || ''}
        autoFocus={true}
      />
      <FormButtonGroup
        register={register}
        error={errors.type}
        errorMessage={t('Type is required')}
        name="type"
        label={t('Type')}
        buttons={[
          { value: 'SKU', text: t('SKU') },
          { value: 'Workflow', text: t('Workflow') },
          { value: 'Key provider', text: t('Key provider') },
          { value: 'Platform', text: t('Platform') },
        ]}
        onChange={handleOnTypeChange}
        value={watchType}
      ></FormButtonGroup>
      {watchType === 'SKU' && (
        <>
          <FormSelect
            register={register}
            required
            error={errors.gameId}
            errorMessage={t('Game is required')}
            name="gameId"
            label={t('Game')}
            onChange={handleOnGameChange}
            defaultValue={game?.id || ''}
          >
            <option value="">{t('--- choose ---')}</option>
            {games
              ? [...games]
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  ))
              : ''}
          </FormSelect>
          {game && (
            <FormSelect
              register={register}
              required
              error={errors.skuId}
              errorMessage={t('SKU is required')}
              name="skuId"
              label={t('SKU')}
              defaultValue={keyPoolNotification.skuId || ''}
            >
              <option value="">{t('--- choose ---')}</option>
              {[...game.skUs]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                  <option key={x.skuId} value={x.skuId}>
                    {x.name}
                  </option>
                ))}
            </FormSelect>
          )}
        </>
      )}
      {watchType === 'Workflow' && (
        <FormSelect
          register={register}
          required
          error={errors.keyWorkflowId}
          errorMessage={t('Workflow is required')}
          name="keyWorkflowId"
          label={t('Workflow')}
          defaultValue={keyPoolNotification.keyWorkflowId || ''}
        >
          <option value="">{t('--- choose ---')}</option>
          {workflows
            ? [...workflows]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))
            : ''}
        </FormSelect>
      )}
      {watchType === 'Key provider' && (
        <FormSelect
          register={register}
          required
          error={errors.keyProviderId}
          errorMessage={t('Key provider is required')}
          name="keyProviderId"
          label={t('Key provider')}
          defaultValue={keyPoolNotification.keyProviderId || ''}
        >
          <option value="">{t('--- choose ---')}</option>
          {keyProviders
            ? [...keyProviders]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))
            : ''}
        </FormSelect>
      )}
      {watchType === 'Platform' && (
        <FormSelect
          register={register}
          required
          error={errors.platformId}
          errorMessage="Platform is required"
          name="platformId"
          label="Platform"
          defaultValue={keyPoolNotification.platformId || ''}
        >
          <option value="">{t('--- choose ---')}</option>
          {platforms
            ? [...platforms]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))
            : ''}
        </FormSelect>
      )}
    </KwPanel>
  );
};

KeyPoolNotificationForm.propTypes = {
  keyPoolNotification: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  games: PropTypes.array.isRequired,
  workflows: PropTypes.array.isRequired,
  keyProviders: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
};

export default KeyPoolNotificationForm;
