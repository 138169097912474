import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ActionBar from '../../layout/ActionBar';
import KeyPoolNotificationForm from './components/KeyPoolNotificationForm';
import {
  saveKeyPoolNotification,
  getKeyPoolNotificationById,
} from '../../../actions/keyPoolNotificationActions';
import { getAllWorkflows } from '../../../actions/workflowActions';
import { getKeyProviders } from '../../../actions/keyProviderActions';
import { getPlatforms } from '../../../actions/platformActions';
import { getAllGames } from '../../../actions/gameActions';
import Loading from '../../common/Loading';
import Delete from './slideout/Delete';
import SlidingPane from 'react-sliding-pane';
import { useTranslation } from 'react-i18next';

function KeyPoolNotification({
  id,
  history,
  workflows,
  getAllWorkflows,
  keyProviders,
  getKeyProviders,
  platforms,
  getPlatforms,
  games,
  getAllGames,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    saveKeyPoolNotification({ ...keyPoolNotification, ...data })
      .then(() => {
        setKeyPoolNotification({ ...keyPoolNotification, ...data });
        toast.success(t('Key pool notification saved'));
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to save key pool notification'));
      });
  };

  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);
  const handleDelete = (e) => {
    e.cancelBubble = true;
    setDeleteSlideoutOpen(true);
    return false;
  };

  const [keyPoolNotification, setKeyPoolNotification] = useState();

  useEffect(() => {
    if (id) {
      getKeyPoolNotificationById(id)
        .then((d) => {
          setKeyPoolNotification(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/settings/key-pool-notifications/');
          toast.error(t('Key pool notification not found'));
        });
    }
  }, [id, history, t]);

  useEffect(() => {
    if (!workflows) {
      getAllWorkflows();
    }
  }, [workflows, getAllWorkflows]);

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders]);

  useEffect(() => {
    if (!platforms) {
      getPlatforms();
    }
  }, [platforms, getPlatforms]);

  useEffect(() => {
    if (!games) {
      getAllGames();
    }
  }, [games, getAllGames]);

  return keyPoolNotification ? (
    <>
      <ActionBar
        breadcrumb={[
          {
            link: '/settings/key-pool-notifications',
            label: t('Key pool notifications'),
          },
          { label: t('Key pool notification') },
        ]}
      >
        <input
          type="button"
          value={t('Delete')}
          className="btn btn-danger me-2"
          onClick={handleDelete}
        />
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      </ActionBar>
      {keyPoolNotification &&
      games &&
      workflows &&
      keyProviders &&
      platforms ? (
        <KeyPoolNotificationForm
          keyPoolNotification={keyPoolNotification}
          register={register}
          setValue={setValue}
          watch={watch}
          errors={errors}
          control={control}
          games={games}
          workflows={workflows}
          keyProviders={keyProviders}
          platforms={platforms}
        ></KeyPoolNotificationForm>
      ) : (
        ''
      )}

      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          done={(d) => {
            setDeleteSlideoutOpen(false);
            history.push('/settings/key-pool-notifications/');
          }}
          cancel={() => setDeleteSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    workflows: state.workflows,
    keyProviders: state.keyProviders,
    platforms: state.platforms,
    games: state.games,
  };
}

const mapDispatchToProps = {
  saveKeyPoolNotification,
  getAllWorkflows,
  getKeyProviders,
  getPlatforms,
  getAllGames,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyPoolNotification);
