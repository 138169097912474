import React, { useState } from 'react';
import { connect } from 'react-redux';
import MovedList from './components/MovedList';
import ActionBar from '../layout/ActionBar';

function MovedTransactions({ location, currentUser }) {
  const [reload, setReload] = useState();

  return (
    <>
      <ActionBar></ActionBar>
      <MovedList
        location={location}
        reload={reload}
        currentUser={currentUser}
      />
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MovedTransactions);
