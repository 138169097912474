import React from 'react';
import Gravatar from 'react-gravatar';
function User({ option }) {
  const data = JSON.parse(option.data);
  return (
    <>
      <Gravatar
        email={data?.Username}
        size={26}
        rating="pg"
        default="retro"
        className="img-circle me-3 ms-2"
      />
      <div className="item-content">
        <span>{option.title}</span>
        <br />
        {data.emailAddress}
      </div>
    </>
  );
}
export default User;
