import React from 'react';
import PropTypes from 'prop-types';
import KeyValue from './KeyValue';
import KeyProvider from './KeyProvider';
import Platform from './Platform';
import PackageId from './PackageId';
import KeyCount from './KeyCount';
import KebabMenu from '../../common/KebabMenu';
import GameLink from '../../common/links/GameLink';
import Button from '../../common/Button';
import icons from '../../../constants/icons';
import { RequestStatusLabel } from './RequestStatusLabel';
import { useTranslation } from 'react-i18next';

function GamesCompressedView({
  requests,
  canWithdrawRequest,
  processing,
  onWithdrawRequest,
  canSetStatus,
  onSetStatus,
  canDownloadKeys,
  onDownloadKeys,
  canRequestOnSteam,
  onSteamRequest,
  canUploadKeys,
  onUploadKeys,
  canSetKeysOrdered,
  onKeysOrdered,
  onShowDetails,
  onApproveItem,
  onDeclineItem,
  onEditRequestKeyCount,
  onEditRequestValue,
  canDisableKeys,
  onDisableKeys,
  showAllButtons,
  endUserDetailsRequired,
  handleUploadAccountDetails,
  canReturnKeys,
  handleReturnKeys,
  currentUser,
}) {
  const { t } = useTranslation();
  return (
    <table className="table table-has-buttons width-100">
      <thead>
        <tr>
          <th className="no-wrap" style={{ minWidth: '100px', width: '100px' }}>
            #
          </th>
          <th>{t('Status')}</th>
          <th>{t('Game')}</th>
          <th className="text-right num no-wrap">{t('Keys')}</th>
          <th className="text-right num no-wrap">{t('Value')}</th>
          <th>{t('Key provider')}</th>
          <th>{t('Platform')}</th>
          <th>{t('Package Id')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {requests?.map((request) => (
          <tr key={request.id} className="edit-hover">
            <td>{request.reference}</td>
            <td>
              <RequestStatusLabel
                requestStatus={request.requestStatus}
                isWaitingForKeys={request.isWaitingForKeys}
                isUpdateInProgress={request.isUpdateInProgress}
                isDownloaded={request.downloaded}
              />
            </td>
            <td>
              <GameLink
                gameId={request.gameId}
                skuId={request.skuId}
                gameTitle={request.gameTitle}
                skuName={request.skuName}
                key={request.id}
                currentUser={currentUser}
              />
            </td>
            <td className="text-right num no-wrap">
              <KeyCount
                request={request}
                processing={processing}
                onEditRequestKeyCount={onEditRequestKeyCount}
                compressed={true}
              ></KeyCount>
            </td>
            <td className="text-right num no-wrap">
              <KeyValue
                request={request}
                processing={processing}
                onEditRequestValue={onEditRequestValue}
                compressed={true}
              ></KeyValue>
            </td>
            <td>
              <KeyProvider request={request} compressed={true}></KeyProvider>
            </td>
            <td>
              <Platform request={request} compressed={true}></Platform>
            </td>
            <td>
              <PackageId
                request={request}
                currentUser={currentUser}
                compressed={true}
              ></PackageId>
            </td>
            <td className="text-right no-wrap">
              <div className="d-inline-flex">
                <KebabMenu
                  skip={1}
                  take={1}
                  reverse={true}
                  className="inline-icons"
                  buttons={[
                    canDisableKeys(request) && (
                      <Button
                        isDisabled={processing}
                        className="btn btn-danger btn-xs-full me-2"
                        dropdownclasses="danger"
                        onClick={(e) => onDisableKeys(e, request)}
                        icon={icons.DISABLE_KEYS}
                        text={t('Disable keys')}
                      />
                    ),
                    canReturnKeys(request) && (
                      <Button
                        isDisabled={processing}
                        className="btn btn-default me-2"
                        onClick={(e) => handleReturnKeys(e, request)}
                        icon={icons.RETURN_KEYS}
                        text={t('Return keys')}
                        title={t('Return keys back to key pool')}
                      ></Button>
                    ),
                    canWithdrawRequest(request) && (
                      <Button
                        isDisabled={processing}
                        className="btn btn-danger btn-xs-full me-2"
                        dropdownclasses="danger"
                        onClick={(e) => onWithdrawRequest(e, request)}
                        icon={icons.WITHDRAW_REQUEST}
                        text={t('Withdraw request')}
                      />
                    ),

                    canSetStatus(request) && (
                      <Button
                        isDisabled={processing}
                        className="btn btn-default btn-xs-full me-2"
                        onClick={(e) => onSetStatus(e, request)}
                        icon={icons.SET_STATUS}
                        text={t('Set status')}
                      />
                    ),

                    canDownloadKeys(request) && (
                      <Button
                        isDisabled={processing}
                        className="btn btn-default btn-xs-full me-2"
                        onClick={(e) => onDownloadKeys(e, request)}
                        icon={icons.DOWNLOAD_KEYS}
                        text={t('Download keys')}
                      />
                    ),

                    canRequestOnSteam(request) && (
                      <Button
                        isDisabled={processing}
                        className="btn btn-default btn-xs-full me-2"
                        onClick={(e) => onSteamRequest(e, request)}
                        icon={icons.REQUEST_ON_STEAM}
                        text={t('Request on Steam')}
                      />
                    ),
                    endUserDetailsRequired && (
                      <Button
                        className="btn btn-default btn-xs-full me-2"
                        onClick={() =>
                          handleUploadAccountDetails(
                            request.id,
                            request.numberOfKeys
                          )
                        }
                        icon={icons.UPLOAD_ACCOUNT_DETAILS}
                        text={t('Upload account details')}
                      />
                    ),
                    canSetKeysOrdered(request) && (
                      <Button
                        isDisabled={processing}
                        className="btn btn-default btn-xs-full me-2"
                        onClick={(e) => onKeysOrdered(e, request)}
                        icon={icons.SET_KEYS_ORDERED}
                        text={t('Keys ordered')}
                      />
                    ),
                    canUploadKeys(request) && (
                      <Button
                        isDisabled={processing}
                        className="btn btn-default btn-xs-full me-2"
                        onClick={(e) => onUploadKeys(e, request)}
                        icon={icons.UPLOAD_KEYS}
                        text={t('Upload keys')}
                      />
                    ),
                    <Button
                      isDisabled={processing}
                      className="btn btn-default btn-xs-full"
                      onClick={(e) => onShowDetails(e, request)}
                      icon={icons.INFO}
                      text={t('Details')}
                    />,
                  ]}
                ></KebabMenu>
                {request.currentUserCanSign && (
                  <>
                    <Button
                      isDisabled={processing}
                      title={processing ? t('Processing') : t('Approve')}
                      onClick={(e) => onApproveItem(e, request)}
                      className={`btn btn-default btn--approval r-corners-left ${
                        request.action === 'approve' ? 'active' : ''
                      }`}
                      icon={icons.APPROVE}
                      text={t('Approve')}
                      hideTitle={true}
                    />
                    <Button
                      isDisabled={processing}
                      title={processing ? t('Processing') : t('Decline')}
                      onClick={(e) => onDeclineItem(e, request)}
                      className={`btn btn-default btn--decline r-corners-right me-2 ${
                        request.action === 'decline' ? 'active' : ''
                      }`}
                      icon={icons.DECLINE}
                      text={t('Decline')}
                      hideTitle={true}
                    />
                  </>
                )}
                {request.currentUserHasSigned && (
                  <span className="badge bg-success r-corners">
                    {request.signedDate}
                  </span>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

GamesCompressedView.propTypes = {
  requests: PropTypes.array.isRequired,
  canWithdrawRequest: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  onWithdrawRequest: PropTypes.func.isRequired,
  canSetStatus: PropTypes.func.isRequired,
  onSetStatus: PropTypes.func.isRequired,
  canDownloadKeys: PropTypes.func.isRequired,
  onDownloadKeys: PropTypes.func.isRequired,
  canRequestOnSteam: PropTypes.func.isRequired,
  onSteamRequest: PropTypes.func.isRequired,
  canUploadKeys: PropTypes.func.isRequired,
  onUploadKeys: PropTypes.func.isRequired,
  canSetKeysOrdered: PropTypes.func.isRequired,
  onKeysOrdered: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  onApproveItem: PropTypes.func.isRequired,
  onDeclineItem: PropTypes.func.isRequired,
  onEditRequestKeyCount: PropTypes.func.isRequired,
  onEditRequestValue: PropTypes.func.isRequired,
  canDisableKeys: PropTypes.func.isRequired,
  onDisableKeys: PropTypes.func.isRequired,
  showAllButtons: PropTypes.bool.isRequired,
  endUserDetailsRequired: PropTypes.bool.isRequired,
  handleUploadAccountDetails: PropTypes.func.isRequired,
  canReturnKeys: PropTypes.func.isRequired,
  handleReturnKeys: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default GamesCompressedView;
