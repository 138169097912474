import {
  apiUrl,
  getList,
  deleteRecord,
  getSingleRecord,
  saveRecord,
  apiOptions,
  handleError,
  handleResponse,
  exportData,
} from './apiUtils';
const baseUrl = apiUrl + '/announcements/';

export function getAnnouncements(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function getAllAnnouncements() {
  return fetch(`${baseUrl}`, apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function getAnnouncementById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveAnnouncement(announcement) {
  return saveRecord(baseUrl, announcement);
}

export function deleteAnnouncement(id) {
  return deleteRecord(baseUrl, id);
}

export function exportAnnouncementsData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/announcements/search`,
    'announcementDate',
    filters,
    scopes
  );
}
