import React, { useState } from 'react';
import { connect } from 'react-redux';
import { loadGames, exportData } from '../../../actions/gameActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal, Date_AsString } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import AutoSelectText from '../../common/AutoSelectText';
import { hasPermission, userIs, userIsAny } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import ImageWithFallback from '../../common/ImageWithFallback';
import KebabMenu from '../../common/KebabMenu';
import Button from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import userTypes from '../../../constants/userTypes';
import icons from '../../../constants/icons';
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import CompanyLink from '../../common/links/CompanyLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
function GameList({
  location,
  reload,
  currentUser,
  itemsChecked,
  setItemsChecked,
  onRequestKeys,
  onSteamBulkRequest,
  setBulkActionsSlideoutOpen,
}) {
  const { t } = useTranslation();
  let allColumns = [
    'name',
    'publisher',
    'developer',
    'appId',
    'skuCount',
    'openRequests',
    'isArchived',
    'creationDate',
  ];
  if (
    userIsAny(currentUser, [
      userTypes.ADMIN,
      userTypes.APPROVER,
      userTypes.UPLOADER,
    ])
  ) {
    allColumns = [...allColumns, 'requestActivity', 'keyActivity'];
  }
  const suffixColumns = ['buttons-checkbox'];
  const defaultVisibleColumns = allColumns.filter(
    (x) =>
      x !== 'keyActivity' &&
      x !== 'requestActivity' &&
      x !== 'publisher' &&
      x !== 'developer'
  );
  const [games, setGames] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'gamelist-settings',
      getTableSettingsDefaults({ defaultSort: 'name', defaultVisibleColumns }),
      null,
      loadGames,
      setGames,
      reload,
      t('Failed to load games'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Game'),
      width: '30%',
      className: 'flush',
      Render: (game) => (
        <div className="game-img-wrapper">
          <ImageWithFallback
            url={game.packshotUrl}
            fallBackImage={
              <div className="game-img">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={icons.GAME} className={`game`} />
                </div>
              </div>
            }
            alt={game.name}
          ></ImageWithFallback>
          {hasPermission(currentUser, permissionTypes.MANAGE_GAMES) ? (
            <Link to={'/game/' + game.id + '?f_isArchived=Live'}>
              {game.name}
            </Link>
          ) : (
            <Link to={'/game/' + game.id}>{game.name}</Link>
          )}
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestActivity',
      Label: t('Request activity'),
      width: '5%',
      Render: (item) => (
        <Sparklines
          data={[
            item.requestActivityScore30to60Days,
            item.requestActivityScore7to30Days,
            item.requestActivityScoreLast7Days,
          ]}
          min={0}
          max={100}
          height={20}
          width={60}
          style={{
            opacity:
              item.requestActivityScore30to60Days +
                item.requestActivityScore7to30Days +
                item.requestActivityScoreLast7Days >
              0
                ? 1
                : 0.5,
          }}
        >
          <SparklinesCurve color="#46b29d" />
        </Sparklines>
      ),
    },
    {
      Key: 'keyActivity',
      Label: t('Key activity'),
      width: '5%',
      Render: (item) => (
        <Sparklines
          data={[
            item.keyActivityScore30to60Days,
            item.keyActivityScore7to30Days,
            item.keyActivityScoreLast7Days,
          ]}
          min={0}
          max={100}
          height={20}
          width={60}
          style={{
            opacity:
              item.keyActivityScore30to60Days +
                item.keyActivityScore7to30Days +
                item.keyActivityScoreLast7Days >
              0
                ? 1
                : 0.5,
          }}
        >
          <SparklinesCurve color="#bb5ebf" />
        </Sparklines>
      ),
    },
    {
      Key: 'appId',
      Label: t('App Id'),
      className: 'autoselect',
      width: '12%',
      Render: (item) => <AutoSelectText>{item.appId}</AutoSelectText>,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'publisher',
      Label: t('Publisher'),
      width: '12%',
      Render: (item) => (
        <CompanyLink
          id={item.publisherCompanyId}
          name={item.publisherCompanyName}
          imageId={item.publisherCompanyImageId}
          colourHex={item.publisherCompanyColourHex}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'developer',
      Label: t('Developer'),
      width: '12%',
      Render: (item) => (
        <CompanyLink
          id={item.developerCompanyId}
          name={item.developerCompanyName}
          imageId={item.developerCompanyImageId}
          colourHex={item.developerCompanyColourHex}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'skuCount',
      Label: t('SKUs'),
      width: '5%',
      className: 'text-right num no-wrap',
      Render: (game) =>
        game.skuCount <= 0 ? (
          <span
            className="badge bg-danger"
            title={t('There are no SKUs for this title')}
          >
            0
          </span>
        ) : (
          CommaNumber_NoDecimal(game.skuCount)
        ),
    },
    {
      Key: 'openRequests',
      Label: t('Open requests'),
      width: '5%',
      className: 'text-right num no-wrap',
      Render: (game) => CommaNumber_NoDecimal(game.openRequests),
    },
    {
      Key: 'isArchived',
      Label: t('Status'),
      width: '12%',
      Render: (game) =>
        game.isArchived && game.isLicenseExpired ? (
          <span className="badge bg-danger">
            <FontAwesomeIcon icon={icons.ARCHIVED} className="me-2" />
            {t('Archived')}
          </span>
        ) : game.isArchived ? (
          <span className="badge bg-danger">
            <FontAwesomeIcon icon={icons.ARCHIVED} className="me-2" />
            {t('Archived')}
          </span>
        ) : game.isLicenseExpired ? (
          <span className="badge bg-pink">
            <FontAwesomeIcon
              icon={icons.GAME_LICENSE_EXPIRED}
              className="me-2"
            />
            {t('Expired')}
          </span>
        ) : (
          <span className="badge bg-success">{t('Live')}</span>
        ),
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('All')}</option>
            <option value="Live">{t('Live')}</option>
            <option value="Archived">{t('Archived')}</option>
            <option value="Expired">{t('Expired')}</option>
          </select>
        ),
      },
    },
    {
      Key: 'creationDate',
      width: '12%',
      Label: t('Created'),
      Render: (game) => Date_AsString(game.creationDate),
    },
    {
      Key: 'buttons-checkbox',
      Label: '',
      className: 'text-right no-navigate',
      Sort: false,
      width: '2%',
      Filter: {
        Render: (col) => (
          <Checkbox
            disabled={!games}
            checked={false}
            onChange={(checked) =>
              checked
                ? setItemsChecked(games?.data?.filter((x) => !x.isArchived))
                : setItemsChecked([])
            }
          ></Checkbox>
        ),
      },
      Render: (item) =>
        !item.isArchived && (
          <div className="d-flex align-items-center justify-content-end">
            <KebabMenu
              take={1}
              className="inline-icons"
              buttons={[
                userIs(currentUser, userTypes.REQUESTER) &&
                  !item.isLicenseExpired &&
                  !item.isArchived &&
                  item.skuCount > 0 && (
                    <Button
                      className="btn btn-default me-2"
                      onClick={(e) => {
                        onRequestKeys(e, item);
                      }}
                      icon={icons.REQUEST_KEYS}
                      text={t('Request keys')}
                    ></Button>
                  ),
                userIs(currentUser, userTypes.UPLOADER) &&
                  !item.isLicenseExpired &&
                  !item.isArchived &&
                  item.skuCount > 0 && (
                    <Button
                      className="btn btn-default me-2"
                      onClick={(e) => onSteamBulkRequest(e, [item])}
                      icon={icons.REQUEST_ON_STEAM}
                      text={t('Request on Steam')}
                    ></Button>
                  ),
              ]}
            ></KebabMenu>
            {userIs(currentUser, userTypes.UPLOADER) && item.skuCount > 0 && (
              <Checkbox
                className="ms-3"
                checked={
                  itemsChecked && itemsChecked.some((x) => x.id === item.id)
                }
                onChange={(checked) =>
                  checked
                    ? setItemsChecked([...itemsChecked, item])
                    : setItemsChecked(
                        itemsChecked.filter((x) => x.id !== item.id)
                      )
                }
              ></Checkbox>
            )}
          </div>
        ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return games ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        suffixColumns={suffixColumnsWithConfig}
        allColumns={allColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        className="gamesTable"
        rows={games.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={games.totalRowCount}
        filteredRowCount={games.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportData}
        setBulkActionsSlideoutOpen={setBulkActionsSlideoutOpen}
        itemsChecked={itemsChecked}
        bulkActionTitle={
          itemsChecked.length === 0
            ? t('Bulk actions - first tick some games')
            : t('Bulk actions')
        }
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GameList);
