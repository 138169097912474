import React from 'react';
import PropTypes from 'prop-types';

function AppIdPackageIdRow({ game, style }) {
  return (
    <div className="grid-empty num" style={style}>
      {game.isSKU ? game.packageId : game.appId}
    </div>
  );
}

AppIdPackageIdRow.propTypes = {
  game: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
};

export default AppIdPackageIdRow;
