import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getImageById } from '../../api/imageApi';
import { hexToRgbA, lightenDarkenColor } from '../../util/formatter';
import { useTranslation } from 'react-i18next';

const imageCache = {};

export default function ImageFromApi({
  imageId,
  className = '',
  imageSize,
  fallbackIcon,
  colourHex,
}) {
  const { t } = useTranslation();
  const [preview, setPreview] = useState();
  const smallFontSize = 16;

  useEffect(() => {
    if (imageId && imageCache[imageId]) {
      setPreview(imageCache[imageId]);
    } else if (imageId) {
      getImageById(imageId)
        .then((blob) => {
          if (imageCache[imageId]) {
            setPreview(imageCache[imageId]);
          } else {
            const objectUrl = window.URL.createObjectURL(blob, {
              type: 'application/zip',
            });
            imageCache[imageId] = objectUrl;
            setPreview(objectUrl);
          }
        })
        .catch(() => {});
    }
  }, [imageId]);

  const [bgColor, setBgColor] = useState();
  const [fgColor, setFgColor] = useState();
  useEffect(() => {
    setFgColor(
      `${
        colourHex && colourHex !== 'transparent'
          ? lightenDarkenColor(colourHex, 64)
          : '#ffffff'
      }`
    );

    setBgColor(
      `${
        colourHex && colourHex !== 'transparent'
          ? hexToRgbA(colourHex, 0.3)
          : 'rgba(0, 0, 0, 0.3)'
      }`
    );
  }, [colourHex]);

  return preview ? (
    <img
      src={preview}
      alt={t('Upload')}
      className={`${className}${imageSize > smallFontSize ? ' me-3' : ' me-2'}`}
      width={imageSize || smallFontSize}
      height={imageSize || smallFontSize}
    />
  ) : fallbackIcon ? (
    <div
      className={imageSize > smallFontSize ? ' me-3' : ' me-2'}
      style={{
        minWidth: imageSize || smallFontSize,
        width: imageSize || smallFontSize,
        minHeight: imageSize || smallFontSize,
        height: imageSize || smallFontSize,
        lineHeight: `${imageSize || smallFontSize}px`,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        color: fgColor,
        backgroundColor: bgColor,
      }}
    >
      <FontAwesomeIcon icon={fallbackIcon} fixedWidth className="m-0 p-0" />
    </div>
  ) : null;
}
