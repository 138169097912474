import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import KwPanel from '../../common/KwPanel';
import { cellClick } from '../../../util/table';
import {
  CommaNumber_NoDecimal,
  CommaNumber_WithDecimal,
} from '../../../util/formatter';
import { useTranslation } from 'react-i18next';

import { scopes as tableScopes } from '../../../constants/scopes';

const RequestStats = ({
  openRequests,
  openRequestsValue,
  openRequestsSLABreached,
  openRequestsSLABreachedValue,
  requestsNeedingKeys,
  requestsNeedingKeysValue,
  keysOrdered,
  keysOrderedValue,
  requestsYouCanAuthorise,
  requestsYouCanAuthoriseValue,
}) => {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4">
      <header className="mb-2">
        <h2 className="no-margin">{t('Workflows')}</h2>
      </header>
      <table className="table dataTable width-100">
        <thead>
          <tr>
            <th></th>
            <th className="text-right num no-wrap">#</th>
            <th className="text-right num no-wrap">{t('Value')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td onClick={cellClick}>
              <NavLink to={`/requests/all?f_requestStatus=pending%20approval`}>
                {t('All open requests')}
              </NavLink>
            </td>
            <td className="text-right num no-wrap" onClick={cellClick}>
              {CommaNumber_NoDecimal(openRequests || 0)}
            </td>
            <td className="text-right num no-wrap" onClick={cellClick}>
              {CommaNumber_WithDecimal(openRequestsValue || 0)}
            </td>
          </tr>
          <tr>
            <td onClick={cellClick}>
              <NavLink to={`/requests/all?${tableScopes.ISOVERSLA}=true`}>
                {t('Open requests SLA breached')}
              </NavLink>
            </td>
            <td className="text-right num no-wrap" onClick={cellClick}>
              {CommaNumber_NoDecimal(openRequestsSLABreached || 0)}
            </td>
            <td className="text-right num no-wrap" onClick={cellClick}>
              {CommaNumber_WithDecimal(openRequestsSLABreachedValue || 0)}
            </td>
          </tr>
          <tr>
            <td onClick={cellClick}>
              <NavLink to={`/requests/all?f_requestStatus=pending%20keys`}>
                {t('Requests - needing keys')}
              </NavLink>
            </td>
            <td className="text-right num no-wrap" onClick={cellClick}>
              {CommaNumber_NoDecimal(requestsNeedingKeys || 0)}
            </td>
            <td className="text-right num no-wrap" onClick={cellClick}>
              {CommaNumber_WithDecimal(requestsNeedingKeysValue || 0)}
            </td>
          </tr>
          <tr>
            <td onClick={cellClick}>
              <NavLink to={`/requests/all?f_requestStatus=keys%20ordered`}>
                {t('Requests - keys ordered')}
              </NavLink>
            </td>
            <td className="text-right num no-wrap" onClick={cellClick}>
              {CommaNumber_NoDecimal(keysOrdered || 0)}
            </td>
            <td className="text-right num no-wrap" onClick={cellClick}>
              {CommaNumber_WithDecimal(keysOrderedValue || 0)}
            </td>
          </tr>
          <tr>
            <td onClick={cellClick}>
              <NavLink to={`/requests/approve`}>
                {t('Requests you can authrorise')}
              </NavLink>
            </td>
            <td className="text-right num no-wrap" onClick={cellClick}>
              {CommaNumber_NoDecimal(requestsYouCanAuthorise || 0)}
            </td>
            <td className="text-right num no-wrap" onClick={cellClick}>
              {CommaNumber_WithDecimal(requestsYouCanAuthoriseValue || 0)}
            </td>
          </tr>
        </tbody>
      </table>
    </KwPanel>
  );
};

RequestStats.propTypes = {
  openRequests: PropTypes.number.isRequired,
  openRequestsValue: PropTypes.number.isRequired,
  openRequestsSLABreached: PropTypes.number.isRequired,
  openRequestsSLABreachedValue: PropTypes.number.isRequired,
  requestsNeedingKeys: PropTypes.number.isRequired,
  requestsNeedingKeysValue: PropTypes.number.isRequired,
  requestsYouCanAuthorise: PropTypes.number.isRequired,
  requestsYouCanAuthoriseValue: PropTypes.number.isRequired,
};

export default RequestStats;
