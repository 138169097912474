import React from 'react';
import KwPanel from '../../common/KwPanel';
import FormInput from '../../common/FormInput';
import PropTypes from 'prop-types';
import FormSelect from '../../common/FormSelect';
import FormDate from '../../common/FormDate';
import { useTranslation } from 'react-i18next';

const PromotionForm = ({
  promotion,
  register,
  errors,
  control,
  keyProviders,
  territories,
  getValues,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <KwPanel className="space-bottom pb-2">
        <header className="space-bottom">
          <h2 className="no-margin">{t('Promotion details')}</h2>
        </header>
        <FormInput
          register={register}
          required
          errorMessage={t('Required')}
          error={errors.name}
          name="name"
          label={t('Name')}
          placeholder={t('Name of the promotion')}
          defaultValue={promotion.name || ''}
        />
        <FormSelect
          register={register}
          required
          errorMessage={t('Required')}
          error={errors.keyProviderId}
          name="keyProviderId"
          label={t('Key provider')}
          placeholder=""
          defaultValue={promotion.keyProviderId || ''}
        >
          <option value="">{t('--- choose ---')}</option>
          {keyProviders &&
            [...keyProviders]
              ?.sort((a, b) => a.name.localeCompare(b.name))
              ?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
        </FormSelect>
        <FormSelect
          register={register}
          required
          errorMessage={t('Required')}
          error={errors.territoryId}
          name="territoryId"
          label={t('Territory')}
          placeholder=""
          defaultValue={promotion.territoryId || ''}
        >
          <option value="">{t('--- choose ---')}</option>
          {territories &&
            [...territories]
              ?.sort((a, b) => a.name.localeCompare(b.name))
              ?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
        </FormSelect>
        <FormDate
          register={register}
          required
          errorMessage={t('Required')}
          name="start"
          label={t('Start date')}
          defaultValue={promotion.start || ''}
        />
        <FormDate
          register={register}
          required
          errorMessage={t('Required')}
          name="end"
          label={t('End date')}
          defaultValue={promotion.end || ''}
        />
      </KwPanel>
    </>
  );
};

PromotionForm.propTypes = {
  promotion: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  keyProviders: PropTypes.array.isRequired,
};

export default PromotionForm;
