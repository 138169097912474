import React from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import RequestsByMonthByAuthorisingUserList from './components/month/RequestsByMonthListByAuthorisingUser';
import RequestsByWeekByAuthorisingUserList from './components/week/RequestsByWeekListByAuthorisingUser';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import ReportTableViewSwitcher from './components/ReportTableViewSwitcher';

function RequestsByAuthorisingUser({ location }) {
  const [groupBy, setGroupBy] = useLocalStorage('report-groupBy', 'month');

  return (
    <>
      <ActionBar></ActionBar>
      {groupBy === 'month' ? (
        <RequestsByMonthByAuthorisingUserList
          location={location}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      ) : (
        <RequestsByWeekByAuthorisingUserList
          location={location}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsByAuthorisingUser);
