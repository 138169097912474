import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import TableCell from './TableCell';
export default function TableRowFileUpload({
  row,
  rows,
  totalRowCount,
  sortedField,
  trStyle,
  columnsWithSuffix,
  handleRowFileUpload,
}) {
  let renderStyle = (row) => (trStyle !== undefined ? trStyle(row) : null);
  const onDrop = (droppedFiles) => {
    handleRowFileUpload(row, droppedFiles[0]);
  };
  const { getRootProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone({ onDrop, noClick: true });
  const classeNames = useMemo(
    () =>
      `row-drop-zone ${isDragActive ? 'drag-active' : ''} ${
        isDragAccept ? 'accept' : ''
      } ${isDragReject ? 'reject' : ''}`,
    [isDragActive, isDragReject, isDragAccept]
  );
  return (
    <tr
      key={row.Key}
      style={renderStyle(row)}
      {...getRootProps({ className: classeNames })}
    >
      {columnsWithSuffix.map((col) => {
        return (
          <TableCell
            key={col.Key}
            col={col}
            row={row}
            rows={rows}
            totalRowCount={totalRowCount}
            sortedField={sortedField}
          ></TableCell>
        );
      })}
    </tr>
  );
}
