import * as permissionApi from '../api/permissionApi';

export function getPermissions() {
  return permissionApi.getAllPermissions();
}

// by group
export function getPermissionGroup(userProfileGroupId) {
  return permissionApi.getPermissionGroup(userProfileGroupId);
}
export function addPermissionGroup(permissionKeys, userProfileGroupId) {
  return permissionApi.addPermissionGroup(permissionKeys, userProfileGroupId);
}
export function deletePermissionGroup(permissionKeys, userProfileGroupId) {
  return permissionApi.deletePermissionGroup(
    permissionKeys,
    userProfileGroupId
  );
}
// by user
export function getPermissionUser(userProfileId) {
  return permissionApi.getPermissionUser(userProfileId);
}
export function addPermissionUser(permissionKeys, userProfileId) {
  return permissionApi.addPermissionUser(permissionKeys, userProfileId);
}
export function deletePermissionUser(permissionKeys, userProfileId) {
  return permissionApi.deletePermissionUser(permissionKeys, userProfileId);
}
