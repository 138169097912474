import * as keyProviderApi from '../api/keyProviderApi';
import actionTypes from '../constants/actionTypes';

export function saveKeyProvider(keyProvider) {
  return function (dispatch) {
    return keyProviderApi
      .saveKeyProvider(keyProvider)
      .then((savedKeyProvider) => {
        dispatch({
          type: keyProvider.id
            ? actionTypes.UPDATE_KEYPROVIDER
            : actionTypes.CREATE_KEYPROVIDER,
          keyProvider: { ...keyProvider, id: savedKeyProvider.id, file: null },
        });
      });
  };
}

export function getKeyProviderById(id) {
  return keyProviderApi.getKeyProviderById(id);
}

export function getKeyProviders() {
  return function (dispatch) {
    return keyProviderApi.getAllKeyProviders().then((keyProviders) => {
      keyProviders = keyProviders.sort((a, b) => (a.name > b.name && 1) || -1);
      dispatch({
        type: actionTypes.LOAD_KEYPROVIDERS,
        keyProviders,
      });
    });
  };
}

export function loadKeyProviders({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return keyProviderApi.getKeyProviders(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getStats(id) {
  return keyProviderApi.getStats(id);
}

export function getStatsByDay(id) {
  return keyProviderApi.getStatsByDay(id);
}

export function getAggregateStats(id, from, to) {
  return keyProviderApi.getAggregateStats(id, from, to);
}

export function getAudit(id) {
  return keyProviderApi.getAudit(id);
}

export function addAudit(id, comment) {
  return keyProviderApi.addAudit(id, comment);
}

export function deleteKeyProvider(id) {
  return function (dispatch) {
    return keyProviderApi.deleteKeyProvider(id).then(() => {
      dispatch({
        type: actionTypes.DELETE_KEYPROVIDER,
        id,
      });
    });
  };
}

export function getKeyProviderRequestInformation(id) {
  return keyProviderApi.getKeyProviderRequestInformation(id);
}
export function saveKeyProviderRequestInformation(id, requestInformation) {
  return keyProviderApi.saveKeyProviderRequestInformation(id, {
    items: requestInformation,
  });
}

export function getKeyProviderPromotionSetting(id) {
  return keyProviderApi.getKeyProviderPromotionSetting(id);
}
export function saveKeyProviderPromotionSetting(id, promotionSetting) {
  return keyProviderApi.saveKeyProviderPromotionSetting(id, promotionSetting);
}

export function getPlatformsForKeyProvider(id) {
  return keyProviderApi.getPlatformsForKeyProvider(id);
}

export function saveKeyProviderPlatform(keyProviderId, platformId) {
  return keyProviderApi.saveKeyProviderPlatform(keyProviderId, platformId);
}

export function deleteKeyProviderPlatform(keyProviderId, platformId) {
  return keyProviderApi.deleteKeyProviderPlatform(keyProviderId, platformId);
}

export function exportKeyProviderData(filters, scopes) {
  return keyProviderApi.exportKeyProviderData(filters, scopes);
}
