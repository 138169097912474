import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import ActionBar from '../layout/ActionBar';
import RequestsToBeApprovedList from './components/RequestsToBeApprovedList';
import RequestKeys from './slideout/RequestKeys';
import {
  approveRequest,
  checkRequestTransactionLimits,
  declineRequest,
} from '../../actions/requestActions';
import { toast } from 'react-toastify';
import RequestTransactionWarning from './slideout/RequestTransactionWarning';
import { userIs } from '../auth/authUtils';
import userTypes from '../../constants/userTypes';
import SlidingPane from 'react-sliding-pane';
import TableViewSwitcher from './components/TableViewSwitcher';
import RequestsToBeApprovedGroupedList from './components/RequestsToBeApprovedGroupedList';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

function RequestsToBeApproved({
  currentUser,
  location,
  history,
  setCompleteRequestSlideoutOpen,
}) {
  const { t } = useTranslation();
  const [requestsGrouped, setRequestsGrouped] = useLocalStorage(
    'requestsGrouped',
    false
  );
  const [reload, setReload] = useState();
  const [requestKeysSlideoutOpen, setRequestKeysSlideoutOpen] = useState(false);
  const [
    requestTransactionWarningSlideoutOpen,
    setRequestTransactionWarningSlideoutOpen,
  ] = useState(false);
  const [
    requestTransactionWarningSlideoutData,
    setRequestTransactionWarningSlideoutData,
  ] = useState();

  const handleApproveRequest = (
    requestId,
    signature,
    overrideRequestValue,
    onDone
  ) => {
    checkRequestTransactionLimits(requestId, overrideRequestValue).then((d) => {
      if (d.isOverTransactionLimit || d.isOverGlobalTransactionLimit) {
        // show warning slideout for confirmation
        setRequestTransactionWarningSlideoutData({
          requestId,
          signature,
          overrideRequestValue,
          onDone,
          d,
        });
        setRequestTransactionWarningSlideoutOpen(true);
      } else {
        // approve request
        approveRequest(requestId, signature, overrideRequestValue, onDone).then(
          (r) => {
            if (r.warningMessages) {
              r.warningMessages.forEach((message) =>
                toast.warn(t('Warning : {message}', { message: message }))
              );
            }
          }
        );
        onDone(true).catch((e) => {
          onDone(false);
          console.error(e);
          toast.error(e.message);
        });
      }
    });
  };

  const handleDeclineRequest = (requestId, declineReason, onDone) => {
    declineRequest(requestId, declineReason)
      .then((r) => {
        if (r.warningMessages) {
          r.warningMessages.forEach((message) =>
            toast.warn(t('Warning : {message}', { message: message }))
          );
        }
        onDone(true);
      })
      .catch((e) => {
        onDone(false);
        console.error(e);
        toast.error(e.message);
      });
  };

  return (
    <>
      <ActionBar>
        {userIs(currentUser, userTypes.REQUESTER) && (
          <Button
            className="btn btn-primary"
            onClick={() => setRequestKeysSlideoutOpen(true)}
            text={t('Request keys')}
          />
        )}
      </ActionBar>
      {requestsGrouped ? (
        <RequestsToBeApprovedGroupedList
          location={location}
          reload={reload}
          history={history}
          onApproveRequest={handleApproveRequest}
          onDeclineRequest={handleDeclineRequest}
          tableViewSwitcher={
            <TableViewSwitcher
              requestsGrouped={requestsGrouped}
              setRequestsGrouped={setRequestsGrouped}
            ></TableViewSwitcher>
          }
        />
      ) : (
        <RequestsToBeApprovedList
          location={location}
          reload={reload}
          history={history}
          onApproveRequest={handleApproveRequest}
          onDeclineRequest={handleDeclineRequest}
          tableViewSwitcher={
            <TableViewSwitcher
              requestsGrouped={requestsGrouped}
              setRequestsGrouped={setRequestsGrouped}
            ></TableViewSwitcher>
          }
        />
      )}

      <SlidingPane
        isOpen={requestKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRequestKeysSlideoutOpen(false)}
      >
        <RequestKeys
          done={() => {
            setRequestKeysSlideoutOpen(false);
            setCompleteRequestSlideoutOpen(true);
            setReload(new Date());
          }}
          cancel={() => setRequestKeysSlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={requestTransactionWarningSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRequestTransactionWarningSlideoutOpen(false)}
      >
        <>
          {requestTransactionWarningSlideoutData && (
            <RequestTransactionWarning
              done={() => {
                // approve request
                approveRequest(
                  requestTransactionWarningSlideoutData.requestId,
                  requestTransactionWarningSlideoutData.signature,
                  requestTransactionWarningSlideoutData.overrideRequestValue
                )
                  .then((r) => {
                    //toast.success(r.message);
                    if (r.warningMessages) {
                      r.warningMessages.forEach((message) =>
                        toast.warn(
                          t('Warning : {message}', { message: message })
                        )
                      );
                    }
                    setRequestTransactionWarningSlideoutOpen(false);
                    requestTransactionWarningSlideoutData.onDone(true);
                    //setReload(new Date());
                  })
                  .catch((e) => {
                    setRequestTransactionWarningSlideoutOpen(false);
                    requestTransactionWarningSlideoutData.onDone(false);
                    console.error(e);
                    toast.error(e.message);
                  });
              }}
              cancel={() => {
                setRequestTransactionWarningSlideoutOpen(false);
                requestTransactionWarningSlideoutData.onDone(false);
              }}
              transactionLimits={requestTransactionWarningSlideoutData.d}
            />
          )}
        </>
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return { location: ownProps.location, currentUser: state.auth };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsToBeApproved);
