import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../../../components/common/Loading';
import MemberBadges from './components/MemberBadges';
import { getUserProfileGroups } from '../../../actions/userProfileGroupActions';
import { getPermissions } from '../../../actions/permissionActions';
import { toast } from 'react-toastify';
import permissionTypes from '../../../constants/permissionTypes';
import { hasFeature } from '../../auth/authUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';

function Permissions({ currentUser }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState();
  const [permissions, setPermissions] = useState();

  const [reload, setReload] = useState();
  const [isError, setIsError] = useState();
  const [loadingMessage, setLoadingMessage] = useState();

  useEffect(() => {
    // get the groups
    getUserProfileGroups()
      .then((d) => {
        setGroups(d.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    setIsError(false);
    // get the permissions
    getPermissions()
      .then((d) => {
        setPermissions(d.data);
        setLoading(false);
      })
      .catch((e) => {
        setIsError(true);
        toast.error(t('Failed to get permissions'));
        setLoadingMessage(t('Sorry, failed to get permissions'));
        console.error(e);
      });
  }, [reload, t]);

  const getPermissionMembers = (permissionKey) => {
    if (permissions) {
      let permission = permissions.find(
        (x) => x.permissionKey === permissionKey
      );
      if (permission) {
        return [...permission.userProfileGroups, ...permission.userProfiles];
      }
      console.error(`Permission not found ${permissionKey}`);
      return null;
    }
    return [];
  };

  // Using loading here as it's ok to have no permissions at all, so cant use permissions ?
  return !loading ? (
    <>
      <h1 className="my-4">
        <FontAwesomeIcon icon={icons.KEYS} className="c-blue mx-3" />
        {t('Keys')}
      </h1>
      <MemberBadges
        label={t('Manage keys')}
        description={t('Full management of the key lifecycle')}
        permissionKey={permissionTypes.MANAGE_KEYS}
        members={getPermissionMembers(permissionTypes.MANAGE_KEYS)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
      <MemberBadges
        label={t('Upload activations')}
        description={t('Upload activated keys')}
        permissionKey={permissionTypes.UPLOAD_ACTIVATIONS}
        members={getPermissionMembers(permissionTypes.UPLOAD_ACTIVATIONS)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
      <MemberBadges
        label={t('Search keys')}
        description={t('Search for keys in Alaska by full key code or tag')}
        permissionKey={permissionTypes.KEYS_SEARCH}
        members={getPermissionMembers(permissionTypes.KEYS_SEARCH)}
        groups={groups}
        reload={() => setReload(new Date())}
      />

      <h1 className="my-4">
        <FontAwesomeIcon icon={icons.USERS} className="c-blue mx-3" />
        {t('Users')}
      </h1>
      <MemberBadges
        label={t('Request on behalf of another user')}
        description={t(
          'Create a key request on behalf of someone else in any company'
        )}
        permissionKey={permissionTypes.REQUEST_ON_BEHALF_OF}
        members={getPermissionMembers(permissionTypes.REQUEST_ON_BEHALF_OF)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
      <MemberBadges
        label={t('Manage user groups')}
        description={t('Manage list of available user groups.')}
        permissionKey={permissionTypes.MANAGE_USERGROUPS}
        members={getPermissionMembers(permissionTypes.MANAGE_USERGROUPS)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
      <MemberBadges
        label={t('Manage permissions')}
        description={t('Manage permissions (this screen)')}
        permissionKey={permissionTypes.MANAGE_PERMISSIONS}
        members={getPermissionMembers(permissionTypes.MANAGE_PERMISSIONS)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
      <MemberBadges
        label={t('Manage users')}
        description={t('Invite / Delete / Edit all users of the system.')}
        permissionKey={permissionTypes.MANAGE_USERS}
        members={getPermissionMembers(permissionTypes.MANAGE_USERS)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
      <MemberBadges
        label={t('Impersonate users')}
        description={t('Login as any user, see what they see, act as them.')}
        permissionKey={permissionTypes.IMPERSONATE_USERS}
        members={getPermissionMembers(permissionTypes.IMPERSONATE_USERS)}
        groups={groups}
        reload={() => setReload(new Date())}
      />

      <h1 className="mx-1 my-4">
        <FontAwesomeIcon icon={icons.GRID} className="c-blue mx-3" />
        {t('Reports')}
      </h1>
      <MemberBadges
        label={t('View reports')}
        description=""
        permissionKey={permissionTypes.VIEW_REPORTS}
        members={getPermissionMembers(permissionTypes.VIEW_REPORTS)}
        groups={groups}
        reload={() => setReload(new Date())}
      />

      <h1 className="mx-1 my-4">
        <FontAwesomeIcon icon={icons.SETTINGS} className="c-blue mx-3" />
        {t('Settings')}
      </h1>
      <MemberBadges
        label={t('Manage companies')}
        description=""
        permissionKey={permissionTypes.MANAGE_COMPANIES}
        members={getPermissionMembers(permissionTypes.MANAGE_COMPANIES)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
      <MemberBadges
        label={t('Manage games')}
        description=""
        permissionKey={permissionTypes.MANAGE_GAMES}
        members={getPermissionMembers(permissionTypes.MANAGE_GAMES)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
      <MemberBadges
        label={t('Manage platforms')}
        description=""
        permissionKey={permissionTypes.MANAGE_PLATFORMS}
        members={getPermissionMembers(permissionTypes.MANAGE_PLATFORMS)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
      {hasFeature(currentUser, 'Promotions') && (
        <MemberBadges
          label={t('Manage promotions')}
          description=""
          permissionKey={permissionTypes.MANAGE_PROMOTIONS}
          members={getPermissionMembers(permissionTypes.MANAGE_PROMOTIONS)}
          groups={groups}
          reload={() => setReload(new Date())}
        />
      )}
      {hasFeature(currentUser, 'Pricing') && (
        <MemberBadges
          label={t('Manage pricing')}
          description=""
          permissionKey={permissionTypes.MANAGE_PRICING}
          members={getPermissionMembers(permissionTypes.MANAGE_PRICING)}
          groups={groups}
          reload={() => setReload(new Date())}
        />
      )}
      <MemberBadges
        label={t('Manage settings')}
        description=""
        permissionKey={permissionTypes.MANAGE_SETTINGS}
        members={getPermissionMembers(permissionTypes.MANAGE_SETTINGS)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
      <MemberBadges
        label={t('Manage territories')}
        description=""
        permissionKey={permissionTypes.MANAGE_TERRITORIES}
        members={getPermissionMembers(permissionTypes.MANAGE_TERRITORIES)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
      <MemberBadges
        label={t('Manage workflows')}
        description=""
        permissionKey={permissionTypes.MANAGE_WORKFLOWS}
        members={getPermissionMembers(permissionTypes.MANAGE_WORKFLOWS)}
        groups={groups}
        reload={() => setReload(new Date())}
      />
    </>
  ) : (
    <Loading message={loadingMessage} isError={isError}></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);
