import React from 'react';
import KwPanel from '../../../common/KwPanel';
import PropTypes from 'prop-types';
import FormStatic from '../../../common/FormStatic';
import Loading from '../../../common/Loading';
import {
  Date_AsString_NoHtml,
  TryFormatJson,
} from '../../../../util/formatter';
import YesNoLabel from '../../../common/YesNoLabel';
import { useTranslation } from 'react-i18next';

const EmailForm = ({ email }) => {
  const { t } = useTranslation();
  return email ? (
    <KwPanel className="pb-2">
      <FormStatic label={t('To address')}>{email.toAddress}</FormStatic>
      {email.ccAddress && (
        <FormStatic label={t('Cc address')}>{email.ccAddress}</FormStatic>
      )}
      {email.bccAddress && (
        <FormStatic label={t('Bcc address')}>{email.bccAddress}</FormStatic>
      )}
      <FormStatic label={t('Subject')}>{email.subject}</FormStatic>
      <FormStatic label={t('Email template')}>{email.viewName}</FormStatic>
      <FormStatic label={t('Data')}>
        <div className="num" style={{ whiteSpace: 'pre', overflowX: 'auto' }}>
          {TryFormatJson(email.templateData)}
        </div>
      </FormStatic>
      <FormStatic label={t('Success')}>
        <YesNoLabel value={email.success} />
      </FormStatic>
      {email.lastError && (
        <FormStatic label={t('Error')}>
          <div className="num" style={{ whiteSpace: 'pre', overflowX: 'auto' }}>
            {email.lastError}
          </div>
        </FormStatic>
      )}
      <FormStatic label={t('Last send time')}>
        {Date_AsString_NoHtml(email.lastSendTime)}
      </FormStatic>
      <FormStatic label={t('Retry count')}>{email.retryCount}</FormStatic>
      <FormStatic label={t('Next send time')}>
        {Date_AsString_NoHtml(email.nextSendTime)}
      </FormStatic>
      <FormStatic label={t('Expire time')}>
        {Date_AsString_NoHtml(email.expireDateTime)}
      </FormStatic>
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
};

EmailForm.propTypes = {
  email: PropTypes.shape({
    id: PropTypes.string,
    toAddress: PropTypes.string,
    ccAddress: PropTypes.string,
    bccAddress: PropTypes.string,
    subject: PropTypes.string,
    viewName: PropTypes.string,
    templateData: PropTypes.string,
    success: PropTypes.bool,
    retryCount: PropTypes.number,

    lastSendTime: PropTypes.string,
    lastError: PropTypes.string,
    nextSendTime: PropTypes.string,
    expireDateTime: PropTypes.string,
    creationDate: PropTypes.string,
  }).isRequired,
};

export default EmailForm;
