import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import KwPanel from '../../../common/KwPanel';
import Button from '../../../common/Button';
import ConfirmationHeader from '../../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function RemovePermission({ done, permissionKeys, cancel }) {
  const { t } = useTranslation();
  const handleDelete = () => {
    done(permissionKeys);
  };

  return (
    <SlideoutLayout
      title={t('Remove confirmation')}
      cancel={cancel}
      done={done}
    >
      <KwPanel>
        <ConfirmationHeader text={t('Are you sure?')} />
        <Button
          className="btn btn-default me-2"
          onClick={handleDelete}
          text={t('Yes, remove this permission')}
        />
        <Button
          className="btn btn-primary"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
RemovePermission.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RemovePermission);
