import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { signoutRedirectCallback } from '../../services/userService';
import { useHistory } from 'react-router-dom';
import { userSignedOut } from '../../actions/authActions';
import { useTranslation } from 'react-i18next';

function SignoutOidc({ userSignedOut }) {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback();
      userSignedOut().then(() => {
        history.push('/');
      });
    }
    signoutAsync();
  }, [history, userSignedOut]);

  return <div>{t('Redirecting...')}</div>;
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  userSignedOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignoutOidc);
