import React from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import WorkflowsByRequestersList from './components/WorkflowsByRequestersList';

function WorkflowsByRequesters({ location, currentUser }) {
  return (
    <>
      <ActionBar></ActionBar>
      <WorkflowsByRequestersList location={location} currentUser={currentUser} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowsByRequesters);
