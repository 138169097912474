import React, { useState } from 'react';
import { connect } from 'react-redux';
import SkuList from './components/SkuList';

import RequestKeys from '../requests/slideout/RequestKeys';
import Loading from '../common/Loading';
import { userIs } from '../auth/authUtils';
import userTypes from '../../constants/userTypes';
import BasicGameDetails from './components/BasicGameDetails';
import SlidingPane from 'react-sliding-pane';

const ManageGame = ({
  id,
  history,
  location,
  currentUser,
  setCompleteRequestSlideoutOpen,
  game,
}) => {
  const [reload] = useState();
  const [requestKeysSlideoutOpen, setRequestKeysSlideoutOpen] = useState(false);
  const [requestKeysSKU, setRequestKeysSKU] = useState();

  const handleOnRequestKeys = (e, sku) => {
    e.cancelBubble = true;
    setRequestKeysSKU(sku);
    setRequestKeysSlideoutOpen(true);
    return false;
  };

  return game ? (
    <>
      <BasicGameDetails game={game}></BasicGameDetails>

      <SkuList
        location={location}
        reload={reload}
        gameId={id}
        publisherCompanyId={game.publisherCompanyId}
        onRequestKeys={handleOnRequestKeys}
        canRequestKeys={userIs(currentUser, userTypes.REQUESTER)}
        canUploadKeys={userIs(currentUser, userTypes.UPLOADER)}
        locked={game.isArchived}
      />

      {requestKeysSKU && (
        <SlidingPane
          isOpen={requestKeysSlideoutOpen}
          hideHeader={true}
          from="right"
          className="small-side-panel"
          onRequestClose={() => setRequestKeysSlideoutOpen(false)}
        >
          <RequestKeys
            gameId={requestKeysSKU.gameId}
            skuId={requestKeysSKU.id}
            done={() => {
              setRequestKeysSlideoutOpen(false);
              setCompleteRequestSlideoutOpen(true);
            }}
            cancel={() => setRequestKeysSlideoutOpen(false)}
          />
        </SlidingPane>
      )}
    </>
  ) : (
    <Loading></Loading>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.id,
    location: ownProps.location,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageGame);
