import React, { useEffect, useState, useRef } from 'react';
import WizardStepItem from './WizardStepItem';
import icons from '../../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export default function SelectGameStep({ games, handleGameSelect }) {
  const textinput = useRef();
  const [filteredGames, setFilteredGames] = useState(games);
  const [filter, setFilter] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    const filteredGames = games?.filter(
      (x) =>
        x.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        (x.appId ?? '').toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
    setFilteredGames(filteredGames);
  }, [filter, games]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (textinput?.current) {
        textinput.current.focus();
      }
    }, 800);
    return () => clearTimeout(timer);
  }, [games]);

  return (
    <>
      <h2 className="mb-4">{t('Which game is it for?')}</h2>
      <div className="wizard-wrapper">
        <input
          type="search"
          ref={textinput}
          className="form-control mb-4"
          placeholder={t('search...')}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        ></input>
        {filteredGames?.length > 0 ? (
          [...filteredGames]
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((game) => (
              <WizardStepItem
                key={game.id}
                id={game.id}
                title={game.name}
                description={
                  game.appId && (
                    <div className="text-yellow">
                      <FontAwesomeIcon
                        icon={icons.APP_ID}
                        className={`ignore me-2`}
                      />
                      {game.appId}
                    </div>
                  )
                }
                icon={icons.WIZARD_NEXT}
                onClick={handleGameSelect}
                typeIcon={`${icons.GAME} text-green`}
              ></WizardStepItem>
            ))
        ) : (
          <div className="no-data">
            <FontAwesomeIcon icon={icons.SEARCH} className={`me-2`} />
            {t('Filter returned 0 results')}
          </div>
        )}
      </div>
    </>
  );
}
