import React from 'react';
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import {
  CommaNumber_WithDecimal,
  CommaNumber_NoDecimal,
} from '../../../util/formatter';
import YesNoLabel from '../../common/YesNoLabel';
import { NavLink } from 'react-router-dom';
import CompanyLink from '../../common/links/CompanyLink';
import { SimpleTextFilter } from '../../common/Table';
import PropTypes from 'prop-types';
import { scopes as tableScopes } from '../../../constants/scopes';
import { useTranslation } from 'react-i18next';

function CompaniesListColumnsConfig({ params, filterChange, companyTypes }) {
  const { t } = useTranslation();
  return [
    {
      Key: 'companyName',
      Label: t('Company'),
      className: 'flush',
      Render: (item) => (
        <div
          className="p-2 d-flex align-items-center company-td"
          style={
            item.colourHex
              ? {
                  borderLeft: `3px solid ${item.colourHex}`,
                }
              : null
          }
        >
          <CompanyLink
            id={item.id}
            name={item.companyName}
            imageId={item.imageId}
            imageSize={32}
            colourHex={item.colourHex}
          />
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'companyTypeName',
      Label: t('Company type'),
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('--- All ---')}</option>
            {companyTypes &&
              [...companyTypes]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((x) => (
                  <option key={x.id} value={x.name}>
                    {x.name}
                  </option>
                ))}
          </select>
        ),
      },
    },
    {
      Key: 'requestActivity',
      Sort: true,
      Label: t('Request activity'),
      width: '5%',
      Render: (item) => (
        <Sparklines
          data={[
            item.requestActivityScore30to60Days,
            item.requestActivityScore7to30Days,
            item.requestActivityScoreLast7Days,
          ]}
          min={0}
          max={100}
          height={20}
          width={60}
          style={{
            opacity:
              item.requestActivityScore30to60Days +
                item.requestActivityScore7to30Days +
                item.requestActivityScoreLast7Days >
              0
                ? 1
                : 0.5,
          }}
        >
          <SparklinesCurve color="#46b29d" />
        </Sparklines>
      ),
    },
    {
      Key: 'keyActivity',
      Sort: true,
      Label: t('Key activity'),
      width: '5%',
      Render: (item) => (
        <Sparklines
          data={[
            item.keyActivityScore30to60Days,
            item.keyActivityScore7to30Days,
            item.keyActivityScoreLast7Days,
          ]}
          min={0}
          max={100}
          height={20}
          width={60}
          style={{
            opacity:
              item.keyActivityScore30to60Days +
                item.keyActivityScore7to30Days +
                item.keyActivityScoreLast7Days >
              0
                ? 1
                : 0.5,
          }}
        >
          <SparklinesCurve color="#bb5ebf" />
        </Sparklines>
      ),
    },
    {
      Key: 'isProformaInvoicing',
      Label: t('Pro-forma invoicing'),
      Render: (item) => <YesNoLabel value={item.isProformaInvoicing} />,
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('All')}</option>
            <option value="true">{t('Yes')}</option>
            <option value="false">{t('No')}</option>
          </select>
        ),
      },
    },
    {
      Key: 'transactionLimit',
      Label: t('Max open request value'),
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_WithDecimal(item.transactionLimit),
    },
    {
      Key: 'globalTransactionLimit',
      Label: t('Max balance allocated keys'),
      className: 'text-right num no-wrap',
      Render: (item) =>
        item.isOverGlobalTransactionLimit ? (
          <span className="badge bg-danger">
            {CommaNumber_WithDecimal(item.globalTransactionLimit)}
          </span>
        ) : (
          CommaNumber_WithDecimal(item.globalTransactionLimit)
        ),
    },
    {
      Key: 'requestsOpen',
      Label: t('Open'),
      className: 'text-right num no-wrap',
      Render: (item) => (
        <NavLink
          to={`/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISPENDING}=true&${tableScopes.COMPANY}=${item.id}`}
        >
          {CommaNumber_NoDecimal(item.requestsOpen)}
        </NavLink>
      ),
    },
    {
      Key: 'requestsComplete',
      Label: t('Complete'),
      className: 'text-right num no-wrap',
      Render: (item) => (
        <NavLink
          to={`/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISAUTHORISED}=true&${tableScopes.COMPANY}=${item.id}`}
        >
          {CommaNumber_NoDecimal(item.requestsComplete)}
        </NavLink>
      ),
    },
    {
      Key: 'keysRequested',
      Label: t('Total keys'),
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.keysRequested),
    },
  ];
}

CompaniesListColumnsConfig.propTypes = {
  params: PropTypes.object.isRequired,
  filterChange: PropTypes.func.isRequired,
  companyTypes: PropTypes.array.isRequired,
};

export default CompaniesListColumnsConfig;
