import {
  apiUrl,
  getList,
  getAll,
  getSingleRecord,
  saveRecord,
  deleteRecord,
  postAction,
  deleteAction,
  exportData,
  get,
  postArray,
  deleteArray,
} from './apiUtils';
const baseUrl = apiUrl + '/userprofilegroups/';

export function getUserProfileGroups(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAllUserProfileGroups() {
  return getAll(baseUrl);
}

export function getUserProfileGroupById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveUserProfileGroup(userProfileGroup) {
  return saveRecord(baseUrl, userProfileGroup);
}

export function deleteUserProfileGroup(id) {
  return deleteRecord(baseUrl, id);
}
export function addUserToGroup(userProfileGroupId, userProfileId) {
  return postAction(`${baseUrl}${userProfileGroupId}/member/${userProfileId}`);
}
export function removeUserFromGroup(userProfileGroupId, userProfileId) {
  return deleteAction(
    `${baseUrl}${userProfileGroupId}/member/${userProfileId}`
  );
}

export function exportUserProfileGroupData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/userProfileGroups/search`,
    'name',
    filters,
    scopes
  );
}

// companies
export function getCompanies(userProfileGroupId) {
  return get(`${baseUrl}${userProfileGroupId}/companies`);
}
export function addCompanies(companyIds, userProfileGroupId) {
  return postArray(`${baseUrl}${userProfileGroupId}/companies`, companyIds);
}
export function deleteCompanies(companyIds, userProfileGroupId) {
  return deleteArray(`${baseUrl}${userProfileGroupId}/companies`, companyIds);
}
export function getMembers(userProfileGroupId) {
  return get(`${baseUrl}${userProfileGroupId}/members`);
}
