import React, { ButtonHTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  hideTitle?: boolean;
  icon?: string;
  text?: string;
  title?: string;
  isDisabled?: boolean;
}

function Button({
  hideTitle,
  isDisabled,
  className,
  icon,
  text,
  title,
  ...props
}: Readonly<ButtonProps>) {
  return (
    <button
      {...props}
      className={`${className || ''} no-navigate ${
        icon && !hideTitle ? 'ps-3' : ''
      }`}
      title={title ?? (hideTitle ? text : '')}
      aria-label={text}
      disabled={isDisabled ?? false}
    >
      {icon && (
        <div className="icon-wrapper no-navigate">
          <FontAwesomeIcon
            // @ts-ignore
            icon={`${icon} ${!hideTitle ? 'me-3' : ''}`}
            className={`${!hideTitle ? 'me-3' : ''}`}
            fixedWidth
            style={{ pointerEvents: 'none' }}
          ></FontAwesomeIcon>
        </div>
      )}
      {text && !hideTitle && text}
    </button>
  );
}

Button.propTypes = {
  hideTitle: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default Button;
