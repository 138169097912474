import { Link } from 'react-router-dom';
import { SimpleTextFilter } from '../../../common/Table';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DisableReasonsColumnsConfig = ({ params, filterChange }) => {
  const { t } = useTranslation();
  return [
    {
      Key: 'reason',
      Label: t('Reason'),
      width: '100%',
      Render: (disableReason) => (
        <Link to={'/settings/disabled-reason/' + disableReason.id}>
          {disableReason.reason}
        </Link>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
  ];
};

DisableReasonsColumnsConfig.propTypes = {
  params: PropTypes.object.isRequired,
  filterChange: PropTypes.func.isRequired,
};

export default DisableReasonsColumnsConfig;
