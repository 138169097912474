import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import UploadActivatedForm from './UploadActivatedForm';
import { getKeyProviders } from '../../../actions/keyProviderActions';
import { uploadActivations } from '../../../actions/keyCodeActions';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Loading from '../../common/Loading';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function UploadActivated({ done, cancel, keyProviders, getKeyProviders }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [activated, setActivated] = useState();
  const addFiles = (files) => {
    setActivated({ ...activated, file: files[0] });
  };
  const onSubmit = (data) => {
    uploadActivations({ ...activated, ...data })
      .then(() => {
        toast.success(t('Activations successfully uploaded'));
        done();
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to upload activations'));
      });
  };
  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders]);

  return keyProviders ? (
    <SlideoutLayout
      title={t('Upload activated keys')}
      cancel={cancel}
      done={done}
    >
      <UploadActivatedForm
        keyProviders={keyProviders}
        activated={activated}
        register={register}
        errors={errors}
        control={control}
        cancel={cancel}
        submit={handleSubmit(onSubmit)}
        addFiles={addFiles}
      ></UploadActivatedForm>
    </SlideoutLayout>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    keyProviders: state.keyProviders,
  };
}

const mapDispatchToProps = {
  getKeyProviders,
};

UploadActivated.propTypes = {
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadActivated);
