import React from 'react';
import { withRouter } from 'react-router-dom';
import { navPermissionCheck } from '../../auth/authUtils';
import icons from '../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function OffCanvasTopMenuItem(props) {
  let hasPermission = navPermissionCheck(
    props.currentUser,
    props.allowedPermissions,
    props.allowedUserTypes,
    props.isPowerUser
  );

  return hasPermission ? (
    <li
      key={props}
      onClick={props.onClick}
      className={`nav__menu-item ${
        props.activeRegex !== undefined
          ? `${props.location.pathname}${props.location.search}`.match(
              RegExp(props.activeRegex)
            )
            ? 'is-current'
            : ''
          : `${props.location.pathname}${props.location.search}`.startsWith(
                props.activeif !== undefined ? props.activeif : props.url
              )
            ? 'is-current'
            : ''
      }`}
    >
      <div className="a nav-item">
        <span>
          <span className={`menu-icon-wrapper me-3 ${props.colour ?? ''}`}>
            <FontAwesomeIcon icon={props.icon} fixedWidth className="me-3" />
          </span>
          <span>{props.title}</span>
        </span>
        <span className="next-level-cta">
          <FontAwesomeIcon icon={icons.WIZARD_NEXT} />
        </span>
      </div>
      {props.children}
    </li>
  ) : (
    ''
  );
}

export default withRouter(OffCanvasTopMenuItem);
