import React, { useState } from 'react';
import { connect } from 'react-redux';
import PackageTypesList from './components/PackageTypeList';
import ActionBar from '../../layout/ActionBar';
import Edit from './slideout/Edit';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function PackageTypes({ location }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [addPackageTypeSlideoutOpen, setAddPackageTypeSlideoutOpen] =
    useState(false);

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={() => setAddPackageTypeSlideoutOpen(true)}
          text={t('Add package type')}
        />
      </ActionBar>
      <PackageTypesList location={location} reload={reload} />

      <SlidingPane
        isOpen={addPackageTypeSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddPackageTypeSlideoutOpen(false)}
      >
        <Edit
          done={(d) => {
            setReload(new Date());
            setAddPackageTypeSlideoutOpen(false);
          }}
          cancel={() => setAddPackageTypeSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PackageTypes);
