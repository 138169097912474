import React from 'react';
import PropTypes from 'prop-types';
import { Date_AsString_NoHtml } from '../../../util/formatter';
import AutoSelectText from '../../common/AutoSelectText';
import ImageWithFallback from '../../common/ImageWithFallback';
import FormStatic from '../../common/FormStatic';
import icons from '../../../constants/icons';
import CollapsePanel from '../../common/CollapsePanel';
import CompanyLink from '../../common/links/CompanyLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const BasicGameDetails = ({ game }) => {
  const { t } = useTranslation();
  return (
    <CollapsePanel
      storageKey={'gameDetailsAdvanced'}
      header={
        <>
          <div className="game-img-wrapper me-4">
            <ImageWithFallback
              url={game.packshotUrl}
              fallBackImage={
                <div className="game-img">
                  <FontAwesomeIcon icon={icons.GAME} className="game" />
                </div>
              }
              alt={game.name}
            ></ImageWithFallback>
          </div>
          <h2 className="no-margin">{game.name || t('Game')}</h2>
        </>
      }
    >
      <div className="col-count-2">
        <FormStatic label={t('App Id')}>
          {<AutoSelectText>{game.appId}</AutoSelectText> || ''}
        </FormStatic>
        <FormStatic label={t('License expiry date')}>
          {Date_AsString_NoHtml(game.licenseExpires, t('No expiry'))}
        </FormStatic>
        <FormStatic label={t('Publisher')}>
          <CompanyLink
            id={game.publisherCompanyId}
            name={game.publisherCompanyName}
          />
        </FormStatic>
      </div>
    </CollapsePanel>
  );
};

BasicGameDetails.propTypes = {
  game: PropTypes.object.isRequired,
};

export default BasicGameDetails;
