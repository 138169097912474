import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import icons from '../../constants/icons';

export default function ConfirmationHeader({ text }) {
  const { t } = useTranslation();
  return (
    <header className="mb-2">
      <h2 className="no-margin">
        <span className="warning-icon me-3">
          <FontAwesomeIcon icon={icons.WARNING} />
        </span>
        {text || t('Confirmation')}
      </h2>
    </header>
  );
}
