import React from 'react';
import { selectText } from '../../util/selectText';
import { useTranslation } from 'react-i18next';

const AutoSelectText = (props) => {
  const { t } = useTranslation();
  return (
    <span
      className="no-navigate monospace autoselect"
      style={{ cursor: 'copy' }}
      title={props.title ?? t('Click to select text')}
      onClick={(e) => {
        selectText(e.target);
      }}
    >
      {props.children}
    </span>
  );
};
export default AutoSelectText;
