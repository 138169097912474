const actionTypes = Object.freeze({
  CREATE_APPROVER: 'CREATE_APPROVER',
  UPDATE_APPROVER: 'UPDATE_APPROVER',
  LOAD_APPROVERS: 'LOAD_APPROVERS',

  CREATE_ANNOUNCEMENT: 'CREATE_ANNOUNCEMENT',
  UPDATE_ANNOUNCEMENT: 'UPDATE_ANNOUNCEMENT',
  LOAD_ANNOUNCEMENTS: 'LOAD_ANNOUNCEMENTS',
  DELETE_ANNOUNCEMENT: 'DELETE_ANNOUNCEMENT',

  CREATE_COMPANY: 'CREATE_COMPANY',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  LOAD_COMPANIES: 'LOAD_COMPANIES',
  DELETE_COMPANY: 'DELETE_COMPANY',

  CREATE_COMPANYTYPE: 'CREATE_COMPANYTYPE',
  UPDATE_COMPANYTYPE: 'UPDATE_COMPANYTYPE',
  LOAD_COMPANYTYPES: 'LOAD_COMPANYTYPES',
  DELETE_COMPANYTYPE: 'DELETE_COMPANYTYPE',

  CREATE_GAME: 'CREATE_GAME',
  UPDATE_GAME: 'UPDATE_GAME',
  LOAD_GAMES: 'LOAD_GAMES',
  ARCHIVE_GAME: 'ARCHIVE_GAME',
  GAME_UPLOAD_PROCESSING: 'GAME_UPLOAD_PROCESSING',
  GAME_UPLOAD_FAILURE: 'GAME_UPLOAD_FAILURE',
  GAME_UPLOAD_CLEAR: 'GAME_UPLOAD_CLEAR',

  CREATE_GAMESKU: 'CREATE_GAMESKU',
  UPDATE_GAMESKU: 'UPDATE_GAMESKU',
  LOAD_GAMESKUS: 'LOAD_GAMESKUS',
  ARCHIVE_GAMESKU: 'ARCHIVE_GAMESKU',

  CREATE_KEYPROVIDER: 'CREATE_KEYPROVIDER',
  UPDATE_KEYPROVIDER: 'UPDATE_KEYPROVIDER',
  LOAD_KEYPROVIDERS: 'LOAD_KEYPROVIDERS',
  DELETE_KEYPROVIDER: 'DELETE_KEYPROVIDER',

  CREATE_PACKAGETYPE: 'CREATE_PACKAGETYPE',
  UPDATE_PACKAGETYPE: 'UPDATE_PACKAGETYPE',
  LOAD_PACKAGETYPES: 'LOAD_PACKAGETYPES',
  DELETE_PACKAGETYPE: 'DELETE_PACKAGETYPE',

  CREATE_PLATFORM: 'CREATE_PLATFORM',
  UPDATE_PLATFORM: 'UPDATE_PLATFORM',
  LOAD_PLATFORMS: 'LOAD_PLATFORMS',
  DELETE_PLATFORM: 'DELETE_PLATFORM',

  CREATE_KEYREQUEST: 'CREATE_KEYREQUEST',
  UPDATE_KEYREQUEST: 'UPDATE_KEYREQUEST',
  DELETE_KEYREQUEST: 'DELETE_KEYREQUEST',
  LOAD_KEYREQUESTS: 'LOAD_KEYREQUESTS',
  DELETE_KEYREQUESTGROUP: 'DELETE_KEYREQUESTGROUP',
  KEYREQUEST_SUBMITTED: 'KEYREQUEST_SUBMITTED',

  CREATE_TERRITORY: 'CREATE_TERRITORY',
  UPDATE_TERRITORY: 'UPDATE_TERRITORY',
  LOAD_TERRITORIES: 'LOAD_TERRITORIES',
  DELETE_TERRITORY: 'DELETE_TERRITORY',

  CREATE_WORKFLOW: 'CREATE_WORKFLOW',
  UPDATE_WORKFLOW: 'UPDATE_WORKFLOW',
  LOAD_WORKFLOWS: 'LOAD_WORKFLOWS',
  DELETE_WORKFLOW: 'DELETE_WORKFLOW',

  STORE_USER: 'STORE_USER',
  USER_SIGNED_OUT: 'USER_SIGNED_OUT',
  USER_EXPIRED: 'USER_EXPIRED',
  STORE_USER_ERROR: 'STORE_USER_ERROR',
  LOADING_USER: 'LOADING_USER',
  ACCESS_DENIED: 'ACCESS_DENIED',
  TRIAL_ENDED: 'TRIAL_ENDED',
  USER_SIGNED_IN: 'USER_SIGNED_IN',
  API_FAILURE: 'API_FAILURE',
  USER_PERMISSIONS_CHANGED: 'USER_PERMISSIONS_CHANGED',
  USER_CLEAR_PROPERTIES: 'USER_CLEAR_PROPERTIES',

  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_USER: 'DELETE_USER',

  BIG_LOADER_SHOW: 'BIG_LOADER_SHOW',
  BIG_LOADER_HIDE: 'BIG_LOADER_HIDE',

  LAYOUT_CLOSE_NAV_MENU: 'LAYOUT_CLOSE_NAV_MENU',
  LAYOUT_OPEN_NAV_MENU: 'LAYOUT_OPEN_NAV_MENU',
  LAYOUT_TOGGLE_NAV_MENU: 'LAYOUT_TOGGLE_NAV_MENU',

  CREATE_DISABLEREASON: 'CREATE_DISABLEREASON',
  UPDATE_DISABLEREASON: 'UPDATE_DISABLEREASON',
  LOAD_DISABLEREASONS: 'LOAD_DISABLEREASONS',
  DELETE_DISABLEREASON: 'DELETE_DISABLEREASON',

  ADD_TO_GROUP: 'ADD_TO_GROUP',
  REMOVE_FROM_GROUP: 'REMOVE_FROM_GROUP',
  GET_CONNECTED_USERS: 'GET_CONNECTED_USERS',
  LOAD_CONNECTED_USERS: 'LOAD_CONNECTED_USERS',
  CLEAR_CONNECTED_USERS: 'CLEAR_CONNECTED_USERS',
  USER_CONNECTED: 'USER_CONNECTED',
  USER_DISCONNECTED: 'USER_DISCONNECTED',
  PAGE_ACTIVE: 'PAGE_ACTIVE',
  PAGE_INACTIVE: 'PAGE_INACTIVE',
  USER_ACTIVE: 'USER_ACTIVE',
  USER_INACTIVE: 'USER_INACTIVE',

  UPDATE_STEAMREQUEST: 'UPDATE_STEAMREQUEST',
  CLEAR_STEAMREQUEST: 'CLEAR_STEAMREQUEST',

  UPDATE_ACTIVATION: 'UPDATE_ACTIVATION',
  CLEAR_ACTIVATION: 'CLEAR_ACTIVATION',

  UPDATE_DISABLE_TRANSACTION: 'UPDATE_DISABLE_TRANSACTION',
  CLEAR_DISABLE_TRANSACTION: 'CLEAR_DISABLE_TRANSACTION',
  CREATE_DISABLE_TRANSACTION: 'CREATE_DISABLE_TRANSACTION',

  UPDATE_SOLD_TRANSACTION: 'UPDATE_SOLD_TRANSACTION',
  CLEAR_SOLD_TRANSACTION: 'CLEAR_SOLD_TRANSACTION',

  UPDATE_MOVE_TRANSACTION: 'UPDATE_MOVE_TRANSACTION',
  CLEAR_MOVE_TRANSACTION: 'CLEAR_MOVE_TRANSACTION',

  UPDATE_IMPORT_TRANSACTION: 'UPDATE_IMPORT_TRANSACTION',
  CREATE_IMPORT_TRANSACTION: 'CREATE_IMPORT_TRANSACTION',
  DELETE_IMPORT_TRANSACTION: 'DELETE_IMPORT_TRANSACTION',

  UPDATE_BULK_TRANSACTION: 'UPDATE_BULK_TRANSACTION',
  CLEAR_BULK_TRANSACTION: 'CLEAR_BULK_TRANSACTION',
  CREATE_BULK_TRANSACTION: 'CREATE_BULK_TRANSACTION',

  UPDATE_ACCOUNT_DETAILS: 'UPDATE_ACCOUNT_DETAILS',
  DELETE_ACCOUNT_DETAILS: 'DELETE_ACCOUNT_DETAILS',
});

export default actionTypes;
