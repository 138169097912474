import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import KwPanel from '../../common/KwPanel';

import { archiveGameSku } from '../../../actions/gameActions';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import FormToggleBool from '../../common/FormToggleBool';
import Button from '../../common/Button';
import ConfirmationHeader from '../../common/ConfirmationHeader';
import { Trans, useTranslation } from 'react-i18next';

function Archive({ done, id, gameId, cancel, archiveGameSku }) {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();

  const handleArchive = (data) => {
    archiveGameSku(gameId, id, data.lockSignOff)
      .then(() => {
        toast.success(t('Game sku successfully archived'));
        done();
      })
      .catch(() => {
        toast.error(t('Failed to archive game sku'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Archive confirmation')}
      cancel={cancel}
      done={done}
    >
      <KwPanel>
        <ConfirmationHeader text={t('Are you sure?')} />
        <p>
          {t(
            'Once the sku is archived, users will not be able to request keys.'
          )}
        </p>
        <p>
          <Trans i18nKey="archiveConfirmation" components={{ bold: <b /> }}>
            You can also <bold>'Lock sign-off'</bold> meaning outstanding
            requests will be locked and no further keys will be assigned.
          </Trans>
        </p>
        <FormToggleBool
          control={control}
          name="lockSignOff"
          label={t('Lock sign-off?')}
          defaultValue={false}
        />
        <hr />
        <Button
          className="btn btn-danger me-2"
          onClick={handleSubmit(handleArchive)}
          text={t('Yes, archive the sku')}
        />
        <Button
          className="btn btn-default"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
Archive.propTypes = {
  id: PropTypes.string,
  gameId: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  archiveGameSku,
};

export default connect(mapStateToProps, mapDispatchToProps)(Archive);
