import React, { useState, useEffect } from 'react';
import DashboardStatsMini from '../../common/DashboardStatsMini';
import { useTranslation } from 'react-i18next';

import { scopes as tableScopes } from '../../../constants/scopes';

function AccountStats({ stats, id }) {
  const [_stats, _setStats] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (stats) {
      _setStats([
        {
          colour: 'blue',
          linkto: `/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISPENDING}=true&${tableScopes.ACCOUNTDETAIL}=${id}`,
          title: t('Open requests'),
          metric: stats.openRequests,
        },
        {
          colour: 'blue',
          linkto: `/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISPENDING}=true&${tableScopes.ACCOUNTDETAIL}=${id}`,
          title: t('Open requests value'),
          metric: stats.openRequestsValue,
        },
        {
          colour: 'aqua',
          linkto: `/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISAUTHORISED}=true&${tableScopes.ACCOUNTDETAIL}=${id}`,
          title: t('Complete requests'),
          metric: stats.completeRequests,
        },
        {
          colour: 'red',
          linkto: `/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISOVERSLA}=true&${tableScopes.ACCOUNTDETAIL}=${id}`,
          title: t('Over SLA'),
          metric: stats.overSLA,
        },
        {
          colour: 'aqua',
          linkto: `/keys/game?${tableScopes.ACCOUNTDETAIL}=${id}`,
          title: t('Keys allocated'),
          metric: stats.accountKeysTotal,
        },
        {
          colour: 'aqua',
          linkto: `/keys/game?${tableScopes.ACCOUNTDETAIL}=${id}`,
          title: t('Keys downloaded'),
          metric: stats.accountKeysDownloadedKeys,
        },
        {
          colour: 'red',
          linkto: `/keys/game?${tableScopes.ACCOUNTDETAIL}=${id}`,
          title: t('Keys disabled'),
          metric: stats.keysBlacklisted,
        },
      ]);
    }
  }, [id, stats]);

  return <DashboardStatsMini stats={_stats} />;
}
export default AccountStats;
