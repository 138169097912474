import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';
function AccountDetailTableViewSwitcher({
  grouped,
  setGrouped,
  setItemsChecked,
}) {
  const { t } = useTranslation();
  return (
    <>
      <button
        className={`btn btn-${
          grouped ? 'primary' : 'default'
        } btn-outline btn--square r-corners-left`}
        onClick={(e) => {
          setGrouped(true);
          if (setItemsChecked) {
            setItemsChecked([]);
          }
        }}
        title={t('View grouped')}
      >
        <FontAwesomeIcon
          icon={icons.TABLE_GROUPED}
          fixedWidth
          aria-hidden="true"
        />
      </button>
      <button
        className={`btn btn-${
          grouped ? 'default' : 'primary'
        } btn-outline btn--square r-corners-right`}
        onClick={(e) => {
          setGrouped(false);
          if (setItemsChecked) {
            setItemsChecked([]);
          }
        }}
        title={t('View ungrouped')}
      >
        <FontAwesomeIcon
          icon={icons.TABLE_UNGROUPED}
          fixedWidth
          aria-hidden="true"
        />
      </button>
    </>
  );
}
export default AccountDetailTableViewSwitcher;
