import React, { useState } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import RequestKeys from './slideout/RequestKeys';
import { userIs } from '../auth/authUtils';
import userTypes from '../../constants/userTypes';
import SlidingPane from 'react-sliding-pane';
import RequestsTable from './components/RequestsTable';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

function RequestsAssignedToMe({
  currentUser,
  location,
  history,
  setCompleteRequestSlideoutOpen,
}) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [requestKeysSlideoutOpen, setRequestKeysSlideoutOpen] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState([
    { label: t('Requests'), link: '/requests/assigned-to-me' },
  ]);

  return (
    <>
      <ActionBar>
        {userIs(currentUser, userTypes.REQUESTER) && (
          <Button
            className="btn btn-primary ms-2"
            onClick={() => setRequestKeysSlideoutOpen(true)}
            text={t('Request keys')}
          />
        )}
      </ActionBar>

      <RequestsTable
        location={location}
        history={history}
        setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
        scope={{ assignedToMe: currentUser.id }}
        // setBreadcrumb={setBreadcrumb}
        reload={reload}
        setReload={setReload}
        settingsKey={'assignedtome-requests-list'}
      />

      <SlidingPane
        isOpen={requestKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRequestKeysSlideoutOpen(false)}
      >
        <RequestKeys
          done={() => {
            setRequestKeysSlideoutOpen(false);
            setCompleteRequestSlideoutOpen(true);
            setReload(new Date());
          }}
          cancel={() => setRequestKeysSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    history: ownProps.history,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsAssignedToMe);
