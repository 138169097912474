import React from 'react';
import PropTypes from 'prop-types';

function EmptyCell({ promotion, game, editable }) {
  return <div className={'grid-empty'}>---</div>;
}

EmptyCell.propTypes = {
  promotion: PropTypes.object,
  game: PropTypes.object,
  editable: PropTypes.bool,
};

export default EmptyCell;
