import actionTypes from '../constants/actionTypes';

export default function loaderReducer(state = null, action) {
  switch (action.type) {
    case actionTypes.BIG_LOADER_SHOW:
      return { title: action.title, subTitle: action.subTitle };
    case actionTypes.BIG_LOADER_HIDE:
      return null;
    default:
      return state;
  }
}
