import React from 'react';
import KwPanel from '../../../common/KwPanel';
import FormToggleBool from '../../../common/FormToggleBool';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const KeyProviderRequestInformationForm = ({
  requestInformation,
  internal,
  control,
}) => {
  const { t } = useTranslation();
  return requestInformation ? (
    <KwPanel className="mb-4 pb-1">
      {requestInformation.map((item) => (
        <FormToggleBool
          control={control}
          key={item.id}
          name={item.id}
          label={item.type}
          defaultValue={
            (internal ? item.internalSite : item.externalSite) || false
          }
          yesText={t('Show')}
          noText={t('Hide')}
        />
      ))}
    </KwPanel>
  ) : (
    ''
  );
};

KeyProviderRequestInformationForm.propTypes = {
  requestInformation: PropTypes.array.isRequired,
  internal: PropTypes.bool.isRequired,
  control: PropTypes.object.isRequired,
};

export default KeyProviderRequestInformationForm;
