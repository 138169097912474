import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';

import { saveUser } from '../../../../actions/userActions';
import { getAllCompanies } from '../../../../actions/companyActions';
import { toast } from 'react-toastify';
import InviteUserForm from '../components/InviteUserForm';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import icons from '../../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function AddUser({ done, cancel, saveUser, companies, getAllCompanies }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  useEffect(() => {
    if (!companies) {
      getAllCompanies().catch((e) => {
        toast.error(e.message || t('Failed to get companies'));
      });
    }
  }, [companies, getAllCompanies]);
  const onSubmit = (data) => {
    if (data.companyId === 'new' || data.companyId === '') {
      data.companyId = null;
    }
    saveUser(data)
      .then(() => {
        toast.success(t('User saved'));
        done();
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to save user'));
      });
  };
  return (
    companies && (
      <SlideoutLayout
        title={t('Invite users to Alaska')}
        cancel={cancel}
        done={done}
        bar={
          <input
            type="submit"
            value={t('Invite')}
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
          />
        }
      >
        <div className="alert alert-info twinkle flex-shrink-0 ">
          <div>
            <FontAwesomeIcon icon={icons.INFO} className="me-2 c-blue" />
            {t(
              "We'll ask new users to enter their personal details when they sign up."
            )}
          </div>
        </div>
        <p></p>
        <InviteUserForm
          register={register}
          errors={errors}
          control={control}
          companies={companies}
        ></InviteUserForm>
      </SlideoutLayout>
    )
  );
}

AddUser.propTypes = {
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    done: ownProps.done,
    cancel: ownProps.cancel,
    companies: state.companies,
  };
}

const mapDispatchToProps = {
  saveUser,
  getAllCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
