import React, { useState } from 'react';
import AnnouncementsList from './components/AnnouncementList';
import ActionBar from '../../layout/ActionBar';
import Edit from './slideout/Edit';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function Announcements({ location }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [addAnnouncementSlideoutOpen, setAddAnnouncementSlideoutOpen] =
    useState(false);

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={() => setAddAnnouncementSlideoutOpen(true)}
          text={t('Add announcement')}
        />
      </ActionBar>
      <AnnouncementsList location={location} reload={reload} />

      <SlidingPane
        isOpen={addAnnouncementSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddAnnouncementSlideoutOpen(false)}
      >
        <Edit
          done={(d) => {
            setReload(new Date());
            setAddAnnouncementSlideoutOpen(false);
          }}
          cancel={() => setAddAnnouncementSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

export default Announcements;
