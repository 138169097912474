import {
  apiUrl,
  getList,
  getSingleRecord,
  getStatsData,
  exportData,
} from './apiUtils';
const baseUrl = apiUrl + '/accounts/';

export function getAccountDetails(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAccountDetailById(id) {
  return getSingleRecord(baseUrl, id);
}

export function loadAccountDetailKeyRequests(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}keyRequests`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function loadAccountDetailKeyRequestsForAccount(
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}${id}/keyRequests`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function getStats(id) {
  return getStatsData(baseUrl, id);
}

export function exportAccountData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/accounts/search`,
    'login',
    filters,
    scopes
  );
}

export function exportAccountDetailKeyRequestsForAccount(id, filters, scopes) {
  return exportData(
    `${apiUrl}/export/accounts/${id}/keyRequests`,
    'game',
    filters,
    scopes
  );
}

export function exportAccountDetailKeyRequests(filters, scopes) {
  return exportData(
    `${apiUrl}/export/accounts/keyRequests`,
    'login',
    filters,
    scopes
  );
}
