import React from 'react';

const KwPanel = (props) => (
  <div
    onClick={props.onClick}
    className={`panel kw-panel r-corners-sm-up ${
      props.className !== undefined ? props.className : ''
    }`}
  >
    {props.children}
  </div>
);
export default KwPanel;
