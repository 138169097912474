import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import { withRouter, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DashboardStats = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    return function cleanup() {};
  }, [props.stats]);

  const animateDelay = (e, stat) => {
    e.preventDefault();
    e.currentTarget.firstChild.classList.add('active');
    setTimeout(() => {
      props.history.push(stat.linkto);
    }, 500);
    return false;
  };

  return (
    <div className="dashboard">
      <div id="statsdashboard" className={props.className}>
        <div className="row dashboard-row">
          {props.stats.map((stat, i) => {
            let result = 0;
            let suffix = '';
            let digits = 0;

            if (stat.metric <= 999) {
              result = stat.metric;
            } else if (stat.metric > 999 && stat.metric <= 999999) {
              result = stat.metric / 1000;
              digits = 1;
              suffix = 'k';
            } else if (stat.metric > 999999) {
              result = stat.metric / 1000000;
              digits = 1;
              suffix = 'm';
            }

            return (
              <div key={i} className="dashboard-tile mb-4">
                <NavLink
                  to={stat.linkto}
                  onClick={(e) => animateDelay(e, stat)}
                >
                  <div
                    className={`panel kw-panel r-corners-sm-up dashboard-stat ${stat.colour}`}
                  >
                    <h4>{t(stat.title)}</h4>
                    <div className={`number text-${stat.colour}`}>
                      <CountUp
                        end={result}
                        digits={digits}
                        suffix={suffix}
                        speed={0.6}
                        delay={0.35}
                      />
                    </div>
                  </div>
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default withRouter(DashboardStats);
