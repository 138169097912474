const requestStatuses = Object.freeze({
  PENDING_APPROVAL: 'AF310AE6-14BB-4EF6-9316-F82BD8491E7D',
  AUTHORISED: '8A5E082C-43F4-4FDA-8AF8-04DC9F27678F',
  COMPLETE: '0987A4E0-BFDC-4AD0-9383-9B50DA5EF9B4',
  DECLINED: '1771F5C6-04E7-4875-B296-C2C3DE167EB6',
  KEYS_ORDERED: '088A230C-D7CE-4E36-A176-CBDBFE5DB0D1',
  ALLOCATED: '2554AF0D-BFC8-48F4-BA95-9C53146CF6D8',
  WITHDRAWN: '8C224145-4930-4B85-8E28-F0AC29C97620',
  ON_HOLD: 'DC8F890F-64EB-4006-A064-28C9E721DA33',
});

export default requestStatuses;
