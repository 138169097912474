import React from 'react';
import ImageWithFallback from '../../../common/ImageWithFallback';
import icons from '../../../../constants/icons';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function GameRow({ game, style, onRemove }) {
  return (
    <div
      className="bulk-grid-game-name d-flex align-items-center justify-content-between"
      style={style}
    >
      <div className="border-0 d-flex align-items-center">
        {game.showRemove && (
          <button
            className="btn btn-default btn--square btn-outline"
            onClick={() => onRemove(game)}
          >
            <FontAwesomeIcon icon={icons.DELETE_TAG} />
          </button>
        )}
        <span className="game-title">{game.name}</span>
      </div>
      <ImageWithFallback
        url={game.packshotUrl}
        fallBackImage={
          <div className="game-img">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={icons.GAME} className="game" />
            </div>
          </div>
        }
        alt={game.name}
      ></ImageWithFallback>
    </div>
  );
}

GameRow.propTypes = {
  game: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default GameRow;
