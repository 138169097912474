import actionTypes from '../constants/actionTypes';
export default function (state = false, action) {
  switch (action.type) {
    case actionTypes.LAYOUT_CLOSE_NAV_MENU:
      return false;
    case actionTypes.LAYOUT_OPEN_NAV_MENU:
      return true;
    case actionTypes.LAYOUT_TOGGLE_NAV_MENU:
      return !state;
    default:
      return state;
  }
}
