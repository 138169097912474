import React from 'react';
import KwPanel from '../../../common/KwPanel';
import FormInput from '../../../common/FormInput';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PackageTypeForm = ({ packageType, register, errors }) => {
  const { t } = useTranslation();
  return packageType ? (
    <KwPanel className="mb-4 pb-3 pt-2">
      <FormInput
        register={register}
        required={true}
        error={errors.name}
        errorMessage={t('Required')}
        name="name"
        label={t('Name')}
        defaultValue={packageType.name || ''}
        autoFocus={true}
      />
    </KwPanel>
  ) : (
    ''
  );
};

PackageTypeForm.propTypes = {
  packageType: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default PackageTypeForm;
