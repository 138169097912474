import actionTypes from '../constants/actionTypes';
export function showLoader(title, subTitle) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.BIG_LOADER_SHOW,
      title: title || 'ALASKA',
      subTitle: subTitle || 'loading...',
    });
  };
}

export function hideLoader() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.BIG_LOADER_HIDE,
    });
  };
}
