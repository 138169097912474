import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { toast } from 'react-toastify';
import { getAllCompanies } from '../../../../actions/companyActions';
import FormCheckBoxList from '../../../common/FormCheckBoxList';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';

function AddCompany({
  companies,
  userProfileGroupId,
  done,
  cancel,
  allCompanies,
  getAllCompanies,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      companies: companies.map((x) => x.id),
    },
  });

  // all companies
  useEffect(() => {
    if (!allCompanies) {
      getAllCompanies().catch((e) => {
        toast.error(t('Failed to get companies'));
      });
    }
  }, [allCompanies, getAllCompanies]);

  const onSubmit = (data) => {
    let selected = data.companies;
    let companyIds = companies.map((x) => x.id);

    // added
    let added = selected.filter((item) => !companyIds.includes(item));

    // removed
    let removed = companyIds.filter((item) => !selected.includes(item));

    done({ added, removed });
  };

  return (
    <SlideoutLayout
      title={`Add company`}
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="submit"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      {allCompanies && (
        <>
          <FormCheckBoxList
            label={t('Companies')}
            required
            name="companies"
            error={errors.keyProviders}
            getValues={getValues}
            setValue={setValue}
            control={control}
            items={
              allCompanies
                ? [...allCompanies]
                    ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
                    .map((x) => ({
                      text: x.companyName,
                      value: x.id,
                    }))
                : null
            }
          ></FormCheckBoxList>
        </>
      )}
    </SlideoutLayout>
  );
}

AddCompany.propTypes = {
  userProfileGroupId: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    allCompanies: state.companies,
  };
}

const mapDispatchToProps = {
  getAllCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCompany);
