import React from 'react';
import KwPanel from '../../common/KwPanel';
import FormInput from '../../common/FormInput';
import FormDate from '../../common/FormDate';
import FormRichText from '../../common/FormRichText';
import PropTypes from 'prop-types';
import icons from '../../../constants/icons';
import FormSelect from '../../common/FormSelect';
import { useTranslation } from 'react-i18next';

const GameForm = ({
  game,
  register,
  errors,
  control,
  clearLicenseExpires,
  companies,
  allowedPublishers,
}) => {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4 pb-3 pt-1">
      <FormInput
        register={register}
        required={true}
        error={errors.name}
        errorMessage={t('Name is required')}
        name="name"
        label={t('Title')}
        placeholder={t('Name of the game')}
        defaultValue={game.name || ''}
        autoFocus={true}
      />
      <FormInput
        register={register}
        name="appId"
        label={t('App Id')}
        placeholder={t('Optional')}
        defaultValue={game.appId || ''}
      />
      <FormDate
        register={register}
        name="licenseExpires"
        label={t('License expiry date')}
        placeholder={t('Optional')}
        defaultValue={game.licenseExpires || ''}
        joinedButton={true}
        joinedButtonIcon={icons.DELETE}
        joinedButtonClassName="btn-default"
        joinedButtonClick={clearLicenseExpires}
        joinedButtonTitle={t('Clear')}
      />
      <FormSelect
        register={register}
        required={true}
        error={errors.publisherCompanyId}
        errorMessage={t('Publisher is required')}
        name="publisherCompanyId"
        label={t('Publisher')}
        placeholder=""
        defaultValue={game.publisherCompanyId || ''}
      >
        <option value="">{t('--- choose ---')}</option>
        {companies &&
          allowedPublishers &&
          [...companies]
            .filter(
              (x) => x.isPublisher && allowedPublishers?.some((y) => y === x.id)
            )
            ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
            ?.map((x) => (
              <option key={x.id} value={x.id}>
                {x.companyName}
              </option>
            ))}
      </FormSelect>
      <FormSelect
        register={register}
        error={errors.developerCompanyId}
        name="developerCompanyId"
        label={t('Developer')}
        placeholder=""
        defaultValue={game.developerCompanyId || ''}
      >
        <option value="">{t('--- choose ---')}</option>
        {companies &&
          [...companies]
            .filter((x) => x.isDeveloper)
            ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
            ?.map((x) => (
              <option key={x.id} value={x.id}>
                {x.companyName}
              </option>
            ))}
      </FormSelect>
      <FormRichText
        control={control}
        name="notesHTML"
        label={t('Notes')}
        extraRowClasses="align-items-start"
        placeholder={t('Information, links etc.')}
        defaultValue={game.notesHTML || ''}
      />
    </KwPanel>
  );
};

GameForm.propTypes = {
  game: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default GameForm;
