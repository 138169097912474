import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../../../constants/icons';
import ImageFromApi from '../../../common/ImageFromApi';

function PlatformRow({ game, style }) {
  return (
    <div className="grid-empty" style={style}>
      {game.isSKU && (
        <>
          <ImageFromApi
            imageId={game.platformImageId}
            fallbackIcon={icons.PLATFORM}
            colourHex={game.platformColourHex}
          ></ImageFromApi>
          {game.platform}
        </>
      )}
    </div>
  );
}

PlatformRow.propTypes = {
  game: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
};

export default PlatformRow;
