import React from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import KwPanel from '../../common/KwPanel';

import { toast } from 'react-toastify';
import { disableKeys } from '../../../actions/keyCodeActions';
import { Link } from 'react-router-dom';
import Button from '../../common/Button';
import ConfirmationHeader from '../../common/ConfirmationHeader';
import { Trans, useTranslation } from 'react-i18next';

function DisableUnusedKeys({
  done,
  skuId,
  skuName,
  keyWorkflowId,
  keyWorkflowName,
  gameName,
  cancel,
}) {
  const { t } = useTranslation();
  const SuccessToast = () => (
    <div>
      {t('Keys queued to be disabled')}
      <br />
      <Link to="/keys/reported-disabled">{t('View queue')}</Link>
    </div>
  );

  const handleArchive = (data) => {
    disableKeys({
      reason: t(
        'Disable unused keys in "{keyWorkflowName}" keypool on "{gameName} - {skuName}"',
        {
          keyWorkflowName: keyWorkflowName,
          gameName: gameName,
          skuName: skuName,
        }
      ),
      targetGameSKUId: skuId,
      targetKeyWorkflowId: keyWorkflowId,
      targetIsAvailable: true,
    })
      .then((d) => {
        toast.success(SuccessToast);
        done(d);
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to enqueue disable keys request'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Disable confirmation')}
      cancel={cancel}
      done={done}
    >
      <KwPanel>
        <ConfirmationHeader />
        <p>
          <Trans
            i18nKey="disableAllCodesForSKU"
            components={{ bold: <b /> }}
            values={{
              gameName: gameName,
              skuName: skuName,
              keyWorkflowName: keyWorkflowName,
            }}
          >
            This will disable all pooled codes for this SKU{' '}
            <bold>"{gameName}"</bold> - <bold>"{skuName}"</bold> and key
            workflow <bold>"{keyWorkflowName}"</bold> that are currently
            "available".
          </Trans>
        </p>
        <hr />
        <Button
          className="btn btn-danger me-2"
          onClick={handleArchive}
          text={t('Yes, disable the codes')}
        />
        <Button
          className="btn btn-default"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
DisableUnusedKeys.propTypes = {
  skuId: PropTypes.string.isRequired,
  skuName: PropTypes.string.isRequired,
  keyWorkflowId: PropTypes.string.isRequired,
  keyWorkflowName: PropTypes.string.isRequired,
  gameName: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default DisableUnusedKeys;
