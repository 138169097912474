import React, { useState } from 'react';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import KeyPoolNotificationForm from '../components/KeyPoolNotificationForm';
import { saveKeyPoolNotification } from '../../../../actions/keyPoolNotificationActions';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';

function Add({ done, id, cancel, games, workflows, keyProviders, platforms }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    saveKeyPoolNotification({ ...keyPoolNotification, ...data })
      .then(() => {
        toast.success(t('Key pool notification saved'));
        done(keyPoolNotification);
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to save key pool notification'));
      });
  };

  const [keyPoolNotification] = useState({});

  return (
    <SlideoutLayout
      title={
        id ? t('Edit key pool notification') : t('Add key pool notification')
      }
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="submit"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <KeyPoolNotificationForm
        keyPoolNotification={keyPoolNotification}
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        control={control}
        games={games}
        workflows={workflows}
        keyProviders={keyProviders}
        platforms={platforms}
      ></KeyPoolNotificationForm>
    </SlideoutLayout>
  );
}
Add.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  games: PropTypes.array.isRequired,
  workflows: PropTypes.array.isRequired,
  keyProviders: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
};
export default Add;
