import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import icons from '../../../../../constants/icons';
import { useTranslation } from 'react-i18next';

function WarningCell({ promotion, game, editable }) {
  const { t } = useTranslation();
  return (
    <div className={'grid-warning'}>
      <FontAwesomeIcon icon={icons.ARE_YOU_SURE} className="pe-2" />
      {t('Warning message')}
    </div>
  );
}

WarningCell.propTypes = {
  promotion: PropTypes.object,
  game: PropTypes.object,
  editable: PropTypes.bool,
};

export default WarningCell;
