import React from 'react';
import KwPanel from '../../common/KwPanel';
import FormTextArea from '../../common/FormTextArea';
import FormCheckBoxList from '../../common/FormCheckBoxList';
import Button from '../../common/Button';
import ConfirmationHeader from '../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function UploadDisabledForm({
  showKeyProviders,
  keyProviders,
  disableReasons,
  register,
  errors,
  control,
  submit,
  cancel,
  getValues,
  setValue,
  defaultReason,
}) {
  const { t } = useTranslation();
  return (
    <>
      <KwPanel className="mb-4 pb-1">
        {showKeyProviders && (
          <FormCheckBoxList
            label={t('Key providers')}
            required
            name="keyProviders"
            error={errors.keyProviders}
            getValues={getValues}
            setValue={setValue}
            control={control}
            items={
              keyProviders
                ? [...keyProviders]
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((x) => ({ text: x.name, value: x.id }))
                : null
            }
          ></FormCheckBoxList>
        )}

        <FormCheckBoxList
          label={t('Any codes in the following status')}
          required
          name="codeStatuses"
          error={errors.codeStatuses}
          getValues={getValues}
          setValue={setValue}
          control={control}
          items={[
            { text: t('Available'), value: 1 },
            { text: t('Allocated'), value: 2 },
            { text: t('Activated'), value: 4 },
            { text: t('Sold'), value: 8 },
            { text: t('Expired'), value: 16 },
          ]}
        ></FormCheckBoxList>

        <FormCheckBoxList
          label={t('Reason')}
          required
          name="disabledReasons"
          errorMessage={t('Required')}
          error={errors.reasons}
          getValues={getValues}
          setValue={setValue}
          control={control}
          items={
            disableReasons &&
            [...disableReasons]
              ?.sort((a, b) => a.reason.localeCompare(b.reason))
              .map((x) => ({ text: x.reason, value: x.id }))
          }
        ></FormCheckBoxList>

        <FormTextArea
          register={register}
          required
          error={errors.reason}
          errorMessage={t('Required')}
          name="reason"
          label={t('Please provide more information')}
          placeholder=""
          defaultValue={defaultReason}
        />
      </KwPanel>
      <KwPanel className="mb-4">
        <>
          <ConfirmationHeader />
          <p>
            {t(
              'Are you sure you want to queue these to be marked as disabled?'
            )}
          </p>
          <Button
            onClick={submit}
            className="btn btn-primary me-2"
            text={t('Yes, continue')}
          />
          <Button
            onClick={cancel}
            className="btn btn-default"
            text={t('No, cancel')}
          />
        </>
      </KwPanel>
    </>
  );
}

export default UploadDisabledForm;
