import React, { useState } from 'react';
import { connect } from 'react-redux';
import { hasPermission } from '../auth/authUtils';
import permissionTypes from '../../constants/permissionTypes';
import KwPanel from '../common/KwPanel';
import ActionBar from '../layout/ActionBar';
import UploadActivated from './slideout/UploadActivated';
import UploadDisabled from './slideout/UploadDisabled';
import UploadSold from './slideout/UploadSold';
import SlidingPane from 'react-sliding-pane';
import Button from '../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';
import icons from '../../constants/icons';

function Keys({ location, currentUser }) {
  const [uploadSoldKeysSlideoutOpen, setUploadSoldKeysSlideoutOpen] =
    useState(false);
  const [uploadActivatedKeysSlideoutOpen, setUploadActivatedKeysSlideoutOpen] =
    useState(false);
  const [uploadDisabledKeysSlideoutOpen, setUploadDisabledKeysSlideoutOpen] =
    useState(false);
  const { t } = useTranslation();

  return (
    <>
      <ActionBar></ActionBar>
      <KwPanel className="pb-2 space-bottom">
        <header className="mb-2">
          <h2>
            <FontAwesomeIcon icon={icons.KEY_SOLD} className="c-green me-2" />
            {t('Sold keys')}
          </h2>
          <button
            className="btn btn-default"
            onClick={() => setUploadSoldKeysSlideoutOpen(true)}
          >
            {t('Upload')}
          </button>
        </header>
        <p>
          <Trans
            i18nKey="reportKeysAsSold"
            components={{
              span: (
                <span
                  className="a"
                  onClick={() => setUploadSoldKeysSlideoutOpen(true)}
                />
              ),
            }}
          >
            To report keys as sold, <span>upload them here</span>
          </Trans>
        </p>
      </KwPanel>
      {hasPermission(currentUser, permissionTypes.UPLOAD_ACTIVATIONS) && (
        <KwPanel className="pb-2 space-bottom">
          <header className="mb-2">
            <h2>
              <FontAwesomeIcon
                icon={icons.KEY_ACTIVATED}
                className="c-yellow me-2"
              />
              {t('Activated keys')}
            </h2>
            <Button
              className="btn btn-default"
              onClick={() => setUploadActivatedKeysSlideoutOpen(true)}
              text={t('Upload')}
            />
          </header>
          <p>
            <Trans
              i18nKey="reportKeysAsActivated"
              components={{
                span: (
                  <span
                    className="a"
                    onClick={() => setUploadActivatedKeysSlideoutOpen(true)}
                  />
                ),
              }}
            >
              To report keys as activated, <span>upload them here</span>
            </Trans>
          </p>
        </KwPanel>
      )}
      <KwPanel className="pb-2 space-bottom">
        <header className="mb-2">
          <h2>
            <FontAwesomeIcon icon={icons.KEY_DISABLED} className="c-red me-2" />
            {t('Disabled keys')}
          </h2>
          <Button
            className="btn btn-default"
            onClick={() => setUploadDisabledKeysSlideoutOpen(true)}
            text={t('Upload')}
          />
        </header>
        <p>
          <Trans
            i18nKey="reportKeysAsDisabled"
            components={{
              span: (
                <span
                  className="a"
                  onClick={() => setUploadDisabledKeysSlideoutOpen(true)}
                />
              ),
            }}
          >
            To report keys as disabled, <span>upload them here</span>
          </Trans>
        </p>
      </KwPanel>

      <SlidingPane
        isOpen={uploadSoldKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => {
          setUploadSoldKeysSlideoutOpen(false);
        }}
      >
        <UploadSold
          done={() => {
            setUploadSoldKeysSlideoutOpen(false);
          }}
          cancel={() => setUploadSoldKeysSlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={uploadActivatedKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => {
          setUploadActivatedKeysSlideoutOpen(false);
        }}
      >
        <UploadActivated
          done={() => {
            setUploadActivatedKeysSlideoutOpen(false);
          }}
          cancel={() => setUploadActivatedKeysSlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={uploadDisabledKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => {
          setUploadDisabledKeysSlideoutOpen(false);
        }}
      >
        <UploadDisabled
          done={() => {
            setUploadDisabledKeysSlideoutOpen(false);
          }}
          cancel={() => setUploadDisabledKeysSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

Keys.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(Keys);
