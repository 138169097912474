import * as promotionApi from '../api/promotionApi';

export function savePromotion(promotion) {
  return promotionApi.savePromotion(promotion);
}

export function getPromotionById(id) {
  return promotionApi.getPromotionById(id);
}

export function getPromotions() {
  return promotionApi.getAllPromotions().then((promotions) => {
    promotions = promotions.sort((a, b) => (a.name > b.name && 1) || -1);
  });
}

export function loadPromotionsByDate({
  from,
  to,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return promotionApi.getPromotionsByDate(
    from,
    to,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadPromotions({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return promotionApi.getPromotions(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAudit(id) {
  return promotionApi.getAudit(id);
}

export function addAudit(id, comment) {
  return promotionApi.addAudit(id, comment);
}

export function deletePromotion(id) {
  return promotionApi.deletePromotion(id);
}
