import React, { useEffect } from 'react';
import FormGroup from './FormGroup';
import { Controller } from 'react-hook-form';
import { CompactPicker } from 'react-color';
import icons from '../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const FormColourPicker = ({
  label,
  name,
  defaultValue,
  placeholder,
  register,
  error,
  errorMessage,
  control,
  required,
  extraRowClasses = 'align-items-start',
  disabled,
}) => {
  const { t } = useTranslation();
  const [state, setState] = React.useState({
    displayColorPicker: false,
  });

  const [editorState, setEditorState] = React.useState({
    background: 'transparent',
  });

  useEffect(() => {
    setEditorState({
      background: defaultValue,
    });
  }, [defaultValue]);

  const handleChangeComplete = (color, onChange) => {
    setEditorState({
      background: color.hex,
    });
    onChange(color.hex);
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  const handleOpen = () => {
    setState({ displayColorPicker: !state.displayColorPicker });
  };

  const handleClose = () => {
    setState({ displayColorPicker: false });
  };

  return (
    <FormGroup
      label={label}
      extraRowClasses={extraRowClasses}
      error={error}
      errorMessage={errorMessage}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange } }) => (
          <div>
            <div style={{ display: 'flex' }}>
              <button
                onClick={handleOpen}
                disabled={disabled ?? false}
                style={{
                  backgroundColor: editorState.background,
                  width: 34,
                  height: 34,
                }}
                title={t('Pick colour')}
              ></button>
              <button
                className="btn btn-default ms-2 btn--square"
                title={t('Clear colour')}
                onClick={() => {
                  handleChangeComplete({ hex: 'transparent' }, onChange);
                }}
              >
                <FontAwesomeIcon icon={icons.DELETE} fixedWidth />
              </button>
            </div>
            {state.displayColorPicker ? (
              <div style={popover}>
                <div style={cover} onClick={handleClose} />
                <CompactPicker
                  color={editorState.background}
                  alpha={editorState.alpha}
                  onChangeComplete={(color) =>
                    handleChangeComplete(color, onChange)
                  }
                />
              </div>
            ) : null}
          </div>
        )}
      />
    </FormGroup>
  );
};
export default FormColourPicker;
