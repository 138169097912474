import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.LOAD_KEYPROVIDERS:
      return action.keyProviders;
    case actionTypes.UPDATE_KEYPROVIDER:
      return state
        ? state.map((keyProvider) =>
            keyProvider.id === action.keyProvider.id
              ? action.keyProvider
              : keyProvider
          )
        : state;
    case actionTypes.CREATE_KEYPROVIDER:
      return state ? [...state, action.keyProvider] : state;
    case actionTypes.DELETE_KEYPROVIDER:
      return state
        ? state.filter((keyProvider) => keyProvider.id !== action.id)
        : state;
    default:
      return state;
  }
}
