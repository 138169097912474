import React, { useState, useEffect } from 'react';
import Loading from '../public/components/Loading';
import { CommaNumber_NoDecimal } from '../../util/formatter';
import { useTranslation } from 'react-i18next';

const AggregateBarChart = ({
  series,
  colour,
  seriesNamePropertyName,
  seriesValuePropertyName,
  onClick,
}) => {
  const { t } = useTranslation();
  const [max, setMax] = useState();
  const [_series, setSeries] = useState(series);

  useEffect(() => {
    if (series) {
      setMax(Math.max(...series.map((s) => s[seriesValuePropertyName])));

      // sort series by viewMode
      setSeries(
        series.sort(
          (a, b) => b[seriesValuePropertyName] - a[seriesValuePropertyName]
        )
      );
    }
  }, [series, setSeries, seriesValuePropertyName]);

  const GetPercentage = (value) => {
    return (value / max) * 100;
  };

  const handleBarClick = (e, item, index) => {
    e.preventDefault();
    if (!onClick) return;
    onClick(item, index);
  };

  // return a div with each series as a bar
  return _series ? (
    <div className="aggregate-bars">
      {_series && _series.length > 0 ? (
        _series.map((s, i) => (
          <div
            key={i}
            className={`aggregate-bar-wrapper ${onClick ? 'clickable' : ''}`}
            onClick={(e) => handleBarClick(e, s, i)}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleBarClick(e, s, i);
              }
            }}
          >
            <label className="aggregateLabel">
              {s[seriesNamePropertyName]}
            </label>
            <div
              className={`aggregate-bar ${colour}`}
              style={{ width: `${GetPercentage(s[seriesValuePropertyName])}%` }}
            >
              <label className="aggregateLabel">
                {s[seriesNamePropertyName]}
              </label>
            </div>
            <label className="valueLabel">
              {CommaNumber_NoDecimal(s[seriesValuePropertyName])}
            </label>
          </div>
        ))
      ) : (
        <>{t('No data in range')}</>
      )}
    </div>
  ) : (
    <Loading inPanel={true} />
  );
};

export default AggregateBarChart;
