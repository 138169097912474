import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ActionBar from '../../layout/ActionBar';
import GameTemplateForm from './components/GameTemplateForm';
import {
  saveGameTemplate,
  getGameTemplateById,
  addTerritories,
  removeTerritories,
  addKeyWorkflows,
  removeKeyWorkflows,
} from '../../../actions/gameTemplateActions';
import { getAllTerritories } from '../../../actions/territoryActions';
import { getAllWorkflows } from '../../../actions/workflowActions';
import Loading from '../../common/Loading';
import Delete from './slideout/Delete';
import TerritoryRestrictions from '../territory/components/TerritoryRestrictions';
import WorkflowRestrictions from '../workflow/components/WorkflowRestrictions';
import SlidingPane from 'react-sliding-pane';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';
import ResponsiveActionBarButtons from '../../common/ResponsiveActionBarButtons';

function GameTemplate({
  id,
  history,
  getAllTerritories,
  territories,
  workflows,
  getAllWorkflows,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();
  const updateTerritoryRestrictions = async (data) => {
    if (!gameTemplate) {
      return false;
    }

    const territoryIds = data.territories?.map((x) => x.id);
    const exisitingIds = gameTemplate.territories?.map((x) => x.id);
    const removedTerritories = exisitingIds?.filter(
      (x) =>
        data.disallowListedTerritories === 'Unrestricted' ||
        territoryIds?.indexOf(x) === -1
    );
    const addedTerritories = territoryIds?.filter(
      (x) =>
        data.disallowListedTerritories !== 'Unrestricted' &&
        exisitingIds?.indexOf(x) === -1
    );

    let promises = [];

    if (removedTerritories?.length > 0) {
      promises.push(
        new Promise((resolve, reject) => {
          resolve(removeTerritories(id, removedTerritories));
        })
      );
    }

    if (addedTerritories?.length > 0) {
      promises.push(
        new Promise((resolve, reject) => {
          resolve(addTerritories(id, addedTerritories));
        })
      );
    }

    return Promise.all(promises);
  };

  const updateWorkflowRestrictions = async (data) => {
    const workflowIds = data.workflows?.map((x) => x.id);
    const exisitingIds = gameTemplate?.keyWorkflows?.map((x) => x.id);
    const removedWorkflows = exisitingIds?.filter(
      (x) =>
        data.disallowListedKeyWorkflows === 'Unrestricted' ||
        workflowIds?.indexOf(x) === -1
    );
    const addedWorkflows = workflowIds?.filter(
      (x) =>
        data.disallowListedKeyWorkflows !== 'Unrestricted' &&
        exisitingIds?.indexOf(x) === -1
    );

    let promises = [];

    if (removedWorkflows?.length > 0) {
      promises.push(
        new Promise((resolve, reject) => {
          resolve(removeKeyWorkflows(id, removedWorkflows));
        })
      );
    }

    if (addedWorkflows?.length > 0) {
      promises.push(
        new Promise((resolve, reject) => {
          resolve(addKeyWorkflows(id, addedWorkflows));
        })
      );
    }

    return Promise.all(promises);
  };

  const onSubmit = (data) => {
    updateTerritoryRestrictions(data)
      .then(() => {
        updateWorkflowRestrictions(data)
          .then(() => {
            // put these back to bools
            // todo - change api to use an enum for this
            data.disallowListedKeyWorkflows =
              data.disallowListedKeyWorkflows === 'Disallow';
            data.disallowListedTerritories =
              data.disallowListedTerritories === 'Disallow';

            saveGameTemplate({ ...gameTemplate, ...data })
              .then(() => {
                setGameTemplate({ ...gameTemplate, ...data });
                toast.success(t('Game template saved'));
              })
              .catch(() => {
                toast.error(t('Failed to save game template'));
              });
          })
          .catch(() => {
            toast.error(
              t(
                'Failed to update some of the key workflow restrictions, please try again'
              )
            );
          });
      })
      .catch(() => {
        toast.error(
          t(
            'Failed to update some of the territory restrictions, please try again'
          )
        );
      });
  };

  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);
  const handleDelete = (e) => {
    e.cancelBubble = true;
    setDeleteSlideoutOpen(true);
    return false;
  };

  const [gameTemplate, setGameTemplate] = useState();

  useEffect(() => {
    if (id) {
      getGameTemplateById(id)
        .then((d) => {
          setGameTemplate(d);
        })
        .catch(() => {
          toast.error(t('Failed to find game template'));
        });
    }
  }, [id, history, t]);

  useEffect(() => {
    if (!territories) {
      getAllTerritories();
    }
  }, [territories, getAllTerritories]);

  useEffect(() => {
    if (!workflows) {
      getAllWorkflows();
    }
  }, [workflows, getAllWorkflows]);

  return gameTemplate && territories && workflows ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/settings/game-templates', label: t('Game templates') },
          { label: gameTemplate.name },
        ]}
      >
        <div className="d-flex">
          <ResponsiveActionBarButtons
            buttons={[
              id && (
                <Button
                  className="btn btn-danger ms-2 d-none d-md-inline-flex"
                  onClick={handleDelete}
                  text={t('Delete')}
                />
              ),
              <Button
                key="save"
                className="btn btn-primary ms-2"
                onClick={handleSubmit(onSubmit)}
                text={t('Save')}
              />,
            ]}
          />
        </div>
      </ActionBar>
      <GameTemplateForm
        gameTemplate={gameTemplate}
        register={register}
        errors={errors}
        control={control}
      ></GameTemplateForm>
      <TerritoryRestrictions
        register={register}
        setValue={setValue}
        restricted={gameTemplate.disallowListedTerritories}
        restrictions={gameTemplate.territories}
        territories={territories}
      ></TerritoryRestrictions>
      <WorkflowRestrictions
        register={register}
        setValue={setValue}
        restricted={gameTemplate.disallowListedKeyWorkflows}
        restrictions={gameTemplate.keyWorkflows}
        workflows={workflows}
      ></WorkflowRestrictions>

      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          done={(d) => {
            setDeleteSlideoutOpen(false);
            history.push('/settings/game-templates/');
          }}
          cancel={() => setDeleteSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    territories: state.territories,
    workflows: state.workflows,
  };
}

const mapDispatchToProps = {
  getAllTerritories,
  getAllWorkflows,
};

GameTemplate.propTypes = {
  id: PropTypes.string,
  history: PropTypes.object,
  getAllTerritories: PropTypes.func,
  getAllWorkflows: PropTypes.func,
  territories: PropTypes.array,
  workflows: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameTemplate);
