import React from 'react';
import { useState } from 'react';
import Gravatar from 'react-gravatar';

const ApproverNotificationUserItem = ({
  fullName,
  userProfileId,
  emailAddress,
  notificationFilters,
  onClick,
}) => {
  const [selected, setSelected] = useState(true);

  let isSelected = () => {
    return (
      notificationFilters &&
      userProfileId &&
      !notificationFilters.find((x) => x.userProfileId === userProfileId)
    );
  };

  return (
    <div
      className={`tag ${isSelected() === true ? 'checked' : 'unchecked'}`}
      onClick={() => onClick({ fullName, userProfileId, emailAddress })}
    >
      <Gravatar
        email={emailAddress}
        size={20}
        rating="pg"
        default="retro"
        className="img-circle me-3"
      />
      <span>{fullName}</span>
    </div>
  );
};
export default ApproverNotificationUserItem;
