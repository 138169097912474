import React from 'react';
import { withRouter } from 'react-router-dom';
import ActionBar from '../layout/ActionBar';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import ReportTableViewSwitcher from './components/ReportTableViewSwitcher';
import RequestsByWeekListBySKU from './components/week/RequestsByWeekListBySKU';
import RequestsByMonthListBySKU from './components/month/RequestsByMonthListBySKU';

function RequestsBySKU({ location, history }) {
  const [groupBy, setGroupBy] = useLocalStorage('report-group-by', 'month');

  return (
    <>
      <ActionBar></ActionBar>
      {groupBy === 'month' ? (
        <RequestsByMonthListBySKU
          location={location}
          history={history}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      ) : (
        <RequestsByWeekListBySKU
          location={location}
          history={history}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      )}
    </>
  );
}

export default withRouter(RequestsBySKU);
