import React from 'react';
import PropTypes from 'prop-types';

function PercentageCell({ promotion, game, editable }) {
  return editable ? (
    <div className={'grid-pct'}>
      <input
        type="text"
        className="form-control me-2"
        value={(promotion.discount * 100).toFixed()}
      />{' '}
      %
    </div>
  ) : (
    <div className={'grid-pct-readonly'}>
      {(promotion.discount * 100).toFixed()}%
    </div>
  );
}

PercentageCell.propTypes = {
  promotion: PropTypes.object,
  game: PropTypes.object,
  editable: PropTypes.bool,
};

export default PercentageCell;
