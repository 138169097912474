import * as packageTypeApi from '../api/packageTypeApi';
import actionTypes from '../constants/actionTypes';

export function savePackageType(packageType) {
  return function (dispatch) {
    return packageTypeApi
      .savePackageType(packageType)
      .then((savedPackageType) => {
        dispatch({
          type: packageType.id
            ? actionTypes.UPDATE_PACKAGETYPE
            : actionTypes.CREATE_PACKAGETYPE,
          packageType: { ...packageType, id: savedPackageType.id },
        });
      });
  };
}

export function getPackageTypeById(id) {
  return packageTypeApi.getPackageTypeById(id);
}

export function loadPackageTypes({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return packageTypeApi.getPackageTypes(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getStats(id) {
  return packageTypeApi.getStats(id);
}

export function getAudit(id) {
  return packageTypeApi.getAudit(id);
}

export function addAudit(id, comment) {
  return packageTypeApi.addAudit(id, comment);
}

export function getAllPackageTypes() {
  return function (dispatch) {
    return packageTypeApi.getAllPackageTypes().then((packageTypes) => {
      dispatch({
        type: actionTypes.LOAD_PACKAGETYPES,
        packageTypes,
      });
    });
  };
}

export function deletePackageType(id) {
  return function (dispatch) {
    return packageTypeApi.deletePackageType(id).then(() => {
      dispatch({
        type: actionTypes.DELETE_PACKAGETYPE,
        id,
      });
    });
  };
}

export function exportPackageTypeData(filters, scopes) {
  return packageTypeApi.exportPackageTypeData(filters, scopes);
}
