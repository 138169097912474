import React, { useState } from 'react';
import { connect } from 'react-redux';
import KeyPoolsList from './components/KeyPoolsList';
import ActionBar from '../layout/ActionBar';
import KeyPoolUpload from './slideout/KeyPoolUpload';
import { addToGroup, removeFromGroup } from '../../actions/signalRActions';
import DisableUnusedKeys from './slideout/DisableUnusedKeys';
import SlidingPane from 'react-sliding-pane';

function KeyPools({
  location,
  addToGroup,
  removeFromGroup,
  importTransactions,
  currentUser,
}) {
  const [reload, setReload] = useState();

  const [disableUnusedSlideoutOpen, setDisableUnusedSlideoutOpen] =
    useState(false);
  const [disableUnusedSlideoutItem, setDisableUnusedSlideoutItem] = useState();

  const [uploadSlideoutOpen, setUploadSlideoutOpen] = useState(false);
  const [uploadSlideoutItem, setUploadSlideoutItem] = useState();

  return (
    <>
      <ActionBar></ActionBar>
      <KeyPoolsList
        location={location}
        reload={reload}
        upload={(e, item) => {
          setUploadSlideoutItem(item);
          setUploadSlideoutOpen(true);
        }}
        addToGroup={addToGroup}
        removeFromGroup={removeFromGroup}
        importTransactions={importTransactions}
        onDisableUnused={(e, item) => {
          setDisableUnusedSlideoutItem(item);
          setDisableUnusedSlideoutOpen(true);
        }}
        currentUser={currentUser}
      />

      <SlidingPane
        isOpen={disableUnusedSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDisableUnusedSlideoutOpen(false)}
      >
        {disableUnusedSlideoutItem ? (
          <DisableUnusedKeys
            done={(d) => {
              setDisableUnusedSlideoutOpen(false);
            }}
            cancel={() => setDisableUnusedSlideoutOpen(false)}
            skuId={disableUnusedSlideoutItem.skuId}
            skuName={disableUnusedSlideoutItem.skuName}
            keyWorkflowId={disableUnusedSlideoutItem.keyWorkflowId}
            keyWorkflowName={disableUnusedSlideoutItem.keyWorkflowName}
            gameName={disableUnusedSlideoutItem.gameName}
          />
        ) : (
          <></>
        )}
      </SlidingPane>

      <SlidingPane
        isOpen={uploadSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setUploadSlideoutOpen(false)}
      >
        {uploadSlideoutItem ? (
          <KeyPoolUpload
            done={(d) => {
              setUploadSlideoutOpen(false);
              setReload(new Date());
            }}
            cancel={() => setUploadSlideoutOpen(false)}
            gameId={uploadSlideoutItem.gameId}
            gameName={uploadSlideoutItem.gameName}
            skuId={uploadSlideoutItem.skuId}
            skuName={uploadSlideoutItem.skuName}
            keyWorkflowId={uploadSlideoutItem.keyWorkflowId}
            keyWorkflowName={uploadSlideoutItem.keyWorkflowName}
          />
        ) : (
          <></>
        )}
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    importTransactions: state.importTransactions,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  addToGroup,
  removeFromGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyPools);
