import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import TableActionBar from './TableActionBar';
import TableRow from './table/TableRow';
import TableRowFileUpload from './table/TableRowFileUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Help from './Help';
import { useTranslation } from 'react-i18next';
import icons from '../../constants/icons';

function SimpleTextFilter({ Value, onChange, placeholder }) {
  const [filter, setFilter] = useState(Value);
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onChange(filter);
    }
  };
  return (
    <input
      type="text"
      className={`form-control ${filter ? 'filtered' : ''}`}
      value={filter}
      onChange={(event) => setFilter(event.target.value)}
      onBlur={() => onChange(filter)}
      onKeyPress={handleKeyPress}
      placeholder={placeholder}
    />
  );
}

function HeaderColumn({ sortedField, setSortedField, col, isLoading }) {
  let getTHSortClassNames = (col) => {
    if ((col.Sort ?? true) && sortedField !== undefined) {
      if (col.Key === sortedField.key) {
        return `sortable ${
          sortedField.direction === 'asc' ? 'sorted_asc' : 'sorted_desc'
        } ${col.className || ''} ${isLoading ? 'disabled' : ''}`;
      }
      return `sortable ${col.className || ''} ${isLoading ? 'disabled' : ''}`;
    }
    return col.className || '';
  };

  let getTHSortIcon = (col) => {
    if ((col.Sort ?? true) && sortedField !== undefined) {
      if (col.Key === sortedField.key) {
        return sortedField.direction === 'asc' ? (
          <FontAwesomeIcon icon={icons.SORT_ASC} />
        ) : (
          <FontAwesomeIcon icon={icons.SORT_DESC} />
        );
      }
      return <FontAwesomeIcon icon={icons.SORT_ASC} />;
    }
    return <></>;
  };

  let handleSort = () => {
    if (setSortedField && !isLoading) {
      setSortedField({
        key: col.Key,
        direction:
          sortedField !== undefined &&
          sortedField.key === col.Key &&
          sortedField.direction === 'asc'
            ? 'desc'
            : 'asc',
      });
    }
  };
  return (
    <th
      className={getTHSortClassNames(col)}
      onClick={handleSort}
      style={{ width: col.width || 'auto' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className="w-100 flex align-items-center">
          <div className={`${col.className || ''} w-100`}>{col.Label}</div>
          {col.helpContent && (
            <Help helpContent={col.helpContent} className={'ms-2'} />
          )}
        </div>
        <div className="sort-icon">{getTHSortIcon(col)}</div>
      </div>
    </th>
  );
}

function FilterColumn({ filterChange, col }) {
  let getFilter = (col) =>
    col.Filter !== undefined &&
    (col.Filter.Render !== undefined ? (
      col.Filter.Render(col)
    ) : (
      <SimpleTextFilter
        value={col.Filter.Value}
        onChange={(value) => filterChange(col.Key, value)}
      />
    ));
  return <th className={col.className}>{getFilter(col)}</th>;
}
function Table({
  columns,
  allColumns,
  defaultVisibleColumns,
  rows,
  totalRowCount,
  filteredRowCount,
  tableSettings,
  setTableSettings,
  className,
  isLoading,
  noDataComponent,
  filterChange,
  trStyle,
  exportData,
  setBulkActionsSlideoutOpen,
  itemsChecked,
  bulkActionTitle,
  tableActionBarButtons,
  suffixColumns,
  filters,
  scopes,
  location,
  setBreadcrumb,
  hideScopeTags,
  shouldRowUpload,
  handleRowFileUpload,
}) {
  const { t } = useTranslation();
  const setSortedField = (e) =>
    setTableSettings({ ...tableSettings, sortedField: e });
  const setPageNumber = (e) =>
    setTableSettings({ ...tableSettings, pageNumber: e });
  const setPageSize = (e) => {
    setTableSettings({ ...tableSettings, pageSize: e, pageNumber: 0 });
  };
  const setVisibleColumns = (cols) => {
    setTableSettings({ ...tableSettings, columns: cols });
  };
  let columnsWithSuffix = suffixColumns
    ? [...columns, ...suffixColumns]
    : columns;

  const showNoDataComponent =
    !isLoading && noDataComponent !== undefined && totalRowCount === 0;

  useEffect(() => {
    if (scopes && setBreadcrumb) {
      setBreadcrumb((prevState) => [
        ...prevState.filter((x) => !x.isScope),
        ...scopes.map((scope) => ({ label: scope.name, isScope: true })),
      ]);
    }
  }, [scopes, setBreadcrumb]);

  return showNoDataComponent ? (
    noDataComponent
  ) : (
    <>
      <TableActionBar
        exportData={exportData}
        setBulkActionsSlideoutOpen={setBulkActionsSlideoutOpen}
        itemsChecked={itemsChecked}
        bulkActionTitle={bulkActionTitle}
        columns={columns.map((x) => x.Key)}
        allColumns={allColumns}
        defaultVisibleColumns={defaultVisibleColumns || allColumns}
        setVisibleColumns={setVisibleColumns}
        filters={filters}
        scopes={scopes}
        hideScopeTags={hideScopeTags}
        location={location}
      >
        {tableActionBarButtons}
      </TableActionBar>
      <div className="table-wrapper">
        <div className={`loadingframe ${isLoading ? 'loading' : ''}`}>
          <div className="loadingbar"></div>
        </div>
        <table className={`table dataTable width-100 ${className || ''}`}>
          <thead>
            <tr>
              {columnsWithSuffix.map((col) => {
                return col.Sort === false ? (
                  <HeaderColumn key={col.Key} col={col} isLoading={isLoading} />
                ) : (
                  <HeaderColumn
                    key={col.Key}
                    col={col}
                    sortedField={tableSettings.sortedField}
                    setSortedField={setSortedField}
                    isLoading={isLoading}
                  />
                );
              })}
            </tr>

            {columnsWithSuffix.some((x) => x.Filter !== undefined) && (
              <tr>
                {columnsWithSuffix.map((col) => {
                  return (
                    <FilterColumn
                      key={col.Key}
                      col={col}
                      filterChange={filterChange}
                    />
                  );
                })}
              </tr>
            )}
          </thead>
          <tbody>
            {rows.map((row) => {
              return shouldRowUpload && shouldRowUpload(row) ? (
                <TableRowFileUpload
                  key={row.Key}
                  row={row}
                  rows={rows}
                  totalRowCount={totalRowCount}
                  sortedField={tableSettings.sortedField}
                  trStyle={trStyle}
                  columnsWithSuffix={columnsWithSuffix}
                  handleRowFileUpload={handleRowFileUpload}
                />
              ) : (
                <TableRow
                  key={row.Key}
                  row={row}
                  rows={rows}
                  totalRowCount={totalRowCount}
                  sortedField={tableSettings.sortedField}
                  trStyle={trStyle}
                  columnsWithSuffix={columnsWithSuffix}
                />
              );
            })}
          </tbody>
        </table>
        {rows && rows.length === 0 && !isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={icons.NOTHING_TO_SHOW}
              // No constant for this
              size="3x"
              className="me-3 c-dark-yellow"
            ></FontAwesomeIcon>{' '}
            {t('Nothing to show')}
          </div>
        ) : (
          ''
        )}
        <Pagination
          totalRowCount={totalRowCount}
          filteredRowCount={filteredRowCount}
          pageNumber={tableSettings.pageNumber}
          setPageNumber={setPageNumber}
          pageSize={tableSettings.pageSize}
          setPageSize={setPageSize}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}
Table.propTypes = {
  allColumns: PropTypes.array,
  defaultVisibleColumns: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Label: PropTypes.any.isRequired,
      SortDirection: PropTypes.number,
    })
  ).isRequired,

  rows: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
    })
  ).isRequired,

  tableSettings: PropTypes.shape({
    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }),
  className: PropTypes.string,
  trStyle: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  noDataComponent: PropTypes.element,
  setTableSettings: PropTypes.func.isRequired,
  totalRowCount: PropTypes.number.isRequired,
  filteredRowCount: PropTypes.number.isRequired,
  filterChange: PropTypes.func.isRequired,
  exportData: PropTypes.func,
  setBulkActionsSlideoutOpen: PropTypes.func,
  itemsChecked: PropTypes.array,
  bulkActionTitle: PropTypes.string,
  tableActionBarButtons: PropTypes.element,
  suffixColumns: PropTypes.array,
  filters: PropTypes.object,
  scopes: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object,
  setBreadcrumb: PropTypes.func,
  hideScopeTags: PropTypes.object,
  shouldRowUpload: PropTypes.func,
  handleRowFileUpload: PropTypes.func,
};

const getTableSettingsDefaults = ({
  defaultSort,
  direction,
  pageSize,
  defaultVisibleColumns,
}) => {
  return {
    sortedField: {
      key: defaultSort,
      direction: direction || 'asc',
    },
    pageNumber: 0,
    pageSize: pageSize || 10,
    columns: defaultVisibleColumns,
  };
};

export { Table, SimpleTextFilter, getTableSettingsDefaults };
