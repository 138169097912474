import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FriendlyTimeFromMinutes } from '../../../../util/formatter';
import { useTranslation } from 'react-i18next';
import { ToggleSwitch } from '../../../common/ToggleSwitch';

const TargetsForm = ({
  keyWorkflowName,
  workflowSteps,
  onStepChange,
  workflowSLAMinutes,
  workflowEnableSLAReminder,
  onWorkflowSLAMinutesChange,
  onWorkflowEnableSLAReminderChange,
}) => {
  const { t } = useTranslation();
  const [steps, setSteps] = useState(workflowSteps);
  const [slaMinutes, setSLAMinutes] = useState(workflowSLAMinutes);
  const [enableSLAReminder, setEnableSLAReminder] = useState(
    workflowEnableSLAReminder
  );

  const update = (newArr) => {
    setSteps(newArr);
    onStepChange(newArr);
  };
  const handleSLAMinutesChange = ({ value, step, index }, props) => {
    let newArr = [...steps];
    newArr[index].slaMinutes = parseFloat(value.replace(/,/g, '')) || 0;
    update(newArr);
  };
  const handleEnableSLAReminderChange = ({ value, step, index }) => {
    let newArr = [...steps];
    newArr[index].enableSLAReminder = value;
    update(newArr);
  };
  const handleWorkflowSLAMinutesChange = ({ value }) => {
    let val = parseFloat(value.replace(/,/g, '')) || 0;
    setSLAMinutes(val);
    onWorkflowSLAMinutesChange(val);
  };
  const handleWorkflowEnableSLAReminderChange = ({ value }) => {
    setEnableSLAReminder(value);
    onWorkflowEnableSLAReminderChange(value);
  };

  return (
    <>
      <table className="table dataTable width-100">
        <thead>
          <tr>
            <th>{t('Step')}</th>
            <th>{t('Enable reminders')}</th>
            <th>{t('SLA (minutes)')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {steps &&
            steps.length > 0 &&
            steps.map((step, index) => {
              return (
                <tr key={`${step.sequence}-${step.userProfileGroupId}`}>
                  <td>
                    <NavLink
                      to={`/settings/user-profile-group/${step.userProfileGroupId}`}
                    >
                      {step.groupName}
                    </NavLink>
                  </td>
                  <td className="no-wrap">
                    <ToggleSwitch
                      value={step.enableSLAReminder || false}
                      onChange={(value) =>
                        handleEnableSLAReminderChange({ value, step, index })
                      }
                    />
                  </td>
                  <td className="no-wrap">
                    <input
                      type="text"
                      className={`form-control me-2`}
                      defaultValue={step.slaMinutes || 0}
                      data-lpignore="true"
                      style={{ width: '75px' }}
                      autoComplete="off"
                      onChange={(e) => {
                        handleSLAMinutesChange({
                          value: e.target.value,
                          step,
                          index,
                        });
                      }}
                    />
                  </td>
                  <td>{FriendlyTimeFromMinutes(step.slaMinutes)}</td>
                </tr>
              );
            })}
          <tr>
            <td>{t('Key upload')}</td>
            <td className="no-wrap">
              <ToggleSwitch
                value={enableSLAReminder || false}
                onChange={(value) =>
                  handleWorkflowEnableSLAReminderChange({ value })
                }
              />
            </td>
            <td className="no-wrap">
              <input
                type="text"
                className={`form-control me-2`}
                defaultValue={slaMinutes || 0}
                data-lpignore="true"
                style={{ width: '75px' }}
                autoComplete="off"
                onChange={(e) => {
                  handleWorkflowSLAMinutesChange({
                    value: e.target.value,
                  });
                }}
              />
            </td>
            <td>{FriendlyTimeFromMinutes(slaMinutes)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

TargetsForm.propTypes = {
  workflowSteps: PropTypes.array.isRequired,
  onStepChange: PropTypes.func.isRequired,
  workflowSLAMinutes: PropTypes.number.isRequired,
  workflowEnableSLAReminder: PropTypes.bool.isRequired,
  onWorkflowSLAMinutesChange: PropTypes.func.isRequired,
  onWorkflowEnableSLAReminderChange: PropTypes.func.isRequired,
};

export default TargetsForm;
