import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ActionBar from '../../layout/ActionBar';
import KeyProviderForm from './components/KeyProviderForm';
import Stats from './components/Stats';
import Delete from './slideout/Delete';
import EditRequestInformation from './slideout/EditRequestInformation';
import {
  getKeyProviderById,
  saveKeyProvider,
  getStats,
  getKeyProviderRequestInformation,
  getAudit,
  addAudit,
  saveKeyProviderPromotionSetting,
  getKeyProviderPromotionSetting,
  getStatsByDay,
} from '../../../actions/keyProviderActions';
import Loading from '../../common/Loading';
import KeyProviderRequestInformation from './components/KeyProviderRequestInformation';
import KwPanel from '../../common/KwPanel';
import UploaderBadges from '../uploader/components/UploaderBadges';
import PlatformBadges from './components/PlatformBadges';
import AuditButton from '../../audit/components/AuditButton';
import KeyProviderPromotionSettingForm from './components/KeyProviderPromotionSettingForm';
import permissionTypes from '../../../constants/permissionTypes';
import { hasPermission } from '../../auth/authUtils';
import SlidingPane from 'react-sliding-pane';
import { useTranslation } from 'react-i18next';

import image_tag from '../../../content/images/tag.svg';
import ResponsiveActionBarButtons from '../../common/ResponsiveActionBarButtons';
import Button from '../../common/Button';

function KeyProvider({ id, saveKeyProvider, history, currentUser }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const handleDelete = (e) => {
    setDeleteSlideoutOpen(true);
    return false;
  };

  const [keyProvider, setKeyProvider] = useState({});
  const [keyProviderPromotionSetting, setKeyProviderPromotionSetting] =
    useState();
  const [stats, setStats] = useState();
  const [requestInformation, setRequestInformation] = useState();

  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);
  const [
    editRequestInformationSlideoutOpen,
    setEditRequestInformationSlideoutOpen,
  ] = useState(false);

  const [file, setFile] = useState();

  const onSubmit = (data) => {
    saveKeyProvider({
      ...keyProvider,
      ...{
        colourHex: data.colourHex,
        name: data.name,
        packageIdLabel: data.packageIdLabel,
        packshotUrlFormat: data.packshotUrlFormat,
        validCodeFormatMasks: data.validCodeFormatMasks,
        instructions: data.instructions,
        disabledKeyBatchLimit: data.disabledKeyBatchLimit,
        file: file,
        bulkFilenameRegexPattern: data.bulkFilenameRegexPattern,
      },
    })
      .then(() => {
        setKeyProvider({
          ...keyProvider,
          ...{
            colourHex: data.colourHex,
            name: data.name,
            packageIdLabel: data.packageIdLabel,
            packshotUrlFormat: data.packshotUrlFormat,
            validCodeFormatMasks: data.validCodeFormatMasks,
            disabledKeyBatchLimit: data.disabledKeyBatchLimit,
            bulkFilenameRegexPattern: data.bulkFilenameRegexPattern,
            instructions: data.instructions,
          },
        });

        if (hasPermission(currentUser, permissionTypes.MANAGE_PROMOTIONS)) {
          saveKeyProviderPromotionSetting(id, {
            cooldownPeriodDays: data.cooldownPeriodDays,
            maximumPromoDays: data.maximumPromoDays,
            minimumPromoDays: data.minimumPromoDays,
          })
            .then(() => {
              setKeyProviderPromotionSetting({
                cooldownPeriodDays: data.cooldownPeriodDays,
                maximumPromoDays: data.maximumPromoDays,
                minimumPromoDays: data.minimumPromoDays,
              });
              toast.success(t('Key provider saved'));
            })
            .catch(() => {
              toast.error(t('Failed to save key provider'));
            });
        } else {
          toast.success(t('Key provider saved'));
        }
      })
      .catch(() => {
        toast.error(t('Failed to save key provider'));
      });
  };

  useEffect(() => {
    if (id) {
      getKeyProviderById(id)
        .then((d) => {
          setKeyProvider(d);
        })
        .catch((e) => {
          toast.error(t('Failed to find key provider'));
        });

      // get the stats
      getStats(id)
        .then((d) => {
          setStats(d);
        })
        .catch((e) => {
          toast.error(t('Failed to find key provider stats'));
        });
    }
  }, [id, history, t]);

  useEffect(() => {
    if (id && !requestInformation) {
      // get the request information
      getKeyProviderRequestInformation(id)
        .then((d) => {
          setRequestInformation(d);
        })
        .catch((e) => {
          toast.error(t('Failed to find key provider request information'));
        });
    }
  }, [id, requestInformation, t]);

  useEffect(() => {
    if (hasPermission(currentUser, permissionTypes.MANAGE_PROMOTIONS) && id) {
      // get the request information
      getKeyProviderPromotionSetting(id)
        .then((d) => {
          setKeyProviderPromotionSetting(d);
        })
        .catch((e) => {
          toast.error(t('Failed to find key provider promotion settings'));
        });
    }
  }, [id, currentUser, t]);

  return keyProvider && stats ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/settings/key-providers', label: t('Key providers') },
          { label: keyProvider.name },
        ]}
      >
        <div className="d-flex">
          <AuditButton id={id} getAudit={getAudit} addAudit={addAudit} />
          <ResponsiveActionBarButtons
            buttons={[
              id && (
                <Button
                  className="btn btn-danger ms-2 d-none d-md-inline-flex"
                  onClick={handleDelete}
                  text={t('Delete')}
                />
              ),
              <Button
                key="save"
                className="btn btn-primary ms-2"
                onClick={handleSubmit(onSubmit)}
                text={t('Save')}
              />,
            ]}
          />
        </div>
      </ActionBar>
      <Stats
        id={keyProvider.id}
        stats={stats}
        getData={() => getStatsByDay(keyProvider.id)}
      ></Stats>

      <KwPanel className="mb-4 pb-1">
        <KeyProviderForm
          keyProvider={keyProvider}
          register={register}
          errors={errors}
          control={control}
          setFile={setFile}
        ></KeyProviderForm>
      </KwPanel>

      {keyProviderPromotionSetting ? (
        <KwPanel className="mb-4 pb-1">
          <header className="mb-2">
            <h2 className="no-margin">
              <span className="workflow-icon">
                <img
                  src={image_tag}
                  alt={t('Upload keys icon')}
                  width="32"
                  height="32"
                />
              </span>
              {t('Promotion settings')}
            </h2>
          </header>
          <KeyProviderPromotionSettingForm
            keyProviderPromotionSetting={keyProviderPromotionSetting}
            register={register}
            errors={errors}
            control={control}
          ></KeyProviderPromotionSettingForm>
        </KwPanel>
      ) : (
        ''
      )}

      <PlatformBadges
        label={t('Supported platforms')}
        keyProviderId={id}
      ></PlatformBadges>
      <UploaderBadges
        label={t('Who can upload keys')}
        keyProviderId={id}
      ></UploaderBadges>
      {requestInformation && (
        <KeyProviderRequestInformation
          keyProvider={keyProvider}
          handleEditInternalRequestInformation={() => {
            setEditRequestInformationSlideoutOpen(true);
          }}
          requestInformation={requestInformation}
        />
      )}

      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          done={(d) => {
            history.push('/settings/key-providers/');
            setDeleteSlideoutOpen(false);
          }}
          cancel={() => setDeleteSlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={editRequestInformationSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setEditRequestInformationSlideoutOpen(false)}
      >
        {requestInformation ? (
          <EditRequestInformation
            id={id}
            done={(d) => {
              // reload request information
              setRequestInformation(null);
              setEditRequestInformationSlideoutOpen(false);
            }}
            cancel={() => setEditRequestInformationSlideoutOpen(false)}
            internal={true}
            requestInformation={requestInformation}
          />
        ) : (
          <></>
        )}
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  saveKeyProvider,
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyProvider);
