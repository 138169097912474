import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import {
  addPermissionGroup,
  addPermissionUser,
} from '../../../../actions/permissionActions';
import { toast } from 'react-toastify';
import FormSelect from '../../../common/FormSelect';
import FormButtonGroup from '../../../common/FormButtonGroup';
import FormGroup from '../../../common/FormGroup';
import Search from '../../users/components/Search';
import { useTranslation } from 'react-i18next';

function AddMember({ permissionKeys, groups, done, cancel }) {
  const { t } = useTranslation();
  const [mode, setMode] = useState('Group');
  const [user, setUser] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    if (mode === 'Group') {
      addPermissionGroup(permissionKeys, data.id)
        .then(() => {
          toast.success(
            permissionKeys.length > 1
              ? t('Permissions granted to user group')
              : t('Permission granted to user group')
          );
          done();
        })
        .catch(() => {
          toast.error(
            permissionKeys.length > 1
              ? t('Failed to grant permissions')
              : t('Failed to grant permission')
          );
        });
      return;
    } else if (mode === 'User' && user) {
      addPermissionUser(permissionKeys, user.id)
        .then((requester) => {
          toast.success(
            permissionKeys.length > 1
              ? t('Permissions granted to user')
              : t('Permission granted to user')
          );
          done();
        })
        .catch(() => {
          toast.error(
            permissionKeys.length > 1
              ? t('Failed to grant permissions to user')
              : t('Failed to grant permission to user')
          );
        });
      return;
    }
    toast.error(t('Please required fields'));
  };

  const handleUserSearchChange = (d) => {
    if (d && d.length > 0) {
      setUser(d[0]);
    } else {
      setUser(undefined);
    }
  };

  return (
    <SlideoutLayout
      title={t('Add member')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      {groups && (
        <>
          <FormButtonGroup
            register={register}
            error={errors.type}
            errorMessage={t('Type is required')}
            name="mode"
            label={t('Type of member')}
            buttons={[
              { value: 'Group', text: t('Group') },
              { value: 'User', text: t('User') },
            ]}
            onChange={(btn) => setMode(btn.value)}
            value={mode}
          ></FormButtonGroup>
          {mode === 'Group' ? (
            <FormSelect
              register={register}
              required
              errorMessage={t('Required')}
              error={errors.id}
              name="id"
              label={t('Group')}
              placeholder=""
            >
              <option value="00000000-0000-0000-0000-000000000000">
                {t('--- choose ---')}
              </option>
              {groups
                ? [...groups]
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((x) => (
                      <option key={x.id} value={x.id}>
                        {x.name}
                      </option>
                    ))
                : ''}
            </FormSelect>
          ) : mode === 'User' ? (
            <FormGroup label="User">
              <Search onChange={handleUserSearchChange}></Search>
            </FormGroup>
          ) : (
            ''
          )}
        </>
      )}
    </SlideoutLayout>
  );
}

AddMember.propTypes = {
  permissionKeys: PropTypes.array.isRequired,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddMember);
