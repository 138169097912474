import React, { useMemo } from 'react';
import Help from './Help';
const FormGroup = ({
  label,
  children,
  error,
  errorMessage,
  required,
  extraRowClasses,
  helpContent,
}) => {
  const classeNames = useMemo(
    () =>
      `pb-2 ${error ? 'has-error' : ''} ${required ? 'required' : ''} ${
        extraRowClasses ? extraRowClasses : ''
      }`,
    [error, required, extraRowClasses]
  );

  return label ? (
    <div className={classeNames}>
      <label className="col-form-label">{label}</label>
      <Help helpContent={helpContent} className={'ms-2'} />
      <div className="ps-0">
        {children}
        {error && (
          <span className="field-validation-error">{errorMessage}</span>
        )}
      </div>
    </div>
  ) : (
    <div className={classeNames}>
      <div className="ps-0">
        {children}
        {error && (
          <span className="field-validation-error">{errorMessage}</span>
        )}
      </div>
    </div>
  );
};
export default FormGroup;
