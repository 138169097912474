import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import KwPanel from '../../../common/KwPanel';

import { deleteWorkflowTag } from '../../../../actions/workflowActions';
import { toast } from 'react-toastify';
import Button from '../../../common/Button';
import ConfirmationHeader from '../../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function DeleteTag({ done, workflowId, tag, cancel }) {
  const { t } = useTranslation();
  const handleDelete = () => {
    deleteWorkflowTag(workflowId, tag)
      .then(() => {
        toast.success(t('Tag successfully deleted'));
        done();
      })
      .catch((ex) => {
        console.error(ex);
        toast.error(t('Failed to delete tag'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Delete confirmation')}
      cancel={cancel}
      done={done}
    >
      <KwPanel>
        <ConfirmationHeader text={t('Are you sure?')} />
        <Button
          className="btn btn-danger me-2"
          onClick={handleDelete}
          text={t('Yes, delete the tag')}
        />
        <Button
          className="btn btn-default"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
DeleteTag.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTag);
