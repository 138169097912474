import React from 'react';
import i18next from 'i18next';

import FormInput from '../../../common/FormInput';
import PropTypes from 'prop-types';
import FormToggleBool from '../../../common/FormToggleBool';
import FormToggleGroup from '../../../common/FormToggleGroup';
import FormTextArea from '../../../common/FormTextArea';
import FormColourPicker from '../../../common/FormColourPicker';
import workflowHelp from '../../../../constants/help/workflowHelp';
import FormButtonGroup from '../../../common/FormButtonGroup';
import { hasFeature } from '../../../auth/authUtils';
import { useTranslation } from 'react-i18next';
const WorkflowForm = ({
  workflow,
  register,
  errors,
  control,
  isAuroraWorkflow,
  setIsAuroraWorkflow,
  currentUser,
}) => {
  const language = i18next.language;
  const { t } = useTranslation();
  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <header className="mb-3">
            <h2>{t('Basic settings')}</h2>
          </header>
          <FormInput
            register={register}
            required
            error={errors.keyWorkflowName}
            errorMessage={t('Request type is required')}
            name="keyWorkflowName"
            label={t('Request type')}
            placeholder={t('The type of request this workflow is for')}
            defaultValue={workflow.keyWorkflowName || ''}
            autoFocus={true}
          />
          <FormTextArea
            register={register}
            name="description"
            label={t('Description')}
            placeholder={t(
              'Describe this workflow, this is visible to requesters...'
            )}
            defaultValue={workflow.description || ''}
            maxLength={300}
            rows={3}
          />
          <FormInput
            register={register}
            required
            name="maxKeys"
            label={t('Max keys per request')}
            placeholder={t(
              'Limit the maximum number of keys available to request at any one time (0 = unlimited)'
            )}
            defaultValue={workflow.maxKeys || 0}
          />
          <FormInput
            register={register}
            required
            name="maxValue"
            label={t('Max value of request')}
            placeholder={t(
              'Limit the maximum financial value of the request (0 = unlimited)'
            )}
            defaultValue={workflow.maxValue || 0}
          />
          <FormInput
            register={register}
            required
            name="maxKeysPerUser"
            label={t('Max keys per user')}
            placeholder={t(
              'Limit the maximum number of keys a user can request for any single SKU (0 = unlimited)'
            )}
            defaultValue={workflow.maxKeysPerUser || 0}
          />
          <FormInput
            register={register}
            name="keyProviderReference"
            label={t('Key provider reference')}
            placeholder={t('Optional')}
            defaultValue={workflow.keyProviderReference || ''}
          />
          <FormColourPicker
            control={control}
            name="colourHex"
            label={t('Colour')}
            extraRowClasses="align-items-start"
            defaultValue={workflow.colourHex || 'transparent'}
          />
          <FormToggleBool
            control={control}
            name="defaultRestricted"
            label={t('Default restricted')}
            defaultValue={workflow.defaultRestricted || false}
            helpContent={workflowHelp.DEFAULT_RESTRICTED[language]}
          />
          {!isAuroraWorkflow && (
            <FormToggleBool
              control={control}
              name="EnableKeyPools"
              label={t('Enable key pools')}
              defaultValue={workflow.enableKeyPools || false}
            />
          )}
        </div>
        <div className="col-sm-6">
          {hasFeature(currentUser, 'Aurora') && (
            <>
              <header className="mb-3 mt-5 mt-sm-0">
                <h2>{t('Workflow type')}</h2>
              </header>
              <div className="mb-5">
                <FormButtonGroup
                  name="isAuroraWorkflow"
                  label={t('What type of workflow is this?')}
                  buttons={[
                    { value: false, text: t('Standard') },
                    { value: true, text: t('Key on demand API (Aurora™)') },
                  ]}
                  onChange={(value) => setIsAuroraWorkflow(value.value)}
                  helpContent={workflowHelp.WHAT_IS_AURORA[language]}
                  defaultValue={workflow.isAuroraWorkflow || false}
                  value={isAuroraWorkflow}
                ></FormButtonGroup>
              </div>
            </>
          )}
          {!isAuroraWorkflow ? (
            <>
              <header className="mb-3 mt-5 mt-sm-0">
                <h2>{t('Request for information')}</h2>
              </header>
              <FormToggleBool
                control={control}
                name="requiresJustification"
                label={t('Workflow requires justification')}
                defaultValue={workflow.requiresJustification || false}
                helpContent={workflowHelp.REQUIRES_JUSTIFICATION[language]}
              />
              <FormToggleBool
                control={control}
                name="poNumberAvailable"
                label={t('Workflow includes optional PO number')}
                defaultValue={workflow.poNumberAvailable || false}
              />
              <FormToggleBool
                control={control}
                name="requiresTermsAgreement"
                label={t('Workflow requires terms agreement')}
                defaultValue={workflow.requiresTermsAgreement || false}
              />
              <FormToggleBool
                control={control}
                name="ShowKeyPoolLevelDuringRequest"
                label={t('Show the user the keypool balance during request')}
                defaultValue={workflow.showKeyPoolLevelDuringRequest || false}
              />
              <FormToggleBool
                control={control}
                name="EnableEndUserDetailsAgainstKeyRequest"
                label={t(
                  'Allow requester to upload end user account details against request'
                )}
                defaultValue={
                  workflow.enableEndUserDetailsAgainstKeyRequest || false
                }
              />
              <FormToggleBool
                control={control}
                name="RequestForSameSKUCreateNewRequestGroup"
                label={t(
                  'Requests for the same SKU create a new request group'
                )}
                defaultValue={
                  workflow.requestForSameSKUCreateNewRequestGroup || false
                }
              />
              <FormToggleBool
                control={control}
                name="ShowRequiredByDate"
                label={t(
                  "Show a date where the user can specify a 'required by date'"
                )}
                defaultValue={workflow.showRequiredByDate || false}
              />
              <FormToggleGroup
                control={control}
                name="FileUploadOnKeyRequest"
                label={t('Require file upload for purchase order')}
                defaultValue={workflow.fileUploadOnKeyRequest || 0}
                options={[
                  { id: 0, label: t('Not required') },
                  { id: 1, label: t('Optional') },
                  { id: 2, label: t('Mandatory') },
                ]}
              ></FormToggleGroup>
              <FormToggleBool
                control={control}
                name="AllowSameCompanyDownload"
                label={t(
                  "Allow users from the same company to download each other's keys for this workflow"
                )}
                defaultValue={workflow.allowSameCompanyDownload || false}
                helpContent={workflowHelp.ALLOW_SAME_COMPANY_DOWNLOAD[language]}
              />
              <FormToggleBool
                control={control}
                name="ExcludeRequestVisibleToAllUsers"
                label={t(
                  'Exclude these requests when "Request visible to all users" is on'
                )}
                defaultValue={workflow.excludeRequestVisibleToAllUsers || false}
                helpContent={
                  workflowHelp.EXCLUDE_REQUEST_VISIBLE_TO_ALL_USERS[language]
                }
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

WorkflowForm.propTypes = {
  workflow: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default WorkflowForm;
