import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import KwPanel from '../../common/KwPanel';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import PropTypes from 'prop-types';
import {
  saveRequest,
  clearSteamRequest,
} from '../../../actions/steamRequestActions';
import { getAllPackageTypes } from '../../../actions/packageTypeActions';
import { getAllWorkflows } from '../../../actions/workflowActions';
import { addToGroup, removeFromGroup } from '../../../actions/signalRActions';
import { steamRequestKeysUri } from '../../../util/steamUtils';
import { ParseInt } from '../../../util/formatter';
import Button from '../../common/Button';
import { useForm, useFieldArray } from 'react-hook-form';
import FormSelect from '../../common/FormSelect';
import { useTranslation } from 'react-i18next';
import FormDataList from '../../common/FormDataList';

function SteamBulkRequest({
  done,
  cancel,
  skus,
  gameName,
  addToGroup,
  removeFromGroup,
  steamRequest,
  clearSteamRequest,
  packageTypes,
  getAllPackageTypes,
  workflows,
  getAllWorkflows,
}) {
  const { t } = useTranslation();
  const { control, register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      items: skus.flatMap((sku) =>
        sku.keyPools.map((keyPool) => ({
          keyWorkflowId: keyPool.keyWorkflowId,
          keyWorkflowName: keyPool.keyWorkflowName,
          keyProviderReference: keyPool.keyProviderReference,
          numberOfKeys: keyPool.numberOfKeys,
          skuId: sku.id,
          gameName: sku.gameName,
          name: sku.name,
          packageTypeId: sku.packageTypeId,
        }))
      ),
    },
  });
  const { fields, update } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'items', // unique name for your Field Array
  });
  const watchFieldArray = watch('items');
  const [loading, setLoading] = useState(false);
  const [bulkKeys, setBulkKeys] = useState('');

  const [processing, setProcessing] = useState([]);
  const handleMakeRequest = (data) => {
    setLoading(true);
    setProcessing(data.items.filter((x) => x.numberOfKeys > 0));
  };
  const setStatus = (skuId, keyRequestId, keyWorkflowId, status, message) => {
    fields.forEach((item, index) => {
      if (
        item.skuId === skuId &&
        item.keyWorkflowId === keyWorkflowId &&
        item.keyRequestId === keyRequestId
      ) {
        update(index, {
          ...item,
          status,
          message,
        });
      }
    });
  };

  const [currentSteamRequest, setCurrentSteamRequest] = useState();
  let timer = useRef();

  useEffect(() => {
    const makeRequest = (
      keyWorkflowId,
      gameSkuId,
      keyRequestId,
      packageTypeId,
      keyProviderReference,
      numberOfKeys
    ) => {
      setStatus(gameSkuId, keyRequestId, keyWorkflowId, 'In progress');
      saveRequest({
        keyWorkflowId,
        gameSkuId,
        keyRequestId,
        packageTypeId,
        keyProviderReference,
        numberOfKeys,
      })
        .then((data) => {
          setCurrentSteamRequest({
            id: data.id,
            keyWorkflowId,
            gameSkuId,
            numberOfKeys,
            keyRequestId,
          });
          //uri schema
          window.location = steamRequestKeysUri(data.id);
          timer.current = setTimeout(() => {
            setStatus(
              gameSkuId,
              keyRequestId,
              keyWorkflowId,
              'Failed',
              t('Failed to launch Steam request within 30 seconds')
            );
            setProcessing(processing.filter((_, i) => i !== 0));
          }, 30000);
        })
        .catch((e) => {
          setStatus(
            gameSkuId,
            keyRequestId,
            keyWorkflowId,
            'Failed',
            e?.message ?? t('Failed to create Steam request')
          );
          setProcessing(processing.filter((_, i) => i !== 0));
        });
    };

    if (processing.length > 0) {
      makeRequest(
        processing[0].keyWorkflowId,
        processing[0].skuId,
        processing[0].keyRequestId,
        processing[0].packageTypeId,
        processing[0].keyProviderReference,
        processing[0].numberOfKeys
      );
    }
  }, [processing]);

  useEffect(() => {
    if (currentSteamRequest) {
      addToGroup(`steamRequest:${currentSteamRequest.id}`);
      return () => {
        removeFromGroup(`steamRequest:${currentSteamRequest.id}`);
        clearTimeout(timer.current);
      };
    }
  }, [currentSteamRequest, addToGroup, removeFromGroup]);

  useEffect(() => {
    if (
      steamRequest &&
      currentSteamRequest &&
      steamRequest.id === currentSteamRequest.id
    ) {
      clearSteamRequest();
      clearTimeout(timer.current);
      if (steamRequest.hasErrored) {
        setStatus(
          currentSteamRequest.gameSkuId,
          currentSteamRequest.keyRequestId,
          currentSteamRequest.keyWorkflowId,
          'Failed',
          steamRequest.errorMessage
        );
        setProcessing(processing.filter((_, i) => i !== 0));
      } else if (steamRequest.hasCompleted) {
        setStatus(
          currentSteamRequest.gameSkuId,
          currentSteamRequest.keyRequestId,
          currentSteamRequest.keyWorkflowId,
          'Success',
          t('Successfully created request on Steam')
        );
        setProcessing(processing.filter((_, i) => i !== 0));
      }
    }
  }, [
    currentSteamRequest,
    steamRequest,
    clearSteamRequest,
    done,
    setProcessing,
    processing,
  ]);

  useEffect(() => {
    if (!packageTypes) {
      getAllPackageTypes();
    }
  }, [packageTypes, getAllPackageTypes]);

  useEffect(() => {
    if (!workflows) {
      getAllWorkflows();
    }
  }, [workflows, getAllWorkflows]);

  const getTags = (keyWorkflowId) => {
    const keyWorkflow = workflows?.find((x) => x.id === keyWorkflowId);
    return keyWorkflow?.requesterTags?.map((x) => x.tag);
  };

  const handleSetBulkNumberOfKeys = (e) => {
    const numberOfKeys = ParseInt(e.target.value);
    setBulkKeys(numberOfKeys);
    fields.forEach((item, index) => {
      setValue(`items.${index}.numberOfKeys`, numberOfKeys);
    });
  };

  const StatusBadge = ({ status, message }) => {
    switch (status) {
      case 'In progress':
        return (
          <span className={`badge bg-info`} title={message}>
            {t(`{status}`, { status: status })}
          </span>
        );
      case 'Success':
        return (
          <span className={`badge bg-success`} title={message}>
            {t(`{status}`, { status: status })}
          </span>
        );
      case 'Failed':
        return (
          <span className={`badge bg-danger`} title={message}>
            {t(`{status}`, { status: status })}
          </span>
        );
      default:
        return '';
    }
  };

  return (
    <SlideoutLayout
      title={t('Request on Steam')}
      cancel={cancel}
      done={done}
      bar={
        !loading && (
          <Button
            className="btn btn-primary"
            onClick={handleSubmit(handleMakeRequest)}
            isDisabled={!watchFieldArray.some((x) => x.numberOfKeys)}
            text={t('Request on Steam')}
          />
        )
      }
    >
      <KwPanel className="mb-4 form-horizontal">
        <header>
          <h2>{gameName}</h2>
        </header>
        <div className="table-wrapper">
          <table className="table dataTable width-100">
            <thead>
              <tr>
                {!gameName && <th>{t('Game')}</th>}
                <th>{t('SKU')}</th>
                <th>{t('Key pool')}</th>
                <th>{t('Package type')}</th>
                <th>{t('Customer')}</th>
                <th className="text-right" style={{ width: '120px' }}>
                  {t('Number of Keys')}
                </th>
                {loading && <th>{t('Status')}</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bulk" colSpan={6}>
                  {!loading && (
                    <div className="flex justify-content-end">
                      <input
                        type="number"
                        className={`form-control input-sm num text-right no-navigate`}
                        style={{ width: '120px' }}
                        value={bulkKeys}
                        onChange={handleSetBulkNumberOfKeys}
                        placeholder={t('Bulk edit')}
                      ></input>
                    </div>
                  )}
                </td>
              </tr>
              {fields.map((item, index) => (
                <tr key={`${item.skuId}-${item.keyWorkflowId}`}>
                  {!gameName && <td>{item.gameName}</td>}
                  <td>{item.name}</td>
                  <td>{item.keyWorkflowName}</td>
                  <td>
                    <FormSelect
                      register={register}
                      name={`items.${index}.packageTypeId`}
                      defaultValue={item.packageTypeId || ''}
                      disabled={loading}
                    >
                      <option value="">{t('--- choose ---')}</option>
                      {packageTypes &&
                        [...packageTypes]
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map((x) => (
                            <option key={x.id} value={x.id}>
                              {x.name}
                            </option>
                          ))}
                    </FormSelect>
                  </td>
                  <td>
                    <FormDataList
                      register={register}
                      name={`items.${index}.keyProviderReference`}
                      defaultValue={item.keyProviderReference || ''}
                      disabled={loading}
                      items={getTags(item.keyWorkflowId)}
                    ></FormDataList>
                  </td>
                  <td>
                    <input
                      type="number"
                      className={`form-control input-sm num text-right no-navigate`}
                      defaultValue={item.numberOfKeys}
                      {...register(`items.${index}.numberOfKeys`, {
                        valueAsNumber: true,
                      })}
                      disabled={loading}
                      placeholder={0}
                      style={{ width: '120px' }}
                    />
                  </td>
                  {loading && (
                    <td>
                      <StatusBadge
                        status={item.status}
                        message={item.message}
                      ></StatusBadge>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </KwPanel>
    </SlideoutLayout>
  );
}

SteamBulkRequest.propTypes = {
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  skus: PropTypes.array,
  gameName: PropTypes.string,
  addToGroup: PropTypes.func.isRequired,
  removeFromGroup: PropTypes.func.isRequired,
  steamRequest: PropTypes.object,
  clearSteamRequest: PropTypes.func.isRequired,
  packageTypes: PropTypes.array,
  getAllPackageTypes: PropTypes.func.isRequired,
  workflows: PropTypes.array,
  getAllWorkflows: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    steamRequest: state.steamRequest,
    packageTypes: state.packageTypes,
    workflows: state.workflows,
  };
}

const mapDispatchToProps = {
  addToGroup,
  removeFromGroup,
  clearSteamRequest,
  getAllPackageTypes,
  getAllWorkflows,
};

export default connect(mapStateToProps, mapDispatchToProps)(SteamBulkRequest);
