import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import SlideoutLayout from '../../layout/slideout/SlideoutLayout';

import { saveGame, getGameById } from '../../../actions/gameActions';
import { getAllCompanies } from '../../../actions/companyActions';
import { toast } from 'react-toastify';
import GameForm from '../components/GameForm';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import permissionTypes from '../../../constants/permissionTypes';
import { getCompanyIdsForPermission } from '../../auth/authUtils';
import { useTranslation } from 'react-i18next';

function EditGame({
  done,
  id,
  cancel,
  saveGame,
  companies,
  getAllCompanies,
  currentUser,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  //companies
  useEffect(() => {
    if (!companies) {
      getAllCompanies().catch((e) => {
        toast.error(t('Failed to get companies'));
      });
    }
  }, [companies, getAllCompanies]);

  const onSubmit = (data) => {
    if (!game) {
      toast.error(t('Failed to save game'));
      return;
    }

    let gameRecord = {
      id: id,
      isArchived: game.isArchived,
      name: data.name,
      appId: data.appId,
      licenseExpires: data.licenseExpires,
      notesHTML: data.notesHTML,
    };
    if (data.publisherCompanyId !== '') {
      gameRecord = {
        ...gameRecord,
        publisherCompanyId: data.publisherCompanyId,
      };
    }
    if (data.developerCompanyId !== '') {
      gameRecord = {
        ...gameRecord,
        developerCompanyId: data.developerCompanyId,
      };
    }
    saveGame(gameRecord).then(() => {
      toast.success(t('Game saved'));
      done(gameRecord);
    });
  };

  const [game, setGame] = useState();

  useEffect(() => {
    if (id) {
      getGameById(id).then((d) => {
        setGame(d);
      });
    } else {
      setGame({ id: '' });
    }
  }, [id]);

  const [allowedPublishers, setAllowedPublishers] = useState();
  useEffect(() => {
    let pubs = getCompanyIdsForPermission(
      currentUser,
      permissionTypes.MANAGE_GAMES
    );
    setAllowedPublishers(pubs);
  }, [companies, currentUser]);

  return (
    <SlideoutLayout
      title={`${id ? t('Edit game') : t('Add game')}`}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      {companies && game && (
        <GameForm
          game={game}
          companies={companies}
          allowedPublishers={allowedPublishers}
          register={register}
          errors={errors}
          control={control}
          clearLicenseExpires={() => {
            setGame({ ...game, licenseExpires: null });
          }}
        ></GameForm>
      )}
    </SlideoutLayout>
  );
}
EditGame.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    company: state.company,
    companies: state.companies,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  saveGame,
  getAllCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGame);
