import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid';
import KwPanel from '../common/KwPanel';
import {
  cellRenderer,
  getColumnWidth,
  getRowHeight,
} from './components/Bulk/utils';
import { hasPermission } from '../../components/auth/authUtils';
import Search from './components/Bulk/Search';
import { toast } from 'react-toastify';
import permissionTypes from '../../constants/permissionTypes';
import { getRequestableCompanies } from '../../actions/companyActions';
import {
  getRequestableKeyWorkflows,
  getGamesForWorkflows,
} from '../../actions/workflowActions';
import { bulkSaveRequests } from '../../actions/requestActions';
import { CommaNumber_NoDecimal } from '../../util/formatter';
import Loading from '../common/Loading';
import SelectCompanyStep from './slideout/wizard/SelectCompanyStep';
import SelectKeyWorkflowStep from './slideout/wizard/SelectKeyWorkflowStep';
import { getKeyProviders } from '../../actions/keyProviderActions';
import { getPlatforms } from '../../actions/platformActions';
import { getAllTerritories } from '../../actions/territoryActions';
import Button from '../common/Button';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import icons from '../../constants/icons';

function Bulk({
  setClasses,
  currentUser,
  bulkSaveRequests,
  setCompleteRequestSlideoutOpen,
  keyProviders,
  getKeyProviders,
  platforms,
  getPlatforms,
  territories,
  getAllTerritories,
}) {
  const { t } = useTranslation();
  const [breadcrumb, setBreadcrumb] = useState();
  const [showRemove, setShowRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [companyId, setCompanyId] = useState();
  const [companies, setCompanies] = useState();
  const [company, setCompany] = useState();
  const requestOnBehalfOf = hasPermission(
    currentUser,
    permissionTypes.REQUEST_ON_BEHALF_OF
  );
  useEffect(() => {
    if (!companies && requestOnBehalfOf) {
      getRequestableCompanies()
        .then((_companies) => {
          setCompanies(_companies);
          if (_companies.length === 1) {
            setCompanyId(_companies[0].id);
          } else {
            setIsLoading(false);
          }
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to get list of companies'));
          setIsLoading(false);
        });
    } else if (companies && companies.length === 1) {
      setCompanyId(companies[0].id);
    } else if (!companies) {
      setCompanies([{ id: currentUser.companyId }]);
      setCompanyId(currentUser.companyId);
    }
  }, [companies, requestOnBehalfOf, currentUser, t]);

  useEffect(() => {
    if (companies && companyId && !company) {
      setCompany(companies.find((x) => x.id === companyId));
    }
  }, [companyId, companies, company]);
  const [keyWorkflowId, setKeyWorkflowId] = useState();
  const [keyWorkflow, setKeyWorkflow] = useState();
  const [keyWorkflows, setKeyWorkflows] = useState();

  //Get the list of key workflows
  useEffect(() => {
    if (companyId) {
      getRequestableKeyWorkflows(requestOnBehalfOf ? companyId : '')
        .then((workflows) => {
          setKeyWorkflows(workflows);
          if (workflows.length === 1) {
            setKeyWorkflowId(workflows[0].id);
          } else {
            setIsLoading(false);
          }
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to get list of workflows'));
          setIsLoading(false);
        });
    }
  }, [companyId, companies, requestOnBehalfOf, t]);

  useEffect(() => {
    if (keyWorkflows && keyWorkflowId && !keyWorkflow) {
      setKeyWorkflow(keyWorkflows.find((x) => x.id === keyWorkflowId));
    }
  }, [keyWorkflowId, keyWorkflows, keyWorkflow]);

  useEffect(() => {
    if (keyWorkflow && company) {
      setBreadcrumb([
        { label: t('Bulk requests'), link: '/requests/bulk' },
        { label: company.companyName },
        { label: keyWorkflow.name },
      ]);
    } else if (company && !keyWorkflow) {
      setBreadcrumb([
        { label: t('Bulk requests'), link: '/requests/bulk' },
        { label: company.companyName },
      ]);
    } else {
      setBreadcrumb([{ label: t('Bulk requests'), link: '/requests/bulk' }]);
    }
  }, [setBreadcrumb, keyWorkflow, company, t]);

  const [games, setGames] = useState([]);

  const toggleShowRemove = (id) => {
    setGames([...games.map((game) => ({ ...game, showRemove: !showRemove }))]);
    setShowRemove(!showRemove);
  };

  const rowRenderer = ({ style }) => {
    return <div style={style}></div>;
  };

  const handleRemove = ({ gameId, skuId }) => {
    if (skuId) {
      const updatedGames = [...games.filter((game) => game.skuId !== skuId)];
      setGames(updatedGames);
      if (
        updatedGames.filter((game) => game.gameId === gameId && game.isSKU)
          .length === 0
      ) {
        setGames([...games.filter((game) => game.gameId !== gameId)]);
      }
    } else {
      setGames([...games.filter((game) => game.gameId !== gameId)]);
    }
  };

  const [bulkNumberOfKeys, setBulkNumberOfKeys] = useState();
  const bulkNumberOfKeysOnBlur = () => {
    setBulkNumberOfKeys();
  };

  const bulkChangeNumberOfKeys = (e) => {
    setBulkNumberOfKeys(e.target.value);
    setGames([
      ...games.map((game) =>
        game.isSKU && filteredGames.some((x) => x.skuId === game.skuId)
          ? { ...game, numberOfKeys: e.target.value }
          : game
      ),
    ]);
  };

  const changeNumberOfKeys = (numberOfKeys, game) => {
    game.skuId
      ? setGames([
          ...games.map((x) =>
            x.skuId === game.skuId ? { ...x, numberOfKeys } : x
          ),
        ])
      : setGames([
          ...games.map((x) =>
            x.gameId === game.gameId &&
            filteredGames.some((g) => g.skuId === x.skuId)
              ? { ...x, numberOfKeys }
              : x
          ),
        ]);
  };

  const gameNumberOfKeysOnBlur = (game) => {
    setGames([
      ...games.map((x) =>
        x.gameId === game.gameId && !x.isSKU ? { ...x, numberOfKeys: '' } : x
      ),
    ]);
  };
  const [bulkTag, setBulkTag] = useState();
  const bulkTagOnBlur = () => {
    setBulkTag();
  };
  const bulkChangeTag = (e) => {
    setBulkTag(e.target.value);
    setGames([
      ...games.map((game) =>
        game.isSKU && filteredGames.some((x) => x.skuId === game.skuId)
          ? { ...game, tag: e.target.value }
          : game
      ),
    ]);
  };
  const changeTag = (tag, game) => {
    game.skuId
      ? setGames([
          ...games.map((x) => (x.skuId === game.skuId ? { ...x, tag } : x)),
        ])
      : setGames([
          ...games.map((x) =>
            x.gameId === game.gameId &&
            filteredGames.some((g) => g.skuId === x.skuId)
              ? { ...x, tag }
              : x
          ),
        ]);
  };
  const gameTagOnBlur = (game) => {
    setGames([
      ...games.map((x) =>
        x.gameId === game.gameId && !x.isSKU ? { ...x, tag: '' } : x
      ),
    ]);
  };

  useEffect(() => {
    setClasses('flex-grow-1');
  }, [setClasses]);

  const [availableGames, setAvailableGames] = useState();
  useEffect(() => {
    if (!availableGames && keyWorkflowId) {
      setIsLoading(true);
      getGamesForWorkflows(keyWorkflowId, '')
        .then((games) => {
          const filteredGames = games.filter((x) => x.skUs.length > 0);
          setAvailableGames(filteredGames);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to get list of games'));
        });
    }
  }, [availableGames, keyWorkflowId, t]);

  const mapGame = (game) => ({
    gameId: game.id,
    name: game.name,
    isSKU: false,
    packshotUrl: game.packshotUrl,
    appId: game.appId,
    skuNames: game.skUs?.map((sku) => sku.name).flat(),
    packageIds: game.skUs?.map((sku) => sku.packageId).flat(),
    keyProviders: game.skUs?.map((sku) => sku.keyProviderId).flat(),
    platforms: game.skUs?.map((sku) => sku.platformId).flat(),
    territories: game.skUs
      ?.map((sku) =>
        sku.territories?.map((x) => territories?.find((y) => y.id === x))
      )
      .flat(),
  });

  const mapSKU = (sku, game) => ({
    skuId: sku.skuId,
    name: sku.name,
    isSKU: true,
    gameId: game.id,
    gameName: game.name,
    appId: game.appId,
    packageId: sku.packageId,
    packageType: sku.packageType,
    platformId: sku.platformId,
    platform: sku.platform,
    platformImageId: sku.platformImageId,
    platformColourHex: sku.platformColourHex,
    keyProviderId: sku.keyProviderId,
    keyProviderName: sku.keyProviderName,
    keyProviderImageId: sku.keyProviderImageId,
    keyProviderColourHex: sku.keyProviderColourHex,
    territoriesRestricted: sku.territoriesRestricted,
    territories: sku.territories?.map((x) =>
      territories?.find((y) => y.id === x)
    ),
  });
  const handleAddGame = (selected) => {
    const game = selected[0];
    if (!games.some((x) => x.gameId === game.id)) {
      setGames([
        ...games,
        mapGame(game),
        ...game.skUs.map((sku) => mapSKU(sku, game)),
      ]);
    } else {
      const missingSKUs = game.skUs.filter(
        (sku) => !games.map((x) => x.skuId).includes(sku.skuId)
      );
      if (missingSKUs.length > 0) {
        // Add the missing SKUs
        let updatedGames = [];
        for (const sku of missingSKUs) {
          for (const existingGame of games) {
            if (existingGame.gameId !== game.id || !existingGame.isSKU) {
              updatedGames.push(existingGame);
            } else if (sku.name.localeCompare(existingGame.name) === 1) {
              updatedGames.push(mapSKU(sku, game));
              updatedGames.push(existingGame);
            } else {
              updatedGames.push(existingGame);
              updatedGames.push(mapSKU(sku, game));
            }
          }
        }
        setGames(updatedGames);
      }
    }
  };

  const addBackCataglogue = () => {
    if (!availableGames) {
      toast.error(t('There are no games available'));
      return;
    }

    const gamesToAdd = availableGames.filter(
      (game) => !games.some((x) => x.gameId === game.id)
    );
    setGames([
      ...games,
      ...gamesToAdd
        .map((game) => [
          mapGame(game),
          ...game.skUs.map((sku) => mapSKU(sku, game)),
        ])
        .flat(),
    ]);
  };

  const [filterGame, setFilterGame] = useState();
  const handleFilterGame = (e) => {
    setFilterGame(e.target.value);
  };

  const [filterPackageType, setFilterPackageType] = useState();
  const handleFilterPackageType = (e) => {
    setFilterPackageType(e.target.value);
  };

  const [filterAppPackageId, setFilterAppPackageId] = useState();
  const handleFilterAppPackageId = (e) => {
    setFilterAppPackageId(e.target.value);
  };

  const [filterKeyProvider, setFilterKeyProvider] = useState();
  const handleFilterKeyProvider = (e) => {
    setFilterKeyProvider(e.target.value);
  };

  const [filterPlatform, setFilterPlatform] = useState();
  const handleFilterPlatform = (e) => {
    setFilterPlatform(e.target.value);
  };

  const [filterTerritory, setFilterTerritory] = useState();
  const handleFilterTerritory = (e) => {
    setFilterTerritory(e.target.value);
  };

  const [filteredGames, setFilteredGames] = useState([]);

  useEffect(() => {
    if (games) {
      const loweredGameName = filterGame?.toLowerCase() ?? '';
      const loweredPackageAppType = filterPackageType?.toLowerCase() ?? '';
      const loweredPackageAppId = filterAppPackageId?.toLowerCase() ?? '';

      const matchSKUGameName = (game) =>
        game.gameName.toLowerCase().indexOf(loweredGameName) !== -1 ||
        game.name.toLowerCase().indexOf(loweredGameName) !== -1;

      const matchSKUAppPackageType = (game) =>
        game.packageType?.toLowerCase().indexOf(loweredPackageAppType) !== -1;

      const matchSKUAppPackageId = (game) =>
        game.packageId?.toLowerCase().indexOf(loweredPackageAppId) !== -1 ||
        game.appId?.toLowerCase().indexOf(loweredPackageAppId) !== -1;

      const matchSKUKeyProvider = (game) =>
        !filterKeyProvider || game.keyProviderId === filterKeyProvider;

      const matchSKUPlatform = (game) =>
        !filterPlatform || game.platformId === filterPlatform;

      const matchSKUTerritoryId = (game) =>
        !filterTerritory ||
        game.territories?.some((x) => x?.id === filterTerritory);

      const matchGameName = (game) =>
        game.name.toLowerCase().indexOf(loweredGameName) !== -1 ||
        game.skuNames?.some(
          (skuName) => skuName?.toLowerCase().indexOf(loweredGameName) !== -1
        );
      const matchGameAppId = (game) =>
        game.packageIds?.some(
          (packageId) =>
            packageId?.toLowerCase().indexOf(loweredPackageAppId) !== -1
        ) || game.appId?.toLowerCase().indexOf(loweredPackageAppId) !== -1;

      const matchGameKeyProvider = (game) =>
        !filterKeyProvider ||
        game.keyProviders?.some(
          (keyProvider) => keyProvider === filterKeyProvider
        );
      const matchGamePlatform = (game) =>
        !filterPlatform ||
        game.platforms?.some((platform) => platform === filterPlatform);

      const matchGameTerritoryId = (game) =>
        !filterTerritory ||
        game.territories?.some((x) => x?.id === filterTerritory);

      setFilteredGames([
        ...games.filter(
          (game) =>
            (game.isSKU &&
              matchSKUGameName(game) &&
              matchSKUTerritoryId(game) &&
              matchSKUAppPackageType(game) &&
              matchSKUAppPackageId(game) &&
              matchSKUKeyProvider(game) &&
              matchSKUPlatform(game)) ||
            (!game.isSKU &&
              matchGameName(game) &&
              matchGameTerritoryId(game) &&
              matchGameAppId(game) &&
              matchGameKeyProvider(game) &&
              matchGamePlatform(game))
        ),
      ]);
    }
  }, [
    filterGame,
    games,
    filterPackageType,
    filterAppPackageId,
    filterKeyProvider,
    filterPlatform,
    filterTerritory,
  ]);

  // For displaying the total count of SKUs
  const totalSKUsCount = games.filter((x) => x.isSKU).length;

  // For displaying the total count of games that are not SKUs
  const totalNonSKUGamesCount = games.filter((x) => !x.isSKU).length;

  // For displaying the count of games that are not SKUs and have associated SKUs with keys
  const nonSKUGamesWithKeysCount = games.filter(
    (x) =>
      !x.isSKU &&
      games.filter(
        (sku) =>
          sku.isSKU && sku.gameId === x.gameId && parseInt(sku.numberOfKeys) > 0
      ).length > 0
  ).length;

  // For displaying the count of SKUs with more than 0 keys
  const skusWithKeysCount = games.filter(
    (x) => x.isSKU && parseInt(x.numberOfKeys) > 0
  ).length;

  // For displaying the total number of keys across all SKUs
  const totalKeysCount = CommaNumber_NoDecimal(
    games
      .filter((x) => x.skuId && x.numberOfKeys)
      .map((x) => x.numberOfKeys)
      .reduce((a, b) => parseInt(a) + parseInt(b), 0)
  );

  const handleComplete = async () => {
    const skusWithKeys = games
      .filter((x) => x.isSKU && parseInt(x.numberOfKeys) > 0)
      .map((sku) => ({
        gameSKUId: sku.skuId,
        requesterTagId: keyWorkflow?.tagsAsFreeText ? null : sku.tag,
        requesterTag: keyWorkflow?.tagsAsFreeText ? sku.tag : null,
        numberOfKeys: parseInt(sku.numberOfKeys),
      }));
    setIsLoading(true);
    bulkSaveRequests({
      companyId,
      keyWorkflowId,
      items: skusWithKeys,
    })
      .then(() => {
        toast.success(t('Request added to basket'));
        setCompleteRequestSlideoutOpen(true);
        setGames([]);
      })
      .catch((e) =>
        toast.error(e.message ? e.message : t('Failed to add request'))
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders().catch(() => {});
    }
  }, [keyProviders, getKeyProviders]);

  useEffect(() => {
    if (!platforms) {
      getPlatforms().catch(() => {});
    }
  }, [platforms, getPlatforms]);

  useEffect(() => {
    if (!territories) {
      getAllTerritories().catch(() => {});
    }
  }, [territories, getAllTerritories]);

  return !isLoading ? (
    <>
      <ActionBar breadcrumb={breadcrumb}>
        {games && games.length > 0 && (
          <Button
            className={`btn ${
              showRemove ? 'btn-primary' : 'btn-default btn-outline'
            } me-2`}
            onClick={() => toggleShowRemove()}
            text={showRemove ? t('Continue') : t('Remove items')}
          />
        )}
      </ActionBar>
      {!companyId && (
        <SelectCompanyStep
          stepName="company"
          currentUser={currentUser}
          companies={companies}
          handleCompanySelect={(value) => setCompanyId(value)}
        ></SelectCompanyStep>
      )}

      {companyId && !keyWorkflowId && (
        <SelectKeyWorkflowStep
          stepName="keyWorkflow"
          keyWorkflows={keyWorkflows}
          handleKeyWorkflowSelect={(value) => setKeyWorkflowId(value)}
        ></SelectKeyWorkflowStep>
      )}

      {companyId && keyWorkflowId && (
        <>
          <KwPanel className="mb-4">
            <div className="d-flex">
              <div className="d-flex flex-column flex-grow-1 pe-4">
                <span className="mb-2">{t('Add a game')}</span>
                <div className="search-wrapper in-panel">
                  <Search
                    games={availableGames}
                    onSelect={handleAddGame}
                    disabled={showRemove}
                  ></Search>

                  <div className="search-icon">
                    <FontAwesomeIcon icon={icons.SEARCH} />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column border-left ps-4">
                <span className="mb-2">{t('...or')}</span>
                <div>
                  <Button
                    className="btn btn-default px-50"
                    isDisabled={showRemove}
                    onClick={addBackCataglogue}
                    text={t('Add all games')}
                  />
                </div>
              </div>
            </div>
          </KwPanel>
          {games.length > 0 && (
            <>
              <div className="grid-wrapper mb-4 bulk-requests">
                <AutoSizer>
                  {({ width, height }) => (
                    <MultiGrid
                      fixedRowCount={1}
                      rowRenderer={rowRenderer}
                      cellRenderer={({
                        columnIndex,
                        key,
                        parent,
                        rowIndex,
                        style,
                      }) =>
                        cellRenderer({
                          t,
                          columnIndex,
                          rowIndex,
                          games: filteredGames,
                          style,
                          key,
                          handleRemove,
                          bulkChangeNumberOfKeys,
                          bulkNumberOfKeys,
                          bulkNumberOfKeysOnBlur,
                          gameNumberOfKeysOnBlur,
                          changeNumberOfKeys,
                          bulkChangeTag,
                          bulkTag,
                          bulkTagOnBlur,
                          changeTag,
                          gameTagOnBlur,
                          keyWorkflow,
                          filterGame,
                          handleFilterGame,
                          filterPackageType,
                          handleFilterPackageType,
                          filterAppPackageId,
                          handleFilterAppPackageId,
                          filterKeyProvider,
                          handleFilterKeyProvider,
                          filterPlatform,
                          handleFilterPlatform,
                          platforms,
                          filterTerritory,
                          handleFilterTerritory,
                          territories,
                          keyProviders,
                        })
                      }
                      columnWidth={({ index }) =>
                        getColumnWidth({ index, keyWorkflow })
                      }
                      columnCount={8}
                      enableFixedColumnScroll
                      enableFixedRowScroll
                      fixedColumnCount={1}
                      height={height}
                      rowHeight={getRowHeight}
                      rowCount={filteredGames.length + 1}
                      width={width}
                      hideTopRightGridScrollbar
                      hideBottomLeftGridScrollbar
                    />
                  )}
                </AutoSizer>
              </div>

              <KwPanel className="d-flex mb-4 justify-content-between align-items-center">
                <div className="totals d-flex flex-grow-1">
                  <div className="d-flex flex-column  border-right flex-basis-584">
                    <span className="me-5">
                      <span className="d-inline-block w-60">{t('Games:')}</span>
                      <span>
                        {t(
                          '{nonSKUGamesWithKeysCount} of {totalNonSKUGamesCount}',
                          {
                            nonSKUGamesWithKeysCount: nonSKUGamesWithKeysCount,
                            totalNonSKUGamesCount: totalNonSKUGamesCount,
                          }
                        )}
                      </span>
                    </span>
                    <span className="me-5">
                      <span className="d-inline-block w-60">{t('Skus:')}</span>
                      <span>
                        {t('{skusWithKeysCount} of {totalSKUsCount}', {
                          skusWithKeysCount: skusWithKeysCount,
                          totalSKUsCount: totalSKUsCount,
                        })}
                      </span>
                    </span>
                  </div>
                  <div className="ms-4 d-flex flex-column flex-grow-1 border-right">
                    <span>
                      <span className="d-inline-block w-60">{t('Keys:')}</span>
                      <span className="me-5">
                        {t('{totalKeysCount}', {
                          totalKeysCount: totalKeysCount,
                        })}
                      </span>
                    </span>
                    <span style={{ display: 'none' }}>
                      <span className="d-inline-block w-60">{t('Value:')}</span>
                      <span>0</span>
                    </span>
                  </div>
                </div>
                <Button
                  className="btn btn-primary btn-large ms-4"
                  isDisabled={skusWithKeysCount === 0 || showRemove}
                  onClick={handleComplete}
                  text={t('Complete')}
                />
              </KwPanel>
            </>
          )}
        </>
      )}
    </>
  ) : (
    <Loading></Loading>
  );
}
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    keyProviders: state.keyProviders,
    platforms: state.platforms,
    territories: state.territories,
  };
}

const mapDispatchToProps = {
  bulkSaveRequests,
  getKeyProviders,
  getPlatforms,
  getAllTerritories,
};

Bulk.propTypes = {
  setClasses: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  bulkSaveRequests: PropTypes.func.isRequired,
  setCompleteRequestSlideoutOpen: PropTypes.func.isRequired,
  keyProviders: PropTypes.array,
  getKeyProviders: PropTypes.func.isRequired,
  platforms: PropTypes.array,
  getPlatforms: PropTypes.func.isRequired,
  territories: PropTypes.array,
  getAllTerritories: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Bulk);
