import React from 'react';
import FormInput from '../../../common/FormInput';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const KeyProviderPromotionSettingForm = ({
  keyProviderPromotionSetting,
  register,
  errors,
}) => {
  const { t } = useTranslation();
  return keyProviderPromotionSetting ? (
    <>
      <FormInput
        register={register}
        error={errors.minimumPromoDays}
        name="minimumPromoDays"
        label={t('Minimum promotion (days)')}
        defaultValue={keyProviderPromotionSetting.minimumPromoDays || 0}
      />
      <FormInput
        register={register}
        error={errors.maximumPromoDays}
        name="maximumPromoDays"
        label={t('Maximum promotion (days)')}
        defaultValue={keyProviderPromotionSetting.maximumPromoDays || 0}
      />
      <FormInput
        register={register}
        error={errors.cooldownPeriodDays}
        name="cooldownPeriodDays"
        label={t('Cooldown period (days)')}
        defaultValue={keyProviderPromotionSetting.cooldownPeriodDays || 0}
      />
    </>
  ) : (
    ''
  );
};

KeyProviderPromotionSettingForm.propTypes = {
  keyProviderPromotionSetting: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default KeyProviderPromotionSettingForm;
