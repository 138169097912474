import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import permissionTypes from '../../../constants/permissionTypes';
import LinkBadge from './LinkBadge';
import PropTypes from 'prop-types';
import { getAllTerritories } from '../../../actions/territoryActions';
import { useTranslation } from 'react-i18next';

function TerritoryLink({ id, name, colour, territories, getAllTerritories }) {
  let [localT, setLocalT] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (!territories) {
      getAllTerritories().catch(() => {});
    }
    setLocalT(territories?.find((x) => x.id === id));
  }, [id, territories, getAllTerritories]);

  return (
    <LinkBadge
      url={`/settings/territory/${id}`}
      name={name}
      noLinkTitle={`${localT?.description || ''}`}
      title={`${localT?.description || ''}\n` + t('(View territory)')}
      colour={colour}
      permissionType={permissionTypes.MANAGE_TERRITORIES}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    territories: state.territories,
  };
}

const mapDispatchToProps = {
  getAllTerritories: () => getAllTerritories(),
};

TerritoryLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  colour: PropTypes.string,
  territories: PropTypes.array,
  getAllTerritories: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryLink);
