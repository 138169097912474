import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ActionBar from '../../layout/ActionBar';
import AnnouncementForm from './components/AnnouncementForm';
import Delete from './slideout/Delete';
import {
  getAnnouncementById,
  saveAnnouncement,
} from '../../../actions/announcementActions';
import Loading from '../../common/Loading';
import SlidingPane from 'react-sliding-pane';
import { useTranslation } from 'react-i18next';

function Announcement({ match, history }) {
  const { t } = useTranslation();
  const id = match.params.id;
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = (data) => {
    saveAnnouncement({ ...announcement, ...data })
      .then(() => {
        setAnnouncement({ ...announcement, ...data });
        toast.success(t('Announcement saved'));
      })
      .catch((ex) => {
        toast.error(ex.message ?? t('Failed to save announcement'));
      });
  };

  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);
  const handleDelete = (e) => {
    e.cancelBubble = true;
    setDeleteSlideoutOpen(true);
    return false;
  };

  const [announcement, setAnnouncement] = useState();

  useEffect(() => {
    if (id) {
      getAnnouncementById(id)
        .then((d) => {
          setAnnouncement(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/settings/announcements/');
          toast.error(t('Announcement not found'));
        });
    }
  }, [id, history, t]);

  return announcement ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/settings/announcements', label: t('Announcements') },
          { label: announcement.message },
        ]}
      >
        <input
          type="button"
          value={t('Delete')}
          className="btn btn-danger me-2"
          onClick={handleDelete}
        />
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      </ActionBar>
      <AnnouncementForm
        announcement={announcement}
        register={register}
        errors={errors}
        control={control}
      ></AnnouncementForm>

      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          done={(d) => {
            setDeleteSlideoutOpen(false);
            history.push('/settings/announcements/');
          }}
          cancel={() => setDeleteSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

export default Announcement;
