import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { toast } from 'react-toastify';
import FormSelect from '../../../common/FormSelect';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';

function AddGroupMembership({ allGroups, done, cancel }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    if (data.id) {
      done(data.id);
      return;
    }
    toast.error(t('Please required fields'));
  };

  return (
    <SlideoutLayout
      title={t('Add membership')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="submit"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <FormSelect
        autoFocus
        register={register}
        required={true}
        errorMessage={t('Required')}
        error={errors.id}
        name="id"
        label={t('Group')}
        placeholder=""
      >
        <option value="00000000-0000-0000-0000-000000000000">
          {t('--- choose ---')}
        </option>
        {allGroups
          ? [...allGroups]
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))
          : ''}
      </FormSelect>
    </SlideoutLayout>
  );
}

AddGroupMembership.propTypes = {
  userProfileId: PropTypes.string,
  allGroups: PropTypes.array,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupMembership);
