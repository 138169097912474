import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  loadRequestsMadeByMe,
  exportMyRequestData,
  loadRequests,
} from '../../../actions/requestActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';

import { CommaNumber_NoDecimal, Date_AsString } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import RequestListButtons from './RequestListButtons';
import { RequestStatusLabel } from './RequestStatusLabel';
import useTableSettings from '../../../hooks/useTableSettings';
import GameLink from '../../common/links/GameLink';
import UserLink from '../../common/links/UserLink';
import WorkflowLink from '../../common/links/WorkflowLink';
import requestStatusNames from '../../../constants/requestStatusNames';
import PlatformLink from '../../common/links/PlatformLink';
import { useTranslation } from 'react-i18next';

function RequestsMadeByMeList({
  location,
  reload,
  handleDownloadKeys,
  isProcessing,
  currentUser,
  settingsKey,
  defaultPageSize,
  title,
  noDataComponent,
  tableViewSwitcher,
  allRequests,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'reference',
    'games',
    'platform',
    'numberOfKeys',
    'keyWorkflowName',
    'requestedByFullName',
    'requestedDate',
    'currentUserProfileGroupName',
    'requestStatus',
  ];
  const suffixColumns = ['id'];
  const defaultVisibleColumns = allColumns;

  const [requests, setRequests] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      settingsKey || 'requestsmadebymelist-2.13-settings',
      getTableSettingsDefaults({
        defaultSort: 'reference',
        direction: 'desc',
        defaultVisibleColumns,
      }),
      null,
      allRequests ? loadRequests : loadRequestsMadeByMe,
      setRequests,
      reload,
      t('Failed to load requests'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'reference',
      Label: '#',
      width: '5%',
      className: 'no-wrap',
      Render: (row) => (
        <Link to={'/request/' + row.requestGroupId}>{row.reference}</Link>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'games',
      Label: t('Games'),
      width: '20%',
      Render: (row) => (
        <GameLink
          gameId={row.gameId}
          skuId={row.skuId}
          gameTitle={row.gameTitle}
          skuName={row.skuName}
          currentUser={currentUser}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'platform',
      Label: t('Platform'),
      width: '20%',
      Render: (row) => (
        <PlatformLink
          id={row.platformId}
          name={row.platform}
          key={`${row.id},${row.platformId}`}
          currentUser={currentUser}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'numberOfKeys',
      Label: t('Keys'),
      width: '10%',
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.numberOfKeys),
    },
    {
      Key: 'keyWorkflowName',
      Label: t('Type'),
      width: '20%',
      Render: (row) => (
        <WorkflowLink
          id={row.keyWorkflowId}
          key={row.keyWorkflowId}
          name={row.keyWorkflowName}
          colour={row.keyWorkflowColourHex}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedByFullName',
      Label: t('Requested by'),
      width: '10%',
      Render: (row) => (
        <>
          <UserLink id={row.requestedById} name={row.requestedByFullName} />
          <br />
          {row.requestedByCompanyName}
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'currentUserProfileGroupName',
      Label: t('Waiting for'),
      width: '10%',
      Render: (row) => row.currentUserProfileGroupName,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestStatus',
      Label: t('Status'),
      width: '10%',
      Render: (row) => (
        <RequestStatusLabel
          requestStatus={row.requestStatus}
          isWaitingForKeys={row.isWaitingForKeys}
          isDownloaded={row.downloaded}
        />
      ),
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">All</option>
            {requestStatusNames.map((x) => (
              <option key={x} value={x}>
                {t(x)}
              </option>
            ))}
          </select>
        ),
      },
    },
    {
      Key: 'requestedDate',
      Label: t('Request date'),
      width: '5%',
      Render: (row) => Date_AsString(row.requestedDate),
    },
    {
      Key: 'id',
      width: '5%',
      Label: '',
      className: 'text-right',
      Sort: false,
      Render: (row) => (
        <RequestListButtons
          row={row}
          handleDownloadKeys={handleDownloadKeys}
          isProcessing={isProcessing}
          currentUser={currentUser}
        />
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return requests?.data ? (
    <KwPanel className="mb-4">
      {title && (
        <header className="mb-2">
          <h2>{title}</h2>
        </header>
      )}
      <Table
        columns={columns}
        suffixColumns={suffixColumnsWithConfig}
        allColumns={allColumns}
        rows={requests.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={requests.totalRowCount}
        filteredRowCount={requests.filteredRowCount}
        scopes={requests.scopes}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        className="td-align-top"
        noDataComponent={noDataComponent}
        tableActionBarButtons={tableViewSwitcher}
        exportData={exportMyRequestData}
        location={location}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    handleDownloadKeys: ownProps.handleDownloadKeys,
    currentUser: state.auth,
    isProcessing: ownProps.isProcessing,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsMadeByMeList);
