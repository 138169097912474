import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';

import { configureSubscriptions } from '../util/eventSubscriptions';
import actionTypes from '../constants/actionTypes';
const startSignalRConnection = (connection) =>
  connection
    .start()
    .then(() => console.info('SignalR Connected'))
    .catch((err) => console.error('SignalR Connection Error: ', err));

const signalRMiddleware = ({ dispatch, getState }) => {
  let connection;
  return (next) => async (action) => {
    switch (action.type) {
      case actionTypes.USER_SIGNED_IN:
        const connectionHub =
          window.globalConfig?.hubUrl || process.env.REACT_APP_HUB_URL;

        const protocol = new JsonHubProtocol();
        // let transport to fall back to to LongPolling if it needs to
        const transport =
          HttpTransportType.WebSockets | HttpTransportType.LongPolling;
        const options = {
          transport,
          logMessageContent: true,
          logger: LogLevel.Critical,
          accessTokenFactory: () => action.user.access_token,
        };

        // create the connection instance
        connection = new HubConnectionBuilder()
          .withUrl(`${connectionHub}/hub/notificationhub`, options)
          .withHubProtocol(protocol)
          .build();

        configureSubscriptions(connection, dispatch);

        // re-establish the connection if connection dropped
        connection.onclose(() =>
          setTimeout(startSignalRConnection(connection), 5000)
        );

        startSignalRConnection(connection);
        break;
      case actionTypes.ADD_TO_GROUP:
        connection.state === 'Connected' &&
          connection.invoke('AddToGroupAsync', action.groupName);
        break;
      case actionTypes.REMOVE_FROM_GROUP:
        connection.state === 'Connected' &&
          connection.invoke('RemoveFromGroupAsync', action.groupName);
        break;
      case actionTypes.GET_CONNECTED_USERS:
        if (connection && connection.state === 'Connected') {
          connection
            .invoke('GetConnectedUsers')
            .then(function (users) {
              dispatch({ type: actionTypes.LOAD_CONNECTED_USERS, users });
            })
            .catch(() => {});
        }
        break;
      case actionTypes.PAGE_ACTIVE:
        connection?.state === 'Connected' && connection.invoke('PageActive');
        break;
      case actionTypes.PAGE_INACTIVE:
        connection?.state === 'Connected' && connection.invoke('PageInactive');
        break;
      default:
        break;
    }
    return next(action);
  };
};
export default signalRMiddleware;
