import React from 'react';
import LeftNavWrapper from '../menu-components/LeftNavWrapper';
import LeftMenuItem from '../menu-components/LeftMenuItem';
import permissionTypes from '../../../constants/permissionTypes';
import { hasAnyPermission } from '../../auth/authUtils';
import icons from '../../../constants/icons';
import NavColours from '../../../constants/NavColours';
import { useTranslation } from 'react-i18next';

function LeftMenu_Home({ currentUser, wrapperClassName }) {
  const { t } = useTranslation();

  return (
    <LeftNavWrapper className={wrapperClassName}>
      <LeftMenuItem
        url="/keys/search"
        icon={icons.SEARCH}
        colour={NavColours.NAV_COLOUR_1}
        activeRegex="^\/keys\/search$"
        title={t('Search keys')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.KEYS_SEARCH]}
      />
      {hasAnyPermission(currentUser, [permissionTypes.KEYS_SEARCH]) && <hr />}
      <LeftMenuItem
        url="/keys/accounts"
        icon={icons.USER}
        colour={NavColours.NAV_COLOUR_2}
        activeRegex="^\/keys\/account"
        title={t('Accounts')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.KEYS_SEARCH]}
      />
      {hasAnyPermission(currentUser, [permissionTypes.KEYS_SEARCH]) && <hr />}
      <LeftMenuItem
        url="/keys/game"
        activeRegex="^\/keys/game"
        icon={icons.GAME}
        colour={NavColours.NAV_COLOUR_3}
        title={t('Game keys')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_KEYS]}
      />
      <LeftMenuItem
        url="/keys/pools"
        activeRegex="^\/keys/pools"
        icon={icons.KEY_POOL}
        colour={NavColours.NAV_COLOUR_3}
        title={t('Key pools')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_KEYS]}
      />
      {hasAnyPermission(currentUser, [permissionTypes.MANAGE_KEYS]) && <hr />}
      <LeftMenuItem
        url="/keys/ingested-batches"
        activeRegex="^\/keys/ingested-batches"
        icon={icons.BATCHES}
        colour={NavColours.NAV_COLOUR_4}
        title={t('Ingested batches')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_KEYS]}
      />
      <LeftMenuItem
        url="/keys/bulk-ingest"
        activeRegex="^\/keys/bulk-ingest"
        icon={icons.BATCHES}
        colour={NavColours.NAV_COLOUR_4}
        title={t('Bulk ingest')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_KEYS]}
      />
      {hasAnyPermission(currentUser, [permissionTypes.MANAGE_KEYS]) && <hr />}
      <LeftMenuItem
        url="/keys/reported-sold"
        activeRegex="^\/keys/reported-sold"
        icon={icons.KEY_SOLD}
        colour={NavColours.NAV_COLOUR_5}
        title={t('Reported sold')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_KEYS]}
      />
      <LeftMenuItem
        url="/keys/reported-activated"
        activeRegex="^\/keys/reported-activated"
        icon={icons.KEY_ACTIVATED}
        colour={NavColours.NAV_COLOUR_5}
        title={t('Reported activated')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_KEYS]}
      />
      <hr />
      <LeftMenuItem
        url="/keys/moved"
        activeRegex="^\/keys/moved"
        icon={icons.KEY_MOVED}
        colour={NavColours.NAV_COLOUR_6}
        title={t('Moved')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_KEYS]}
      />
      <LeftMenuItem
        url="/keys/reported-disabled"
        activeRegex="^\/keys/reported-disabled"
        icon={icons.KEY_DISABLED}
        colour={NavColours.NAV_COLOUR_6}
        title={t('Disabled')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.MANAGE_KEYS]}
      />
    </LeftNavWrapper>
  );
}

export default LeftMenu_Home;
