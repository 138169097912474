import { useState, useEffect } from 'react';
import { getRequestableCompanies } from '../actions/companyActions';
import {
  getRequestableKeyWorkflows,
  getGamesForWorkflows,
  getKeyPoolBalance,
} from '../actions/workflowActions';
import { getKeyProviderRequestInformation } from '../actions/keyProviderActions';
import permissionTypes from '../constants/permissionTypes';
import { hasPermission } from '../components/auth/authUtils';
import { toast } from 'react-toastify';
import WizardSteps from '../constants/WizardSteps';

const useRequestKeysWizard = ({ currentUser, _gameId, _skuId }) => {
  const [companyId, setCompanyId] = useState();
  const [companies, setCompanies] = useState();
  const [keyWorkflowId, setKeyWorkflowId] = useState();
  const [keyWorkflows, setKeyWorkflows] = useState();
  const [gameId, setGameId] = useState();
  const [games, setGames] = useState();
  const [skuId, setSKUId] = useState();
  const [skus, setSKUs] = useState();
  const [currentStep, setCurrentStep] = useState();
  const [requiresTags, setRequiresTags] = useState(false);
  const [tagsAsFreeText, setTagsAsFreeText] = useState(false);
  const [showKeyPoolLevelDuringRequest, setShowKeyPoolLevelDuringRequest] =
    useState(false);
  const [requesterTagId, setRequesterTagId] = useState();
  const [requesterTags, setRequesterTags] = useState();
  const [requesterTag, setRequesterTag] = useState();
  const [keyProviderRequestInformation, setKeyProviderRequestInformation] =
    useState();
  const [request, setRequest] = useState();
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const requestOnBehalfOf = hasPermission(
    currentUser,
    permissionTypes.REQUEST_ON_BEHALF_OF
  );
  const [isFirstItem, setIsFirstItem] = useState(true);

  const handleBack = () => {
    const _currentStep = history.slice(-1)[0];
    const previousStep = history.slice(-2)[0];
    if (previousStep) {
      setHistory((prevState) => prevState.filter((x) => x !== _currentStep));
      setCurrentStep(previousStep);

      switch (previousStep) {
        case WizardSteps.COMPANY:
          // make sure to clear out the sku, so it doesn't trigger refetch in useEffect
          setSKUId();
          setCompanyId();
          setKeyWorkflowId();
          break;
        case WizardSteps.KEY_WORKFLOW:
          // make sure to clear out the sku, so it doesn't trigger refetch in useEffect
          setSKUId();
          setKeyWorkflowId();
          break;
        case WizardSteps.GAME:
          setGameId();
          break;
        case WizardSteps.SKU:
          setSKUId();
          break;
        case WizardSteps.TAGS_FREE_TEXT:
          setRequesterTag();
          break;
        case WizardSteps.TAGS:
          setRequesterTagId();
          break;
        case WizardSteps.CONFIRM:
          break;
        default:
          break;
      }
    }
  };

  //Get the list of companies
  useEffect(() => {
    if (!companies && requestOnBehalfOf) {
      getRequestableCompanies(_gameId, _skuId)
        .then((_companies) => {
          setCompanies(_companies);
          if (_companies.length === 1) {
            setCompanyId(_companies[0].id);
          } else {
            setCurrentStep(WizardSteps.COMPANY);
            setHistory((prevState) => [...prevState, WizardSteps.COMPANY]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          toast.error(e.message || 'Failed to get list of companies');
          setIsLoading(false);
        });
    } else if (companies && companies.length === 1) {
      setCompanyId(companies[0].id);
    } else if (!companies) {
      setCompanies([{ id: currentUser.companyId }]);
      setCompanyId(currentUser.companyId);
    }
  }, [companies, requestOnBehalfOf, currentUser, _gameId, _skuId]);

  //Get the list of key workflows
  useEffect(() => {
    if (companyId) {
      const company = companies?.find((x) => x.id === companyId);
      setRequest((prevState) => ({
        ...prevState,
        ...{ company },
      }));
      getRequestableKeyWorkflows(
        requestOnBehalfOf ? companyId : '',
        _gameId,
        _skuId
      )
        .then((workflows) => {
          setKeyWorkflows(workflows);
          if (workflows.length === 1) {
            setKeyWorkflowId(workflows[0].id);
          } else {
            setCurrentStep(WizardSteps.KEY_WORKFLOW);
            setIsLoading(false);
            setHistory((prevState) => [...prevState, WizardSteps.KEY_WORKFLOW]);
          }
        })
        .catch((e) => {
          toast.error(e.message || 'Failed to get list of workflows');
          setIsLoading(false);
        });
    }
  }, [companyId, companies, requestOnBehalfOf, _gameId, _skuId]);

  //Get the list of games
  useEffect(() => {
    if (keyWorkflowId) {
      const keyWorkflow = keyWorkflows?.find((x) => x.id === keyWorkflowId);
      if (keyWorkflow) {
        setRequest((prevState) => ({
          ...prevState,
          ...{ keyWorkflow },
        }));
        setRequiresTags(keyWorkflow.workflowRequiresTag);
        setTagsAsFreeText(keyWorkflow.tagsAsFreeText);
        setRequesterTags(keyWorkflow.requesterTags);
        setShowKeyPoolLevelDuringRequest(
          keyWorkflow.showKeyPoolLevelDuringRequest
        );
      }

      getGamesForWorkflows(keyWorkflowId, requestOnBehalfOf ? companyId : '')
        .then((games) => {
          const filteredGames = games.filter((x) => x.skUs.length > 0);
          setGames(filteredGames);
          if (filteredGames.length === 1) {
            setGameId(filteredGames[0].id);
          } else if (_gameId) {
            setGameId(_gameId);
          } else {
            setCurrentStep(WizardSteps.GAME);
            setIsLoading(false);
            setHistory((prevState) => [...prevState, WizardSteps.GAME]);
          }
        })
        .catch((e) => {
          toast.error(e.message || 'Failed to get list of games');
          setIsLoading(false);
        });
    }
  }, [keyWorkflowId, keyWorkflows, _gameId, companyId, requestOnBehalfOf]);

  //Get the list of SKUs
  useEffect(() => {
    if (gameId) {
      const game = games?.find((x) => x.id === gameId);
      if (game) {
        setRequest((prevState) => ({
          ...prevState,
          ...{ game },
        }));

        if (game.skUs.length === 1) {
          setSKUId(game.skUs[0].skuId);
        } else if (_skuId) {
          setSKUId(_skuId);
        } else {
          setSKUs(game?.skUs);
          setCurrentStep(WizardSteps.SKU);
          setIsLoading(false);
          setHistory((prevState) => [...prevState, WizardSteps.SKU]);
        }
      } else {
        toast.error('Failed to find game');
      }
    }
  }, [gameId, games, _skuId]);

  //Decide if you need to show tags or straight to confirm
  useEffect(() => {
    if (skuId) {
      const game = games?.find((x) => x.id === gameId);
      const sku = game?.skUs.find((x) => x.skuId === skuId);
      if (sku) {
        setRequest((prevState) => ({
          ...prevState,
          ...{ sku },
        }));
        getKeyProviderRequestInformation(sku.keyProviderId)
          .then((info) => {
            setKeyProviderRequestInformation(info);
            if (requiresTags && tagsAsFreeText) {
              setCurrentStep(WizardSteps.TAGS_FREE_TEXT);
              setHistory((prevState) => [
                ...prevState,
                WizardSteps.TAGS_FREE_TEXT,
              ]);
            } else if (requiresTags && requesterTags?.length > 0) {
              setCurrentStep(WizardSteps.TAGS);
              setHistory((prevState) => [...prevState, WizardSteps.TAGS]);
            } else {
              setCurrentStep(WizardSteps.CONFIRM);
              setHistory((prevState) => [...prevState, WizardSteps.CONFIRM]);
            }
            setIsLoading(false);
          })
          .catch((e) => {});

        if (showKeyPoolLevelDuringRequest) {
          getKeyPoolBalance(
            keyWorkflowId,
            companyId,
            game.id ?? sku.gameId,
            sku.skuId
          )
            .then((keyPoolLevel) => {
              setRequest((prevState) => ({
                ...prevState,
                keyPoolLevel,
              }));
            })
            .catch((e) => {});
        }
      }
    }
  }, [
    skuId,
    requiresTags,
    tagsAsFreeText,
    games,
    gameId,
    requesterTags,
    showKeyPoolLevelDuringRequest,
    keyWorkflowId,
    companyId,
  ]);

  useEffect(() => {
    if (requesterTag) {
      setRequest((prevState) => ({
        ...prevState,
        ...{ requesterTag: { freeText: requesterTag } },
      }));
      setCurrentStep(WizardSteps.CONFIRM);
      setHistory((prevState) => [...prevState, WizardSteps.CONFIRM]);
    }
  }, [requesterTag]);

  useEffect(() => {
    if (requesterTagId && requesterTags) {
      const requesterTag = requesterTags.find((x) => x.id === requesterTagId);
      setRequest((prevState) => ({
        ...prevState,
        ...{ requesterTag },
      }));
      setCurrentStep(WizardSteps.CONFIRM);
      setHistory((prevState) => [...prevState, WizardSteps.CONFIRM]);
    }
  }, [requesterTagId, requesterTags]);

  useEffect(() => {
    setIsFirstItem(history.length <= 1);
  }, [history]);

  return [
    currentStep,
    companies,
    setCompanyId,
    keyWorkflows,
    setKeyWorkflowId,
    games,
    setGameId,
    skus,
    setSKUId,
    setRequesterTag,
    requesterTags,
    setRequesterTagId,
    keyProviderRequestInformation,
    request,
    handleBack,
    isLoading,
    setIsLoading,
    isFirstItem,
  ];
};

export default useRequestKeysWizard;
