import { useEffect, useCallback } from 'react';
import useUploadNotificationsSingle from './useUploadNotificationsSingle';

const useUploadNotificationsGeneric = (
  tableData,
  setTableData,
  groupPrefix,
  addToGroup,
  removeFromGroup,
  importTransactions,
  idColumnName,
  isInProgressCallback
) => {
  const found = useCallback(
    (filteredTransactions) => {
      setTableData((prevState) =>
        prevState && prevState.data
          ? {
              ...prevState,
              data: prevState.data.map((row) => {
                const importTransaction = filteredTransactions.find(
                  (i) => i.id === row[idColumnName]
                );
                return importTransaction
                  ? { ...row, ...importTransaction }
                  : row;
              }),
            }
          : prevState
      );
    },
    [setTableData, idColumnName]
  );
  const [uploadsInProgress, setUploadsInProgress] =
    useUploadNotificationsSingle(
      groupPrefix,
      removeFromGroup,
      importTransactions,
      found
    );
  useEffect(() => {
    if (tableData) {
      for (const i in tableData.data) {
        const row = tableData.data[i];
        if (
          isInProgressCallback(row) &&
          !uploadsInProgress.includes(row[idColumnName])
        ) {
          addToGroup(`${groupPrefix}:${row[idColumnName]}`);
          setUploadsInProgress((prevState) =>
            prevState.includes(row[idColumnName])
              ? prevState
              : [...prevState, row[idColumnName]]
          );
        }
      }
    }
  }, [
    tableData,
    addToGroup,
    groupPrefix,
    idColumnName,
    isInProgressCallback,
    uploadsInProgress,
    setUploadsInProgress,
  ]);
};
export default useUploadNotificationsGeneric;
