import React, { useState } from 'react';
import { connect } from 'react-redux';
import PlatformsList from './components/PlatformsList';
import ActionBar from '../../layout/ActionBar';
import Edit from './slideout/Edit';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function Platforms({ location }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [addPlatformSlideoutOpen, setAddPlatformSlideoutOpen] = useState(false);

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={() => setAddPlatformSlideoutOpen(true)}
          text={t('Add platform')}
        />
      </ActionBar>

      <PlatformsList location={location} reload={reload} />
      <SlidingPane
        isOpen={addPlatformSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddPlatformSlideoutOpen(false)}
      >
        <Edit
          done={(d) => {
            setReload(new Date());
            setAddPlatformSlideoutOpen(false);
          }}
          cancel={() => setAddPlatformSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Platforms);
