import {
  apiUrl,
  getList,
  deleteRecord,
  getSingleRecord,
  saveRecord,
  getAll,
  exportData,
} from './apiUtils';
const baseUrl = apiUrl + '/territories/';

export function getTerritories(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getTerritoryById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveTerritory(territory) {
  return saveRecord(baseUrl, territory);
}

export function deleteTerritory(id) {
  return deleteRecord(baseUrl, id);
}
export function getAllTerritories() {
  return getAll(baseUrl);
}

export function getDisallowedSkus(
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}${id}/disallowedskus`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAllowedSkus(
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}${id}/allowedskus`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportTerritoryData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/territories/search`,
    'name',
    filters,
    scopes
  );
}
