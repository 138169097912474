import React from 'react';
import PropTypes from 'prop-types';

function PackageTypeRow({ game, style }) {
  return (
    <div className="grid-empty" style={style}>
      <span>{game.isSKU ? game.packageType : ''}</span>
    </div>
  );
}

PackageTypeRow.propTypes = {
  game: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
};

export default PackageTypeRow;
