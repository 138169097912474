import {
  apiUrl,
  getList,
  deleteRecord,
  getSingleRecord,
  saveRecord,
  apiOptions,
  handleError,
  handleResponse,
  postAction,
  getAuditData,
  exportData,
} from './apiUtils';
const baseUrl = apiUrl + '/companyTypes/';

export function getCompanyTypes(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function getAllCompanyTypes() {
  return fetch(`${baseUrl}?_page=0`, apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function getCompanyTypeById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveCompanyType(companyType) {
  return saveRecord(baseUrl, companyType);
}

export function deleteCompanyType(id) {
  return deleteRecord(baseUrl, id);
}

export function getAudit(id) {
  return getAuditData(baseUrl, id);
}

export function addAudit(id, comment) {
  return postAction(`${baseUrl}${id}/audit`, { comment });
}

export function exportCompanyTypesData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/companyTypes/search`,
    'name',
    filters,
    scopes
  );
}
