import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UserForm from '../components/UserForm';
import {
  saveUser,
  getUserById,
  getUserProfileGroupsForUser,
  getTerritoriesForUser,
  addTerritory,
  removeTerritory,
  resetAuthenticator,
} from '../../../../actions/userActions';

import { getUserProfileGroups } from '../../../../actions/userProfileGroupActions';
import { getAllTerritories } from '../../../../actions/territoryActions';
import { getAllCompanies } from '../../../../actions/companyActions';

import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Loading from '../../../common/Loading';
import PermissionBadges from '../../permissions/components/PermissionBadges';
import {
  addPermissionUser,
  deletePermissionUser,
  getPermissionUser,
} from '../../../../actions/permissionActions';
import {
  addUserToGroup,
  removeUserFromGroup,
} from '../../../../actions/userProfileGroupActions';
import UserProfileGroupBadges from '../components/UserProfileGroupBadges';
import TerritoryBadges from '../components/TerritoryBadges';
import { hasPermission } from '../../../auth/authUtils';
import permissionTypes from '../../../../constants/permissionTypes';
import icons from '../../../../constants/icons';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import ResponsiveActionBarButtons from '../../../common/ResponsiveActionBarButtons';
import DisableKeys from '../../../keys/slideout/DisableKeys';
import ResetAuthenticator from './ResetAuthenticator';
import Archive from './Archive';
import SlidingPane from 'react-sliding-pane';
import image_no_content from '../../../../content/images/no-content.svg';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const EditUser = ({
  done,
  cancel,
  id,
  saveUser,
  history,
  getAllTerritories,
  territories,
  companies,
  getAllCompanies,
  currentUser,
}) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [reloadPermissions, setReloadPermissions] = useState();
  const [reloadUserProfileGroups, setReloadUserProfileGroups] = useState();
  const [reloadUserProfileTerritories, setReloadUserProfileTerritories] =
    useState();
  const [permissions, setPermissions] = useState();
  const [userProfileGroups, setUserProfileGroups] = useState();
  const [userProfileTerritories, setUserProfileTerritories] = useState();
  const [allGroups, setAllGroups] = useState();

  const [showArchive, setShowArchive] = useState(false);
  const [showDisableKeys, setShowDisableKeys] = useState(false);
  const [showResetAuthenticator, setShowResetAuthenticator] = useState(false);

  const onSubmit = (data) => {
    saveUser({ ...user, ...data })
      .then(() => {
        setUser({ ...user, ...data });
        toast.success(t('User saved'));
        done(user);
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to save user'));
      });
  };

  const onResetAuthenticator = () => {
    if (!user) {
      toast.error(t('Failed to reset authenticator'));
      return;
    }

    resetAuthenticator(user.id)
      .then(() => {
        toast.success(t('Authenticator token reset'));
      })
      .catch((e) => {
        toast.error(t('Failed to reset authenticator'));
      });
  };

  const [user, setUser] = useState();

  useEffect(() => {
    if (id) {
      // get the user
      getUserById(id)
        .then((d) => {
          setUser(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get user'));
        });
    }
  }, [id, history]);

  useEffect(() => {
    // get the groups
    if (!allGroups) {
      getUserProfileGroups()
        .then((d) => {
          setAllGroups(d.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [allGroups]);

  // get the territories
  useEffect(() => {
    if (!territories) {
      getAllTerritories();
    }
  }, [territories, getAllTerritories]);

  // user's permissions
  useEffect(() => {
    if (id && hasPermission(currentUser, permissionTypes.MANAGE_PERMISSIONS)) {
      getPermissionUser(id)
        .then((d) => {
          setPermissions(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get permissions'));
        });
    }
  }, [currentUser, reloadPermissions, id]);

  // user's groups
  useEffect(() => {
    if (id) {
      getUserProfileGroupsForUser(id)
        .then((d) => {
          setUserProfileGroups(d.data);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get groups'));
        });
    }
  }, [reloadUserProfileGroups, id]);

  // user's territories
  useEffect(() => {
    if (id) {
      getTerritoriesForUser(id)
        .then((d) => {
          setUserProfileTerritories(d.data);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get territories'));
        });
    }
  }, [reloadUserProfileTerritories, id]);

  //companies
  useEffect(() => {
    if (!companies) {
      getAllCompanies().catch((e) => {
        toast.error(t('Failed to get companies'));
      });
    }
  }, [companies, getAllCompanies]);

  const calculateTitle = () => {
    let title;
    if (!user) return;
    else if (user.isArchived) {
      title = t('User is archived');
    } else if (!user.supportsAuthenticatorApp) {
      title = t('Authentication method does not support reset');
    } else {
      title = t('Reset authenticator');
    }
    return title;
  };

  return user ? (
    <SlideoutLayout
      title={t('Edit user')}
      cancel={cancel}
      done={done}
      bar={
        <>
          <ResponsiveActionBarButtons
            buttons={[
              <Button
                key="archive"
                className="btn btn-danger ms-2 d-none d-md-inline-flex"
                onClick={() => setShowArchive(true)}
                isDisabled={user.isArchived}
                title={user.isArchived ? t('User is archived') : t('Archive')}
                text={t('Archive')}
              />,
              <Button
                key="disableKeys"
                className="btn btn-danger ms-2 d-none d-md-inline-flex"
                onClick={() => setShowDisableKeys(true)}
                text={t('Disable keys')}
              />,
              <Button
                key="resetAuthenticator"
                className="btn btn-default ms-2 d-none d-md-inline-flex"
                onClick={() => setShowResetAuthenticator(true)}
                isDisabled={user.isArchived || !user.supportsAuthenticatorApp}
                title={calculateTitle()}
                text={t('Reset authenticator')}
              />,
              <Button
                key="edit"
                className="btn btn-default ms-2 d-none d-md-inline-flex"
                onClick={handleSubmit(onSubmit)}
                isDisabled={user.isArchived}
                title={user.isArchived ? t('User is archived') : t('Save')}
                text={t('Save')}
              />,
            ]}
          />

          <SlidingPane
            isOpen={showArchive}
            hideHeader={true}
            from="right"
            className="side-panel"
            onRequestClose={() => setShowArchive(false)}
          >
            <Archive
              id={id}
              done={(d) => {
                setShowArchive(false);
                history.push('/users');
              }}
              cancel={() => setShowArchive(false)}
            />
          </SlidingPane>

          <SlidingPane
            isOpen={showDisableKeys}
            hideHeader={true}
            from="right"
            className="large-side-panel"
            onRequestClose={() => setShowDisableKeys(false)}
          >
            <DisableKeys
              title={t('Disable keys for {name}', {
                name: user.fullName || 'user',
              })}
              targetRequestingUserProfileId={id}
              done={(d) => {
                setShowDisableKeys(false);
              }}
              cancel={() => setShowDisableKeys(false)}
              detaultReason={t('Disable keys for {name} user', {
                name: user.fullName,
              })}
            />
          </SlidingPane>

          <SlidingPane
            isOpen={showResetAuthenticator}
            hideHeader={true}
            from="right"
            className="side-panel"
            onRequestClose={() => setShowResetAuthenticator(false)}
          >
            <ResetAuthenticator
              title={t('Reset authenticator for {name}', {
                name: user.fullName || 'user',
              })}
              done={(d) => {
                onResetAuthenticator();
                setShowResetAuthenticator(false);
              }}
              cancel={() => setShowResetAuthenticator(false)}
            />
          </SlidingPane>
        </>
      }
    >
      {user ? (
        !user.isArchived ? (
          <>
            {companies && (
              <UserForm
                user={user}
                register={register}
                control={control}
                errors={errors}
                companies={companies}
                readonly={
                  !hasPermission(currentUser, permissionTypes.MANAGE_USERS)
                }
              />
            )}
            {hasPermission(currentUser, permissionTypes.MANAGE_PERMISSIONS) && (
              <PermissionBadges
                onAdd={(d) => {
                  addPermissionUser(d, id).then(() =>
                    setReloadPermissions(new Date())
                  );
                }}
                onRemove={(d) => {
                  deletePermissionUser(d, id).then(() =>
                    setReloadPermissions(new Date())
                  );
                }}
                permissions={permissions}
                label={t('Direct permissions')}
                description={t(
                  'In addition to permissions inherited from group membership, users can have permissions granted directly.'
                )}
              />
            )}
            <UserProfileGroupBadges
              onAdd={(d) => {
                addUserToGroup(d, id).then(() =>
                  setReloadUserProfileGroups(new Date())
                );
              }}
              onRemove={(d) => {
                removeUserFromGroup(d, id).then(() =>
                  setReloadUserProfileGroups(new Date())
                );
              }}
              userProfileGroups={userProfileGroups}
              allGroups={allGroups}
              label={t('Group membership')}
              description={t(
                "The user will inherit permissions from these groups although these permissions aren't shown above."
              )}
            />

            <TerritoryBadges
              onAdd={(d) => {
                addTerritory(id, d).then(() =>
                  setReloadUserProfileTerritories(new Date())
                );
              }}
              onRemove={(d) => {
                removeTerritory(id, d).then(() =>
                  setReloadUserProfileTerritories(new Date())
                );
              }}
              userProfileTerritories={userProfileTerritories}
              allTerritories={territories}
              label={t('Allowed territories')}
              description={t(
                'If a user has territories listed then they are restricted to SKUS with this territory, if there are no territories listed then they can request any SKU.'
              )}
              nodata={
                <>
                  <FontAwesomeIcon
                    icon={icons.ARE_YOU_SURE}
                    className="me-3 c-orange"
                  />
                  {t('This user can request a SKU from any territory')}
                </>
              }
            />
          </>
        ) : (
          <div
            className="alert alert-info text-center"
            style={{ padding: '30px 15px' }}
          >
            <img
              src={image_no_content}
              alt={t('This user has been archived')}
              className="space-bottom-small"
            />
            <p>{t('This user has been archived')}</p>
          </div>
        )
      ) : (
        <Loading></Loading>
      )}
    </SlideoutLayout>
  ) : (
    <Loading></Loading>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    territories: state.territories,
    companies: state.companies,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  saveUser,
  getAllTerritories,
  getAllCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
