import React, { useEffect, useState, useRef } from 'react';
import { CommaNumber_NoDecimal } from '../../util/formatter';
import { withRouter, NavLink } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { StartPlasm, StopPlasm } from './plasm';
import Gravatar from 'react-gravatar';
import { getUserLens } from '../../actions/lensActions';
import { useTranslation } from 'react-i18next';

function UserContent(props) {
  const ref = useRef();
  const [user, setUser] = useState();
  const { t } = useTranslation();

  // Animation effect
  useEffect(() => {
    let plas = null;
    if (props.state.animate && user) {
      plas = StartPlasm(ref.current, props.id);
    } else if (plas != null) {
      StopPlasm(plas);
    }
    return function cleanup() {
      if (plas != null) {
        StopPlasm(plas);
      }
    };
  }, [props.state.animate, props.id, user]);

  // get the data and start the animation
  useEffect(() => {
    if (props.state.animate && props.id) {
      getUserLens(props.id)
        .then((d) => {
          setUser(d);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [props.state.animate, props.id]);

  return user ? (
    <div className="tippy-content">
      <div
        className="ani-wrapper"
        ref={ref}
        style={{ height: '70px', width: '300px' }}
      ></div>
      <div className="ani-content">
        <div className="ani-content-inner">
          <Gravatar
            email={user.username}
            size={68}
            rating="pg"
            default="retro"
            className="img-circle"
            style={{ border: '2px solid #ffffff', overflow: 'hidden' }}
          />
          <div className="ani-details">
            <h1>{user.fullName}</h1>
            <h2>{user.companyName}</h2>
            <div>
              {t('Assignments:')}{' '}
              <span className="num">
                {CommaNumber_NoDecimal(user.assignments)}
              </span>
            </div>
            <div>
              {t('Open requests:')}{' '}
              <span className="num">
                {CommaNumber_NoDecimal(user.openRequests)}
              </span>
            </div>
          </div>
        </div>
        <div className="ani-btns">
          <NavLink to={`/user/${props.id}`} className="btn btn-default">
            {t('View user')}
          </NavLink>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
}

function UserPopup(props) {
  const [state, setState] = useState({ animate: false });
  const onMount = () => setState({ ...state, animate: true });
  const onHide = () => setState({ ...state, animate: false });

  //https: github.com/atomiks/tippyjs-react
  return (
    <Tippy
      interactive={true}
      interactiveBorder={20}
      delay={300}
      arrow={true}
      content={<UserContent id={props.id} state={state} />}
      onMount={onMount}
      onHide={onHide}
      appendTo={document.body}
    >
      {props.children}
    </Tippy>
  );
}

export default withRouter(UserPopup);
