import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.LOAD_TERRITORIES:
      return action.territories;
    case actionTypes.UPDATE_TERRITORY:
      return state
        ? state.map((territory) =>
            territory.id === action.territory.id ? action.territory : territory
          )
        : state;
    case actionTypes.CREATE_TERRITORY:
      return state ? [...state, action.territory] : state;
    case actionTypes.DELETE_TERRITORY:
      return state
        ? state.filter((territory) => territory.id !== action.id)
        : state;
    default:
      return state;
  }
}
