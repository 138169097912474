import React from 'react';
import ActionBar from '../layout/ActionBar';
import SLAsBySkuList from './components/SLAsBySkuList';

function SLAsBySku({ location }) {
  return (
    <>
      <ActionBar></ActionBar>
      <SLAsBySkuList location={location} />
    </>
  );
}
export default SLAsBySku;
