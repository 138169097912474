import React, { useEffect, useState } from 'react';
import DashboardStats from '../../common/DashboardStats';
import { scopes as tableScopes } from '../../../constants/scopes';

const Stats = ({ id, stats }) => {
  const [_stats, _setStats] = useState([]);

  useEffect(() => {
    if (stats) {
      _setStats([
        {
          colour: 'blue',
          linkto: `/requests/made-by-me?${tableScopes.ISPENDING}=true`,
          title: 'Your requests - pending',
          metric: stats.yourOpenRequests,
        },
        {
          colour: 'blue',
          linkto: `/requests/made-by-me`,
          title: 'Your requests - all',
          metric: stats.yourRequests,
        },
        {
          colour: 'aqua',
          linkto: `/games`,
          title: 'Games available to request',
          metric: stats.games,
        },
      ]);
    }
  }, [id, stats]);

  return <DashboardStats stats={_stats} />;
};

export default Stats;
