import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';

import {
  saveSku,
  getSkuById,
  removeTerritories,
  addTerritories,
  removeWorkflows,
  addWorkflows,
} from '../../../actions/skuActions';
import SkuForm from '../components/SkuForm';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import TerritoryRestrictions from '../../settings/territory/components/TerritoryRestrictions';
import WorkflowRestrictions from '../../settings/workflow/components/WorkflowRestrictions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';

function EditSku({
  id,
  done,
  cancel,
  addMode,
  keyProviders,
  platforms,
  packageTypes,
  gameId,
  saveSku,
  columnCssClass,
  currentUser,
  territories,
  workflows,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm();

  const updateTerritoryRestrictions = async (data) => {
    if (!sku) {
      return;
    }
    const territoryIds = data.territories?.map((x) => x.id);
    const exisitingIds = sku.territories?.map((x) => x.id);
    const removedTerritories = exisitingIds?.filter(
      (x) =>
        data.disallowListedTerritories === 'Unrestricted' ||
        territoryIds?.indexOf(x) === -1
    );
    const addedTerritories = territoryIds?.filter(
      (x) =>
        data.disallowListedTerritories !== 'Unrestricted' &&
        exisitingIds?.indexOf(x) === -1
    );

    let promises = [];

    if (removedTerritories?.length > 0) {
      promises.push(
        Promise.resolve(removeTerritories(id, sku.gameId, removedTerritories))
      );
    }

    if (addedTerritories?.length > 0) {
      promises.push(
        Promise.resolve(addTerritories(id, sku.gameId, addedTerritories))
      );
    }

    return Promise.all(promises);
  };

  const updateWorkflowRestrictions = async (data) => {
    const workflowIds = data.workflows?.map((x) => x.id);
    const exisitingIds = sku?.keyWorkflows?.map((x) => x.id);
    const removedWorkflows = exisitingIds?.filter(
      (x) =>
        data.disallowListedKeyWorkflows === 'Unrestricted' ||
        workflowIds?.indexOf(x) === -1
    );
    const addedWorkflows = workflowIds?.filter(
      (x) =>
        data.disallowListedKeyWorkflows !== 'Unrestricted' &&
        exisitingIds?.indexOf(x) === -1
    );

    let promises = [];

    if (removedWorkflows?.length > 0) {
      if (!sku) {
        return;
      }
      promises.push(
        Promise.resolve(removeWorkflows(id, sku.gameId, removedWorkflows))
      );
    }

    if (addedWorkflows?.length > 0) {
      if (!sku) {
        return;
      }
      promises.push(
        Promise.resolve(addWorkflows(id, sku.gameId, addedWorkflows))
      );
    }

    return Promise.all(promises);
  };

  const onSubmit = (data) => {
    if (sku) {
      let updatedSKU = { ...sku, ...data };
      updateTerritoryRestrictions(updatedSKU)
        .then(() => {
          updateWorkflowRestrictions(updatedSKU)
            .then(() => {
              // put these back to bools
              // todo - change api to use an enum for this
              updatedSKU.disallowListedKeyWorkflows =
                updatedSKU.disallowListedKeyWorkflows !== 'Allow Only';
              updatedSKU.disallowListedTerritories =
                updatedSKU.disallowListedTerritories !== 'Allow Only';

              saveSku(sku.gameId, updatedSKU)
                .then(() => {
                  setSku(updatedSKU);
                  done();
                  toast.success(t('Sku saved'));
                })
                .catch((e) => {
                  toast.error(e.message || t('Failed to save sku'));
                });
            })
            .catch((e) => {
              console.error(e);
              toast.error(
                t(
                  'Failed to update some of the workflow restrictions, please try again'
                )
              );
            });
        })
        .catch((e) => {
          console.error(e);
          toast.error(
            t(
              'Failed to update some of the territory restrictions, please try again'
            )
          );
        });
    } else {
      toast.error(t('Failed to save sku'));
    }
  };

  const [sku, setSku] = useState();
  const [filteredKeyProviders, setFilteredKeyProviders] = useState([]);

  useEffect(() => {
    if (id) {
      getSkuById(id).then((d) => {
        const platform = platforms.find((x) => x.id === d.platformId);
        if (platform) {
          setFilteredKeyProviders(platform.keyProviders);
        } else {
          setFilteredKeyProviders([]);
        }
        setSku(d);
      });
    }
  }, [id, platforms]);

  return (
    <SlideoutLayout
      title={id ? t('Edit sku') : t('Add sku')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="submit"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      {sku && packageTypes && (
        <>
          <SkuForm
            sku={sku}
            register={register}
            errors={errors}
            control={control}
            addMode={addMode}
            columnCssClass={columnCssClass}
            platforms={platforms}
            keyProviders={filteredKeyProviders}
            packageTypes={packageTypes}
            currentUser={currentUser}
          ></SkuForm>

          <TerritoryRestrictions
            register={register}
            setValue={setValue}
            restricted={sku.disallowListedTerritories}
            restrictions={sku.territories}
            territories={territories}
            locked={sku.isArchived}
          ></TerritoryRestrictions>

          <WorkflowRestrictions
            register={register}
            setValue={setValue}
            restricted={sku.disallowListedKeyWorkflows}
            restrictions={sku.keyWorkflows}
            workflows={workflows}
            locked={sku.isArchived}
          ></WorkflowRestrictions>
        </>
      )}
    </SlideoutLayout>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}
const mapDispatchToProps = {
  saveSku,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSku);

EditSku.propTypes = {
  id: PropTypes.string,
  gameId: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  addMode: PropTypes.bool,
  keyProviders: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
  packageTypes: PropTypes.array.isRequired,
  saveSku: PropTypes.func.isRequired,
  columnCssClass: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  territories: PropTypes.array,
  workflows: PropTypes.array,
};
