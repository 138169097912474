import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GameStats from './components/GameStats';
import SkuList from './components/SkuList';
import RolledUpStats from '../common/RolledUpStats';

import {
  saveGame,
  getStats,
  getAudit,
  addAudit,
  getStatsByDay,
} from '../../actions/gameActions';
import {
  saveSku,
  addTerritories,
  addWorkflows,
} from '../../actions/skuActions';
import ActionBar from '../layout/ActionBar';
import { toast } from 'react-toastify';

import { getKeyProviders } from '../../actions/keyProviderActions';
import { getPlatforms } from '../../actions/platformActions';
import { getAllPackageTypes } from '../../actions/packageTypeActions';
import { getAllTerritories } from '../../actions/territoryActions';
import { getAllWorkflows } from '../../actions/workflowActions';
import RequestKeys from '../requests/slideout/RequestKeys';
import Archive from './slideout/Archive';
import Loading from '../common/Loading';
import SteamBulkRequest from '../requests/slideout/SteamBulkRequest';
import AuditButton from '../audit/components/AuditButton';
import { userIs } from '../auth/authUtils';
import userTypes from '../../constants/userTypes';
import SlidingPane from 'react-sliding-pane';
import SkuBulkActions from './slideout/SkuBulkActions';
import { populateSkuKeyPools } from '../../util/steamUtils';
import GameDetailsAdvanced from './components/GameDetailsAdvanced';
import EditGame from './slideout/EditGame';
import AddSku from '../sku/slideout/AddSku';
import DisableKeys from '../keys/slideout/DisableKeys';
import ResponsiveActionBarButtons from '../common/ResponsiveActionBarButtons';
import Button from '../common/Button';
import { scopes as tableScopes } from '../../constants/scopes';
import { useTranslation } from 'react-i18next';

const ManageGame = ({
  id,
  saveGame,
  history,
  location,
  keyProviders,
  getKeyProviders,
  platforms,
  getPlatforms,
  packageTypes,
  getAllPackageTypes,
  saveSku,
  currentUser,
  setCompleteRequestSlideoutOpen,
  game,
  setGame,
  reloadGame,
  setReloadGame,
  territories,
  getAllTerritories,
  workflows,
  getAllWorkflows,
}) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [requestKeysSlideoutOpen, setRequestKeysSlideoutOpen] = useState(false);
  const [requestKeysSKU, setRequestKeysSKU] = useState();
  const [archiveSlideoutOpen, setArchiveSlideoutOpen] = useState(false);
  const [addSKUSlideoutOpen, setAddSKUSlideoutOpen] = useState(false);
  const [bulkSteamRequestSlideoutOpen, setBulkSteamRequestSlideoutOpen] =
    useState(false);
  const [bulkSteamRequestSlideoutSKUs, setBulkSteamRequestSlideoutSKUs] =
    useState();
  const [itemsChecked, setItemsChecked] = useState([]);
  const [bulkActionsSlideoutOpen, setBulkActionsSlideoutOpen] = useState(false);
  const [editSlideoutOpen, setEditSlideoutOpen] = useState(false);
  const [showDisableKeys, setShowDisableKeys] = useState(false);

  const [stats, setStats] = useState();

  useEffect(() => {
    if (id) {
      // get the stats
      getStats(id)
        .then((d) => {
          setStats(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to load stats for game'));
        });
    }
  }, [id, history, reloadGame, t]);

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders, id]);

  useEffect(() => {
    if (!platforms) {
      getPlatforms();
    }
  }, [platforms, getPlatforms, id]);

  useEffect(() => {
    if (!packageTypes) {
      getAllPackageTypes();
    }
  }, [packageTypes, getAllPackageTypes, id]);

  useEffect(() => {
    if (!territories) {
      getAllTerritories();
    }
  }, [territories, getAllTerritories]);

  useEffect(() => {
    if (!workflows) {
      getAllWorkflows();
    }
  }, [workflows, getAllWorkflows]);

  const handleOnRequestKeys = (e, sku) => {
    e.cancelBubble = true;
    setRequestKeysSKU(sku);
    setRequestKeysSlideoutOpen(true);
    return false;
  };

  const handleSteamBulkRequest = async (e, items) => {
    e.cancelBubble = true;
    const skus = await populateSkuKeyPools([
      { id: game.id, skus: items.map((x) => x.id) },
    ]);
    if (skus.length > 0) {
      setBulkSteamRequestSlideoutSKUs(skus);
      setBulkSteamRequestSlideoutOpen(true);
    } else {
      toast.error(
        t(
          'None of the selected SKUs support key pools or the workflow is missing key provider reference'
        )
      );
    }
    return false;
  };

  return game && stats ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/games', label: t('Games') },
          { label: game.name },
        ]}
      >
        <div>
          <AuditButton id={id} getAudit={getAudit} addAudit={addAudit} />
          {!game.isArchived && (
            <ResponsiveActionBarButtons
              buttons={[
                <Button
                  key="archive"
                  className="btn btn-danger ms-2 d-none d-md-inline-flex"
                  onClick={() => setArchiveSlideoutOpen(true)}
                  text={t('Archive game')}
                />,
                <Button
                  key="disableKeys"
                  className="btn btn-danger ms-2 d-none d-md-inline-flex"
                  onClick={() => setShowDisableKeys(true)}
                  text={t('Disable keys')}
                />,
                <Button
                  key="edit"
                  className="btn btn-primary ms-2"
                  onClick={() => setEditSlideoutOpen(true)}
                  text={t('Edit')}
                />,
              ]}
            />
          )}
        </div>
      </ActionBar>

      <GameDetailsAdvanced game={game} />

      <GameStats
        id={game.id}
        stats={stats}
        getData={() => getStatsByDay(game.id)}
      />

      <SkuList
        location={location}
        reload={reload}
        gameId={id}
        publisherCompanyId={game.publisherCompanyId}
        onAddSku={() => setAddSKUSlideoutOpen(true)}
        onRequestKeys={handleOnRequestKeys}
        canRequestKeys={userIs(currentUser, userTypes.REQUESTER)}
        canUploadKeys={userIs(currentUser, userTypes.UPLOADER)}
        handleSteamBulkRequest={handleSteamBulkRequest}
        locked={game.isArchived}
        handleBulkActions={() => {
          setBulkActionsSlideoutOpen(true);
        }}
        itemsChecked={itemsChecked}
        setItemsChecked={setItemsChecked}
      />

      <RolledUpStats
        viewAllRequestsLinkTo={`/requests/all?${tableScopes.GAME}=${game.id}`}
        openRequests={game.gameKeyRequestStats.openRequests}
        openRequestsLinkTo={`/requests/all?f_requestStatus=pending%20approval&${tableScopes.GAME}=${game.id}`}
        authorisedRequests={game.gameKeyRequestStats.authorisedRequests}
        authorisedRequestsLinkTo={`/requests/all?f_requestStatus=authorised&${tableScopes.GAME}=${game.id}`}
        declinedRequests={game.gameKeyRequestStats.declinedRequests}
        declinedRequestsLinkTo={`/requests/all?f_requestStatus=declined&${tableScopes.GAME}=${game.id}`}
        totalKeysRequested={game.gameKeyRequestStats.keysRequested}
        totalKeysRequestedLinkTo={`/keys/game?${tableScopes.GAME}=${game.id}`}
      ></RolledUpStats>

      {requestKeysSKU && (
        <SlidingPane
          isOpen={requestKeysSlideoutOpen}
          hideHeader={true}
          from="right"
          className="small-side-panel"
          onRequestClose={() => setRequestKeysSlideoutOpen(false)}
        >
          <RequestKeys
            gameId={requestKeysSKU.gameId}
            skuId={requestKeysSKU.id}
            done={() => {
              setRequestKeysSlideoutOpen(false);
              setCompleteRequestSlideoutOpen(true);
            }}
            cancel={() => setRequestKeysSlideoutOpen(false)}
          />
        </SlidingPane>
      )}

      <SlidingPane
        isOpen={archiveSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setArchiveSlideoutOpen(false)}
      >
        <Archive
          id={id}
          done={(d) => {
            history.push('/games/');
          }}
          cancel={() => setArchiveSlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={addSKUSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddSKUSlideoutOpen(false)}
      >
        <AddSku
          done={(d) => {
            setReload(new Date());
            setAddSKUSlideoutOpen(false);
          }}
          cancel={() => setAddSKUSlideoutOpen(false)}
          addMode={true}
          columnCssClass="col-12"
          keyProviders={keyProviders}
          platforms={platforms}
          packageTypes={packageTypes}
          gameId={id}
          saveSku={saveSku}
          addTerritories={addTerritories}
          addWorkflows={addWorkflows}
          currentUser={currentUser}
          workflows={workflows}
          territories={territories}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={bulkActionsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setBulkActionsSlideoutOpen(false)}
      >
        <SkuBulkActions
          done={() => {
            setReload(new Date());
            setBulkActionsSlideoutOpen(false);
          }}
          cancel={() => setBulkActionsSlideoutOpen(false)}
          items={itemsChecked}
          game={game}
        ></SkuBulkActions>
      </SlidingPane>

      <SlidingPane
        isOpen={bulkSteamRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setBulkSteamRequestSlideoutOpen(false)}
      >
        {game && bulkSteamRequestSlideoutSKUs ? (
          <SteamBulkRequest
            done={(d) => {
              setReload(new Date());
              setAddSKUSlideoutOpen(false);
              setBulkSteamRequestSlideoutSKUs(null);
            }}
            cancel={() => setBulkSteamRequestSlideoutOpen(false)}
            gameName={game.name}
            skus={bulkSteamRequestSlideoutSKUs}
          ></SteamBulkRequest>
        ) : (
          <></>
        )}
      </SlidingPane>

      <SlidingPane
        isOpen={editSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setEditSlideoutOpen(false)}
      >
        <EditGame
          id={game.id}
          history={history}
          done={() => {
            setReloadGame(new Date());
            setEditSlideoutOpen(false);
          }}
          cancel={() => setEditSlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={showDisableKeys}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setShowDisableKeys(false)}
      >
        <DisableKeys
          title={t('Disable keys for {game}', { game: game.name })}
          targetGameId={id}
          done={(d) => {
            setShowDisableKeys(false);
          }}
          cancel={() => setShowDisableKeys(false)}
          defaultReason={t('Disable keys for {game}', { game: game.name })}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.id,
    keyProviders: state.keyProviders,
    platforms: state.platforms,
    packageTypes: state.packageTypes,
    currentUser: state.auth,
    territories: state.territories,
    workflows: state.workflows,
  };
}

const mapDispatchToProps = {
  saveGame,
  getKeyProviders,
  getPlatforms,
  getAllPackageTypes,
  saveSku,
  getAllTerritories,
  getAllWorkflows,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageGame)
);
