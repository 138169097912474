import React, { useRef, useEffect, useState } from 'react';
import FormGroup from './FormGroup';
import { getImageById } from '../../api/imageApi';
import { useTranslation } from 'react-i18next';

export default function FormImage({ label, setFile, value }) {
  const { t } = useTranslation();
  const inputFile = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    setFile(e.target.files[0]);
  };
  useEffect(() => {
    let objectUrl;
    if (selectedFile) {
      // create the preview
      var binaryData = [];
      binaryData.push(selectedFile);
      objectUrl = window.URL.createObjectURL(
        new Blob(binaryData, { type: 'application/zip' })
      );

      setPreview(objectUrl);
    }

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    let objectUrl;
    if (value) {
      getImageById(value).then((blob) => {
        objectUrl = window.URL.createObjectURL(blob, {
          type: 'application/zip',
        });
        setPreview(objectUrl);
      });
    }
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [value]);

  return (
    <FormGroup label={label}>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={onSelectFile}
      />
      <div
        style={{
          border: '2px solid black',
          width: '34px',
          height: '34px',
          cursor: 'pointer',
        }}
        onClick={onButtonClick}
      >
        {preview && (
          <img
            src={preview}
            alt={t('Upload')}
            style={{ width: '100%', height: '100%' }}
          />
        )}
      </div>
    </FormGroup>
  );
}
