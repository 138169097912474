import React, { useState } from 'react';
import { connect } from 'react-redux';
import TerritoriesList from './components/TerritoriesList';
import ActionBar from '../../layout/ActionBar';
import Edit from './slideout/Edit';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Territories({ location }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [addTerritorySlideoutOpen, setAddTerritorySlideoutOpen] =
    useState(false);

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={() => setAddTerritorySlideoutOpen(true)}
          text={t('Add territory')}
        />
      </ActionBar>
      <TerritoriesList location={location} reload={reload} />

      <SlidingPane
        isOpen={addTerritorySlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddTerritorySlideoutOpen(false)}
      >
        <Edit
          done={(d) => {
            setReload(new Date());
            setAddTerritorySlideoutOpen(false);
          }}
          cancel={() => setAddTerritorySlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
  };
}

const mapDispatchToProps = {};

Territories.propTypes = {
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Territories);
