import React from 'react';

const ExpandPanel = (props) => (
  <div
    onClick={props.onClick}
    className={`expandable-panel ${
      props.className !== undefined ? props.className : ''
    }`}
  >
    {props.children}
  </div>
);
export default ExpandPanel;
