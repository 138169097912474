import React from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import SLAsByWorkflowsList from './components/SLAsByWorkflowsList';

function SLAsByWorkflows({ location, currentUser }) {
  return (
    <>
      <ActionBar></ActionBar>
      <SLAsByWorkflowsList location={location} currentUser={currentUser} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SLAsByWorkflows);
