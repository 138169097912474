import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import FormStatic from '../../common/FormStatic';
import KwPanel from '../../common/KwPanel';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import PropTypes from 'prop-types';
import FormInput from '../../common/FormInput';
import {
  saveRequest,
  clearSteamRequest,
} from '../../../actions/steamRequestActions';
import { addToGroup, removeFromGroup } from '../../../actions/signalRActions';
import { getAllPackageTypes } from '../../../actions/packageTypeActions';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Loading from '../../common/Loading';
import { steamRequestKeysUri } from '../../../util/steamUtils';
import Button from '../../common/Button';
import FormSelect from '../../common/FormSelect';
import { useTranslation } from 'react-i18next';

function SteamRequest({
  done,
  cancel,
  keyWorkflowId,
  keyPoolName,
  gameName,
  gameSkuId,
  skuName,
  keyRequestId,
  customerName,
  packageTypeId,
  addToGroup,
  removeFromGroup,
  steamRequest,
  clearSteamRequest,
  numberOfKeys,
  packageTypes,
  getAllPackageTypes,
}) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [steamRequestId, setSteamRequestId] = useState();
  let timer = useRef();

  const onError = (message) => {
    toast.error(message);
    setLoading(false);
  };

  const onSubmit = (record) => {
    setLoading(true);
    saveRequest({ keyWorkflowId, gameSkuId, keyRequestId, ...record })
      .then((data) => {
        setSteamRequestId(data.id);
        //uri schema
        window.location = steamRequestKeysUri(data.id);
        timer.current = setTimeout(() => {
          onError(t('Failed to launch Steam request within 30 seconds'));
        }, 30000);
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.message ?? t('Failed to create Steam request'));
      });
  };
  useEffect(() => {
    if (steamRequestId) {
      addToGroup(`steamRequest:${steamRequestId}`);
      return () => {
        removeFromGroup(`steamRequest:${steamRequestId}`);
        clearTimeout(timer.current);
      };
    }
  }, [steamRequestId, addToGroup, removeFromGroup]);

  useEffect(() => {
    if (steamRequest && steamRequest.id === steamRequestId) {
      clearSteamRequest();
      clearTimeout(timer.current);
      if (steamRequest.hasErrored) {
        onError(steamRequest.errorMessage);
      } else if (steamRequest.hasCompleted) {
        toast.success(t('Steam request successful'));
        done();
      }
    }
  }, [steamRequestId, steamRequest, clearSteamRequest, done]);

  useEffect(() => {
    if (!packageTypes) {
      getAllPackageTypes();
    }
  }, [packageTypes, getAllPackageTypes]);

  return (
    <SlideoutLayout
      title={t('Request on Steam')}
      cancel={cancel}
      done={done}
      bar={
        !loading && (
          <Button
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
            text={t('Request on Steam')}
          />
        )
      }
    >
      {!loading ? (
        <KwPanel className="mb-4 form-horizontal">
          <FormStatic label={t('Key pool')}>{keyPoolName}</FormStatic>
          <FormStatic label={t('Game')}>{gameName}</FormStatic>
          <FormStatic label={t('SKU')}>{skuName}</FormStatic>
          <FormSelect
            register={register}
            name="packageTypeId"
            label={t('Package type')}
            error={errors.packageTypeId}
            errorMessage={t('Package type is required')}
            required
            autoFocus={true}
            defaultValue={packageTypeId || ''}
          >
            <option value="">{t('--- choose ---')}</option>
            {[...packageTypes]
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
          </FormSelect>
          <FormInput
            register={register}
            name="keyProviderReference"
            label={t('Customer')}
            error={errors.customerName}
            errorMessage={t('Customer is required')}
            required
            autoFocus={true}
            defaultValue={customerName || ''}
          ></FormInput>
          <FormInput
            register={register}
            name="numberOfKeys"
            label={t('Number of keys')}
            error={errors.numberOfKeys}
            errorMessage={t('Number of keys is required')}
            required
            autoFocus={true}
            defaultValue={numberOfKeys || ''}
          ></FormInput>
        </KwPanel>
      ) : (
        <Loading></Loading>
      )}
    </SlideoutLayout>
  );
}

SteamRequest.propTypes = {
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  keyPoolName: PropTypes.string.isRequired,
  gameName: PropTypes.string.isRequired,
  skuName: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  addToGroup: PropTypes.func.isRequired,
  removeFromGroup: PropTypes.func.isRequired,
  steamRequest: PropTypes.object,
  clearSteamRequest: PropTypes.func.isRequired,
  numberOfKeys: PropTypes.number,
  keyWorkflowId: PropTypes.string,
  gameSkuId: PropTypes.string.isRequired,
  keyRequestId: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    steamRequest: state.steamRequest,
    packageTypes: state.packageTypes,
  };
}

const mapDispatchToProps = {
  addToGroup,
  removeFromGroup,
  clearSteamRequest,
  getAllPackageTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(SteamRequest);
