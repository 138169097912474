import React, { useState } from 'react';
import { baseUrl } from '../../../api/imageApi';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

import image_no_media from '../../../content/images/no-media.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';

export default function KeyCodeDetails({
  details,
  packshotError,
  setPackshotError,
}) {
  const [showInstructions, setShowInstruction] = useState(false);
  const [copied, setCopied] = useState(false);
  const copyToClipBoard = () => {
    navigator.clipboard.writeText(details?.keyCode);
    setCopied(true);
  };
  const { t } = useTranslation();
  return (
    <div className="content">
      <h1>{details.game}</h1>
      {!packshotError ? (
        <img
          src={details.packshotUrl}
          referrerPolicy="no-referrer"
          alt={details.game}
          onError={(e) => {
            e.target.onError = null;
            setPackshotError(true);
          }}
        ></img>
      ) : (
        <img src={image_no_media} alt="" />
      )}
      <div className="code-section">
        <span className="key-code">
          {details.keyProviderImageId && (
            <img
              src={`${baseUrl}${details.keyProviderImageId}`}
              className="keyprovider-img"
              alt={details.keyProviderName}
            ></img>
          )}
          {t('{keyProviderName} key code', {
            keyProviderName: details.keyProviderName,
          })}
        </span>
        <div className={`code-input ${copied ? 'green' : ''}`}>
          <div className="code">{details.keyCode}</div>
          <Tippy content={t('Copied')} trigger="click">
            <div
              className={`copy-btn`}
              onClick={copyToClipBoard}
              style={{ cursor: 'pointer' }}
            >
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={icons.REQUEST_AGAIN} />
              </div>
            </div>
          </Tippy>
        </div>
        <p className="instructions-link">
          <Trans
            i18nKey="keyCodeInstructions"
            components={{
              span: (
                <span className="a" onClick={() => setShowInstruction(true)} />
              ),
            }}
          >
            Need <span>instructions</span> on how to redeem your key code?
          </Trans>
        </p>
      </div>
      {showInstructions && (
        <div className="instruction-section">
          <span className="key-code">
            {details.keyProviderImageId && (
              <img
                src={`${baseUrl}${details.keyProviderImageId}`}
                className="keyprovider-img"
                alt={details.keyProviderName}
              ></img>
            )}
            {t('{keyProviderName} key code instructions', {
              keyProviderName: details.keyProviderName,
            })}
          </span>
          <div dangerouslySetInnerHTML={{ __html: details.instructions }} />
        </div>
      )}
      <img
        className="publisher-img"
        src={details.publisherImage}
        alt={`${details.publisherName}`}
        referrerPolicy="no-referrer"
      />
      <div className="publisher-statement">
        <p>{details.publisherStatement}</p>
      </div>
    </div>
  );
}
