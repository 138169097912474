import React from 'react';
import icons from '../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export default function SkuRestrictions({
  isDisallow,
  restrictions,
  render,
  noDataText,
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center me-3">
      {isDisallow && restrictions?.length > 0 ? (
        <FontAwesomeIcon
          icon={icons.DISALLOW}
          className="disallow me-2"
          title={t('Disallowed')}
          fixedWidth
        />
      ) : !isDisallow && restrictions?.length > 0 ? (
        <FontAwesomeIcon
          icon={icons.ALLOW}
          className="allow me-2"
          title={t('Allow only')}
          fixedWidth
        />
      ) : (
        <>{noDataText}</>
      )}
      {restrictions &&
        restrictions.length > 0 &&
        restrictions
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((x, i) => {
            return render({ id: x.id, name: x.name, colour: x.colourHex });
          })}
    </div>
  );
}
