import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddRequester from '../slideout/AddRequester';
import DeleteRequester from '../slideout/DeleteRequester';
import KwPanel from '../../../common/KwPanel';
import { NavLink, Link } from 'react-router-dom';
import Gravatar from 'react-gravatar';
import SlidingPane from 'react-sliding-pane';

import image_no_content from '../../../../content/images/no-content.svg';
import image_request_start from '../../../../content/images/request_icons/request_start.svg';
import icons from '../../../../constants/icons';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function WorkflowWhoCanRequest({ groups, requesters, workflow }) {
  const { t } = useTranslation();
  const [_requesters, setRequesters] = useState();

  useEffect(() => {
    setRequesters(requesters);
  }, [requesters]);

  const [addRequesterSlideoutOpen, setAddRequesterSlideoutOpen] =
    useState(false);
  const addRequester = (e) => {
    e.cancelBubble = true;
    setAddRequesterSlideoutOpen(true);
    return false;
  };

  const [removeRequesterSlideoutOpen, setRemoveRequesterSlideoutOpen] =
    useState(false);
  const [removeRequesterSlideoutItem, setRemoveRequesterSlideoutItem] =
    useState();
  const removeRequesterGroup = (e, userProfileGroupId) => {
    e.cancelBubble = true;
    setRemoveRequesterSlideoutOpen(true);
    setRemoveRequesterSlideoutItem(userProfileGroupId);
    return false;
  };

  const [removeRequesterUserSlideoutOpen, setRemoveRequesterUserSlideoutOpen] =
    useState(false);
  const [removeRequesterUserSlideoutItem, setRemoveRequesterUserSlideoutItem] =
    useState();
  const removeRequesterUser = (e, userProfileId) => {
    e.cancelBubble = true;
    setRemoveRequesterUserSlideoutOpen(true);
    setRemoveRequesterUserSlideoutItem(userProfileId);
    return false;
  };

  const [
    removeRequesterCompanySlideoutOpen,
    setRemoveRequesterCompanySlideoutOpen,
  ] = useState(false);
  const [
    removeRequesterCompanySlideoutItem,
    setRemoveRequesterCompanySlideoutItem,
  ] = useState();
  const removeRequesterCompany = (e, companyId) => {
    e.cancelBubble = true;
    setRemoveRequesterCompanySlideoutOpen(true);
    setRemoveRequesterCompanySlideoutItem(companyId);
    return false;
  };

  const GroupTag = ({ requester }) => (
    <div className="tag" key={requester.userProfileGroupId}>
      <NavLink
        to={`/settings/user-profile-group/${requester.userProfileGroupId}`}
        title={t('Click to view group details')}
      >
        <span className="type">{t('Group')}</span> :{' '}
        <span>{requester.groupName}</span>
      </NavLink>
      <span
        className="ms-3 a"
        onClick={(e) => removeRequesterGroup(e, requester.userProfileGroupId)}
        title={t('Remove')}
      >
        <FontAwesomeIcon icon={icons.DELETE_TAG} />
      </span>
    </div>
  );

  const UserTag = ({ requester }) => (
    <div className="tag pink" key={requester.userProfileId}>
      <NavLink
        to={`/user/${requester.userProfileId}`}
        title={t('Click to view user details')}
      >
        <Gravatar
          email={requester?.username}
          size={16}
          rating="pg"
          default="retro"
          className="img-circle me-3"
        />
        <span>{requester.fullName}</span>
      </NavLink>
      <span
        className="ms-3 a"
        onClick={(e) => removeRequesterUser(e, requester.userProfileId)}
        title={t('Remove')}
      >
        <FontAwesomeIcon icon={icons.DELETE_TAG} />
      </span>
    </div>
  );

  const CompanyTag = ({ requester }) => (
    <div className="tag aqua" key={requester.companyId}>
      <NavLink
        to={`/company/${requester.companyId}`}
        title={t('Click to view company details')}
      >
        <span className="type">{t('Company')}</span> :{' '}
        <span>{requester.companyName}</span>
      </NavLink>
      <span
        className="ms-3 a"
        onClick={(e) => removeRequesterCompany(e, requester.companyId)}
        title={t('Remove')}
      >
        <FontAwesomeIcon icon={icons.DELETE_TAG} />
      </span>
    </div>
  );
  return (
    <>
      <KwPanel className="mb-4">
        <header className="mb-2">
          <h2 className="no-margin">
            <span className="workflow-icon">
              <img
                src={image_request_start}
                alt={t('Trophy')}
                width="32"
                height="32"
              />
            </span>
            {t('Who can request?')}
          </h2>
          <div className="float-right">
            <Link
              to={`/reports/workflows-requesters?f_keyWorkflowName=${workflow.keyWorkflowName}`}
              className="btn btn-default"
            >
              {t('View all requesters')}
            </Link>
            <Button
              className="btn btn-default ms-2"
              onClick={addRequester}
              text={t('Add')}
            />
          </div>
        </header>
        <div>
          {_requesters && _requesters.length > 0 ? (
            <div className="tags">
              {_requesters.map((x, i) => (
                <span key={i}>
                  {x.userProfileGroupId ? (
                    <GroupTag requester={x} />
                  ) : x.userProfileId ? (
                    <UserTag requester={x} />
                  ) : x.companyId ? (
                    <CompanyTag requester={x} />
                  ) : (
                    ''
                  )}
                </span>
              ))}
            </div>
          ) : (
            <div
              className="alert alert-info text-center"
              style={{ padding: '30px 15px' }}
            >
              <img
                src={image_no_content}
                alt={t('No requesters added')}
                className="space-bottom-small"
              />
              <p>
                {t(
                  'There are no users associated with this workflow. Add a requester to make this available to users.'
                )}
              </p>
            </div>
          )}
        </div>
      </KwPanel>

      <SlidingPane
        isOpen={addRequesterSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setAddRequesterSlideoutOpen(false)}
      >
        <AddRequester
          workflowId={workflow.id}
          groups={groups}
          cancel={() => setAddRequesterSlideoutOpen(false)}
          done={(d) => {
            setAddRequesterSlideoutOpen(false);
            setRequesters([..._requesters, d]);
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={removeRequesterSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRemoveRequesterSlideoutOpen(false)}
      >
        <DeleteRequester
          workflowId={workflow.id}
          userProfileGroupId={removeRequesterSlideoutItem}
          cancel={() => setRemoveRequesterSlideoutOpen(false)}
          done={(d) => {
            if (!_requesters || !Array.isArray(_requesters)) {
              return;
            }

            setRemoveRequesterSlideoutOpen(false);
            setRequesters([
              ..._requesters.filter(
                (r) => r.userProfileGroupId !== removeRequesterSlideoutItem
              ),
            ]);
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={removeRequesterUserSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRemoveRequesterUserSlideoutOpen(false)}
      >
        <DeleteRequester
          workflowId={workflow.id}
          userProfileId={removeRequesterUserSlideoutItem}
          cancel={() => setRemoveRequesterUserSlideoutOpen(false)}
          done={(d) => {
            if (!_requesters || !Array.isArray(_requesters)) {
              return;
            }

            setRemoveRequesterUserSlideoutOpen(false);
            setRequesters([
              ..._requesters.filter(
                (r) => r.userProfileId !== removeRequesterUserSlideoutItem
              ),
            ]);
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={removeRequesterCompanySlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRemoveRequesterCompanySlideoutOpen(false)}
      >
        <DeleteRequester
          workflowId={workflow.id}
          companyId={removeRequesterCompanySlideoutItem}
          cancel={() => setRemoveRequesterCompanySlideoutOpen(false)}
          done={(d) => {
            if (!_requesters || !Array.isArray(_requesters)) {
              return;
            }

            setRemoveRequesterCompanySlideoutOpen(false);
            setRequesters([
              ..._requesters.filter(
                (r) => r.companyId !== removeRequesterCompanySlideoutItem
              ),
            ]);
          }}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowWhoCanRequest);
