import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import {
  getBasket,
  deleteRequestGroup,
  deleteRequest,
  submitRequestGroup,
  downloadAccountDetailsTemplate,
  downloadAccountDetails,
} from '../../../actions/requestActions';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import Loading from '../../common/Loading';
import { toast } from 'react-toastify';
import CompleteForm from './CompleteForm';
import UploadAccountDetails from './UploadAccountDetails';
import RequestKeys from '../../requests/slideout/RequestKeys';

import image_basket from '../../../content/images/basket.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Button from '../../common/Button';
import { useTranslation, Trans } from 'react-i18next';

function Complete({
  basket,
  getBasket,
  cancel,
  done,
  deleteRequestGroup,
  deleteRequest,
  submitRequestGroup,
  currentUser,
}) {
  const { t } = useTranslation();
  const [uploadAccountDetailsOpen, setUploadAccountDetailsOpen] =
    useState(false);
  const [uploadAccountDetail, setUploadAccountDetail] = useState();
  const [showRemove, setShowRemove] = useState(false);
  const [requestKeysSlideoutOpen, setRequestKeysSlideoutOpen] = useState(false);
  const [submittingRequestsSlideoutOpen, setSubmittingRequestsSlideoutOpen] =
    useState(false);

  const toggleShowRemove = (id) => {
    setShowRemove(!showRemove);
  };

  const handleRemoveAll = (id) => {
    deleteRequestGroup(id)
      .then(() => {
        toast.success(t('Successfully removed'));
      })
      .catch(() => {
        toast.error(t('Failed to remove all'));
      });
  };

  const handleRemove = (requestGroupId, id) => {
    deleteRequest(requestGroupId, id)
      .then(() => {
        toast.success(t('Successfully removed'));
      })
      .catch(() => {
        toast.error(t('Failed to remove'));
      });
  };

  const onSubmit = (id, record) => {
    submitRequestGroup(id, record)
      .then(() => {})
      .catch((e) => {
        toast.error(e.message || t('Failed to submit'));
      });
  };

  const handleDownloadAccountDetailsTemplate = () => {
    downloadAccountDetailsTemplate().catch(() => {
      toast.error(t('Failed to download account details template'));
    });
  };

  const handleUploadAccountDetails = (id, numberOfKeys) => {
    setUploadAccountDetail({ keyRequestId: id, numberOfKeys });
    setUploadAccountDetailsOpen(true);
  };

  useEffect(() => {
    if (!basket) {
      getBasket();
    }
  }, [basket, getBasket]);

  if (basket) {
    return (
      <>
        <SlideoutLayout
          title={t('Confirm request')}
          cancel={cancel}
          done={done}
          bar={
            <>
              <div className="flex justify-content-between w-100">
                <button
                  disabled={!basket || basket.submittingCount === 0}
                  className={
                    basket && basket.submittingCount > 0
                      ? 'btn btn-basket me-2'
                      : 'btn btn-default btn-outline me-2'
                  }
                  onClick={() => {
                    if (basket && basket.submittingCount > 0) {
                      setSubmittingRequestsSlideoutOpen(true);
                    }
                  }}
                >
                  <div>
                    {basket.submittingCount > 1 ? (
                      <Trans
                        i18nKey="submittingCount"
                        components={{ bold: <b /> }}
                        values={{ submittingCount: basket.submittingCount }}
                      >
                        You have <bold>{basket.submittingCount}</bold> requests
                        being processed.
                      </Trans>
                    ) : (
                      <Trans
                        i18nKey="oneRequestBeingProcessed"
                        components={{ bold: <strong /> }}
                      >
                        You have <bold>1</bold> request being processed.
                      </Trans>
                    )}
                  </div>
                </button>
              </div>
              {basket.data && basket.data.length > 0 && (
                <Button
                  className={`btn ${
                    showRemove ? 'btn-primary' : 'btn-default btn-outline'
                  } me-2`}
                  onClick={toggleShowRemove}
                  text={showRemove ? t('Continue') : t('Remove items')}
                />
              )}
              {showRemove ? (
                <Button
                  isDisabled={true}
                  className="btn btn-default me-2"
                  text={t('Add bulk request')}
                />
              ) : (
                <Link
                  to={'/requests/bulk'}
                  className="btn btn-default me-2"
                  disabled={showRemove}
                  onClick={() => cancel()}
                >
                  {t('Add bulk request')}
                </Link>
              )}
              <Button
                isDisabled={showRemove}
                className="btn btn-default"
                onClick={() => setRequestKeysSlideoutOpen(true)}
                text={t('Add request')}
              />
            </>
          }
        >
          {basket && basket.basketCount > 0 ? (
            basket.data
              .filter((x) => !x.submitting)
              .map((x) => (
                <CompleteForm
                  key={x.id}
                  requestGroup={x}
                  handleRemoveAll={handleRemoveAll}
                  handleRemove={handleRemove}
                  showRemove={showRemove}
                  downloadAccountDetailsTemplate={
                    handleDownloadAccountDetailsTemplate
                  }
                  handleUploadAccountDetails={handleUploadAccountDetails}
                  onSubmit={onSubmit}
                  disabled={x.submitting}
                  currentUser={currentUser}
                  done={done}
                ></CompleteForm>
              ))
          ) : (
            <div className="no-data text-center">
              <img
                src={image_basket}
                alt={t('Empty basket')}
                className="space-bottom"
              />
              <p>{t('Your basket is currently empty.')}</p>
              <Button
                onClick={() => setRequestKeysSlideoutOpen(true)}
                className="btn btn-primary"
                text={t('Add request')}
              />
            </div>
          )}
        </SlideoutLayout>
        <SlidingPane
          isOpen={uploadAccountDetailsOpen}
          hideHeader={true}
          from="right"
          className="large-side-panel"
          onRequestClose={() => setUploadAccountDetailsOpen(false)}
        >
          <UploadAccountDetails
            id={uploadAccountDetail?.keyRequestId}
            numberOfKeys={uploadAccountDetail?.numberOfKeys}
            done={(d) => {
              setUploadAccountDetailsOpen(false);
            }}
            cancel={() => setUploadAccountDetailsOpen(false)}
            downloadAccountDetailsTemplate={downloadAccountDetailsTemplate}
            downloadAccountDetails={() =>
              downloadAccountDetails(uploadAccountDetail?.keyRequestId)
            }
          />
        </SlidingPane>
        <SlidingPane
          isOpen={requestKeysSlideoutOpen}
          hideHeader={true}
          from="right"
          className="small-side-panel"
          onRequestClose={() => setRequestKeysSlideoutOpen(false)}
        >
          <RequestKeys
            done={() => {
              setRequestKeysSlideoutOpen(false);
            }}
            cancel={() => setRequestKeysSlideoutOpen(false)}
          />
        </SlidingPane>
        <SlidingPane
          isOpen={submittingRequestsSlideoutOpen}
          hideHeader={true}
          from="right"
          className="xlarge-side-panel"
          onRequestClose={() => setSubmittingRequestsSlideoutOpen(false)}
        >
          <SlideoutLayout
            title={t('Processing...')}
            cancel={() => setSubmittingRequestsSlideoutOpen(false)}
            done={() => setSubmittingRequestsSlideoutOpen(false)}
          >
            {basket.data
              .filter((x) => x.submitting)
              .map((x) => (
                <CompleteForm
                  key={x.id}
                  requestGroup={x}
                  disabled={true}
                  hideConfirm={true}
                  done={done}
                ></CompleteForm>
              ))}
          </SlideoutLayout>
        </SlidingPane>
      </>
    );
  } else {
    return <Loading></Loading>;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    basket: state.basket,
    cancel: ownProps.cancel,
    done: ownProps.done,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  getBasket,
  deleteRequestGroup,
  deleteRequest,
  submitRequestGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Complete);
