import React from 'react';
import { saveKeyProviderRequestInformation } from '../../../../actions/keyProviderActions';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import KeyProviderRequestInformationForm from '../components/KeyProviderRequestInformationForm';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';

function EditRequestInformation({
  id,
  done,
  requestInformation,
  internal,
  cancel,
}) {
  const { t } = useTranslation();
  const { register, handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    // update the correct values
    const updatedRequestInformation = requestInformation.map((item) => {
      const newItem = { ...item };
      if (internal) {
        newItem.internalSite = data[item.id];
      } else {
        newItem.externalSite = data[item.id];
      }
      return newItem;
    });

    saveKeyProviderRequestInformation(id, updatedRequestInformation)
      .then(() => {
        toast.success(t('Request information saved'));
        done(id);
      })
      .catch(() => {
        toast.error(t('Failed to save request information'));
      });
  };

  return (
    <SlideoutLayout
      title={
        internal
          ? t('Key provider internal request information')
          : t('Key provider external request information')
      }
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="submit"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <KeyProviderRequestInformationForm
        requestInformation={requestInformation}
        register={register}
        control={control}
        internal={internal}
      ></KeyProviderRequestInformationForm>
    </SlideoutLayout>
  );
}
EditRequestInformation.propTypes = {
  id: PropTypes.string.isRequired,
  internal: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  requestInformation: PropTypes.array.isRequired,
};

export default EditRequestInformation;
