import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';

import { saveWorkflowSteps } from '../../../../actions/workflowActions';
import { getUserProfileGroups } from '../../../../actions/userProfileGroupActions';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import StepsForm from '../components/StepsForm';
import { useTranslation } from 'react-i18next';

function EditSteps({ done, id, cancel, workflow, workflowSteps }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const [updatedSteps, setUpdatedSteps] = useState();

  const handleStepChange = (steps) => {
    setUpdatedSteps(steps);
  };

  const [userProfileGroups, setUserProfileGroups] = useState();

  useEffect(() => {
    if (id) {
      getUserProfileGroups()
        .then((d) => {
          setUserProfileGroups(d.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [id]);

  const onSubmit = (data) => {
    if (updatedSteps) {
      saveWorkflowSteps(id, updatedSteps)
        .then((savedSteps) => {
          toast.success(t('Workflow steps saved'));
          done(savedSteps);
        })
        .catch(() => {
          toast.error(t('Failed to save workflow steps'));
        });
    } else {
      cancel();
    }
  };

  return userProfileGroups && workflowSteps ? (
    <SlideoutLayout
      title={t('Workflow steps')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <StepsForm
        register={register}
        errors={errors}
        control={control}
        workflowSteps={workflowSteps}
        userProfileGroups={userProfileGroups}
        onStepChange={handleStepChange}
        keyWorkflowName={workflow.keyWorkflowName}
        cancel={cancel}
      />
    </SlideoutLayout>
  ) : (
    ''
  );
}

EditSteps.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditSteps);
