import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  exportAssignedRequestData,
  exportRequestData,
  getRequestById,
  loadRequests,
} from '../../../../actions/requestActions';
import { getTableSettingsDefaults, Table } from '../../../common/Table';
import SlideoutRequestDetail from '../../slideout/RequestDetails';
import SlideoutCopyRequest from '../../slideout/CopyRequest';

import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import RequestDetailsSignatures from '../../slideout/RequestDetailsSignatures';
import useTableSettings from '../../../../hooks/useTableSettings';
import SlidingPane from 'react-sliding-pane';
import RequestsColumnsConfig from './RequestsColumnsConfig';
import { useTranslation } from 'react-i18next';

const allColumns = [
  'reference',
  'games',
  'platform',
  'companyName',
  'requestedByCompanyName',
  'currentRequestValue',
  'numberOfKeys',
  'keyWorkflowName',
  'requestedByFullName',
  'requestedDate',
  'requiredByDate',
  'requestCompletionDate',
  'currentUserProfileGroupName',
  'requestStatus',
  'assignedToUserProfileFullName',
];

const suffixColumns = ['checkbox'];
function getDefaultVisibleColumns() {
  return allColumns.filter(
    (x) => x !== 'requestedByCompanyName' && x !== 'requestCompletionDate'
  );
}

function RequestsList({
  location,
  reload,
  assignedToMe,
  handleDownloadKeys,
  handleUploadKeys,
  handleBulkStatusChange,
  handleBulkReassign,
  handleBulkSteamRequest,
  isProcessing,
  history,
  itemsChecked,
  setItemsChecked,
  currentUser,
  handleBulkActions,
  handleReturnKeys,
  bulkActionTitle,
  tableViewSwitcher,
  setCompleteRequestSlideoutOpen,
  setBreadcrumb,
  scope,
  settingsKey,
  title,
}) {
  const { t } = useTranslation();
  const [requests, setRequests] = useState();
  const params = new URLSearchParams(location.search);
  const defaultVisibleColumns = getDefaultVisibleColumns();

  const {
    tableSettings,
    setTableSettings,
    filterChange,
    isLoading,
    filters,
    filtersChange,
  } = useTableSettings(
    settingsKey || 'requestlist-settings',
    getTableSettingsDefaults({
      defaultSort: 'reference',
      defaultVisibleColumns,
    }),
    { assignedToMe: assignedToMe || false },
    loadRequests,
    setRequests,
    reload,
    t('Failed to load requests'),
    location,
    scope
  );

  // slideout panel
  const [detailsSlideoutOpen, setDetailsSlideoutOpen] = useState(false);
  const [detailsSlideoutItem, setDetailsSlideoutItem] = useState();
  const [signaturesSlideoutOpen, setSignaturesSlideoutOpen] = useState(false);
  const [signaturesSlideoutItem, setSignaturesSlideoutItem] = useState();
  const [copyRequestSlideoutOpen, setCopyRequestSlideoutOpen] = useState(false);
  const [copyRequestSlideoutRequests, setCopyRequestSlideoutRequests] =
    useState();
  const [copyRequestSlideoutRequestGroup, setCopyRequestSlideoutRequestGroup] =
    useState();

  const handleCopyRequest = (e, request) => {
    e.cancelBubble = true;
    setCopyRequestSlideoutOpen(true);
    getRequestById(request.requestGroupId).then((d) => {
      setCopyRequestSlideoutRequests([request]);
      setCopyRequestSlideoutRequestGroup(d);
    });
  };

  const handleShowDetails = (e, request) => {
    e.cancelBubble = true;
    setDetailsSlideoutItem(request);
    setDetailsSlideoutOpen(true);
  };

  let handleShowSignatures = (e, requestStep) => {
    setSignaturesSlideoutItem(requestStep);
    setSignaturesSlideoutOpen(true);
  };

  // The base config of the columns, this will be filtered based on the visible (above)
  const columnsConfig = RequestsColumnsConfig({
    params,
    requests,
    currentUser,
    filterChange,
    filtersChange,
    itemsChecked,
    setItemsChecked,
    handleShowDetails,
    handleDownloadKeys,
    handleUploadKeys,
    handleBulkStatusChange,
    handleBulkReassign,
    handleBulkSteamRequest,
    handleCopyRequest,
    handleReturnKeys,
    isProcessing,
  });

  const shouldRowUpload = (row) =>
    row.allowUploadKeys && !row.isUpdateInProgress && !isProcessing;

  const handleRowFileUpload = (row, file) => {
    handleUploadKeys(null, { ...row, file });
  };

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;

  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return requests?.data ? (
    <>
      <KwPanel className="mb-4">
        {title && (
          <header className="mb-2">
            <h2 className="no-margin">{title}</h2>
          </header>
        )}
        <Table
          columns={columns}
          suffixColumns={suffixColumnsWithConfig}
          allColumns={allColumns}
          defaultVisibleColumns={defaultVisibleColumns}
          rows={requests.data.map((x) => {
            return { Key: x.id, ...x };
          })}
          totalRowCount={requests.totalRowCount}
          filteredRowCount={requests.filteredRowCount}
          scopes={requests.scopes}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          isLoading={isLoading}
          filters={filters}
          filterChange={filterChange}
          className="td-align-top"
          bulkActionTitle={bulkActionTitle}
          itemsChecked={itemsChecked}
          setBulkActionsSlideoutOpen={handleBulkActions}
          tableActionBarButtons={tableViewSwitcher}
          exportData={
            assignedToMe ? exportAssignedRequestData : exportRequestData
          }
          location={location}
          setBreadcrumb={setBreadcrumb}
          hideScopeTags={scope}
          shouldRowUpload={shouldRowUpload}
          handleRowFileUpload={handleRowFileUpload}
        />
      </KwPanel>

      <SlidingPane
        isOpen={detailsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDetailsSlideoutOpen(false)}
      >
        <>
          {detailsSlideoutItem && (
            <SlideoutRequestDetail
              requestGroupId={detailsSlideoutItem.requestGroupId}
              requestId={detailsSlideoutItem.id}
              history={history}
              done={(d) => {
                setDetailsSlideoutOpen(false);
              }}
              cancel={() => setDetailsSlideoutOpen(false)}
              onShowSignatures={handleShowSignatures}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={signaturesSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setSignaturesSlideoutOpen(false)}
      >
        <>
          {signaturesSlideoutItem && (
            <RequestDetailsSignatures
              done={(d) => {
                setSignaturesSlideoutOpen(false);
              }}
              cancel={() => setSignaturesSlideoutOpen(false)}
              requestStep={signaturesSlideoutItem}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={copyRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="xlarge-side-panel"
        onRequestClose={() => setCopyRequestSlideoutOpen(false)}
      >
        <>
          {copyRequestSlideoutRequestGroup && copyRequestSlideoutRequests ? (
            <SlideoutCopyRequest
              done={(d) => {
                setCopyRequestSlideoutOpen(false);
              }}
              cancel={() => setCopyRequestSlideoutOpen(false)}
              requestGroup={copyRequestSlideoutRequestGroup}
              requests={copyRequestSlideoutRequests}
              history={history}
              currentUser={currentUser}
              setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
            />
          ) : (
            <Loading></Loading>
          )}
        </>
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    handleDownloadKeys: ownProps.handleDownloadKeys,
    handleUploadKeys: ownProps.handleUploadKeys,
    isProcessing: ownProps.isProcessing,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

RequestsList.propTypes = {
  location: PropTypes.object.isRequired,
  reload: PropTypes.any,
  assignedToMe: PropTypes.bool,
  handleDownloadKeys: PropTypes.func.isRequired,
  handleUploadKeys: PropTypes.func.isRequired,
  handleBulkStatusChange: PropTypes.func.isRequired,
  handleBulkReassign: PropTypes.func.isRequired,
  handleBulkSteamRequest: PropTypes.func.isRequired,
  handleBulkActions: PropTypes.func.isRequired,
  handleReturnKeys: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  itemsChecked: PropTypes.array.isRequired,
  setItemsChecked: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  bulkActionTitle: PropTypes.string.isRequired,
  tableViewSwitcher: PropTypes.object.isRequired,
  setCompleteRequestSlideoutOpen: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func,
  scope: PropTypes.object,
  settingsKey: PropTypes.string,
  title: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsList);
