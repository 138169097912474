import React, { useState } from 'react';
import {
  loadPlatforms,
  exportPlatformData,
} from '../../../../actions/platformActions';
import { NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { CommaNumber_NoDecimal, hexToRgbA } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import PlatformLink from '../../../common/links/PlatformLink';
import { useTranslation } from 'react-i18next';

function PlatformList({ location, reload }) {
  const { t } = useTranslation();
  const allColumns = ['name', 'gameCount', 'requestCount'];
  const defaultVisibleColumns = allColumns;
  const [platforms, setPlatforms] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'platformlist-settings',
      getTableSettingsDefaults({ defaultSort: 'name', defaultVisibleColumns }),
      null,
      loadPlatforms,
      setPlatforms,
      reload,
      'Failed to load platforms',
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Name'),
      className: 'flush',
      width: '40%',
      Render: (platform) => (
        <div
          className="p-2 d-flex align-items-center company-td"
          style={
            platform.colourHex
              ? {
                  borderLeft: `3px solid ${platform.colourHex}`,
                }
              : null
          }
        >
          <PlatformLink
            id={platform.id}
            name={platform.name}
            imageId={platform.imageId}
            imageSize={32}
          />
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'gameCount',
      Label: t('Number of games'),
      width: '30%',
      className: 'text-right num no-wrap',
      //Render: (platform) => CommaNumber_NoDecimal(platform.gameCount),
      Render: (platform) => (
        <NavLink to={`/games?s_platform=${platform.id}`}>
          {CommaNumber_NoDecimal(platform.gameCount)}
        </NavLink>
      ),
    },
    {
      Key: 'requestCount',
      Label: t('Number of requests'),
      width: '30%',
      className: 'text-right num no-wrap',
      //Render: (platform) => CommaNumber_NoDecimal(platform.requestCount),
      Render: (platform) => (
        <NavLink to={`/requests/all?s_platform=${platform.id}`}>
          {CommaNumber_NoDecimal(platform.requestCount)}
        </NavLink>
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return platforms ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={platforms.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={platforms.totalRowCount}
        filteredRowCount={platforms.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        trStyle={(row) => {
          return {
            backgroundColor: `${hexToRgbA(row.colourHex, 0.0375)}`,
          };
        }}
        exportData={exportPlatformData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default PlatformList;
