import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { hasPermission } from '../../auth/authUtils';
import { bestContrast } from '../../../util/formatter';

function LinkBadge({
  url,
  name,
  title,
  noLinkTitle,
  colour,
  permissionType,
  currentUser,
}) {
  const _colour = colour || '#171717';
  const textClass = _colour
    ? bestContrast(_colour, 'text-light', 'text-dark')
    : 'text-light';

  let notClickable = () => (
    <div
      className={`badge me-2 ${textClass}`}
      style={{ backgroundColor: _colour }}
      title={noLinkTitle}
    >
      {name}
    </div>
  );

  let clickable = () => (
    <div
      className={`badge me-2 ${textClass}`}
      style={
        _colour === 'transparent'
          ? { border: 'solid 1px #545050', backgroundColor: _colour }
          : { backgroundColor: _colour }
      }
    >
      <NavLink to={url} title={title} className={`${textClass}`}>
        {name}
      </NavLink>
    </div>
  );

  return (
    <>
      {url && name
        ? hasPermission(currentUser, permissionType)
          ? clickable()
          : notClickable()
        : name
          ? notClickable()
          : '-'}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LinkBadge);
