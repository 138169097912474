import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import UsersList from './components/UsersList';
import ActionBar from '../../layout/ActionBar';
import { getAllCompanyTypes } from '../../../actions/companyTypeActions';
import AddUser from './slideout/AddUser';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function Users({ location, companyTypes, getAllCompanyTypes, connectedUsers }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [inviteSlideoutOpen, setInviteSlideoutOpen] = useState(false);

  //companies
  useEffect(() => {
    if (!companyTypes) {
      getAllCompanyTypes().catch((e) => {});
    }
  }, [companyTypes, getAllCompanyTypes]);

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={() => setInviteSlideoutOpen(true)}
          text={t('Invite user')}
        />
      </ActionBar>
      <UsersList
        location={location}
        reload={reload}
        companyTypes={companyTypes}
        connectedUsers={connectedUsers}
      />

      <SlidingPane
        isOpen={inviteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setInviteSlideoutOpen(false)}
      >
        <AddUser
          cancel={() => setInviteSlideoutOpen(false)}
          done={(d) => {
            setInviteSlideoutOpen(false);
            setReload(new Date());
          }}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    companyTypes: state.companyTypes,
    connectedUsers: state.connectedUsers,
  };
}

const mapDispatchToProps = { getAllCompanyTypes };

export default connect(mapStateToProps, mapDispatchToProps)(Users);
