import React from 'react';
import LeftNavWrapper from '../menu-components/LeftNavWrapper';
import LeftMenuItem from '../menu-components/LeftMenuItem';
import userTypes from '../../../constants/userTypes';
import NavColours from '../../../constants/NavColours';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';

function LeftMenu_Home({ currentUser, wrapperClassName }) {
  const { t } = useTranslation();
  return (
    <LeftNavWrapper className={wrapperClassName}>
      <LeftMenuItem
        url="/requests/all"
        icon={icons.REQUESTS}
        colour={NavColours.NAV_COLOUR_1}
        title={t('All requests')}
        currentUser={currentUser}
      />
      <LeftMenuItem
        url="/requests/assigned-to-me"
        icon={icons.USER}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Assigned to me')}
        currentUser={currentUser}
        allowedUserTypes={[userTypes.APPROVER, userTypes.UPLOADER]}
      />
      <LeftMenuItem
        url="/requests/made-by-me"
        icon={icons.USER}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Made by me')}
        currentUser={currentUser}
        allowedUserTypes={[userTypes.REQUESTER]}
      />
      <LeftMenuItem
        url="/requests/approve"
        icon={icons.APPROVE_REQUESTS}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Approve requests')}
        currentUser={currentUser}
        allowedUserTypes={[userTypes.APPROVER]}
      />
      <LeftMenuItem
        url="/requests/bulk"
        icon={icons.BULK_REQUEST}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Bulk request')}
        currentUser={currentUser}
        allowedUserTypes={[userTypes.REQUESTER]}
      />
    </LeftNavWrapper>
  );
}

export default LeftMenu_Home;
