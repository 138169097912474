import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import KwPanel from '../../common/KwPanel';
import { useDropzone } from 'react-dropzone';
import {
  downloadGames,
  uploadGames,
  downloadTemplate,
  clearUploadResults,
} from '../../../actions/gameActions';
import Loading from '../../common/Loading';
import './UploadGames.css';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import image_xlsx_file from '../../../content/images/xlsx-file.svg';
import image_xlsx_file_with_content from '../../../content/images/xlsx-file-with-content.svg';
import image_upload_arrow from '../../../content/images/upload-arrow.svg';
import Button from '../../common/Button';
import ViewLink from './ViewLink';
import SuccessIcon from './SuccessIcon';

function UploadGames({ gameUploadResults, clearUploadResults, setClasses }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [processingStarted, setProcessingStarted] = useState(false);
  useEffect(() => {
    setClasses('h-100');
  }, [setClasses]);

  const onDrop = (droppedFiles) => {
    clearUploadResults();
    setLoading(true);
    uploadGames({ file: droppedFiles[0] })
      .then(() => {
        setLoading(false);
        toast.success(t('Game upload started'));
        setProcessingStarted(true);
      })
      .catch(() => {
        setLoading(false);
        toast.error(t('Failed to upload games'));
      });
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });
  const classeNames = useMemo(
    () =>
      `dropzone game-upload-action-panel ${isDragActive ? 'active' : ''} ${
        isDragAccept ? 'accept positive-colour1' : ''
      } ${isDragReject ? 'reject' : ''}`,
    [isDragActive, isDragReject, isDragAccept]
  );

  const downloadBlankTemplate = () => {
    downloadTemplate().catch(() => {
      toast.error(t('Failed to download template'));
    });
  };
  const downloadYourGames = () => {
    downloadGames().catch(() => {
      toast.error(t('Failed to download games'));
    });
  };

  return (
    <>
      {!loading ? (
        <KwPanel className="mb-4">
          <div className="game-upload-wrapper">
            <div className="col-wrapper">
              <KwPanel className="mb-4 game-upload-panel">
                <header className="mb-2">
                  <h2 className="no-margin">
                    {t('Download blank xlsx template')}
                  </h2>
                </header>
                <div className="content clearfix">
                  <div className="float-left">
                    <img
                      src={image_xlsx_file}
                      alt={t('Download blank template')}
                    />
                  </div>
                  <div style={{ paddingLeft: '85px' }}>
                    <p className="space-bottom-x2">
                      {t(
                        'Click the link below to download a blank XLSX game template file. Complete and upload the file to Alaska.'
                      )}
                    </p>
                    <Button
                      className="btn btn-primary"
                      onClick={downloadBlankTemplate}
                      text={t('Download blank template')}
                    />
                  </div>
                </div>
              </KwPanel>
              <KwPanel className="mb-4 game-upload-panel">
                <header className="mb-2">
                  <h2 className="no-margin">{t('Download your games')}</h2>
                </header>
                <div className="content clearfix">
                  <div className="float-left">
                    <img
                      src={image_xlsx_file_with_content}
                      alt={t('Download your games')}
                    />
                  </div>
                  <div style={{ paddingLeft: '85px' }}>
                    <p className="space-bottom-x2">
                      {t(
                        'Click the link below to download a XLSX file of containing all of your current games. Edit and upload to Alaska.'
                      )}
                    </p>
                    <Button
                      className="btn btn-primary"
                      onClick={downloadYourGames}
                      text={t('Download your games')}
                    />
                  </div>
                </div>
              </KwPanel>
            </div>
            <div className="game-upload-action-panel game-upload-divider ps-lg-5 pe-lg-5">
              <img
                src={image_upload_arrow}
                alt={t('Arrow')}
                width="100"
                height="100"
                className="space-bottom"
              />
            </div>
            <div {...getRootProps({ className: classeNames })}>
              <input {...getInputProps()} />
              <img
                src={image_xlsx_file_with_content}
                alt={t('Upload  your games')}
                className="space-bottom"
              />
              <p>
                {t('Drag & drop .xlsx files here, or click to select files')}
              </p>
            </div>
          </div>
        </KwPanel>
      ) : (
        <Loading></Loading>
      )}

      {processingStarted && (
        <>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow={gameUploadResults?.percentage ?? 0}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${gameUploadResults?.percentage ?? 0}%` }}
            ></div>
          </div>
          <KwPanel className="flex-1 overflow-auto">
            <table className="table dataTable width-100 ">
              <thead>
                <tr>
                  <td style={{ width: '5%' }}></td>
                  <td style={{ width: '5%' }}>{t('Row')}</td>
                  <td style={{ width: '25%' }}>{t('Game')}</td>
                  <td style={{ width: '25%' }}>{t('SKU')}</td>
                  <td style={{ width: '35%' }}>{t('Message')}</td>
                  <td style={{ width: '5%' }}></td>
                </tr>
              </thead>
              <tbody>
                {gameUploadResults?.updates?.map((result) => {
                  return (
                    <tr key={result.row}>
                      <td>
                        <SuccessIcon result={result}></SuccessIcon>
                      </td>
                      <td>{result.row}</td>
                      <td>{result.gameName}</td>
                      <td>{result.skuName}</td>
                      <td>{result.message}</td>
                      <td>
                        <ViewLink result={result}></ViewLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </KwPanel>
        </>
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    gameUploadResults: state.gameUploadResults,
  };
}

const mapDispatchToProps = { clearUploadResults };

export default connect(mapStateToProps, mapDispatchToProps)(UploadGames);
