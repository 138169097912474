import React from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import UsersModifiedByMonthByUserList from './components/month/UsersModifiedByMonthByUserList';
import UsersModifiedByWeekByUserList from './components/week/UsersModifiedByWeekByUserList ';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import ReportTableViewSwitcher from './components/ReportTableViewSwitcher';

function UsersModifiedByUser({ location }) {
  const [groupBy, setGroupBy] = useLocalStorage('report-groupBy', 'month');

  return (
    <>
      <ActionBar></ActionBar>
      {groupBy === 'month' ? (
        <UsersModifiedByMonthByUserList
          location={location}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      ) : (
        <UsersModifiedByWeekByUserList
          location={location}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersModifiedByUser);
