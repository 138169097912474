import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import { useTranslation } from 'react-i18next';

function PackageTypeLink({ id, name, currentUser }) {
  const { t } = useTranslation();
  return (
    <>
      {id && name ? (
        hasPermission(currentUser, permissionTypes.MANAGE_SETTINGS) ? (
          <NavLink
            to={`/settings/package-type/${id}`}
            title={t('View package type')}
          >
            {name}
          </NavLink>
        ) : (
          name
        )
      ) : name ? (
        name
      ) : (
        '-'
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PackageTypeLink);
