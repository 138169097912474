import React from 'react';
import FormGroup from './FormGroup';
const FormCheckBox = ({
  label,
  description,
  register,
  name,
  getValues,
  setValue,
  required,
  checked,
  helpContent,
}) => {
  const handleOnClick = () => {
    const value = getValues(name);
    setValue(name, !value);
  };
  return (
    <FormGroup label={label} helpContent={helpContent}>
      <input
        {...register(name, { required })}
        aria-label={label}
        name={name}
        type="checkbox"
        checked={checked}
        required={required}
      />
      <label
        style={{ borderTop: '0px' }}
        onClick={handleOnClick}
        className={required ? 'required' : ''}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleOnClick(e);
          }
        }}
      >
        <span></span>
        {description || label}
      </label>
    </FormGroup>
  );
};
export default FormCheckBox;
