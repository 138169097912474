import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  loadAccountDetailKeyRequests,
  exportAccountDetailKeyRequests,
} from '../../../actions/accountDetailActions';
import {
  Table,
  getTableSettingsDefaults,
  SimpleTextFilter,
} from '../../common/Table';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import { Link } from 'react-router-dom';
import { Date_AsString } from '../../../util/formatter';
import KebabMenu from '../../common/KebabMenu';
import Button from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import icons from '../../../constants/icons';
import { useTranslation } from 'react-i18next';
function AccountKeysList({
  currentUser,
  location,
  reload,
  tableViewSwitcher,
  itemsChecked,
  setItemsChecked,
  onDisableKeys,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'login',
    'name',
    'emailAddress',
    'game',
    'sku',
    'keyRequestReference',
    'emailSentDate',
    'keyCodeDownloadedDate',
  ];
  const suffixColumns = ['buttons-checkbox'];
  const defaultVisibleColumns = allColumns;
  const [accountDetails, setAccountDetails] = useState();
  const params = new URLSearchParams(location.search);

  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'account-keys-settings',
      getTableSettingsDefaults({
        defaultSort: 'login',
        direction: 'desc',
        defaultVisibleColumns,
      }),
      null,
      loadAccountDetailKeyRequests,
      setAccountDetails,
      reload,
      t('Failed to load account details'),
      location
    );
  // The base config of the columns, this will be filtered based on the visible (above)
  const columnsConfig = [
    {
      Key: 'login',
      Label: t('Login'),
      Render: (item) => (
        <NavLink to={`/keys/account/${item.accountDetailId}`}>
          {item.login}
        </NavLink>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'name',
      Label: t('Name'),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'emailAddress',
      Label: t('Email address'),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'game',
      Label: t('Game'),
      Render: (item) => (
        <>
          <NavLink to={`/sku/${item.skuId}`} className="game-title">
            {item.game}
          </NavLink>
          <br />
          <NavLink to={`/sku/${item.skuId}`}>{item.sku}</NavLink>
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyRequestReference',
      Label: t('Key request'),
      Render: (item) => (
        <Link to={'/request/' + item.keyRequestGroupId}>
          {item.keyRequestReference}
        </Link>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'emailSentDate',
      Label: t('Sent date'),
      Render: (item) => Date_AsString(item.emailSentDate),
    },
    {
      Key: 'keyCodeDownloadedDate',
      Label: t('Downloaded date'),
      Render: (item) => Date_AsString(item.keyCodeDownloadedDate),
    },
    {
      Key: 'buttons-checkbox',
      Label: '',
      className: 'text-right no-navigate',
      Sort: false,
      width: '2%',
      Filter: {
        Render: (col) => (
          <Checkbox
            disabled={!accountDetails}
            checked={false}
            onChange={(checked) =>
              checked
                ? setItemsChecked(accountDetails?.data)
                : setItemsChecked([])
            }
          ></Checkbox>
        ),
      },
      Render: (item) => (
        <div className="d-flex align-items-center justify-content-end">
          <KebabMenu
            take={1}
            className="inline-icons"
            buttons={[
              <Button
                className="btn btn-danger btn-xs-full me-2"
                dropdownclasses="danger"
                onClick={(e) => onDisableKeys([item])}
                icon={icons.DISABLE_KEYS}
                text={t('Disable keys')}
              />,
            ]}
          ></KebabMenu>
          <Checkbox
            className="ms-3"
            checked={itemsChecked && itemsChecked.some((x) => x.id === item.id)}
            onChange={(checked) =>
              checked
                ? setItemsChecked([...itemsChecked, item])
                : setItemsChecked(itemsChecked.filter((x) => x.id !== item.id))
            }
          ></Checkbox>
        </div>
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return accountDetails ? (
    <KwPanel className="mb-4">
      <header>
        <h2>{t('Accounts - Ungrouped')}</h2>
        {accountDetails.data.length > 0 && (
          <Button
            className="btn btn-danger btn-xs-full space-bottom-xs-s me-2"
            onClick={() => onDisableKeys(itemsChecked)}
            icon={icons.DISABLE_KEYS}
            text={t('Disable selected keys')}
            isDisabled={itemsChecked.length === 0}
          />
        )}
      </header>
      <Table
        columns={columns}
        suffixColumns={suffixColumnsWithConfig}
        allColumns={allColumns}
        rows={accountDetails.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={accountDetails.totalRowCount}
        filteredRowCount={accountDetails.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportAccountDetailKeyRequests}
        tableActionBarButtons={tableViewSwitcher}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default AccountKeysList;
