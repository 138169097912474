import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  clearImportTransaction,
  getIngestedTransactionById,
} from '../../actions/keyCodeActions';
import { addToGroup, removeFromGroup } from '../../actions/signalRActions';
import IngestedTransactionDetails from './components/IngestedTransactionDetails';
import KeyProcessProgressBar from './components/KeyProcessProgressBar';
import ActionBar from '../layout/ActionBar';
import RemoveIngestedKeys from './slideout/RemoveIngestedKeys';
import Loading from '../common/Loading';
import { toast } from 'react-toastify';
import SlidingPane from 'react-sliding-pane';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

function IngestedTransaction({
  id,
  history,
  addToGroup,
  removeFromGroup,
  importTransactions,
  clearImportTransaction,
  currentUser,
}) {
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState();
  const [lastUpdate] = useState(new Date());

  const [removeIngestedKeysSlideoutOpen, setRemoveIngestedKeysSlideoutOpen] =
    useState(false);

  useEffect(() => {
    if (id) {
      // get transaction
      getIngestedTransactionById(id)
        .then((d) => {
          setTransaction(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Transaction not found'));
          history.push('/keys/ingested-batches');
        });
    }
  }, [id, history, lastUpdate, t]);

  useEffect(() => {
    if (transaction && transaction.status === 'Pending') {
      addToGroup(`import:${transaction.id}`);
      return () => removeFromGroup(`import:${transaction.id}`);
    }
  }, [transaction, addToGroup, removeFromGroup]);

  useEffect(() => {
    if (
      importTransactions &&
      importTransactions.length > 0 &&
      importTransactions.find((x) => x.id === id)
    ) {
      setTransaction((prevState) => ({
        ...prevState,
        ...importTransactions.find((x) => x.id === id),
      }));
      return () => clearImportTransaction(id);
    }
  }, [importTransactions, id, clearImportTransaction]);

  let getToolTip = () => {
    let tooltip = '';
    if (transaction) {
      if (transaction.requestId != null) {
        tooltip = t('Already assigned to a request');
      } else if (transaction.keysDirty) {
        tooltip = t('Keys have already been allocated');
      } else if (transaction.codesImported !== true) {
        tooltip = t('Keys are being processed and cannot be stopped');
      }
    }
    return tooltip;
  };

  return transaction ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/keys/ingested-batches', label: t('Ingested batches') },
          { label: t('Ingested transaction') },
        ]}
      >
        {!transaction.keysDirty ? (
          <Button
            className="btn btn-primary"
            onClick={() => setRemoveIngestedKeysSlideoutOpen(true)}
            text={t('Remove')}
          />
        ) : (
          <Button
            className="btn btn-primary"
            isDisabled={true}
            title={getToolTip()}
            text={t('Remove')}
          />
        )}
      </ActionBar>
      <IngestedTransactionDetails
        transaction={transaction}
        currentUser={currentUser}
      />
      <KeyProcessProgressBar transaction={transaction} />

      <SlidingPane
        isOpen={removeIngestedKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setRemoveIngestedKeysSlideoutOpen(false)}
      >
        {transaction ? (
          <RemoveIngestedKeys
            id={transaction.id}
            done={(d) => {
              history.push('/keys/ingested-batches');
            }}
            cancel={() => setRemoveIngestedKeysSlideoutOpen(false)}
          />
        ) : (
          <></>
        )}
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    importTransactions: state.importTransactions,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  addToGroup,
  removeFromGroup,
  clearImportTransaction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IngestedTransaction);
