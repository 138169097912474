import React, { useState } from 'react';
import { connect } from 'react-redux';
import { loadEmails, exportEmailData } from '../../../../actions/emailActions';
import View from '../slideout/View';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import Loading from '../../../common/Loading';
import { Date_AsString } from '../../../../util/formatter';

import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import SlidingPane from 'react-sliding-pane';
import YesNoLabel from '../../../common/YesNoLabel';
import { useTranslation } from 'react-i18next';

function EmailList({ location, reload }) {
  const { t } = useTranslation();
  const allColumns = [
    'creationDate',
    'toAddress',
    'subject',
    'viewName',
    'success',
  ];
  const defaultVisibleColumns = allColumns;
  const [emails, setEmails] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'emaillist-settings',
      getTableSettingsDefaults({
        defaultSort: 'creationDate',
        defaultVisibleColumns,
        direction: 'desc',
      }),
      null,
      loadEmails,
      setEmails,
      reload,
      t('Failed to load email logs'),
      location
    );

  const [viewSlideoutOpen, setViewSlideoutOpen] = useState(false);
  const [viewSlideoutItem, setViewSlideoutItem] = useState();
  const view = (e, email) => {
    e.cancelBubble = true;
    setViewSlideoutOpen(true);
    setViewSlideoutItem(email);
    return false;
  };

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'creationDate',
      Label: t('Queued'),
      width: '20%',
      Render: (item) => (
        <span className="a" nodelay="true" onClick={(e) => view(e, item)}>
          {Date_AsString(item.creationDate)}
        </span>
      ),
    },
    {
      Key: 'toAddress',
      Label: t('To address'),
      width: '10%',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'subject',
      Label: t('Subject'),
      width: '40%',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'viewName',
      Label: t('View name'),
      width: '20%',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'success',
      Label: t('Success'),
      width: '10%',
      Render: (email) => <YesNoLabel value={email.success} />,
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return emails ? (
    <>
      <KwPanel className="mb-4">
        <Table
          columns={columns}
          allColumns={allColumns}
          rows={emails.data.map((x) => {
            return { Key: x.id, ...x };
          })}
          totalRowCount={emails.totalRowCount}
          filteredRowCount={emails.filteredRowCount}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          isLoading={isLoading}
          filters={filters}
          filterChange={filterChange}
          exportData={exportEmailData}
        />
      </KwPanel>

      <SlidingPane
        isOpen={viewSlideoutOpen}
        hideHeader={true}
        from="right"
        className="xlarge-side-panel"
        onRequestClose={() => setViewSlideoutOpen(false)}
      >
        <>
          {viewSlideoutItem && (
            <View
              id={viewSlideoutItem.id}
              done={(d) => {
                setViewSlideoutOpen(false);
              }}
              cancel={() => setViewSlideoutOpen(false)}
            />
          )}
        </>
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EmailList);
