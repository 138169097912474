import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import Gravatar from 'react-gravatar';
import { Date_AsString_NoHtml } from '../../../util/formatter';
import Loading from '../../common/Loading';
import { NavLink } from 'react-router-dom';
import UserPopup from '../../popups/user';
import domainObjects from '../domainObjects';
import { useTranslation } from 'react-i18next';

import image_audit_access_granted from '../../../content/images/audit_icons/audit_access-granted.svg';
import image_audit_account_revoked from '../../../content/images/audit_icons/audit_account-revoked.svg';
import image_audit_approved from '../../../content/images/audit_icons/audit_approved.svg';
import image_audit_archived from '../../../content/images/audit_icons/audit_archived.svg';
import image_audit_auto_replenish from '../../../content/images/audit_icons/audit_auto-replenish.svg';
import image_audit_comment from '../../../content/images/audit_icons/audit_comment.svg';
import image_audit_created from '../../../content/images/audit_icons/audit_created.svg';
import image_audit_deactivated from '../../../content/images/audit_icons/audit_deactivated.svg';
import image_audit_declined from '../../../content/images/audit_icons/audit_declined.svg';
import image_audit_deleted from '../../../content/images/audit_icons/audit_deleted.svg';
import image_audit_download from '../../../content/images/audit_icons/audit_download.svg';
import image_audit_edited from '../../../content/images/audit_icons/audit_edited.svg';
import image_audit_keys_deactivated from '../../../content/images/audit_icons/audit_keys-deactivated.svg';
import image_audit_password_reset from '../../../content/images/audit_icons/audit_password-reset.svg';
import image_audit_replenish from '../../../content/images/audit_icons/audit_replenish.svg';
import image_audit_request_made from '../../../content/images/audit_icons/audit_request-made.svg';
import image_audit_reset from '../../../content/images/audit_icons/audit_reset.svg';
import image_audit_upload_activation from '../../../content/images/audit_icons/audit_upload-activation.svg';
import image_audit_upload_activations from '../../../content/images/audit_icons/audit_upload-activations.svg';
import image_audit_upload_keys from '../../../content/images/audit_icons/audit_upload-keys.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import icons from '../../../constants/icons';

function Audits({ done, cancel, id, getAudit, addAudit, reload }) {
  const { t } = useTranslation();
  const [audits, setAudits] = useState();
  const [error, setError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState();

  const resolveUrl = (item) => {
    if (item.domainId === id) {
      return undefined;
    }

    switch (item.domainObject) {
      case domainObjects.Company:
        return `/company/${item.domainId}`;
      case domainObjects.Game:
        return `/game/${item.domainId}`;
      case domainObjects.Sku:
        return `/sku/${item.domainId}`;
      default:
        return undefined;
    }
  };

  const resolveImage = (item) => {
    switch (item.auditTypeCssClasses) {
      case 'access-granted':
        return image_audit_access_granted;
      case 'account-revoked':
        return image_audit_account_revoked;
      case 'approved':
        return image_audit_approved;
      case 'archived':
        return image_audit_archived;
      case 'auto-replenish':
        return image_audit_auto_replenish;
      case 'comment':
        return image_audit_comment;
      case 'created':
        return image_audit_created;
      case 'deactivated':
        return image_audit_deactivated;
      case 'declined':
        return image_audit_declined;
      case 'deleted':
        return image_audit_deleted;
      case 'download':
        return image_audit_download;
      case 'edited':
        return image_audit_edited;
      case 'keys-deactivated':
        return image_audit_keys_deactivated;
      case 'password-reset':
        return image_audit_password_reset;
      case 'replenish':
        return image_audit_replenish;
      case 'request-made':
        return image_audit_request_made;
      case 'reset':
        return image_audit_reset;
      case 'upload-activation':
        return image_audit_upload_activation;
      case 'upload-activations':
        return image_audit_upload_activations;
      case 'upload-keys':
        return image_audit_upload_keys;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    setError(false);
    setLoadingMessage(undefined);
    getAudit(id)
      .then((d) => setAudits(d))
      .catch((e) => {
        setError(true);
        setLoadingMessage(e.message);
      });
  }, [id, getAudit, setAudits, reload, setError, setLoadingMessage]);

  return (
    <SlideoutLayout
      title={t('Audit history')}
      cancel={cancel}
      done={done}
      bar={
        <button className="btn btn-default" onClick={addAudit}>
          {t('Add comment')}
        </button>
      }
    >
      {audits ? (
        audits.length > 0 ? (
          <div className="audit-trail">
            {audits.map((item, index) => (
              <article
                key={item.id}
                className={`audit-item audit-item--${
                  item.auditTypeCssClasses
                } ${index % 2 ? 'alt' : ''}`}
              >
                <div className="audit-item__body">
                  <div className="audit-item__entry">
                    <div className="audit-item__icon">
                      <Gravatar
                        email={item.createdByUsername}
                        size={52}
                        rating="pg"
                        default="retro"
                        className="img-circle float-left"
                        style={{
                          border: '2px solid #ffffff',
                          overflow: 'hidden',
                        }}
                      />
                      <img
                        src={resolveImage(item)}
                        width="20"
                        height="20"
                        style={{ height: '100%' }}
                        alt={`${item.auditType} icon`}
                      />
                    </div>
                    <div className="panel kw-panel has-buttons r-corners">
                      <header className="mb-2">
                        <div className="valign-container clearfix width-100">
                          <h3 className="audit-item__title valign bold">
                            {item.auditType}
                          </h3>
                          {resolveUrl(item) && (
                            <NavLink
                              to={resolveUrl(item)}
                              className="float-end"
                              title={t('View related item')}
                              onClick={cancel}
                            >
                              <FontAwesomeIcon
                                icon={icons.LINK}
                              ></FontAwesomeIcon>
                            </NavLink>
                          )}
                        </div>
                      </header>
                      <p>{item.message}</p>
                      <div className="d-flex justify-content-between mt-3">
                        <i>
                          <UserPopup id={item.createdById}>
                            <span>
                              <NavLink
                                to={`/user/${item.createdById}`}
                                onClick={cancel}
                              >
                                {item.createdByFullName}
                              </NavLink>
                            </span>
                          </UserPopup>
                        </i>
                        <span>{Date_AsString_NoHtml(item.created)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        ) : (
          <p>{t('No audits')}</p>
        )
      ) : (
        <Loading isError={error} message={loadingMessage}></Loading>
      )}
    </SlideoutLayout>
  );
}
Audits.propTypes = {
  id: PropTypes.string.isRequired,
  getAudit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default Audits;
