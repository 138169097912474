import React, { useEffect, useRef, useCallback } from 'react';
import * as wordanimation from '../../util/alaska.ui.wordanimation.js';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const BigLoader = ({ loader }) => {
  const { t } = useTranslation();
  const ref = useRef();

  const hideTimeoutRef = React.useRef();

  const showBigLoader = useCallback(
    (title, subtitle) => {
      if (ref.current) {
        const initializingComponent = ref.current;
        const initCanvas =
          initializingComponent.getElementsByTagName('canvas')[0];
        initializingComponent.style.transition = `opacity 20ms, transform 20ms`;
        initializingComponent.style.opacity = 1;
        initializingComponent.style.display = null;
        wordanimation.init(
          initCanvas,
          title || 'ALASKA',
          subtitle || t('loading...')
        );
      }
    },
    [ref]
  );

  const hideBigLoader = useCallback(() => {
    if (ref.current) {
      const initializingComponent = ref.current;
      const initCanvas =
        initializingComponent.getElementsByTagName('canvas')[0];
      initializingComponent.style.transition = `opacity 250ms, transform 250ms`;
      initializingComponent.style.opacity = 0;
      initializingComponent.style.display = null;

      hideTimeoutRef.current = setTimeout(() => {
        wordanimation.stop(initCanvas);
        initializingComponent.style.display = 'none';
      }, 500);
    }
  }, [ref, hideTimeoutRef]);

  // watch for the show hide property changes - triggered from the dispatcher below
  useEffect(() => {
    if (loader) {
      showBigLoader(loader.title, loader.subTitle);
    } else {
      hideBigLoader();
    }
    return () => {
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
    };
  }, [loader, showBigLoader, hideBigLoader, hideTimeoutRef]);

  return (
    <div id="initializing" style={{ display: 'none' }} ref={ref}>
      <canvas></canvas>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    loader: state.loader,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BigLoader);
