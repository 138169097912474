import React from 'react';
import PropTypes from 'prop-types';
import { arrayMoveImmutable } from 'array-move';
import SortableList from './components/SortableList';

function TableColumnSortableComponent({ items, setItems, allColumns }) {
  const onSortEnd = (result) => {
    let ret = arrayMoveImmutable(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(() => ret);
  };

  const handleColumnChange = (e, column) => {
    let newArr = [...items];
    let index = items.findIndex((x) => x.id === column.id);
    newArr[index] = { id: column.id, key: e.target.value };
    setItems(newArr);
  };

  const handleColumnDelete = (column) => {
    let newArr = [...items].filter((x) => x.id !== column.id);
    setItems(newArr);
  };

  return (
    <SortableList
      items={items}
      allColumns={allColumns}
      onSortEnd={onSortEnd}
      onColumnChange={handleColumnChange}
      onColumnDelete={handleColumnDelete}
    />
  );
}

TableColumnSortableComponent.propTypes = {
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  allColumns: PropTypes.array.isRequired,
};

export default TableColumnSortableComponent;
