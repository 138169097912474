import * as reportApi from '../api/reportByWeekApi';

export function loadRequestsByWeekByGame({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByWeekByGame(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadRequestsByWeekBySKU({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByWeekBySKU(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadKeysByWeekByGame({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByWeekByGame(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadKeysByWeekBySku({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByWeekBySku(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadKeysByWeekByCompany({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByWeekByCompany(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadRequestsByWeekByCompany({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByWeekByCompany(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadRequestsByWeekByAuthorisingUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByWeekByAuthorisingUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportRequestsByWeekByAuthorisingUser(year, filters, scopes) {
  return reportApi.exportRequestsByWeekByAuthorisingUser(year, filters, scopes);
}

export function loadKeysByWeekByAuthorisingUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByWeekByAuthorisingUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportKeysByWeekByAuthorisingUser(year) {
  return reportApi.exportKeysByWeekByAuthorisingUser(year);
}

export function loadRequestsByWeekByUploadingUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByWeekByUploadingUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportRequestsByWeekByUploadingUser(year, filters, scopes) {
  return reportApi.exportRequestsByWeekByUploadingUser(year, filters, scopes);
}

export function loadKeysByWeekByUploadingUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByWeekByUploadingUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportKeysByWeekByUploadingUser(year, filters, scopes) {
  return reportApi.exportKeysByWeekByUploadingUser(year, filters, scopes);
}

export function exportRequestsByWeekByGame(year, company, filters, scopes) {
  return reportApi.exportRequestsByWeekByGame(year, company, filters, scopes);
}

export function exportRequestsByWeekBySKU(year, company, filters, scopes) {
  return reportApi.exportRequestsByWeekBySKU(year, company, filters, scopes);
}

export function exportKeysByWeekByGame(year, company, filters, scopes) {
  return reportApi.exportKeysByWeekByGame(year, company, filters, scopes);
}

export function exportKeysByWeekBySku(year, company, filters, scopes) {
  return reportApi.exportKeysByWeekBySku(year, company, filters, scopes);
}

export function exportKeysByWeekByCompany(year, filters, scopes) {
  return reportApi.exportKeysByWeekByCompany(year, filters, scopes);
}
export function exportRequestsByWeekByCompany(year, filters, scopes) {
  return reportApi.exportRequestsByWeekByCompany(year, filters, scopes);
}

export function loadUsersModifiedByWeekByUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getUsersModifiedByWeekByUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportUsersModifiedByWeekByUser(year, filters, scopes) {
  return reportApi.exportUsersModifiedByWeekByUser(year, filters, scopes);
}

export function loadKeyWorkflowsModifiedByWeekByUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeyWorkflowsModifiedByWeekByUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportKeyWorkflowsModifiedByWeekByUser(year, filters, scopes) {
  return reportApi.exportKeyWorkflowsModifiedByWeekByUser(
    year,
    filters,
    scopes
  );
}

export function loadGamesModifiedByWeekByUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getGamesModifiedByWeekByUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportGamesModifiedByWeekByUser(year, filters, scopes) {
  return reportApi.exportGamesModifiedByWeekByUser(year, filters, scopes);
}

export function loadSKUsModifiedByWeekByUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getSKUsModifiedByWeekByUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportSKUsModifiedByWeekByUser(year, filters, scopes) {
  return reportApi.exportSKUsModifiedByWeekByUser(year, filters, scopes);
}

export function loadRequestsByWeekByWorkflow({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByWeekByWorkflow(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportRequestsByWeekByWorkflow(year, company, filters, scopes) {
  return reportApi.exportRequestsByWeekByWorkflow(
    year,
    company,
    filters,
    scopes
  );
}

export function loadKeysByWeekByWorkflow({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByWeekByWorkflow(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportKeysByWeekByWorkflow(year, company, filters, scopes) {
  return reportApi.exportKeysByWeekByWorkflow(year, company, filters, scopes);
}
