export function init(canvas, str, subtitle, colours) {
  let ctx = canvas.getContext('2d'),
    particles = [],
    amount = 0,
    mouse = { x: 0, y: 0 },
    numparticles = 100,
    speed = 90;

  canvas.isrunning = true;

  let colors = colours || ['#f00', '#46B29D', '#BB5EBF', '#fe5557', '#b2178a'];

  let ww = (canvas.width = window.innerWidth);
  let wh = (canvas.height = window.innerHeight);

  function Particle(x, y) {
    this.x = Math.random() * ww;
    this.y = Math.random() * wh;
    this.dest = {
      x: x,
      y: y,
    };
    this.r = Math.random() * 5 + 2;
    this.vx = (Math.random() - 0.5) * 20;
    this.vy = (Math.random() - 0.5) * 20;
    this.accX = 0;
    this.accY = 0;
    this.friction = Math.random() * 0.023 + 0.85;

    this.color = colors[Math.floor(Math.random() * 6)];
  }

  Particle.prototype.render = function () {
    this.accX = (this.dest.x - this.x) / speed;
    this.accY = (this.dest.y - this.y) / speed;
    this.vx += this.accX;
    this.vy += this.accY;
    this.vx *= this.friction;
    this.vy *= this.friction;

    this.x += this.vx;
    this.y += this.vy;

    ctx.fillStyle = this.color;
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.r, Math.PI * 2, false);
    ctx.fill();

    var a = this.x - mouse.x;
    var b = this.y - mouse.y;

    var distance = Math.sqrt(a * a + b * b);
    let radius =
      window.innerWidth > 1400 ? 1.8 : window.innerWidth > 800 ? 0.9 : 0.5;
    if (distance < radius * 70) {
      this.accX = (this.x - mouse.x) / 100;
      this.accY = (this.y - mouse.y) / 100;
      this.vx += this.accX;
      this.vy += this.accY;
    }
  };

  let onMouseMove = (e) => {
    mouse.x = e.clientX;
    mouse.y = e.clientY;
  };

  let onTouchMove = (e) => {
    if (e.touches.length > 0) {
      mouse.x = e.touches[0].clientX;
      mouse.y = e.touches[0].clientY;
    }
  };

  let onTouchEnd = (e) => {
    mouse.x = -9999;
    mouse.y = -9999;
  };

  let initScene = () => {
    ww = canvas.width = window.innerWidth;
    wh = canvas.height = window.innerHeight;
    if (ww > 0 && canvas.width > 0) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.font = 'bold ' + ww / 5 + 'px sans-serif';
      ctx.textAlign = 'center';
      ctx.fillText(str, ww / 2, wh / 2);

      var data = ctx.getImageData(0, 0, ww, wh).data;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'screen';

      particles = [];
      for (var i = 0; i < ww; i += Math.round(ww / numparticles)) {
        for (var j = 0; j < wh; j += Math.round(ww / numparticles)) {
          if (data[(i + j * ww) * 4 + 3] > numparticles) {
            particles.push(new Particle(i, j));
          }
        }
      }
      amount = particles.length;
    }
  };

  //let onMouseClick = () => {
  //	radius++;
  //	if(radius ===5){
  //		radius = 0;
  //	}
  //}
  let render = (a) => {
    if (canvas.isrunning) {
      requestAnimationFrame(render);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (var i = 0; i < amount; i++) {
        particles[i].render();
      }
      ctx.font = '20px arial';
      ctx.fillStyle = '#ffffff';
      ctx.fillText(subtitle, ww / 2, wh / 2 + 45);
    }
  };

  window.addEventListener('resize', initScene);
  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('touchmove', onTouchMove);
  //window.addEventListener("click", onMouseClick);
  window.addEventListener('touchend', onTouchEnd);
  initScene();
  requestAnimationFrame(render);
}

export function stop(canvas) {
  canvas.isrunning = false;
}
