import * as metricApi from '../api/metricApi';

export function gameKeys({ filters, _scopes, _sort, _order, _page, _limit }) {
  return metricApi.gameKeys(filters, _scopes, _sort, _order, _page, _limit);
}
export function exportGameKeysData(filters, scopes) {
  return metricApi.exportGameKeysData(filters, scopes);
}

export function loadKeyPools({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return metricApi.keyPools(filters, _scopes, _sort, _order, _page, _limit);
}

export function exportKeyPoolsData(filters, scopes) {
  return metricApi.exportKeyPoolsData(filters, scopes);
}
