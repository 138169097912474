import React, { useEffect, useState } from 'react';
import SlideoutLayout from './slideout/SlideoutLayout';
import KwPanel from '../common/KwPanel';
import { useForm } from 'react-hook-form';
import FormStatic from '../common/FormStatic';
import {
  signoutRedirect,
  changePassword,
  impersonate,
  impersonationFailed,
} from '../../services/userService';
import permissionTypes from '../../constants/permissionTypes';
import Impersonate from './Impersonate';
import SlidingPane from 'react-sliding-pane';

import ResponsiveActionBarButtons from '../common/ResponsiveActionBarButtons';
import { hasFeature, hasPermission, userIs } from '../auth/authUtils';
import userTypes from '../../constants/userTypes';
import Button from '../common/Button';
import ApproverNotificationUsersList from '../settings/workflow/components/ApproverNotificationUsersList';
import icons from '../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import LanguagePicker from '../settings/users/components/LanguagePicker';

export default function Settings({ currentUser, cancel, done, location }) {
  const { t } = useTranslation();
  const [reload] = useState();

  const steamToolDownloadUri =
    window.globalConfig?.steamToolDownloadUri ??
    process.env.REACT_APP_STEAM_TOOL_DOWNLOAD_URI ??
    null;

  const {
    register,
    formState: { errors },
  } = useForm();
  const handleSignout = () => {
    signoutRedirect();
  };
  const handleChangePassword = () => {
    changePassword();
  };

  const [canImpersonate, setCanImpersonate] = useState(false);
  const [impersonateSlideoutOpen, setImpersonateSlideoutOpen] = useState(false);

  useEffect(() => {
    if (currentUser) {
      let can =
        hasPermission(currentUser, permissionTypes.IMPERSONATE_USERS) ||
        currentUser?.isImpersonating;
      setCanImpersonate(can);
    }
  }, [currentUser]);

  const handleStopImpersonate = () => {
    impersonate()
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        impersonationFailed();
      });
  };

  return (
    <>
      <SlideoutLayout
        title={t('Settings')}
        cancel={cancel}
        done={done}
        bar={
          <ResponsiveActionBarButtons
            buttons={[
              canImpersonate && !currentUser.isImpersonating && (
                <Button
                  key="impersonate"
                  className="btn btn-default ms-2 d-none d-md-inline-flex"
                  onClick={() => setImpersonateSlideoutOpen(true)}
                  text={t('Impersonate')}
                />
              ),
              currentUser.isImpersonating && (
                <Button
                  key="stopImpersonating"
                  className="btn btn-default ms-2 d-none d-md-inline-flex"
                  onClick={handleStopImpersonate}
                  text={t('Stop impersonating')}
                />
              ),
              <Button
                key="changePassword"
                className="btn btn-default ms-2 d-none d-md-inline-flex"
                onClick={handleChangePassword}
                text={t('Change password')}
              />,
              <Button
                key="Signout"
                className="btn btn-primary ms-2"
                onClick={handleSignout}
                text={t('Signout')}
              />,
            ]}
          />
        }
      >
        <KwPanel className="mb-4">
          <header className="mb-2">
            <h2 className="no-margin ">{t('Your details')}</h2>
          </header>
          <FormStatic label={t('Name')}>{currentUser.name}</FormStatic>
          <FormStatic label={t('Company')}>
            {currentUser.companyName}
          </FormStatic>
          {hasFeature(currentUser, 'Translations') && (
            <LanguagePicker currentUser={currentUser} register={register} />
          )}
        </KwPanel>

        {steamToolDownloadUri && userIs(currentUser, userTypes.UPLOADER) && (
          <KwPanel className="mb-4">
            <header className="mb-2">
              <h2 className="no-margin ">{t('Steam tool')}</h2>
              <a
                href={steamToolDownloadUri}
                rel="noopener noreferrer"
                className="btn btn-default"
              >
                {t('Download')}
              </a>
            </header>
            <p className="mt-4">{t('Steam tool settings description')}</p>
            <p>{t('Steam tool settings download prompt')}</p>
          </KwPanel>
        )}

        {userIs(currentUser, userTypes.APPROVER) && (
          <ApproverNotificationUsersList
            title={t('Approval notifications')}
            helpPanel={
              <>
                <p className="alert alert-info">
                  <span className="icon-wrapper me-3">
                    <FontAwesomeIcon icon={icons.NOTIFICATION} />
                  </span>
                  {''}
                  {t(
                    'You can opt in and out of notifications about approvals. Click your name to toggle your preference.'
                  )}
                </p>
              </>
            }
            location={location}
            reload={reload}
            ownUserOnly={true}
            scope={{ user: currentUser.id }}
          />
        )}
      </SlideoutLayout>

      <SlidingPane
        isOpen={impersonateSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setImpersonateSlideoutOpen(false)}
      >
        <Impersonate
          currentUser={currentUser}
          done={done}
          cancel={() => setImpersonateSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}
