import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { withRouter } from 'react-router-dom';
import { dynamicDateRanges } from '../../../util/dateRanges';
import { DateForFilter } from '../../../util/formatter';
import icons from '../../../constants/icons';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function DateRange({
  range,
  onChange,
  className,
  useDynamicRanges = false,
  setStorageRange,
}) {
  const dynamicRanges = {};
  const { t } = useTranslation();
  if (useDynamicRanges) {
    Object.keys(dynamicDateRanges).forEach((rangeKey) => {
      const dynamicRange = dynamicDateRanges[rangeKey];
      dynamicRanges[dynamicRange.label] = [
        dynamicRange.calculateFrom(),
        dynamicRange.calculateTo(),
      ];
    });
  }

  function getDisplayDateLabel(range) {
    if (range.dynamic === 'Custom Range' || !range.dynamic) {
      return `${DateForFilter(range.from)} - ${DateForFilter(range.to)}`;
    }

    if (range.dynamic) {
      const matchingDymamicRangeKey = Object.keys(dynamicDateRanges).find(
        (key) => dynamicDateRanges[key].label === range.dynamic
      );
      const matchingDynamicRange = dynamicDateRanges[matchingDymamicRangeKey];
      if (matchingDynamicRange) {
        return matchingDynamicRange.label;
      }
    }
  }

  return (
    <div className={className}>
      <DateRangePicker
        initialSettings={{
          showDropdowns: true,
          minYear: 2010,
          buttonClasses: 'btn btn-default',
          autoUpdateInput: false,
          startDate: range.from,
          endDate: range.to,
          locale: {
            format: 'DD-MMM-YYYY',
            applyLabel: t('Apply'),
            cancelLabel: t('Clear'),
          },
          ...(useDynamicRanges && { ranges: dynamicRanges }),
        }}
        onApply={(e, picker) => {
          let from = picker.startDate.toDate(); //.format('DD-MMM-YYYY');
          let to = picker.endDate.toDate(); //.format('DD-MMM-YYYY');
          setStorageRange && setStorageRange(picker.chosenLabel);
          onChange({ from, to });
        }}
        onCancel={(e, picker) => {
          picker.setStartDate(new Date());
          picker.setEndDate(new Date());
          onChange({ from: '', to: '' });
        }}
      >
        {
          // without this wrapping div the button does nothing when clicked
        }
        <div>
          <Button
            icon={icons.CALENDAR}
            className="btn btn-default btn-outline"
            text={getDisplayDateLabel(range)}
          />
        </div>
      </DateRangePicker>
    </div>
  );
}

export default withRouter(DateRange);
