import * as skuApi from '../api/skuApi';
import actionTypes from '../constants/actionTypes';

export function saveSku(gameId, sku) {
  return function (dispatch) {
    return skuApi.saveSku(gameId, sku).then((savedGameSku) => {
      dispatch({
        type: sku.id ? actionTypes.UPDATE_GAMESKU : actionTypes.CREATE_GAMESKU,
        sku: savedGameSku,
      });
      return savedGameSku;
    });
  };
}

export function getSkuById(id) {
  return skuApi.getSkuById(id);
}

export function loadSkus({ filters, _scopes, _sort, _order, _page, _limit }) {
  return skuApi.getSkus(filters, _scopes, _sort, _order, _page, _limit);
}
export function getSKUs() {
  return skuApi.getAllSKUs();
}

export function getStats(id) {
  return skuApi.getStats(id);
}

export function getStatsByDay(id) {
  return skuApi.getStatsByDay(id);
}

export function getAggregateStats(id, from, to) {
  return skuApi.getAggregateStats(id, from, to);
}
export function getAudit(id) {
  return skuApi.getAudit(id);
}

export function addAudit(id, comment) {
  return skuApi.addAudit(id, comment);
}

export function addTerritories(id, gameid, territories) {
  return skuApi.addTerritories(id, gameid, territories);
}

export function removeTerritories(id, gameid, territories) {
  return skuApi.removeTerritories(id, gameid, territories);
}
export function addWorkflows(id, gameid, workflows) {
  return skuApi.addWorkflows(id, gameid, workflows);
}
export function removeWorkflows(id, gameid, workflows) {
  return skuApi.removeWorkflows(id, gameid, workflows);
}
export function uploadToKeyPool(id, gameid, keyWorkflowId, record) {
  return skuApi.uploadToKeyPool(id, gameid, keyWorkflowId, record);
}
export function validateCodeFile(id, gameid, file) {
  return skuApi.validateCodeFile(id, gameid, file);
}

export function loadKeyPools({
  id,
  gameid,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return skuApi.getKeyPools(
    id,
    gameid,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getSkuCompanyStocks(id) {
  return skuApi.getSkuCompanyStocks(id);
}
