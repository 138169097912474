import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import 'tippy.js/dist/tippy.css'; // optional

import KwPanel from '../../../common/KwPanel';
import { NavLink } from 'react-router-dom';
import icons from '../../../../constants/icons';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import workflowHelp from '../../../../constants/help/workflowHelp';
import Help from '../../../common/Help';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const WorkflowRestrictions = ({
  register,
  setValue,
  restricted,
  restrictions,
  workflows,
  locked,
}) => {
  const language = i18next.language;
  const { t } = useTranslation();
  const [mode, setMode] = useState(
    restricted && restrictions?.length > 0
      ? 'Disallow'
      : !restricted && restrictions?.length > 0
        ? 'Allow Only'
        : 'Unrestricted'
  );
  const [workflow, setWorkflow] = useState();
  const [workflowRestrictions, setWorkflowRestrictions] = useState(
    restrictions || []
  );
  setValue('workflows', workflowRestrictions);

  const handleOnModeChange = (value) => {
    if (!locked) {
      setMode(value);
      setValue('disallowListedKeyWorkflows', value);
    }
  };

  const handleOnAdd = () => {
    if (!workflow) {
      return;
    }
    if (!locked) {
      if (!workflowRestrictions.find((x) => x.id === workflow.id)) {
        const updatedWorkflowRestrictions = [...workflowRestrictions, workflow];
        setWorkflowRestrictions(updatedWorkflowRestrictions);
        setValue('workflows', updatedWorkflowRestrictions);
      }
    }
  };
  const handleOnChange = (e) => {
    if (!locked) {
      setWorkflow(workflows.find((x) => x.id === e.target.value));
    }
  };
  const handleOnRemove = (id) => {
    if (!locked) {
      const updatedWorkflowRestrictions = workflowRestrictions.filter(
        (x) => x.id !== id
      );
      setWorkflowRestrictions(updatedWorkflowRestrictions);
      setValue('workflows', updatedWorkflowRestrictions);
    }
  };

  useEffect(() => {
    setValue('disallowListedKeyWorkflows', mode);
  });

  return (
    <KwPanel className="mb-4">
      <header className="space-bottom" style={{ justifyContent: 'flex-start' }}>
        <h2 className="space-right">{t('Workflow restrictions')}</h2>
        <Help
          helpContent={workflowHelp.WORKFLOW_RESTRICTIONS[language]}
          size={'2x'}
        />
      </header>
      <div className="row">
        <div className="col-sm-6">
          <div
            className="toggle-group"
            register={register('disallowListedKeyWorkflows')}
            defaultValue="true"
          >
            <label
              onClick={(e) => handleOnModeChange('Unrestricted')}
              className={`${mode === 'Unrestricted' ? 'checked' : ''}`}
            >
              {t('Unrestricted')}
            </label>
            <label
              onClick={(e) => handleOnModeChange('Allow Only')}
              className={`${mode === 'Allow Only' ? 'checked' : ''}`}
            >
              {t('Allow Only')}
            </label>
            <label
              onClick={(e) => handleOnModeChange('Disallow')}
              className={`${mode === 'Disallow' ? 'checked' : ''}`}
            >
              {t('Disallow')}
            </label>
          </div>
        </div>
        {mode !== 'Unrestricted' && (
          <>
            {!locked && (
              <div className="col-sm-6 text-right select-filter right">
                <select
                  disabled={locked}
                  className="form-control"
                  style={{ display: 'inline-block', width: '50%' }}
                  onChange={handleOnChange}
                >
                  <option value="">{t('--- choose ---')}</option>
                  {workflows &&
                    [...workflows]
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((x) => (
                        <option key={x.id} value={x.id}>
                          {x.name}
                        </option>
                      ))}
                </select>
                <Button
                  isDisabled={locked || !workflow}
                  title={locked ? 'Locked' : 'Add'}
                  className="btn btn-primary r-corners-right"
                  onClick={handleOnAdd}
                  text={t('Add')}
                />
              </div>
            )}
            <div className="col-sm-12">
              <div className="tags space-top" register={register('workflows')}>
                {workflowRestrictions?.map((x) => (
                  <div key={x.id} className="tag">
                    <NavLink
                      to={`/settings/workflow/${x.id}`}
                      title={t('Click to view group details')}
                    >
                      {x.name}
                    </NavLink>
                    {!locked && (
                      <span
                        className="ms-3 a"
                        onClick={() => handleOnRemove(x.id)}
                        title={t('Remove')}
                      >
                        <FontAwesomeIcon icon={icons.DELETE_TAG} />
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </KwPanel>
  );
};

WorkflowRestrictions.propTypes = {
  restricted: PropTypes.bool,
  restrictions: PropTypes.array,
  workflows: PropTypes.array.isRequired,
};

export default WorkflowRestrictions;
