import React, { useEffect, useState } from 'react';
import {
  loadAnnouncements,
  exportAnnouncementsData,
} from '../../../../actions/announcementActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import { DateOnly_AsString_NoHtml } from '../../../../util/formatter';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useTranslation } from 'react-i18next';

function AnnouncementList({ location, reload }) {
  const params = new URLSearchParams(location.search);
  const { t } = useTranslation();

  const [announcementDateDisplayValue, setAnnouncementDateDisplayValue] =
    useState();
  useEffect(() => {
    if (!announcementDateDisplayValue) {
      let _from = params.get(`f_announcementDateFrom`) || null;
      let _to = params.get(`f_announcementDateTo`) || null;
      if (_from && _to) {
        let fromDate = Date.parse(_from);
        let toDate = Date.parse(_to);
        if (isNaN(fromDate) || isNaN(toDate)) {
          setAnnouncementDateDisplayValue('');
        } else {
          setAnnouncementDateDisplayValue(`${_from} - ${_to}`);
        }
      }
    }
  }, [params, announcementDateDisplayValue]);

  const allColumns = ['message', 'announcementDate', 'expiryDate'];
  const defaultVisibleColumns = allColumns;
  const [announcements, setAnnouncements] = useState();
  const {
    tableSettings,
    setTableSettings,
    filters,
    filterChange,
    filtersChange,
    isLoading,
  } = useTableSettings(
    'announcementlist-settings',
    getTableSettingsDefaults({
      defaultSort: 'announcementDate',
      defaultVisibleColumns,
    }),
    null,
    loadAnnouncements,
    setAnnouncements,
    reload,
    t('Failed to load announcements'),
    location
  );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'message',
      Label: t('Message'),
      width: '40%',
      Render: (announcement) => (
        <Link to={'/settings/announcement/' + announcement.id}>
          {announcement.message}
        </Link>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'announcementDate',
      Label: t('Announcement date'),
      Render: (item) => DateOnly_AsString_NoHtml(item.announcementDate),
      Filter: {
        Render: (col) => (
          <DateRangePicker
            initialSettings={{
              showDropdowns: true,
              minYear: 2010,
              buttonClasses: 'btn btn-default',
              autoUpdateInput: false,
              locale: {
                format: 'DD-MMM-YYYY',
                applyLabel: t('Apply'),
                cancelLabel: t('Clear'),
              },
            }}
            onApply={(e, picker) => {
              let from = picker.startDate.format('DD-MMM-YYYY');
              let to = picker.endDate.format('DD-MMM-YYYY');
              filtersChange([
                { key: 'announcementDateFrom', value: from },
                { key: 'announcementDateTo', value: to },
              ]);
              setAnnouncementDateDisplayValue(`${from} - ${to}`);
            }}
            onCancel={(e, picker) => {
              picker.setStartDate(new Date());
              picker.setEndDate(new Date());
              filtersChange([
                { key: 'announcementDateFrom', value: '' },
                { key: 'announcementDateTo', value: '' },
              ]);
              setAnnouncementDateDisplayValue('');
            }}
          >
            <input
              type="text"
              className={`form-control ${
                announcementDateDisplayValue ? 'filtered' : ''
              }`}
              value={announcementDateDisplayValue}
              onChange={(e) => {
                setAnnouncementDateDisplayValue(e.target.value);
              }}
            />
          </DateRangePicker>
        ),
      },
    },
    {
      Key: 'expiryDate',
      Label: t('Expires'),
      Render: (item) => DateOnly_AsString_NoHtml(item.expiryDate),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return announcements ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={announcements.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={announcements.totalRowCount}
        filteredRowCount={announcements.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        filters={filters}
        isLoading={isLoading}
        filterChange={filterChange}
        exportData={exportAnnouncementsData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default AnnouncementList;
