import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';

import {
  saveAnnouncement,
  getAnnouncementById,
} from '../../../../actions/announcementActions';
import { toast } from 'react-toastify';
import AnnouncementForm from '../components/AnnouncementForm';
import { useForm } from 'react-hook-form';
import { CurrentDateForDatePicker } from '../../../../util/formatter';
import { useTranslation } from 'react-i18next';

function Edit({ done, id, cancel }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = (data) => {
    saveAnnouncement({ ...announcement, ...data })
      .then(() => {
        toast.success(t('Announcement saved'));
        done(announcement);
      })
      .catch((ex) => {
        toast.error(ex.message ?? t('Failed to save announcement'));
      });
  };

  const [announcement, setAnnouncement] = useState({
    announcementDate: CurrentDateForDatePicker(),
    expiryDate: CurrentDateForDatePicker(),
  });

  useEffect(() => {
    if (id) {
      getAnnouncementById(id).then((d) => {
        setAnnouncement(d);
      });
    }
  }, [id, getAnnouncementById]);

  return (
    <SlideoutLayout
      title={`${id ? t('Edit announcement') : t('Add announcement')}`}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <AnnouncementForm
        announcement={announcement}
        register={register}
        errors={errors}
        control={control}
      ></AnnouncementForm>
    </SlideoutLayout>
  );
}
Edit.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default Edit;
