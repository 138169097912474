import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import AddUploader from '../../uploader/slideout/Add';
import DeleteUploader from '../../uploader/slideout/Delete';
import {
  getUploadersForKeyProvider,
  getUploadersForKeyWorkflow,
  getUploadersForPlatform,
} from '../../../../actions/uploaderActions';
import KwPanel from '../../../common/KwPanel';
import { NavLink } from 'react-router-dom';
import Gravatar from 'react-gravatar';
import SlidingPane from 'react-sliding-pane';

import image_request_waiting_for_keys from '../../../../content/images/request_icons/request_waiting-for-keys.svg';
import image_activated_keys from '../../../../content/images/activated_keys.svg';
import icons from '../../../../constants/icons';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function UploaderBadges({
  label,
  noneMessage,
  keyProviderId,
  keyWorkflowId,
  platformId,
}) {
  const { t } = useTranslation();
  const [uploaders, setUploaders] = useState();
  useEffect(() => {
    if (keyProviderId && !uploaders) {
      // get uploaders
      getUploadersForKeyProvider(keyProviderId)
        .then((d) => {
          setUploaders(d);
        })
        .catch((e) => {
          toast.error(t('Failed to find key provider uploaders'));
        });
    }
  }, [keyProviderId, uploaders]);

  useEffect(() => {
    if (keyWorkflowId && !uploaders) {
      // get uploaders
      getUploadersForKeyWorkflow(keyWorkflowId)
        .then((d) => {
          setUploaders(d);
        })
        .catch((e) => {
          toast.error(t('Failed to find workflow uploaders'));
        });
    }
  }, [keyWorkflowId, uploaders]);

  useEffect(() => {
    if (platformId && !uploaders) {
      // get uploaders
      getUploadersForPlatform(platformId)
        .then((d) => {
          setUploaders(d);
        })
        .catch((e) => {
          toast.error(t('Failed to find platform uploaders'));
        });
    }
  }, [platformId, uploaders]);

  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const addUploader = (e) => {
    e.cancelBubble = true;
    setAddSlideoutOpen(true);
    return false;
  };

  const [removeSlideoutOpen, setRemoveSlideoutOpen] = useState(false);
  const [removeSlideoutItem, setRemoveSlideoutItem] = useState();
  const removeUploader = (e, uploaderId) => {
    e.cancelBubble = true;
    setRemoveSlideoutItem(uploaderId);
    setRemoveSlideoutOpen(true);
    return false;
  };

  const GroupTag = ({ uploader }) => (
    <div className="tag" key={uploader.userProfileGroupId}>
      <NavLink
        to={`/settings/user-profile-group/${uploader.userProfileGroupId}`}
        title={t('Click to view group details')}
      >
        <span className="type">{t('Group')}</span> :{' '}
        <span>{uploader.groupName}</span>
      </NavLink>
      <span
        className="ms-3 a"
        onClick={(e) => removeUploader(e, uploader.id)}
        title={t('Remove')}
      >
        <FontAwesomeIcon icon={icons.DELETE_TAG} />
      </span>
    </div>
  );

  const UserTag = ({ uploader }) => (
    <div className="tag pink" key={uploader.userProfileId}>
      <NavLink
        to={`/user/${uploader.userProfileId}`}
        title={t('Click to view user details')}
      >
        <Gravatar
          email={uploader?.username}
          size={16}
          rating="pg"
          default="retro"
          className="img-circle me-3"
        />
        <span>{uploader.fullName}</span>
      </NavLink>
      <span
        className="ms-3 a"
        onClick={(e) => removeUploader(e, uploader.id)}
        title={t('Remove')}
      >
        <FontAwesomeIcon icon={icons.DELETE_TAG} />
      </span>
    </div>
  );

  return (
    <>
      <KwPanel className="mb-4">
        <header className="mb-2">
          <h2 className="no-margin">
            <span className="workflow-icon">
              <img
                src={image_request_waiting_for_keys}
                alt={t('Upload keys icon')}
                width="32"
                height="32"
              />
            </span>
            {label}
          </h2>
          <div className="float-right">
            <Button
              className="btn btn-default"
              onClick={addUploader}
              text={t('Add')}
            />
          </div>
        </header>
        {uploaders && uploaders.length > 0 ? (
          <div className="tags">
            {uploaders.map((x, i) => (
              <span key={i}>
                {x.userProfileGroupId ? (
                  <GroupTag uploader={x} />
                ) : x.userProfileId ? (
                  <UserTag uploader={x} />
                ) : (
                  ''
                )}
              </span>
            ))}
          </div>
        ) : (
          <div
            className="alert alert-info text-center"
            style={{ padding: '30px 15px' }}
          >
            {noneMessage && (
              <>
                <img
                  src={image_activated_keys}
                  alt={t('No uploaders')}
                  className="space-bottom-small"
                  height={80}
                />
                <p>{noneMessage}</p>
              </>
            )}
          </div>
        )}
      </KwPanel>

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <AddUploader
          keyProviderId={keyProviderId}
          keyWorkflowId={keyWorkflowId}
          platformId={platformId}
          cancel={() => setAddSlideoutOpen(false)}
          done={(d) => {
            setUploaders([...uploaders, d]);
            setAddSlideoutOpen(false);
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={removeSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRemoveSlideoutOpen(false)}
      >
        <>
          {removeSlideoutItem && (
            <DeleteUploader
              id={removeSlideoutItem}
              done={(d) => {
                setRemoveSlideoutOpen(false);
                setUploaders(
                  uploaders?.filter((r) => r.id !== removeSlideoutItem)
                );
              }}
              cancel={() => setRemoveSlideoutOpen(false)}
            />
          )}
        </>
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UploaderBadges);
