import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import {
  CommaNumber_NoDecimal,
  Date_AsString_NoHtml,
} from '../../../util/formatter';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import RequestDetailsStep from '../components/RequestDetailsStep';
import {
  getApprovalStatus,
  getApprovals,
  getCodeStatsByRequestForRequest,
} from '../../../actions/requestActions';
import { toast } from 'react-toastify';
import { isPowerUser } from '../../auth/authUtils';
import UserLink from '../../../components/common/links/UserLink';
import KwPanel from '../../common/KwPanel';
import { useTranslation } from 'react-i18next';

function RequestDetails({
  done,
  cancel,
  requestGroupId,
  requestId,
  history,
  onShowSignatures,
  currentUser,
}) {
  const [request, setRequest] = useState();
  const [requestCodeStats, setRequestCodeStats] = useState();
  const [requestSteps, setRequestSteps] = useState();
  const [lastUpdate] = useState(new Date());
  const { t } = useTranslation();

  useEffect(() => {
    if (requestGroupId) {
      // request group
      getApprovalStatus(requestGroupId, requestId)
        .then((d) => {
          setRequest(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get approval statuses'));
        });

      // individual requests
      getApprovals(requestGroupId, requestId)
        .then((d) => {
          setRequestSteps(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/requests/all');
          toast.error(t('Request not found'));
        });

      getCodeStatsByRequestForRequest(requestId)
        .then((d) => {
          setRequestCodeStats(d);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [requestGroupId, requestId, history, lastUpdate]);

  let getTitle = (request) => {
    if (request.isWaitingForKeys) {
      return t('Approved');
    } else {
      return request.requestStatus.name;
    }
  };

  let getClassName = (request) => {
    if (request.requestStatus.isAuthorised && request.isWaitingForKeys) {
      return 'authpending';
    }
    return request.requestStatus.cssClasses;
  };

  let getStepClassName = (request, step) => {
    if (requestSteps) {
      let currentIndex = requestSteps.findIndex((x) => x.isCurrentStep);
      let thisIndex = requestSteps.indexOf(step);
      if (step.isCurrentStep && !request.requestStatus.isWithdrawn) {
        return 'current';
      } else if (currentIndex > -1) {
        return currentIndex < thisIndex ? 'inactive' : '';
      }
    }
    return '';
  };

  return request && requestSteps ? (
    <SlideoutLayout
      title={t('Request details - {groupReference} ({reference})', {
        groupReference: request.groupReference,
        reference: request.reference,
      })}
      cancel={cancel}
      done={done}
      bar={<div className="request-status-bar">{getTitle(request)}</div>}
    >
      <div id="RequestStatus" className={`${getClassName(request)}`}>
        {/* request made */}
        <RequestDetailsStep
          stepClassName={getClassName(request)}
          iconClassName="start"
          iconFileName="start"
          stepName={
            <>
              Request made by{' '}
              <UserLink
                id={request.requestedById}
                name={request.requestedByFullName}
                currentUser={currentUser}
              />
            </>
          }
          stepStatus={Date_AsString_NoHtml(request.requestedDate)}
          showButton={false}
        />
        {/* approval steps */}
        {requestSteps.map((step) => {
          return (
            <RequestDetailsStep
              key={step.id}
              stepClassName={getStepClassName(request, step)}
              iconClassName={step.isComplete ? 'complete' : 'inprogress'}
              iconFileName={step.isComplete ? 'complete' : 'inprogress'}
              stepName={step.keyRequestApproverUserProfileGroupName ?? ''}
              stepStatus={step.status}
              showButton={isPowerUser(currentUser) && true}
              buttonOnClick={(e) => onShowSignatures(e, step)}
            />
          );
        })}

        {/* pending keys */}
        {!request.keyPoolEnabled &&
        (!request.requestStatus.isAuthorised ||
          (request.requestStatus.isAuthorised && request.isWaitingForKeys)) &&
        !request.requestStatus.isDeclined &&
        !request.requestStatus.isWithdrawn ? (
          <RequestDetailsStep
            stepClassName={
              !request.isComplete &&
              !(request.requestStatus.isAuthorised && request.isWaitingForKeys)
                ? 'inactive'
                : ''
            }
            iconClassName={
              request.requestStatus.isAuthorised && request.isWaitingForKeys
                ? 'inprogress'
                : 'pending'
            }
            iconFileName="waiting-for-keys"
            stepName="Pending keys"
            stepStatus=""
            showButton={false}
          />
        ) : (
          ''
        )}

        {/* keys uploaded */}
        {!request.keyPoolEnabled &&
        request.requestStatus.isAuthorised &&
        !(request.requestStatus.isAuthorised && request.isWaitingForKeys) &&
        !request.requestStatus.isDeclined &&
        !request.requestStatus.isWithdrawn ? (
          <RequestDetailsStep
            stepClassName=""
            iconClassName="pending"
            iconFileName="keys"
            stepName="Keys uploaded"
            stepStatus={Date_AsString_NoHtml(request.keysUploadedDate)}
            showButton={false}
          />
        ) : (
          ''
        )}

        {/* complete */}
        {!request.requestStatus.isWithdrawn &&
        !request.requestStatus.isDeclined ? (
          <RequestDetailsStep
            stepClassName={
              request.requestCompletionDate ? 'current' : 'inactive'
            }
            iconClassName="authorised"
            iconFileName="finish"
            stepName="Request complete"
            stepStatus={Date_AsString_NoHtml(request.requestCompletionDate)}
            showButton={false}
          />
        ) : (
          ''
        )}

        {/* withdrawn */}
        {request.requestStatus.isWithdrawn ? (
          <RequestDetailsStep
            stepClassName="current"
            iconClassName="withdrawn"
            iconFileName="withdrawn"
            stepName="Request withdrawn"
            stepStatus={Date_AsString_NoHtml(request.requestCompletionDate)}
            showButton={false}
          />
        ) : (
          ''
        )}
      </div>
      {requestCodeStats && requestCodeStats.length > 0 && (
        <KwPanel className="mt-4">
          <header>
            <h2>{t('Key stats')}</h2>
          </header>
          <table className="table dataTable width-100">
            <thead>
              <tr>
                <th>{t('Status')}</th>
                <th className="text-right num no-wrap">
                  {t('Number of codes')}
                </th>
              </tr>
            </thead>
            <tbody>
              {requestCodeStats.map((x, i) => (
                <tr key={i}>
                  <td>
                    {x.isActivated
                      ? t('Activated')
                      : x.isAllocated
                        ? t('Allocated')
                        : x.isDisabled
                          ? t('Disabled')
                          : x.isSold
                            ? t('Sold')
                            : x.isExpired
                              ? t('Expired')
                              : ''}
                    {x.isDownloaded ? ' ' + t('(Downloaded)') : ''}
                  </td>
                  <td className="text-right num no-wrap">
                    {CommaNumber_NoDecimal(x.codeCount || 0)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </KwPanel>
      )}
    </SlideoutLayout>
  ) : (
    <SlideoutLayout></SlideoutLayout>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

RequestDetails.propTypes = {
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  requestGroupId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onShowSignatures: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetails);
