import React, { useState } from 'react';
import {
  loadSLAsByWorkflows,
  exportSLAsByWorkflows,
} from '../../../actions/reportActions';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import WorkflowLink from '../../common/links/WorkflowLink';
import { useTranslation } from 'react-i18next';

function SLAsByWorkflowsList({ location, currentUser }) {
  const { t } = useTranslation();
  const [reload] = useState();
  const allColumns = [
    'name',
    'openRequests',
    'inSLA',
    'outsideSLA',
    'completeRequests',
    'averageDuration',
  ];
  const defaultVisibleColumns = allColumns;
  const [items, setItems] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'slabyworkflowreport-settings',
      getTableSettingsDefaults({ defaultSort: 'name', defaultVisibleColumns }),
      null,
      loadSLAsByWorkflows,
      setItems,
      reload,
      t('Failed to load report'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Workflow'),
      Render: (item) => (
        <WorkflowLink
          id={item.keyWorkflowId}
          name={item.name}
          colour={item.colourHex}
          currentUser={currentUser}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'openRequests',
      Label: t('Open requests'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.openRequests),
    },
    {
      Key: 'inSLA',
      Label: t('Within target'),
      className: 'text-right num no-wrap green',
      Render: (row) => CommaNumber_NoDecimal(row.inSLA),
    },
    {
      Key: 'outsideSLA',
      Label: t('Outside target'),
      className: 'text-right num no-wrap red',
      Render: (row) => CommaNumber_NoDecimal(row.outsideSLA),
    },
    {
      Key: 'completeRequests',
      Label: t('Completed requests'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.completeRequests),
    },
    {
      Key: 'averageDuration',
      Label: t('Average duration'),
      className: 'text-right no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.averageDuration),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return items ? (
    <KwPanel className="mb-4">
      <Table
        className="table-v-stripes"
        columns={columns}
        allColumns={allColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        rows={items.data.map((x) => {
          return { Key: x.keyWorkflowId, ...x };
        })}
        totalRowCount={items.totalRowCount}
        filteredRowCount={items.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportSLAsByWorkflows}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default SLAsByWorkflowsList;
