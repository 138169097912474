import React from 'react';
import KwPanel from '../../../common/KwPanel';
import FormInput from '../../../common/FormInput';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const UserProfileGroupForm = ({
  userProfileGroup,
  register,
  errors,
  control,
}) => {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4">
      <FormInput
        register={register}
        required={true}
        error={errors.name}
        errorMessage={t('Name is required')}
        name="name"
        label={t('Name')}
        placeholder={t('Name of the group')}
        defaultValue={userProfileGroup.name || ''}
        autoFocus={true}
      />
    </KwPanel>
  );
};

UserProfileGroupForm.propTypes = {
  userProfileGroup: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default UserProfileGroupForm;
