import {
  apiUrl,
  getList,
  getSingleRecord,
  upload,
  saveRecord,
  deleteRecord,
  download,
  get,
  getAll,
  postAction,
  exportData,
} from './apiUtils';
const baseUrl = apiUrl + '/keycodes/';
const exportBaseUrl = `${apiUrl}/export/keycodes/`;
const activatedBaseUrl = `${baseUrl}activated/`;
const disabledBaseUrl = `${baseUrl}disabled/`;
const disableReasonsBaseUrl = `${baseUrl}disablereasons/`;
const soldBaseUrl = `${baseUrl}sold/`;
const moveBaseUrl = `${baseUrl}move/`;
const ingestedBaseUrl = `${baseUrl}ingested/`;
const bulkIngestedBaseUrl = `${ingestedBaseUrl}bulk/`;

// ===================================
// Search
export function searchCode({
  code,
  keyProviderId,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  filters = { ...filters, code };
  return getList(
    `${baseUrl}search/${keyProviderId}/code`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit,
    {
      code,
      keyProviderId,
    }
  );
}
export function searchTag({
  tag,
  keyProviderId,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  filters = { ...filters, tag };
  return getList(
    `${baseUrl}search/${keyProviderId}/tag`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit,
    {
      tag,
      keyProviderId,
    }
  );
}

export function getCodeStatusHistory(id) {
  return get(`${baseUrl}${id}/history`);
}

// ===================================
// Activated
export function getActivatedTransactions(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    activatedBaseUrl,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

// lists the skus for the given transaction
export function loadActivatedTransactionSkus(
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${activatedBaseUrl}${id}/skus`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getActivatedTransactionById(id) {
  return getSingleRecord(activatedBaseUrl, id);
}

export function uploadActivations(record) {
  return upload(activatedBaseUrl, record);
}

export function exportActivatedTransactionData(filters, scopes) {
  return exportData(
    `${exportBaseUrl}activated`,
    'creationDate',
    filters,
    scopes
  );
}
// ===================================
// Disabled
export function getDisabledTransactions(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    disabledBaseUrl,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

// lists the skus for the given transaction
export function loadDisabledTransactionSkus(
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${disabledBaseUrl}${id}/skus`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getDisabledTransactionById(id) {
  return getSingleRecord(disabledBaseUrl, id);
}

export function uploadDisabled(record) {
  return upload(disabledBaseUrl, record);
}

export function downloadDisabledKeys(id) {
  return download(`${disabledBaseUrl}${id}/downloadkeys`);
}
export function downloadDisabledKeysForSkuAndCompany(id, skuId, companyId) {
  return download(`${disabledBaseUrl}${id}/${skuId}/${companyId}/downloadkeys`);
}

export function authoriseDisabledTransaction(id) {
  return get(`${disabledBaseUrl}${id}/authorise`);
}

export function exportDisabledTransactionData(filters, scopes) {
  return exportData(
    `${exportBaseUrl}disabled`,
    'creationDate',
    filters,
    scopes
  );
}
// ===================================
// Disabled reasons

export function getAllDisableReasons() {
  return getAll(disableReasonsBaseUrl);
}

export function getDisableReasons(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    disableReasonsBaseUrl,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getDisableReasonById(id) {
  return getSingleRecord(disableReasonsBaseUrl, id);
}

export function saveDisableReason(disableReason) {
  return saveRecord(disableReasonsBaseUrl, disableReason);
}

export function deleteDisableReason(id) {
  return deleteRecord(disableReasonsBaseUrl, id);
}

export function exportDisableReasonsData(filters, scopes) {
  return exportData(
    `${exportBaseUrl}disableReasons`,
    'reason',
    filters,
    scopes
  );
}

// ===================================
// Sold
export function getSoldTransactions(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(soldBaseUrl, filters, _scopes, _sort, _order, _page, _limit);
}

export function getSoldTransactionById(id) {
  return getSingleRecord(soldBaseUrl, id);
}
export function uploadSold(record) {
  return upload(soldBaseUrl, record);
}
// lists the skus for the given transaction
export function loadSoldTransactionSkus(
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${soldBaseUrl}${id}/skus`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function authoriseSoldTransaction(id) {
  return get(`${soldBaseUrl}${id}/authorise`);
}

export function exportSoldTransactionData(filters, scopes) {
  return exportData(`${exportBaseUrl}sold`, 'creationDate', filters, scopes);
}

// ===================================
// MOVE
export function getMoveTransactions(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(moveBaseUrl, filters, _scopes, _sort, _order, _page, _limit);
}

export function getMoveTransactionById(id) {
  return getSingleRecord(moveBaseUrl, id);
}

export function exportMoveTransactionData(filters, scopes) {
  return exportData(`${exportBaseUrl}move`, 'creationDate', filters, scopes);
}

// ===================================
// Ingested
export function getIngestedTransactions(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    ingestedBaseUrl,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getIngestedTransactionById(id) {
  return getSingleRecord(ingestedBaseUrl, id);
}

export function removeIngestedKeys(id) {
  return deleteRecord(ingestedBaseUrl, id);
}

export function retryIngestedKeys(id) {
  return postAction(`${ingestedBaseUrl}${id}/retry`);
}

export function exportIngestedTransactionsData(filters, scopes) {
  return exportData(
    `${exportBaseUrl}ingested`,
    'uploadedDate',
    filters,
    scopes
  );
}
// ===================================
// Bulk Ingested
export function getBulkIngestedTransactions(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    bulkIngestedBaseUrl,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getBulkIngestedTransactionById(id) {
  return getSingleRecord(bulkIngestedBaseUrl, id);
}

export function bulkUpload(record) {
  return upload(bulkIngestedBaseUrl, record);
}

export function exportBulkIngestedTransactionsData(filters, scopes) {
  return exportData(
    `${exportBaseUrl}ingested/bulk`,
    'uploadedDate',
    filters,
    scopes
  );
}
// ===================================
// Disable keys
export function disableKeys({
  keyProviderId,
  reason,
  disabledReasons,
  description,

  targetGameId,
  targetGameSKUId,
  targetCompanyId,
  targetKeyWorkflowId,
  targetRequestingUserProfileId,
  targetAccountDetailId,
  targetKeyRequestId,
  targetKeyRequestAccountDetail,

  targetIsAvailable,
  targetIsAllocated,
  targetIsActivated,
  targetIsSold,
  targetIsExpired,
}) {
  return postAction(`${baseUrl}disable`, {
    keyProviderId,
    reason,
    disabledReasons,
    description,

    targetGameId,
    targetGameSKUId,
    targetCompanyId,
    targetKeyWorkflowId,
    targetRequestingUserProfileId,
    targetAccountDetailId,
    targetKeyRequestId,
    targetKeyRequestAccountDetail,

    targetIsAvailable,
    targetIsAllocated,
    targetIsActivated,
    targetIsSold,
    targetIsExpired,
  });
}
// ===================================
// Move keys
export function moveKeys({
  skuId,
  sourceKeyWorkflowId,
  destinationKeyPools,
  description,
}) {
  return postAction(`${baseUrl}sku/${skuId}/moveKeys`, {
    sourceKeyWorkflowId,
    destinationKeyPools,
    description,
  });
}

export function splitFileIntoKeyPools({
  skuId,
  fileId,
  destinationKeyPools,
  description,
}) {
  return postAction(`${baseUrl}sku/${skuId}/splitFileIntoKeyPools`, {
    fileId,
    destinationKeyPools,
    description,
  });
}

// ===================================
// Move keys between requests
export function moveKeysBetweenRequests({
  sourceKeyRequestId,
  destinationKeyRequestId,
  reason,
}) {
  return postAction(`${baseUrl}moveKeysBetweenRequests`, {
    sourceKeyRequestId,
    destinationKeyRequestId,
    reason,
  });
}
