import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TerritoryHeader({
  territories,
  filterTerritory,
  handleFilterTerritory,
}) {
  const { t } = useTranslation();
  return (
    <div className="bulk-grid-game-name-th">
      <span className="mb-2">{t('Territory')}</span>
      <select
        className="form-control "
        value={filterTerritory || ''}
        onChange={handleFilterTerritory}
      >
        <option value="">{t('All')}</option>
        {territories
          ? [...territories]
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))
          : ''}
      </select>
    </div>
  );
}

TerritoryHeader.propTypes = {
  territories: PropTypes.array.isRequired,
  filterTerritory: PropTypes.string,
  handleFilterTerritory: PropTypes.func.isRequired,
};

export default TerritoryHeader;
