import {
  apiUrl,
  getList,
  getSingleRecord,
  saveRecord,
  deleteRecord,
  getStatsData,
  getAll,
  putAction,
  deleteAction,
  getAuditData,
  postAction,
  get,
  upload,
  exportData,
} from './apiUtils';
const baseUrl = apiUrl + '/keyProviders/';

export function getAllKeyProviders() {
  return getAll(baseUrl);
}

export function getKeyProviders(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getKeyProviderById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveKeyProvider(keyProvider) {
  return upload(baseUrl, keyProvider);
}

export function deleteKeyProvider(id) {
  return deleteRecord(baseUrl, id);
}
export function getStats(id) {
  return getStatsData(baseUrl, id);
}

export function getStatsByDay(id) {
  return get(`${baseUrl}${id}/statsByDay/`);
}

export function getAggregateStats(id, from, to) {
  // Format the from as YYYY-MM-DD
  from = from.toISOString().split('T')[0];
  // Format the to as YYYY-MM-DD
  to = to.toISOString().split('T')[0];

  return get(`${baseUrl}${id}/aggregateStats?fromDate=${from}&toDate=${to}`);
}

export function getAudit(id) {
  return getAuditData(baseUrl, id);
}

export function addAudit(id, comment) {
  return postAction(`${baseUrl}${id}/audit`, { comment });
}

export function getKeyProviderRequestInformation(id) {
  return getAll(`${baseUrl}${id}/requestinformation`);
}
export function saveKeyProviderRequestInformation(id, requestInformation) {
  return putAction(`${baseUrl}${id}/requestinformation`, requestInformation);
}

export function getKeyProviderPromotionSetting(id) {
  return get(`${baseUrl}${id}/promotionsetting`);
}
export function saveKeyProviderPromotionSetting(id, promotionSetting) {
  return putAction(`${baseUrl}${id}/promotionsetting`, promotionSetting);
}

export function getPlatformsForKeyProvider(id) {
  return getAll(`${baseUrl}${id}/platforms`);
}

export function saveKeyProviderPlatform(keyProviderId, platformId) {
  return saveRecord(`${baseUrl}${keyProviderId}/platform/`, { platformId });
}

export function deleteKeyProviderPlatform(keyProviderId, platformId) {
  return deleteAction(`${baseUrl}${keyProviderId}/platform/${platformId}`);
}

export function exportKeyProviderData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/keyProviders/search`,
    'name',
    filters,
    scopes
  );
}
