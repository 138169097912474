import React, { useState } from 'react';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { toast } from 'react-toastify';
import SkuForm from '../components/SkuForm';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import TerritoryRestrictions from '../../settings/territory/components/TerritoryRestrictions';
import WorkflowRestrictions from '../../settings/workflow/components/WorkflowRestrictions';
import { useTranslation } from 'react-i18next';
export default function AddSku({
  done,
  cancel,
  addMode,
  platforms,
  packageTypes,
  gameId,
  saveSku,
  columnCssClass,
  currentUser,
  addTerritories,
  territories,
  addWorkflows,
  workflows,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();
  const defaultDisabledKeyWorkflows = workflows.filter(
    (x) => x.defaultDisallowed
  );

  const onSubmit = (data) => {
    data.disallowListedKeyWorkflows =
      data.disallowListedKeyWorkflows !== 'Allow Only';
    data.disallowListedTerritories =
      data.disallowListedTerritories !== 'Allow Only';
    const workflowIds = data.workflows?.map((x) => x.id);
    const territoryIds = data.territories?.map((x) => x.id);
    saveSku(gameId, { ...sku, ...data })
      .then((addedSKU) => {
        let promises = [];
        if (territoryIds?.length > 0) {
          promises.push(
            Promise.resolve(addTerritories(addedSKU.id, gameId, territoryIds))
          );
        }
        if (workflowIds?.length > 0) {
          promises.push(
            Promise.resolve(addWorkflows(addedSKU.id, gameId, workflowIds))
          );
        }
        Promise.all(promises).then(() => {
          toast.success(t('Sku saved'));
          done(sku);
        });
      })
      .catch((e) => {
        toast.error(e.message ?? t('Failed to save sku'));
      });
  };

  const [sku] = useState({
    id: '',
    name: '',
    appId: '',
    notesHTML: '',
    licenseExpires: '',
    isLicenseExpired: true,
    isArchived: false,
    skuKeyCodeMetrics: null,
    skuKeyRequestStats: {
      authorisedRequests: 0,
      declinedRequests: 0,
      openRequests: 0,
      keysRequested: 0,
    },
    skuSKUs: [],
  });

  return (
    <SlideoutLayout
      title={t('Add sku')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <SkuForm
        sku={sku}
        register={register}
        errors={errors}
        control={control}
        addMode={addMode}
        columnCssClass={columnCssClass}
        platforms={platforms}
        keyProviders={[]}
        packageTypes={packageTypes}
        currentUser={currentUser}
      ></SkuForm>
      <TerritoryRestrictions
        register={register}
        setValue={setValue}
        territories={territories}
      ></TerritoryRestrictions>

      <WorkflowRestrictions
        register={register}
        setValue={setValue}
        workflows={workflows}
        restricted={defaultDisabledKeyWorkflows.length > 0}
        restrictions={defaultDisabledKeyWorkflows}
      ></WorkflowRestrictions>
    </SlideoutLayout>
  );
}

AddSku.propTypes = {
  gameId: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  addMode: PropTypes.bool,
  platforms: PropTypes.array.isRequired,
  packageTypes: PropTypes.array.isRequired,
  saveSku: PropTypes.func.isRequired,
  columnCssClass: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  addTerritories: PropTypes.func,
  territories: PropTypes.array,
  addWorkflows: PropTypes.func,
  workflows: PropTypes.array,
};
