import React, { useState } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import GameList from './components/GameList';
import EditGame from './slideout/EditGame';

import { hasPermission, userIs } from '../auth/authUtils';
import permissionTypes from '../../constants/permissionTypes';
import SlidingPane from 'react-sliding-pane';
import userTypes from '../../constants/userTypes';
import SteamBulkRequest from '../requests/slideout/SteamBulkRequest';
import { toast } from 'react-toastify';
import GameBulkActions from './slideout/GameBulkActions';
import { populateSkuKeyPools } from '../../util/steamUtils';
import RequestKeys from '../requests/slideout/RequestKeys';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

function Game({ location, currentUser, setCompleteRequestSlideoutOpen }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [addGameSlideoutOpen, setAddGameSlideoutOpen] = useState(false);
  const [itemsChecked, setItemsChecked] = useState([]);
  const [bulkSteamRequestSlideoutOpen, setBulkSteamRequestSlideoutOpen] =
    useState(false);
  const [bulkSteamRequestSlideoutSKUs, setBulkSteamRequestSlideoutSKUs] =
    useState();
  const [bulkActionsSlideoutOpen, setBulkActionsSlideoutOpen] = useState(false);
  const [requestKeysSlideoutOpen, setRequestKeysSlideoutOpen] = useState(false);
  const [requestKeysGameId, setRequestKeysGameId] = useState(false);
  const handleSteamBulkRequest = async (e, games) => {
    e.cancelBubble = true;
    const skus = await populateSkuKeyPools(
      games.map((x) => {
        return { id: x.id, gameName: x.name, skus: x.skuIds };
      })
    );
    if (skus.length > 0) {
      setBulkSteamRequestSlideoutSKUs(skus);
      setBulkSteamRequestSlideoutOpen(true);
    } else {
      toast.error(
        t(
          'None of the selected SKUs support key pools or the workflow is missing key provider reference'
        )
      );
    }
    return false;
  };
  return (
    <>
      <ActionBar>
        {hasPermission(currentUser, permissionTypes.MANAGE_GAMES) && (
          <Button
            className="btn btn-primary me-2"
            onClick={() => setAddGameSlideoutOpen(true)}
            text={t('Add game')}
          ></Button>
        )}
      </ActionBar>
      <GameList
        location={location}
        reload={reload}
        itemsChecked={itemsChecked}
        setItemsChecked={setItemsChecked}
        onRequestKeys={(e, item) => {
          setRequestKeysGameId(item.id);
          setRequestKeysSlideoutOpen(true);
        }}
        onSteamBulkRequest={handleSteamBulkRequest}
        setBulkActionsSlideoutOpen={
          userIs(currentUser, userTypes.UPLOADER) && setBulkActionsSlideoutOpen
        }
      />

      <SlidingPane
        isOpen={addGameSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddGameSlideoutOpen(false)}
      >
        <EditGame
          done={() => {
            setReload(new Date());
            setAddGameSlideoutOpen(false);
          }}
          cancel={() => setAddGameSlideoutOpen(false)}
        />
      </SlidingPane>
      <SlidingPane
        isOpen={bulkActionsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setBulkActionsSlideoutOpen(false)}
      >
        <GameBulkActions
          done={() => {
            setReload(new Date());
            setBulkActionsSlideoutOpen(false);
          }}
          cancel={() => setBulkActionsSlideoutOpen(false)}
          items={itemsChecked}
        ></GameBulkActions>
      </SlidingPane>
      <SlidingPane
        isOpen={bulkSteamRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="xlarge-side-panel"
        onRequestClose={() => setBulkSteamRequestSlideoutOpen(false)}
      >
        {bulkSteamRequestSlideoutSKUs ? (
          <SteamBulkRequest
            done={(d) => {
              setReload(new Date());
              setBulkSteamRequestSlideoutSKUs(null);
            }}
            cancel={() => setBulkSteamRequestSlideoutOpen(false)}
            skus={bulkSteamRequestSlideoutSKUs}
          ></SteamBulkRequest>
        ) : (
          <></>
        )}
      </SlidingPane>
      <SlidingPane
        isOpen={requestKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRequestKeysSlideoutOpen(false)}
      >
        <RequestKeys
          gameId={requestKeysGameId}
          done={() => {
            setRequestKeysSlideoutOpen(false);
            setCompleteRequestSlideoutOpen(true);
          }}
          cancel={() => setRequestKeysSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Game);
