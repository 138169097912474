import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import FormSelect from '../../../common/FormSelect';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import { savePlatformKeyProvider } from '../../../../actions/platformActions';
import { getKeyProviders } from '../../../../actions/keyProviderActions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';

function AddPlatformKeyProvider({
  platformId,
  done,
  cancel,
  keyProviders,
  getKeyProviders,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders]);

  const onSubmit = (data) => {
    savePlatformKeyProvider(platformId, data.keyProviderId)
      .then(() => {
        toast.success(t('Key provider successfully added to platform'));
        const keyProvider = keyProviders.find(
          (x) => x.id === data.keyProviderId
        );
        done(keyProvider);
      })
      .catch(() => {
        toast.error(t('Failed to add key provider to platform'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Add key provider')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          key="submit"
          text={t('Save')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <FormSelect
        register={register}
        required={true}
        error={errors.keyProviderId}
        errorMessage={t('Key provider is required')}
        name="keyProviderId"
        label={t('Key provider')}
        autoFocus={true}
      >
        <option value="">{t('--- choose ---')}</option>
        {keyProviders
          ? [...keyProviders]
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))
          : ''}
      </FormSelect>
    </SlideoutLayout>
  );
}

AddPlatformKeyProvider.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  platformId: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    keyProviders: state.keyProviders,
  };
}

const mapDispatchToProps = {
  getKeyProviders,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPlatformKeyProvider);
