import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import WizardStepItem from '../../requests/slideout/wizard/WizardStepItem';
import icons from '../../../constants/icons';
import { useTranslation } from 'react-i18next';

function ViewOptions({
  done,
  viewRequestsUrl,
  viewItemUrl,
  item,
  typeIcon,
  cancel,
  history,
}) {
  const { t } = useTranslation();
  const handleViewRequest = () => {
    history.push(viewRequestsUrl);
  };
  const handleViewItem = () => {
    history.push(viewItemUrl);
  };
  return (
    <SlideoutLayout
      title={t('What would you like to see?')}
      cancel={cancel}
      done={done}
    >
      <WizardStepItem
        title={t('View requests for {item}', { item: item.name })}
        icon={icons.WIZARD_NEXT}
        onClick={handleViewRequest}
        typeIcon={`${icons.REQUESTS} text-green`}
      ></WizardStepItem>
      <WizardStepItem
        title={t('View {item}', { item: item.name })}
        icon={icons.WIZARD_NEXT}
        typeImageId={item.logo}
        onClick={handleViewItem}
        typeIcon={`${typeIcon} text-green`}
      ></WizardStepItem>
    </SlideoutLayout>
  );
}

ViewOptions.propTypes = {
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  viewRequestsUrl: PropTypes.string,
  viewItemUrl: PropTypes.string,
  item: PropTypes.object,
  typeIcon: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(ViewOptions);
