import React, { useState } from 'react';
import { connect } from 'react-redux';
import DisableReasonsList from './components/DisableReasonsList';
import ActionBar from '../../layout/ActionBar';
import Edit from './slideout/Edit';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function DisableReasons({ location }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [addDisableReasonSlideoutOpen, setAddDisableReasonSlideoutOpen] =
    useState(false);

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={() => setAddDisableReasonSlideoutOpen(true)}
          text={t('Add disabled reason')}
        />
      </ActionBar>
      <DisableReasonsList location={location} reload={reload} />

      <SlidingPane
        isOpen={addDisableReasonSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddDisableReasonSlideoutOpen(false)}
      >
        <Edit
          done={(d) => {
            setReload(new Date());
            setAddDisableReasonSlideoutOpen(false);
          }}
          cancel={() => setAddDisableReasonSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DisableReasons);
