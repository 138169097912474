import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getMoveTransactionById,
  clearMoveTransaction,
} from '../../actions/keyCodeActions';
import MovedTransactionDetails from './components/MovedTransactionDetails';
import ActionBar from '../layout/ActionBar';
import { addToGroup, removeFromGroup } from '../../actions/signalRActions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function MovedTransaction({
  id,
  history,
  location,
  addToGroup,
  removeFromGroup,
  moveTransaction,
  clearMoveTransaction,
  currentUser,
}) {
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState();

  useEffect(() => {
    if (id) {
      // get transaction
      getMoveTransactionById(id)
        .then((d) => {
          setTransaction(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/keys/moved/');
          toast.error(t('Transaction not found'));
        });
    }
  }, [id, history, t]);

  useEffect(() => {
    if (
      transaction &&
      (transaction.status === 'Pending' ||
        transaction.status === 'Pending Authorisation')
    ) {
      const key = `move:${transaction.id}`;
      addToGroup(key);
      return () => removeFromGroup(key);
    }
  }, [transaction, addToGroup, removeFromGroup]);

  useEffect(() => {
    if (moveTransaction && moveTransaction.id === id) {
      setTransaction((prevState) => ({
        ...prevState,
        ...moveTransaction,
      }));
      return () => clearMoveTransaction();
    }
  }, [moveTransaction, id, clearMoveTransaction]);

  return (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/keys/moved', label: t('Moved transactions') },
          { label: t('Move transaction') },
        ]}
      ></ActionBar>
      {transaction && (
        <MovedTransactionDetails
          transaction={transaction}
          currentUser={currentUser}
        />
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    moveTransaction: state.moveTransaction,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  addToGroup,
  removeFromGroup,
  clearMoveTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MovedTransaction);
