import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ActionBar from '../../layout/ActionBar';
import DisableReasonForm from './components/DisableReasonForm';
import {
  saveDisableReason,
  getDisableReasonById,
} from '../../../actions/keyCodeActions';
import Loading from '../../common/Loading';
import Delete from './slideout/Delete';
import SlidingPane from 'react-sliding-pane';
import { useTranslation } from 'react-i18next';
import ResponsiveActionBarButtons from '../../common/ResponsiveActionBarButtons';
import Button from '../../common/Button';

function DisableReason({ id, history, saveDisableReason }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);

  const handleDelete = (e) => {
    e.cancelBubble = true;
    setDeleteSlideoutOpen(true);
    return false;
  };
  const onSubmit = (data) => {
    saveDisableReason({ ...disableReason, ...data }).then(() => {
      toast.success(t('Disabled reason saved'));
    });
  };

  const [disableReason, setDisableReason] = useState({});

  useEffect(() => {
    if (id) {
      getDisableReasonById(id)
        .then((d) => {
          setDisableReason(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/settings/disabled-reasons/');
          toast.error(t('Disabled reason not found'));
        });
    }
  }, [id, history, t]);

  return disableReason ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/settings/disabled-reasons', label: t('Disabled reasons') },
          { label: disableReason.reason },
        ]}
      >
        <div className="d-flex">
          <ResponsiveActionBarButtons
            buttons={[
              id && (
                <Button
                  className="btn btn-danger ms-2 d-none d-md-inline-flex"
                  onClick={handleDelete}
                  text={t('Delete')}
                />
              ),
              <Button
                key="save"
                className="btn btn-primary ms-2"
                onClick={handleSubmit(onSubmit)}
                text={t('Save')}
              />,
            ]}
          />
        </div>
      </ActionBar>
      <DisableReasonForm
        disableReason={disableReason}
        register={register}
        errors={errors}
        control={control}
      ></DisableReasonForm>

      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          done={(d) => {
            history.push('/settings/disabled-reasons/');
            setDeleteSlideoutOpen(false);
          }}
          cancel={() => setDeleteSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
  };
}

const mapDispatchToProps = {
  saveDisableReason,
};

export default connect(mapStateToProps, mapDispatchToProps)(DisableReason);
