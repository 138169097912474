import React from 'react';
import PropTypes from 'prop-types';
import { isPowerUser } from '../../auth/authUtils';
import AutoSelectText from '../../common/AutoSelectText';

const PackageId = ({ request, compressed, currentUser }) => {
  const canAccessProviderUrl = (request) => {
    return isPowerUser(currentUser) && request.keyProviderUrl;
  };
  return compressed ? (
    <>
      {canAccessProviderUrl(request) ? (
        <a
          href={request.keyProviderUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {request.packageId}
        </a>
      ) : (
        <AutoSelectText>{request.packageId}</AutoSelectText>
      )}
    </>
  ) : (
    <div className="col-6">
      <label className="col-form-label">{request.packageIdLabel}</label>
      <div className="flex ps-0">
        <div className="form-control-static px-3 flex h-100 w-100 align-items-center">
          {canAccessProviderUrl(request) ? (
            <a
              href={request.keyProviderUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {request.packageId}
            </a>
          ) : (
            <AutoSelectText>{request.packageId}</AutoSelectText>
          )}
        </div>
      </div>
    </div>
  );
};

PackageId.propTypes = {
  request: PropTypes.object.isRequired,
  compressed: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
};

export default PackageId;
