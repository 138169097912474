import * as reportApi from '../api/reportByMonthApi';

export function loadRequestsByMonthByGame({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByMonthByGame(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadRequestsByMonthBySKU({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByMonthBySKU(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadKeysByMonthByGame({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByMonthByGame(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadKeysByMonthBySku({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByMonthBySku(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadKeysByMonthByCompany({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByMonthByCompany(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadRequestsByMonthByCompany({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByMonthByCompany(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadRequestsByMonthByAuthorisingUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByMonthByAuthorisingUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportRequestsByMonthByAuthorisingUser(year, filters, scopes) {
  return reportApi.exportRequestsByMonthByAuthorisingUser(
    year,
    filters,
    scopes
  );
}

export function loadKeysByMonthByAuthorisingUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByMonthByAuthorisingUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportKeysByMonthByAuthorisingUser(year) {
  return reportApi.exportKeysByMonthByAuthorisingUser(year);
}

export function loadRequestsByMonthByUploadingUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByMonthByUploadingUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportRequestsByMonthByUploadingUser(year, filters, scopes) {
  return reportApi.exportRequestsByMonthByUploadingUser(year, filters, scopes);
}

export function loadKeysByMonthByUploadingUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByMonthByUploadingUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportKeysByMonthByUploadingUser(year, filters, scopes) {
  return reportApi.exportKeysByMonthByUploadingUser(year, filters, scopes);
}

export function exportRequestsByMonthByGame(year, company, filters, scopes) {
  return reportApi.exportRequestsByMonthByGame(year, company, filters, scopes);
}

export function exportRequestsByMonthBySKU(year, company, filters, scopes) {
  return reportApi.exportRequestsByMonthBySKU(year, company, filters, scopes);
}

export function exportKeysByMonthByGame(year, company, filters, scopes) {
  return reportApi.exportKeysByMonthByGame(year, company, filters, scopes);
}

export function exportKeysByMonthBySku(year, company, filters, scopes) {
  return reportApi.exportKeysByMonthBySku(year, company, filters, scopes);
}

export function exportKeysByMonthByCompany(year, filters, scopes) {
  return reportApi.exportKeysByMonthByCompany(year, filters, scopes);
}
export function exportRequestsByMonthByCompany(year, filters, scopes) {
  return reportApi.exportRequestsByMonthByCompany(year, filters, scopes);
}

export function loadUsersModifiedByMonthByUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getUsersModifiedByMonthByUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportUsersModifiedByMonthByUser(year, filters, scopes) {
  return reportApi.exportUsersModifiedByMonthByUser(year, filters, scopes);
}

export function loadKeyWorkflowsModifiedByMonthByUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeyWorkflowsModifiedByMonthByUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportKeyWorkflowsModifiedByMonthByUser(year, filters, scopes) {
  return reportApi.exportKeyWorkflowsModifiedByMonthByUser(
    year,
    filters,
    scopes
  );
}

export function loadGamesModifiedByMonthByUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getGamesModifiedByMonthByUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportGamesModifiedByMonthByUser(year, filters, scopes) {
  return reportApi.exportGamesModifiedByMonthByUser(year, filters, scopes);
}

export function loadSKUsModifiedByMonthByUser({
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getSKUsModifiedByMonthByUser(
    year,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportSKUsModifiedByMonthByUser(year, filters, scopes) {
  return reportApi.exportSKUsModifiedByMonthByUser(year, filters, scopes);
}

export function loadRequestsByMonthByWorkflow({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getRequestsByMonthByWorkflow(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportRequestsByMonthByWorkflow(
  year,
  company,
  filters,
  scopes
) {
  return reportApi.exportRequestsByMonthByWorkflow(
    year,
    company,
    filters,
    scopes
  );
}

export function loadKeysByMonthByWorkflow({
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByMonthByWorkflow(
    year,
    company,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function exportKeysByMonthByWorkflow(year, company, filters, scopes) {
  return reportApi.exportKeysByMonthByWorkflow(year, company, filters, scopes);
}
