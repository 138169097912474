import React from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';

import { cloneGameSku } from '../../../actions/gameActions';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import CloneSkuForm from '../components/CloneSkuForm';
import { useTranslation } from 'react-i18next';

function Clone({ done, id, gameId, cancel }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = (data) => {
    cloneGameSku({ ...data, skuId: id, gameId })
      .then((newId) => {
        toast.success(t('Sku successfully cloned'));
        done(newId);
      })
      .catch(() => {
        toast.error(t('Failed to clone sku'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Clone sku')}
      cancel={cancel}
      done={done}
      bar={
        <input
          type="submit"
          value={t('Clone')}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
      }
    >
      <CloneSkuForm register={register} errors={errors} control={control} />
    </SlideoutLayout>
  );
}
Clone.propTypes = {
  id: PropTypes.string.isRequired,
  gameId: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default Clone;
