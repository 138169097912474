import React from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import KwPanel from '../../../common/KwPanel';

import { deletePlatformKeyProvider } from '../../../../actions/platformActions';
import { toast } from 'react-toastify';
import Button from '../../../common/Button';
import ConfirmationHeader from '../../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function DeletePlatformKeyProvider({
  done,
  keyProviderId,
  platformId,
  cancel,
}) {
  const { t } = useTranslation();
  const handleDelete = () => {
    deletePlatformKeyProvider(platformId, keyProviderId)
      .then(() => {
        toast.success(t('Key provider successfully deleted from platform'));
        done();
      })
      .catch(() => {
        toast.error(t('Failed to delete key provider from platform'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Delete confirmation')}
      cancel={cancel}
      done={done}
    >
      <KwPanel>
        <ConfirmationHeader text={t('Are you sure?')} />
        <Button
          className="btn btn-danger me-2"
          onClick={handleDelete}
          text={t('Yes, delete the key provider on this platform')}
        />
        <Button
          className="btn btn-default"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
DeletePlatformKeyProvider.propTypes = {
  keyProviderId: PropTypes.string,
  platformId: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default DeletePlatformKeyProvider;
