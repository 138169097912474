import actionTypes from '../constants/actionTypes';
import { getByUsername } from '../api/userApi';
import { toast } from 'react-toastify';
export function configureSubscriptions(connection, dispatch) {
  // KEY PROVIDER ---------------------------------------------------
  connection.on('KeyProviderCreated', (keyProvider) => {
    dispatch({ type: actionTypes.CREATE_KEYPROVIDER, keyProvider });
  });

  connection.on('KeyProviderDeleted', (id) => {
    dispatch({ type: actionTypes.DELETE_KEYPROVIDER, id });
  });

  // PLATFORM ---------------------------------------------------
  connection.on('PlatformCreated', (platform) => {
    dispatch({ type: actionTypes.CREATE_PLATFORM, platform });
  });

  connection.on('PlatformDeleted', (id) => {
    dispatch({ type: actionTypes.DELETE_PLATFORM, id });
  });

  // COMPANY ---------------------------------------------------
  connection.on('CompanyCreated', (company) => {
    dispatch({ type: actionTypes.CREATE_COMPANY, company });
  });
  connection.on('ConpanyDeleted', (id) => {
    dispatch({ type: actionTypes.DELETE_COMPANY, id });
  });

  // COMPANYTYPE ---------------------------------------------------
  connection.on('CompanyTypeCreated', (companyType) => {
    dispatch({
      type: actionTypes.CREATE_COMPANYTYPE,
      companyType: companyType,
    });
  });
  connection.on('CompanyTypeUpdated', (companyType) => {
    dispatch({
      type: actionTypes.UPDATE_COMPANYTYPE,
      companyType: companyType,
    });
  });
  connection.on('ConpanyTypeDeleted', (id) => {
    dispatch({ type: actionTypes.DELETE_COMPANYTYPE, id });
  });

  // WORKFLOW ---------------------------------------------------
  connection.on('KeyWorkflowCreated', (workflow) => {
    dispatch({ type: actionTypes.CREATE_WORKFLOW, workflow });
  });
  connection.on('KeyWorkflowDeleted', (id) => {
    dispatch({ type: actionTypes.DELETE_WORKFLOW, id });
  });
  connection.on('KeyWorkflowUpdated', (workflow) => {
    dispatch({ type: actionTypes.UPDATE_WORKFLOW, workflow });
  });

  // GAME ---------------------------------------------------
  connection.on('GameCreated', (game) => {
    dispatch({ type: actionTypes.CREATE_GAME, game });
  });
  connection.on('GameUpdated', (game) => {
    dispatch({ type: actionTypes.UPDATE_GAME, game });
  });
  connection.on('GameUploadProcessing', (progress) => {
    dispatch({
      type: actionTypes.GAME_UPLOAD_PROCESSING,
      progress,
    });
  });

  // GAME SKU ---------------------------------------------------
  connection.on('GameSKUCreated', (sku) => {
    dispatch({ type: actionTypes.CREATE_GAMESKU, sku });
  });
  connection.on('GameSKUUpdated', (sku) => {
    dispatch({ type: actionTypes.UPDATE_GAMESKU, sku });
  });

  // TERRITORY ---------------------------------------------------
  connection.on('TerritoryCreated', (territory) => {
    dispatch({ type: actionTypes.CREATE_TERRITORY, territory });
  });
  connection.on('TerritoryDeleted', (id) => {
    dispatch({ type: actionTypes.DELETE_TERRITORY, id });
  });

  // PACKAGE ---------------------------------------------------
  connection.on('PackageTypeCreated', (packageType) => {
    dispatch({ type: actionTypes.CREATE_PACKAGETYPE, packageType });
  });

  // STEAM REQUEST ---------------------------------------------
  connection.on('SteamRequestUpdated', (steamRequest) => {
    dispatch({ type: actionTypes.UPDATE_STEAMREQUEST, steamRequest });
  });

  //IMPORT TRANSACTIONS ----------------------------------------
  connection.on('UpdatedKeyCodeProcessingProgress', (importTransaction) => {
    dispatch({
      type: actionTypes.UPDATE_IMPORT_TRANSACTION,
      transaction: importTransaction,
    });
  });
  connection.on('KeyCodeProcessingFailed', (importTransaction) => {
    dispatch({
      type: actionTypes.UPDATE_IMPORT_TRANSACTION,
      transaction: importTransaction,
    });
  });

  //ACTIVATIONS --------------------------------------------------
  connection.on('ActivatedKeyCodeProcessingFailed', (activation) => {
    dispatch({
      type: actionTypes.UPDATE_ACTIVATION,
      transaction: activation,
    });
  });

  connection.on('UpdatedActivatedKeyCodeProcessingProgress', (activation) => {
    dispatch({
      type: actionTypes.UPDATE_ACTIVATION,
      transaction: activation,
    });
  });

  //DISABLED TRANSACTIONS --------------------------------------------
  connection.on('DisabledKeyCodeProcessingFailed', (disabledTransaction) => {
    dispatch({
      type: actionTypes.UPDATE_DISABLE_TRANSACTION,
      transaction: disabledTransaction,
    });
  });

  connection.on(
    'UpdatedDisabledKeyCodeProcessingProgress',
    (disabledTransaction) => {
      dispatch({
        type: actionTypes.UPDATE_DISABLE_TRANSACTION,
        transaction: disabledTransaction,
      });
    }
  );

  connection.on('UpdatedDisabledImportTransaction', (disabledTransaction) => {
    dispatch({
      type: actionTypes.UPDATE_DISABLE_TRANSACTION,
      transaction: { ...disabledTransaction, status: 'reload' },
    });
  });

  //SOLD TRANSACTIONS --------------------------------------------
  connection.on('SoldKeyCodeProcessingFailed', (soldTransaction) => {
    dispatch({
      type: actionTypes.UPDATE_SOLD_TRANSACTION,
      transaction: soldTransaction,
    });
  });

  connection.on('UpdatedSoldKeyCodeProcessingProgress', (soldTransaction) => {
    dispatch({
      type: actionTypes.UPDATE_SOLD_TRANSACTION,
      transaction: soldTransaction,
    });
  });

  //MOVE TRANSACTIONS --------------------------------------------
  connection.on('MoveKeyCodeProcessingFailed', (moveTransaction) => {
    dispatch({
      type: actionTypes.UPDATE_MOVE_TRANSACTION,
      transaction: moveTransaction,
    });
  });

  connection.on('UpdatedMoveKeyCodeProcessingProgress', (moveTransaction) => {
    dispatch({
      type: actionTypes.UPDATE_MOVE_TRANSACTION,
      transaction: moveTransaction,
    });
  });

  //BULK UPLOAD --------------------------------------------------
  connection.on('KeyCodeBulkProcessingFailed', (bulk) => {
    dispatch({
      type: actionTypes.UPDATE_BULK_TRANSACTION,
      transaction: bulk,
    });
  });

  connection.on('KeyCodeBulkProcessingProgress', (bulk) => {
    dispatch({
      type: actionTypes.UPDATE_BULK_TRANSACTION,
      transaction: bulk,
    });
  });

  //USER --------------------------------------------
  connection.on('PermissionsChanged', () => {
    getByUsername()
      .then((currentUser) => {
        dispatch({
          type: actionTypes.USER_PERMISSIONS_CHANGED,
          user: currentUser,
        });
      })
      .catch((e) => {
        console.error('Failed to update permissions');
      });
  });

  // KEY REQUEST --------------------------------------
  connection.on('KeyRequestGroupSubmitted', (keyRequestGroup) => {
    dispatch({
      type: actionTypes.KEYREQUEST_SUBMITTED,
      keyRequestGroupId: keyRequestGroup.keyRequestGroupId,
    });
    toast.success('Successfully submitted');
  });

  // SIGNALR --------------------------------------------
  connection.on('UserConnected', (user) => {
    dispatch({
      type: actionTypes.USER_CONNECTED,
      user,
    });
  });

  connection.on('UserDisconnected', (user) => {
    dispatch({
      type: actionTypes.USER_DISCONNECTED,
      user,
    });
  });

  connection.on('UserActive', (username) => {
    dispatch({
      type: actionTypes.USER_ACTIVE,
      username,
    });
  });

  connection.on('UserInactive', (username) => {
    dispatch({
      type: actionTypes.USER_INACTIVE,
      username,
    });
  });

  // ANNOUNCEMENTS ---------------------------------------------------
  connection.on('AnnouncementCreated', (announcement) => {
    dispatch({
      type: actionTypes.CREATE_ANNOUNCEMENT,
      announcement,
    });
  });
  connection.on('AnnouncementUpdated', (announcement) => {
    dispatch({
      type: actionTypes.UPDATE_ANNOUNCEMENT,
      announcement,
    });
  });
  connection.on('AnnouncementDeleted', (announcement) => {
    dispatch({ type: actionTypes.DELETE_ANNOUNCEMENT, id: announcement.id });
  });
}
