import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import SlidingPane from 'react-sliding-pane';
import SteamBulkRequest from '../../requests/slideout/SteamBulkRequest';
import { toast } from 'react-toastify';
import { populateSkuKeyPools } from '../../../util/steamUtils';
import icons from '../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function GameBulkActions({ done, cancel, items }) {
  const { t } = useTranslation();
  const [bulkSteamRequestSlideoutOpen, setBulkSteamRequestSlideoutOpen] =
    useState(false);
  const [bulkSteamRequestSlideoutSKUs, setBulkSteamRequestSlideoutSKUs] =
    useState();

  const handleSteamBulkRequest = async (e) => {
    e.cancelBubble = true;
    const skus = await populateSkuKeyPools(
      items.map((x) => {
        return { id: x.id, skus: x.skuIds };
      })
    );
    if (skus.length > 0) {
      setBulkSteamRequestSlideoutSKUs(skus);
      setBulkSteamRequestSlideoutOpen(true);
    } else {
      toast.error(
        t(
          'None of the selected SKUs support key pools or the workflow is missing key provider reference'
        )
      );
    }
    return false;
  };

  const BulkActionItem = ({ title, description, icon, onClick }) => (
    <div
      className="col-sm-12 template p-0 bulk action-update"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <div className="template-detail p-4">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="icon-wrapper">
        <FontAwesomeIcon icon={icon} />
      </div>
    </div>
  );
  return (
    <>
      <SlideoutLayout title={t('Bulk actions')} cancel={cancel} done={done}>
        <BulkActionItem
          title={t('Request on Steam')}
          description={t('Send an automated request to Steam')}
          icon={icons.REQUEST_ON_STEAM}
          onClick={handleSteamBulkRequest}
        ></BulkActionItem>
      </SlideoutLayout>
      <SlidingPane
        isOpen={bulkSteamRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setBulkSteamRequestSlideoutOpen(false)}
      >
        <SteamBulkRequest
          done={(d) => {
            setBulkSteamRequestSlideoutSKUs(null);
            done();
          }}
          cancel={() => setBulkSteamRequestSlideoutOpen(false)}
          skus={bulkSteamRequestSlideoutSKUs}
        ></SteamBulkRequest>
      </SlidingPane>
    </>
  );
}
GameBulkActions.propTypes = {
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

export default GameBulkActions;
