import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  loadRequestsToBeApprovedGrouped,
  exportApprovalRequestData,
} from '../../../actions/requestActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';

import {
  CommaNumber_NoDecimal,
  CommaNumber_WithDecimal,
  Date_AsString_OneLine,
  WorkflowWithJustification,
} from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import GameLink from '../../common/links/GameLink';
import SlideoutRequestDetail from '../slideout/RequestDetails';
import RequestDetailsSignatures from '../slideout/RequestDetailsSignatures';
import SlidingPane from 'react-sliding-pane';
import TimeLabel from '../../common/TimeLabel';
import UserLink from '../../common/links/UserLink';
import CompanyLink from '../../common/links/CompanyLink';
import { useTranslation } from 'react-i18next';

function RequestsToBeApprovedGroupedList({
  location,
  history,
  reload,
  currentUser,
  tableViewSwitcher,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'reference',
    'games',
    'requestedByCompanyName',
    'keyWorkflowName',
    'requestedByFullName',
    'requestedDate',
    'numberOfKeys',
    'currentRequestValue',
    'currentUserProfileGroupNames',
    'assignedToUserProfileFullName',
  ];
  const defaultVisibleColumns = allColumns;

  const [requests, setRequests] = useState();
  const params = new URLSearchParams(location?.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'requeststobeapprovedlist-settings2',
      getTableSettingsDefaults({
        defaultSort: 'groupReference',
        defaultVisibleColumns,
      }),
      null,
      loadRequestsToBeApprovedGrouped,
      setRequests,
      reload,
      t('Failed to load requests'),
      location
    );

  // slideout panel
  const [requestDetailsSlideoutItem, setRequestDetailsSlideoutItem] =
    useState();
  const [requestDetailsSlideoutOpen, setRequestDetailsSlideoutOpen] =
    useState(false);
  const showWaitingFor = (e, row) => {
    e.cancelBubble = true;
    setRequestDetailsSlideoutItem(row);
    setRequestDetailsSlideoutOpen(true);
    return false;
  };

  const [signaturesSlideoutOpen, setSignaturesSlideoutOpen] = useState(false);
  const [signaturesSlideoutItem, setSignaturesSlideoutItem] = useState();
  let handleShowSignatures = (e, requestStep) => {
    setSignaturesSlideoutItem(requestStep);
    setSignaturesSlideoutOpen(true);
  };

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'reference',
      Label: '#',
      Render: (row) => <Link to={'/request/' + row.id}>{row.reference}</Link>,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'games',
      Label: t('Games'),
      Render: (row) =>
        row.games && row.games.length > 0
          ? row.games.map((x, i) => (
              <GameLink
                gameId={x.id}
                skuId={x.skuId}
                gameTitle={x.gameTitle}
                skuName={x.skuName}
                currentUser={currentUser}
                key={`${x.id},${i}`}
                className={i < row.games.length - 1 ? 'space-bottom' : ''}
              />
            ))
          : '',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedByCompanyName',
      Label: t('Company'),
      Render: (row) => (
        <CompanyLink
          id={row.requestedByCompanyId}
          name={row.requestedByCompanyName}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyWorkflowName',
      Label: t('Type'),
      Render: (row) => WorkflowWithJustification(row),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedByFullName',
      Label: t('Requested by'),
      Render: (row) => (
        <>
          <UserLink id={row.requestedById} name={row.requestedByFullName} />
          <br />
          {row.requestedByCompanyName}
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'numberOfKeys',
      Label: t('Keys'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.numberOfKeys),
    },
    {
      Key: 'currentRequestValue',
      Label: t('Value'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_WithDecimal(row.currentRequestValue),
    },
    {
      Key: 'currentUserProfileGroupNames',
      Label: t('Waiting for'),
      Render: (row) =>
        row.currentUserProfileGroupNames &&
        row.currentUserProfileGroupNames.length > 0
          ? row.currentUserProfileGroupNames.map((x) => (
              <div
                className="a"
                key={x}
                onClick={(e) => showWaitingFor(e, row)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    showWaitingFor(e, row);
                  }
                }}
                tabIndex={0}
              >
                {x}
              </div>
            ))
          : '',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'assignedToUserProfileFullName',
      Label: t('Assigned to'),
      Render: (row) => (
        <UserLink
          id={row.assignedToUserProfileId}
          name={row.assignedToUserProfileFullName}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedDate',
      width: '10%',
      Label: t('Request date'),
      Render: (row) => (
        <>
          <div>{Date_AsString_OneLine(row.requestedDate)}</div>

          <TimeLabel
            elapsedMinutesFormatted={row.elapsedMinutesFormatted}
            breached={row.isSLABreached}
            ongoing={row.requestCompletionDate ? false : true}
          />
        </>
      ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return requests?.data ? (
    <>
      <KwPanel className="mb-4">
        <Table
          columns={columns}
          allColumns={allColumns}
          rows={requests.data.map((x) => {
            return { Key: x.id, ...x };
          })}
          totalRowCount={requests.totalRowCount}
          filteredRowCount={requests.filteredRowCount}
          scopes={requests.scopes}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          isLoading={isLoading}
          filters={filters}
          filterChange={filterChange}
          className="td-align-top"
          tableActionBarButtons={tableViewSwitcher}
          exportData={exportApprovalRequestData}
        />
      </KwPanel>

      <SlidingPane
        isOpen={requestDetailsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setRequestDetailsSlideoutOpen(false)}
      >
        <>
          {requestDetailsSlideoutItem && (
            <SlideoutRequestDetail
              done={(d) => {
                setRequestDetailsSlideoutOpen(false);
              }}
              cancel={() => setRequestDetailsSlideoutOpen(false)}
              history={history}
              requestGroupId={requestDetailsSlideoutItem.id}
              requestId={requestDetailsSlideoutItem.games[0].requestId}
              onShowSignatures={handleShowSignatures}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={signaturesSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setSignaturesSlideoutOpen(false)}
      >
        <>
          {signaturesSlideoutItem && (
            <RequestDetailsSignatures
              done={(d) => {
                setSignaturesSlideoutOpen(false);
              }}
              cancel={() => setSignaturesSlideoutOpen(false)}
              requestStep={signaturesSlideoutItem}
            />
          )}
        </>
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

RequestsToBeApprovedGroupedList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  reload: PropTypes.object,
  tableViewSwitcher: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsToBeApprovedGroupedList);
