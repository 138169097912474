import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import FormFileUpload from '../../common/FormFileUpload';
import {
  uploadAccountDetails,
  getAccountDetails,
  saveAccountDetails,
  sendAccountDetailEmail,
  deleteAccountDetail,
} from '../../../actions/requestActions';
import { toast } from 'react-toastify';
import { Date_AsString_Raw } from '../../../util/formatter';
import KwPanel from '../../common/KwPanel';

import Loading from '../../common/Loading';
import { hasAnyPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import { NavLink } from 'react-router-dom';
import Button from '../../common/Button';
import icons from '../../../constants/icons';
import { useTranslation } from 'react-i18next';

const UploadAccountDetails = ({
  done,
  cancel,
  id,
  downloadAccountDetailsTemplate,
  uploadAccountDetails,
  downloadAccountDetails,
  numberOfKeys,
  deleteAccountDetail,
  currentUser,
  onUploadSuccess,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [accountDetail, setAccountDetail] = useState({ id });
  const [accountDetails, setAccountDetails] = useState();
  const [showRemove, setShowRemove] = useState(false);

  const toggleShowRemove = (id) => {
    setShowRemove(!showRemove);
  };
  const addFiles = (files) => {
    setAccountDetail({ ...accountDetail, file: files[0] });
  };
  const onUpload = () => {
    if (accountDetail.file) {
      uploadAccountDetails(id, accountDetail)
        .then((d) => {
          toast.success(t('Successfully uploaded account details'));
          setAccountDetails((prevState) => [...prevState, ...d]);
          if (onUploadSuccess) {
            onUploadSuccess();
          }
        })
        .catch((e) =>
          toast.error(e.message || t('Failed to upload account details'))
        );
    } else {
      toast.error(t('Please select a file before clicking Upload'));
    }
  };
  useEffect(() => {
    if (!accountDetails) {
      getAccountDetails(id)
        .then((data) => {
          setAccountDetails(data);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(t('Failed to get account details'));
        });
    }
  }, [accountDetails, id]);

  const sendEmail = (accountDetail) => {
    return sendAccountDetailEmail(id, accountDetail.keyRequestAccountDetailsId)
      .then((data) => {
        setAccountDetails((prevState) =>
          prevState?.map((existing) =>
            existing.keyRequestAccountDetailsId ===
            data.keyRequestAccountDetailsId
              ? data
              : existing
          )
        );
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to send email'));
      });
  };

  const handleSendEmail = (accountDetail) => {
    sendEmail(accountDetail).then(() => {
      toast.success(t('Email sent successfully'));
    });
  };

  const handleSendAllEmails = () => {
    const accountDetailsToSend = accountDetails?.filter(
      (item) => item.canSendEmail && !item.emailSentDate
    );
    let promises = [];
    accountDetailsToSend.forEach((accountDetail) => {
      promises.push(sendEmail(accountDetail));
    });
    Promise.all(promises).then(() => {
      toast.success(t('Emails sent successfully'));
    });
  };

  const handleDelete = (accountDetail) => {
    deleteAccountDetail(id, accountDetail.keyRequestAccountDetailsId)
      .then(() => {
        toast.success(t('Account removed'));
        setAccountDetails((prevState) =>
          prevState?.filter(
            (x) =>
              x.keyRequestAccountDetailsId !==
              accountDetail.keyRequestAccountDetailsId
          )
        );
      })
      .catch((e) => {
        toast.error(t('Failed to remove account'));
      });
  };

  return (
    <SlideoutLayout title={t('Account details')} cancel={cancel} done={done}>
      {!isLoading ? (
        <>
          {numberOfKeys > accountDetails?.length && (
            <KwPanel className="mb-4">
              <header className="flex-wrap row-gap-5">
                <h2 className="flex-grow-1 flex-shrink-0 flex-basis-100">
                  {t('Upload remaining {number} accounts', {
                    number: numberOfKeys - accountDetails?.length,
                  })}
                </h2>
                <div className="d-flex flex-wrap col-gap-5 row-gap-5 flex-grow-1">
                  <Button
                    className="btn btn-default btn-xs-full"
                    onClick={downloadAccountDetailsTemplate}
                    text={t('Download account details template')}
                  />
                </div>
              </header>
              <FormFileUpload
                name="files"
                required
                errorMessage={t('Required')}
                label={t('Upload account details file')}
                fileExtension=".xlsx"
                addFiles={addFiles}
              />
              <input
                type="submit"
                value={t('Upload')}
                className="btn btn-primary btn-xs-full mt-3"
                onClick={onUpload}
              />
            </KwPanel>
          )}
          {accountDetails && accountDetails.length > 0 && (
            <KwPanel>
              <header className="flex-wrap row-gap-5">
                <div className="d-flex flex-wrap col-gap-5 row-gap-5 flex-grow-1">
                  <Button
                    className="btn btn-default btn-xs-full"
                    onClick={downloadAccountDetails}
                    text={t('Download')}
                  />
                  <Button
                    className="btn btn-default btn-xs-full"
                    isDisabled={
                      accountDetails?.filter(
                        (item) => item.canSendEmail && !item.emailSentDate
                      ).length === 0
                    }
                    onClick={handleSendAllEmails}
                    text={t('Send all emails')}
                  />
                  {accountDetails?.filter((item) => !item.canSendEmail).length >
                    0 && (
                    <Button
                      className={`btn ${
                        showRemove ? 'btn-default' : ''
                      } btn-xs-full ms-auto me-md-3`}
                      onClick={toggleShowRemove}
                      icon={icons.DELETE}
                      text={t('Remove')}
                      hideTitle={true}
                    />
                  )}
                </div>
              </header>
              <div className="table-wrapper">
                <table className="table table-has-buttons jsDataTable width-100 space-bottom mt-4">
                  <thead>
                    <tr>
                      <th>{t('Login')}</th>
                      <th>{t('Name')}</th>
                      <th>{t('Email address')}</th>
                      <th>{t('Status')}</th>
                      <th style={{ minWidth: '68px', width: '68px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountDetails?.map((item) => (
                      <React.Fragment key={item.login}>
                        <tr>
                          <td>
                            {hasAnyPermission(currentUser, [
                              permissionTypes.MANAGE_KEYS,
                              permissionTypes.KEYS_SEARCH,
                            ]) ? (
                              <NavLink
                                to={`/keys/account/${item.accountDetailId}`}
                              >
                                {item.login}
                              </NavLink>
                            ) : (
                              item.login
                            )}
                          </td>
                          <td>{item.name}</td>
                          <td>{item.emailAddress}</td>
                          <td>
                            {!item.canSendEmail ? (
                              item.emailAddress ? (
                                <span className="badge bg-info">
                                  {t('Pending key')}
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  {t('No email address')}
                                </span>
                              )
                            ) : !item.emailSentDate ? (
                              <Button
                                className="btn btn-default"
                                onClick={() => handleSendEmail(item)}
                                text={t('Send email')}
                              />
                            ) : !item.keyDownloaded ? (
                              <span
                                className="badge bg-info"
                                data-tooltip={`Email sent: ${Date_AsString_Raw(
                                  item.emailSentDate
                                )}`}
                              >
                                {t('Sent')}
                              </span>
                            ) : (
                              <span
                                className="badge bg-success"
                                data-tooltip={`Retrieved: ${Date_AsString_Raw(
                                  item.keyDownloaded
                                )}`}
                              >
                                {t('Retrieved')}
                              </span>
                            )}
                          </td>
                          <td className="text-right">
                            {!item.canSendEmail && (
                              <Button
                                className="btn btn-default btn-sm btn-xs-full show-loading"
                                style={{
                                  display: showRemove ? 'block' : 'none',
                                }}
                                onClick={() => handleDelete(item)}
                                icon={icons.DELETE}
                                text={t('Remove')}
                                hideTitle={true}
                              />
                            )}
                          </td>
                        </tr>
                        {item.notes && (
                          <tr>
                            <td colSpan="5">
                              {t('Notes: {notes}', { notes: item.notes })}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </KwPanel>
          )}
        </>
      ) : (
        <Loading></Loading>
      )}
    </SlideoutLayout>
  );
};
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  uploadAccountDetails,
  saveAccountDetails,
  deleteAccountDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadAccountDetails);
