import React, { useState } from 'react';
import GameKeysList from './components/GameKeysList';
import ActionBar from '../layout/ActionBar';
import { useTranslation } from 'react-i18next';

function GameKeys({ location }) {
  const { t } = useTranslation();
  const [reload] = useState();
  const [breadcrumb, setBreadcrumb] = useState([
    { label: t('Game keys'), link: '/keys/game' },
  ]);
  return (
    <>
      <ActionBar breadcrumb={breadcrumb}></ActionBar>
      <GameKeysList
        location={location}
        reload={reload}
        setBreadcrumb={setBreadcrumb}
      />
    </>
  );
}

export default GameKeys;
