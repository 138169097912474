const icons = Object.freeze({
  ACCORDIAN_CLOSED: 'fa-sharp fa-chevron-down',
  ACCORDIAN_OPENED: 'fa-sharp fa-chevron-up',
  ADD: 'fal fa-plus-circle',
  ALLOW: 'fal fa-circle-check',
  ANNOUNCEMENT: 'fa-sharp fa-light fa-bell',
  APPROVE: 'fal fa-circle-check',
  APPROVE_REQUESTS: 'fal fa-thumbs-up',
  SET_STATUS: 'fal fa-thumbs-up',
  APP_ID: 'fal fa-memo',
  ARCHIVED: 'fas fa-skull',
  ARE_YOU_SURE: 'fas fa-triangle-exclamation',
  AUDIT: 'fa-sharp fa-clock-rotate-left',
  BASKET: 'fa-thin fa-shopping-cart',
  BATCHES: 'fal fa-box-open',
  BULK_REQUEST: 'fal fa-cubes-stacked',
  CALENDAR: 'fal fa-calendar-days',
  CARET_DOWN: 'fal fa-caret-down',
  CHANGE_STATUS: 'fal fa-layer-plus',
  CHEVRON_LEFT: 'fa-sharp fa-chevron-left',
  CLOSE: 'fas fa-times',
  COMPANY: 'fal fa-building',
  CONFIRM_REQUEST: 'fa-sharp fa-check',
  CUBES_STACKED: 'fal fa-cubes-stacked',
  DECLINE: 'fal fa-circle-xmark',
  DELETE: 'fa-sharp fa-xmark',
  DELETE_TAG: 'fa-sharp fa-xmark',
  DISABLED: 'fal fa-ban',
  DISABLED_REASON: 'fal fa-block-question',
  DISABLE_KEYS: 'fa-sharp fa-trash',
  DISALLOW: 'fal fa-xmark',
  DOWNLOAD: 'fal fa-download',
  DOWNLOADED: 'fal fa-download',
  DOWNLOAD_ALL_DATA: 'fal fa-filter-slash',
  DOWNLOAD_DATA: 'fal fa-cloud-arrow-down',
  DOWNLOAD_FILTERED_DATA: 'fal fa-filter',
  DOWNLOAD_KEYS: 'fal fa-download',
  DROPDOWN_TOGGLE: 'fa-sharp fa-ellipsis-vertical',
  EDIT: 'fas fa-pencil',
  EMAIL_LOGS: 'fal fa-envelope-open-text',
  FAILED: 'fal fa-xmark',
  FILE: 'fa-regular fa-file',
  GAME: 'fal fa-gamepad',
  GAME_LICENSE_EXPIRED: 'fas fa-calendar-xmark',
  GRID: 'fal fa-grid',
  GRID_DIVIDER: 'fal fa-grid-dividers',
  INFO: 'fal fa-circle-info',
  INSIGHTS: 'fa-sharp fa-light fa-sparkles',
  KEBAB_MENU: 'fa-sharp fa-ellipsis',
  KEBAB_MENU_VERTICAL: 'fa-sharp fa-ellipsis-vertical',
  KEYS: 'fal fa-key',
  KEY_ACTIVATED: 'fal fa-trophy',
  KEY_ALLOCATED: 'fal fa-user-plus',
  KEY_AVAILABLE: 'fal fa-key',
  KEY_DISABLED: 'fal fa-ban',
  KEY_MOVED: 'fal fa-exchange',
  KEY_POOL: 'fal fa-key',
  KEY_PROVIDER: 'fal fa-key',
  KEY_SOLD: 'fal fa-money-bill-wave',
  LICENSE_EXPIRED: 'fas fa-calendar-xmark',
  LINK: 'far fa-link',
  LINK_EXTERNAL: 'fa-sharp fa-external-link-alt',
  LINK_INTERNAL: 'fa-sharp fa-internal-link-alt',
  LIST: 'fal fa-list',
  LOCKED: 'fa-sharp fa-lock',
  MAGNIFYING_GLASS: 'fa-light fa-magnifying-glass',
  MOVED: 'fal fa-exchange',
  MOVE_KEYS: 'fa-sharp fa-right-left',
  NO: 'fal fa-xmark',
  NOTHING_TO_SHOW: 'fa-thin fa-person-dolly-empty',
  NOTIFICATION: 'fa-sharp fa-light fa-bell',
  PACKAGE_TYPE: 'fal fa-box-open',
  PERMISSIONS: 'fal fa-user-shield',
  PERSONALISATION: 'fa-sharp fa-light fa-heart',
  PLATFORM: 'fal fa-gamepad',
  PLUS: 'fa-sharp fa-solid fa-plus',
  PROCESS_SELECTION: 'fal fa-loader',
  REASSIGN: 'fal fa-user-pen',
  REPORT: 'fa-file',
  REQUESTS: 'fal fa-shopping-cart',
  REQUEST_AGAIN: 'fal fa-copy',
  REQUEST_KEYS: 'fal fa-key',
  REQUEST_ON_STEAM: 'fa-brands fa-steam-symbol',
  RETRY: 'fal fa-lock',
  RETURN_KEYS: 'fa-regular fa-arrow-turn-down-left',
  SEARCH: 'fas fa-search',
  SEND_EMAILS: 'fa-solid fa-envelopes',
  SETTINGS: 'fal fa-gear',
  SET_KEYS_ORDERED: 'fal fa-hourglass-clock',
  SKU: 'fal fa-gamepad',
  SKU_LICENSE_EXPIRED: 'fas fa-calendar-xmark',
  SLA: 'fas fa-calendar-check',
  SLA_BREACHED: 'fas fa-calendar-times',
  SLIDERS: 'fal fa-sliders-h',
  SOLD: 'fal fa-money-bill-wave',
  SORTABLE_HANDLE: 'fa-sharp fa-solid fa-grip-dots-vertical',
  SORT_ASC: 'fa-light fa-arrow-up',
  SORT_DESC: 'fa-light fa-arrow-down',
  SUCCESS: 'fal fa-circle-check',
  TABLE_BULK: 'fa-sharp fa-list-ul',
  TABLE_GROUPED: 'fa-sharp fa-light fa-list-dropdown',
  TABLE_SETTINGS: 'fal fa-gear',
  TABLE_UNGROUPED: 'fal fa-list',
  TAG: 'fal fa-tag',
  TERRITORIES: 'fal fa-globe-americas',
  UPLOAD: 'fal fa-upload',
  UPLOADERS: 'fal fa-user-tag',
  UPLOAD_ACCOUNT_DETAILS: 'fal fa-user-plus',
  UPLOAD_KEYS: 'fa-sharp fa-upload',
  USER: 'fal fa-user',
  USERS: 'fal fa-users',
  USER_PERMISSIONS: 'fal fa-users',
  VIEW: 'fa-regular fa-eye',
  WARNING: 'fal fa-exclamation-triangle',
  WITHDRAW_REQUEST: 'fal fa-rotate-left',
  WIZARD_NEXT: 'fa-sharp fa-chevron-right',
  WIZARD_STAR: 'fal fa-star',
  WORKFLOW: 'fal fa-wave-square',
  YES: 'fa-sharp fa-check',
});

export default icons;
