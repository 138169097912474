import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import KwPanel from '../../common/KwPanel';

import { removeIngestedKeys } from '../../../actions/keyCodeActions';
import { toast } from 'react-toastify';
import Loading from '../../common/Loading';
import Button from '../../common/Button';
import ConfirmationHeader from '../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function RemoveIngestedKeys({ done, id, cancel }) {
  const [processing, setProcessing] = useState(false);
  const { t } = useTranslation();

  const handleRemove = () => {
    setProcessing(true);
    removeIngestedKeys(id)
      .then((d) => {
        if (!d.deleted) {
          toast.error(t('Could not remove keys'));
          cancel();
        } else {
          toast.success(t('Keys successfully removed'));
          done(d);
        }
      })
      .catch(() => {
        toast.error(t('Failed to remove keys'));
        setProcessing(false);
      });
  };

  return (
    <SlideoutLayout title={t('Remove keys')} cancel={cancel} done={done}>
      {!processing ? (
        <KwPanel>
          <ConfirmationHeader />
          <Button
            className="btn btn-default me-2"
            onClick={handleRemove}
            text={t('Yes, remove the keys')}
          />
          <Button
            className="btn btn-primary"
            onClick={cancel}
            autoFocus={true}
            text={t('No, cancel')}
          />
        </KwPanel>
      ) : (
        <KwPanel>
          <header className="mb-2">
            <h2 className="no-margin ">{t('Removing keys')}</h2>
          </header>
          <Loading message={t('PLEASE WAIT...')} inPanel={true} />
        </KwPanel>
      )}
    </SlideoutLayout>
  );
}
RemoveIngestedKeys.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveIngestedKeys);
