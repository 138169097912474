import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.UPDATE_STEAMREQUEST:
      return action.steamRequest;
    case actionTypes.CLEAR_STEAMREQUEST:
      return null;
    default:
      return state;
  }
}
