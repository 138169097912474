import actionTypes from '../constants/actionTypes';
import { setToken } from '../api/apiUtils';
import * as userApi from '../api/userApi';
import { handleErrorWithDispatch } from './actionUtils';

export function storeUser(user) {
  return function (dispatch) {
    setToken(user.access_token);
    const username = user.profile.preferred_username ?? user.profile.name;
    //send a temp user object to prevent redirect loop - may have to await some loader or other
    dispatch({
      type: actionTypes.STORE_USER,
      user: { username, isLoading: true },
    });
    //get the actual user from the api
    return userApi
      .getByUsername(username)
      .then((currentUser) => {
        dispatch({
          type: actionTypes.USER_SIGNED_IN,
          user: { ...currentUser, access_token: user.access_token },
        });
      })
      .catch((e) => handleErrorWithDispatch(e, dispatch));
  };
}

export function loadingUser() {
  return {
    type: actionTypes.LOADING_USER,
  };
}

export function storeUserError() {
  return {
    type: actionTypes.STORE_USER_ERROR,
  };
}

export function userExpired() {
  return {
    type: actionTypes.USER_EXPIRED,
  };
}

export function userSignedOut() {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.USER_SIGNED_OUT,
    });
  };
}

export function clearUser() {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.CLEAR_USER,
    });
  };
}

export function clearProperties() {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.USER_CLEAR_PROPERTIES,
    });
  };
}
