import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import icons from '../../../../constants/icons';

function RequestGroup({ option }) {
  const data = JSON.parse(option.data);
  return (
    <>
      <div className="icon-wrapper request">
        <FontAwesomeIcon icon={icons.REQUESTS} className="request" />
      </div>
      <div className="item-content">
        <span>
          {option.title} - {data.RequestedByCompanyName} -{' '}
          {data.KeyWorkflowName}
        </span>
        <br />
        {data.Requests &&
          data.Requests.map((x) => x.RequestReference).join(', ')}
      </div>
    </>
  );
}
export default RequestGroup;
