import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PromotionsList from './components/PromotionsList';
import ActionBar from '../layout/ActionBar';
import { NavLink } from 'react-router-dom';
import { getKeyProviders } from '../../actions/keyProviderActions';
import { getAllTerritories } from '../../actions/territoryActions';
import { useTranslation } from 'react-i18next';
function Promotions({
  location,
  keyProviders,
  getKeyProviders,
  territories,
  getAllTerritories,
}) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders]);

  useEffect(() => {
    if (!territories) {
      getAllTerritories();
    }
  }, [territories, getAllTerritories]);

  return (
    <>
      <ActionBar
        breadcrumb={[
          {
            link: '/promotions',
            label: t('All promotions'),
          },
        ]}
      >
        <NavLink className="btn btn-primary" to={`/promotion/add`}>
          {t('Add promotion')}
        </NavLink>
      </ActionBar>
      <PromotionsList
        location={location}
        reload={reload}
        keyProviders={keyProviders}
        territories={territories}
      />
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    keyProviders: state.keyProviders,
    territories: state.territories,
  };
}

const mapDispatchToProps = {
  getKeyProviders,
  getAllTerritories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
