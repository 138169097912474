import { apiUrl, exportData, getList } from './apiUtils';
const baseUrl = apiUrl + '/reports/';
const exportBaseUrl = `${apiUrl}/export/reports/`;

export function getRequestsByWeekByGame(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}requests/week/game?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getRequestsByWeekBySKU(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}requests/week/sku?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getKeysByWeekByGame(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}keys/week/game?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getKeysByWeekBySku(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}keys/week/sku?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getKeysByWeekByCompany(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}keys/week/company?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getRequestsByWeekByCompany(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}requests/week/company?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getRequestsByWeekByAuthorisingUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}requests/week/authorisinguser?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportRequestsByWeekByAuthorisingUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}requests/week/authorisinguser?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getKeysByWeekByAuthorisingUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}keys/week/authorisinguser?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportKeysByWeekByAuthorisingUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}keys/week/authorisinguser?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getRequestsByWeekByUploadingUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}requests/week/uploadinguser?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportRequestsByWeekByUploadingUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}requests/week/uploadinguser?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getKeysByWeekByUploadingUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}keys/week/uploadinguser?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportKeysByWeekByUploadingUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}keys/week/uploadinguser?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function exportRequestsByWeekByGame(year, company, filters, scopes) {
  let url = `${exportBaseUrl}requests/week/game?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'game', filters, scopes);
}

export function exportRequestsByWeekBySKU(year, company, filters, scopes) {
  let url = `${exportBaseUrl}requests/week/sku?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'game', filters, scopes);
}

export function exportKeysByWeekByGame(year, company, filters, scopes) {
  let url = `${exportBaseUrl}keys/week/game?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'game', filters, scopes);
}

export function exportKeysByWeekBySku(year, company, filters, scopes) {
  let url = `${exportBaseUrl}keys/week/sku?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'game', filters, scopes);
}

export function exportKeysByWeekByCompany(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}keys/week/company?year=${year}`,
    'companyName',
    filters,
    scopes
  );
}

export function exportRequestsByWeekByCompany(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}requests/week/company?year=${year}`,
    'companyName',
    filters,
    scopes
  );
}

export function exportSLAsByCompany(filters, scopes) {
  return exportData(
    `${exportBaseUrl}sla/company`,
    'companyName',
    filters,
    scopes
  );
}

export function getUsersModifiedByWeekByUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}users/week/user?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportUsersModifiedByWeekByUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}users/week/user?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getKeyWorkflowsModifiedByWeekByUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}keyworkflows/week/user?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportKeyWorkflowsModifiedByWeekByUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}keyworkflows/week/user?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getGamesModifiedByWeekByUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}games/week/user?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportGamesModifiedByWeekByUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}games/week/user?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getSKUsModifiedByWeekByUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}skus/week/user?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportSKUsModifiedByWeekByUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}skus/week/user?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getRequestsByWeekByWorkflow(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}requests/week/workflow?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function exportRequestsByWeekByWorkflow(year, company, filters, scopes) {
  let url = `${exportBaseUrl}requests/week/workflow?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'workflow', filters, scopes);
}
export function getKeysByWeekByWorkflow(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}keys/week/workflow?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function exportKeysByWeekByWorkflow(year, company, filters, scopes) {
  let url = `${exportBaseUrl}keys/week/workflow?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'workflow', filters, scopes);
}
