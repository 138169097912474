import React, { useEffect, useRef, useState } from 'react';

const Scroll = ({ children, style }) => {
  const el = useRef();

  const [mouseDown, setMouseDown] = useState(false);
  const [startX, setStartX] = useState();
  const [scrollLeft, setScrollLeft] = useState();

  useEffect(() => {
    const _el = el.current;

    const startDragging = function (e) {
      e.preventDefault();
      e.stopPropagation();
      setMouseDown(true);
      setStartX((e.pageX ?? 0) - (_el.offsetLeft ?? 0));
      setScrollLeft(_el.scrollLeft ?? 0);
    };
    const stopDragging = function (event) {
      setMouseDown(false);
      event.preventDefault();
      event.stopPropagation();
      return false;
    };
    const handleClick = function (event) {
      try {
        const x = (event.pageX ?? 0) - (_el.offsetLeft ?? 0);
        const scroll = x - (startX ?? 0);
        if (scroll > 5 || scroll < -5) {
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      } catch (e) {
        console.error(e);
      }
    };

    const handleMouseMove = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!mouseDown) {
        return;
      }
      const x = (event.pageX ?? 0) - (_el.offsetLeft ?? 0);
      const scroll = x - (startX ?? 0);
      _el.scrollLeft = scrollLeft - scroll;
    };

    // Add the event listeners
    _el.addEventListener('mousemove', handleMouseMove, false);
    _el.addEventListener('mousedown', startDragging, false);
    _el.addEventListener('mouseup', stopDragging, false);
    _el.addEventListener('mouseleave', stopDragging, false);
    _el.addEventListener('click', handleClick, false);
    return () => {
      _el.removeEventListener('mousemove', handleMouseMove);
      _el.removeEventListener('mousedown', startDragging);
      _el.removeEventListener('mouseup', stopDragging);
      _el.removeEventListener('mouseleave', stopDragging);
      _el.removeEventListener('click', handleClick);
    };
  }, [mouseDown, scrollLeft, startX]);

  return (
    <div ref={el} className="overflow-x-scroll" style={style}>
      {children}
    </div>
  );
};

export default Scroll;
