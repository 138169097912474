import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// actions
import {
  getAudit,
  addAudit,
  getWorkflowById,
  getWorkflowRequestersById,
  getWorkflowStepsById,
  getWorkflowTagsById,
  saveWorkflow,
  getStats,
  getStatsByDay,
} from '../../../actions/workflowActions';
import { getUserProfileGroups } from '../../../actions/userProfileGroupActions';

// common components
import ActionBar from '../../layout/ActionBar';
import KwPanel from '../../common/KwPanel';
import Loading from '../../common/Loading';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ResponsiveActionBarButtons from '../../common/ResponsiveActionBarButtons';
import { useTranslation } from 'react-i18next';

// components
import WorkflowForm from './components/WorkflowForm';
import WorkflowStats from './components/WorkflowStats';
import WorkflowSteps from './components/WorkflowSteps';
import WorkflowTags from './components/WorkflowTags';
import WorkflowTargets from './components/WorkflowTargets';
import WorkflowWhoCanRequest from './components/WorkflowWhoCanRequest';
import UploaderBadges from '../uploader/components/UploaderBadges';

// slideouts
import Clone from './slideout/Clone';
import Delete from './slideout/Delete';
import EditSteps from './slideout/EditSteps';
import EditTargets from './slideout/EditTargets';
import AuditButton from '../../audit/components/AuditButton';
import SlidingPane from 'react-sliding-pane';
import DisableKeys from '../../keys/slideout/DisableKeys';

// images
import image_no_content from '../../../content/images/no-content.svg';
import Button from '../../common/Button';

const ManageWorkflow = ({ id, saveWorkflow, history, currentUser }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();
  const [isAuroraWorkflow, setIsAuroraWorkflow] = useState();

  const onSubmit = (data) => {
    const workflowRecord = {
      ...workflow,
      ...data,
      isAuroraWorkflow: isAuroraWorkflow,
      workflowRequiresTag: workflow.workflowRequiresTag,
      tagsAsFreeText: workflow.tagsAsFreeText,
    };
    saveWorkflow(workflowRecord)
      .then(() => {
        setWorkflow({ ...workflow, ...workflowRecord });
        toast.success(t('Workflow saved'));
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to save workflow'));
      });
  };

  const [showDisableKeys, setShowDisableKeys] = useState(false);

  // clone
  const [cloneSlideoutOpen, setCloneSlideoutOpen] = useState(false);
  const handleClone = (e) => {
    e.cancelBubble = true;
    setCloneSlideoutOpen(true);
    return false;
  };

  // delete
  const [deleteSlideoutOpen, setDeleteSlideoutOpen] = useState(false);
  const handleDelete = (e) => {
    e.cancelBubble = true;
    setDeleteSlideoutOpen(true);
    return false;
  };

  // edit steps
  const [editStepsSlideoutOpen, setEditStepsSlideoutOpen] = useState(false);
  const handleEditSteps = (e) => {
    e.cancelBubble = true;
    setEditStepsSlideoutOpen(true);
    return false;
  };

  // edit target
  const [editTargetsSlideoutOpen, setEditTargetsSlideoutOpen] = useState(false);
  const handleEditTargets = (e) => {
    e.cancelBubble = true;
    setEditTargetsSlideoutOpen(true);
    return false;
  };

  const handleWorkflowChange = (wf) => {
    setWorkflow({
      ...workflow,
      isAuroraWorkflow: isAuroraWorkflow,
      workflowRequiresTag: wf.workflowRequiresTag,
      tagsAsFreeText: wf.tagsAsFreeText,
    });
  };

  const [workflow, setWorkflow] = useState();
  const [workflowSteps, setWorkflowSteps] = useState();
  const [workflowRequesters, setWorkflowRequesters] = useState();
  const [workflowTags, setWorkflowTags] = useState();
  const [stats, setStats] = useState();
  const [groups, setGroups] = useState();

  useEffect(() => {
    if (id) {
      // get the workflow
      getWorkflowById(id)
        .then((d) => {
          setWorkflow(d);
          setIsAuroraWorkflow(d.isAuroraWorkflow);
          // reset the form
          reset(d);
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to get workflow'));
        });

      // get the workflow steps
      getWorkflowStepsById(id)
        .then((d) => {
          setWorkflowSteps(d);
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to get workflow steps'));
        });

      // get the workflow requesters
      getWorkflowRequestersById(id)
        .then((d) => {
          setWorkflowRequesters(d);
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to get workflow requesters'));
        });

      // get the workflow tags
      getWorkflowTagsById(id)
        .then((d) => {
          setWorkflowTags(d);
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to get workflow tags'));
        });

      // get the groups
      getUserProfileGroups()
        .then((d) => {
          setGroups(d.data);
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to get user groups'));
        });

      // get the stats
      getStats(id)
        .then((d) => {
          setStats(d);
        })
        .catch((e) => {
          toast.error(e.message || t('Failed to get stats'));
        });
    }
  }, [id, reset]);

  // TODO - Add other loading conditions like requesters, steps, targets, uploaders - when they're ready
  return workflow && stats && workflowSteps ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/settings/workflows', label: t('Workflows') },
          { label: workflow.keyWorkflowName },
        ]}
      >
        <AuditButton id={id} getAudit={getAudit} addAudit={addAudit} />
        <ResponsiveActionBarButtons
          buttons={[
            <Button
              key="delete"
              className="btn btn-danger ms-2 d-none d-md-inline-flex"
              onClick={handleDelete}
              isDisabled={workflow.isDeleted}
              title={
                workflow.isDeleted ? t('Workflow is deleted') : t('Delete')
              }
              text={t('Delete')}
            />,
            <Button
              key="disableKeys"
              className="btn btn-danger ms-2 d-none d-md-inline-flex"
              onClick={() => setShowDisableKeys(true)}
              text={t('Disable keys')}
            />,
            <Button
              key="clone"
              className="btn btn-default ms-2 d-none d-md-inline-flex"
              onClick={handleClone}
              text={t('Clone')}
            />,
            <Button
              key="save"
              text={t('Save')}
              className="btn btn-primary ms-2"
              onClick={handleSubmit(onSubmit)}
              isDisabled={workflow.isDeleted}
              title={workflow.isDeleted ? t('Workflow is deleted') : t('Save')}
            />,
          ]}
        />
      </ActionBar>

      <WorkflowStats
        id={workflow.id}
        stats={stats}
        getData={() => getStatsByDay(workflow.id)}
      />
      {!workflow.isDeleted ? (
        <KwPanel className="mb-4">
          <WorkflowForm
            workflow={workflow}
            register={register}
            errors={errors}
            control={control}
            isAuroraWorkflow={isAuroraWorkflow}
            setIsAuroraWorkflow={setIsAuroraWorkflow}
            currentUser={currentUser}
          />
        </KwPanel>
      ) : (
        <div
          className="alert alert-info text-center"
          style={{ padding: '30px 15px' }}
        >
          <img
            src={image_no_content}
            alt={t('This workflow has been deleted')}
            className="space-bottom-small"
          />
          <p>{t('This workflow has been deleted')}</p>
        </div>
      )}

      {!workflow.isDeleted && (
        <WorkflowTags
          workflow={workflow}
          isAuroraWorkflow={isAuroraWorkflow}
          tags={workflowTags}
          onWorkflowChange={handleWorkflowChange}
        />
      )}

      {!workflow.isDeleted && (
        <WorkflowWhoCanRequest
          groups={groups}
          workflow={workflow}
          requesters={workflowRequesters}
        />
      )}

      {!workflow.isDeleted && (
        <WorkflowSteps
          workflow={workflow}
          isAuroraWorkflow={isAuroraWorkflow}
          steps={workflowSteps}
          onEdit={handleEditSteps}
        />
      )}

      {!workflow.isDeleted && (
        <UploaderBadges
          label={t('Who can upload keys')}
          noneMessage={t(
            'There have been no specific permissions set, we will use the default permissions to allow key uploads'
          )}
          keyWorkflowId={id}
        />
      )}

      {!workflow.isDeleted && (
        <WorkflowTargets
          steps={workflowSteps}
          isAuroraWorkflow={isAuroraWorkflow}
          workflowSLAMinutes={workflow.keyUploadSLAMinutes}
          workflowEnableSLAReminder={workflow.enableSLAReminder}
          onEdit={handleEditTargets}
        />
      )}

      <SlidingPane
        isOpen={cloneSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setCloneSlideoutOpen(false)}
      >
        <Clone
          id={id}
          cancel={() => setCloneSlideoutOpen(false)}
          done={(d) => {
            setCloneSlideoutOpen(false);
            history.push(`/settings/workflow/${d.id}`);
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={deleteSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setDeleteSlideoutOpen(false)}
      >
        <Delete
          id={id}
          cancel={() => setDeleteSlideoutOpen(false)}
          done={(d) => {
            setDeleteSlideoutOpen(false);
            history.push('/settings/workflows/');
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={editStepsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="xlarge-side-panel"
        onRequestClose={() => setEditStepsSlideoutOpen(false)}
      >
        <EditSteps
          id={id}
          workflow={workflow}
          workflowSteps={workflowSteps}
          cancel={() => setEditStepsSlideoutOpen(false)}
          done={(d) => {
            setEditStepsSlideoutOpen(false);

            // reload steps
            setWorkflowSteps(d);
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={editTargetsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="xlarge-side-panel"
        onRequestClose={() => setEditTargetsSlideoutOpen(false)}
      >
        <EditTargets
          id={id}
          workflow={workflow}
          workflowSteps={workflowSteps}
          cancel={() => setEditTargetsSlideoutOpen(false)}
          done={({ steps, workflow }) => {
            setEditTargetsSlideoutOpen(false);

            // reload steps
            if (steps) {
              setWorkflowSteps(steps);
            }
            if (workflow) {
              setWorkflow(workflow);
            }
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={showDisableKeys}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setShowDisableKeys(false)}
      >
        <DisableKeys
          title={t('Disable keys for {workflow}', {
            workflow: workflow.keyWorkflowName || 'workflow',
          })}
          targetKeyWorkflowId={id}
          done={(d) => {
            setShowDisableKeys(false);
          }}
          cancel={() => setShowDisableKeys(false)}
          defaultReason={t('Disable keys for "{workflow}" workflow', {
            workflow: workflow.keyWorkflowName,
          })}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  saveWorkflow,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageWorkflow);
