import {
  apiUrl,
  getList,
  deleteRecord,
  getSingleRecord,
  saveRecord,
  getStatsData,
  getAll,
  postArray,
  deleteArray,
  upload,
  get,
  getAuditData,
  postAction,
} from './apiUtils';
const baseUrl = apiUrl + '/gameskus/';

export function getSkus(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAllSKUs() {
  return getAll(`${apiUrl}/search`);
}
export function getSkuById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveSku(gameid, sku) {
  // handle commas, decimals etc
  if (sku.singleKeyValue) {
    sku.singleKeyValue = parseFloat(sku.singleKeyValue.replace(/,/g, '')) || 0;
  }
  return saveRecord(`${apiUrl}/games/${gameid}/sku/`, sku);
}

export function deleteSku(id) {
  return deleteRecord(baseUrl, id);
}

export function getStats(id) {
  return getStatsData(baseUrl, id);
}

export function getStatsByDay(id) {
  return get(`${baseUrl}${id}/statsByDay/`);
}

export function getAggregateStats(id, from, to) {
  // Format the from as YYYY-MM-DD
  from = from.toISOString().split('T')[0];
  // Format the to as YYYY-MM-DD
  to = to.toISOString().split('T')[0];

  return get(`${baseUrl}${id}/aggregateStats?fromDate=${from}&toDate=${to}`);
}

export function getAudit(id) {
  return getAuditData(baseUrl, id);
}

export function addAudit(id, comment) {
  return postAction(`${baseUrl}${id}/audit`, { comment });
}

export function addTerritories(id, gameid, territories) {
  return postArray(
    `${apiUrl}/games/${gameid}/sku/${id}/territories`,
    territories
  );
}

export function removeTerritories(id, gameid, territories) {
  return deleteArray(
    `${apiUrl}/games/${gameid}/sku/${id}/territories`,
    territories
  );
}

export function addWorkflows(id, gameid, workflows) {
  return postArray(
    `${apiUrl}/games/${gameid}/sku/${id}/keyWorkflows`,
    workflows
  );
}

export function removeWorkflows(id, gameid, workflows) {
  return deleteArray(
    `${apiUrl}/games/${gameid}/sku/${id}/keyWorkflows`,
    workflows
  );
}
export function uploadToKeyPool(id, gameid, keyWorkflowId, record) {
  return upload(
    `${apiUrl}/games/${gameid}/sku/${id}/keyWorkflow/${keyWorkflowId}/upload`,
    record
  );
}

export function validateCodeFile(id, gameid, file) {
  return upload(`${apiUrl}/games/${gameid}/sku/${id}/upload/validate`, file);
}

export function getKeyPools(
  id,
  gameId,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${apiUrl}/games/${gameId}/sku/${id}/keyPools`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getSkuCompanyStocks(id) {
  return get(`${baseUrl}${id}/companyStocks`);
}
