import React, { useState } from 'react';
import {
  loadUserProfileGroups,
  exportUserProfileGroupData,
} from '../../../../actions/userProfileGroupActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { CommaNumber_NoDecimal } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import { useTranslation } from 'react-i18next';

function UserProfileGroupList({ location, reload }) {
  const { t } = useTranslation();
  const allColumns = ['name', 'memberCount'];
  const defaultVisibleColumns = allColumns;
  const [userProfileGroups, setUserProfileGroups] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'userprofilegrouplist-settings',
      getTableSettingsDefaults({ defaultSort: 'name', defaultVisibleColumns }),
      null,
      loadUserProfileGroups,
      setUserProfileGroups,
      reload,
      t('Failed to load groups'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Name'),
      width: '40%',
      Render: (item) => (
        <Link to={'/settings/user-profile-group/' + item.id}>{item.name}</Link>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'memberCount',
      Label: t('Members'),
      width: '30%',
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.memberCount),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return userProfileGroups ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={userProfileGroups.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={userProfileGroups.totalRowCount}
        filteredRowCount={userProfileGroups.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportUserProfileGroupData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default UserProfileGroupList;
