import React, { useState } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../../layout/ActionBar';
import Add from './slideout/Add';
import UploaderList from './components/UploaderList';
import Uploader from './slideout/Uploader';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function Uploaders({ location, currentUser }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();

  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const handleAddUploader = (e) => {
    e.cancelBubble = true;
    setAddSlideoutOpen(true);
    return false;
  };

  const [editSlideoutOpen, setEditSlideoutOpen] = useState(false);
  const [editSlideoutItem, setEditSlideoutItem] = useState();
  const handleEditUploader = (id) => {
    setEditSlideoutItem(id);
    setEditSlideoutOpen(true);
    return false;
  };

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={handleAddUploader}
          text={t('Add uploader')}
        />
      </ActionBar>

      <UploaderList
        location={location}
        reload={reload}
        onEdit={handleEditUploader}
        currentUser={currentUser}
      />

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <Add
          cancel={() => setAddSlideoutOpen(false)}
          done={(d) => {
            setReload(new Date());
            setAddSlideoutOpen(false);
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={editSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setEditSlideoutOpen(false)}
      >
        <>
          {editSlideoutItem && (
            <Uploader
              done={(d) => {
                setEditSlideoutOpen(false);
                setReload(new Date());
              }}
              cancel={() => setEditSlideoutOpen(false)}
              id={editSlideoutItem}
            />
          )}
        </>
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Uploaders);
