import React from 'react';
import WizardStepItem from './WizardStepItem';
import icons from '../../../../constants/icons';
import { useTranslation } from 'react-i18next';
export default function SelectCompanyStep({
  currentUser,
  companies,
  handleCompanySelect,
}) {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="mb-4">{t('Who is this request for?')}</h2>
      <p>{t('Your company')}</p>
      {companies
        ? [...companies]
            ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
            .filter((x) => x.id === currentUser.companyId)
            .map((company) => (
              <WizardStepItem
                key={company.id}
                id={company.id}
                typeIcon={`${icons.WIZARD_STAR} text-yellow`}
                typeImageId={company.imageId}
                typeImageColourHex={company.colourHex}
                typeImageFallback={icons.WIZARD_STAR}
                title={company.companyName}
                description=""
                icon={icons.WIZARD_NEXT}
                onClick={handleCompanySelect}
              ></WizardStepItem>
            ))
        : ''}
      <p>{t('Request on behalf of another company')}</p>
      {companies
        ? [...companies]
            ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
            .filter((x) => x.id !== currentUser.companyId)
            .map((company) => (
              <WizardStepItem
                key={company.id}
                id={company.id}
                typeIcon={`${icons.COMPANY} text-blue`}
                typeImageId={company.imageId}
                typeImageColourHex={company.colourHex}
                typeImageFallback={icons.COMPANY}
                title={company.companyName}
                description=""
                icon={icons.WIZARD_NEXT}
                onClick={handleCompanySelect}
              ></WizardStepItem>
            ))
        : ''}
    </>
  );
}
