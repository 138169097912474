import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import SteamBulkRequest from '../../requests/slideout/SteamBulkRequest';
import SlidingPane from 'react-sliding-pane';
import icons from '../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function KeyPoolBulkActions({ done, cancel, items, sku }) {
  const { t } = useTranslation();
  const [bulkSteamRequestSlideoutOpen, setBulkSteamRequestSlideoutOpen] =
    useState(false);

  const BulkActionItem = ({ title, description, icon, onClick }) => (
    <div
      className="col-sm-12 template p-0 bulk action-update"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <div className="template-detail p-4">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="icon-wrapper">
        <FontAwesomeIcon icon={icon} />
      </div>
    </div>
  );
  return (
    <>
      <SlideoutLayout title={t('Bulk actions')} cancel={cancel} done={done}>
        <BulkActionItem
          title={t('Request on Steam')}
          description={t('Send an automated request to Steam')}
          icon={icons.REQUEST_ON_STEAM}
          onClick={() => setBulkSteamRequestSlideoutOpen(true)}
        ></BulkActionItem>
      </SlideoutLayout>
      <SlidingPane
        isOpen={bulkSteamRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setBulkSteamRequestSlideoutOpen(false)}
      >
        <SteamBulkRequest
          done={() => setBulkSteamRequestSlideoutOpen(false)}
          cancel={() => setBulkSteamRequestSlideoutOpen(false)}
          gameName={sku.gameName}
          skus={[{ ...sku, keyPools: items }]}
        ></SteamBulkRequest>
      </SlidingPane>
    </>
  );
}
KeyPoolBulkActions.propTypes = {
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  sku: PropTypes.object.isRequired,
};

export default KeyPoolBulkActions;
