import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddMember from '../slideout/AddMember';
import RemoveMember from '../slideout/RemoveMember';
import KwPanel from '../../../common/KwPanel';
import { NavLink } from 'react-router-dom';
import Gravatar from 'react-gravatar';
import SlidingPane from 'react-sliding-pane';
import icons from '../../../../constants/icons';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function MemberBadges({
  label,
  description,
  groups,
  members,
  permissionKey,
  reload,
}) {
  const [_members, setMembers] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setMembers(members);
  }, [members]);

  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const addMember = (e) => {
    e.cancelBubble = true;
    setAddSlideoutOpen(true);
    return false;
  };

  const [removeMemberGroupSlideoutOpen, setRemoveMemberGroupSlideoutOpen] =
    useState(false);
  const [removeMemberGroupSlideoutItem, setRemoveMemberGroupSlideoutItem] =
    useState();
  const removeMemberGroup = (e, userProfileGroupId) => {
    e.cancelBubble = true;
    setRemoveMemberGroupSlideoutItem(userProfileGroupId);
    setRemoveMemberGroupSlideoutOpen(true);
    return false;
  };

  const [removeMemberUserSlideoutOpen, setRemoveMemberUserSlideoutOpen] =
    useState(false);
  const [removeMemberUserSlideoutItem, setRemoveMemberUserSlideoutItem] =
    useState();
  const removeMemberUser = (e, userProfileId) => {
    e.cancelBubble = true;
    setRemoveMemberUserSlideoutItem(userProfileId);
    setRemoveMemberUserSlideoutOpen(true);
    return false;
  };

  const GroupTag = ({ member }) => (
    <div className="tag" key={member.userProfileGroupId}>
      <NavLink
        to={`/settings/user-profile-group/${member.userProfileGroupId}`}
        title={t('Click to view group details')}
      >
        {member.groupName}
      </NavLink>
      <span
        className="ms-3 a"
        onClick={(e) => removeMemberGroup(e, member.userProfileGroupId)}
        title={t('Remove')}
      >
        <FontAwesomeIcon icon={icons.DELETE_TAG} />
      </span>
    </div>
  );

  const UserTag = ({ member }) => (
    <div className="tag pink" key={member.userProfileId}>
      <NavLink
        to={`/user/${member.userProfileId}`}
        title={t('Click to view user details')}
      >
        <Gravatar
          email={member?.username}
          size={16}
          rating="pg"
          default="retro"
          className="img-circle me-3"
        />
        <span>{member.fullName}</span>
      </NavLink>
      <span
        className="ms-3 a"
        onClick={(e) => removeMemberUser(e, member.userProfileId)}
        title={t('Remove')}
      >
        <FontAwesomeIcon icon={icons.DELETE_TAG} />
      </span>
    </div>
  );

  return (
    <>
      <KwPanel className="mb-4">
        <header className="mb-2">
          <h2 className="no-margin">{label}</h2>
          <div className="float-right">
            <Button
              className="btn btn-default"
              onClick={addMember}
              text={t('Add')}
            />
          </div>
        </header>
        {description && <div>{description}</div>}
        <div className="mt-3 tags">
          {_members && _members.length > 0 ? (
            _members.map((x, i) => (
              <span key={i}>
                {x.userProfileGroupId ? (
                  <GroupTag member={x} />
                ) : x.userProfileId ? (
                  <UserTag member={x} />
                ) : (
                  ''
                )}
              </span>
            ))
          ) : (
            <></>
          )}
        </div>
      </KwPanel>

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <AddMember
          permissionKeys={[permissionKey]}
          groups={groups}
          cancel={() => setAddSlideoutOpen(false)}
          done={(d) => {
            reload();
            setAddSlideoutOpen(false);
          }}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={removeMemberGroupSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRemoveMemberGroupSlideoutOpen(false)}
      >
        <>
          {removeMemberGroupSlideoutItem && _members && _members.length && (
            <RemoveMember
              permissionKeys={[permissionKey]}
              userProfileGroupId={removeMemberGroupSlideoutItem}
              done={(d) => {
                setRemoveMemberGroupSlideoutOpen(false);

                setMembers([
                  ..._members.filter(
                    (r) =>
                      r.userProfileGroupId !== removeMemberGroupSlideoutItem
                  ),
                ]);
                reload();
              }}
              cancel={() => setRemoveMemberGroupSlideoutOpen(false)}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={removeMemberUserSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRemoveMemberUserSlideoutOpen(false)}
      >
        <>
          {removeMemberUserSlideoutItem && _members && _members.length && (
            <RemoveMember
              permissionKeys={[permissionKey]}
              userProfileId={removeMemberUserSlideoutItem}
              done={(d) => {
                setRemoveMemberUserSlideoutOpen(false);
                setMembers([
                  ..._members.filter(
                    (r) => r.userProfileId !== removeMemberUserSlideoutItem
                  ),
                ]);
              }}
              cancel={() => setRemoveMemberUserSlideoutOpen(false)}
            />
          )}
        </>
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MemberBadges);
