import * as healthCheckApi from '../api/healthCheckApi';
export function loadHealthChecks({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return healthCheckApi.getHealthChecks(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function downloadHealthCheck(id) {
  return healthCheckApi.downloadHealthCheck(id);
}
