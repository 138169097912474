import * as userApi from '../api/userApi';
import actionTypes from '../constants/actionTypes';

export function getStats(id) {
  return userApi.getStats(id);
}

export function getStatsByDay(id) {
  return userApi.getStatsByDay(id);
}

export function getAggregateStats(id, from, to) {
  return userApi.getAggregateStats(id, from, to);
}

export function getAuthorisedStatsByDay(id) {
  return userApi.getAuthorisedStatsByDay(id);
}

export function getUploadedStatsByDay(id) {
  return userApi.getUploadedStatsByDay(id);
}

export function getAudit(id) {
  return userApi.getAudit(id);
}

export function addAudit(id, comment) {
  return userApi.addAudit(id, comment);
}

export function getGeneralStats() {
  return userApi.getGeneralStats();
}

export function loadUsers({
  userProfileGroupById,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  if (userProfileGroupById) {
    return userApi.getUsersForGroup(
      userProfileGroupById,
      filters,
      _scopes,
      _sort,
      _order,
      _page,
      _limit
    );
  }
  return userApi.getUsers(filters, _scopes, _sort, _order, _page, _limit);
}
export function getUserById(id) {
  return userApi.getUserById(id);
}

export function saveUser(user) {
  return function (dispatch) {
    return userApi.saveUser(user).then((savedUser) => {
      dispatch({
        type: user.id ? actionTypes.UPDATE_USER : actionTypes.CREATE_USER,
        user: savedUser,
      });
    });
  };
}

export function getAssignableUsers() {
  return userApi.getAssignableUsers();
}

export function getUserProfileGroupsForUser(userProfileId) {
  return userApi.getUserProfileGroupsForUser(userProfileId);
}

export function getTerritoriesForUser(userProfileId) {
  return userApi.getTerritoriesForUser(userProfileId);
}

export function addTerritory(userProfileId, territoryId) {
  return userApi.addTerritory(userProfileId, territoryId);
}

export function removeTerritory(userProfileId, territoryId) {
  return userApi.removeTerritory(userProfileId, territoryId);
}

export function archiveUser(id) {
  return userApi.archiveUser(id);
}

export function resendInvite(id) {
  return userApi.resendInvite(id);
}

export function resetAuthenticator(id) {
  return userApi.resetAuthenticator(id);
}

export function exportUserData(filters, scopes) {
  return userApi.exportUserData(filters, scopes);
}

export function setLanguage(language) {
  return userApi.setLanguage(language);
}
