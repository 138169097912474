import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../../layout/ActionBar';
import SlidingPane from 'react-sliding-pane';
import {
  getStats,
  getUserById,
  getUserProfileGroupsForUser,
  getTerritoriesForUser,
  resendInvite,
  getAudit,
  addAudit,
  getStatsByDay,
  getAuthorisedStatsByDay,
  getUploadedStatsByDay,
} from '../../../actions/userActions';

import { toast } from 'react-toastify';
import Loading from '../../common/Loading';
import { getPermissionUser } from '../../../actions/permissionActions';
import UserStats from './components/UserStats';
import AuditButton from '../../audit/components/AuditButton';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import ResponsiveActionBarButtons from '../../common/ResponsiveActionBarButtons';
import EditUser from './slideout/EditUser';
import UserDetails from './components/UserDetails';
import RequestsTable from '../../requests/components/RequestsTable';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

const ManageUser = ({
  id,
  history,
  currentUser,
  location,
  setCompleteRequestSlideoutOpen,
}) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [reloadRequests, setReloadRequests] = useState();
  const [permissions, setPermissions] = useState();
  const [userProfileGroups, setUserProfileGroups] = useState();
  const [userProfileTerritories, setUserProfileTerritories] = useState();
  const [stats, setStats] = useState();
  const [showEditUser, setShowEditUser] = useState(false);

  const [user, setUser] = useState();

  const onResendInvite = () => {
    if (!user) {
      toast.error(t('Failed to resend invite'));
      return;
    }

    resendInvite(user.id)
      .then(() => {
        toast.success(t('Invite resent'));
      })
      .catch((e) => {
        toast.error(t('Failed to resend invite'));
      });
  };

  useEffect(() => {
    if (id) {
      // get the user
      getUserById(id)
        .then((d) => {
          setUser(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get user'));
        });

      // get the stats
      getStats(id)
        .then((d) => {
          setStats(d);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [id, history, reload, t]);

  // user's permissions
  useEffect(() => {
    if (id && hasPermission(currentUser, permissionTypes.MANAGE_PERMISSIONS)) {
      getPermissionUser(id)
        .then((d) => {
          setPermissions(d);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get permissions'));
        });
    }
  }, [currentUser, reload, id, t]);

  // user's groups
  useEffect(() => {
    if (id) {
      getUserProfileGroupsForUser(id)
        .then((d) => {
          setUserProfileGroups(d.data);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get groups'));
        });
    }
  }, [reload, id, t]);

  // user's territories
  useEffect(() => {
    if (id) {
      getTerritoriesForUser(id)
        .then((d) => {
          setUserProfileTerritories(d.data);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Failed to get territories'));
        });
    }
  }, [reload, id, t]);

  return user && stats ? (
    <>
      <ActionBar
        breadcrumb={[
          { link: '/users', label: t('Users') },
          { label: user.fullName ?? user.username },
        ]}
      >
        {hasPermission(currentUser, permissionTypes.MANAGE_USERS) && (
          <>
            <AuditButton id={id} getAudit={getAudit} addAudit={addAudit} />
            <ResponsiveActionBarButtons
              buttons={[
                !user.isInviteAccepted && (
                  <Button
                    key="resend"
                    className="btn btn-default ms-2 d-none d-md-inline-flex"
                    onClick={onResendInvite}
                    isDisabled={user.isArchived}
                    title={
                      user.isArchived
                        ? t('User is archived')
                        : t('Resend invite')
                    }
                    text={t('Resend invite')}
                  />
                ),
                <Button
                  key="edit"
                  className="btn btn-primary ms-2 d-none d-md-inline-flex"
                  onClick={() => setShowEditUser(true)}
                  text={t('Edit')}
                />,
              ]}
            />

            <SlidingPane
              isOpen={showEditUser}
              hideHeader={true}
              from="right"
              className="large-side-panel"
              onRequestClose={() => setShowEditUser(false)}
            >
              <EditUser
                id={user.id}
                history={history}
                title={t('Edit {user}', { user: user.fullName || 'user' })}
                done={(d) => {
                  setReload(new Date());
                  setShowEditUser(false);
                }}
                cancel={() => setShowEditUser(false)}
              />
            </SlidingPane>
          </>
        )}
      </ActionBar>

      <UserDetails
        user={user}
        userProfileGroups={userProfileGroups}
        permissions={permissions}
        userProfileTerritories={userProfileTerritories}
        currentUser={currentUser}
      />

      <UserStats
        id={id}
        stats={stats}
        getData={() => getStatsByDay(id)}
        getAuthorisedData={() => getAuthorisedStatsByDay(id)}
        getUploadedData={() => getUploadedStatsByDay(id)}
      />

      <RequestsTable
        title={t('Requests made by {user}', { user: user.fullName })}
        location={location}
        history={history}
        setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
        scope={{ user: id }}
        reload={reloadRequests}
        setReload={setReloadRequests}
        settingsKey={'user-requests-list'}
      />
    </>
  ) : (
    <Loading></Loading>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
