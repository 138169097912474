import React from 'react';
import moment from 'moment';
import WorkflowLink from '../components/common/links/WorkflowLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Returns a 123456.00 as 123,456
 * @param {any} data
 * @param {any} displayOnZero
 */
export function CommaNumber_NoDecimal(data, displayOnZero) {
  if (isNaN(data)) {
    return data;
  }
  if (data === 0 && displayOnZero !== undefined) {
    return displayOnZero;
  }

  return data == null
    ? ''
    : data.toLocaleString('en-GB', { minimumFractionDigits: 0 });
}

/**
 * Returns a 123456.00 as 123,456.00
 * @param {any} data
 */
export function CommaNumber_WithDecimal(data) {
  if (isNaN(data)) {
    return data;
  }
  return data == null
    ? ''
    : data.toLocaleString('en-GB', { minimumFractionDigits: 2 });
}

const parseDate = (data) => {
  const date = new Date(data);
  const justNow = 5 * 60 * 1000; //5 minutes
  const dateDiff = new Date() - date;
  const isJustNow = dateDiff > 0 && dateDiff < justNow;
  return [date, isJustNow];
};
const twoDigitPad = (num) => {
  return num < 10 ? '0' + num : num;
};

/**
 * Returns a '2016-04-28T09:19:55.343' as '28/04/2016<br><small>09:19:55</small>'
 * @param {any} data
 */
export function Date_AsString(data) {
  if (data === null) {
    return '';
  }
  const [date, isJustNow] = parseDate(data);

  if (isJustNow) {
    return <span title={date.toLocaleString()}>Just now</span>;
  } else {
    return (
      <>
        {date.toLocaleDateString()}
        <br />
        <small>
          {twoDigitPad(date.getHours())}:{twoDigitPad(date.getMinutes())}
        </small>
      </>
    );
  }
}

/**
 * Returns a '2016-04-28T09:19:55.343' as '28/04/2016 <small>09:19:55</small>'
 * @param {any} data
 */
export function Date_AsString_OneLine(data) {
  if (data === null || data === 0 || data === '') {
    return '';
  }
  const [date, isJustNow] = parseDate(data);

  if (isJustNow) {
    return <span title={date.toLocaleString()}>Just now</span>;
  } else {
    return (
      <>
        {date.toLocaleDateString() + ' '}
        <small>
          {twoDigitPad(date.getHours())}:{twoDigitPad(date.getMinutes())}
        </small>
      </>
    );
  }
}

/**
 * Returns a '2016-04-28T09:19:55.343' as '28/04/2016 09:19:55' in a react fragment
 * @param {any} data
 * @param {any} valueIfNull
 */
export function Date_AsString_NoHtml(data, valueIfNull) {
  if (data === null || data === '') {
    return valueIfNull || '';
  }
  const [date, isJustNow] = parseDate(data);

  if (isJustNow) {
    return <span title={date.toLocaleString()}>Just now</span>;
  } else {
    return (
      <>
        {date.toLocaleDateString()} {twoDigitPad(date.getHours())}:
        {twoDigitPad(date.getMinutes())}
      </>
    );
  }
}

/**
 * Returns a '2016-04-28T09:19:55.343' as '28/04/2016 19:55' with no HTML
 * @param {any} data
 * @param {any} valueIfNull
 */
export function Date_AsString_Raw(data, valueIfNull) {
  if (data === null || data === '') {
    return valueIfNull || '';
  }
  const [date, isJustNow] = parseDate(data);

  if (isJustNow) {
    return 'Just now';
  } else {
    return `${date.toLocaleDateString()} ${twoDigitPad(
      date.getHours()
    )}:${twoDigitPad(date.getMinutes())}`;
  }
}

/**
 * Returns a '2016-04-28T09:19:55.343' as '28/04/2016' with no HTML
 * @param {any} data
 * @param {any} valueIfNull
 */
export function DateOnly_AsString_Raw(data, valueIfNull) {
  if (data === null || data === '') {
    return valueIfNull || '';
  }
  const [date, isJustNow] = parseDate(data);

  if (isJustNow) {
    return 'Just now';
  } else {
    return `${date.toLocaleDateString()}`;
  }
}

/**
 * Returns a '2016-04-28T09:19:55.343' as 'Wed, 28/04/2016' with no HTML
 * @param {any} data
 * @param {any} valueIfNull
 */
export function DateAndWeekDayOnly_AsString_Raw(data, valueIfNull) {
  if (data === null || data === '') {
    return valueIfNull || '';
  }
  const [date, isJustNow] = parseDate(data);

  if (isJustNow) {
    return 'Just now';
  } else {
    return `${moment(date).format('ddd')}, ${date.toLocaleDateString()}`;
  }
}

/**
 * Returns a '2016-04-28T09:19:55.343' as '28/04/2016' with no HTML
 * @param {any} data
 * @param {any} valueIfNull
 */
export function DateOnly_AsString_NoHtml(data, valueIfNull) {
  if (data === null || data === '') {
    return valueIfNull || '';
  }
  const [date] = parseDate(data);
  return `${date.toLocaleDateString()}`;
}

export function DateForFilter(data, valueIfNull) {
  if (data === null || data === '') {
    return valueIfNull || '';
  }
  const [date, isJustNow] = parseDate(data);
  return moment(date).format('DD-MMM-YYYY');
}

export function CurrentDateAsString() {
  return moment(new Date()).format('YYMMDD');
}

export function CurrentDateForDatePicker() {
  return moment(new Date()).format('YYYY-MM-DD');
}
/**
 * Returns a '5000' as '5.0 kB'
 * @param data Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * @param valueIfNull
 *
 * @return Formatted string.
 */
export function ByteLength_AsString(data, si = true, dp = 1, valueIfNull) {
  if (data === null || data === '') {
    return valueIfNull || '';
  }

  const thresh = si ? 1000 : 1024;

  if (Math.abs(data) < thresh) {
    return data + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    data /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(data) * r) / r >= thresh &&
    u < units.length - 1
  );

  return data.toFixed(dp) + ' ' + units[u];
}

export function WorkflowWithJustification({
  keyWorkflowName,
  keyWorkflowId,
  keyWorkflowColourHex,
  justification,
}) {
  let trimString = (str, len) =>
    str.length > len ? (str = str.substring(0, len) + '...') : str;
  return (
    <>
      <WorkflowLink
        id={keyWorkflowId}
        key={keyWorkflowId}
        name={keyWorkflowName}
        colour={keyWorkflowColourHex}
      />
      {justification ? (
        <div title={justification} className="justification mt-2">
          <span className="icon-wrapper">
            <FontAwesomeIcon
              icon="fa-light fa-balance-scale"
              className="me-2 c-pos-1"
            />
          </span>
          {trimString(justification, 30)}
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export function TryFormatJson(data) {
  try {
    return JSON.stringify(JSON.parse(data), null, 4);
  } catch {
    return data;
  }
}
export function FriendlyTimeFromMinutes(minutes) {
  return FriendlyTimeFromSeconds(minutes * 60);
}
export function FriendlyTimeFromSeconds(seconds) {
  if (seconds) {
    let months = Math.floor(seconds / 2628000);
    let days = Math.floor(seconds / 86400);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor(seconds / 60);
    let remainingMinutes = Math.floor(minutes - hours * 60);
    let years = Math.floor(months / 12);
    let remainingMonths = Math.floor(months - years * 12);

    if (years >= 1) {
      if (years > 1) {
        if (remainingMonths >= 1) {
          return `${years} years ${remainingMonths} months`;
        } else {
          return `${years} years`;
        }
      } else {
        if (remainingMonths >= 1) {
          return `${years} year ${remainingMonths} months`;
        } else {
          return `${years} year`;
        }
      }
    } else if (months >= 1) {
      if (months > 1) {
        return `${months} months`;
      } else {
        return `${months} month`;
      }
    } else if (days >= 1) {
      if (days > 1) {
        return `${days} days`;
      } else {
        return `${days} day`;
      }
    } else if (hours >= 1) {
      if (hours > 1) {
        if (remainingMinutes >= 1) {
          return `${hours} hours ${remainingMinutes} minutes`;
        } else {
          return `${hours} hours`;
        }
      } else {
        if (remainingMinutes >= 1) {
          return `${hours} hour ${remainingMinutes} minutes`;
        } else {
          return `${hours} hour`;
        }
      }
    } else {
      if (minutes > 1) {
        return `${minutes} mins`;
      } else {
        // make this 1 minute to prevent problems if elapsed time is 59 seconds or less?
        return `1 min`;
      }
    }
  } else {
    return '0 min';
  }
}

export function hexToRgbA(hex, opacity) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )},${opacity})`
    : null;
}

export function lightenDarkenColor(col, amt) {
  col = col.replace(/^#/, '');
  if (col.length === 3)
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [
    parseInt(r, 16) + amt,
    parseInt(g, 16) + amt,
    parseInt(b, 16) + amt,
  ];

  r = r <= amt ? r + amt : r;
  g = g <= amt ? g + amt : g;
  b = b <= amt ? b + amt : b;

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
}

export function ParseBoolean(str) {
  return str === 'true' || str === 'True';
}

export function ParseInt(value) {
  return value ? (isNaN(value) || value === '' ? 0 : parseInt(value)) : 0;
}

export function FormatCamelCaseAsString(camel) {
  return camel
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z][a-z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase());
}

export function ToCamelCase(str) {
  return str
    .replace(/[-_](.)/g, function (match, group) {
      return group.toUpperCase();
    })
    .replace(/^./, function (match) {
      return match.toLowerCase();
    });
}

export function Truncate(str, maxLength) {
  if (!str) {
    return '';
  }
  return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
}
export function hexDistance(hex, compareHex) {
  // convert hex values to RGB
  let r1 = parseInt('ff', 16);
  let g1 = parseInt('ff', 16);
  let b1 = parseInt('ff', 16);
  if (compareHex) {
    r1 = parseInt(compareHex.substring(1, 3), 16);
    g1 = parseInt(compareHex.substring(3, 5), 16);
    b1 = parseInt(compareHex.substring(5, 7), 16);
  }
  const r2 = parseInt(hex.substring(1, 3), 16);
  const g2 = parseInt(hex.substring(3, 5), 16);
  const b2 = parseInt(hex.substring(5, 7), 16);

  // calculate the Euclidean distance between the two colors
  const distance = Math.sqrt(
    Math.pow(r2 - r1, 2) + Math.pow(g2 - g1, 2) + Math.pow(b2 - b1, 2)
  );

  return distance;
}

const RED = 0.2126;
const GREEN = 0.7152;
const BLUE = 0.0722;
const GAMMA = 2.4;

function hextToRGB(hex) {
  return [
    parseInt(hex.substring(1, 3), 16),
    parseInt(hex.substring(3, 5), 16),
    parseInt(hex.substring(5, 7), 16),
  ];
}

function luminance(r, g, b) {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA);
  });
  return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
}

function contrast(rgb1, rgb2) {
  const lum1 = luminance(...rgb1);
  const lum2 = luminance(...rgb2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

export function bestContrast(hex, lightresult, darkresult) {
  if (hex === 'transparent') {
    return lightresult;
  }

  let rgb = hextToRGB(hex);
  let lightDistance = contrast(rgb, [255, 255, 255]);
  let darkDistance = contrast(rgb, [0, 0, 0]);
  return lightDistance > darkDistance ? lightresult : darkresult;
}

export function gameNameSubsition(gameName, skuName) {
  return skuName.indexOf(gameName) > -1 ? '...' : gameName;
}

export function skuNameSubsition(gameName, skuName) {
  return skuName.indexOf(gameName) > -1
    ? skuName.replace(gameName, '')
    : skuName;
}
