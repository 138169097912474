import React from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import KwPanel from '../../../common/KwPanel';
import Button from '../../../common/Button';
import ConfirmationHeader from '../../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function ResetAuthenticator({ cancel, done }) {
  const { t } = useTranslation();
  const handleYes = () => {
    done();
  };

  return (
    <SlideoutLayout title={t('Confirmation')} cancel={cancel} done={done}>
      <KwPanel>
        <ConfirmationHeader text={t('Are you sure?')} />
        <p>
          {t(
            'In the event the user no longer has access to their authenticator app, this action will remove the association and allow them to setup authenticator on next login'
          )}
        </p>
        <hr />
        <Button
          className="btn btn-default me-2"
          onClick={handleYes}
          text={t('Yes, reset the authenticator')}
        />
        <Button
          className="btn btn-primary"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}

ResetAuthenticator.propTypes = {
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default ResetAuthenticator;
