import React, { useState, useEffect } from 'react';

function Checkbox({ onChange, checked, label, className, disabled, title }) {
  const [value, setValue] = useState(checked || false);
  useEffect(() => {
    setValue(checked);
  }, [checked]);

  const handleClick = () => {
    if (!disabled) {
      setValue((prevState) => !prevState);
      onChange(!value);
    }
  };
  return (
    <>
      <span
        className={`${className ? className : ''} checkbox-container ${
          disabled ? 'disabled' : ''
        }`}
        onClick={handleClick}
        title={title}
      >
        <input
          type="checkbox"
          style={{ display: 'inline' }}
          checked={value}
          onChange={handleClick}
          disabled
        />
        <span className="checkmark"></span>
      </span>
      {label && (
        <span className="ms-2 checkbox-label" onClick={handleClick}>
          {' '}
          {label}
        </span>
      )}
    </>
  );
}

export default Checkbox;
