import { hasPermission } from '../components/auth/authUtils';
import permissionTypes from '../constants/permissionTypes';

export function CanCopyRequest({ companyId, requestedById, currentUser }) {
  if (!companyId || !requestedById || !currentUser) {
    return false;
  }
  return (
    companyId === currentUser.companyId ||
    hasPermission(currentUser, permissionTypes.REQUEST_ON_BEHALF_OF)
  );
}
