import React from 'react';
import KwPanel from '../../common/KwPanel';
import icons from '../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function KeyProcessProgressBar({ transaction }) {
  const heading = transaction.status;
  const message = transaction.message;

  const percentageComplete = transaction.percentageComplete;

  const className = transaction.partialSuccess
    ? 'progress-bar-warning'
    : transaction.isAuthorised === false
      ? 'progress-bar-info'
      : transaction.success === false
        ? 'progress-bar-danger'
        : 'progress-bar-info';

  return transaction.success ? (
    ''
  ) : (
    <KwPanel className="mb-4">
      <header className="mb-2">
        <h2 className="no-margin">{heading}</h2>
      </header>
      <div>
        <div className="progress my-4">
          <div
            className={`progress-bar progress-bar-striped active ${className}`}
            role="progressbar"
            aria-valuenow={percentageComplete}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${percentageComplete}%` }}
          >
            <span className="sr-only"></span>
          </div>
        </div>
        {message && (
          <p
            style={{ wordBreak: 'break-all', whiteSpace: 'pre' }}
            className="alert alert-info"
          >
            {' '}
            <FontAwesomeIcon icon={icons.INFO} className={`me-3`} />
            {message}
          </p>
        )}
      </div>
    </KwPanel>
  );
}
export default KeyProcessProgressBar;
