import React, { useState } from 'react';
import { connect } from 'react-redux';
import CompanyTypesList from './components/CompanyTypeList';
import ActionBar from '../../layout/ActionBar';
import Edit from './slideout/Edit';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function CompanyTypes({ location }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [addCompanyTypeSlideoutOpen, setAddCompanyTypeSlideoutOpen] =
    useState(false);

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={() => setAddCompanyTypeSlideoutOpen(true)}
          text={t('Add company type')}
        />
      </ActionBar>
      <CompanyTypesList location={location} reload={reload} />

      <SlidingPane
        isOpen={addCompanyTypeSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddCompanyTypeSlideoutOpen(false)}
      >
        <Edit
          done={(d) => {
            setReload(new Date());
            setAddCompanyTypeSlideoutOpen(false);
          }}
          cancel={() => setAddCompanyTypeSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTypes);
