import { apiUrl, exportData, getList, getSingleRecord } from './apiUtils';
const baseUrl = apiUrl + '/emails/';

export function getEmails(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getEmailById(id) {
  return getSingleRecord(baseUrl, id);
}

export function exportEmailData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/emails/search`,
    'creationDate',
    filters,
    scopes
  );
}

export function resendEmail(id) {
  return getSingleRecord(`${baseUrl}resend/`, id);
}
