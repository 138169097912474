import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import KwPanel from '../../../common/KwPanel';
import { getKeyProvidersForPlatform } from '../../../../actions/platformActions';
import DeletePlatformKeyProvider from './DeletePlatformKeyProvider';
import AddPlatformKeyProvider from './AddPlatformKeyProvider';
import SlidingPane from 'react-sliding-pane';

import image_request_keys from '../../../../content/images/request_icons/request_keys.svg';
import icons from '../../../../constants/icons';
import KeyProviderLink from '../../../common/links/KeyProviderLink';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function KeyProviderBadges({ label, platformId }) {
  const { t } = useTranslation();
  const [keyProviders, setKeyProviders] = useState();
  useEffect(() => {
    if (platformId && !keyProviders) {
      // get keyProviders
      getKeyProvidersForPlatform(platformId)
        .then((d) => {
          setKeyProviders(d);
        })
        .catch((e) => {
          toast.error(t('Failed to find key providers for platform'));
        });
    }
  }, [platformId, keyProviders]);

  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const addKeyProvider = (e) => {
    e.cancelBubble = true;
    setAddSlideoutOpen(true);
    return false;
  };

  const [removeSlideoutOpen, setRemoveSlideoutOpen] = useState(false);
  const [removeSlideoutItem, setRemoveSlideoutItem] = useState();
  const removeKeyProvider = (e, keyProviderId) => {
    e.cancelBubble = true;
    setRemoveSlideoutItem(keyProviderId);
    setRemoveSlideoutOpen(true);
    return false;
  };

  return (
    <>
      <KwPanel className="mb-4">
        <header className="mb-2">
          <h2 className="no-margin">
            <span className="workflow-icon">
              <img
                src={image_request_keys}
                alt={t('Upload keys icon')}
                width="32"
                height="32"
              />
            </span>
            {label}
          </h2>
          <div className="float-right">
            <Button
              className="btn btn-default"
              onClick={addKeyProvider}
              text={t('Add')}
            />
          </div>
        </header>
        <div>
          {keyProviders && keyProviders.length > 0 ? (
            <div className="tags">
              {keyProviders.map((keyProvider) => (
                <div className="tag" key={keyProvider.id}>
                  <div className="d-flex">
                    <KeyProviderLink
                      id={keyProvider.id}
                      name={keyProvider.name}
                    />
                    <span
                      className="ms-3 a"
                      onClick={(e) => removeKeyProvider(e, keyProvider.id)}
                      title={t('Remove')}
                    >
                      <FontAwesomeIcon icon={icons.DELETE_TAG} />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <span>
              {t(
                'There have been no supported key providers defined for this key platform'
              )}
            </span>
          )}
        </div>
      </KwPanel>

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <AddPlatformKeyProvider
          done={(d) => {
            setKeyProviders([...keyProviders, d]);
            setAddSlideoutOpen(false);
          }}
          cancel={() => setAddSlideoutOpen(false)}
          platformId={platformId}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={removeSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRemoveSlideoutOpen(false)}
      >
        <>
          {removeSlideoutItem && (
            <DeletePlatformKeyProvider
              platformId={platformId}
              keyProviderId={removeSlideoutItem}
              done={(d) => {
                if (!keyProviders) {
                  setRemoveSlideoutOpen(false);
                  return;
                }
                setKeyProviders(
                  keyProviders.filter((r) => r.id !== removeSlideoutItem)
                );
                setRemoveSlideoutOpen(false);
              }}
              cancel={() => setRemoveSlideoutOpen(false)}
            />
          )}
        </>
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(KeyProviderBadges);
