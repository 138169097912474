import React, { useEffect, useRef } from 'react';
import * as wordanimation from '../util/alaska.ui.oopsanimation.js';
import { useTranslation } from 'react-i18next';

function TrialEnded() {
  const ref = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (ref.current) {
      const initializingComponent = ref.current;
      const initCanvas =
        initializingComponent.getElementsByTagName('canvas')[0];
      initializingComponent.style.transition = `opacity 20ms, transform 20ms`;
      initializingComponent.style.opacity = 1;
      initializingComponent.style.display = null;
      wordanimation.init(initCanvas, t('ENDED'), t('Your trial has ended'), [
        '#16beef',
        '#46B29D',
        '#BB5EBF',
        '#fe5557',
        '#b2178a',
      ]);
    }
  }, [ref, t]);
  return (
    <div className="page page--403">
      <main ref={ref}>
        <canvas></canvas>
      </main>
    </div>
  );
}

export default TrialEnded;
