export default {
  Company: 0,
  DisabledTransaction: 1,
  DisableReason: 2,
  Game: 3,
  ImportTransaction: 4,
  KeyPoolLowerLimit: 5,
  KeyProvider: 6,
  KeyRequest: 7,
  KeyRequestGroup: 8,
  KeyUploader: 9,
  KeyWorkflow: 10,
  PackageType: 11,
  Platform: 12,
  Sku: 13,
  SoldTransaction: 14,
  Template: 15,
  Territory: 16,
  UserProfile: 17,
  UserProfileGroup: 18,
};
