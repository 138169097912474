import React, { useState } from 'react';
import {
  loadGameTemplates,
  exportGameTemplateData,
} from '../../../../actions/gameTemplateActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { CommaNumber_NoDecimal } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import { useTranslation } from 'react-i18next';

function GameTemplateList({ location, reload }) {
  const { t } = useTranslation();
  const allColumns = ['name', 'territoryCount', 'workflowCount'];
  const defaultVisibleColumns = allColumns;
  const [gameTemplates, setGameTemplates] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'gametemplatelist-settings',
      getTableSettingsDefaults({ defaultSort: 'name', defaultVisibleColumns }),
      null,
      loadGameTemplates,
      setGameTemplates,
      reload,
      t('Failed to load game templates'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Name'),
      width: '40%',
      Render: (gameTemplate) => (
        <Link to={'/settings/game-template/' + gameTemplate.id}>
          {gameTemplate.name}
        </Link>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'territoryCount',
      Label: t('Territories'),
      width: '30%',
      className: 'text-right num no-wrap',
      Render: (gameTemplate) =>
        CommaNumber_NoDecimal(gameTemplate.territoryCount),
    },
    {
      Key: 'workflowCount',
      Label: t('Workflows'),
      width: '30%',
      className: 'text-right num no-wrap',
      Render: (gameTemplate) =>
        CommaNumber_NoDecimal(gameTemplate.workflowCount),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return gameTemplates ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={gameTemplates.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={gameTemplates.totalRowCount}
        filteredRowCount={gameTemplates.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportGameTemplateData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default GameTemplateList;
