import React, { useState } from 'react';
import { loadCompaniesWithOpenRequests } from '../../../actions/companyActions';
import { NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';

import { CommaNumber_NoDecimal } from '../../../util/formatter';

import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import CompanyLink from '../../common/links/CompanyLink';
import { scopes as tableScopes } from '../../../constants/scopes';
import { useTranslation } from 'react-i18next';

function CompanyStats({ location, reload }) {
  const { t } = useTranslation();
  const allColumns = [
    'companyName',
    'requestsOpen',
    'requestsComplete',
    'keysRequested',
  ];
  const defaultVisibleColumns = allColumns;
  const [companies, setCompanies] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'home-company-openrequests-settings',
      getTableSettingsDefaults({
        defaultSort: 'requestsOpen',
        direction: 'desc',
        defaultVisibleColumns,
      }),
      null,
      loadCompaniesWithOpenRequests,
      setCompanies,
      reload,
      t('Failed to load companies'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'companyName',
      Label: t('Company'),
      Render: (item) => (
        <CompanyLink id={item.id} name={item.companyName} imageSize={32} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestsOpen',
      Label: t('Open'),
      className: 'text-right num no-wrap',
      Render: (item) => (
        <NavLink
          to={`/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}&${tableScopes.ISPENDING}=true&${tableScopes.COMPANY}=${item.id}`}
        >
          {CommaNumber_NoDecimal(item.requestsOpen)}
        </NavLink>
      ),
    },
    {
      Key: 'requestsComplete',
      Label: t('Complete'),
      className: 'text-right num no-wrap',
      Render: (item) => (
        <NavLink
          to={`/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISAUTHORISED}=true&${tableScopes.COMPANY}=${item.id}`}
        >
          {CommaNumber_NoDecimal(item.requestsComplete)}
        </NavLink>
      ),
    },
    {
      Key: 'keysRequested',
      Label: t('Total keys'),
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.keysRequested),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return companies ? (
    <KwPanel className="mb-4">
      <header className="mb-3">
        <h2 className="no-margin">{t('Companies with open requests')}</h2>
      </header>
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={companies.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={companies.totalRowCount}
        filteredRowCount={companies.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filterChange={filterChange}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default CompanyStats;
