const navColours = Object.freeze({
  NAV_COLOUR_1: 'nav-colour-1',
  NAV_COLOUR_2: 'nav-colour-2',
  NAV_COLOUR_3: 'nav-colour-3',
  NAV_COLOUR_4: 'nav-colour-4',
  NAV_COLOUR_5: 'nav-colour-5',
  NAV_COLOUR_6: 'nav-colour-6',
  NAV_COLOUR_7: 'nav-colour-5',
  NAV_COLOUR_8: 'nav-colour-4',
  NAV_COLOUR_9: 'nav-colour-3',
  NAV_COLOUR_10: 'nav-colour-2',
  NAV_COLOUR_11: 'nav-colour-1',
  NAV_COLOUR_12: 'nav-colour-2',
});

export default navColours;
