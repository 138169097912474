import React, { useState } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import BulkIngest from './slideout/BulkIngest';
import BulkIngestedBatchesList from './components/BulkIngestedBatchesList';
import SlidingPane from 'react-sliding-pane';
import { useTranslation } from 'react-i18next';
import { addToGroup, removeFromGroup } from '../../actions/signalRActions';
import Button from '../common/Button';
function BulkIngested({
  location,
  bulkTransactions,
  addToGroup,
  removeFromGroup,
  currentUser,
}) {
  const [reload, setReload] = useState();
  const { t } = useTranslation();

  const [bulkIngestSlideoutOpen, setBulkIngestSlideoutOpen] = useState(false);

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={() => setBulkIngestSlideoutOpen(true)}
          text={t('Bulk upload keys')}
        />
      </ActionBar>
      <BulkIngestedBatchesList
        location={location}
        reload={reload}
        addToGroup={addToGroup}
        removeFromGroup={removeFromGroup}
        bulkTransactions={bulkTransactions}
        currentUser={currentUser}
      />

      <SlidingPane
        isOpen={bulkIngestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setBulkIngestSlideoutOpen(false)}
      >
        <BulkIngest
          done={(d) => {
            setReload(new Date());
            setBulkIngestSlideoutOpen(false);
          }}
          cancel={() => setBulkIngestSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    bulkTransactions: state.bulkTransactions,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = { addToGroup, removeFromGroup };

export default connect(mapStateToProps, mapDispatchToProps)(BulkIngested);
