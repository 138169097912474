import React, { useState } from 'react';

const ImageWithFallback = ({ url, alt, fallBackImage }) => {
  const [errored, setErrored] = useState(false);
  return !errored && url ? (
    <div className="img-placeholder">
      <img
        src={url}
        referrerPolicy="no-referrer"
        alt={alt}
        width={120}
        height={45}
        onError={(e) => {
          e.target.onError = null;
          setErrored(true);
        }}
      ></img>
    </div>
  ) : (
    <div className="img-placeholder">{fallBackImage}</div>
  );
};
export default ImageWithFallback;
