import React, { useEffect, useState, useMemo } from 'react';
import ReactCalendarHeatmap from 'react-calendar-heatmap';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import {
  CommaNumber_NoDecimal,
  DateAndWeekDayOnly_AsString_Raw,
} from '../../util/formatter';
import KwPanel from './KwPanel';
import Scroll from './Scroll';
import SlidingPane from 'react-sliding-pane';
import AggregateStats from '../insights/AggregateStats';
import Button from './Button';
import icons from '../../constants/icons';
import { useTranslation } from 'react-i18next';

const StatsOverTime = ({
  getData,
  getAuthorisedData,
  getUploadedData,
  onClick,
  onAuthorisedClick,
  onUploadedClick,
  setModeStorageKey,
  id,
  mode,
}) => {
  const { t } = useTranslation();
  const [rawData, setRawData] = useState();
  const [rawAuthorisedData, setRawAuthorisedData] = useState();
  const [rawUploadedData, setRawUploadedData] = useState();
  const [data, setData] = useState();
  const [viewMode, setViewMode] = useLocalStorage(
    setModeStorageKey ?? 'StatsOverTime-mode',
    'requests'
  );
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [aggregateSlideoutOpen, setAggregateSlideoutOpen] = useState(false);

  useEffect(() => {
    if (!rawData) {
      getData()
        .then((d) => {
          setRawData(d);
        })
        .catch(() => {
          //silent failure, or we can throw a message here
        });
    }
  }, [getData, rawData]);

  useEffect(() => {
    if (!rawAuthorisedData && getAuthorisedData) {
      getAuthorisedData()
        .then((d) => {
          setRawAuthorisedData(d);
        })
        .catch(() => {
          //silent failure, or we can throw a message here
        });
    }
  }, [getAuthorisedData, rawAuthorisedData]);

  useEffect(() => {
    if (!rawUploadedData && getUploadedData) {
      getUploadedData()
        .then((d) => {
          setRawUploadedData(d);
        })
        .catch(() => {
          //silent failure, or we can throw a message here
        });
    }
  }, [getUploadedData, rawUploadedData]);

  useEffect(() => {
    if (rawData && (viewMode === 'requests' || viewMode === 'keys')) {
      let mappedData = rawData.map((x) => {
        return {
          date: x.metricDate,
          count: viewMode === 'requests' ? x.numberOfRequests : x.numberOfKeys,
        };
      });

      setData(mappedData);
      setMin(Math.min(...mappedData.map((item) => item.count)));
      setMax(Math.max(...mappedData.map((item) => item.count)));
    } else if (rawAuthorisedData && viewMode === 'authorised') {
      let mappedData = rawAuthorisedData.map((x) => {
        return {
          date: x.metricDate,
          count: x.numberOfRequests,
        };
      });

      setData(mappedData);
      setMin(Math.min(...mappedData.map((item) => item.count)));
      setMax(Math.max(...mappedData.map((item) => item.count)));
    } else if (rawUploadedData && viewMode === 'uploads') {
      let mappedData = rawUploadedData.map((x) => {
        return {
          date: x.metricDate,
          count: x.numberOfRequests,
        };
      });

      setData(mappedData);
      setMin(Math.min(...mappedData.map((item) => item.count)));
      setMax(Math.max(...mappedData.map((item) => item.count)));
    }
  }, [rawData, rawAuthorisedData, rawUploadedData, viewMode]);

  // Get today's date
  const today = new Date();

  const startDate = useMemo(() => {
    // Get a startdate 3 years ago that falls on a Sunday
    let start = new Date(
      today.getFullYear() - 3,
      today.getMonth(),
      today.getDate()
    );
    while (start.getDay() !== 0) {
      start.setDate(start.getDate() - 1);
    }
    return start;
  }, [today]);

  const getTitle = (value, viewMode) => {
    let suffix = '';
    if (viewMode === 'requests') {
      suffix = value?.count > 1 ? t('Requests made') : t('Request made');
    } else if (viewMode === 'keys') {
      suffix = value?.count > 1 ? t('Keys requested') : t('Key requested');
    } else if (viewMode === 'authorised') {
      suffix =
        value?.count > 1 ? t('Requests authorised') : t('Request authorised');
    } else if (viewMode === 'uploads') {
      suffix =
        value?.count > 1 ? t('Key request uploads') : t('Key request upload');
    }

    return value
      ? `${DateAndWeekDayOnly_AsString_Raw(
          value.date,
          ''
        )} : ${CommaNumber_NoDecimal(value.count)} ${suffix}`
      : ``;
  };

  const handleOnClick = (value, viewMode) => {
    if (viewMode === 'authorised' && onAuthorisedClick) {
      onAuthorisedClick(value);
    } else if (viewMode === 'uploads' && onUploadedClick) {
      onUploadedClick(value);
    } else {
      onClick(value);
    }
  };
  return (
    <KwPanel className={`mb-4 heatmap ${viewMode}`}>
      <Scroll style={{ minHeight: 126 }}>
        {data ? (
          <ReactCalendarHeatmap
            startDate={startDate}
            onClick={(value) => handleOnClick(value, viewMode)}
            endDate={today}
            values={data}
            titleForValue={(value) => getTitle(value, viewMode)}
            classForValue={(value) => {
              if (!value) {
                return 'color-empty';
              }
              let f = (max - min) / 5;
              if (value.count < f) return `color-0`;
              if (value.count < f * 2) return `color-1`;
              if (value.count < f * 3) return `color-2`;
              if (value.count < f * 4) return `color-3`;
              return `color-4`;
            }}
          />
        ) : (
          <></>
          // <Loading inPanel={true} />
        )}
      </Scroll>
      <div className="d-flex justify-content-between mt-2">
        <Button
          icon={icons.INSIGHTS}
          className="btn btn-default btn-outline"
          onClick={() => setAggregateSlideoutOpen(true)}
          text={t('Insights')}
        />
        <div className="react-calendar-heatmap-key">
          <div className="legend">{t('Less')}</div>
          <span className="c-0 ms-2"></span>
          <span className="c-1"></span>
          <span className="c-2"></span>
          <span className="c-3"></span>
          <span className="c-4 me-2"></span>
          <div className="legend">{t('More')}</div>
          <div className="toggle-view ms-4">
            <button
              className={`requests ${viewMode === 'requests' ? 'active' : ''}`}
              onClick={() => setViewMode('requests')}
              title={t('Requests made')}
            >
              {t('Requests')}
            </button>
            {' | '}
            <button
              className={`me-2 keys ${viewMode === 'keys' ? 'active' : ''}`}
              onClick={() => setViewMode('keys')}
              title={t('Keys requested')}
            >
              {t('Keys')}
            </button>
            {getAuthorisedData && (
              <>
                {' | '}
                <button
                  className={`authorised ${
                    viewMode === 'authorised' ? 'active' : ''
                  }`}
                  onClick={() => setViewMode('authorised')}
                  title={t('Requests authorised')}
                >
                  {t('Authorised')}
                </button>
              </>
            )}
            {getUploadedData && (
              <>
                {' | '}
                <button
                  className={`uploads ${
                    viewMode === 'uploads' ? 'active' : ''
                  }`}
                  onClick={() => setViewMode('uploads')}
                  title={t('Key request uploads')}
                >
                  {t('Uploads')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <SlidingPane
        isOpen={aggregateSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setAggregateSlideoutOpen(false)}
      >
        <AggregateStats
          id={id}
          mode={mode}
          done={() => {
            setAggregateSlideoutOpen(false);
          }}
          cancel={() => setAggregateSlideoutOpen(false)}
        />
      </SlidingPane>
    </KwPanel>
  );
};

export default StatsOverTime;
