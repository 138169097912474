import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import FormGroup from './FormGroup';

function FormToggleGroup({
  label,
  error,
  errorMessage,
  required,
  name,
  control,
  defaultValue,
  disabled,
  options,
}) {
  const [value, setValue] = useState(defaultValue);

  return (
    <FormGroup
      label={label}
      error={error}
      errorMessage={errorMessage}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange } }) => (
          <div className="toggle-group no-wrap">
            {options.map((option) => (
              <label
                key={option.id}
                aria-label={label}
                className={`${value === option.id ? 'checked' : ''}`}
                onClick={(e) => {
                  onChange(option.id);
                  setValue(option.id);
                }}
              >
                {option.label}
              </label>
            ))}
          </div>
        )}
      />
    </FormGroup>
  );
}

export default FormToggleGroup;
