import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
function TransactionStatusBadge({ success, partialSuccess, message, status }) {
  const { t } = useTranslation();

  if (success == null) {
    return <span className="badge bg-info">{t('processing')}</span>;
  }

  if (success) {
    return <span className="badge bg-success">{status}</span>;
  }

  if (partialSuccess) {
    return <span className="badge bg-warning">{status}</span>;
  }

  return (
    <span className="badge bg-danger" title={message}>
      {status}
    </span>
  );
}

TransactionStatusBadge.propTypes = {
  success: PropTypes.bool,
  partialSuccess: PropTypes.bool,
  message: PropTypes.string,
  status: PropTypes.string,
};

export default TransactionStatusBadge;
