import React from 'react';
export default function MenuIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 46 34"
      xmlSpace="preserve"
      className="center-block"
      style={{ height: '20px' }}
    >
      <rect
        id="Rectangle_11"
        data-name="Rectangle 11"
        width="46"
        height="8"
        fill="#fff"
      />
      <rect
        id="Rectangle_12"
        data-name="Rectangle 12"
        width="46"
        height="8"
        transform="translate(0 13)"
        fill="#fff"
      />
      <rect
        id="Rectangle_13"
        data-name="Rectangle 13"
        width="46"
        height="8"
        transform="translate(0 26)"
        fill="#fff"
      />
    </svg>
  );
}
