import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UsersList from './components/UsersList';
import ActionBar from '../../layout/ActionBar';
import AddMember from './slideout/AddMember';
import { getUserProfileGroupById } from '../../../actions/userProfileGroupActions';
import { toast } from 'react-toastify';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function UsersInGroup({ id, location, history }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [userProfileGroup, setUserProfileGroup] = useState({});

  const [addSlideoutOpen, setAddSlideoutOpen] = useState(false);
  const handleAddMember = (e) => {
    e.cancelBubble = true;
    setAddSlideoutOpen(true);
    return false;
  };

  useEffect(() => {
    if (id) {
      getUserProfileGroupById(id)
        .then((d) => {
          setUserProfileGroup(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/settings/user-profile-groups/');
          toast.error(t('Group not found'));
        });
    }
  }, [id, history, t]);

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={handleAddMember}
          text={t('Add member')}
        />
      </ActionBar>
      <UsersList
        location={location}
        userProfileGroupById={id}
        reload={reload}
      />

      <SlidingPane
        isOpen={addSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddSlideoutOpen(false)}
      >
        <AddMember
          userProfileGroupId={id}
          cancel={() => setAddSlideoutOpen(false)}
          done={(d) => {
            setAddSlideoutOpen(false);
            setReload(new Date());
          }}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    location: ownProps.location,
    history: ownProps.history,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UsersInGroup);
