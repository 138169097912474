import React from 'react';
import { useTranslation } from 'react-i18next';

import image_loading from '../../../content/images/loading.svg';

export default function Loading() {
  const { t } = useTranslation();
  return (
    <div className="loading">
      <img className="publisher-img" src={image_loading} alt={t('Loading')} />
    </div>
  );
}
