import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import GameResult from './GameResult';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Search({ games, onSelect, disabled }) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  return (
    <Typeahead
      id="game-search"
      placeholder={t('Start typing a game name..')}
      labelKey="name"
      minLength={3}
      onChange={(options) => {
        onSelect(options);
        setSelected([]);
      }}
      selected={selected}
      renderMenuItemChildren={(option, props) => (
        <GameResult {...option}></GameResult>
      )}
      options={games || []}
      disabled={disabled}
    />
  );
}

Search.propTypes = {
  games: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Search;
