import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  loadRequestsByMonthByWorkflow,
  exportRequestsByMonthByWorkflow,
} from '../../../../actions/reportByMonthActions';
import { getAllCompanies } from '../../../../actions/companyActions';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { CommaNumber_NoDecimal } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import useTableSettings from '../../../../hooks/useTableSettings';
import YearFormSelect from '../YearFormSelect';
import CompanyFormSelect from '../CompanyFormSelect';
import WorkflowLink from '../../../common/links/WorkflowLink';
import { useTranslation } from 'react-i18next';

import { scopes } from '../../../../constants/scopes';

function RequestsByMonthListByWorkflow({
  location,
  companies,
  getAllCompanies,
  currentUser,
  history,
  tableViewSwitcher,
}) {
  const { t } = useTranslation();
  const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];
  const allColumns = ['workflow', 'year'];
  for (const month of months) {
    allColumns.push(month);
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const defaultVisibleColumns = allColumns;
  const [reload, setReload] = useState();
  const [items, setItems] = useState();
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useLocalStorage('report-year', currentYear);
  const [company, setCompany] = useState();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const years = range(currentYear, currentYear - 5, -1);
  const params = new URLSearchParams(location.search);
  const {
    tableSettings,
    setTableSettings,
    filters,
    filterChange,
    isLoading,
    parameters,
  } = useTableSettings(
    'requestsbymonthreport-workflow-settings',
    getTableSettingsDefaults({
      defaultSort: 'workflow',
      defaultVisibleColumns,
    }),
    { year, company },
    loadRequestsByMonthByWorkflow,
    setItems,
    reload,
    t('Failed to load report'),
    location
  );

  useEffect(() => {
    if (!companies) {
      getAllCompanies();

      const params = new URLSearchParams(location.search);
      const companyId = params.get(scopes.COMPANY);
      if (companyId) {
        setCompany(companyId);
      }
    }
  }, [companies, getAllCompanies]);

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'workflow',
      Label: t('Workflow'),
      Render: (item) => (
        <WorkflowLink
          Key={item.Key}
          id={item.keyWorkflowId}
          name={item.workflow}
          colour={item.colourHex}
          currentUser={currentUser}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'year',
      Label: t('Year'),
      Render: (item) => item.year,
    },
  ];
  // Add the months to the array
  for (const month of months) {
    columnsConfig.push({
      Key: month,
      Label: t(capitalizeFirstLetter(month)),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row[month], ''),
    });
  }

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  const handleYearChange = (e) => {
    setYear(e.target.value);
    parameters.current = { ...parameters.current, year: e.target.value };
    setReload(new Date());
  };

  const handleCompanyChange = (e) => {
    const params = new URLSearchParams(location.search);
    if (e.target.value === '') {
      params.delete(scopes.COMPANY);
    } else {
      params.set(scopes.COMPANY, e.target.value);
    }
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });

    setCompany(e.target.value);
    parameters.current = { ...parameters.current, company: e.target.value };
    setReload(new Date());
  };

  // return an alaska table
  return items ? (
    <>
      <div className="form-horizontal row px-4 px-md-0">
        <div className="col-md-6">
          <YearFormSelect
            year={year}
            years={years}
            handleYearChange={handleYearChange}
          ></YearFormSelect>
        </div>
        <div className="col-md-6">
          <CompanyFormSelect
            company={company}
            companies={companies}
            handleCompanyChange={handleCompanyChange}
          ></CompanyFormSelect>
        </div>
      </div>
      <KwPanel className="mb-4">
        <Table
          className="table-v-stripes"
          columns={columns}
          allColumns={allColumns}
          defaultVisibleColumns={defaultVisibleColumns}
          rows={items.data.map((x) => {
            return { Key: x.skuId, ...x };
          })}
          totalRowCount={items.totalRowCount}
          filteredRowCount={items.filteredRowCount}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          isLoading={isLoading}
          filters={filters}
          filterChange={filterChange}
          exportData={(filters, scopes) =>
            exportRequestsByMonthByWorkflow(year, company, filters, scopes)
          }
          tableActionBarButtons={tableViewSwitcher}
        />
      </KwPanel>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    companies: state.companies,
    currentUser: state.auth,
    history: ownProps.history,
  };
}

const mapDispatchToProps = { getAllCompanies };

RequestsByMonthListByWorkflow.propTypes = {
  companies: PropTypes.array,
  getAllCompanies: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsByMonthListByWorkflow);
