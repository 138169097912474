import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import KwPanel from '../../common/KwPanel';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { useForm } from 'react-hook-form';
import FormSelect from '../../common/FormSelect';
import {
  getAvailableStatuses,
  changeStatuses,
} from '../../../actions/requestActions';
import FormTextArea from '../../common/FormTextArea';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

export default function ChangeStatusBulk({ done, cancel, requests }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [processing, setProcessing] = useState();
  const [statuses, setStatuses] = useState();

  useEffect(() => {
    if (!statuses) {
      getAvailableStatuses()
        .then((d) => {
          setStatuses(d.items.statuses);
        })
        .catch((e) => {
          console.error(e);
          toast.error(t('Statuses not found'));
        });
    }
  }, [statuses]);

  const onSubmit = (data) => {
    setProcessing(true);
    changeStatuses(requests, data.statusId, data.reason)
      .then((d) => {
        toast.success(t('Requests updated'));
        done(d);
        setProcessing(false);
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to update requests'));
        setProcessing(false);
      });
    // const actions = [];
    // for (const i in requests) {
    //   const request = requests[i];
    //   actions.push(changeStatus(request, data.statusId, data.reason));
    // }
    // const results = await Promise.all(actions.map((p) => p.catch((e) => e)));
    // const validResults = results.filter((result) => !(result instanceof Error));
    // setProcessing(false);
    // if (results.length === validResults.length) {
    //   toast.success('Key requests updated');
    //   done();
    // } else if (validResults.length === 0) {
    //   toast.error('Failed to update key requests');
    // } else {
    //   toast.warning('Some of the key requests failed to update');
    // }
  };

  return (
    <SlideoutLayout
      title={t('Change status')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
          isDisabled={processing}
          text={t('Change status')}
        />
      }
    >
      <KwPanel className="mb-4 form-horizontal">
        <FormSelect
          register={register}
          required
          error={errors.statusId}
          errorMessage={t('Status is required')}
          name="statusId"
          label={t('New status')}
          placeholder=""
        >
          <option value="">{t('--- choose ---')}</option>
          {statuses?.map((x) => (
            <option key={x.value} value={x.value}>
              {x.text}
            </option>
          ))}
        </FormSelect>
        <FormTextArea
          register={register}
          required
          error={errors.reason}
          errorMessage={t('Required')}
          name="reason"
          label={t('Please provide a reason...')}
          placeholder=""
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
