import React from 'react';
import { isPowerUser } from '../../auth/authUtils';
import Button from '../../common/Button';
import icons from '../../../constants/icons';
import KebabMenu from '../../common/KebabMenu';
import { CanCopyRequest } from '../../../util/permissions';
import { useTranslation } from 'react-i18next';

function RequestListButtons({
  row,
  handleDownloadKeys,
  handleUploadKeys,
  handleBulkStatusChange,
  handleBulkReassign,
  handleBulkSteamRequest,
  handleCopyRequest,
  handleReturnKeys,
  isProcessing,
  currentUser,
}) {
  const { t } = useTranslation();
  return (
    <KebabMenu
      take={1}
      className="inline-icons"
      buttons={[
        typeof handleDownloadKeys === 'function' && row.allowDownloadKeys && (
          <Button
            isDisabled={isProcessing}
            className="btn btn-default me-2"
            onClick={(e) => handleDownloadKeys(e, row)}
            icon={icons.DOWNLOAD_KEYS}
            text={t('Download keys')}
            title={t('Download keys for this request')}
          ></Button>
        ),
        typeof handleUploadKeys === 'function' && row.allowUploadKeys && (
          <Button
            isDisabled={isProcessing || row.isUpdateInProgress}
            className="btn btn-default me-2"
            onClick={(e) => handleUploadKeys(e, row)}
            icon={icons.UPLOAD_KEYS}
            text={t('Upload keys')}
            title={t('Upload keys to this request')}
          ></Button>
        ),
        typeof handleBulkSteamRequest === 'function' &&
          isPowerUser(currentUser) &&
          row.canOrderKeys && (
            <Button
              isDisabled={isProcessing || row.isUpdateInProgress}
              className="btn btn-default me-2"
              onClick={(e) => handleBulkSteamRequest(e, row)}
              icon={icons.REQUEST_ON_STEAM}
              text={t('Request on Steam')}
              title={t('Send an automated request to Steam')}
            ></Button>
          ),
        typeof handleCopyRequest === 'function' && (
          <Button
            isDisabled={
              !CanCopyRequest({
                companyId: row.companyId,
                requestedById: row.requestedById,
                currentUser,
              })
            }
            className="btn btn-default me-2"
            onClick={(e) => handleCopyRequest(e, row)}
            icon={icons.REQUEST_AGAIN}
            text={t('Request again')}
            title={t('Make a new request based on this request')}
          ></Button>
        ),
        typeof handleBulkStatusChange === 'function' &&
          isPowerUser(currentUser) && (
            <Button
              isDisabled={
                isProcessing || row.isUpdateInProgress || !row.isWaitingForKeys
              }
              className="btn btn-default me-2"
              onClick={(e) => handleBulkStatusChange(e, row)}
              icon={icons.APPROVE}
              text={t('Change status')}
              title={t(
                'Change the status of multiple requests with pending keys'
              )}
            ></Button>
          ),
        typeof handleBulkReassign === 'function' &&
          isPowerUser(currentUser) && (
            <Button
              isDisabled={isProcessing || row.isUpdateInProgress}
              className="btn btn-default me-2"
              onClick={(e) => handleBulkReassign(e, row)}
              icon={icons.REASSIGN}
              text={t('Reassign')}
              title={t('Reassign the request to a user')}
            ></Button>
          ),
        typeof handleReturnKeys === 'function' && row.allowReturnKeys && (
          <Button
            isDisabled={isProcessing || row.isUpdateInProgress}
            className="btn btn-default me-2"
            onClick={(e) => handleReturnKeys(e, row)}
            icon={icons.RETURN_KEYS}
            text={t('Return keys')}
            title={t('Return keys back to key pool')}
          ></Button>
        ),
      ]}
    ></KebabMenu>
  );
}

export default RequestListButtons;
