import React from 'react';
import FormGroup from './FormGroup';
const FormDataList = ({
  name,
  label,
  placeholder,
  defaultValue,
  register,
  required,
  error,
  errorMessage,
  onChange,
  className,
  autoFocus,
  disabled,
  helpContent,
  items,
}) => (
  <FormGroup
    label={label}
    error={error}
    errorMessage={errorMessage}
    required={required}
    helpContent={helpContent}
  >
    <input
      {...register(name, { required })}
      type="text"
      aria-label={label}
      name={name}
      placeholder={placeholder}
      className={`form-control ${className || ''}`}
      defaultValue={defaultValue}
      data-lpignore="true"
      autoComplete="off"
      onChange={onChange}
      autoFocus={autoFocus ?? false}
      disabled={disabled ?? false}
      list={`datalist-${name}`}
    />
    <datalist id={`datalist-${name}`}>
      {items?.map((item, index) => (
        <option key={index}>{item}</option>
      ))}
    </datalist>
  </FormGroup>
);
export default FormDataList;
