import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.LOAD_ANNOUNCEMENTS:
      return action.announcements;
    case actionTypes.UPDATE_ANNOUNCEMENT:
      return state
        ? state.map((announcement) =>
            announcement.id === action.announcement.id
              ? action.announcement
              : announcement
          )
        : state;
    case actionTypes.CREATE_ANNOUNCEMENT:
      return state ? [...state, action.announcement] : state;
    case actionTypes.DELETE_ANNOUNCEMENT:
      return state
        ? state.filter((announcement) => announcement.id !== action.id)
        : state;
    default:
      return state;
  }
}
