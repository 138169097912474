import React, { useState } from 'react';
import { loadUsers } from '../../../actions/companyActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import Gravatar from 'react-gravatar';
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import YesNoLabel from '../../common/YesNoLabel';
import { useTranslation } from 'react-i18next';

function CompanyUserList({ id, location, currentUser, reload }) {
  const allColumns = [
    'fullName',
    'requestActivity',
    'keyActivity',
    'emailAddress',
    'isAuthorised',
    'openRequests',
    'completeRequests',
    'requestsOverSla',
  ];
  const defaultVisibleColumns = allColumns.filter(
    (x) => x !== 'keyActivity' && x !== 'creditLimit'
  );
  const [users, setUsers] = useState();
  const { t } = useTranslation();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'companyuserlist-2.14-settings',
      getTableSettingsDefaults({
        defaultSort: 'fullName',
        defaultVisibleColumns,
      }),
      { id },
      loadUsers,
      setUsers,
      reload,
      t('Failed to load company users'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'fullName',
      Label: t('Name'),
      width: '30%',
      Render: (item) => (
        <div style={{ display: 'flex' }}>
          <Gravatar
            email={item?.username}
            size={20}
            rating="pg"
            default="retro"
            className="img-circle me-3"
          />
          {hasPermission(currentUser, permissionTypes.MANAGE_USERS) ? (
            <Link to={'/user/' + item.id}>{item.fullName}</Link>
          ) : (
            item.fullName
          )}
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestActivity',
      Label: t('Request activity'),
      width: '5%',
      Render: (item) => (
        <Sparklines
          data={[
            item.requestActivityScore30to60Days,
            item.requestActivityScore7to30Days,
            item.requestActivityScoreLast7Days,
          ]}
          min={0}
          max={100}
          height={20}
          width={60}
          style={{
            opacity:
              item.requestActivityScore30to60Days +
                item.requestActivityScore7to30Days +
                item.requestActivityScoreLast7Days >
              0
                ? 1
                : 0.5,
          }}
        >
          <SparklinesCurve color="#46b29d" />
        </Sparklines>
      ),
    },
    {
      Key: 'keyActivity',
      Label: t('Key activity'),
      width: '5%',
      Render: (item) => (
        <Sparklines
          data={[
            item.keyActivityScore30to60Days,
            item.keyActivityScore7to30Days,
            item.keyActivityScoreLast7Days,
          ]}
          min={0}
          max={100}
          height={20}
          width={60}
          style={{
            opacity:
              item.keyActivityScore30to60Days +
                item.keyActivityScore7to30Days +
                item.keyActivityScoreLast7Days >
              0
                ? 1
                : 0.5,
          }}
        >
          <SparklinesCurve color="#bb5ebf" />
        </Sparklines>
      ),
    },
    {
      Key: 'emailAddress',
      Label: t('Email'),
      width: '30%',
      Render: (item) => item.emailAddress,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'isAuthorised',
      Label: t('Authorised'),
      width: '10%',
      Render: (item) => <YesNoLabel value={item.isAuthorised} />,
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('--- any ---')}</option>
            <option value="true">{t('Yes - available to external')}</option>
            <option value="false">{t('No - not available to external')}</option>
          </select>
        ),
      },
    },
    {
      Key: 'openRequests',
      Label: t('Open requests'),
      width: '10%',
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.openRequests),
    },
    {
      Key: 'completeRequests',
      Label: t('Complete requests'),
      width: '10%',
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.completeRequests),
    },
    {
      Key: 'requestsOverSla',
      Label: t('Requests over SLA'),
      width: '10%',
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.requestsOverSla),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return users ? (
    <KwPanel className="mb-4">
      <header className="mb-2">
        <h2 className="no-margin">{t('Users')}</h2>
      </header>
      <Table
        columns={columns}
        allColumns={allColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        rows={users.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={users.totalRowCount}
        filteredRowCount={users.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filterChange={filterChange}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default CompanyUserList;
