import actionTypes from '../constants/actionTypes';

export function addToGroup(groupName) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ADD_TO_GROUP,
      groupName,
    });
  };
}

export function removeFromGroup(groupName) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.REMOVE_FROM_GROUP,
      groupName,
    });
  };
}

export function getConnectedUsers() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.GET_CONNECTED_USERS,
    });
  };
}

export function clearConnectedUsers() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CLEAR_CONNECTED_USERS,
    });
  };
}

export function pageActive() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.PAGE_ACTIVE,
    });
  };
}

export function pageInactive() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.PAGE_INACTIVE,
    });
  };
}
