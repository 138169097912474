import React, { useState } from 'react';
import { connect } from 'react-redux';

import { clearConnectedUsers } from '../../actions/signalRActions';
import SlideoutLayout from './slideout/SlideoutLayout';
import KwPanel from '../common/KwPanel';
import FormStatic from '../common/FormStatic';
import FormGroup from '../common/FormGroup';
import Search from '../settings/users/components/Search';
import { impersonate, impersonationFailed } from '../../services/userService';
import { toast } from 'react-toastify';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

function Impersonate({ currentUser, cancel, done, clearConnectedUsers }) {
  const { t } = useTranslation();
  const [user, setUser] = useState();

  const handleImpersonate = () => {
    if (!user) {
      toast.error(t('Failed to impersonate user'));
      return;
    }

    impersonate(user.id)
      .then(() => {
        clearConnectedUsers();
        window.location.assign('/');
      })
      .catch((e) => {
        console.error(e);
        toast.error(t('Failed to impersonate user'));
      });
  };
  const handleStopImpersonate = () => {
    impersonate()
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        impersonationFailed();
      });
  };

  const handleUserSearchChange = (d) => {
    if (d && d.length > 0) {
      setUser(d[0]);
    } else {
      setUser();
    }
  };

  return (
    <SlideoutLayout
      title={t('Impersonate')}
      cancel={cancel}
      done={done}
      bar={
        <>
          <Button
            className="btn btn-primary"
            isDisabled={!user}
            onClick={handleImpersonate}
            text={t('Impersonate')}
          />
        </>
      }
    >
      <KwPanel className="mb-4">
        <FormGroup label={t('User to impersonate')}>
          <Search onChange={handleUserSearchChange}></Search>
        </FormGroup>
      </KwPanel>
      {currentUser?.isImpersonating && (
        <KwPanel className="mb-4">
          <header>
            <h2>{t('You are currently impersonating..')}</h2>
            <Button
              className="btn btn-default"
              onClick={handleStopImpersonate}
              text={t('Stop')}
            />
          </header>
          <FormStatic label={t('User')}>{currentUser?.username}</FormStatic>
        </KwPanel>
      )}
    </SlideoutLayout>
  );
}
function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = { clearConnectedUsers };

export default connect(mapStateToProps, mapDispatchToProps)(Impersonate);
