import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TagRow({
  game,
  style,
  tagsAsFreeText,
  requesterTags,
  changeTag,
  gameTagOnBlur,
}) {
  const { t } = useTranslation();
  return (
    <div className="grid-empty hidden-input" style={style}>
      {tagsAsFreeText ? (
        <input
          type="text"
          className={`form-control ${!game.isSKU ? 'apply' : ''}`}
          value={game.tag || ''}
          placeholder={!game.isSKU ? t('Apply to skus') : ''}
          onChange={(e) => changeTag(e.target.value, game)}
          onBlur={() => {
            if (!game.isSKU) {
              gameTagOnBlur(game);
            }
          }}
        ></input>
      ) : (
        <select
          className={`form-control ${!game.isSKU ? 'apply' : ''}`}
          onChange={(e) => {
            changeTag(e.target.value, game);
          }}
          value={game.tag || ''}
          disabled={game.showRemove}
        >
          {!game.isSKU ? (
            <option value="">{t('Apply to skus')}</option>
          ) : (
            <option value=""></option>
          )}
          {requesterTags &&
            [...requesterTags]
              ?.sort((a, b) => a.tag.localeCompare(b.tag))
              ?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.tag}
                </option>
              ))}
        </select>
      )}
    </div>
  );
}

TagRow.propTypes = {
  game: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  tagsAsFreeText: PropTypes.bool.isRequired,
  requesterTags: PropTypes.array.isRequired,
  changeTag: PropTypes.func.isRequired,
  gameTagOnBlur: PropTypes.func.isRequired,
};

export default TagRow;
