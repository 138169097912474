import actionTypes from '../constants/actionTypes';
import * as steamRequestApi from '../api/steamRequestApi';

export function saveRequest(record) {
  return steamRequestApi.saveRequest(record);
}

export function clearSteamRequest() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CLEAR_STEAMREQUEST,
    });
  };
}
