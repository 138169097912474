import React from 'react';
import FormInput from '../../common/FormInput';
import FormStatic from '../../common/FormStatic';
import FormTextArea from '../../common/FormTextArea';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

const EditRequestValueForm = ({ request, register, errors }) => {
  const { t } = useTranslation('requests');
  return (
    <>
      <FormStatic label="Current request value">
        {request.currentRequestValue}
      </FormStatic>
      <FormInput
        register={register}
        required={true}
        pattern={/^[0-9,.]+$/i}
        error={errors.newRequestValue}
        errorMessage={t('Number required')}
        name="newRequestValue"
        label={t('New request value')}
        className="num"
        defaultValue={request.currentRequestValue || 0}
        autoFocus={true}
      />
      <FormTextArea
        register={register}
        required={true}
        error={errors.reason}
        errorMessage={t('Required')}
        name="reason"
        label={t('Please provide a reason...')}
        placeholder=""
      />
    </>
  );
};

EditRequestValueForm.propTypes = {
  request: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default EditRequestValueForm;
