import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  changeStatus,
  getAvailableStatusesForRequest,
} from '../../../actions/requestActions';
import KwPanel from '../../common/KwPanel';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { useForm } from 'react-hook-form';
import ChangeStatusForm from '../components/ChangeStatusForm';
import Button from '../../common/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ChangeStatus({ done, cancel, request, history }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = useForm();
  const [processing, setProcessing] = useState();
  const { t } = useTranslation();

  const [statuses, setStatuses] = useState();
  useEffect(() => {
    getAvailableStatusesForRequest(request.id)
      .then((d) => {
        setStatuses(d.items.statuses);
      })
      .catch((e) => {
        console.error(e);
        toast.error(t('Statuses not found'));
      });
  }, [history, request.id]);

  const onSubmit = (data) => {
    setProcessing(true);
    changeStatus(request.id, data.statusId, data.reason)
      .then((d) => {
        toast.success(t('Request updated'));
        done(d);
        setProcessing(false);
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to update request'));
        setProcessing(false);
      });
  };

  return (
    <SlideoutLayout
      title={t('Change status')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
          isDisabled={processing}
          title={processing ? t('Processing') : t('Change status')}
          text={t('Change status')}
        />
      }
    >
      <KwPanel className="mb-4 form-horizontal">
        {statuses && (
          <ChangeStatusForm
            request={request}
            statuses={statuses}
            register={register}
            errors={errors}
            control={control}
            getValues={getValues}
          ></ChangeStatusForm>
        )}
      </KwPanel>
    </SlideoutLayout>
  );
}

ChangeStatus.propTypes = {
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ChangeStatus;
