import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.LOAD_COMPANYTYPES:
      return action.companyTypes;
    case actionTypes.UPDATE_COMPANYTYPE:
      return state
        ? state.map((companyType) =>
            companyType.id === action.companyType.id
              ? action.companyType
              : companyType
          )
        : state;
    case actionTypes.CREATE_COMPANYTYPE:
      if (state.find((x) => x.id === action.companyType.id)) {
        // update
        return state
          ? state.map((companyType) =>
              companyType.id === action.companyType.id
                ? action.companyType
                : companyType
            )
          : state;
      } else {
        // create
        return state ? [...state, action.companyType] : state;
      }
    case actionTypes.DELETE_COMPANYTYPE:
      return state
        ? state.filter((companyType) => companyType.id !== action.id)
        : state;
    default:
      return state;
  }
}
