import * as emailApi from '../api/emailApi';

export function getEmailById(id) {
  return emailApi.getEmailById(id);
}

export function loadEmails({ filters, _scopes, _sort, _order, _page, _limit }) {
  return emailApi.getEmails(filters, _scopes, _sort, _order, _page, _limit);
}

export function exportEmailData(filters, scopes) {
  return emailApi.exportEmailData(filters, scopes);
}

export function resendEmail(id) {
  return emailApi.resendEmail(id);
}
