import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReportedDisabledList from './components/ReportedDisabledList';
import ActionBar from '../layout/ActionBar';
import UploadDisabled from './slideout/UploadDisabled';
import { addToGroup, removeFromGroup } from '../../actions/signalRActions';
import SlidingPane from 'react-sliding-pane';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

function ReportedDisabled({
  location,
  disabledTransactions,
  addToGroup,
  removeFromGroup,
}) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [uploadKeysSlideoutOpen, setUploadKeysSlideoutOpen] = useState(false);
  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={() => setUploadKeysSlideoutOpen(true)}
          text={t('Upload disabled keys')}
        />
      </ActionBar>
      <ReportedDisabledList
        location={location}
        reload={reload}
        addToGroup={addToGroup}
        removeFromGroup={removeFromGroup}
        disabledTransactions={disabledTransactions}
      />
      <SlidingPane
        isOpen={uploadKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => {
          setUploadKeysSlideoutOpen(false);
          setReload(new Date());
        }}
      >
        <UploadDisabled
          done={() => {
            setUploadKeysSlideoutOpen(false);
            setReload(new Date());
          }}
          cancel={() => setUploadKeysSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    disabledTransactions: state.disabledTransactions,
  };
}

const mapDispatchToProps = { addToGroup, removeFromGroup };

export default connect(mapStateToProps, mapDispatchToProps)(ReportedDisabled);
