import React from 'react';
import LeftNavWrapper from '../menu-components/LeftNavWrapper';
import LeftMenuItem from '../menu-components/LeftMenuItem';
import AddCompany from '../../company/slideout/AddCompany';
import permissionTypes from '../../../constants/permissionTypes';
import SlidingPane from 'react-sliding-pane';
import { useState } from 'react';
import icons from '../../../constants/icons';
import NavColours from '../../../constants/NavColours';
import { scopes as tableScopes } from '../../../constants/scopes';
import { useTranslation } from 'react-i18next';

function LeftMenu_Company({ currentUser, wrapperClassName, companyTypes }) {
  const [addCompanySlideoutOpen, setAddCompanySlideoutOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <LeftNavWrapper className={wrapperClassName}>
        <LeftMenuItem
          url="/companies/"
          activeRegex="\/(company|companies)?\/(?!\?)"
          icon={icons.COMPANY}
          colour={NavColours.NAV_COLOUR_1}
          title={t('All companies')}
          currentUser={currentUser}
        />
        {companyTypes && <hr />}
        {companyTypes &&
          [...companyTypes]
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map((x) => (
              <LeftMenuItem
                key={x.id}
                url={`/companies/?f_companyTypeId=${x.id}`}
                icon={icons.COMPANY}
                colour={NavColours.NAV_COLOUR_2}
                title={x.name}
                currentUser={currentUser}
                allowedPermissions={[permissionTypes.MANAGE_COMPANIES]}
              />
            ))}
        <hr />
        <LeftMenuItem
          url="/company"
          activeRegex="^\/company$"
          icon={icons.ADD}
          colour={NavColours.NAV_COLOUR_3}
          title={t('Add company')}
          onClick={() => setAddCompanySlideoutOpen(true)}
          currentUser={currentUser}
          allowedPermissions={[permissionTypes.MANAGE_COMPANIES]}
        />
        <LeftMenuItem
          url={`/companies?${tableScopes.ISARCHIVED}=true`}
          icon={icons.DELETE}
          colour={NavColours.NAV_COLOUR_3}
          title={t('Archived companies')}
          currentUser={currentUser}
          allowedPermissions={[permissionTypes.MANAGE_USERS]}
        />
      </LeftNavWrapper>
      <SlidingPane
        isOpen={addCompanySlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddCompanySlideoutOpen(false)}
      >
        <AddCompany
          done={() => setAddCompanySlideoutOpen(false)}
          cancel={() => setAddCompanySlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

export default LeftMenu_Company;
