import {
  apiUrl,
  getList,
  deleteRecord,
  getSingleRecord,
  saveRecord,
  getStatsData,
  getAll,
  postAction,
  download,
  upload,
  getAuditData,
  exportData,
  get,
} from './apiUtils';
const baseUrl = apiUrl + '/games/';

export function getGames(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getSkus(
  gameId,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}${gameId}/skus`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getGameById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveGame(game) {
  return saveRecord(baseUrl, game);
}

export function archiveGame(id, lockSignOff) {
  return deleteRecord(baseUrl, id, { lockSignOff });
}

export function archiveGameSku(gameId, skuId, lockSignOff) {
  return deleteRecord(`${baseUrl}${gameId}/sku/`, skuId, { lockSignOff });
}

export function getStats(id) {
  return getStatsData(baseUrl, id);
}

export function getStatsByDay(id) {
  return get(`${baseUrl}${id}/statsByDay/`);
}

export function getAggregateStats(id, from, to) {
  // Format the from as YYYY-MM-DD
  from = from.toISOString().split('T')[0];
  // Format the to as YYYY-MM-DD
  to = to.toISOString().split('T')[0];

  return get(`${baseUrl}${id}/aggregateStats?fromDate=${from}&toDate=${to}`);
}

export function getAudit(id) {
  return getAuditData(baseUrl, id);
}

export function addAudit(id, comment) {
  return postAction(`${baseUrl}${id}/audit`, { comment });
}

export function getAllGames() {
  return getAll(baseUrl);
}

export function cloneGameSku(gameId, skuId, skuName, withRestrictions) {
  return postAction(`${baseUrl}${gameId}/sku/${skuId}/clone`, {
    skuName,
    withRestrictions,
  });
}

export function downloadGames() {
  return download(`${baseUrl}download`);
}
export function downloadTemplate() {
  return download(`${baseUrl}download/template`);
}
export function uploadGames(record) {
  return upload(`${baseUrl}upload`, record);
}

export function exportGameListData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/games/search`,
    'gameName',
    filters,
    scopes
  );
}
