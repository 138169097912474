import React, { useState } from 'react';
import { connect } from 'react-redux';
import KeyProvidersList from './components/KeyProvidersList';
import ActionBar from '../../layout/ActionBar';
import Add from './slideout/Add';
import SlidingPane from 'react-sliding-pane';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function KeyProviders({ location }) {
  const { t } = useTranslation();
  const [reload, setReload] = useState(false);
  const [addKeyProviderSlideoutOpen, setAddKeyProviderSlideoutOpen] =
    useState(false);

  return (
    <>
      <ActionBar>
        <Button
          className="btn btn-primary"
          onClick={() => setAddKeyProviderSlideoutOpen(true)}
          text={t('Add key provider')}
        />
      </ActionBar>
      <KeyProvidersList key={reload} location={location} reload={reload} />

      <SlidingPane
        isOpen={addKeyProviderSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setAddKeyProviderSlideoutOpen(false)}
      >
        <Add
          done={(d) => {
            setReload(new Date());
            setAddKeyProviderSlideoutOpen(false);
          }}
          cancel={() => setAddKeyProviderSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(KeyProviders);
