const WizardSteps = Object.freeze({
  COMPANY: 'company',
  KEY_WORKFLOW: 'keyWorkflow',
  GAME: 'game',
  SKU: 'sku',
  TAGS_FREE_TEXT: 'tagsFreeText',
  TAGS: 'tags',
  CONFIRM: 'confirm',
});

export default WizardSteps;
