import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import NavMenu from './layout/NavMenu';
import LeftMenu from './layout/LeftMenu';
import { toggleNavMenu, closeNavMenu } from '../actions/layoutActions';
import { getBasket } from '../actions/requestActions';
import OffCanvasMenu from './layout/OffCanvasMenu';
import { getAllCompanyTypes } from '../actions/companyTypeActions';
import { getAllCompanies } from '../actions/companyActions';
import { getKeyProviders } from '../actions/keyProviderActions';
import { getPlatforms } from '../actions/platformActions';
import { hasAnyPermission } from './auth/authUtils';
import permissionTypes from '../constants/permissionTypes';
import i18n from 'i18next';

import SlidingPane from 'react-sliding-pane';
import Complete from './requests/slideout/Complete';
import { getAllAnnouncements } from '../actions/announcementActions';
import AnnouncementModal from './layout/AnnouncementModal';
import { useTranslation } from 'react-i18next';

function Layout({
  currentUser,
  children,
  toggleNavMenu,
  closeNavMenu,
  navMenuOpen,
  basket,
  getBasket,
  companyTypes,
  getAllCompanyTypes,
  classes,
  completeRequestSlideoutOpen,
  setCompleteRequestSlideoutOpen,
  companies,
  getAllCompanies,
  keyProviders,
  getKeyProviders,
  platforms,
  getPlatforms,
  announcements,
  getAllAnnouncements,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!basket && currentUser && !currentUser.isLoading) {
      getBasket().catch(() => {
        console.error(t('Failed to get basket'));
      });
    }
  }, [getBasket, basket, currentUser, t]);

  //company types
  useEffect(() => {
    if (hasAnyPermission(currentUser, [permissionTypes.MANAGE_COMPANIES])) {
      if (!companyTypes && currentUser && !currentUser.isLoading) {
        getAllCompanyTypes().catch((e) => {
          toast.error(t('Failed to get company types'));
        });
      }
    }
  }, [companyTypes, getAllCompanyTypes, currentUser, t]);

  useEffect(() => {
    if (currentUser) {
      i18n.changeLanguage(currentUser.language);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!companies) {
      getAllCompanies().catch(() => {});
    }
  }, [companies, getAllCompanies]);

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders().catch(() => {});
    }
  }, [keyProviders, getKeyProviders]);

  useEffect(() => {
    if (!platforms) {
      getPlatforms().catch(() => {});
    }
  }, [platforms, getPlatforms]);
  const actionBarOpen =
    document.getElementById('layout-action-bar')?.children?.length > 0;

  useEffect(() => {
    if (!announcements) {
      getAllAnnouncements().catch(() => {});
    }
  }, [announcements, getAllAnnouncements]);

  return (
    <div
      id="page"
      className={`page ${navMenuOpen ? 'off-canvas-open' : ''} ${
        actionBarOpen ? 'action-bar-open' : ''
      }`}
    >
      <header id="header">
        <NavMenu
          currentUser={currentUser}
          basket={basket}
          companyTypes={companyTypes}
          toggleNavMenu={toggleNavMenu}
          setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
        />
      </header>
      <LeftMenu currentUser={currentUser} companyTypes={companyTypes} />
      <main className="page__region page__region--main">
        <div className="content-header">
          <div className="action-bar text-right" id="layout-action-bar"></div>
        </div>
        <div
          className={`container-fluid container-xs-flush d-flex flex-column pt-4 ${classes}`}
        >
          {children}
        </div>
      </main>
      <div className={`panel-overlay`}></div>
      <OffCanvasMenu
        currentUser={currentUser}
        companyTypes={companyTypes}
        basket={basket}
        closeNavMenu={closeNavMenu}
        setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
      />
      <SlidingPane
        isOpen={completeRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="xlarge-side-panel"
        onRequestClose={() => setCompleteRequestSlideoutOpen(false)}
      >
        <Complete
          done={() => setCompleteRequestSlideoutOpen(false)}
          cancel={() => setCompleteRequestSlideoutOpen(false)}
        />
      </SlidingPane>
      {announcements && <AnnouncementModal announcements={announcements} />}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    navMenuOpen: state.navMenuOpen,
    basket: state.basket,
    companyTypes: state.companyTypes,
    companies: state.companies,
    keyProviders: state.keyProviders,
    platforms: state.platforms,
    announcements: state.announcements,
  };
}

const mapDispatchToProps = {
  toggleNavMenu,
  closeNavMenu,
  getBasket,
  getAllCompanyTypes,
  getAllCompanies,
  getKeyProviders,
  getPlatforms,
  getAllAnnouncements,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
