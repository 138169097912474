import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { useLocalStorage } from './useLocalStorage';
import useLoader from './useLoader';
import { toast } from 'react-toastify';
import { handleError } from '../actions/actionUtils';

const useTableSettings = (
  key,
  defaults,
  parameters,
  loadList,
  setList,
  reload,
  errorMessage,
  location,
  defaultScope
) => {
  const [isInit, setIsInit] = useState(true);
  const [isLoading, startLoader, clearLoader] = useLoader();
  const [tableSettings, setTableSettings] = useLocalStorage(key, defaults);
  useEffect(() => {
    if (isInit) {
      const getUrlFilters = () => {
        const params = new URLSearchParams(location.search);
        let filters = {};
        for (let key of params.keys()) {
          if (key.startsWith('f_')) {
            filters = {
              ...filters,
              [key.substring(2)]: params.get(key),
            };
          }
        }
        return filters;
      };
      setTableSettings({ ...tableSettings, filters: getUrlFilters() });
      setIsInit(false);
    }
  }, [isInit, tableSettings, setTableSettings, location.search]);

  const refParameters = useRef(parameters);
  const checkPageResult = useCallback(
    (pageNumber) => {
      pageNumber--;
      if (pageNumber >= 0 && pageNumber !== tableSettings.pageNumber) {
        setTableSettings({
          ...tableSettings,
          pageNumber: pageNumber,
        });
      }
    },
    [tableSettings, setTableSettings]
  );
  const history = useHistory();

  let filterChange = useCallback(
    (key, value) => {
      const currentValue = tableSettings.filters
        ? tableSettings.filters[key]
        : '';
      if (currentValue !== value) {
        // set the search param in the url?
        const params = new URLSearchParams(location.search);
        if (value === '' || value === null) {
          params.delete(`f_${[key]}`);
        } else {
          params.set(`f_${[key]}`, value);
        }
        history.replace({
          pathname: location.pathname,
          search: params.toString(),
        });
        setTableSettings({
          ...tableSettings,
          filters: { ...tableSettings.filters, [key]: value },
          pageNumber: 0,
        });
      }
    },
    [
      tableSettings,
      setTableSettings,
      location.search,
      history,
      location.pathname,
    ]
  );

  let filtersChange = useCallback(
    (arr) => {
      const params = new URLSearchParams(location.search);
      arr.map(({ key, value }) => {
        const currentValue = tableSettings.filters
          ? tableSettings.filters[key]
          : '';
        if (currentValue !== value) {
          // set the search param in the url?
          if (value === '' || value === null) {
            params.delete(`f_${[key]}`);
          } else {
            params.set(`f_${[key]}`, value);
          }
        }
        setTableSettings({
          ...tableSettings,
          filters: { ...tableSettings.filters, [key]: value },
          pageNumber: 0,
        });
      });
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [
      tableSettings,
      setTableSettings,
      location.search,
      history,
      location.pathname,
    ]
  );

  const getFilters = (prefix) => {
    const params = new URLSearchParams(location.search);
    let filters = {};
    for (let key of params.keys()) {
      if (key.startsWith(prefix)) {
        filters = {
          ...filters,
          [key.substring(2)]: params.get(key),
        };
      }
    }
    return filters;
  };

  useEffect(() => {
    if (!isInit) {
      startLoader();
      loadList({
        ...{
          filters: getFilters('f_'),
          _scopes: defaultScope || getFilters('s_'),
          _sort: tableSettings.sortedField.key,
          _order: tableSettings.sortedField.direction,
          _page: tableSettings.pageNumber,
          _limit: tableSettings.pageSize,
        },
        ...refParameters.current,
      })
        .then((d) => {
          setList(d);
          checkPageResult(d.page);
        })
        .catch((e) => handleError(e, history, () => toast.error(errorMessage)))
        .finally(clearLoader);
    }
  }, [
    isInit,
    startLoader,
    clearLoader,
    reload,
    tableSettings.scopes,
    tableSettings.sortedField,
    tableSettings.pageNumber,
    tableSettings.pageSize,
    // tableSettings.filters,
    checkPageResult,
    loadList,
    setList,
    errorMessage,
    location.search,
    history,
    defaultScope,
  ]);

  return {
    tableSettings,
    setTableSettings,
    filterChange,
    isLoading,
    parameters: refParameters,
    filtersChange,
    filters: getFilters('f_'),
  };
};
export default useTableSettings;
