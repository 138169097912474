import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import CompanyPopup from '../../popups/company';
import ImageFromApi from '../ImageFromApi';
import icons from '../../../constants/icons';
function CompanyLink({
  id,
  name,
  currentUser,
  fallbackText,
  imageId,
  imageSize,
  companies,
  hideImage,
  colourHex,
  onClick,
}) {
  const [image, setImage] = useState(imageId);
  useEffect(() => {
    if (!imageId && companies) {
      setImage(companies.find((x) => x.id === id)?.imageId);
    }
  }, [imageId, companies, id]);

  const [colour, setColour] = useState(colourHex);
  useEffect(() => {
    if (!colourHex && companies) {
      setColour(companies.find((x) => x.id === id)?.colourHex);
    }
  }, [colourHex, companies, id]);

  const handleOnClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <div className="d-flex align-items-center">
      {!hideImage && (name || id) && (
        <ImageFromApi
          imageId={image}
          imageSize={imageSize}
          fallbackIcon={icons.COMPANY}
          colourHex={colour}
        ></ImageFromApi>
      )}
      {id && name ? (
        hasPermission(currentUser, permissionTypes.MANAGE_COMPANIES) ? (
          <CompanyPopup id={id}>
            <NavLink to={`/company/${id}`} onClick={handleOnClick}>
              {name}
            </NavLink>
          </CompanyPopup>
        ) : (
          name
        )
      ) : name ? (
        name
      ) : (
        fallbackText || '-'
      )}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    companies: state.companies,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLink);
