import React from 'react';
import ActionBar from '../layout/ActionBar';
import SLAsByCompaniesList from './components/SLAsByCompaniesList';
function SLAsByCompanies({ location }) {
  return (
    <>
      <ActionBar></ActionBar>
      <SLAsByCompaniesList location={location} />
    </>
  );
}

export default SLAsByCompanies;
