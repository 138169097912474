import React, { useState } from 'react';
import {
  loadKeyPoolNotifications,
  exportKeyPoolNotificationData,
} from '../../../../actions/keyPoolNotificationActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { CommaNumber_NoDecimal } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import KeyProviderLink from '../../../common/links/KeyProviderLink';
import PlatformLink from '../../../common/links/PlatformLink';
import GameLink from '../../../common/links/GameLink';
import WorkflowLink from '../../../common/links/WorkflowLink';
import { useTranslation } from 'react-i18next';

function KeyPoolNotificationList({ location, reload, currentUser }) {
  const { t } = useTranslation();
  const allColumns = [
    'lowerLimit',
    'sku',
    'keyWorkflowName',
    'keyProviderName',
    'platformName',
  ];
  const defaultVisibleColumns = allColumns;
  const [keyPoolNotifications, setKeyPoolNotifications] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'keypoolnotificationlist-settings',
      getTableSettingsDefaults({
        defaultSort: 'sku',
        defaultVisibleColumns,
      }),
      null,
      loadKeyPoolNotifications,
      setKeyPoolNotifications,
      reload,
      t('Failed to load key pool notifications'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'lowerLimit',
      Label: t('Keys (lower limit)'),
      width: '12%',
      className: 'text-right num no-wrap',
      Render: (item) => (
        <Link to={'/settings/key-pool-notification/' + item.id}>
          {CommaNumber_NoDecimal(item.lowerLimit)}
        </Link>
      ),
    },
    {
      Key: 'sku',
      Label: t('SKU'),
      width: '22%',
      Render: (item) =>
        item.skuId ? (
          <>
            <GameLink
              gameId={item.gameId}
              skuId={item.skuId}
              gameTitle={item.gameTitle}
              skuName={item.sku}
              key={item.skuId}
              currentUser={currentUser}
            />
          </>
        ) : (
          '-'
        ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyWorkflowName',
      Label: t('Key workflow'),
      width: '22%',
      Render: (item) => (
        <WorkflowLink
          id={item.keyWorkflowId}
          key={item.keyWorkflowId}
          name={item.keyWorkflowName}
          colour={item.keyWorkflowColourHex}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyProviderName',
      Label: t('Key provider'),
      width: '22%',
      Render: (item) => (
        <KeyProviderLink id={item.keyProviderId} name={item.keyProviderName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'platformName',
      Label: t('Platform'),
      width: '22%',
      Render: (item) => (
        <PlatformLink id={item.platformId} name={item.platformName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return keyPoolNotifications ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={keyPoolNotifications.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={keyPoolNotifications.totalRowCount}
        filteredRowCount={keyPoolNotifications.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportKeyPoolNotificationData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default KeyPoolNotificationList;
