import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';
import icons from '../../../constants/icons';

function Basket({ basket, setCompleteRequestSlideoutOpen }) {
  return (
    basket && (
      <>
        <button onClick={() => setCompleteRequestSlideoutOpen(true)}>
          <FontAwesomeIcon icon={icons.BASKET} />
          {basket.basketCount > 0 && <span>{basket.basketCount}</span>}
        </button>
      </>
    )
  );
}

function mapStateToProps(state, ownProps) {
  return {
    basket: ownProps.basket,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Basket);
