import React from 'react';
import { useHistory } from 'react-router';
import { ToCamelCase, Truncate } from '../../util/formatter';
import icons from '../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TableScopes({ scopes, location }) {
  const { t } = useTranslation();
  const history = useHistory();
  const handleRemoveScope = (scope) => {
    if (location) {
      const params = new URLSearchParams(location.search);
      params.delete(`s_${[ToCamelCase(scope.scope)]}`);
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    } else {
      console.error('Location not supplied to table');
    }
  };

  const scopeButtonClassName = (scope) => {
    switch (scope.scope) {
      case 'Game':
      case 'Sku':
      case 'KeyProvider':
      case 'PackageType':
      case 'Platform':
      case 'KeyWorkflow':
      case 'Company':
      case 'User':
        return 'ps-3';
      default:
        return '';
    }
  };

  const scopeDisplayName = (scope) => {
    scope.name = Truncate(scope.name, 150);
    switch (scope.scope) {
      case 'Game':
        return (
          <>
            <span className="icon-wrapper me-3 text-aqua">
              <FontAwesomeIcon icon={icons.GAME} />
            </span>
            {scope.name}
          </>
        );
      case 'Sku':
        return (
          <>
            <span className="icon-wrapper me-3 text-aqua">
              <FontAwesomeIcon icon={icons.SKU} />
            </span>
            {scope.name}
          </>
        );
      case 'KeyProvider':
        return (
          <>
            <span className="icon-wrapper me-3 text-blue">
              <FontAwesomeIcon icon={icons.KEY_PROVIDER} />
            </span>
            {scope.name}
          </>
        );
      case 'PackageType':
        return (
          <>
            <span className="icon-wrapper me-3 text-blue">
              <FontAwesomeIcon icon={icons.PACKAGE_TYPE} />
            </span>
            {scope.name}
          </>
        );
      case 'Platform':
        return (
          <>
            <span className="icon-wrapper me-3 text-blue">
              <FontAwesomeIcon icon={icons.PLATFORM} />
            </span>
            {scope.name}
          </>
        );
      case 'KeyWorkflow':
        return (
          <>
            <span className="icon-wrapper me-3 text-blue">
              <FontAwesomeIcon icon={icons.WORKFLOW} />
            </span>
            {scope.name}
          </>
        );
      case 'Company':
        return (
          <>
            <span className="icon-wrapper me-3 text-blue">
              <FontAwesomeIcon icon={icons.COMPANY} />
            </span>
            {scope.name}
          </>
        );
      case 'User':
        return (
          <>
            <span className="icon-wrapper me-3 text-blue">
              <FontAwesomeIcon icon={icons.USER} />
            </span>
            {scope.name}
          </>
        );
      default:
        return scope.name;
    }
  };

  return scopes.map((x) => (
    <button
      className={`btn btn-default btn-outline btn-filter me-2 ${scopeButtonClassName(
        x
      )}`}
      key={x.name}
      onClick={() => handleRemoveScope(x)}
      title={t('Remove filter')}
    >
      {scopeDisplayName(x)}
      <span className="icon-wrapper ms-3">
        <FontAwesomeIcon icon={icons.DELETE_TAG} />
      </span>
    </button>
  ));
}

TableScopes.propTypes = {
  scopes: PropTypes.array,
  location: PropTypes.object,
};

export default TableScopes;
