import * as PIXI from 'pixi.js';

import image_sprite_w from '../../content/images/sprites/w.png';

class Particle {
  constructor(app, container, options) {
    this.app = app;
    this.container = container;
    this.destroy = false;
    this.a = [0.5, 1, 1.5][Math.round(Math.random() * 2)];
    this.steps = app.renderer.width / 5;
    this.scale = 0.5 * Math.random();
    this.rotation = [0.02, 0.04, 0.06, 0.08][Math.round(Math.random() * 3)];
    this.siner = 300 * Math.random();
    this.speed = options.speed;
    this.progress = 0;

    this.texture = new PIXI.Sprite(
      app.loader.resources['user_particle'].texture
    );

    let n = [
      //https://coolors.co/f9c80e-f86624-ea3546-662e9b-43bccd
      [0xf9c80e, 0xf86624, 0xea3546, 0x662e9b, 0x43bccd],

      //https://coolors.co/3c1642-086375-1dd3b0-affc41-b2ff9e
      [0x3c1642, 0x086375, 0x1dd3b0, 0xaffc41, 0xb2ff9e],

      //https://coolors.co/f72585-7209b7-3a0ca3-4361ee-4cc9f0
      [0xf72585, 0x7209b7, 0x3a0ca3, 0x4361ee, 0x4cc9f0],

      //https://coolors.co/ef476f-ffd166-06d6a0-118ab2-073b4c
      [0xef476f, 0xffd166, 0x06d6a0, 0x118ab2, 0x073b4c],
    ];
    this.texture.tint =
      n[options.colourSet][Math.round(Math.random() * (n.length - 1))];
    this.texture.cacheAsBitmap = true;
    this.texture.anchor.set(0.5);

    this.behaviours();
    this.render();
    this.move();
  }

  behaviours() {
    window.onresize += () => {
      this.destroy = true;
    };
  }

  render() {
    this.container.addChild(this.texture);
  }

  move() {
    let x = this.progress;
    let y =
      this.app.renderer.height / 6 +
      this.siner +
      this.a * Math.sin(this.progress / this.steps) * 30;

    this.texture.x = -50 + x;
    this.texture.y = y;
    this.texture.scale.x = this.scale;
    this.texture.scale.y = this.scale;
    this.texture.rotation += this.rotation;
    this.progress = this.progress + this.speed;

    //Destroy
    if (x > this.app.renderer.width + 100 || this.destroy === true) {
      this.container.removeChild(this.texture);
      return false;
    } else {
      return true;
    }
  }

  radians(degrees) {
    return (degrees * Math.PI) / 180;
  }
}
function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

class Icosaedro {
  constructor(el, colourSet) {
    this.el = el;
    this.colourSet = colourSet === undefined ? 0 : colourSet;
    this.progress = 0;
    this.container = null;
    this.sprite = null;
    this.app = null;
    this.bunny = null;
    this.interval = null;
    this.particles = [];
    this.initSuccess = false;
    this.init();
  }

  init() {
    clearInterval(this.interval);
    this.particles = [];

    if (this.el) {
      this.app = new PIXI.Application({
        width: this.el.offsetWidth,
        height: this.el.offsetHeight,
        antialias: true,
        transparent: true,
      });

      let amount = this.app.renderer instanceof PIXI.Renderer ? 100 : 5;
      if (amount === 5) {
        this.app.renderer.context.mozImageSmoothingEnabled = false;
        this.app.renderer.context.webkitImageSmoothingEnabled = false;
      }

      this.el.insertBefore(this.app.view, this.el.firstChild);

      const container = new PIXI.Container();
      let th = this;
      if (this.app.loader.resources['user_particle'] === undefined) {
        this.app.loader.add('user_particle', image_sprite_w).load(setup);
      } else {
        setup();
      }

      function setup() {
        th.app.stage.addChild(container);
        th.app.view.style['transform'] = 'translatez(0)';
        th.interval = setInterval(function () {
          th.particles.push(
            new Particle(th.app, container, {
              speed: 0.1 + Math.random() * 2,
              media: th.progress % 20 === 1 ? 2 : 0,
              colourSet: th.colourSet,
            })
          );
          th.progress++;
        }, 20);
      }
      this.initSuccess = true;
    }
  }

  update() {
    if (this.initSuccess) {
      this.particles = this.particles.filter(function (p) {
        return p.move();
      });
      requestAnimationFrame(this.update.bind(this));
    }
  }

  stop() {
    clearInterval(this.interval);
    if (this.el) {
      this.el.querySelector('canvas').remove();
    }
  }

  resize() {
    if (this.el) {
      this.el.querySelector('canvas').remove();
      this.init();
    }
  }
}

function getRandom(s, max) {
  let xmur3 = (str) => {
    let h;
    for (let i = 0, h = 1779033703 ^ str.length; i < str.length; i++) {
      h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
      h = (h << 13) | (h >>> 19);
    }
    return function () {
      h = Math.imul(h ^ (h >>> 16), 2246822507);
      h = Math.imul(h ^ (h >>> 13), 3266489909);
      return (h ^= h >>> 16) >>> 0;
    };
  };

  let random = (s, max) => {
    let seed = xmur3(s)();
    let x = Math.sin(seed++) * 10000;
    return Math.floor(max * (x - Math.floor(x)));
  };

  return random(s, max);
}

export function StartPlasm(el, colourSeed) {
  if (el) {
    let colourSet = getRandom(colourSeed, 4);
    let icosaedro = new Icosaedro(el, colourSet);
    icosaedro.update();

    window.onresize += () => {
      debounce(function () {
        icosaedro.resize();
      }, 200);
    };

    return icosaedro;
  }
}

export function StopPlasm(icosaedro) {
  icosaedro.stop();
}
