import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  loadWorkflows,
  exportWorkflowData,
} from '../../../../actions/workflowActions';
import { CommaNumber_NoDecimal, hexToRgbA } from '../../../../util/formatter';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { Date_AsString } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import image_aurora_icon from '../../../../content/images/AURORA_LOGO_SM.svg';
import { scopes as tableScopes } from '../../../../constants/scopes';
import { useTranslation } from 'react-i18next';

function WorkflowList({ location, reload }) {
  const allColumns = [
    'keyWorkflowName',
    'description',
    'requesterCount',
    'workflowStepCount',
    'openRequestCount',
    'completeRequestCount',
    'lastRequested',
    'lastUpdated',
  ];
  const defaultVisibleColumns = allColumns;
  const { t } = useTranslation();

  const [workflows, setWorkflows] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'workflowlist-settings',
      getTableSettingsDefaults({
        defaultSort: 'keyWorkflowName',
        defaultVisibleColumns,
      }),
      null,
      loadWorkflows,
      setWorkflows,
      reload,
      t('Failed to load workflows'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'keyWorkflowName',
      Label: t('Request type'),
      Render: (workflow) => (
        <div
          className="p-2 d-flex align-items-center workflow-td"
          style={
            workflow.colourHex
              ? {
                  borderLeft: `3px solid ${workflow.colourHex}`,
                }
              : null
          }
        >
          <Link to={'/settings/workflow/' + workflow.id}>
            {workflow.keyWorkflowName}
            {workflow.isAuroraWorkflow && (
              <>
                <br />
                <img
                  src={image_aurora_icon}
                  alt={t('Aurora logo')}
                  title={t('Aurora enabled workflow')}
                  height={12}
                  className="ms-2"
                  style={{ opacity: 0.6 }}
                />
              </>
            )}
          </Link>
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'description',
      Label: t('Description'),
      Render: (workflow) => workflow.description,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requesterCount',
      Label: t('# Requesters'),
      width: '5%',
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.requesterCount),
    },
    {
      Key: 'workflowStepCount',
      Label: t('# Workflow steps'),
      width: '5%',
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.workflowStepCount),
    },
    {
      Key: 'openRequestCount',
      Label: t('# Open requests'),
      width: '5%',
      className: 'text-right num no-wrap',
      Render: (item) => (
        <NavLink
          to={`/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISPENDING}=true&${tableScopes.KEYWORKFLOW}=${item.id}`}
        >
          {CommaNumber_NoDecimal(item.openRequestCount)}
        </NavLink>
      ),
    },
    {
      Key: 'completeRequestCount',
      Label: t('# Complete requests'),
      width: '5%',
      className: 'text-right num no-wrap',
      Render: (item) => (
        <NavLink
          to={`/requests/all?${tableScopes.EXCLUDEARCHIVEDTITLES}=true&${tableScopes.ISAUTHORISED}=true&${tableScopes.KEYWORKFLOW}=${item.id}`}
        >
          {CommaNumber_NoDecimal(item.completeRequestCount)}
        </NavLink>
      ),
    },
    {
      Key: 'lastRequested',
      Label: t('Last requested'),
      width: '5%',
      Render: (workflow) => Date_AsString(workflow.lastRequested),
    },
    {
      Key: 'lastUpdated',
      Label: t('Updated'),
      width: '5%',
      Render: (workflow) => Date_AsString(workflow.lastUpdated),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return workflows ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={workflows.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={workflows.totalRowCount}
        filteredRowCount={workflows.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportWorkflowData}
        trStyle={(row) => {
          return {
            backgroundColor: `${hexToRgbA(row.colourHex, 0.0375)}`,
          };
        }}
        location={location}
        scopes={workflows.scopes}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default WorkflowList;
