import React from 'react';
import KwPanel from '../../../common/KwPanel';
import FormInput from '../../../common/FormInput';
import PropTypes from 'prop-types';
import FormColourPicker from '../../../common/FormColourPicker';
import FormTextArea from '../../../common/FormTextArea';
import { useTranslation } from 'react-i18next';

const TerritoryForm = ({ territory, register, errors, control }) => {
  const { t } = useTranslation();
  return territory ? (
    <KwPanel className="mb-4 pb-3 pt-2">
      <FormInput
        register={register}
        required={true}
        error={errors.name}
        errorMessage={t('Required')}
        name="name"
        label={t('Name')}
        defaultValue={territory.name || ''}
        autoFocus={true}
      />

      <FormTextArea
        register={register}
        error={errors.register}
        name="description"
        label={t('Description')}
        placeholder={t('Enter a description')}
        defaultValue={territory.description || ''}
      />

      <FormColourPicker
        control={control}
        name="colourHex"
        label={t('Colour')}
        extraRowClasses="align-items-start"
        defaultValue={territory.colourHex || 'transparent'}
      />
    </KwPanel>
  ) : (
    <></>
  );
};

TerritoryForm.propTypes = {
  territory: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object,
};

export default TerritoryForm;
