import React, { useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { ByteLength_AsString } from '../../util/formatter';
import FormGroup from './FormGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation, Trans } from 'react-i18next';
import icons from '../../constants/icons';

const FormFileUpload = ({
  label,
  error,
  errorMessage,
  required,
  addFiles,
  fileExtension,
  hideFiles,
  file,
}) => {
  const { t } = useTranslation();
  const onDrop = useCallback(
    (droppedFiles) => {
      addFiles(droppedFiles);
    },
    [addFiles]
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });
  const files = (file ? [...acceptedFiles, file] : acceptedFiles).map((f) => (
    <li key={f.path}>
      <span>
        <FontAwesomeIcon icon={icons.FILE} className="me-3" />
        <b>{f.path}</b>
      </span>

      <span>{ByteLength_AsString(f.size)}</span>
    </li>
  ));
  const classeNames = useMemo(
    () =>
      `dropzone ${isDragActive ? 'active' : ''} ${
        isDragAccept ? 'accept positive-colour1' : ''
      } ${isDragReject ? 'reject' : ''}`,
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <FormGroup
      label={label}
      error={error}
      errorMessage={errorMessage}
      required={required}
    >
      <>
        <div {...getRootProps({ className: classeNames })}>
          <input {...getInputProps()} />
          <p>
            <Trans
              i18nKey="dragAndDropInstruction"
              components={{ bold: <b /> }}
              values={{ fileExtension: fileExtension || '.txt' }}
            >
              Drag &amp; drop <bold>{{ fileExtension }}</bold> files here, or
              click to select files
            </Trans>
          </p>
        </div>
        {files && files.length > 0 && !hideFiles ? (
          <aside className="drop-files">
            <h4>{t('Files')}</h4>
            <ul>{files}</ul>
          </aside>
        ) : (
          ''
        )}
      </>
    </FormGroup>
  );
};

export default FormFileUpload;
