import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import KwPanel from '../../common/KwPanel';
import { loadKeyPools } from '../../../actions/skuActions';
import Loading from '../../common/Loading';
import { CommaNumber_NoDecimal, ParseInt } from '../../../util/formatter';
import { moveKeys } from '../../../actions/keyCodeActions';
import { toast } from 'react-toastify';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function MoveKeys({
  done,
  skuId,
  gameId,
  skuName,
  keyWorkflowId,
  keyWorkflowName,
  cancel,
}) {
  const [sourcePool, setSourcePool] = useState();
  const [keypools, setKeyPools] = useState();
  const [totalKeys, setTotalKeys] = useState(0);
  const [remainingKeys, setRemainingKeys] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [bulkKeys, setBulkKeys] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    loadKeyPools({
      id: skuId,
      gameid: gameId,
      filters: [],
      _sort: '',
      _order: '',
      _page: 1,
      _limit: 1_000,
    }).then((data) => {
      setKeyPools(data.data.map((x) => ({ ...x, amountToMove: 0 })));
      setSourcePool(
        data.data.filter((x) => x.keyWorkflowId === keyWorkflowId)[0]
      );
    });
  }, [skuId, gameId, keyWorkflowId]);

  useEffect(() => {
    if (keypools && sourcePool) {
      let _total = keypools.reduce(
        (sum, pool) => sum + ParseInt(pool.amountToMove),
        0
      );
      setTotalKeys(_total);
      setRemainingKeys(sourcePool.available - _total);
    } else {
      setTotalKeys(0);
      setRemainingKeys(sourcePool ? sourcePool.available : 0);
    }
  }, [keypools, sourcePool]);

  const setRowData = (id, data) => {
    setBulkKeys('');
    let newArr = [...keypools];
    let index = newArr.findIndex((x) => x.keyWorkflowId === id);
    newArr[index] = { ...newArr[index], ...data };
    setKeyPools(newArr);
  };

  const handleSetBulkNumberOfKeys = (e) => {
    if (!keypools) {
      return;
    }
    const numberOfKeys = ParseInt(e.target.value);
    setBulkKeys(numberOfKeys);
    setKeyPools(
      keypools.map((keyPool) => {
        return { ...keyPool, amountToMove: numberOfKeys };
      })
    );
  };

  const handleSubmit = () => {
    if (!keypools) {
      toast.error(t('No destination keypools selected'));
      return;
    }

    setProcessing(true);

    moveKeys({
      skuId: skuId,
      sourceKeyWorkflowId: keyWorkflowId,
      description: '',
      destinationKeyPools: keypools.map((x) => ({
        keyWorkflowId: x.keyWorkflowId,
        numberOfKeys: ParseInt(x.amountToMove),
      })),
    })
      .then((d) => {
        done(d);
        toast.success(t('Move request queued'));
      })
      .catch(() => {
        setProcessing(false);
        toast.error(t('Failed to move keys'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Move keys')}
      cancel={cancel}
      done={done}
      bar={
        keypools &&
        sourcePool && (
          <Button
            className="btn btn-primary"
            isDisabled={processing || totalKeys < 1 || remainingKeys < 0}
            title={
              processing || totalKeys < 1 || remainingKeys < 0
                ? t('Processing')
                : ''
            }
            onClick={handleSubmit}
            text={t('Move')}
          />
        )
      }
    >
      {keypools && sourcePool ? (
        <KwPanel key={skuId} className="space-bottom">
          <header className="mb-2">
            <h2 className="no-margin bold">
              {t('Move keys from pool : {keyWorkflowName}', {
                keyWorkflowName: keyWorkflowName,
              })}
            </h2>
          </header>
          <div className="table-wrapper">
            <table className="table table-has-buttons jsDataTable width-100 space-bottom td-align-top">
              <thead>
                <tr>
                  <th scope="col">
                    <span>{t('Source pool')}</span>
                  </th>
                  <th scope="col" className="text-right">
                    <span>{t('Currently in source')}</span>
                  </th>
                  <th scope="col" className="text-right">
                    <span>{t('Amount to move')}</span>
                  </th>
                </tr>

                <tr>
                  <td>{keyWorkflowName}</td>
                  <td className="text-right num">
                    {remainingKeys < 0 ? (
                      <span className="text-red">
                        {CommaNumber_NoDecimal(remainingKeys)}
                      </span>
                    ) : (
                      CommaNumber_NoDecimal(remainingKeys)
                    )}
                  </td>
                  <td>
                    <div
                      style={{ marginRight: 12 }}
                      className="num text-right bold"
                    >
                      {CommaNumber_NoDecimal(totalKeys)}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <span>{t('Destination pool')}</span>
                  </th>
                  <th scope="col" className="text-right">
                    <span>{t('Currently in desination')}</span>
                  </th>
                  <th scope="col" className="text-right">
                    <span>{t('Amount to move')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="br_row">
                  <td colSpan={2}></td>
                  <td className="bulk">
                    <div className="flex justify-content-end">
                      <input
                        type="text"
                        className={`form-control input-sm num text-right no-navigate`}
                        style={{ width: '120px' }}
                        value={bulkKeys}
                        onChange={handleSetBulkNumberOfKeys}
                        placeholder={t('Bulk edit')}
                      ></input>
                    </div>
                  </td>
                </tr>
                {keypools
                  .filter((x) => x.keyWorkflowId !== keyWorkflowId)
                  .map((pool) => (
                    <tr key={pool.keyWorkflowId} className="br_row">
                      <td>{pool.keyWorkflowName}</td>
                      <td className="num text-right">
                        {CommaNumber_NoDecimal(pool.available)}
                      </td>
                      <td>
                        <div className="flex justify-content-end">
                          <input
                            type="text"
                            value={pool.amountToMove}
                            className={`form-control input-sm num text-right no-navigate`}
                            style={{ width: '120px' }}
                            onKeyDown={(keyEvent) => {
                              try {
                                let amt = 0;
                                let handled = false;
                                if (keyEvent.keyCode === 38) {
                                  // up
                                  amt = keyEvent.shiftKey ? 10 : 1;
                                  handled = true;
                                } else if (keyEvent.keyCode === 40) {
                                  // down
                                  amt = keyEvent.shiftKey ? -10 : -1;
                                  handled = true;
                                } else if (keyEvent.keyCode === 33) {
                                  // pgup
                                  amt = keyEvent.shiftKey ? 1000 : 100;
                                  handled = true;
                                } else if (keyEvent.keyCode === 34) {
                                  // pgdown
                                  amt = keyEvent.shiftKey ? -1000 : -100;
                                  handled = true;
                                }
                                let newAmount =
                                  ParseInt(pool.amountToMove) + amt;
                                if (handled) {
                                  setRowData(pool.keyWorkflowId, {
                                    amountToMove: newAmount < 0 ? 0 : newAmount,
                                  });
                                }
                              } catch (err) {}
                            }}
                            onChange={(e) => {
                              e.cancelBubble = true;
                              setRowData(pool.keyWorkflowId, {
                                amountToMove: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </KwPanel>
      ) : (
        <Loading />
      )}
    </SlideoutLayout>
  );
}

MoveKeys.propTypes = {
  skuId: PropTypes.string,
  skuName: PropTypes.string,
  keyWorkflowId: PropTypes.string,
  keyWorkflowName: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default MoveKeys;
