import {
  apiUrl,
  getList,
  getSingleRecord,
  saveRecord,
  deleteRecord,
  getAll,
  exportData,
  removeBlankProperties,
} from './apiUtils';
const baseUrl = apiUrl + '/keyUploaders/';

export function getUploaders(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}search`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getUploaderById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveUploader(uploader) {
  removeBlankProperties(uploader);
  return saveRecord(baseUrl, uploader);
}

export function deleteUploader(id) {
  return deleteRecord(baseUrl, id);
}

export function getUploadersForKeyProvider(keyProviderId) {
  return getAll(`${baseUrl}keyProvider/${keyProviderId}`);
}

export function getUploadersForKeyWorkflow(keyWorkflowId) {
  return getAll(`${baseUrl}keyWorkflow/${keyWorkflowId}`);
}
export function getUploadersForPlatform(platformId) {
  return getAll(`${baseUrl}platform/${platformId}`);
}

export function exportUploadersData(filters, scopes) {
  return exportData(
    `${apiUrl}/export/keyUploaders/search`,
    'name',
    filters,
    scopes
  );
}
