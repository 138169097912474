import React from 'react';
import KwPanel from '../../common/KwPanel';
import FormSelect from '../../common/FormSelect';
import FormFileUpload from '../../common/FormFileUpload';
import Button from '../../common/Button';
import ConfirmationHeader from '../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function BulkIngestForm({
  keyProviders,
  register,
  errors,
  control,
  submit,
  cancel,
  addFiles,
}) {
  const { t } = useTranslation();
  return (
    <>
      <KwPanel className="mb-4 pb-1">
        <FormSelect
          register={register}
          error={errors.keyProviderId}
          errorMessage={t('Key provider is required')}
          name="keyProviderId"
          label={t('Key provider')}
          placeholder=""
          autoFocus={true}
        >
          <option value="">{t('--- choose ---')}</option>
          {keyProviders
            ? [...keyProviders]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))
            : ''}
        </FormSelect>
        <FormFileUpload
          name="files"
          label={t('Upload key file')}
          error={errors.files}
          required={true}
          addFiles={addFiles}
        />
      </KwPanel>
      <KwPanel className="mb-4">
        <>
          <ConfirmationHeader />
          <p>{t('Are you sure?')}</p>
          <Button
            onClick={submit}
            className="btn btn-primary me-2"
            text={t('Yes, continue')}
          />
          <Button
            onClick={cancel}
            className="btn btn-default"
            text={t('No, cancel')}
          />
        </>
      </KwPanel>
    </>
  );
}

export default BulkIngestForm;
