import React, { useEffect } from 'react';
import FormGroup from './FormGroup';
import { Controller } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';

const FormRichText = ({
  label,
  name,
  defaultValue,
  placeholder,
  register,
  error,
  errorMessage,
  control,
  required,
  extraRowClasses = 'align-items-start',
  disabled,
}) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    const _contentBlock = htmlToDraft(defaultValue);
    if (_contentBlock) {
      const _contentState = ContentState.createFromBlockArray(
        _contentBlock.contentBlocks
      );
      const _editorState = EditorState.createWithContent(_contentState);
      setEditorState(_editorState);
    }
  }, [defaultValue]);

  const onEditorStateChange = (editorState, onChange) => {
    // https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
    setEditorState(editorState);
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <FormGroup
      label={label}
      extraRowClasses={extraRowClasses}
      error={error}
      errorMessage={errorMessage}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange } }) => (
          <Editor
            editorState={editorState}
            disabled={disabled ?? false}
            toolbarClassName="toolbar"
            wrapperClassName="draftJSWrapper"
            editorClassName="editor"
            onEditorStateChange={(editorState) =>
              onEditorStateChange(editorState, onChange)
            }
            toolbar={{
              options: [
                'emoji',
                'inline',
                'blockType',
                'fontSize',
                'fontFamily',
                'list',
              ],
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: [
                  'bold',
                  'italic',
                  'underline',
                  'strikethrough',
                  'superscript',
                  'subscript',
                ],
              },
              blockType: {
                inDropdown: true,
                options: [
                  'Normal',
                  'H1',
                  'H2',
                  'H3',
                  'H4',
                  'H5',
                  'H6',
                  'Blockquote',
                  'Code',
                ],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
              },
            }}
          />
        )}
      />
    </FormGroup>
  );
};

FormRichText.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.func,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  control: PropTypes.object,
  required: PropTypes.bool,
  extraRowClasses: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormRichText;
