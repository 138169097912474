import React from 'react';
import PropTypes from 'prop-types';

import image_request_request_complete from '../../../content/images/request_icons/request_complete.svg';
import image_request_request_declined from '../../../content/images/request_icons/request_declined.svg';
import image_request_request_finish from '../../../content/images/request_icons/request_finish.svg';
import image_request_request_inprogress from '../../../content/images/request_icons/request_inprogress.svg';
import image_request_request_keys from '../../../content/images/request_icons/request_keys.svg';
import image_request_request_pending from '../../../content/images/request_icons/request_pending.svg';
import image_request_request_start from '../../../content/images/request_icons/request_start.svg';
import image_request_request_todo from '../../../content/images/request_icons/request_todo.svg';
import image_request_request_waiting_for_keys from '../../../content/images/request_icons/request_waiting-for-keys.svg';
import image_request_request_withdrawn from '../../../content/images/request_icons/request_withdrawn.svg';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

function RequestDetailsStep({
  stepClassName,
  iconClassName,
  iconFileName,
  stepName,
  stepStatus,
  showButton,
  buttonOnClick,
}) {
  const { t } = useTranslation();
  const resolveImage = (iconFileName) => {
    switch (iconFileName) {
      case 'complete':
        return image_request_request_complete;
      case 'declined':
        return image_request_request_declined;
      case 'finish':
        return image_request_request_finish;
      case 'inprogress':
        return image_request_request_inprogress;
      case 'keys':
        return image_request_request_keys;
      case 'pending':
        return image_request_request_pending;
      case 'start':
        return image_request_request_start;
      case 'todo':
        return image_request_request_todo;
      case 'waiting-for-keys':
        return image_request_request_waiting_for_keys;
      case 'withdrawn':
        return image_request_request_withdrawn;
      default:
        return undefined;
    }
  };
  return stepName && stepStatus ? (
    <div className={`status-step ${stepClassName}`}>
      <div
        className={`status-icon img-circle text-center abs-tl img-border center-child ${iconClassName}`}
      >
        <img
          src={resolveImage(iconFileName)}
          className="img-details-step"
          alt={t('Trophy')}
          width="32"
          height="32"
        />
      </div>
      <div
        className="panel kw-panel r-corners space-bottom clearfix"
        style={{ padding: '13px 15px' }}
      >
        <div className="float-left">
          <h4 className="bold no-margin space-bottom-small">{stepName}</h4>
          <div className="sub space-bottom-xs">{stepStatus}</div>
        </div>
        {showButton ? (
          <Button
            onClick={buttonOnClick}
            className="btn btn-default btn-xs-full float-right"
            text={t('View')}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}

RequestDetailsStep.propTypes = {
  stepClassName: PropTypes.any.isRequired,
  iconClassName: PropTypes.string.isRequired,
  iconFileName: PropTypes.string.isRequired,
  stepName: PropTypes.any.isRequired,
  stepStatus: PropTypes.any.isRequired,
  showButton: PropTypes.bool.isRequired,
  buttonOnClick: PropTypes.func,
};

export default RequestDetailsStep;
