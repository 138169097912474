import { useEffect, useState } from 'react';

const useUploadNotificationsSingle = (
  groupPrefix,
  removeFromGroup,
  importTransactions,
  found
) => {
  const [uploadsInProgress, setUploadsInProgress] = useState([]);

  useEffect(() => {
    //clear subscriptions
    if (uploadsInProgress && uploadsInProgress.length > 0) {
      return () => {
        let uploads = [];
        for (let i in uploadsInProgress) {
          uploads.push(
            removeFromGroup(`${groupPrefix}:${uploadsInProgress[i]}`)
          );
        }
        return Promise.all(uploads);
      };
    }
  }, [uploadsInProgress, removeFromGroup, groupPrefix]);

  useEffect(() => {
    if (
      importTransactions &&
      importTransactions.length > 0 &&
      importTransactions.filter((x) => uploadsInProgress.includes(x.id))
        .length > 0
    ) {
      found(importTransactions.filter((x) => uploadsInProgress.includes(x.id)));
    }
  }, [importTransactions, uploadsInProgress, found]);

  return [uploadsInProgress, setUploadsInProgress];
};
export default useUploadNotificationsSingle;
