import { apiUrl, get } from './apiUtils';
const baseUrl = apiUrl + '/lens/';

export function getUserLens(id) {
  return get(`${baseUrl}user/${id}`);
}

export function getCompanyLens(id) {
  return get(`${baseUrl}company/${id}`);
}
