import React from 'react';
import TableCell from './TableCell';
export default function TableRow({
  row,
  rows,
  totalRowCount,
  sortedField,
  trStyle,
  columnsWithSuffix,
}) {
  let renderStyle = (row) => (trStyle !== undefined ? trStyle(row) : null);
  return (
    <tr key={row.Key} style={renderStyle(row)}>
      {columnsWithSuffix.map((col) => {
        return (
          <TableCell
            key={col.Key}
            col={col}
            row={row}
            rows={rows}
            totalRowCount={totalRowCount}
            sortedField={sortedField}
          />
        );
      })}
    </tr>
  );
}
