import actionTypes from '../constants/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.CREATE_PACKAGETYPE:
      return state ? [...state, action.packageType] : state;
    case actionTypes.UPDATE_PACKAGETYPE:
      return state
        ? state.map((packageType) =>
            packageType.id === action.packageType.id
              ? action.packageType
              : packageType
          )
        : state;
    case actionTypes.LOAD_PACKAGETYPES:
      return action.packageTypes;
    case actionTypes.DELETE_PACKAGETYPE:
      return state
        ? state.filter((packageType) => packageType.id !== action.id)
        : state;
    default:
      return state;
  }
}
