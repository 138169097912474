import React from 'react';
import { CommaNumber_NoDecimal } from '../../util/formatter';
import { useTranslation } from 'react-i18next';

const PageSize = ({ pageSize, setPageSize, isLoading }) => {
  const { t } = useTranslation();
  const onChange = (el) => {
    setPageSize(parseInt(el.target.value));
  };
  return (
    <div className="dataTables_length">
      <select
        className="form-control input-sm"
        value={pageSize}
        onChange={onChange}
        disabled={isLoading}
      >
        <option value="2">{t('SHOW 2')}</option>
        <option value="10">{t('SHOW 10')}</option>
        <option value="25">{t('SHOW 25')}</option>
        <option value="50">{t('SHOW 50')}</option>
        <option value="100">{t('SHOW 100')}</option>
      </select>
    </div>
  );
};

const RowCountLabel = ({
  pageNumber,
  pageSize,
  totalRowCount,
  filteredRowCount,
}) => {
  const { t } = useTranslation();
  const start = pageNumber * pageSize + 1;
  let end = pageNumber * pageSize + pageSize;
  end = end > filteredRowCount ? filteredRowCount : end;
  let text =
    filteredRowCount > 0
      ? t('Showing {{start}} to {{end}} of {{filteredRowCount}}', {
          start: CommaNumber_NoDecimal(start),
          end: CommaNumber_NoDecimal(end),
          filteredRowCount: CommaNumber_NoDecimal(filteredRowCount),
        })
      : '';
  // if (filteredRowCount > 0 && totalRowCount !== filteredRowCount) {
  //   text += ` (filtered from ${CommaNumber_NoDecimal(totalRowCount)})`;
  // }

  return (
    <div className="dataTables_info" title={text}>
      {text}
    </div>
  );
};

const Page = ({
  text,
  pageNumber,
  setPageNumber,
  disabled,
  currentPageNumber,
}) => {
  let classes = `paginate_button ${
    text === currentPageNumber + 1 ? 'active' : ''
  }`;
  return (
    <li className={classes}>
      <button disabled={disabled} onClick={(el) => setPageNumber(pageNumber)}>
        {text}
      </button>
    </li>
  );
};

const PageNumbers = ({
  pageNumber,
  setPageNumber,
  pageSize,
  filteredRowCount,
  isLoading,
}) => {
  const { t } = useTranslation();
  const pages = Math.ceil(filteredRowCount / pageSize);
  const items = [];
  items.push(
    <Page
      key="previous"
      text={t('Previous')}
      pageNumber={pageNumber - 1}
      disabled={pageNumber === 0 || isLoading}
      setPageNumber={setPageNumber}
    ></Page>
  );
  //set a maximum number of buttons to show
  const maxPages = 5;
  if (pageNumber > pages - maxPages && pages > maxPages) {
    //1...43,44,45,46,47
    items.push(
      <Page
        text="1"
        pageNumber={0}
        setPageNumber={setPageNumber}
        disabled={isLoading}
      ></Page>
    );
    items.push(
      <Page
        key="viewmore"
        text="..."
        pageNumber={maxPages}
        setPageNumber={setPageNumber}
        disabled={isLoading}
      ></Page>
    );
    for (let i = pages - maxPages; i < pages; i++) {
      items.push(
        <Page
          key={i}
          text={i + 1}
          currentPageNumber={pageNumber}
          pageNumber={i}
          setPageNumber={setPageNumber}
          disabled={isLoading}
        ></Page>
      );
    }
  } else if (pageNumber <= maxPages - 1) {
    //1,2,3,4,5...47
    for (let i = 0; i < pages && i < maxPages; i++) {
      items.push(
        <Page
          key={i}
          text={i + 1}
          currentPageNumber={pageNumber}
          pageNumber={i}
          setPageNumber={setPageNumber}
          disabled={isLoading}
        ></Page>
      );
    }
    if (pages > maxPages) {
      items.push(
        <Page
          key="viewmore"
          text="..."
          pageNumber={maxPages}
          setPageNumber={setPageNumber}
          disabled={isLoading}
        ></Page>
      );
      items.push(
        <Page
          key="lastpage"
          text={pages}
          pageNumber={pages - 1}
          setPageNumber={setPageNumber}
          disabled={isLoading}
        ></Page>
      );
    }
  } else {
    //1...42,43,44...47
    items.push(
      <Page
        key="1"
        text="1"
        pageNumber={0}
        setPageNumber={setPageNumber}
        disabled={isLoading}
      ></Page>
    );
    items.push(
      <Page
        key="viewmore"
        text="..."
        pageNumber={pageNumber - 2}
        setPageNumber={setPageNumber}
        disabled={isLoading}
      ></Page>
    );
    for (let i = pageNumber - 1; i <= pageNumber + 1; i++) {
      items.push(
        <Page
          key={i}
          text={i + 1}
          pageNumber={i}
          currentPageNumber={pageNumber}
          setPageNumber={setPageNumber}
          disabled={isLoading}
        ></Page>
      );
    }

    items.push(
      <Page
        key="viewmore_after"
        text="..."
        pageNumber={pageNumber + 2}
        setPageNumber={setPageNumber}
        disabled={isLoading}
      ></Page>
    );
    items.push(
      <Page
        key="lastpage"
        text={pages}
        pageNumber={pages - 1}
        setPageNumber={setPageNumber}
        disabled={isLoading}
      ></Page>
    );
  }

  items.push(
    <Page
      key="next"
      text={t('Next')}
      pageNumber={pageNumber + 1}
      disabled={pageNumber === pages - 1 || isLoading}
      setPageNumber={setPageNumber}
    ></Page>
  );

  return (
    <div className="dataTables_paginate paging_simple_numbers">
      <ul className="pagination">{items}</ul>
    </div>
  );
};

function Pagination({
  totalRowCount,
  filteredRowCount,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  isLoading,
}) {
  if (totalRowCount > 0) {
    return (
      <div className="datatables-bottom clearfix">
        <PageSize
          pageSize={pageSize}
          setPageSize={setPageSize}
          isLoading={isLoading}
        />
        <RowCountLabel
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalRowCount={totalRowCount}
          filteredRowCount={filteredRowCount}
        />
        <div className="float-right">
          <PageNumbers
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            filteredRowCount={filteredRowCount}
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  }
  return '';
}

export default Pagination;
