import { apiUrl, exportData, getList } from './apiUtils';
const baseUrl = apiUrl + '/reports/';
const exportBaseUrl = `${apiUrl}/export/reports/`;

export function getRequestsByMonthByGame(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}requests/month/game?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getRequestsByMonthBySKU(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}requests/month/sku?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getKeysByMonthByGame(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}keys/month/game?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getKeysByMonthBySku(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}keys/month/sku?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getKeysByMonthByCompany(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}keys/month/company?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getRequestsByMonthByCompany(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}requests/month/company?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getRequestsByMonthByAuthorisingUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}requests/month/authorisinguser?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportRequestsByMonthByAuthorisingUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}requests/month/authorisinguser?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getKeysByMonthByAuthorisingUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}keys/month/authorisinguser?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportKeysByMonthByAuthorisingUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}keys/month/authorisinguser?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getRequestsByMonthByUploadingUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}requests/month/uploadinguser?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportRequestsByMonthByUploadingUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}requests/month/uploadinguser?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getKeysByMonthByUploadingUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}keys/month/uploadinguser?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportKeysByMonthByUploadingUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}keys/month/uploadinguser?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function exportRequestsByMonthByGame(year, company, filters, scopes) {
  let url = `${exportBaseUrl}requests/month/game?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'game', filters, scopes);
}

export function exportRequestsByMonthBySKU(year, company, filters, scopes) {
  let url = `${exportBaseUrl}requests/month/sku?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'game', filters, scopes);
}

export function exportKeysByMonthByGame(year, company, filters, scopes) {
  let url = `${exportBaseUrl}keys/month/game?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'game', filters, scopes);
}

export function exportKeysByMonthBySku(year, company, filters, scopes) {
  let url = `${exportBaseUrl}keys/month/sku?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'game', filters, scopes);
}

export function exportKeysByMonthByCompany(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}keys/month/company?year=${year}`,
    'companyName',
    filters,
    scopes
  );
}

export function exportRequestsByMonthByCompany(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}requests/month/company?year=${year}`,
    'companyName',
    filters,
    scopes
  );
}

export function exportSLAsByCompany(filters, scopes) {
  return exportData(
    `${exportBaseUrl}sla/company`,
    'companyName',
    filters,
    scopes
  );
}

export function getUsersModifiedByMonthByUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}users/month/user?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportUsersModifiedByMonthByUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}users/month/user?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getKeyWorkflowsModifiedByMonthByUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}keyworkflows/month/user?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportKeyWorkflowsModifiedByMonthByUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}keyworkflows/month/user?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getGamesModifiedByMonthByUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}games/month/user?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportGamesModifiedByMonthByUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}games/month/user?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getSKUsModifiedByMonthByUser(
  year,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}skus/month/user?year=${year}`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportSKUsModifiedByMonthByUser(year, filters, scopes) {
  return exportData(
    `${exportBaseUrl}skus/month/user?year=${year}`,
    'fullName',
    filters,
    scopes
  );
}

export function getRequestsByMonthByWorkflow(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}requests/month/workflow?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function exportRequestsByMonthByWorkflow(
  year,
  company,
  filters,
  scopes
) {
  let url = `${exportBaseUrl}requests/month/workflow?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'workflow', filters, scopes);
}
export function getKeysByMonthByWorkflow(
  year,
  company,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}keys/month/workflow?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function exportKeysByMonthByWorkflow(year, company, filters, scopes) {
  let url = `${exportBaseUrl}keys/month/workflow?year=${year}`;
  if (company) {
    url += `&companyId=${company}`;
  }
  return exportData(url, 'workflow', filters, scopes);
}
