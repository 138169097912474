import React from 'react';

import image_alaska_loader_error from '../../content/images/alaska_loader_error.png';
import image_alaska_loader2 from '../../content/images/alaska_loader2.gif';
import image_alaska_loader3 from '../../content/images/alaska_loader3.gif';
import { useTranslation } from 'react-i18next';

const Loading = ({ message, inPanel, isError }) => {
  const { t } = useTranslation();
  return (
    <div className="alaskaloader">
      {' '}
      <div className="loading-img">
        {isError ? (
          <img
            src={image_alaska_loader_error}
            alt={t('loading')}
            className="img-responsive"
            width="100px"
          />
        ) : inPanel ? (
          <img
            src={image_alaska_loader2}
            alt={t('loading')}
            className="img-responsive"
            width="100px"
          />
        ) : (
          <img
            src={image_alaska_loader3}
            alt={t('loading')}
            className="img-responsive"
            width="100px"
          />
        )}
      </div>
      <span>{message || t('LOADING')}</span>
    </div>
  );
};
export default Loading;
