import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { updateRequestValue } from '../../../actions/requestActions';
import KwPanel from '../../common/KwPanel';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { useForm } from 'react-hook-form';
import EditRequestValueForm from '../components/EditRequestValueForm';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

export default function EditRequestValue({ done, cancel, request, history }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = useForm();
  const [processing, setProcessing] = useState();
  const { t } = useTranslation();
  const onSubmit = (data) => {
    setProcessing(true);
    updateRequestValue(request.id, data.newRequestValue, data.reason)
      .then((d) => {
        toast.success(t('Request updated'));
        done(d);
        setProcessing(false);
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to update request'));
        setProcessing(false);
      });
  };

  return (
    <SlideoutLayout
      title={t('Edit request value')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
          isDisabled={processing}
          title={processing ? t('Processing') : t('Save changes')}
          text={t('Save changes')}
        />
      }
    >
      <KwPanel className="mb-4 form-horizontal">
        <EditRequestValueForm
          request={request}
          register={register}
          errors={errors}
          control={control}
          getValues={getValues}
        ></EditRequestValueForm>
      </KwPanel>
    </SlideoutLayout>
  );
}
