import * as requestApi from '../api/requestApi';
import actionTypes from '../constants/actionTypes';

export function saveRequest(request) {
  return function (dispatch) {
    return requestApi.saveRequest(request).then((savedRequest) => {
      dispatch({
        type: request.id
          ? actionTypes.UPDATE_KEYREQUEST
          : actionTypes.CREATE_KEYREQUEST,
        request: savedRequest,
      });
    });
  };
}

export function bulkSaveRequests(requests) {
  return function (dispatch) {
    return requestApi.bulkSaveRequests(requests).then((savedRequestGroup) => {
      savedRequestGroup.requests.map((savedRequest) =>
        dispatch({
          type: actionTypes.CREATE_KEYREQUEST,
          request: savedRequest.id,
        })
      );
    });
  };
}

export function getRequestById(id) {
  return requestApi.getRequestById(id);
}
export function getRequestByKeyRequestGroupId(id) {
  return requestApi.getRequestByKeyRequestGroupId(id);
}

export function deleteRequestGroup(id) {
  return function (dispatch) {
    return requestApi.deleteRequestGroup(id).then(() => {
      dispatch({
        type: actionTypes.DELETE_KEYREQUESTGROUP,
        keyRequestGroupId: id,
      });
    });
  };
}
export function submitRequestGroup(id, record) {
  return function (dispatch) {
    return requestApi.submitRequestGroup(id, record).then(() => {
      //deconstruct the file object, as we don't need to track it
      const { file, ...updatedRecord } = record;
      dispatch({
        type: actionTypes.UPDATE_KEYREQUEST,
        record: { ...updatedRecord, submitting: true },
      });
    });
  };
}
export function deleteRequest(requestGroupId, id) {
  return function (dispatch) {
    return requestApi.deleteRequest(requestGroupId, id).then(() => {
      dispatch({
        type: actionTypes.DELETE_KEYREQUEST,
        keyRequestGroupId: requestGroupId,
        keyRequestId: id,
      });
    });
  };
}

export function loadRequests({
  assignedToMe,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return requestApi.getRequests(
    assignedToMe,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadRequestsGrouped({
  assignedToMe,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return requestApi.getRequestsGrouped(
    assignedToMe,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadRequestsMadeByMeGrouped({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return requestApi.getRequestsMadeByMeGrouped(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadRequestsMadeByMe({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return requestApi.getRequestsMadeByMe(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadRequestsToBeApprovedGrouped({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return requestApi.getRequestsToBeApprovedGrouped(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadRequestsToBeApproved({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return requestApi.getRequestsToBeApproved(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function reassignRequest(requestGroupId, assignToUserId) {
  return requestApi.reassignRequest(requestGroupId, assignToUserId);
}

export function changeStatus(requestId, statusId, reason) {
  return requestApi.changeStatus(requestId, statusId, reason);
}
export function changeStatuses(requestIds, statusId, reason) {
  return requestApi.changeStatuses(requestIds, statusId, reason);
}

export function getAvailableStatuses() {
  return requestApi.getAvailableStatuses();
}

export function getAvailableStatusesForRequest(requestId) {
  return requestApi.getAvailableStatusesForRequest(requestId);
}

export function withdrawRequest(requestId) {
  return requestApi.withdrawRequest(requestId);
}

export function updateRequestValue(requestId, value, reason) {
  return requestApi.updateRequestValue(requestId, value, reason);
}

export function updateNumberOfKeys(requestId, numberOfKeys, reason) {
  return requestApi.updateNumberOfKeys(requestId, numberOfKeys, reason);
}

export function downloadKeys(requestId) {
  return requestApi.downloadKeys(requestId);
}

export function uploadKeys(requestId, record) {
  return requestApi.uploadKeys(requestId, record);
}

export function getApprovalStatus(requestGroupId, requestId) {
  return requestApi.getApprovalStatus(requestGroupId, requestId);
}

export function getApprovals(requestGroupId, requestId) {
  return requestApi.getApprovals(requestGroupId, requestId);
}

export function getBasket() {
  return function (dispatch) {
    return requestApi.getBasket().then((requests) => {
      dispatch({
        type: actionTypes.LOAD_KEYREQUESTS,
        requests,
      });
    });
  };
}

export function checkRequestTransactionLimits(requestId, overrideRequestValue) {
  return requestApi.checkRequestTransactionLimits(
    requestId,
    overrideRequestValue
  );
}

export function checkRequestGroupTransactionLimits(
  requestGroupId,
  overrideRequestValue
) {
  return requestApi.checkRequestGroupTransactionLimits(
    requestGroupId,
    overrideRequestValue
  );
}

export function approveRequest(requestId, signature, overrideRequestValue) {
  return requestApi.approveRequest(requestId, signature, overrideRequestValue);
}

export function declineRequest(requestId, declineReason) {
  return requestApi.declineRequest(requestId, declineReason);
}

export function getAudit(id) {
  return requestApi.getAudit(id);
}

export function addAudit(id, comment) {
  return requestApi.addAudit(id, comment);
}

export function downloadAccountDetailsTemplate() {
  return requestApi.downloadAccountDetailsTemplate();
}
export function downloadAccountDetails(requestId) {
  return requestApi.downloadAccountDetails(requestId);
}
export function uploadAccountDetails(id, record) {
  return function (dispatch) {
    return requestApi
      .uploadAccountDetails(id, record)
      .then((accountDetails) => {
        dispatch({
          type: actionTypes.UPDATE_ACCOUNT_DETAILS,
          requestId: id,
          accountDetails,
        });
        return accountDetails;
      });
  };
}
export function getAccountDetails(id) {
  return requestApi.getAccountDetails(id);
}
export function saveAccountDetails(id, record) {
  return function (dispatch) {
    return requestApi.saveAccountDetails(id, record).then((accountDetails) => {
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT_DETAILS,
        requestId: id,
        accountDetails,
      });
      return accountDetails;
    });
  };
}

export function deleteAccountDetail(requestId, id) {
  return function (dispatch) {
    return requestApi.deleteAccountDetail(requestId, id).then(() => {
      dispatch({
        type: actionTypes.DELETE_ACCOUNT_DETAILS,
        requestId: requestId,
        id,
      });
    });
  };
}

export function sendAccountDetailEmail(requestId, id) {
  return requestApi.sendAccountDetailEmail(requestId, id);
}

export function downloadAccountDetailKeyCode(requestId, accountId) {
  return requestApi.downloadAccountDetailKeyCode(requestId, accountId);
}

export function copyRequestGroup(id, copyDetails) {
  return function (dispatch) {
    return requestApi
      .copyRequestGroup(id, copyDetails)
      .then((savedRequestGroup) => {
        savedRequestGroup.requests.map((savedRequest) =>
          dispatch({
            type: actionTypes.CREATE_KEYREQUEST,
            request: savedRequest.id,
          })
        );
      });
  };
}

export function exportRequestData(filters, scopes) {
  return requestApi.exportRequestData(filters, scopes);
}

export function exportMyRequestData(filters, scopes) {
  return requestApi.exportMyRequestData(filters, scopes);
}

export function exportAssignedRequestData(filters, scopes) {
  return requestApi.exportAssignedRequestData(filters, scopes);
}

export function exportApprovalRequestData(filters, scopes) {
  return requestApi.exportApprovalRequestData(filters, scopes);
}

export function validateCodeFile(keyRequestGroupId, filename) {
  return requestApi.validateCodeFile(keyRequestGroupId, filename);
}

export function downloadPurchaseOrder(keyRequestGroupId) {
  return requestApi.downloadPurchaseOrder(keyRequestGroupId);
}

export function getStatsByDay() {
  return requestApi.getStatsByDay();
}

export function getAggregateStats(from, to) {
  return requestApi.getAggregateStats(from, to);
}

export function getCodeStatsByRequestForRequest(id) {
  return requestApi.getCodeStatsByRequestForRequest(id);
}

export function returnKeys(requestId) {
  return requestApi.returnKeys(requestId);
}
