import React, { useState } from 'react';
import KwPanel from '../../common/KwPanel';
import FormDate from '../../common/FormDate';
import FormInput from '../../common/FormInput';
import FormTextArea from '../../common/FormTextArea';
import PropTypes from 'prop-types';
import FormSelect from '../../common/FormSelect';
import { NavLink } from 'react-router-dom';
import FormStatic from '../../common/FormStatic';
import KeyProviderLink from '../../common/links/KeyProviderLink';
import PlatformLink from '../../common/links/PlatformLink';
import skuHelp from '../../../constants/help/skuHelp';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const SkuForm = ({
  sku,
  register,
  errors,
  addMode,
  keyProviders,
  platforms,
  packageTypes,
  columnCssClass,
}) => {
  const { t } = useTranslation();
  const language = i18next.language;
  const [filteredKeyProviders, setFilteredKeyProviders] =
    useState(keyProviders);

  const handlePlatformChange = (e) => {
    const platform = platforms.find((x) => x.id === e.target.value);
    if (platform) {
      setFilteredKeyProviders(platform.keyProviders);
    } else {
      setFilteredKeyProviders([]);
    }
  };
  return (
    <>
      {sku && (
        <KwPanel className="mb-4">
          {!addMode || false ? (
            <div className="row">
              <div className="col-sm-6">
                <FormStatic label={t('Game')}>
                  <NavLink to={`/game/${sku.gameId}`}>{sku.gameName}</NavLink>
                </FormStatic>
              </div>
              <div className="col-sm-6">
                <FormStatic label={t('Key provider')}>
                  <KeyProviderLink
                    id={sku.keyProviderId}
                    name={sku.keyProvider}
                  />
                </FormStatic>
              </div>
              <div className="col-sm-12">
                <FormStatic label={t('Platform')}>
                  <PlatformLink id={sku.platformId} name={sku.platform} />
                </FormStatic>
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="row">
            <div className={columnCssClass || 'col-sm-6'}>
              <FormInput
                register={register}
                required={true}
                error={errors.name}
                errorMessage={t('Required')}
                name="name"
                label={t('Sku title')}
                placeholder={t('e.g. EFIGS')}
                defaultValue={sku.name || ''}
                autoFocus={true}
              />
              {addMode && (
                <>
                  <FormSelect
                    register={register}
                    required={true}
                    error={errors.platfromId}
                    errorMessage={t('Platform is required')}
                    name="platformId"
                    label={t('Platform')}
                    defaultValue={sku.platformId || ''}
                    onChange={handlePlatformChange}
                  >
                    <option value="">{t('--- choose ---')}</option>
                    {[...platforms]
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((x) =>
                        // filter options where there is no keyprovider
                        x.keyProviders && x.keyProviders.length > 0 ? (
                          <option key={x.id} value={x.id}>
                            {x.name}
                          </option>
                        ) : (
                          ''
                        )
                      )}
                  </FormSelect>
                  <FormSelect
                    register={register}
                    required={true}
                    error={errors.keyProviderId}
                    errorMessage={t('Key provider is required')}
                    name="keyProviderId"
                    label={t('Key provider')}
                    defaultValue={sku.keyProviderId || ''}
                  >
                    <option value="">{t('--- choose ---')}</option>
                    {[...filteredKeyProviders]
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((x) => (
                        <option key={x.id} value={x.id}>
                          {x.name}
                        </option>
                      ))}
                  </FormSelect>
                </>
              )}
              <FormSelect
                register={register}
                required={true}
                error={errors.packageTypeId}
                errorMessage={t('Package type is required')}
                name="packageTypeId"
                label={t('Package type')}
                placeholder=""
                defaultValue={sku.packageTypeId || ''}
              >
                <option value="">{t('--- choose ---')}</option>
                {[...packageTypes]
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  ))}
              </FormSelect>
              <FormInput
                register={register}
                name="appId"
                label={t('App Id')}
                placeholder={t('Optional')}
                helpContent={skuHelp.SKU_APP_ID[language]}
                defaultValue={sku.skuAppId || ''}
              />
              <FormInput
                register={register}
                name="packageId"
                label={t('Package Id')}
                placeholder={t('Optional')}
                defaultValue={sku.packageId || ''}
              />
            </div>
            <div className={columnCssClass || 'col-sm-6'}>
              <FormInput
                register={register}
                name="productCode"
                label={t('Product code')}
                placeholder={t('Optional')}
                defaultValue={sku.productCode || ''}
              />
              <FormInput
                register={register}
                required={true}
                error={errors.singleKeyValue}
                errorMessage={t('Required')}
                name="singleKeyValue"
                label={t('Single key value')}
                placeholder="0.00"
                defaultValue={sku.singleKeyValue || 0}
              />
              <FormDate
                register={register}
                name="fulfillmentDate"
                label={t('Fulfillment date')}
                placeholder={t('Optional')}
                defaultValue={sku.fulfillmentDate || ''}
                helpContent={skuHelp.FULFILMENT_DATE[language]}
              />
              <FormDate
                register={register}
                name="canRequestFromDate"
                label={t('Can request from')}
                placeholder={t('Optional')}
                defaultValue={sku.canRequestFromDate || ''}
                helpContent={skuHelp.CAN_REQUEST_FROM[language]}
              />
              <FormDate
                register={register}
                name="canRequestUntilDate"
                label={t('Can request until')}
                placeholder={t('Optional')}
                defaultValue={sku.canRequestUntilDate || ''}
                helpContent={skuHelp.CAN_REQUEST_UNTIL[language]}
              />
            </div>
            <div className="col-12">
              <FormTextArea
                register={register}
                name="notesHTML"
                label={t('Notes')}
                placeholder={t('Information, links etc.')}
                defaultValue={sku.notesHTML || ''}
              />
            </div>
          </div>
        </KwPanel>
      )}
    </>
  );
};

SkuForm.propTypes = {
  sku: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  addMode: PropTypes.bool,
  keyProviders: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
  packageTypes: PropTypes.array.isRequired,
  columnCssClass: PropTypes.string,
};

export default SkuForm;
