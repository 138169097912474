import React from 'react';
import FormStatic from '../../common/FormStatic';
import KwPanel from '../../common/KwPanel';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { CommaNumber_WithDecimal } from '../../../util/formatter';
import icons from '../../../constants/icons';
import Button from '../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompanyLink from '../../common/links/CompanyLink';
import { useTranslation } from 'react-i18next';
export default function RequestTransactionWarning({
  done,
  cancel,
  transactionLimits,
}) {
  const { t } = useTranslation();
  return (
    <SlideoutLayout
      title={
        <>
          <span className="warning-icon me-3">
            <FontAwesomeIcon icon={icons.ARE_YOU_SURE} />
          </span>
          {t('Warning - Transaction limits exceeded')}
        </>
      }
      cancel={cancel}
      done={done}
      bar={
        <>
          <Button
            className="btn btn-default me-2"
            onClick={cancel}
            text={t('Cancel')}
          />
          <Button
            className="btn btn-primary"
            onClick={done}
            text={t('Approve')}
          />
        </>
      }
    >
      <h1 className="mb-4">
        <CompanyLink
          id={transactionLimits.companyId}
          name={transactionLimits.companyName}
        />
      </h1>
      {transactionLimits.isOverTransactionLimit && (
        <KwPanel className="mb-4 form-horizontal">
          <header className="mb-2">
            <h2>{t('Transaction limit')}</h2>
          </header>
          <p>
            {t(
              'This is the maximum value of all open requests including this one, excluding key pools'
            )}
          </p>
          <FormStatic label={t('Maximum')}>
            <div className="num">
              {CommaNumber_WithDecimal(transactionLimits.transactionLimit)}
            </div>
          </FormStatic>
          <FormStatic label={t('Current including this')}>
            <div className="num">
              {CommaNumber_WithDecimal(
                transactionLimits.valueOfAllPendingRequests
              )}
            </div>
          </FormStatic>
        </KwPanel>
      )}
      {transactionLimits.isOverGlobalTransactionLimit && (
        <KwPanel className="mb-4 form-horizontal">
          <header className="mb-2">
            <h2>{t('Global Transaction limit')}</h2>
          </header>
          <p>
            {t(
              'This is the maximum balance of all key pools and open requests including this one'
            )}
          </p>
          <FormStatic label={t('Maximum')}>
            <div className="num">
              {CommaNumber_WithDecimal(
                transactionLimits.globalTransactionLimit
              )}
            </div>
          </FormStatic>
          <FormStatic label={t('Current including this')}>
            <div className="num">
              {CommaNumber_WithDecimal(transactionLimits.keyStockValue)}
            </div>
          </FormStatic>
        </KwPanel>
      )}
    </SlideoutLayout>
  );
}
