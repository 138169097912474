import React from 'react';

const keyProviderHelp = Object.freeze({
  CODE_FORMATS: {
    en: (
      <>
        <p>
          Alaska can validate code formats when keys are uploaded. Enter code
          formats masks here. We support any format.
        </p>
        <p>
          Use "x" to represent a number or letter. Everything else will need to
          match exactly.<br></br>For example, "xxx-xxx-xxx" will match
          "123-def-ghi" but not "123 def ghi".
        </p>
        <p>
          You can supply more than one format by entering each one on a new
          line.
        </p>
        <p>
          If no formats are supplied, Alaska will not validate the format of the
          uploaded codes.
        </p>
        <p>Example: xxxxx-xxxxx-xxxxx</p>
      </>
    ),
    ja: (
      <>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    fr: (
      <>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    de: (
      <>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    it: (
      <>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    es: (
      <>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
  },
  PACKSHOT_URL_FORMAT: {
    en: (
      <>
        <p>
          If the key provider has a standard format URL for displaying
          packshots, you can specify this here.
        </p>
        <p>
          This feature allows you to display game packshots in Alaska without
          the overhead of having to upload each one individually.
        </p>
        <p>
          The format using a string replacement of {`{0}`} with the game&apos;s
          AppId, e.g. https://cdn.alaska.games/apps/{`{0}`}/packshot.jpg
        </p>
      </>
    ),
    ja: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    fr: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    de: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    it: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    es: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
  },
  BULK_FILENAME_REGEX_PATTERN: {
    en: (
      <>
        <p>
          If the key provider has a standard format for the key code files, you
          can specify this here.
        </p>
        <p>
          This feature allows you to download the key code file from the key
          provider in their format and then upload it into Alaska via Keys &gt;
          Bulk ingest.
        </p>
        <p>
          The pattern accepts a regular expression (regex) to match on the
          filename and find the appropriate SKU and key pool to upload the file
          to.
        </p>
        <p>
          <strong>&lt;data&gt;</strong> = Alaska uses this text to search for
          the appropriate key workflow, looking for instances of the key
          provider reference (replacing spaces with underscores) found on the
          key workflow record
        </p>
        <p>
          <strong>&lt;packageid&gt;</strong> = package Id of the SKU to upload
          the keys to
        </p>
        <p>
          <strong>&lt;num&gt;</strong> = number of keys, this is used to
          validate the number in the file matches this value
        </p>
        <p>Example format:</p>
        <p
          style={{
            wordBreak: 'break-all',
          }}
        >
          Default_Game_Key_Workflow_1_pkg123456_start0_num100.txt
        </p>
        <p>
          This would find the key workflow with the key provider reference “Key
          Workflow 1” and upload the 100 keys to the SKU with package ID 123456
        </p>
      </>
    ),
    ja: (
      <>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    fr: (
      <>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    de: (
      <>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    it: (
      <>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    es: (
      <>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
  },
  DISABLED_KEY_BATCH_LIMIT: {
    en: (
      <>
        <p>
          If the key provider has a maximum limit for how many key codes can be
          uploaded and marked as disabled / banned, you can specify this here.
        </p>
        <p>
          If the disabled key code batch is greater than this limit, when you
          download the file from within the disabled transaction, Alaska will
          split it into multiple files of the maximum limit.
        </p>
        <p>
          i.e. Disabled Transaction Batch has 400,000 keys in it, the key
          provider limit is 40,000 when you download the batch you will get a
          zip file containing 10 text files each with 40,000 keys in it.
        </p>
      </>
    ),
    ja: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    fr: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    de: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    it: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
    es: (
      <>
        <p></p>
        <p></p>
        <p></p>
      </>
    ),
  },
});

export default keyProviderHelp;
