import React, { useState } from 'react';
import { toast } from 'react-toastify';
import FormFileUpload from '../../common/FormFileUpload';
import KwPanel from '../../common/KwPanel';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { validateCodeFile, uploadKeys } from '../../../actions/requestActions';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';
function BulkUploadKeys({
  keyRequestGroupId,
  requestGroupReference,
  done,
  cancel,
}) {
  const [keyRequests, setKeyRequests] = useState([]);
  const { t } = useTranslation();
  const handleSubmit = async () => {
    let uploads = [];
    for (const keyRequest of keyRequests) {
      uploads.push(
        uploadKeys(keyRequest.keyRequestId, {
          tag: keyRequest.tag,
          file: keyRequest.file,
        })
      );
    }
    const results = await Promise.all(uploads);
    done(results.map((x) => x.id));
  };
  const addFiles = (files) => {
    for (const file of files) {
      validateCodeFile(keyRequestGroupId, file.name)
        .then((result) => {
          setKeyRequests((prevState) => [...prevState, { ...result, file }]);
        })
        .catch((ex) => {
          toast.error(!!ex.message ? ex.message : t('Failed to upload file'));
        });
    }
  };

  return (
    <SlideoutLayout
      title={t('Bulk upload keys to {requestGroupReference}', {
        requestGroupReference: requestGroupReference,
      })}
      cancel={cancel}
      done={done}
      bar={
        <Button
          className="btn btn-primary"
          onClick={handleSubmit}
          isDisabled={keyRequests.length === 0}
          text={t('Bulk upload')}
        />
      }
    >
      <KwPanel className="space-bottom">
        <header className="mb-2">
          <h2 className="no-margin bold">{t('Bulk upload keys')}</h2>
        </header>
        <FormFileUpload
          name="files"
          label={t('Upload key file')}
          required={true}
          addFiles={addFiles}
          hideFiles={true}
        />
        {keyRequests?.length > 0 && (
          <div className="table-wrapper pt-2">
            <table className="table table-has-buttons jsDataTable width-100 space-bottom td-align-top">
              <thead>
                <tr>
                  <th scope="col" className="">
                    {t('Reference')}
                  </th>
                  <th scope="col" className="">
                    {t('Game')}
                  </th>
                  <th scope="col" className="">
                    {t('SKU')}
                  </th>
                  <th scope="col" className="">
                    {t('Provider')}
                  </th>
                  <th scope="col" className="">
                    {t('Number of keys')}
                  </th>
                  <th scope="col" className="">
                    <span>{t('Tag')}</span>
                  </th>
                  <th scope="col" className="text-right">
                    <span>{t('Filename')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {keyRequests?.map((keyRequest) => (
                  <tr key={keyRequest.keyRequestId}>
                    <td>{keyRequest.requestReference}</td>
                    <td>{keyRequest.gameTitle}</td>
                    <td>{keyRequest.skuName}</td>
                    <td>{keyRequest.keyProviderName}</td>
                    <td>{keyRequest.numberOfKeys}</td>
                    <td>
                      <input
                        type="text"
                        placeholder={t('Optional')}
                        className={`form-control input-sm no-navigate`}
                        style={{ width: '120px' }}
                        onChange={(e) => {
                          e.cancelBubble = true;
                          setKeyRequests((prevState) =>
                            prevState.map((x) =>
                              x.keyRequestId === keyRequest.keyRequestId
                                ? { ...keyRequest, tag: e.target.value }
                                : x
                            )
                          );
                        }}
                      />
                    </td>
                    <td className="text-right">{keyRequest.file.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </KwPanel>
    </SlideoutLayout>
  );
}
export default BulkUploadKeys;
