import React, { useState } from 'react';
import {
  loadUploaders,
  exportUploadersData,
} from '../../../../actions/uploaderActions';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { CommaNumber_NoDecimal } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import KeyProviderLink from '../../../common/links/KeyProviderLink';
import PlatformLink from '../../../common/links/PlatformLink';
import WorkflowLink from '../../../common/links/WorkflowLink';
import CompanyLink from '../../../common/links/CompanyLink';
import { useTranslation } from 'react-i18next';

function UploaderList({ location, reload, onEdit, currentUser }) {
  const { t } = useTranslation();
  const allColumns = [
    'name',
    'keyWorkflowName',
    'keyProviderName',
    'platformName',
    'companyName',
    'memberCount',
  ];
  const defaultVisibleColumns = allColumns;
  const [uploaders, setUploaders] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'uploaderlist-settings',
      getTableSettingsDefaults({ defaultSort: 'name', defaultVisibleColumns }),
      null,
      loadUploaders,
      setUploaders,
      reload,
      t('Failed to load uploaders'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: t('Name'),
      width: '15%',
      Render: (item) => (
        <span className="a" onClick={() => onEdit(item.id)}>
          {item.groupName ?? item.fullName}
        </span>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyWorkflowName',
      Label: t('Workflow'),
      width: '15%',
      Render: (row) => (
        <WorkflowLink
          id={row.keyWorkflowId}
          key={row.keyWorkflowId}
          name={row.keyWorkflowName}
          colour={row.keyWorkflowColourHex}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyProviderName',
      Label: t('Key provider'),
      width: '15%',
      Render: (row) => (
        <KeyProviderLink id={row.keyProviderId} name={row.keyProviderName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'platformName',
      Label: t('Platform'),
      width: '15%',
      Render: (item) => (
        <PlatformLink id={item.platformId} name={item.platformName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'companyName',
      Label: t('Company'),
      width: '15%',
      Render: (item) => (
        <CompanyLink id={item.companyId} name={item.companyName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'memberCount',
      Label: t('Members'),
      width: '15%',
      className: 'text-right num no-wrap',
      Render: (item) =>
        CommaNumber_NoDecimal(item.memberCount + (item.fullName ? 1 : 0)),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return uploaders ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={uploaders.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={uploaders.totalRowCount}
        filteredRowCount={uploaders.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportUploadersData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default UploaderList;
