import { useEffect, useState } from 'react';

const useUploadNotifications = ({
  keypools,
  setKeyPools,
  addToGroup,
  removeFromGroup,
  importTransactions,
}) => {
  const [uploadsInProgress, setUploadsInProgress] = useState([]);
  useEffect(() => {
    if (keypools) {
      for (const i in keypools.data) {
        const keyPool = keypools.data[i];
        if (keyPool.uploadInProgress) {
          addToGroup(`import:${keyPool.importTransactionId}`);
          setUploadsInProgress((prevState) =>
            prevState.includes(keyPool.importTransactionId)
              ? prevState
              : [...prevState, keyPool.importTransactionId]
          );
        }
      }
    }
  }, [keypools, addToGroup]);

  useEffect(() => {
    //clear subscriptions
    if (uploadsInProgress && uploadsInProgress.length > 0) {
      return () => {
        let uploads = [];
        for (let i in uploadsInProgress) {
          uploads.push(removeFromGroup(`import:${uploadsInProgress[i]}`));
        }

        return Promise.all(uploads);
      };
    }
  }, [uploadsInProgress, removeFromGroup]);

  useEffect(() => {
    if (
      importTransactions &&
      importTransactions.length > 0 &&
      importTransactions.filter((x) => uploadsInProgress.includes(x.id))
    ) {
      setKeyPools((prevState) =>
        prevState && prevState.data
          ? {
              ...prevState,
              data: prevState.data.map((keypool) => {
                const importTransaction = importTransactions.find(
                  (i) => i.id === keypool.importTransactionId
                );
                return importTransaction
                  ? { ...keypool, ...importTransaction }
                  : keypool;
              }),
            }
          : prevState
      );
    }
  }, [importTransactions, uploadsInProgress, setKeyPools]);
};
export default useUploadNotifications;
