import React from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../../layout/slideout/SlideoutLayout';
import KwPanel from '../../../common/KwPanel';

import { deleteUploader } from '../../../../actions/uploaderActions';
import { toast } from 'react-toastify';
import Button from '../../../common/Button';
import ConfirmationHeader from '../../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function Delete({ done, id, cancel }) {
  const { t } = useTranslation();
  const handleDelete = () => {
    deleteUploader(id)
      .then(() => {
        toast.success(t('Uploader successfully deleted'));
        done();
      })
      .catch(() => {
        toast.error(t('Failed to delete uploader'));
      });
  };

  return (
    <SlideoutLayout
      title={t('Delete confirmation')}
      cancel={cancel}
      done={done}
    >
      <KwPanel>
        <ConfirmationHeader text={t('Are you sure?')} />
        <Button
          className="btn btn-danger me-2"
          onClick={handleDelete}
          text={t('Yes, delete the uploader')}
        />
        <Button
          className="btn btn-default"
          onClick={cancel}
          autoFocus={true}
          text={t('No, cancel')}
        />
      </KwPanel>
    </SlideoutLayout>
  );
}
Delete.propTypes = {
  id: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
};

export default Delete;
