import React from 'react';
import ActionBar from '../layout/ActionBar';
import KeysByMonthBySkuList from './components/month/KeysByMonthBySkuList';
import KeysByWeekBySkuList from './components/week/KeysByWeekBySkuList';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import ReportTableViewSwitcher from './components/ReportTableViewSwitcher';

function KeysBySku({ location }) {
  const [groupBy, setGroupBy] = useLocalStorage('report-groupBy', 'month');

  return (
    <>
      <ActionBar></ActionBar>
      {groupBy === 'month' ? (
        <KeysByMonthBySkuList
          location={location}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      ) : (
        <KeysByWeekBySkuList
          location={location}
          tableViewSwitcher={
            <ReportTableViewSwitcher
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          }
        />
      )}
    </>
  );
}

export default KeysBySku;
