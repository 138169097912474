import React from 'react';
import PropTypes from 'prop-types';
import image_space_invader from '../../../content/images/space-invader.svg';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import CompanyLink from '../../common/links/CompanyLink';
import { useTranslation } from 'react-i18next';

const CompanyKeyStock = ({ companyKeyStocks }) => {
  const { t } = useTranslation();
  return companyKeyStocks && companyKeyStocks.length ? (
    <>
      <div className="panel kw-panel r-corners-sm-up space-bottom">
        <header className="mb-2">
          <h2 className="no-margin">{t('Company keys in stock')}</h2>
        </header>
        <div id="CompanyKeyStockDiv" className="table-overflow">
          <table className="table table-has-buttons width-100">
            <thead>
              <tr>
                <th scope="col">{t('Company name')}</th>
                <th scope="col" className="text-right num">
                  {t('Keys in stock')}
                </th>
              </tr>
            </thead>
            <tbody>
              {companyKeyStocks.map((company) => (
                <tr key={company}>
                  <td>
                    <CompanyLink
                      id={company.companyId}
                      name={company.companyName}
                    />
                  </td>
                  <td className="text-right num">
                    <span id="available">
                      {CommaNumber_NoDecimal(company.stock)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  ) : (
    <div
      className="panel kw-panel text-center no-records space-bottom"
      style={{ padding: '30px 15px' }}
    >
      <img
        src={image_space_invader}
        className="space-bottom"
        alt={t('Space invader')}
      />
      <h2 className="bold space-bottom">{t('Company keys in stock')}</h2>
      <div>{t('Currently no companies have keys in stock')}</div>
    </div>
  );
};

CompanyKeyStock.propTypes = {
  companyKeyStocks: PropTypes.array.isRequired,
};

export default CompanyKeyStock;
