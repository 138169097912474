import React from 'react';
import KwPanel from '../../common/KwPanel';
import FormInput from '../../common/FormInput';
import PropTypes from 'prop-types';
import FormToggleBool from '../../common/FormToggleBool';
import { useTranslation } from 'react-i18next';

const CloneSkuForm = ({ register, errors, control }) => {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4">
      <FormInput
        register={register}
        required={true}
        error={errors.skuName}
        errorMessage={t('Name is required')}
        name="skuName"
        label={t('Name')}
        placeholder={t('Name of the sku')}
        autoFocus={true}
      />
      <FormToggleBool
        control={control}
        name="withRestrictions"
        label={t('Clone restrictions?')}
        defaultValue={true}
      />
    </KwPanel>
  );
};

CloneSkuForm.propTypes = {
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
};

export default CloneSkuForm;
