import { useState, useCallback } from 'react';

const useLoader = () => {
  const [isLoading, setIsLoading] = useState(false);

  let start = useCallback(() => {
    setIsLoading(true);
  }, []);

  const clear = useCallback(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, []);

  return [isLoading, start, clear];
};

export default useLoader;
